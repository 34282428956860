import React, { useEffect, useState } from "react";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../../Components/Modals/Loading/Loader";
import moment from "moment";
import swal from "sweetalert";
import { InfoGeneracionPeriodoModal } from "../../../Components/EvaluacionDesempeño/Modal";
import {
  selectListaEvaluaciones, 
  selectIsLoading,
  selectIsSuccess,
  selectMessage,
  selectMensajeGeneracionPeriodo,  
  fetchListaEvaluaciones, 
  fetchListaEvaluacionesExportarMAP, 
  fetchListaEvaluacionesExportarDAF,
  fetchEvaluacionGenerarPeriodo, 
  fetchGetReporteEvaluacionesPeriodoMAP2024,
  fetchEvaluacionCerrarPeriodo,
  fetchCambiarSuccess
  } from '../../../redux/Evaluaciones/EvaluacionesSlice';
  import { PermissionData } from '../../../context/Component/PermissionData';
import { AgregarEditarPeriodoEvaluacionModal } from "../../../Components/EvaluacionDesempeño/Modal";
import { IconButton } from "@mui/material";

export const EvaluacionesDesempeño = () => {

  /* 
  * ************************************ *
  * *****- ESTADOS DE UN PERIODO: -***** *
  * ************************************ *
  * 
  * 0: PERIODO ABIERTO
  * 1: PERIODO CERRADO
  *
  * ************************************ *
  * ************************************ *
  */

  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  // ? HOOK DISPATCH
  const dispatch = useDispatch();

  // ? HOOK SELECTOR
  const listadoEvaluaciones = useSelector(selectListaEvaluaciones);
  const listaMensajesGeneracionPeriodo = useSelector(selectMensajeGeneracionPeriodo);
  const isLoading = useSelector(selectIsLoading);
  const isSuccess = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);

  // ? HOOK STATE
  const [stateGeneracionPeriodo, setStateGeneracionPeriodo] = useState(false);
  const [stateEditarInicioPeriodo, setStateEditarInicioPeriodo] = useState(false);
  const [periodoEvaluacion, setPeriodoEvaluacion] = useState({});
  const [tipoDeAccion, setTipoDeAccion] = useState(0);

  // * REFRESCAR LISTA
  const refreshList = () => dispatch(fetchListaEvaluaciones());

  useEffect(() => {
    refreshList();
  }, [])

  useEffect(() => {

    if(Object.keys(listaMensajesGeneracionPeriodo).length > 0){
      setStateGeneracionPeriodo(true);
      refreshList();
    }
    
  }, [listaMensajesGeneracionPeriodo])
  
  useEffect(() => {
    
    if(isSuccess === 'success'){
      swal("Exitoso!", message, "success");
      refreshList();
      dispatch(fetchCambiarSuccess());
    }
    else if(isSuccess === 'error'){
      swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implementación de Sistemas.", "error");
      dispatch(fetchCambiarSuccess());
    }

  }, [isSuccess])

  // ? HOOK NAVEGACION.
  const navigate = useNavigate();

  // * FUNCION PARA NAVEGACION.
  const NavigateTo = (ano) => {
    navigate(`/evaluaciones/desempeno/${ano}`);
  }

  // * FUNCION PARA GENERAR EVALUACIONES DE X PERIODO DE MANERA AUTOMATICA.
  const generarEvaluacionesAutomaticas = (ano) => {
    swal({
      title: 'Advertencia',
      text: `Estás seguro/a que desea generar las evaluaciones del periodo ${ano}?`,
      icon: 'warning',
      buttons: ['No', 'Si']
  }).then(respuesta => {
      if(respuesta){
          dispatch(fetchEvaluacionGenerarPeriodo(ano));
      }
  })
  }

  // * FUNCION PARA SOLICITAR EL REPORTES.

  const generateReportMAP = (ano) => {
    dispatch(fetchListaEvaluacionesExportarMAP(ano))
  }

  const generateReportDAF = (ano) => {
    dispatch(fetchListaEvaluacionesExportarDAF(ano))
  }

  const generateReportMAP2024 = (ano) => {
    dispatch(fetchGetReporteEvaluacionesPeriodoMAP2024(ano))
  }

  // * FUNCION PARA EDITAR EL PERIODO DE EVALUACION.
  const editarPeriodoEvaluacion = (periodo) => {
    setTipoDeAccion(2);
    setPeriodoEvaluacion(periodo);
    setStateEditarInicioPeriodo(true);
  }

  // * FUNCION PARA AGREGAR PERIODO DE EVALUACION
  const agregarPeriodoEvaluacion = () => {
    setTipoDeAccion(1);
    setStateEditarInicioPeriodo(true);
  }

  // * FUNCION PARA CERRAR X PERIODO.
  const cerrarPeriodo = (ano) => {
    swal({
      title: 'Advertencia',
      text: `Estás seguro/a que desea cerrar el periodo ${ano}?`,
      icon: 'warning',
      buttons: ['No', 'Si']
    }).then(respuesta => {
        if(respuesta){
            dispatch(fetchEvaluacionCerrarPeriodo(ano));
        }
    })
  }

  // * BOTON PERSONALIZADO DE DESCARGA DE REPORTE
  const ReportButton = ({text, func, params}) => {
    return(
      <IconButton 
      onClick={() => func(params)}
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <DownloadRoundedIcon sx={{ color: '#003876', fontSize: 29 }} />
        <Typography fontWeight='bold' style={{fontSize: 14}} variant="caption" sx={{ color: '#003876' }}>{text}</Typography>
      </IconButton>
    )
  }

  // * ULTIMA POSICION DEL ARREGLO.
  const ultimaPosicionArrEvaluaciones = listadoEvaluaciones[listadoEvaluaciones?.length - 1];

  // * VALIDACION QUE RETORNA UN BOOLEANO SI SE CUMPLE LA CONDICION DE QUE LA CANTIDAD DE EVALUACIONES ES
  // * IGUAL A LA CANTIDAD DE EVALUACIONES TERMINADAS.
  const validacionCantidadEvaluacionesIgualCerradas = ultimaPosicionArrEvaluaciones?.cantidadEvaluaciones === ultimaPosicionArrEvaluaciones?.terminadasCerradas && ultimaPosicionArrEvaluaciones?.cantidadEvaluaciones !== 0 ? true : false

  return (
    <div className="container-fluid main">
          <div className="row mt-2">
            <div className="col-md-12">
              <div>
                <h4 className="pt-2 ps-2">EVALUACIÓN DE DESEMPEÑO</h4>
                <hr />
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button disabled={validacionCantidadEvaluacionesIgualCerradas && ultimaPosicionArrEvaluaciones?.estado === 1 ? false : true} className='btn btn-primary-mem' onClick={() => agregarPeriodoEvaluacion()}>Agregar Período Evaluación <AddIcon sx={{marginTop: -0.5}} /></button>
            </div>

            <div className="row mt-4 mx-auto">

            {
              listadoEvaluaciones.map((item, index) =>

              <div className="card mb-3" key={index}>
                <div className="row g-0">
                  <div className="col-1 text-center mt-3 mb-3">
                    <p className="card-text fw-bold text-14">Año</p>
                    <h6 className="text-14">{item.anio}</h6>
                  </div>

                  <div className="col-3">
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Inicio de período:</p>
                      <h6 className="text-14">{moment(item.iniciodePeriodo).format(`D [de] MMMM`)}</h6>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Cantidad de Evaluaciones</p>
                      <h6 className="text-14">{item.cantidadEvaluaciones}</h6>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Terminadas (cerradas)</p>
                      <h6 className="text-14">{item.terminadasCerradas}</h6>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className='mt-3 mb-2 d-flex justify-content-center'>

                      {
                        item.estado === 1 && permisosState.ROLE_ADMIN_EVALUACIONES && item.anio < 2024 &&
                        
                          <>
                            <ReportButton 
                            func={generateReportMAP}
                            params={item.anio}
                            text={'MAP'}
                            key={index}
                            />

                            <ReportButton 
                            func={generateReportDAF}
                            params={item.anio}
                            text={'DAF'}
                            key={index}
                            />

                            <div className="mt-2">
                              <IconButton sx={{color: '#003876'}}>
                                <DoneAllRoundedIcon />
                              </IconButton>
                            </div>

                          </>
                      }

                      {
                        item.estado === 1 && permisosState.ROLE_ADMIN_EVALUACIONES && item.anio >= 2024 &&
                        
                          <ReportButton 
                          func={generateReportMAP2024}
                          params={item.anio}
                          text={'MAP'}
                          key={index}
                          />

                      }

                      {
                        item.estado === 0 && permisosState.ROLE_ADMIN_EVALUACIONES && 

                        <>
                          <div className="mt-2">
                            <IconButton onClick={() => editarPeriodoEvaluacion(item)}>
                              <ModeEditOutlineIcon sx={{color: '#003876'}} />
                            </IconButton>
                          </div>

                          <div className="mt-2">
                            <IconButton onClick={() => generarEvaluacionesAutomaticas(item.anio)}>
                              <CachedIcon sx={{color: '#003876'}} />
                            </IconButton>
                          </div>
                        </>
                        
                      }

                      {
                        item.estado === 0 && item.cantidadEvaluaciones === item.terminadasCerradas && item.cantidadEvaluaciones !== 0 &&
                          <IconButton onClick={() => cerrarPeriodo(item.anio)}>
                            <DoneOutlineIcon sx={{color: '#005A17'}} />
                          </IconButton>
                      }
                        <div className="mt-2">
                          <IconButton onClick={() => NavigateTo(item.anio)}>
                            <ExitToAppRoundedIcon sx={{color: '#003876'}} />
                          </IconButton>
                        </div>
                    </div>
                  </div>   
                </div>
              </div>

              )
              }

            </div>
          </div>

          <InfoGeneracionPeriodoModal 
            setOpen={setStateGeneracionPeriodo}
            open={stateGeneracionPeriodo}
            listaMensajesGeneracionPeriodo = {listaMensajesGeneracionPeriodo}
          />

          <AgregarEditarPeriodoEvaluacionModal
            setOpen={setStateEditarInicioPeriodo}
            open={stateEditarInicioPeriodo}
            periodo={periodoEvaluacion}
            tipoDeAccion={tipoDeAccion}
            ultimaEvaluacionAno={ultimaPosicionArrEvaluaciones?.anio}
          />

          <Loading open={isLoading} />

    </div>
  );
};
