import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { IoAddCircle } from "react-icons/io5";
import { CleaningServices } from "@mui/icons-material";
import { Typography } from '@mui/material';
import { Controller } from "react-hook-form";
import Select from "react-select";

import { PermissionData } from "../../context/Component/PermissionData";

import {
    selectAllEmpleadosList,
    fetchAllEmpleados,
} from "../../redux/Empleados/EmpleadosSlice";

import {
    fetchAreasOrganizacionales,
    selectAllAreasOrganizacionales,
} from "../../redux/AreaOrganizacional/AreaOrganizacionalSlice";

import {
    fetchEstadosEmpleado,
    selectAllEstadosEmpleado
} from "../../redux/Utility/UtilitySlice"

export const FiltroAcciones = ({ register, control, reset, rutaAccionBotonAgregar, permisoBoton }) => {

    // * PERMISOS DEL USUARIO.
    const permisosState = PermissionData();

    // ? HOOK-NAVEGACION
    const navigate = useNavigate();

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();
    
    // ? HOOK-SELECTOR
    const empleadosList = useSelector(selectAllEmpleadosList);
    const listAreaOrganizacionales = useSelector(selectAllAreasOrganizacionales);
    const listEstadosEmpleado = useSelector(selectAllEstadosEmpleado);
    
    // ? HOOK-STATE DE LISTAS
    const [listaAreasState, setListaAreasState] = useState([]);
    const [listaEmpleadosState, setListaEmpleadosState] = useState([]);

    useEffect(() => {
        setListaAreasState([
            { id: 0, nombre: "Seleccionar Área" },
            ...listAreaOrganizacionales,
        ]);

        setListaEmpleadosState([
            { id: 0, nombreLabel: "Seleccionar Empleado" },
            ...empleadosList,
        ]);

    }, [listAreaOrganizacionales, empleadosList])


    useEffect(() => {
        dispatch(fetchAllEmpleados());
        dispatch(fetchAreasOrganizacionales());
        dispatch(fetchEstadosEmpleado());
    }, [])

    // * VARIABLE PARA EVALUAR PERMISOS
    const buttonEnabled = permisosState[permisoBoton] || permisosState.ROLE_ADMIN;

  return (
    <div className='border m-1 p-2 d-flex justify-content-between'>
        
        <div className={"col-3"}>
            <div className="form-group">
                <Typography fontSize='12px' fontWeight='bold'>Empleado</Typography>

                <Controller
                control={control}
                name="empleadoId"
                {...register("empleado.empleadoId")}
                render={({ field }) => (
                    <Select
                    id='select-empleado'
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Seleccionar Empleado"
                    options={listaEmpleadosState}
                    getOptionLabel={(option) => option.nombreLabel}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => field.onChange(e.id)}
                    value={listaEmpleadosState.find(x => x.id === field.value)}
                    />
                )}
                />

            </div>
        </div>

        <div className={"col-3"}>
            <Typography fontSize='12px' fontWeight='bold'>Área Organizacional</Typography>
            <Controller
                control={control}
                name="areaId"
                {...register("empleado.areaId")}
                render={({ field }) => (
                <Select
                    id='select-area'
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Seleccionar Área"
                    options={listaAreasState}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => field.onChange(e.id)}
                    value={listaAreasState.find(x => x.id === field.value)}
                />
                )}
            />
        </div>

        <div className={"col-2"}>
            <Typography fontSize='12px' fontWeight='bold'>Estado</Typography>
            <Controller
                control={control}
                name="empleado.estadoId"
                render={({ field }) => (
                <Select
                    id='select-estado'
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Seleccionar Estado"
                    options={listEstadosEmpleado}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => field.onChange(e.id)}
                    value={listEstadosEmpleado.find(x => x.id === field.value)}
                />
                )}
            />
        </div>

        <div className='col-1'>
            <button id='limpiar-button' className='btn btn-danger' style={{marginTop: 17}} onClick={() => reset()}><CleaningServices /></button>
        </div>
        
        <div className="col-2 d-flex justify-content-end">
            <button id='agregar-button' hidden={!buttonEnabled} className='btn btn-primary-mem' onClick={() => navigate(rutaAccionBotonAgregar)}>Agregar Acción <IoAddCircle style={{marginBottom: 4}} fontSize='22' /></button>
        </div>
    </div>
  )
}
