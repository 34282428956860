import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Add from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};

const TipoPermisoNuevo = ({ open, setOpen,tiposPermisosCategorias,handleAddTipoPermiso }) => {

    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
	useEffect(()=>{
		setValue("estado",true)
	},[])
    const onSubmit = tipoPermiso => handleAddTipoPermiso(tipoPermiso);
	const handleClose = () => {
		setOpen(false);
		reset();
	};
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
		>
			<Box sx={{ ...style, width: 700 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
            	<div className="container">
					<div className="row">
						<h3 className="text-primary-mem text-center">Tipo Permiso</h3>
					</div>
					<div className="row">
						<div className="col-5 me-0 pe-0">
							<hr />
						</div>
						<div className="col-2 text-center">
							<Add className="text-primary-mem" />
						</div>
						<div className="col-5 ms-0 ps-0 ">
							<hr />
						</div>
					</div>
                    <div className='row'>
                        <div className='form-group'>
                            <label className='fw-bold'>Tipo Permiso</label>
                            <input {...register("nombre",{required:"El tipo permiso es requerido"})} type="text" className='form-control'></input>
                            <p className='text-danger'>{errors.nombre?.message}</p>
                        </div>
                        <div className='form-group'>
                            <label className='fw-bold'>Categoria</label>
                            <select {...register("categoriaId",{required:"La categoria es requerida"})} className='form-control'>
                                <option value="">Seleccionar Categoria</option>
                                {
                                   tiposPermisosCategorias && tiposPermisosCategorias.length>0&&  tiposPermisosCategorias.map((categoria)=><option key={categoria.id} value={categoria.id}>{categoria.nombre}</option>)
                                }
                            </select>
                            <p className='text-danger'>{errors.categoriaId?.message}</p>
                        </div>
                        <div className='form-group'>
                            <label className='fw-bold'>Estado</label>
                            <select {...register("estado",{required:"El estado es requerido"})} className='form-control'>
                                <option value="">Seleccionar Estado</option>
                                <option value={true}>Activo</option>
                                <option value={false}>Inactivo</option>
                            </select>
                            <p className='text-danger'>{errors.estado?.message}</p>
                        </div>
						<div className='form-group'>
                            <label className='fw-bold'>Duración Minima</label>
                            <input {...register("duracionMinima",{required:"Duración Minima es requerida"})} type="number" className='form-control'></input>
                            <p className='text-danger'>{errors.nombre?.message}</p>
                        </div>
						<div className='form-group'>
                            <label className='fw-bold'>Duración Maxima</label>
                            <input {...register("duracionMaxima",{required:"Duración Maxima es requerida"})} type="number" className='form-control'></input>
                            <p className='text-danger'>{errors.nombre?.message}</p>
                        </div>
						<div className='form-group'>
                            <label className='fw-bold'>Niveles de Aprobación</label>
                            <select {...register("nivelesAprobacion",{required:"El estado es requerido"})} className='form-control'>
                                <option value="">Seleccionar Nivel de Aprobación</option>
                                <option value={1}>Supervisor Inmediato</option>
                                <option value={2}>Recursos Humanos</option>
                                <option value={3}>Supervisor Inmediato y Recursos Humanos </option>
                            </select>
                            <p className='text-danger'>{errors.estado?.message}</p>
                        </div>
                        <div className='d-flex justify-content-center'>
                        <button id='btn-guardar' type='submit' className='btn btn-primary-mem me-3'>Guardar</button>
                        <button id='btn-cancelar' type='button' className='btn btn-danger ' onClick={handleClose}>Cancelar</button>
                        </div>
                    </div>
				</div>
                </form>
			</Box>
		</Modal>
	);
};

export default TipoPermisoNuevo;
