import React from "react";
import Card from "@mui/material/Card";
import moment from "moment";
import "moment/locale/es";
import { IconButton } from "@mui/material";
import { BorderColor, Delete } from "@mui/icons-material";

const DiaFeriadoCard = ({
  setOpenEditar,
  eliminarDiaFeriado,
  diaFeriado,
  editarDiaFeriado,
}) => {
  return (
    <Card sx={{ maxWidth: 345, marginTop: 2, marginBottom: 5, marginRight: 5 }}>
      <div className="d-flex justify-content-between">
        
        <IconButton id="btn-eliminar-feriado">
          <Delete
            className="text-danger"
            onClick={() => eliminarDiaFeriado(diaFeriado)}
            sx={{ cursor: "pointer" }}
          />
        </IconButton>

        <IconButton id="btn-editar-feriado">
          <BorderColor
            className="text-primary-mem"
            onClick={() => editarDiaFeriado(diaFeriado)}
            sx={{ cursor: "pointer" }}
          />
        </IconButton>
       
      </div>
      <div className="d-flex justify-content-center">
        <div className="col-2 align-self-center">
          <h2 className="text-primary-mem">
            {moment(diaFeriado.fecha).format("DD")}
          </h2>
        </div>
        <div className="col-10">
          <h6 className="text-primary-mem fw-bold">
            {diaFeriado.justificacion}
          </h6>
          <label>{moment(diaFeriado.fecha).format("dddd, DD MMMM YYYY")}</label>
        </div>
      </div>
    </Card>
  );
};

export default DiaFeriadoCard;
