import React from "react";
import  { render } from "react-dom";
import App from "./App";
import { store } from "./redux/store";
import { injectStore } from './utils/apiClient'
import { Provider } from "react-redux";
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider, createTheme } from "@mui/material";

// * STORE QUE SE ALMACENARA EN EL LOCAL-STORAGE.
let persistor = persistStore(store)

// * INJECTAR DEPENDENCIA EN EL API-CLIENT.
injectStore(store);

// * CREACION DE TEMA.
const theme = createTheme({
  palette: {
    primary: {
      main: '#003876'
    },
    secondary: {
      main: '#ffff'
    },
    primaryMem: '#003876'
  },
});

render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,

  document.getElementById('root')
);