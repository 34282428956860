import React, { useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Loading from "../../Components/Modals/Loading/Loader";
import swal from 'sweetalert';

import { PermissionData } from '../../context/Component/PermissionData';

import { 
  Acciones,
  Asignaciones,
  HistorialLaboral,
  ComposicionFamiliar,
  DatosNombramientos,
  IdiomasEmpleados,
  EmpleadoPerfil,
  Estudios
} from '../../Components/Empleado';

import ComposicionFamiliarNuevo from '../../Components/Modals/ComposicionFamiliar/ComposicionFamiliarNuevo';
import HistorialLaboralNuevo from "./../../Components/Modals/HistorialLaboral/HistorialLaboralNuevo"
import EstudiosNuevos from '../../Components/Modals/Estudios/EstudiosNuevo';
import IdiomaNuevo from '../../Components/Modals/Idiomas/IdiomaNuevo';

import {
	selectEmpleadosIsLoading,
	selectEmpleado,
	selectEmpleadosIsSuccess,
  selectMessage,
	fetchEmpleado,
  fetchCambiarSuccess
} from '../../redux/Empleados/EmpleadosSlice';

export const EmpleadoDetalle = () => {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-PARAMS
  const { empleadoId } = useParams();

  // ? HOOK-SELECTOR
  const empleado = useSelector(selectEmpleado);
  const isLoading = useSelector(selectEmpleadosIsLoading);
  const isSuccess = useSelector(selectEmpleadosIsSuccess);
  const message = useSelector(selectMessage);
  
  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  // ? HOOK-STATE
  const [value, setValue] = useState("1");
  const [openComposicionFamiliar, setOpenComposicionFamiliar] = useState(false);
  const [openHistorialLaboral, setOpenHistorialLaboral] = useState(false);
  const [openEstudiosNuevo, setOpenEstudiosNuevo] = useState(false);
  const [openIdiomasNuevo, setOpenIdiomasNuevo] = useState(false);

  // * REFRESCAR DATA DEL EMPLEADO 
  const refrestData = () => dispatch(fetchEmpleado(empleadoId));

  // ? HOOK-EFFECT
  useEffect(() => {
    refrestData();
  }, [empleadoId]);

    // ? USE-EFFECT
    useEffect(() => {
      if (isSuccess === "success" && message.length > 0) {
        swal("Exitoso!", message, "success");
        refrestData();
        dispatch(fetchCambiarSuccess());
      } else if (isSuccess === "error") {
        swal("Error", message, "error");
        dispatch(fetchCambiarSuccess());
      }
    }, [isSuccess]);

    // * FUNCIONES
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  return (
    <div className="container">
      <div className="row mt-2">
        <h1 className="text-primary-mem">Empleado</h1>
        <hr />
      </div>
      <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Datos Personales" value="1" />
              <Tab label="Composición Familiar" value="2" />
              <Tab label="Reconocimiento" value="3" />
              <Tab label="Estudios" value="4" />
              <Tab label="Idiomas" value="5" />
              <Tab label="Datos del Nombramiento" value="6" />
              <Tab label="Asignaciones" value="7" />
              <Tab label="Historia Laboral" value="8" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <EmpleadoPerfil empleado={empleado} />
          </TabPanel>
          <TabPanel value="2">
            {!isLoading &&
              empleado &&
              isSuccess &&
              empleado.composicionFamiliar &&
              empleado.composicionFamiliar.length > 0 &&
              empleado.composicionFamiliar.map((familia, index) => (
                <ComposicionFamiliar
                  key={familia.id}
                  familia={familia}
                  index={index}
                />
              ))}
            <div className="text-end mt-3">
              <button
                id='btn-agregar-composicion-familiar'
			          hidden={!permisosState.ROLE_CREAR_EMPLEADO}
                type="button"
                className="btn btn-primary-mem"
                onClick={() => setOpenComposicionFamiliar(true)}
              >
                Agregar Composición Familiar
              </button>
            </div>
          </TabPanel>
          <TabPanel value="3">
            {!isLoading &&
              empleado &&
              isSuccess &&
              empleado.historialLaboral &&
              empleado.historialLaboral.length > 0 &&
              empleado.historialLaboral.map((empleo, index) => (
                <HistorialLaboral
                  key={empleo.id}
                  empleo={empleo}
                  index={index}
                />
              ))}
            <div className="text-end mt-3">
              <button
                id='btn-agregar-historial-laboral'
                type="button"
                className="btn btn-primary-mem"
                onClick={() => setOpenHistorialLaboral(true)}
                hidden={!permisosState.ROLE_CREAR_EMPLEADO}
              >
                Agregar Historial Laboral
              </button>
            </div>
          </TabPanel>
          <TabPanel value="4">
            {!isLoading &&
              empleado &&
              isSuccess &&
              empleado.estudios &&
              empleado.estudios.length > 0 &&
              empleado.estudios.map((estudio, index) => (
                <Estudios key={estudio.id} estudio={estudio} index={index} />
              ))}
            <div className="text-end mt-3">
              <button
                id='btn-agregar-estudios'
			          hidden={!permisosState.ROLE_CREAR_EMPLEADO}
                type="button"
                className="btn btn-primary-mem"
                onClick={() => setOpenEstudiosNuevo(true)}
              >
                Agregar Estudios
              </button>
            </div>
          </TabPanel>
          <TabPanel value="5">
            {!isLoading &&
              empleado &&
              isSuccess &&
              empleado.idiomasEmpleado &&
              empleado.idiomasEmpleado.length > 0 &&
              empleado.idiomasEmpleado.map((idiomaEmpleado, index) => (
                <IdiomasEmpleados
                  key={idiomaEmpleado.id}
                  idiomaEmpleado={idiomaEmpleado}
                  index={index}
                />
              ))}
            <div className="text-end mt-3">
              <button
                id='btn-agregar-idioma'
			  	      hidden={!permisosState.ROLE_CREAR_EMPLEADO}
                type="button"
                className="btn btn-primary-mem"
                onClick={() => setOpenIdiomasNuevo(true)}
              >
                Agregar Idioma
              </button>
            </div>
          </TabPanel>
          <TabPanel value="6">
            <DatosNombramientos empleado={empleado} />
          </TabPanel>
          <TabPanel value="7">
            {!isLoading &&
              empleado &&
              isSuccess &&
              empleado.asignaciones &&
              empleado.asignaciones.length > 0 &&
              empleado.asignaciones.map((asignacion, index) => (
                <Asignaciones
                  key={asignacion.id}
                  asignacion={asignacion}
                  index={index}
                />
              ))}
          </TabPanel>
          <TabPanel value="8">
           {
            empleado &&
            empleado?.acciones &&
            empleado?.acciones?.length > 0 &&
              <Acciones 
              acciones={empleado.acciones}
              />
           }
          </TabPanel>
        </TabContext>
      </Box>

      <ComposicionFamiliarNuevo
        openComposicionFamiliar={openComposicionFamiliar}
        empleadoId={empleado?.id}
        setOpenComposicionFamiliar={setOpenComposicionFamiliar}
      />
      <HistorialLaboralNuevo
        openHistorialLaboral={openHistorialLaboral}
        empleadoId={empleado?.id}
        setOpenHistorialLaboral={setOpenHistorialLaboral}
      />
      <EstudiosNuevos
        openEstudiosNuevo={openEstudiosNuevo}
        setOpenEstudiosNuevo={setOpenEstudiosNuevo}
        empleadoId={empleado?.id}
      />
      <IdiomaNuevo
        openIdiomasNuevo={openIdiomasNuevo}
        setOpenIdiomasNuevo={setOpenIdiomasNuevo}
        empleadoId={empleado?.id}
      />

      <Loading open={isLoading} />
    </div>
  );
};

export default EmpleadoDetalle;
