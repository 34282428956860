import React from 'react'
import { Route, Routes, Navigate } from 'react-router'
import { RequiredPermission} from '../authentication/RequiredPermission';
import { EvaluacionesDesempeño, EvaluacionDesempeñoDetalle, EvaluacionDesempeñoEmpleado, Dashboard } from '../pages/Evaluaciones/Desempeño';
import { EvaluacionesPendientes, EvaluacionesPendientesAno } from '../pages/Evaluaciones/Pendientes';

export const RutasEvaluaciones = () => {
  return (
    <Routes>

          <Route element={<RequiredPermission permission='ROLE_VER_PERIODO_EVALUACION'/>}> 
              <Route path='/desempeno/dashboard' element={<Dashboard />} />
              <Route path="/desempeno" element={<EvaluacionesDesempeño />} />
              <Route path="/desempeno/:ano" element={<EvaluacionDesempeñoDetalle />} />
              <Route path="/desempeno/empleado/:hash" element={<EvaluacionDesempeñoEmpleado />} />
          </Route>

          <Route path='/misevaluaciones' element={<EvaluacionesPendientes />}/>
          <Route path='/misevaluaciones/:ano' element={<EvaluacionesPendientesAno />}/>
          <Route path='/*' element={<Navigate to="/" replace />} />

    </Routes>
  )
}
