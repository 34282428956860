import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Encabezado } from "../../Components/Encabezado/Encabezado";
import Loading from "../../Components/Modals/Loading/Loader";

import { TextField, Autocomplete, Box } from "@mui/material";

import swal from "sweetalert";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { meses } from "../../utils/meses";

import {
  fetchPeriodosSubirNomina,
  fetchClearEmpleadosNoEncontrados,
  fetchSubirNomina,
  selectPeriodoSubirNomina,
  selectIsLoading,
  selectMessage,
  selectIsSuccess,
  selectEmpleadoNoEncontradosNomina,
  fetchCambiarSuccess,
} from "../../redux/Nominas/NominaSlice";

import { ModalAlertaCargaNomina } from "../../Components/Nomina/Modal/ModalAlertaCargaNomina";

export const CargarNominas = () => {
  // ? AÑO ACTUAL
  const ano = new Date().getFullYear().toString();

  // ? MES ACTUAL
  const mes = new Date().getMonth() + 1;

  // ? USE-DISPATCH
  const dispatch = useDispatch();

  // ? USE-SELECTOR
  const listadoPeriodos = useSelector(selectPeriodoSubirNomina);
  const listaEmpleadoNoEncontrados = useSelector(
    selectEmpleadoNoEncontradosNomina
  );
  const isLoading = useSelector(selectIsLoading);
  const message = useSelector(selectMessage);
  const success = useSelector(selectIsSuccess);

  // ? USE-REF
  const refExcel = useRef();

  // ? HOOK-STATE
  const [nominaEmpleadoData, setNominaEmpleadoData] = useState({
    ano: listadoPeriodos[0]?.fecha ?? ano,
    mes: meses.find(x => x.noEntero === mes).no
  });

  // ? HOOK-STATE PARA VALIDACION
  const [validacionValorInputExcel, setValidacionValorInputExcel] = useState(
    true
  );

  // * FUNCION PARA LIMPIAR SUCCESS
  const clearSuccess = () => dispatch(fetchCambiarSuccess());

  // * FUNCION PARA GUARDAR LA DATA DEL FORMULARIO EN EL USE-STATE
  const saveData = (name, value) => {
    setNominaEmpleadoData({ ...nominaEmpleadoData, [name]: value });
  };

  // * FUNCION PARA VALIDAR ARCHIVO EXCEL.
  const saveExcel = (e) => {
    const archivo = e.value;

    // * OBTENER EXTENSIÓN DEL ARCHIVO PARA REALIZAR VALIDACIONES.
    let extension = archivo.substring(archivo.lastIndexOf("."), archivo.length);

    // * CONDICIONAL PARA VALIDAR EXCEL.
    const validacionTipoArchivo =
      extension === ".xls" || extension === ".xlsx" || extension === ".csv"
        ? true
        : false;

    if (!validacionTipoArchivo) {
      swal(
        "Advertencia",
        "El formato del archivo no es válido. Intente subir un archivo de EXCEL.",
        "warning"
      );

      // * LIMPIANDO ARCHIVO EXCEL.
      refExcel.current.value = "";
      setValidacionValorInputExcel(true);
      return;
    }

    setValidacionValorInputExcel(false);
  };

  useEffect(() => {
    dispatch(fetchPeriodosSubirNomina());
  }, []);

  useEffect(() => {
    if (
      success === "success" &&
      listaEmpleadoNoEncontrados.length === 0 &&
      message?.length > 0
    ) {
      swal("Exitoso!", message, "success");
      clear();
    } else if (success === "error") {
      swal("Error", message, "error");
      clear();
    }
  }, [success]);

  // * ENVIAR DATA A LA API.
  const sendExcelApi = () => {
    const nomina = {
      ano: nominaEmpleadoData.ano,
      mes: nominaEmpleadoData.mes,
      archivo: refExcel.current.files[0],
    };

    dispatch(fetchSubirNomina(nomina));
  };

  const clear = () => {
    // * RESTURANDO VALOR INICIAL
    setNominaEmpleadoData({
      ano: listadoPeriodos[0]?.fecha ?? ano,
      mes: meses.find(x => x.noEntero === mes).no,
    });

    // * LIMPIANDO INPUT
    refExcel.current.value = "";

    // * REALIZANDO RESET EN LA LISTA.
    dispatch(fetchClearEmpleadosNoEncontrados());
    clearSuccess();
    setValidacionValorInputExcel(true);
  };

  return (
    <Encabezado title="CARGAR NÓMINA">
      <div className="p-3">
        <div className="col-3">
          <div className="form-group">
            <Autocomplete
              id="periodo"
              name="periodo"
              className="mb-3"
              disablePortal
              disableClearable
              getOptionLabel={(listadoPeriodos) => `${listadoPeriodos.fecha}`}
              options={listadoPeriodos}
              value={{ fecha: nominaEmpleadoData.ano }}
              isOptionEqualToValue={(option, value) =>
                option.fecha === value.fecha
              }
              noOptionsText={"No encontrado"}
              renderOption={(props, listadoPeriodos) => (
                <Box
                  component="option"
                  {...props}
                  key={listadoPeriodos.fecha}
                  value={listadoPeriodos.fecha}
                >
                  {listadoPeriodos.fecha}
                </Box>
              )}
              renderInput={(params) => <TextField {...params} label={" Año"} />}
              onChange={(e) => saveData("ano", e.target.value)}
              onKeyUp={(e) => saveData("ano", e.target.value)}
            />
          </div>
        </div>

        <div className="col-3">
          <div className="form-group">
            <Autocomplete
              id="meses"
              className="mb-2"
              disablePortal
              disableClearable
              getOptionLabel={(meses) => `${meses.nombre}`}
              options={meses}
              isOptionEqualToValue={(option, value) => option.no === value.no}
              noOptionsText={"No encontrado"}
              value={meses.find((x) => x.no === nominaEmpleadoData.mes)}
              renderOption={(props, meses) => (
                <Box
                  component="option"
                  {...props}
                  key={meses.no}
                  value={meses.no}
                >
                  {meses.nombre}
                </Box>
              )}
              onChange={(e) => saveData("mes", e.target.value)}
              onKeyUp={(e) =>
                saveData(
                  "mes",
                  meses.find((x) => x.nombre === e.target.value)?.no ?? 0
                )
              }
              renderInput={(params) => <TextField {...params} label={"Mes"} />}
            />
          </div>
        </div>

        <div className="col-3">
          <div className="mb-3">
            <label for="formFile" className="form-label">
              Cargar Nómina
            </label>
            <input
              ref={refExcel}
              className="form-control"
              accept=".xls,.xlsx,.csv"
              required
              type="file"
              id="formFile"
              onChange={(e) => saveExcel(e.target)}
            />
          </div>
        </div>

        <div className="col-3">
          <div className="text-end">
            <button
              id="btn-subir-nomina"
              className={
                validacionValorInputExcel
                  ? "btn btn-primary-mem disabled"
                  : "btn btn-primary-mem"
              }
              onClick={() => sendExcelApi()}
            >
              Subir <FileUploadIcon sx={{ marginTop: -0.5 }} />
            </button>
          </div>
        </div>
      </div>

      <Loading open={isLoading} />

      <ModalAlertaCargaNomina
        open={listaEmpleadoNoEncontrados?.length > 0 ? true : false}
        listaEmpleadoNoEncontrados={listaEmpleadoNoEncontrados}
        clear={clear}
      />
    </Encabezado>
  );
};
