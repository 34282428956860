import React, { useEffect, useState } from 'react'
import { EvaluadoDetalle } from '../../../Components/EvaluacionDesempeño/DetallesEmpleadoEvaluado/EvaluadoDetalle'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardHeader } from '@mui/material';
import { EvaluacionTabs } from '../../../Components/EvaluacionDesempeño/Tabs/EvaluacionTabs';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { 
    fetchObtenerEvaluacionEmpleadoHash,
    fetchObtenerCompetenciasComportamientosEvaluacionById,
    fetchEvaluacionMetasCrear,
    fetchEvaluacionMetasActualizar,
    fetchEvaluacionMetasEliminar,
    fetchEvaluacionMetasObtener,
    fetchEvaluacionMetasAprobar,
    fetchEvaluacionMetasAprobadasObtenerEstado,
    fetchObtenerPlanMejora,
    fetchCambiarSuccess, 
    selectEvaluacionEmpleadoHash,
    selectEvaluacionLogroMetasEmpleado,
    selectEvaluacionLogroMetasEstado,
    selectIsSuccess,
    selectMessage,
    selectCompetenciasComportamientos,
    selectIsLoading
} from '../../../redux/Evaluaciones/EvaluacionesSlice';

import { selectUserData } from "../../../redux/Login/LoginSlice";

import swal from 'sweetalert';
import Loading from '../../../Components/Modals/Loading/Loader';

export const EvaluacionDesempeñoEmpleado = () => {

    // * PARAMETRO A TRAVES DE LA RUTA.
    const { hash } = useParams();

    // * FUNCION PARA REFRESCAR LAS LISTAS.
    const refreshListMetas = () => dispatch(fetchEvaluacionMetasObtener(evaluacionEmpleadoDetalleHash.evaluacionId));
    const refreshHash = () => dispatch(fetchObtenerEvaluacionEmpleadoHash(hash));
    const refreshStateListMetas = () => dispatch(fetchEvaluacionMetasAprobadasObtenerEstado(evaluacionEmpleadoDetalleHash.evaluacionId));
    const refreshPlanDeMejora = () => dispatch(fetchObtenerPlanMejora(evaluacionEmpleadoDetalleHash.evaluacionId));
    const refreshCompetenciasComportamientos = () => dispatch(fetchObtenerCompetenciasComportamientosEvaluacionById(evaluacionEmpleadoDetalleHash.evaluacionId));

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const evaluacionEmpleadoDetalleHash = useSelector(selectEvaluacionEmpleadoHash);
    const listaEvaluacionesLogroMetasUsuario = useSelector(selectEvaluacionLogroMetasEmpleado);
    const estadoEvaluacionLogroMetas = useSelector(selectEvaluacionLogroMetasEstado);
    const listaCompetenciasComportamientos = useSelector(selectCompetenciasComportamientos);
    const isSuccess = useSelector(selectIsSuccess);
    const message = useSelector(selectMessage);
    const isLoading = useSelector(selectIsLoading)

    // ? HOOK SELECTOR
    const usuario = useSelector(selectUserData);

    // * DESESTRUCTURACION DE DATOS
    const { estado = 0 } = evaluacionEmpleadoDetalleHash;

    // ? HOOK-STATE

    // ? LOGRO DE METAS.
    const [logroMetasAdd, setLogroMetasAdd] = useState({
        resultadoId: 0,
        que: "",
        metasPlanOperativo: "",
        cuanto: 0,
        cuando: new Date(),
        ponderacion: 0,
        calificacion: 0,
        medioVerificacion: "",
        evaluacionId: 0,
        observaciones: ''
    });

    // ? LISTA DE COMPETENCIAS-MODIFICADAS
    const [listaCompetenciasComportamientosMod, setListaCompetenciasComportamientosMod] = useState([]);

    // ? STATUS
    const [addRequestStatus, setAddRequestStatus] = useState('idle');

    // ? HOOK-STATE PARA VALIDAR UN CAMPO EDITABLE.
    const [editEnabled, setEditEnabled] = useState(false);
    
    // ? HOOK-STATE PARA VALIDAR PONDERACION SEGUN EL GRUPO ORGANIZACIONAL.
    const [ponderacionGrupoOrganizacional, setPonderacionGrupoOrganizacional] = useState(0);

    // ? HOOK-STATE PARA VALIDAR SI EL BOTON DE APROBACION SE OCULTARA O NO.
    const [validacionEstadoLogroMetas, setValidacionEstadoLogroMetas] = useState(false);

    // ? HOOK-STATE PARA VALIDAR SI EL USUARIO LOGEADO ES EL MISMO QUE EL EVALUADOR, PERMITIRA
    // ? EDITAR O AGREGAR EVALUACION.
    const [validatioUserLoginEvaluador, setValidatioUserLoginEvaluador] = useState(false);

    // ? HOOK-STATE PARA GUARDAR LA SUMATORIA DE LOS COMPORTAMIENTOS-COMPETENCIAS
    const [sumatoriaComportamientosCompetencias, setSumatoriaComportamientosCompetencias] = useState(0);

    // ? HOOK-EFFECT
    useEffect(() => {
        refreshHash();
    }, [hash])

    useEffect(() => {
        if(evaluacionEmpleadoDetalleHash.evaluacionId !== undefined){
            setLogroMetasAdd({...logroMetasAdd, ['evaluacionId']: evaluacionEmpleadoDetalleHash.evaluacionId});
            setPonderacionGrupoOrganizacional(evaluacionEmpleadoDetalleHash.grupoOcupacionalId === 5 ? 65 : 55);
            refreshStateListMetas();
            refreshListMetas();
            refreshPlanDeMejora();
            refreshCompetenciasComportamientos();
        }

    }, [evaluacionEmpleadoDetalleHash, hash])

    useEffect(() => {
        setValidacionEstadoLogroMetas(estadoEvaluacionLogroMetas?.estado === 1 ? true : false);
    }, [estadoEvaluacionLogroMetas, listaEvaluacionesLogroMetasUsuario])
    

    useEffect(() => {

        // * FUNCION QUE GUARDA LA DATA DE LOGRO DE METAS, REALIZA TANTO EL UPDATE COMO EL CREATE.
        if(addRequestStatus === 'success'){

            // * ACTUALIZAR ESTADO.
            setAddRequestStatus('idle');

            // * LISTA APLICANDO FILTROS DEL RESULTADO ID PARA EXTRAER EL TOTAL DE LA PONDERACION DE METAS.
            const resultList = listaEvaluacionesLogroMetasUsuario.filter(x => x.resultadoId !== logroMetasAdd.resultadoId)
            .reduce((cont, item) => cont + item?.ponderacion, 0)

            // * LISTA APLICANDO FILTROS DEL RESULTADO ID PARA EXTRAER EL TOTAL DE LA CALIFICACION DE METAS.
            const resultListCalificacion = listaEvaluacionesLogroMetasUsuario.filter(x => x.resultadoId !== logroMetasAdd.resultadoId)
            .reduce((cont, item) => cont + item?.calificacion, 0)

            // * TOTALES
            const totalLogroMetas = resultList + parseInt(logroMetasAdd.ponderacion);
            const totalLogroMetasCalificacion = resultListCalificacion + parseInt(logroMetasAdd.calificacion);

                // * REALIZAR ALERTA EN CASO DE EXCEDER EL LIMITE.
                if(totalLogroMetas > ponderacionGrupoOrganizacional || totalLogroMetasCalificacion > ponderacionGrupoOrganizacional || logroMetasAdd.calificacion > logroMetasAdd.ponderacion){
                    swal("Error", "La ponderación sobrepasa el límite, favor de digitar otro valor.", "error");
                    return;
                }

                // * VALIDACION PARA NO ENVIAR DATA NULA.
                if(logroMetasAdd.que.length <= 0 || logroMetasAdd.medioVerificacion.length <= 0 || parseInt(logroMetasAdd.ponderacion) < 1 || logroMetasAdd.ponderacion === '') {
                
                    swal("Error", "Los campos de Metas del Servidor y Medios de Verificación son requeridos.", "error");
                    return;
                
                }
                    

                // * REALIZAR PETICION A LA API.
                if(logroMetasAdd.resultadoId > 0){
                    dispatch(fetchEvaluacionMetasActualizar(logroMetasAdd));
                }
                else{
                    dispatch(fetchEvaluacionMetasCrear(logroMetasAdd));
                }

            // * LIMPIAR STATE
            setLogroMetasAdd({
                resultadoId: 0,
                que: "",
                metasPlanOperativo: "",
                cuanto: 0,
                cuando: new Date(),
                ponderacion: 0,
                calificacion: 0,
                medioVerificacion: "",
                evaluacionId: evaluacionEmpleadoDetalleHash.evaluacionId,
                observaciones: ''
            });

             setEditEnabled(false);
        }
        
    }, [addRequestStatus])

    useEffect(() => {

        if(isSuccess === 'success' && message?.length > 0){
            
            swal("Exitoso!", message, "success")

            dispatch(fetchCambiarSuccess());
            refreshListMetas();
            refreshStateListMetas();
            refreshHash();
            refreshPlanDeMejora();
        } else if(isSuccess === 'error'){
            swal("Error", 'Por favor, comunicarse con el departamento de Desarrollo e Implementación de Sistemas.', "error");
            dispatch(fetchCambiarSuccess());
        }

    }, [isSuccess])


    // * FUNCION PARA ELIMINAR UN LOGRO DE METAS DE X EVALUACION.
    const deleteLogroMetas = (item) => {
        swal({
            title: 'Eliminar',
            text: 'Estás seguro/a de eliminar este logro de meta?',
            icon: 'warning',
            buttons: ['No', 'Si']
        }).then(respuesta => {
            if(respuesta){
                dispatch(fetchEvaluacionMetasEliminar(item));
            }
        })
    }

    // * FUNCION PARA APROBAR UNA EVALUACION.
    const aprobarLogroMetas = () => {
                
        swal({
            title: 'Aprobar',
            text: 'Estás seguro/a de aprobar el logro de meta?',
            icon: 'warning',
            buttons: ['No', 'Si']
        }).then(respuesta => {
            if(respuesta){
               
                const data = {
                    evaluadorId: evaluacionEmpleadoDetalleHash.evaluadorId,
                    evaluacionId: evaluacionEmpleadoDetalleHash.evaluacionId,
                    estado: 0,
                    fechaAprobacion: new Date()
                }
        
                dispatch(fetchEvaluacionMetasAprobar(data));  
            }
        })

    }

    useEffect(() => {
        setValidatioUserLoginEvaluador(evaluacionEmpleadoDetalleHash?.evaluadorId === parseInt(usuario?.Id));
    }, [evaluacionEmpleadoDetalleHash])

    useEffect(() => {
      setListaCompetenciasComportamientosMod(listaCompetenciasComportamientos);
    }, [listaCompetenciasComportamientos])

    useEffect(() => {

        // * LISTA APLICANDO FILTROS DEL RESULTADO ID PARA EXTRAER EL TOTAL DE LA CALIFICACION DE METAS.
        const resultCompetencias = listaCompetenciasComportamientosMod
        .reduce((total, item) => {
            if (Array.isArray(item.listaComportamientos)) { // Verificamos que listaComportamientos sea un array
            const sumaCalificaciones = item.listaComportamientos
                .filter(comportamiento => comportamiento.calificacion !== null && comportamiento.calificacion !== undefined) // Filtramos las calificaciones no nulas ni indefinidas
                .reduce((subtotal, comportamiento) => {
                const calificacion = Number(comportamiento.calificacion); // Convertimos a número
                return !isNaN(calificacion) ? subtotal + calificacion : subtotal; // Solo sumamos si es un número válido
                }, 0);
            return total + sumaCalificaciones; // Sumamos las calificaciones de cada listaComportamientos
            }
            return total;
        }, 0);

       setSumatoriaComportamientosCompetencias(resultCompetencias);
      
    }, [listaCompetenciasComportamientosMod])

    
  return (
    <div className="container-fluid main">
        <div className="row mt-2">
            <div className="col-xl-12">
                
                <EvaluadoDetalle empleado={evaluacionEmpleadoDetalleHash} />

                <div className='d-flex justify-content-center mb-5'>
                 
                    <Card sx={{ width: '13vw', marginRight: 2 }}>

                        <CardHeader sx={{backgroundColor: '#003876'}} title={<Typography color='white' variant='h6' align='center'> {evaluacionEmpleadoDetalleHash?.logrodeMetas}/{ponderacionGrupoOrganizacional}</Typography>}>
                            
                        </CardHeader>

                        <CardContent>
                            <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="#003876" gutterBottom>
                                Logro de Metas
                            </Typography>
                        </CardContent>

                    </Card>

                   {
                        evaluacionEmpleadoDetalleHash?.periodo > 2024 && 
                        
                            <Card sx={{ width: '13vw', marginRight: 2 }}>

                                <CardHeader sx={{backgroundColor: '#003876'}} title={<Typography color='white' variant='h6' align='center'> {Math.round(sumatoriaComportamientosCompetencias, 3)}/{evaluacionEmpleadoDetalleHash?.grupoOcupacionalId === 5 ? 35 : 45}</Typography>}>
                                    
                                </CardHeader>

                                <CardContent>
                                    <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="#003876" gutterBottom>
                                        Competencias
                                    </Typography>
                                </CardContent>

                            </Card>
                   }

                    {
                        evaluacionEmpleadoDetalleHash?.periodo < 2023 && 

                        <>
                             <Card sx={{ width: '13vw', marginRight: 2 }}>

                                <CardHeader sx={{backgroundColor: '#003876'}} title={<Typography color='white' variant='h6' align='center'>{evaluacionEmpleadoDetalleHash?.competencias}/30</Typography>}>
                                    
                                </CardHeader>

                                <CardContent>
                                    <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="#003876" gutterBottom>
                                        Competencias
                                    </Typography>
                                </CardContent>

                            </Card>

                            <Card sx={{ width: '13vw', marginRight: 2 }}>

                                <CardHeader sx={{backgroundColor: '#003876'}} title={<Typography color='white' variant='h6' align='center'>{evaluacionEmpleadoDetalleHash?.regimenEtico}/15</Typography>}>
                                    
                                </CardHeader>

                                <CardContent>
                                    <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="#003876" gutterBottom>
                                        Régimen ético y disciplinario
                                    </Typography>
                                </CardContent>

                            </Card>
                        </>
                    }

                   {
                    
                    evaluacionEmpleadoDetalleHash?.periodo < 2025 && 
                        <Card sx={{ width: '13vw', marginRight: 2 }}>

                            <CardHeader sx={{backgroundColor: '#003876'}} 
                                title={<Typography color='white' variant='h6' align='center'>{evaluacionEmpleadoDetalleHash?.periodo < 2023 ? evaluacionEmpleadoDetalleHash?.total 
                                : Math.round((evaluacionEmpleadoDetalleHash?.total * 100 / ponderacionGrupoOrganizacional))}/100</Typography>}>
                            </CardHeader>

                            <CardContent>
                                <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="#003876" gutterBottom>
                                    Total
                                </Typography>
                            </CardContent>
                            
                        </Card>
                   }

                    {   
                        evaluacionEmpleadoDetalleHash?.periodo > 2024 && 
                            <Card sx={{ width: '13vw', marginRight: 2 }}>

                                <CardHeader sx={{backgroundColor: '#003876'}} 
                                    title={<Typography color='white' variant='h6' align='center'>{(Math.round(sumatoriaComportamientosCompetencias, 3) ?? 0) + evaluacionEmpleadoDetalleHash?.logrodeMetas}/100</Typography>}>
                                </CardHeader>

                                <CardContent>
                                    <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="#003876" gutterBottom>
                                        Total
                                    </Typography>
                                </CardContent>
                                
                            </Card>
                   }

                </div>

                <div>

                    <EvaluacionTabs 
                    grupoOcupacionalId={evaluacionEmpleadoDetalleHash.grupoOcupacionalId}
                    estado={estado}
                    periodo={evaluacionEmpleadoDetalleHash.periodo} 
                    logroMetasAdd={logroMetasAdd}
                    setLogroMetasAdd={setLogroMetasAdd}
                    listLogroMetasUsuario={listaEvaluacionesLogroMetasUsuario}
                    setAddRequestStatus={setAddRequestStatus}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                    deleteLogroMetas={deleteLogroMetas}
                    aprobarLogroMetas={aprobarLogroMetas}
                    validacionEstadoLogroMetas={validacionEstadoLogroMetas}
                    ponderacionGrupoOrganizacional={ponderacionGrupoOrganizacional}
                    evaluacionId={evaluacionEmpleadoDetalleHash.evaluacionId}
                    validatioUserLoginEvaluador={validatioUserLoginEvaluador}
                    hash={hash}
                    listaCompetenciasComportamientosMod={listaCompetenciasComportamientosMod}
                    setListaCompetenciasComportamientosMod={setListaCompetenciasComportamientosMod}
                    />

                </div>

                <Loading open={isLoading} />

            </div>
        </div>
    </div>
  )
}
