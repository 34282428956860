import React,{useEffect,useState} from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import BorderColor from '@mui/icons-material/BorderColor';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import {useDispatch,useSelector} from "react-redux"
import {selectAllEstudiosNiveles,fetchEstudiosNiveles,getUtilityIsLoading} from './../../../redux/Utility/UtilitySlice'
import { updateEmpleadoEstudios,selectEmpleadosIsLoading,selectEmpleadosIsSuccess,selectEmpleadosMessage } from '../../../redux/Empleados/EmpleadosSlice';
import swal from 'sweetalert';
import Loading from '../Loading/Loader';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};

const EstudiosEditar = ({openEstudiosEditar,setOpenEstudiosEditar,empleadoId,estudio}) => {
    const {register,handleSubmit, formState:{errors},control,setValue } =useForm({defaultValues:estudio});
    const dispatch = useDispatch();
    const estudiosNiveles = useSelector(selectAllEstudiosNiveles);
    const isLoading = useSelector(getUtilityIsLoading);
    const isSuccess = useSelector(selectEmpleadosIsSuccess);
    const isLoadingEmpleado = useSelector(selectEmpleadosIsLoading)
    const empleaodMessage = useSelector(selectEmpleadosMessage)
    const [addRequestStatus,setAddRequestStatus] = useState('idle');
    const [openLoading,setOpenLoading] = useState(false);

    const onSubmit = (data)=>{
        setAddRequestStatus('success');
        dispatch(updateEmpleadoEstudios(data));
    }
    const handleClose=()=>{
        setOpenEstudiosEditar(false);
    }
    useEffect(()=>{
        dispatch(fetchEstudiosNiveles());
       
    },[])
    useEffect(()=>{
        setValue("empleadoId",Number(empleadoId));
    },[empleadoId])

    useEffect(()=>{

        if(isSuccess==='success' && addRequestStatus==='success'){
			swal("Exitoso!", "Fue agregado el dependiente", "success");
            handleClose();
			setAddRequestStatus('idle');
		}else if(isSuccess==='error'&& addRequestStatus==='success'){
			swal("Error!", empleaodMessage, "error");
			setAddRequestStatus('idle');
		}
    },[isSuccess,addRequestStatus])

    useEffect(()=>{
        if(!isLoading && !isLoadingEmpleado)
        {
            setOpenLoading(false)
        }else{
            setOpenLoading(true)
        }

    },[isLoading,isLoadingEmpleado])

  return (
    <Modal
    open={openEstudiosEditar}
    onClose={handleClose}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
>
    <Box sx={{ ...style, width: 700 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
            <div className="row">
                <h3 className="text-primary-mem text-center">Estudios</h3>
            </div>
            <div className="row">
                <div className="col-5 me-0 pe-0">
                    <hr />
                </div>
                <div className="col-2 text-center">
                    <BorderColor className='text-primary-mem'></BorderColor>
                </div>
                <div className="col-5 ms-0 ps-0 ">
                    <hr />
                </div>
            </div>
            <div className='row'>
                <div hidden >
                    <input {...register("empleadoId")}></input>
                </div>
                <div className='form-group'>
                    <label className='fw-bold'>Nivel</label>
                    <select {...register("estudioNivelId",{required:"El parentesco es requerido"})} className='form-control'>
                        <option value={""}>Seleccionar parentesco</option>
                        {
                            estudiosNiveles && estudiosNiveles.length>0 &&(
                                estudiosNiveles.map(nivel => <option key={nivel.id} value={Number(nivel.id)}>{nivel.nombre}</option>)
                            )                        
                        }
                       
                    </select>
                    <p className='text-danger'>{errors.estudioNivelId?.message}</p>
                </div>
                <div className='form-group'>
                    <label className='fw-bold'>Titulo Obtenido</label>
                   <input type="text" {...register("titulo",{required:"El titulo es requerido"})} className="form-control"></input>
                   <p className='text-danger'>{errors.titulo?.message}</p>
                </div>
                <div className='form-group'>
                    <label className='fw-bold'>Estado</label>
                    <select {...register("estado",{required:"El sexo es requerido"})} className='form-control'>
                        <option value="">Seleccionar estado</option>
                        <option value="En curso">En curso</option>
                        <option value="Terminado">Terminado</option>
                       
                    </select>
                    <p className='text-danger'>{errors.estado?.message}</p>
                </div>
                <div className="form-group">
											<label className="fw-bold">Fecha de Obtención</label>
											<Controller
												control={control}
                                                className="mb-3"
												name="ReactDatepicker"
												{...register('fecha')}
												render={({ field }) => (
													<LocalizationProvider dateAdapter={AdapterDateFns}>
														<DatePicker
															label={' '}
															mask="__/__/____"
															value={field.value}
															onChange={(e) => field.onChange(e)}
															renderInput={(params) => (
																<TextField
																	height={10}
																	className="form-control"
																	style={{ height: 10 }}
																	{...params}
																/>
															)}
														/>
													</LocalizationProvider>
												)}
											/>
                                             <p className='text-danger mt-3'>{errors.fechaObtencion?.message}</p>
				</div>
                <div className='form-group'>
                    <label className='fw-bold'>Institución Académica</label>
                   <input type="text" {...register("institucionAcademica",{required:"La institución es requerida"})} className="form-control"></input>
                   <p className='text-danger'>{errors.institucionAcademica?.message}</p>
                </div>
                <div className='d-flex justify-content-center'>
                <button type='submit' className='btn btn-primary-mem me-3'>Guardar</button>
                <button type='button' className='btn btn-danger ' onClick={handleClose}>Cancelar</button>
                </div>
            </div>
        </div>
        </form>
        <Loading open={openLoading}></Loading>
    </Box>
   
</Modal>
  )
}

export default EstudiosEditar