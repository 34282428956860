import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Divider from '@mui/material/Divider';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  TextField,
} from '@mui/material';

import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { 
  fetchBonoEscolarEmpleadoEditar
} from '../../../redux/Beneficios/BonoEscolar/BonoEscolarSlice'

export const EditarBonoEscolarEmpleadoModal = ({open = false, setOpen, setBeneficiario, beneficiario}) => {

  // ? USE-DISPATCH
  const dispatch = useDispatch();

  // ? USE-STATE
  const [beneficiarioMod, setBeneficiarioMod] = useState({
    beneficiarioBonoEscolarId: 0,
    bonoEscolarId: 0,
    composicionFamiliarId: 0,
    empleadoId: 0,
    montoMensual: 0,
    cantidadMeses: 0,
    pagoUnico: 0
  });

  // ? HOOK-EFFECT
  useEffect(() => {
    setBeneficiarioMod({
      beneficiarioBonoEscolarId: beneficiario?.beneficiarioBonoEscolarId ?? 0,
      bonoEscolarId: beneficiario?.bonoEscolarId ?? 0,
      composicionFamiliarId: beneficiario?.composicionFamiliarId ?? 0,
      empleadoId: beneficiario?.empleadoId ?? 0,
      montoMensual: beneficiario?.montoPorMes ?? 0,
      cantidadMeses: beneficiario?.cantidadMeses ?? 0,
      pagoUnico: beneficiario?.pagoUnico ?? 0
    })
  }, [beneficiario])
  
  
  // * FUNCION PARA GUARDAR LA DATA.
  const saveDataBeneficiario = (e) => {
    let {value, name} = e.target;

    if(name === 'pagoUnico'){
      value = parseInt(value) === 1 ? 0 : 1;
    }
    setBeneficiarioMod({...beneficiarioMod, [name]: value});
  }

  // * FUNCION USADA EN EL BOTON CANCELAR.
  const cancelAction = () => {
    setBeneficiario({});
    setOpen(false);
  }

  // * FUNCION PARA ENVIAR LA DATA A LA API.
  const sendDataApi = () => {
    dispatch(fetchBonoEscolarEmpleadoEditar(beneficiarioMod));
  }

  return (
    <Dialog
      PaperProps={{ sx: { width: "27%", height: "52%" } }}
      open={open}
      maxWidth='lg'
      >

        <DialogTitle sx={{textTransform: 'uppercase'}} className='text-mem text-center'>Beneficiario bono escolar</DialogTitle>
        <Divider />

        <DialogContent>

          <Typography>Bono escolar</Typography>
          <input sx={{width: '100%'}} value={beneficiarioMod?.bonoEscolarId} name='bonoEscolarId' className='mb-2 disabled form-control' disabled />

          <Typography>No. Composición familiar</Typography>
          <input sx={{width: '100%'}} value={beneficiarioMod?.composicionFamiliarId} name='composicionFamiliarId' className='mb-2 disabled form-control' disabled />

          <Typography>Monto mensual</Typography>
          <TextField label=" " value={beneficiarioMod?.montoMensual} onChange={saveDataBeneficiario} variant="outlined" name='montoMensual' sx={{width: '100%'}} className='mb-2' />
          
          <Typography>Cantidad de meses</Typography>
          <TextField label=" " value={beneficiarioMod?.cantidadMeses} onChange={saveDataBeneficiario} name='cantidadMeses' variant="outlined" sx={{width: '100%'}} className='mb-2' />

          <FormControlLabel name='pagoUnico' onChange={saveDataBeneficiario} control={<Checkbox checked={beneficiarioMod?.pagoUnico === 1 && true} value={beneficiarioMod?.pagoUnico} />} label="Pago único" className='p-0 mb-1' />
            
        </DialogContent>

        <DialogActions className='justify-content-center'>
          <button onClick={() => cancelAction()} className='btn btn-danger'><DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}} />CANCELAR</button>
          <button onClick={() => sendDataApi()} className='btn btn-primary-mem'><SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}} />GUARDAR</button>
        </DialogActions>

    </Dialog>
  )
}
