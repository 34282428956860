export const camposReporteEmpleados = [

    {
        campo: 'nombres',
        label: 'Nombres'
    },

    {
        campo: 'codigoEmpleado',
        label: 'Código'
    },

    {
        campo: 'cedula',
        label: 'Cédula'
    },

    {
        campo: 'sexo',
        label: 'Sexo'
    },

    {
        campo: 'areaOrganizacional',
        label: 'Área Organizacional'
    },

    {
        campo: 'cargo',
        label: 'Cargo'
    },

    {
        campo: 'cursos',
        label: 'Cursos'
    },

    {
        campo: 'supervisor',
        label: 'Supervisor'
    },

    {
        campo: 'cuentaBancaria',
        label: 'Cuenta Bancaria'
    },

    {
        campo: 'sueldo',
        label: 'Sueldo'
    },

    {
        campo: 'fechaIngreso',
        label: 'Fecha de Ingreso'
    },

    {
        campo: 'edad',
        label: 'Edad'
    },

    {
        campo: 'grupoOcupacional',
        label: 'Grupo Ocupacional'
    },

    {
        campo: 'correoInstitucional',
        label: 'Correo Institucional'
    },

    {
        campo: 'fechaNacimiento',
        label: 'Fecha Nacimiento'
    },

    {
        campo: 'celular',
        label: 'Celular'
    },

    {
        campo: 'flota',
        label: 'Flota',
    },

    {
        campo: 'fechaTerminoDestitucion',
        label: 'Fecha Termino',
    },

    {
        campo: 'motivoDestitucion',
        label: 'Motivo'
    }

]