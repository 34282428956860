import React from 'react'
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import {Add,BorderColor,Delete} from "@mui/icons-material";
import moment from 'moment';
import { diasSemana } from '../../utils/apiClient';

const Turno = ({turno,editarTurno,eliminarTurno}) => {
  return (
    <div className='d-flex justify-content-between'>
         <div className='col-4'>{diasSemana.find(x=> x.id==turno.dia).value }</div>
         <div className='col-6'>{`${moment(turno.horaInicioDate).format("hh:mm A")} - ${moment(turno.horaTerminoDate).format("hh:mm A")}`}</div>
         <div className='col-2'>
                   <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                       <IconButton id='btn-editar-turno' className='text-success'  onClick={()=>editarTurno(turno)}>
                           <BorderColor fontSize="30" />
                       </IconButton>
                       <IconButton id='btn-eliminar-turno' className='text-danger' onClick={()=>eliminarTurno(turno)}>
                           <Delete fontSize="30" />
                       </IconButton>
                   </Stack>
         </div>
    </div>
  )
}

export default Turno