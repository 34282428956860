import React from 'react';
import { BonoEscolarPage, BonoEscolarDetalleAno } from '../pages/Beneficios/Bono Escolar';
import { Route, Routes, Navigate } from "react-router";
import {RequiredPermission} from '../authentication/RequiredPermission';

export const RutasBeneficios = () => {
  return (
    <Routes>
      
      <Route element={<RequiredPermission permission='ROLE_VER_FERIADO'/>}> 
        <Route path="bonoescolar" element={<BonoEscolarPage />} />
        <Route path="bonoescolar/:ano" element={<BonoEscolarDetalleAno />} />
      </Route>

      <Route path='/*' element={<Navigate to="/" replace />} />

    </Routes>
  )
}
