import React from 'react'
import numbro from 'numbro';
export const DatosNombramientos = ({empleado}) => {

    const {sueldo, tipoEmpleado,programa} = empleado;
  return (
    <div className=" container mt-2 pt-3 pb-2 mb-2 border ">
    <div className="row ">
        <div className="col ">
            <div className="col-md-12 ">
                <label className="fw-bold">Sueldo</label>
            </div>
            <div className="col-md-12 ">
                {/* <label className="">RD$ {numbro(sueldo).format({thousandSeparated: true})}</label> */}
                <input type="text" readOnly className="form-control" defaultValue={`RD$ ${numbro(sueldo).format({thousandSeparated: true})}`}></input>
            </div>
        </div>
        <div className="col ">
            <div className="col-md-12 ">
                <label className="fw-bold">Tipo Empleado</label>
            </div>
            <div className="col-md-12 text-center">
                {/* <label className="">{tipoEmpleado.tipo}</label> */}
                <input type="text" readOnly className="form-control" defaultValue={tipoEmpleado.tipo}></input>
            </div>
        </div>
        <div className="col ">
            <div className="col-md-12 ">
                <label className="fw-bold">Programa</label>
            </div>
            <div className="col-md-12 text-center">
                {/* <label className="">{programa.programa}</label> */}
                <input type="text" readOnly className="form-control" defaultValue={programa.programa}></input>
            </div>
        </div>
    </div>
</div>
  )
}

export default DatosNombramientos