  import moment from "moment";
  
  // * FUNCION PARA CALCULAR LOS DIAS LABORALES.
  export const calcularDiasHabiles = ({fechaInicio, fechaTermino, diasFeriados, horaInicio, horaTermino, checkedDiaCompleto}) => {
    let start = moment(fechaInicio, "YYYY/MM/DD").clone();
    let end = moment(fechaTermino, "YYYY/MM/DD").clone();
    let diasHabiles = 0;
    
    // * Convertimos los días feriados a formato de momento para facilitar la comparación
    const feriados = diasFeriados.map(dia => moment(dia.fecha, "YYYY/MM/DD"));
    const listadoFechas = generarRangoDeFechas(start, end);        
    
    // * SI ES EL DIA COMPLETO QUE TOMAR EL USUARIO, EL RESULTADO SERA DE 0.
    if(start.isSame(end, 'day') && moment(horaInicio).hour() === 8 && moment(horaTermino).hour() === 16 && !checkedDiaCompleto){
      return 0;
    }

    // * Iterar desde la fecha de inicio hasta la fecha de término, asegurando contar ambos extremos
    for (let index = 0; index <= listadoFechas.length; index++) {

      // * Convertimos fechaValidar a objeto moment para usar isoWeekday
      const fechaValidar = moment(listadoFechas[index], "YYYY/MM/DD");

      const esDiaHabil = fechaValidar.isoWeekday() >= 1 && fechaValidar.isoWeekday() <= 5;
      const esFeriado = feriados.some(feriado => feriado.isSame(fechaValidar, 'day'));

      if (esDiaHabil && !esFeriado) {
        diasHabiles++;
      }

    }

    return diasHabiles;
  }
  
  // * FUNCION PARA GENERAR RANGO DE FECHAS
  const generarRangoDeFechas = (fechaInicio, fechaTermino) => {
    let start = moment(fechaInicio, "YYYY/MM/DD").clone();
    let end = moment(fechaTermino, "YYYY/MM/DD").clone();
    let rangoDeFechas = [];

    // Iterar desde la fecha de inicio hasta la fecha de término, incluyendo ambos extremos
    while (start.isBefore(end)) {
      rangoDeFechas.push(start.format("YYYY/MM/DD"));
      start.add(1, 'day');
    }
    
    // Aseguramos que el último día (fecha de término) se incluya en el rango
    rangoDeFechas.push(end.format("YYYY/MM/DD"));

    return rangoDeFechas;
  }
