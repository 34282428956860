import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Add, BorderColor, Delete } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

import Loading from "../Loading/Loader";
import {
  selectIsLoading,
  selectIsSuccess,
  selectMessage,
  addDiaFeriado,
} from "../../../redux/DiasFeriados/DiasFeriadosSlice";
import { set } from "date-fns";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const DiaFeriadoNuevo = ({ open, setOpen }) => {
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const isLoading = useSelector(selectIsLoading);
  const isSuccess = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = (diaFeriado) => {
    setAddRequestStatus("success");
    dispatch(addDiaFeriado(diaFeriado));
  };

  useEffect(() => {
    if (isSuccess === "success" && addRequestStatus === "success") {
      setAddRequestStatus("idle");
      handleClose();
      swal("Exito!", "Fue guardado el día feriado", "success");
    } else if (isSuccess === "error" && addRequestStatus === "success") {
      setAddRequestStatus("idle");
      swal("Error!", message, "error");
    }
  }, [isSuccess, addRequestStatus]);

  useEffect(() => {
    setValue("justificacion", "");
    setValue("fecha", new Date());
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 700, minHeight: 400, maxHeight: 700 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row">
              <h3 className="text-primary-mem text-center">
                Nuevo Día Feriado
              </h3>
            </div>
            <div className="row">
              <div className="col-5 me-0 pe-0">
                <hr />
              </div>
              <div className="col-2 text-center">
                <Add className="text-primary-mem"></Add>
              </div>
              <div className="col-5 ms-0 ps-0 ">
                <hr />
              </div>
            </div>
            <div className="row mb-3">
              <div className="form-group">
                <label className="fw-bold">Título</label>
                <input
                  className="form-control"
                  {...register("justificacion", {
                    required: "El titulo es un valor requerido",
                  })}
                  type="text"
                ></input>
              </div>
            </div>
            <div className="row mb-5">
              <div className="form-group">
                <label className="fw-bold">Fecha</label>
                <Controller
                  control={control}
                  name="ReactDatepicker"
                  {...register("fecha", {
                    required: "La fecha es requerida",
                  })}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={" "}
                        mask="__/__/____"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        renderInput={(params) => (
                          <TextField className="form-control" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center align-self-end">
              <button className="btn btn-primary-mem me-3">Guardar</button>
              <button
                type="button"
                className="btn btn-danger "
                onClick={handleClose}
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
        <Loading open={isLoading}></Loading>
      </Box>
    </Modal>
  );
};

export default DiaFeriadoNuevo;
