import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Typography } from "@mui/material";

import { 
  FaArrowCircleLeft,
  FaArrowCircleRight 
} from "react-icons/fa";

import { BiSave } from "react-icons/bi";

import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import IconButton from "@mui/material/IconButton";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { opcionesBotonesCalificacion } from "../../../utils/ponderacionEvaluacion";

export const CompetenciaComportamientosPage = (
  {
    estado,
    listaCompetenciasComportamientosModSeleccionado, 
    listaCompetenciasComportamientosMod, 
    setListaCompetenciasComportamientosMod,
    setConfirmacionEnviarData,
    stepPosition, 
    setStepPosition
  }) => {

  const [checked, setChecked] = useState(false);
  const [botonesCalificacion, setBotonesCalificacion] = useState([])

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1.5),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

   // ? HOOK-FORM PARA EL FORMULARIO HISTORIAL-CLINICO.
   const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    defaultValues: {
      comportamientos: []
    }
  });

  // ? HOOK-WATCH
  const comportamientosForm = useWatch({
    control,
    name: "comportamientos"
  });

  const sendData = () => {

    let arr = {...listaCompetenciasComportamientosModSeleccionado, listaComportamientos: comportamientosForm};

    // Actualizamos el estado reemplazando el objeto con el ID correspondiente
    setListaCompetenciasComportamientosMod((prevLista) =>
      prevLista.map((item) =>
        item.competenciaId === arr.competenciaId ? { ...arr } : item
      )
    );    

    if((listaCompetenciasComportamientosMod?.length - 1) === stepPosition){
      setConfirmacionEnviarData(true);
    }
    else{
      setStepPosition(stepPosition + 1);
    }

  }

  useEffect(() => {
    
    if(listaCompetenciasComportamientosModSeleccionado?.listaComportamientos?.length > 0){
      setValue('comportamientos', listaCompetenciasComportamientosModSeleccionado.listaComportamientos);

      let opciones = opcionesBotonesCalificacion;

      for (let i = 0; i < listaCompetenciasComportamientosModSeleccionado.listaComportamientos.length; i++) {
        
        const { valor } = listaCompetenciasComportamientosModSeleccionado.listaComportamientos[i];

        opciones[i].ponderacion = (valor / 4) *  (4 - i);
      }

      setBotonesCalificacion(opciones);
    }

  }, [listaCompetenciasComportamientosModSeleccionado])
  
  return (
    <>
      <Grid>
        <Grid>
          <Typography variant="h6">
              {listaCompetenciasComportamientosModSeleccionado?.competencia}           
            <IconButton onClick={() => setChecked(!checked)}>
              <HelpRoundedIcon sx={{ marginTop: -0.5, color: "#003876" }} />
            </IconButton>
          </Typography>

          <Grid hidden={!checked} className="animate__animated animate__fadeIn">
            <Typography variant="p" fontSize={14}>
              {listaCompetenciasComportamientosModSeleccionado?.descripcion}      
            </Typography>
          </Grid>

          <hr />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={20}>
          <Item elevation={4}>
            <Box
              sx={{
                fontSize: "14px",
                textTransform: "uppercase",
                textAlign: "initial",
              }}
            >
              COMPORTAMIENTOS
            </Box>

            <Divider />

            <form onSubmit={handleSubmit(sendData)}>

            {listaCompetenciasComportamientosModSeleccionado?.listaComportamientos.map((item, fila) => (
              <Grid item sx={{ marginTop: 2 }}>
                <Item elevation={3} key={fila}>
                  <Grid container sx={{ paddingTop: 1 }}>
                    <Grid lg={6} md={4} sm={5}>
                      <Typography
                        fontSize={14}
                        sx={{
                          marginTop: 0.5,
                          color: "black",
                          textAlign: "initial",
                        }}
                      >
                        {item.comportamiento}
                      </Typography>
                    </Grid>

                    <Grid lg={6} md={8} xs={7} sx={{ paddingTop: -1 }}>
                      <div
                        class="btn-group btn-group-sm"
                        role="group"
                        aria-label="Basic example"
                      >
                        {botonesCalificacion.map((item2, columna) => (
                          
                          <>
                          
                            <input
                            type="hidden"
                            {...register(`comportamientos.${fila}.calificacion`, { required: "Este campo es requerido." })}
                            />

                            <button
                              onClick={estado !== 2 ? (
                              e) => {setValue(`comportamientos.${fila}.calificacion`,  parseFloat(e.target.value))} 
                                : () => { return; }}
                              type="button"
                              className={
                                comportamientosForm[fila].calificacion === item2.ponderacion
                                  ? "btn btn-outline-primary-mem active"
                                  : "btn btn-outline-primary-mem"
                              }
                              key={columna}
                              name={fila}
                              value={item2.ponderacion}
                            >
                              {item2.name}
                            </button>

                          </>
                         
                        ))}              
                      </div>
                    </Grid>

                    
                    <Grid xs className="d-flex justify-content-end">
                      {errors.comportamientos?.[fila]?.calificacion && (
                        <p className="text-danger">{errors.comportamientos[fila].calificacion.message}</p>
                      )}
                    </Grid>

                  </Grid>
                </Item>
              </Grid>
            ))}

              <div className="d-flex justify-content-center">
                <button hidden={stepPosition === 0 || estado === 2} onClick={() => setStepPosition(stepPosition - 1)} type="button" className="btn btn-primary-mem mt-3 me-2"> <FaArrowCircleLeft style={{marginBottom: 4}} /> Anterior</button>
                <button hidden={(listaCompetenciasComportamientosMod?.length - 1) === stepPosition || estado === 2} type="submit" className="btn btn-primary-mem mt-3">Siguiente <FaArrowCircleRight style={{marginBottom: 4}} /></button>
                <button hidden={(listaCompetenciasComportamientosMod?.length - 1) !== stepPosition || estado === 2} type="submit" className="btn btn-primary-mem mt-3">Guardar <BiSave style={{marginBottom: 3}} fontSize={22} /></button>
              </div>

            </form>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};
