import { useLocation, Navigate, Outlet } from "react-router";
import { useSelector } from "react-redux";
import { selectLogin } from "../redux/Login/LoginSlice";
import { useContext } from "react";
import { PermissionContext } from "../context/PermissionContext";

export const RequiredPermission = ({ permission }) => {
  const location = useLocation();

  // ? HOOK-SELECTOR.
  const {accessToken} = useSelector(selectLogin);

  // ? EXTRAYENDO LOS DATOS DEL CONTEXT DE TODOS LOS PERMISOS EN BOOLEAN.
  const value = useContext(PermissionContext);

  // * DESESTRUCTURACION DE LOS DATOS.
  const { permisosState } = value.value;

    return `${permisosState}?.${permission}`? 
        <Outlet />
        : accessToken !== null 
                ? 
                    <Navigate to="/noAutorizado" state={{ from: location }} replace />
                : 
                    <Navigate to="/login" state={{ from: location }} replace />  
};

export default RequiredPermission;
