import React,{useEffect,useState} from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Add from '@mui/icons-material/Add';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import {useDispatch,useSelector} from "react-redux"
import {selectAllIdiomas,fetchIdiomas,getUtilityIsLoading} from './../../../redux/Utility/UtilitySlice'
import { addNewEmpleadoIdioma,selectEmpleadosIsLoading,selectEmpleadosIsSuccess,selectEmpleadosMessage } from '../../../redux/Empleados/EmpleadosSlice';
import swal from 'sweetalert';
import Loading from '../Loading/Loader';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};

const IdiomaNuevo = ({openIdiomasNuevo,setOpenIdiomasNuevo,empleadoId}) => {
    const {register,handleSubmit, formState:{errors},control,setValue, reset } =useForm();
    const dispatch = useDispatch();
    const idiomas = useSelector(selectAllIdiomas);
    const isLoading = useSelector(getUtilityIsLoading);
    const isSuccess = useSelector(selectEmpleadosIsSuccess);
    const isLoadingEmpleado = useSelector(selectEmpleadosIsLoading)
    const empleaodMessage = useSelector(selectEmpleadosMessage)
    const [addRequestStatus,setAddRequestStatus] = useState('idle');
    const [openLoading,setOpenLoading] = useState(false);

    const onSubmit = (data)=>{
        setAddRequestStatus('success');
        dispatch(addNewEmpleadoIdioma(data));
    }
    const handleClose=()=>{
        setOpenIdiomasNuevo(false);
    }
    useEffect(()=>{
        dispatch(fetchIdiomas());
       
    },[])
    useEffect(()=>{
        setValue("empleadoId",Number(empleadoId));
    },[empleadoId])

    useEffect(()=>{

        if(isSuccess==='success' && addRequestStatus==='success'){
			swal("Exitoso!", "Fue agregado el idioma", "success");
            handleClose();
            reset({
                idiomaId:"",
                habla:"",
                escribe:"",
                lee:""
           });
			setAddRequestStatus('idle');
		}else if(isSuccess==='error'&& addRequestStatus==='success'){
			swal("Error!", empleaodMessage, "error");
			setAddRequestStatus('idle');
		}
    },[isSuccess,addRequestStatus])

    useEffect(()=>{
        if(!isLoading && !isLoadingEmpleado)
        {
            setOpenLoading(false)
        }else{
            setOpenLoading(true)
        }

    },[isLoading,isLoadingEmpleado])

  return (
    <Modal
    open={openIdiomasNuevo}
    onClose={handleClose}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
>
    <Box sx={{ ...style, width: 700 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
            <div className="row">
                <h3 className="text-primary-mem text-center">Idiomas</h3>
            </div>
            <div className="row">
                <div className="col-5 me-0 pe-0">
                    <hr />
                </div>
                <div className="col-2 text-center">
                <Add className='text-primary-mem'></Add>
                </div>
                <div className="col-5 ms-0 ps-0 ">
                    <hr />
                </div>
            </div>
            <div className='row'>
                <div hidden >
                    <input {...register("empleadoId")}></input>
                </div>
                <div className='form-group'>
                    <label className='fw-bold'>Idioma</label>
                    <select {...register("idiomaId",{required:"El idioma es requerido"})} className='form-control'>
                        <option value={""}>Seleccionar idioma</option>
                        {
                            idiomas && idiomas.length>0 &&(
                                idiomas.map(idioma => <option key={idioma.id} value={Number(idioma.id)}>{idioma.nombre}</option>)
                            )                        
                        }
                    </select>
                    <p className='text-danger'>{errors.idiomaId?.message}</p>
                </div>

                <div className='form-group'>
                    <label className='fw-bold'>Habla</label>
                    <select {...register("habla",{required:"El nivel hablado es requerido"})} className='form-control'>
                        <option value={""}>Seleccionar nivel hablado</option>
                        <option value="Básico">Básico</option>
                        <option value="Medio">Medio</option>
                        <option value="Avanzado">Avanzado</option>
                    </select>
                    <p className='text-danger'>{errors.habla?.message}</p>
                </div>
                <div className='form-group'>
                    <label className='fw-bold'>Escribe</label>
                    <select {...register("escribe",{required:"El nivel escrito es requerido"})} className='form-control'>
                        <option value={""}>Seleccionar nivel escrito</option>
                        <option value="Básico">Básico</option>
                        <option value="Medio">Medio</option>
                        <option value="Avanzado">Avanzado</option>
                    </select>
                    <p className='text-danger'>{errors.escribe?.message}</p>
                </div>
                <div className='form-group'>
                    <label className='fw-bold'>Lee</label>
                    <select {...register("lee",{required:"El nivel de lectura es requerido"})} className='form-control'>
                        <option value={""}>Seleccionar nivel lectura</option>
                        <option value="Básico">Básico</option>
                        <option value="Medio">Medio</option>
                        <option value="Avanzado">Avanzado</option>
                    </select>
                    <p className='text-danger'>{errors.lee?.message}</p>
                </div>

               
                <div className='d-flex justify-content-center'>
                <button type='submit' className='btn btn-primary-mem me-3' id='btn-guardar'>Guardar</button>
                <button type='button' className='btn btn-danger ' onClick={handleClose} id='btn-cancelar'>Cancelar</button>
                </div>
            </div>
        </div>
        </form>
        <Loading open={openLoading}></Loading>
    </Box>
   
</Modal>
  )
}

export default IdiomaNuevo