// * COMPONENTE QUE RETORNA UNA LISTA DE AÑOS DENTRO DE UN SELECT.

export const ListaDeAnos = ({listadoAnos}) => {
    return listadoAnos ? (
      listadoAnos.map((ano) => (
        <option key={ano} value={ano}>
          {ano}
        </option>
      ))
    ) : (
      <option value={new Date().getFullYear()}>
        {new Date().getFullYear()}
      </option>
    );
  };
