import React, { useState } from "react";
import { PermissionContext } from "./PermissionContext";
import CryptoJS from "crypto-js";

export const PermissionProvider = ({ children }) => {

  // * CODIGO PARA ENCRIPTAR Y DESENCRIPTAR.
  const secretPass = "Mk1N3lpkmoyI8P0g5";

  // * LISTA DE PERMISOS
  const initialStatePermisos = {
    ROLE_CREAR_EVALUACION: false,
    ROLE_EDITAR_EVALUACION: false,
    ROLE_BORRAR_EVALUACION: false,
    ROLE_VER_EVALUACION: false,
    ROLE_GENERAR_EVALUACIONES: false,
    ROLE_CREAR_PERIODO_EVALUACION: false,
    ROLE_EDITAR_PERIODO_EVALUACION: false,
    ROLE_BORRAR_PERIODO_EVALUACION: false,
    ROLE_VER_PERIODO_EVALUACION: false,
    ROLE_EVALUADOR: false,
    ROLE_VER_INFORMACION_PERSONAL: false,
    ROLE_EDITAR_INFORMACION_PERSONAL: false,
    ROLE_REALIZAR_SOLICITUDES: false,
    ROLE_VER_MIS_SOLICITUDES: false,
    ROLE_VER_AREAS_ORGANIZACIONALES: false,
    ROLE_EDITAR_AREAS_ORGANIZACIONALES: false,
    ROLE_CREAR_AREAS_ORGANIZACIONALES: false,
    ROLE_CREAR_CARGOS: false,
    ROLE_VER_CARGOS: false,
    ROLE_EDITAR_CARGOS: false,
    ROLE_VER_REGISTRO_EMPLEADOS: false,
    ROLE_EDITAR_REGISTRO_EMPLEADOS: false,
    ROLE_BORRAR_REGISTRO_EMPLEADOS: false,
    ROLE_BORRAR_CARGOS: false,
    ROLE_BORRAR_AREAS_ORGANIZACIONALES: false,
    ROLE_APROBAR_SOLICITUDES: false,
    ROLE_RECHAZAR_SOLICITUDES: false,
    ROLE_CREAR_PERIODO_BONIFICACION: false,
    ROLE_EDITAR_PERIODO_BONIFICACION: false,
    ROLE_CREAR_BONIFICACION: false,
    ROLE_ELIMINAR_BONIFICACION: false,
    ROLE_EDITAR_BONIFICACION: false,
    ROLE_VER_PERIODO_BONIFICACION: false,
    ROLE_VER_BONIFICACIONES: false,
    ROLE_VER_SOLICITUDES: false,
    ROLE_DESVINCULAR: false,
    ROLE_ADMIN: false,
    ROLE_CREAR_EMPLEADO: false,
    ROLE_ADMIN_EVALUACIONES: false,
    ROLE_VER_ESTADISTICAS: false,
    ROLE_VER_PERMISO: false,
    ROLE_EDITAR_APROBACIONE_PERMISO: false,
    ROLE_APROBAR_PERMISO: false,
    ROLE_EDITAR_TIPO_PERMISO: false,
    ROLE_CREAR_TIPO_PERMISO: false,
    ROLE_VER_PERMISO_GENERAL: false,
    ROLE_BORRAR_PERMISO_GENERAL: false,
    ROLE_VER_FERIADO: false,
    ROLE_BORRAR_FERIADO: false,
    ROLE_EDITAR_FERIADO: false,
    ROLE_CREAR_FERIADO: false,
    ROLE_VER_TURNO: false,
    ROLE_BORRAR_TURNO: false,
    ROLE_GESTION_PERMISOS: false,
    ROLE_AUSENCIAS_GENERALES: false,
    ROLE_ASISTENCIA: false,
    ROLE_VER_TIPO_PERMISO: false,
    ROLE_BORRAR_TIPO_PERMISO: false,
    ROLE_EDITAR_PERMISO_GENERAL: false,
    ROLE_CREAR_PERMISO_GENERAL: false,
    ROLE_EDITAR_TURNO: false,
    ROLE_CREAR_TURNO: false,
    ROLE_VER_HORARIO: false,
    ROLE_BORRAR_HORARIO: false,
    ROLE_EDITAR_HORARIO: false,
    ROLE_CREAR_HORARIO: false,
    ROLE_GENERAR_REPORTES_ASISTENCIA: false,
    ROLE_APROBACIONES_RRHH: false,
    ROLE_APROBAR_PERMISO_CONTROL: false,
    ROLE_VER_MATRIZ_SUPLENCIA: false,
    ROLE_VER_M_SUPLENCIA_D: false,
    ROLE_ELIMINAR_M_SUPLENCIA: false,
    ROLE_ELIMINAR_M_SUPLENCIA_D: false,
    ROLE_EDITAR_M_SUPLENCIA_D: false,
    ROLE_CREAR_M_SUPLENCIA_D: false,
    ROLE_EDITAR_MATRIZ_SUPLENCIA: false,
    ROLE_CREAR_MATRIZ_SUPLENCIA: false,
    ROLE_VER_SUPLENCIAS: false,
    ROLE_ELIMINAR_SUPLENCIAS: false,
    ROLE_GENERAR_REPORTES_CONTROL: false,
    ROLE_EVALUADOR_ASIGNADO: false,
    ROLE_DIGITAR_PERMISO: false,
    ROLE_CARGAR_NOMINA: false,
    ROLE_VER_NOMINAS: false,
    ROLE_VER_NOMINAS_COLABORADORES: false,
    ROLE_VER_CONTROL_ASISTENCIA: false,
    ROLE_VER_PERMISO_SUB: false,
    ROLE_DIGITAR_SUBSIDIO_COMIDA_CAFETERIA: false,
    ROLE_GENERAR_REPORTE_CAFETERIA: false,
    ROLE_DIGITAR_EMPLEADO_SORTEO: false,
    ROLE_GENERAR_SORTEO: false,
    ROLE_VER_ACCIONES: false,
    ROLE_RECLUTAMIENTO_ACCIONES: false,
    ROLE_PERSONAL_ACCIONES: false,
    ROLE_VER_HISTORICO_CLINICO: false,
    ROLE_AGREGAR_HISTORICO_CLINICO: false,
    ROLE_EDITAR_HISTORICO_CLINICO: false,
    ROLE_APROBAR_PERMISO_DIRECTOR_MINISTRO: false
  };

  // * FUNCION PARA ENCRIPTAR LOS PERMISOS
  const encryptData = (permisos) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(permisos),
      secretPass
    ).toString();

    return data;
  };

  // * FUNCION PARA DES-ENCRIPTAR LOS PERMISOS
  const decryptData = (permisosEncriptados) => {
    const bytes = CryptoJS.AES.decrypt(permisosEncriptados, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return data;
  };
 
  // * HOOK-STATE QUE ALMACENA SEGUN EL VALOR ALMACENADO EN EL STATE.
  const [permisosState, setPermisos] = useState(() => {

    // * OBTENIENDO LOS DATOS ALMACENADOS DEL LOCAL-STORAGE.
    const permisosStorage = window.localStorage.getItem("xpe");

    if (permisosStorage === null) {
      return initialStatePermisos;
    }
    // * RETORNANDO UN JSON.
    else {
     
      try {

        // * DESENCRIPTANDO LOS PERMISOS DEL LOCAL-STORAGE.
        const permisosParse = decryptData(permisosStorage);
        return JSON.parse(permisosParse);

      } catch (error) {
        return initialStatePermisos;
      }

    }
  });

  // * FUNCION QUE LIMPIA EL STORAGE.
  const clearStoragePermisos = () => {
    setPermisos(initialStatePermisos);
    window.localStorage.clear("xpe");
  };

  const value = {
    permisosState,
    setPermisos: (permisos) => {

      // * GUARDANDO LOS PERMISOS EN EL STATE.
      setPermisos(JSON.parse(permisos));

      // * ENCRIPTANDO LOS PERMISOS.
      const permisosEncriptados = encryptData(permisos);
      window.localStorage.setItem("xpe", permisosEncriptados);
    },
    clearStoragePermisos,
  };

  return (
    <PermissionContext.Provider value={{ value }}>
      {children}
    </PermissionContext.Provider>
  );
};
