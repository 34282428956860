import React from 'react';
import NavMenu from './Menu/NavMenu';
import DrawerMenu from './Menu/DrawerMenu';
import { Outlet } from 'react-router';

export default function Layout () {
    return (
        <React.Fragment>
    
            <NavMenu />
            <div id="layoutSidenav" className='content'>
                <DrawerMenu />

                <Outlet />
            </div>

            <footer class="py-3 bg-light">
                <div class="container-fluid px-4">
                    <div class="d-flex align-items-center justify-content-center small">
                        <div className="text-muted">Ministerio de Energía y Minas - <b>{new Date().getFullYear()}</b></div>
                    </div>
                </div>
            </footer> 
            
        </React.Fragment>
    );
}
