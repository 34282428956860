import React, { useEffect, useState } from "react";
import {
  Search,
  Assignment,
  TimerOutlined,
  CalendarToday,
  Print,
} from "@material-ui/icons";
import { endpoints } from "./../../redux/config";
import { Avatar, IconButton } from "@mui/material";
import moment from "moment";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";

import { BorderColor, Delete } from "@mui/icons-material";
import PermisoGeneralEditar from "../Modals/PermisosGenerales/PermisoGeneralEditar";
import {
  selectIsLoading,
  selectIsSuccess,
  selectMessage,
  deletePermisosGenerales,
} from "./../../redux/PermisosGenerales/PermisosGeneralesSlice";
import Loading from "../Modals/Loading/Loader";
import { PermissionData } from "../../context/Component/PermissionData";

const PermisoGeneralCard = ({ permisoGeneral, index }) => {
  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  const [open, setOpen] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const isLoading = useSelector(selectIsLoading);
  const isSuccess = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  const dispatch = useDispatch();

  const elminarPermisoGeneral = (permisoGeneralSelected) => {
    swal({
      title: "Esta seguro de eliminar el permiso general?",
      text: "Una vez eliminado, no estara nuevamente disponible!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setAddRequestStatus("success");
        dispatch(deletePermisosGenerales(permisoGeneralSelected));
      } else {
      }
    });
  };

  useEffect(() => {
    if (isSuccess === "success" && addRequestStatus === "success") {
      setAddRequestStatus("idle");
      swal("Exitoso!", "Fue eliminado correctamente", "success");
    } else if (isSuccess === "error" && addRequestStatus === "success") {
      setAddRequestStatus("idle");
      swal("Error!", message, "error");
    }
  }, [isSuccess, addRequestStatus]);

  return (
    <div className="row mt-2 border rounded-3 ms-1 me-1 pt-2 pb-1">
      <div className="col-1 align-self-center  ">
        <h4 className="text-primary-mem text-center">{index + 1}</h4>
      </div>
      <div className="col-4 ">
        <h4 className="text-primary-mem">{permisoGeneral.descripcion}</h4>
      </div>
      <div className="col-2">
        <div className="form-group">
          <div className="row">
            <label className="fw-bold">Fecha</label>
          </div>
          <label>{moment(permisoGeneral.fecha).format("DD/MM/yyyy")}</label>
        </div>
      </div>
      <div className="col-2">
        <div className="form-group">
          <div className="row">
            <label className="fw-bold">Hora inicio:</label>
          </div>
          <label>{moment(permisoGeneral.horaInicioDate).format("hh:mm")}</label>
        </div>
      </div>
      <div className="col-2">
        <div className="form-group">
          <div className="row">
            <label className="fw-bold">Hora inicio:</label>
          </div>
          <label>
            {moment(permisoGeneral.horaTerminoDate).format("hh:mm")}
          </label>
        </div>
      </div>
      <div className="col-1">
        <div className="form-group">
          <div>
            <IconButton id="btn-editar-permiso-general">
              <BorderColor
                hidden={!permisosState.ROLE_EDITAR_PERMISO_GENERAL}
                className="text-primary-mem"
                onClick={() => setOpen(true)}
                sx={{ cursor: "pointer" }}
              />
            </IconButton>
          </div>
          <div>
            <IconButton id="btn-eliminar-permiso-general">
              <Delete
                hidden={!permisosState.ROLE_BORRAR_PERMISO_GENERAL}
                className="text-danger"
                onClick={() => elminarPermisoGeneral(permisoGeneral)}
                sx={{ cursor: "pointer" }}
              />
            </IconButton>
          </div>
        </div>
      </div>

      <PermisoGeneralEditar
        open={open}
        setOpen={setOpen}
        permisoGeneral={permisoGeneral}
      />

      <Loading open={isLoading}></Loading>
    </div>
  );
};

export default PermisoGeneralCard;
