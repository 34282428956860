import { useState } from "react";
import { Box } from "@mui/material";

export const Image = ({src, sx}) => {

    const [actualSrc, setActualSrc] = useState('placeholder image link')
    
    src.then((url) => setActualSrc(url))

    return (
        <Box
            key={src}
            component='img'
            className="image-profile"
            src={`${actualSrc}`}
            sx={sx}
        />  
    )

}

export default Image;