import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { FaEye } from "react-icons/fa";
import { Encabezado } from "../../../Components/Encabezado/Encabezado";
import { BarraOpciones } from "../../../Components/EvaluacionDesempeño/Barra/BarraOpciones";
import { ProgressValueComponent } from "../../../Components/ProgressValue/ProgressValueComponent";
import Loading from "../../../Components/Modals/Loading/Loader";
import CheckIcon from "@mui/icons-material/Check";
import { useParams, useNavigate } from "react-router";
import { selectUserData } from "../../../redux/Login/LoginSlice";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import { IconButton } from "@mui/material";

import {
  fetchListaEvaluacionesPorAno,
  fetchGenerarEvaluacionEmpleadoPDF,
  fetchCerrarEvaluacion,
  fetchCambiarSuccess,
  selectIsLoading,
  selectListaEvaluacionesPorAno,
  selectIsSuccess,
  selectMessage,
} from "../../../redux/Evaluaciones/EvaluacionesSlice";

import { 
  calcGrupoOcupacionalCompetencias, 
  calcGrupoOcupacionalCompetenciasComportamientos2024,
  calcGrupoOcupacionalLogroMetas,
  calcTotalEvaluaciones2023
} from "../functions/evaluacionesFunciones";

export const EvaluacionesPendientesAno = () => {
  // ? HOOK NAVEGACION.
  const navigate = useNavigate();

  // * PARAMETRO A TRAVES DE LA RUTA.
  const { ano } = useParams();

  // ? HOOK DISPATCH
  const dispatch = useDispatch();

  // ? HOOK SELECTOR
  const usuario = useSelector(selectUserData);
  const listaEvaluaciones = useSelector(selectListaEvaluacionesPorAno);
  const isSuccess = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    refreshListEvaluacion();
  }, [usuario]);

  useEffect(() => {
    if (isSuccess === "success") {
      swal("Exitoso!", message, "success");
      refreshListEvaluacion();
      dispatch(fetchCambiarSuccess());
    } else if (isSuccess === "error") {
      swal(
        "Error",
        message,
        "error"
      );
      dispatch(fetchCambiarSuccess());
    }
  }, [isSuccess]);

  // * FUNCION PARA REFRESCAR LISTA
  const refreshListEvaluacion = () => dispatch(fetchListaEvaluacionesPorAno({ ano, evaluadorId: usuario?.Id }));

  // * FUNCION PARA NAVEGAR A OTRA PANTALLA.
  const openEvaluacionEmpleado = (hash) => {
    navigate(`/evaluaciones/desempeno/empleado/${hash}`);
  };

  // * FUNCION PARA ELIMINAR UN LOGRO DE METAS DE X EVALUACION.
  const cerrarEvaluacion = (evaluacionId) => {
    swal({
      title: "Cerrar evaluación".toUpperCase(),
      text: "Estás seguro/a que desea cerrar esta Evaluación?",
      icon: "warning",
      buttons: ["No", "Si"],
    }).then((respuesta) => {
      if (respuesta) {
        dispatch(fetchCerrarEvaluacion(evaluacionId));
      }
    });
  };

    // * FUNCION PARA GENERAR REPORTE PDF.
    const generateReportEvaluacion = (item) => {
      if(item.ano < 2023){
        swal("Información", "Reporte en desarrollo, favor de usar esta función a partir del período 2023.", "warning");
      }
      else
      {
        dispatch(fetchGenerarEvaluacionEmpleadoPDF(item.evaluacionId));
      }
      
    }

  return (
    <Encabezado title={"Evaluaciones de desempeño"} ano={ano}>
      <BarraOpciones route={"/evaluaciones/misevaluaciones"} hidden={true} />

      {listaEvaluaciones?.length > 0 &&
        listaEvaluaciones.map((item, index) => (
          <div className="row mt-4 mx-auto" key={index}>
            <div className="card">
              <div className="row">
                <div className="col-2 text-center mt-3 mb-3">
                  <p className="card-text fw-bold text-14">Evaluado</p>
                  <p className="text-14">{item.empleado}</p>
                </div>

                <div className="col-2">
                  <div className="text-center mt-3 mb-3">
                    <p className="card-text fw-bold text-14">Evaluador</p>
                    <p className="text-14">{item.evaluador}</p>
                  </div>
                </div>

                <div className="col-2">
                  <div className="text-center mt-3 mb-3">
                    <p className="card-text fw-bold text-14">
                      Grupo ocupacional
                    </p>
                    <p className="text-14">{item.grupoOcupacional}</p>
                  </div>
                </div>

                <div className="col-3">
                  <p>&nbsp;</p>
                  <div className="text-center text-14 mt-3 mb-3 d-flex justify-content-center">
                  <ProgressValueComponent value={calcGrupoOcupacionalLogroMetas(item.resultadoValor, item.grupoOcupacional)} ponderacion={item.resultadoValor}/>
                                
                    {
                      ano < 2023 && 
                        <>
                          <Typography color='#003876'>+</Typography>
                            <ProgressValueComponent value={Math.round(calcGrupoOcupacionalCompetencias(item.resultadoCompetenciaValor, item.grupoOcupacional))}/>
                          <Typography color='#003876'>+</Typography>
                            <ProgressValueComponent  value={item.resultadoRedValor * 100 / 15} ponderacion = {item.resultadoRedValor}/>
                        </>
                    }

                    <Typography color='#003876'>{ano < 2025 ? "=" : "+"}</Typography>
                    {
                      ano != 2025 ?
                        <ProgressValueComponent value={ano < 2023 ? item.totalEvaluacion : calcTotalEvaluaciones2023(item.totalEvaluacion, item.grupoOcupacional)}/>
                      :  
                        <>
                          <ProgressValueComponent value={Math.round(calcGrupoOcupacionalCompetenciasComportamientos2024(item.resultadoCompetenciasComportamientosMap, item.grupoOcupacional))} ponderacion={item.resultadoCompetenciasComportamientosMap} />
                          <Typography color='#003876'>=</Typography>
                          <ProgressValueComponent value={Math.round(item.resultadoCompetenciasComportamientosMap + item.totalEvaluacion)} />
                        </>
                    }
                  </div>
                </div>

                <div className="col-1">
                  <div className="text-center mt-3 mb-3">
                    <p className="card-text fw-bold text-14">Estado</p>

                    {item.mcCount !== 0 && item.estado === 0 && (
                      <IconButton sx={{marginTop: -2}} onClick={() => cerrarEvaluacion(item.evaluacionId)}> 
                        <CheckIcon sx={{color: '#005215' }} />
                      </IconButton>
                      
                    )}
                    
                  </div>
                </div>

                <div className="col-2 d-flex justify-content-center">

                  <div style={{marginTop: 40}}> 
                    {
                      (item.estado !== 0) ?
                        <>

                          <div style={{marginTop: '22'}}>
                            <IconButton onClick={() => generateReportEvaluacion(item)}>
                              <TaskOutlinedIcon sx={{color: '#BD0000' }} />
                            </IconButton>

                              {
                                ano >= 2024 && 
                                <IconButton onClick={() => openEvaluacionEmpleado(item.hash)} id='btn-ver-detalle-evaluacion'>
                                  <FaEye color='#003876' />
                                </IconButton>
                              }

                          </div>
                            
                        </>
                        :
                        <IconButton onClick={() => openEvaluacionEmpleado(item.hash)}>
                          <ExitToAppRoundedIcon sx={{color: '#003876' }} />
                        </IconButton>
                    }
                  </div>
                
                </div>

              </div>
            </div>
          </div>
        ))}

        <Loading open={isLoading} />

    </Encabezado>
  );
};
