import React from 'react'

export const Encabezado = ({title, children, ano, container = false}) => {
  return (
    <div className={container ? "container" : "container-fluid main"} style={{minHeight: '100vh'}}>
        <div className="row mt-2">
            <div className="col-md-12">
                <div>
                {
                  ano > 0 ?
                    <h4 className="pt-2 ps-2" style={{textTransform: 'uppercase'}}>{title} <b>{ano}</b></h4>
                    :
                    <h4 className="pt-2 ps-2" style={{textTransform: 'uppercase'}}>{title}</h4>
                }
                <hr />
                </div>
            </div>
        </div>

          {children}
    </div>
  )
}
