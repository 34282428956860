import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "../config";
import { messageFrontEnd } from "../../utils/mensajeErrorCliente";

const initialState = {
  isLoading: false,
  isSuccess: "idle",
  message: "",
  historicoClinico: {},
  empleadoByFilter: {},
  historialClinicoList: [],
  historialClinicoListCount: 0
};

// * CREAR HISTORICO CLINICO.
export const fetchCreateHistorialClinico = createAsyncThunk(
  "historicoClinico/fetchCreateHistorialClinico",

  async (dataApi) => {
    try {
      var resultado = await axiosApiInstance.post(
        endpoints.historicoClinico.createHistoricoClinico, dataApi
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response.data.data;
    }
  }
);

// * ACTUALIAZAR HISTORICO CLINICO.
export const fetchUpdateHistorialClinico = createAsyncThunk(
  "historicoClinico/fetchUpdateHistorialClinico",

  async (dataApi) => {
    try {
      var resultado = await axiosApiInstance.post(
        endpoints.historicoClinico.updateHistoricoClinico, dataApi
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response.data.data;
    }
  }
);

// * OBTENER LISTA DE HISTORIALES MEDICOS POR FILTRO Y PAGINACION.
export const fetchGetHistorialClinico = createAsyncThunk(
  "historicoClinico/fetchGetHistorialClinico",

  async ({skipRow, rowsPerPage, empleado}) => {
    try {
      var resultado = await axiosApiInstance.post(
        `${endpoints.historicoClinico.getListHistoricoClinico}/${skipRow}/${rowsPerPage}`, empleado
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response.data.data;
    }
  }
);

// * OBTENER CANTIDAD DE HISTORIALES MEDICOS SEGUN LOS FILTROS.
export const fetchGetHistorialClinicoCount = createAsyncThunk(
  "historicoClinico/fetchGetHistorialClinicoCount",

  async (empleado) => {
    try {
      var resultado = await axiosApiInstance.post(
        endpoints.historicoClinico.countListHistoricoClinico,
        empleado
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response.data.data;
    }
  }
);

// * OBTENER HISTORIAL POR ID.
export const fetchGetHistorialClinicoById = createAsyncThunk(
  "historicoClinico/fetchGetHistorialClinicoById",

  async (historicoClinicoId) => {
    try {
      var resultado = await axiosApiInstance.get(
        `${endpoints.historicoClinico.getListHistoricoClinico}/${historicoClinicoId}`,
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response.data.data;
    }
  }
);

// * OBTENER EMPLEADO POR CEDULA O ID.
export const fetchGetEmpleado = createAsyncThunk(
  "historicoClinico/fetchGetEmpleado",
  async (filtro) => {

    try {
      const response = await axiosApiInstance.post(endpoints.historicoClinico.getEmpleado, filtro);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

// * GENERAR REPORTE
export const fetchGenerarReporteHistoricoPDF = createAsyncThunk(
  "historicoClinico/fetchGenerarReporteHistoricoPDF",
  async (id) => {
    const result = await axiosApiInstance({
      url: `${
        endpoints.historicoClinico.generarReportePDF
      }?historicoId=${id}`, //your url

      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `Reporte Histórico Clínico (${id}).pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    return result;
  }
);

export const fetchCambiarSuccess = createAsyncThunk(
  "historicoClinico/fetchCambiarSuccess",
  async () => {
    return null;
  }
);

export const fetchLimpiarEmpleadoHistorialClinico = createAsyncThunk(
  "empleados/fetchLimpiarEmpleadoHistorialClinico",
  async () => {
    return null;
  }
);

export const HistoricoClinicoSlice = createSlice({
  name: "historicoClinico",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCambiarSuccess.fulfilled, (state) => {
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchLimpiarEmpleadoHistorialClinico.fulfilled, (state, action) => {
        state.empleadoByFilter = {};
      })
      .addCase(fetchCreateHistorialClinico.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchCreateHistorialClinico.fulfilled, (state, action) => {
        if (action.payload?.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;
        } else {
          state.isSuccess = "error";
          state.message = action.payload.message;
        }
        state.isLoading = false;
      })
      .addCase(fetchCreateHistorialClinico.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error.message);
      })  
      .addCase(fetchUpdateHistorialClinico.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchUpdateHistorialClinico.fulfilled, (state, action) => {
        if (action.payload?.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;
        } else {
          state.isSuccess = "error";
          state.message = action.payload.message;
        }
        state.isLoading = false;
      })
      .addCase(fetchUpdateHistorialClinico.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error.message);
      })  
      .addCase(fetchGetHistorialClinico.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchGetHistorialClinico.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.historialClinicoList = action.payload.data;
        } else {
          state.isSuccess = "error";
          state.message = action.payload.message;
        }
        state.isLoading = false;
      })
      .addCase(fetchGetHistorialClinico.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        state.historialClinicoList = [];
        console.error(action.error.message);
      })  
      .addCase(fetchGetHistorialClinicoCount.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchGetHistorialClinicoCount.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.historialClinicoListCount = action.payload.data;
        } else {
          state.isSuccess = "error";
          state.message = action.payload.message;
        }
        state.isLoading = false;
      })
      .addCase(fetchGetHistorialClinicoCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        state.historialClinicoListCount = 0;
        console.error(action.error.message);
      })  
      .addCase(fetchGetHistorialClinicoById.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchGetHistorialClinicoById.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.historicoClinico = action.payload.data ?? {};
        } else {
          state.isSuccess = "error";
          state.message = action.payload.message;
        }
        state.isLoading = false;
      })
      .addCase(fetchGetHistorialClinicoById.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        state.historicoClinico = {};
        console.error(action.error.message);
      })  
      .addCase(fetchGetEmpleado.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchGetEmpleado.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.empleadoByFilter = action.payload.data;
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
          state.empleadoByFilter = {};
        }
      })
      .addCase(fetchGetEmpleado.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.empleadoByFilter = {};
        state.message = action.error.message;
      })
      .addCase(fetchGenerarReporteHistoricoPDF.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchGenerarReporteHistoricoPDF.fulfilled, (state, action) => {
        state.isSuccess = "success";
        state.message = "El reporte fue generado correctamente.";
        state.isLoading = false;
      })
      .addCase(fetchGenerarReporteHistoricoPDF.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = action.error.message;
        state.isLoading = false;
      })
  },
});

export const selectIsLoading = (state) => state.historicoClinico.isLoading;
export const selectIsSuccess = (state) => state.historicoClinico.isSuccess;
export const selectMessage = (state) => state.historicoClinico.message;
export const selectHistoricoClinicoList = (state) => state.historicoClinico.historialClinicoList;
export const selectHistoricoClinicoListCount = (state) => state.historicoClinico.historialClinicoListCount;
export const selectHistoricoClinico = (state) => state.historicoClinico.historicoClinico;
export const selectEmpleado = (state) => state.historicoClinico.empleadoByFilter;

export const {} = HistoricoClinicoSlice.actions;
export default HistoricoClinicoSlice.reducer;
