import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Add } from "@mui/icons-material";

import Loading from "../../../Components/Modals/Loading/Loader";
import PermisoGeneralCard from "./../../../Components/Cards/PermisoGeneralCard";
import PermisoGeneralNuevo from "./../../../Components/Modals/PermisosGenerales/PermisoGeneralNuevo";
import {
  fetchPermisosGenerales,
  selectIsLoading,
  selectMessage,
  selectPermisosGenerales,
  selectIsSuccess,
} from "./../../../redux/PermisosGenerales/PermisosGeneralesSlice";

import { returArrayAnos } from "./helpers";
import {ListaDeAnos} from './components/'
import {PermissionData} from '../../../context/Component/PermissionData';
import { Encabezado } from '../../../Components/Encabezado/Encabezado';

export const PermisosGenerales = () => {

  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  const [open, setOpen] = useState(false);

  const [listadoAnos, setListadoAnos] = useState(returArrayAnos());

  const [yearSelected, setYearSelected] = useState(new Date().getFullYear());
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const isSuccess = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  const permisosGenerales = useSelector(selectPermisosGenerales);

  useEffect(() => {
    dispatch(fetchPermisosGenerales(yearSelected));
  }, [yearSelected]);


    // ************************************************************* //
   // ******************* COMPONENTES ***************************** //
  // ************************************************************* //

  const PermisosComponent = () => {
    return permisosGenerales.length > 0 ? (
      permisosGenerales.map((permisoGeneral, index) => (
        <PermisoGeneralCard
          key={permisoGeneral.id}
          permisoGeneral={permisoGeneral}
          index={index}
        />
      ))
    ) : (
      <h3>No existen permisos generales</h3>
    );
  };


  return (
    
    <Encabezado title='Permisos Generales'>
        <div className="row mt-2 d-flex justify-content-between">
          
          <div className="col-2">
              <button
                id="btn-agregar-permiso-general"
                hidden={!permisosState.ROLE_CREAR_PERMISO_GENERAL}
                type="button"
                className="btn btn-primary-mem"
                onClick={() => setOpen(true)}
              >
                <Add/> Nuevo Permiso General
              </button>
          </div>

          <div className="col-2 ">
            <div className="input-group">
              {/* <label className="align-self-center me-2">EMPLEADO</label> */}
              <select
                id="select-ano"
                className="form-control"
                defaultValue={yearSelected}
                onChange={(e) => {
                  e.preventDefault();
                  setYearSelected(e.currentTarget.value);
                }}
              >
                
                <ListaDeAnos listadoAnos={listadoAnos} />

                {/* <option value={2022}>2022</option>
                <option value={2023}>2023</option>
                <option value={2024}>2024</option> */}
              </select>
            </div>
          </div>
        </div>

        <hr/>
      
        <PermisosComponent/>

        <Loading open={isLoading} />

        <PermisoGeneralNuevo
          open={open}
          setOpen={setOpen}
        />

    </Encabezado>
       
  );
};

export default PermisosGenerales;
