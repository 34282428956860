import React, {useState,useEffect} from 'react'
import moment from "moment";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EstudiosEditar from '../Modals/Estudios/EstudiosEditar';
import { deleteEmpleadoEstudios } from '../../redux/Empleados/EmpleadosSlice';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import {PermissionData} from '../../context/Component/PermissionData';

export const Estudios = ({estudio,index}) => {

    // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  const { titulo, estudioNivele, estado, fecha, institucionAcademica } =
    estudio;
  const [openEstudiosEditar, setOpenEstudiosEditar] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const dispatch = useDispatch();

  const deleteEstudio = () => {
    swal({
      title: "Esta seguro de eliminar el estudio?",
      text: "Una vez eliminado, no estara nuevamente disponible!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setAddRequestStatus("success");
        dispatch(deleteEmpleadoEstudios(estudio));
      } else {
      }
    });
  };

  return (
    <div className="mt-2 pt-3 pb-2 mb-2 border ">
      <div className="row ">
        <div className="col-md-1 text-center align-self-center">
          <h1 className="text-primary-mem">{index + 1}</h1>
        </div>
        <div className="col-md-3 align-self-center">
          <h6 className="text-primary-mem">{`${titulo}`}</h6>
        </div>
        <div className="col-md-1 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Nivel</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">{estudioNivele.nombre}</label>
          </div>
        </div>
        <div className="col-md-1 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Estado</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">{estado}</label>
          </div>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Fecha Obtención</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">{moment(fecha).format("DD/MM/yyyy")}</label>
          </div>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Institucion Academica</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">{institucionAcademica}</label>
          </div>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <button
              id='btn-editar-estudios'
              hidden={!permisosState.ROLE_EDITAR_REGISTRO_EMPLEADOS}
              type="button"
              className="btn btn-sm"
              onClick={() => setOpenEstudiosEditar(true)}
            >
              {" "}
              <BorderColorIcon className="text-primary-mem" />
            </button>
          </div>
          <div className="col-md-12 text-center">
            {
              <button
                id='btn-eliminar-estudios'
                hidden={!permisosState.ROLE_BORRAR_REGISTRO_EMPLEADOS}
                type="button"
                className="btn btn-sm mt-2 "
                onClick={() => deleteEstudio()}
              >
                {" "}
                <DeleteOutlineOutlinedIcon className="text-danger" />
              </button>
            }
          </div>
        </div>
      </div>
      <EstudiosEditar
        openEstudiosEditar={openEstudiosEditar}
        setOpenEstudiosEditar={setOpenEstudiosEditar}
        empleadoId={estudio.empleadoId}
        estudio={estudio}
      />
    </div>
  );
}

export default Estudios