import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from 'react-router';
import moment from 'moment';
import { Encabezado } from '../../../Components/Encabezado/Encabezado';
import { IoCheckmarkDone } from "react-icons/io5";
import { FaEye } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa6";
import Loading from '../../../Components/Modals/Loading/Loader';
import { FiltroAcciones } from '../../../Components/Acciones/FiltroAcciones';
import swal from 'sweetalert';

import { 
  Box, 
  Paper, 
  TableContainer, 
  Table, 
  TableHead, 
  TableBody, 
  TablePagination,
  TableRow, 
  TableCell,
  IconButton,
  Typography
} from '@mui/material';

import { 
  fetchGetAccionesReclutamiento,
  fetchProcesarAccionReclutamiento,
  fetchCambiarSuccess,
  fetchGenerarReporteAccionPDF,
  fetchGetAccionesReclutamientoCount,
  selectListaAccionesReclutamiento,
  selectCountAccionesReclutamiento,
  selectIsLoading,
  selectMessage,
  selectIsSuccess
} from '../../../redux/Acciones/AccionesSlice';

import { selectUserData } from "../../../redux/Login/LoginSlice";
import { CancelarAccionModal } from '../../../Components/Acciones/Modal/CancelarAccionModal';

export const ReclutamientoSeleccion = () => {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-NAVEGACION
  const navigate = useNavigate();

  // ? HOOK-STATE
  const [open, setOpen] = useState(false);
  const [accion, setAccion] = useState({});

  // ? HOOK-SELECTOR
  const listaAccionesReclutamiento = useSelector(selectListaAccionesReclutamiento);
  const countAccionesReclutamiento = useSelector(selectCountAccionesReclutamiento);
  const isLoading = useSelector(selectIsLoading);
  const usuario = useSelector(selectUserData);
  const message = useSelector(selectMessage);
  const isSuccess = useSelector(selectIsSuccess);

  // ? HOOK-FORM
  const {
    register,
    formState: { errors },
    control,
    reset
  } = useForm({
    defaultValues: {
     empleado: {
      areaId: 0,
      empleadoId: 0,
      estadoId: 2
     }
    }
  });

  // ? HOOK-WATCH
  const empleado = useWatch({ control, name: 'empleado'});

  // ? USE-STATE PARA LA PAGINACION
  const [ page, setPage ] = useState(0);
	const [ rowsPerPage, setRowsPerPage ] = useState(25);
	const [ skipRow, setSkipRow ] = useState(0);

  // * FUNCIONES PARA PAGINACION.
	const handleChangePage = (event, newPage) => {
		setSkipRow(newPage * rowsPerPage);
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setSkipRow(0);
	};


  // * FUNCION PARA REFRESCAR LISTA.
  const refreshList = () => dispatch(fetchGetAccionesReclutamiento({skipRow, rowsPerPage, empleado}));

  // * FUNCION PARA REFRESCAR SUCCESS
  const refreshSuccess = () => dispatch(fetchCambiarSuccess());
  
  // ? HOOK-EFFECT
  useEffect(() => {
    refreshList();
  }, [empleado, page, rowsPerPage])

  // ? HOOK-EFFECT PARA LAS ALERTAS
  useEffect(() => {
    
    if(isSuccess === 'success' && message.length > 0){
      swal({title: 'Exitoso', text: message, icon: 'success'});
      refreshList();
      refreshSuccess();

    }
    else if(isSuccess === 'error'){
      swal({title: 'Error', text: message, icon: 'error'});
      refreshSuccess();
    }

  }, [isSuccess])


  // ? HOOK-EFFECT PARA OBTENER LA CANTIDAD DE ACCIONES DE RECLUTAMIENTO
  // ? DESDE EL BACK-END
  useEffect(() => {
    dispatch(fetchGetAccionesReclutamientoCount(empleado));
  }, [empleado])
  

  // * FUNCION PARA PROCESAR UNA ACCION
  const procesarAccion = (id) => {

    swal({
      title: 'Procesar acción',
      text: 'Estás seguro/a de procesar esta acción?',
      icon: 'warning',
      buttons: ['No', 'Si']
    }).then(respuesta => {

        const data = {
          accionId: id,
          usuarioIdModificado: usuario.Id
        };

        if(respuesta){
          dispatch(fetchProcesarAccionReclutamiento(data));
        }
    })

  }

  // * FUNCION PARA CANCELAR UNA ACCION
  const cancelarAccion = (accion) => {
    setOpen(true);
    setAccion(accion);
  }

  // * FUNCION PARA GENERAR REPORTE
  const generarReportePDF = (accion) => dispatch(fetchGenerarReporteAccionPDF(accion));

  return (
   <Encabezado title='Acciones de Reclutamiento'>

    <FiltroAcciones 
    control={control}
    register={register}
    reset={reset}
    rutaAccionBotonAgregar={"/acciones/reclutamiento/agregar"}
    permisoBoton={'ROLE_RECLUTAMIENTO_ACCIONES'}
    filtroAccionesSinEfecto={true}
    />

    <Box sx={{ mt: 3 }} maxWidth={'100%'}>
      <Paper sx={{ mb: 2 }} elevation={3}>
        <TableContainer>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold'}}>
                  Fecha de Creación
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Fecha de Efectividad
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Empleado
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Código
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Área Organizacional
                </TableCell>
                <TableCell align='center' style={{fontWeight: 'bold'}}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>

              {
                listaAccionesReclutamiento.length === 0 ? 
                  <TableCell colSpan={6} align='center'>
                    <Typography>No hay registros</Typography>
                  </TableCell>
                :
                listaAccionesReclutamiento.map((item, index) => (
                  <TableRow key={index}>
                    
                    <TableCell>
                      {moment(item?.fechaCreacion).format('DD/MM/yyyy')}
                    </TableCell>

                    <TableCell>
                      {moment(item?.fechaEfectividad).format('DD/MM/yyyy')}
                    </TableCell>

                    <TableCell>
                      {item.empleado.nombreLabel}
                    </TableCell>

                    <TableCell>
                      {item.empleado.codigoEmpleado}
                    </TableCell>
                    
                    <TableCell>
                      {item.empleado.areaOrganizacional.nombre}
                    </TableCell>

                    <TableCell align='center'>

                      {/* SI EL ESTADO ES "SIN EFECTO" SE OCULTARA LOS SIGUIENTES BOTONES. */}
                      <IconButton id='btn-procesar-accion' hidden={item.estadoId !== 2} onClick={() => procesarAccion(item.accionId)}>
                       <IoCheckmarkDone color='#005215'/>
                      </IconButton>
                      
                      <IconButton id='btn-cancelar-accion' hidden={item.estadoId !== 2}  onClick={() => cancelarAccion(item)}>
                        <MdCancel color='#BD0000' />
                      </IconButton>
                     
                      <IconButton id='btn-generar-pdf-accion' onClick={() => generarReportePDF(item)}>
                        <FaFilePdf color='#BD0000' />
                      </IconButton>

                      <IconButton id='btn-ver-detalle-accion' onClick={() => navigate(`/acciones/reclutamiento/detalle/${item.accionId}`)}>
                        <FaEye color='#003876' />
                      </IconButton>


                    </TableCell>
                    
                  </TableRow>
                ))
              }

            </TableBody>

          </Table>

        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={countAccionesReclutamiento}
          page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>

    <Loading open={isLoading} />

    <CancelarAccionModal 
    open={open}
    setOpen={setOpen}
    accion={accion}
    usuarioId={usuario?.Id}
    />

   </Encabezado>
  )
}
