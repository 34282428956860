import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Encabezado } from '../../Components/Encabezado/Encabezado';
import { getPhoto } from '../../utils/getPhoto';
import { Image } from '../../Components/Profile/Image';

import { Box, 
  Divider, 
  Grid, 
  Paper, 
  Stack, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow
} from '@mui/material';

import Typography from "@mui/material/Typography";
import GroupsIcon from '@mui/icons-material/Groups';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';

import { 
  fetchEmpleadoCumpleanos,
  fetchPanelControl,
  selectEmpleadoCumpleanos,
  selectPanelControl
} from "../../redux/Empleados/EmpleadosSlice";


export const PanelControl = () => {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const cumpleanos = useSelector(selectEmpleadoCumpleanos);
  const panelControl = useSelector(selectPanelControl);

  const arrTotales = [
    {
      Icon: GroupsIcon,
      titulo: 'Cantidad de Empleados',
      cantidad: panelControl.cantidadEmpleados ?? 0
    },
    {
      Icon: ManIcon,
      titulo: 'Cantidad de Hombres',
      cantidad: panelControl.cantidadHombres ?? 0
    },
    {
      Icon: WomanIcon,
      titulo: 'Cantidad de Mujeres',
      cantidad: panelControl.cantidadMujeres ?? 0
    }
  ];

  useEffect(() => {
    dispatch(fetchEmpleadoCumpleanos())
    dispatch(fetchPanelControl())
  }, [])
  
  return (

      <Encabezado title='Panel de Control'>

        <Grid container spacing={2}>
          
          {
            arrTotales.map((item, index) => (
              <Grid item xs={4} key={index}>
                <Paper sx={{backgroundColor: '#003876'}}>

                  <Grid container>
                    
                    <Grid item xs={4}>
                      <Box padding={3}>
                        <item.Icon style={{fontSize: '7vw'}} sx={{color: 'white'}} />
                      </Box>
                    </Grid>
                    
                    <Grid item xs={8} display='flex' justifyContent='end'>
                      <Box padding={3} pt={4}>
                        <Typography sx={{color: 'white', fontSize: '1.6vw'}} align='right'>{item.cantidad}</Typography>
                        <Typography sx={{color: 'white', fontSize: '1.2vw'}} variant='h6'>{item.titulo}</Typography>
                      </Box>
                    </Grid>

                  </Grid>

                </Paper>
              </Grid>
            ))
          }

        </Grid>

        <Grid container mt={2} spacing={2}>
          
          <Grid item xs={4}>
            <Paper>

              <Stack p={2}>
                <Typography textTransform='uppercase'>Empleados según estado civil</Typography>
                <Divider />
              
                  <Box mt={2}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: {lg: 200, md: 100, sm: 50} }} aria-label="simple table">
                        
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{fontWeight: 'bold'}}>Estado Civil</TableCell>
                            <TableCell sx={{fontWeight: 'bold'}}>Cantidad</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>

                          {
                            panelControl?.listaEstadosCiviles?.map((item, index) => (
               
                              <TableRow key={index}>
                                <TableCell>{item.nombre}</TableCell>
                                <TableCell>{item.cantidad}</TableCell>
                              </TableRow>

                            ))
                          }
                         

                        </TableBody>
                      </Table>
                      
                    </TableContainer>
                  </Box>
                  
                </Stack>
            </Paper>
          </Grid>

          <Grid item xs={4}>
            <Paper>

              <Stack p={2}>
                <Typography textTransform='uppercase'>Empleados según tipo</Typography>
                <Divider />
              
                  <Box mt={2}>

                      <TableContainer component={Paper}>
                          <Table sx={{ minWidth: {lg: 200, md: 100, sm: 50} }} aria-label="simple table">
                            
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{fontWeight: 'bold'}}>Tipo</TableCell>
                                <TableCell sx={{fontWeight: 'bold'}}>Cantidad</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {
                                panelControl?.listaTipoEmpleado?.map((item, index) => (
                  
                                  <TableRow key={index}>
                                    <TableCell>{item.nombre}</TableCell>
                                    <TableCell>{item.cantidad}</TableCell>
                                  </TableRow>

                                ))
                              }
                            </TableBody>
                          </Table>
                          
                      </TableContainer>

                  </Box>
              </Stack>

            </Paper>
          </Grid>

          <Grid item xs={4}>
            <Paper>

              <Stack p={2} component='div'>
                <Typography textTransform='uppercase'>Cumpleaños de hoy</Typography>
                <Divider />
            
                <Box mt={2}>
                  {
                    cumpleanos.map((item, index) => (

                      <Paper elevation={3} key={index}>
                          <Grid container mb={1}>
                              <Grid item md={5} lg={4} xs={4} padding={1} mb={-3}>
                                <Image src={getPhoto(item)} sx={{height: '80%', borderRadius: 2}} />
                              </Grid>

                              <Grid item md={7} lg={8} xs={8} padding={2}>
                                <Typography fontWeight='bold' fontSize={{lg: 14, md: 13, sm: 14}} >{item.nombreCompleto}</Typography>
                                <Typography fontSize={{lg: 13, md: 12, sm: 12}}>{item.departamento}</Typography>
                              </Grid>
                          </Grid>
                      </Paper>

                    ))
                  }
                </Box>

              </Stack>

            </Paper>
          </Grid>

        </Grid>

      </Encabezado>
  )
}
