import React from 'react';
import { Dialog, DialogActions, DialogContent, Divider, Typography, DialogTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import InfoIcon from '@mui/icons-material/Info';
import Grid from '@mui/material/Grid';

export const ModalAlertaCargaNomina = ({open = false, listaEmpleadoNoEncontrados, clear}) => {
 
    return (
    <Dialog 
    PaperProps={{ sx: { width: "40%", height: "70%" } }}
    open={open}
    maxWidth='lg'
    >
        <DialogTitle sx={{textTransform: 'uppercase'}} className='text-mem text-center'>Informaciones</DialogTitle>
        <Divider />

        <DialogContent>

        <Stack sx={{ width: '100%' }} spacing={2}>

        <Grid container spacing={1}>
            <Grid item xs={1}>
                <InfoIcon fontSize='large' sx={{marginBottom: -3.5, color: '#003876'}}/>
            </Grid>
            <Grid item xs={11}>
                <Typography fontSize={18} >
                Listado de empleados que no existen en la base de datos del sistema de recursos humanos (SIREH).
                </Typography>
            </Grid>
            
        </Grid>

        <Divider />
       

            {
                listaEmpleadoNoEncontrados?.length > 0 &&

                listaEmpleadoNoEncontrados.map((item, index) => (
                    <Alert severity="warning">{item.nombre}</Alert>
                ))
            }

        </Stack>

        </DialogContent>

        <DialogActions className='justify-content-center'>
            <button onClick={() => clear()} className='btn btn-primary-mem'>Aceptar</button>
        </DialogActions>

    </Dialog>
  )
}
