import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import {PermissionData} from '../../context/Component/PermissionData';
import { VscOrganization } from 'react-icons/vsc';
import { MdFastfood } from "react-icons/md";

export default function DrawerMenu () {

        // * PERMISOS DEL USUARIO.
        const permisosState = PermissionData();

        // * FUNCION QUE VALIDA UNA RUTA Y IDENTIFICA SI EL USUARIO TIENE O NO ACCESO A LA MISMA.
        const validacionRutas = (ruta) => {
            
          let result = false;

            for (let i = 0; i < Object.keys(permisosState).length; i++) {

                if (ruta === Object.keys(permisosState)[i]) {

                    if (permisosState[Object.keys(permisosState)[i]] === true) {

                        result = true;
                    }
                }
            }

          return result;
        };

        return (
            <div  id="layoutSidenav_nav">
                <nav style={{minHeight: '100%'}} className="accordion bg-white shadow-right" id="sidenavAccordion">
                    <div className="bg-white-menu">

                        <img className="img-fluid p-3" alt="LOGO SIREH" src="https://mem.gob.do/wp-content/themes/memrd/img/logonuevo-left-portal.svg" />
                        
                        <div className="nav">

                            {
                                validacionRutas('ROLE_VER_ESTADISTICAS') &&

                                    <NavLink tag={Link} className="nav-link" to="/dashboard"> <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div> ESTADÍSTICAS</NavLink>
                            
                            }

                            {
                                validacionRutas('ROLE_VER_ACCIONES') | validacionRutas('ROLE_ADMIN') ?

                                <>

                                    <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#accionesCollapse" aria-expanded="false" aria-controls="controlAsistenciaCollapse">
                                        <div className="sb-nav-link-icon"><VscOrganization fontSize={20} /></div>
                                            ACCIONES
                                        <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                    </a>
                                
                                    <div className="collapse" id="accionesCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                        <ul className="navbar-nav flex-grow">

                                            {
                                                validacionRutas('ROLE_VER_ACCIONES') | validacionRutas('ROLE_ADMIN') ?

                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                    <NavLink tag={Link} className="nav-link" to="/acciones/personal">ACCIONES DE PERSONAL</NavLink>
                                                </NavItem>

                                                : <></>
                                            }

                                            {
                                                validacionRutas('ROLE_RECLUTAMIENTO_ACCIONES') | validacionRutas('ROLE_ADMIN') ?

                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                    <NavLink tag={Link} className="nav-link" to="/acciones/reclutamiento">RECLUTAMIENTO Y SELECCIÓN</NavLink>
                                                </NavItem>

                                                : <></>
                                            }
                                                
                                        </ul>
                                    </div>

                                </>

                                :
                                <></>

                            }

                
                            {
                                validacionRutas('ROLE_VER_REGISTRO_EMPLEADOS') | validacionRutas('ROLE_VER_SOLICITUDES') ? 

                                    <>
                                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                            <div className="sb-nav-link-icon"><i className="fas fa-columns"></i></div>
                                            REGISTRO
                                            <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </a>

                                        <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                            <ul className="navbar-nav flex-grow">

                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                    <NavLink tag={Link} className="nav-link" to="/empleados">EMPLEADOS</NavLink>
                                                </NavItem>

                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                    <NavLink tag={Link} className="nav-link" to="empleados/empleadosdesvinculados">EMPLEADOS DESVINCULADOS</NavLink>
                                                </NavItem>

                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                    <NavLink tag={Link} className="nav-link" to="empleados/reporte">REPORTE DE EMPLEADOS</NavLink>
                                                </NavItem>
                            
                                            </ul>

                                        </div>
                            
                                    </>

                                : <></>
                                
                            }

                            {
                                validacionRutas('ROLE_NOMINAS') | validacionRutas('ROLE_CARGAR_NOMINA') | validacionRutas('ROLE_VER_NOMINAS') ?

                                    <>
                                    
                                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#nominas" aria-expanded="false" aria-controls="nominas">
                                            <div className="sb-nav-link-icon"><i className="bi bi-coin" style={{fontSize: 17}}></i></div>
                                            NÓMINAS
                                            <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </a>

                                            <div className="collapse" id="nominas" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                                <ul className="navbar-nav flex-grow">

                                                    {
                                                        validacionRutas('ROLE_CARGAR_NOMINA') &&
                                                        <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                            <NavLink tag={Link} className="nav-link" to="nominas/cargarnomina">CARGAR NÓMINAS</NavLink>
                                                        </NavItem>
                                                    }

                                                    {
                                                        validacionRutas('ROLE_VER_NOMINAS') &&
                                                        <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                            <NavLink tag={Link} className="nav-link" to="nominas/vernominas">VER NÓMINAS</NavLink>
                                                        </NavItem>

                                                    }

                                                    {
                                                        validacionRutas('ROLE_ADMIN') &&
                                                        <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                            <NavLink tag={Link} className="nav-link" to="nominas/listanominas">ELIMINAR NÓMINA</NavLink>
                                                        </NavItem>

                                                    }

                                
                                                </ul>

                                            </div>

                                    
                                    </>

                                    : <></>
                            }
                        
                            {
                                validacionRutas('ROLE_APROBACIONES_RRHH') | validacionRutas('ROLE_VER_TIPO_PERMISO') | validacionRutas('ROLE_GENERAR_REPORTES_CONTROL') | validacionRutas('ROLE_VER_HORARIO') | validacionRutas('ROLE_VER_FERIADO') | validacionRutas('ROLE_VER_PERMISO_GENERAL') | validacionRutas('ROLE_ASISTENCIA')  ? 

                                    <>
                                
                                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                                            <div className="sb-nav-link-icon"><i className="fas fa-history"></i></div>
                                                CONTROL
                                            <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </a>

                                    </>

                                : <></>
                            }

                                <div className="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                                    <nav className="bg-white-menu-nested nav accordion sidenav-menu-nested" id="sidenavAccordionPages">

                                            {
                                    
                                                validacionRutas('ROLE_APROBACIONES_RRHH') | validacionRutas('ROLE_VER_TIPO_PERMISO') | validacionRutas('ROLE_GENERAR_REPORTES_CONTROL') ?
                                    
                                                    <>
                                    
                                                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#permisosCollapse" aria-expanded="false" aria-controls="permisosCollapse">
                                                                <div className="sb-nav-link-icon"><i className="far fa-calendar-check"></i></div>
                                                                    PERMISOS
                                                                <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                                        </a>

                                    
                                                        <div className="collapse" id="permisosCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">

                                                            <ul className="navbar-nav flex-grow">

                                                                {
                                                                    validacionRutas('ROLE_APROBACIONES_RRHH') && 

                                                                    <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                        <NavLink tag={Link} className="nav-link" to="/permisos">APROBACIONES</NavLink>
                                                                    </NavItem>    

                                                                }

                                                                {
                                                                    validacionRutas('ROLE_DIGITAR_PERMISO') && 

                                                                    <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                        <NavLink tag={Link} className="nav-link" to="/permisos/solicitudes_personalizados">DIGITAR PERMISO</NavLink>
                                                                    </NavItem>

                                                                }

                                                                {
                                                                    validacionRutas('ROLE_VER_TIPO_PERMISO') && 

                                                                    <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                        <NavLink tag={Link} className="nav-link" to="/permisos/tipospermisos">TIPOS DE PERMISOS</NavLink>
                                                                    </NavItem>

                                                                }

                                                            </ul>
                                
                                                        </div>

                                                    </>

                                                : <></>

                                            }

                                            {
                                                validacionRutas('ROLE_VER_HORARIO') | validacionRutas('ROLE_VER_FERIADO') | validacionRutas('ROLE_VER_PERMISO_GENERAL') | validacionRutas('ROLE_ASISTENCIA') ?

                                                        <>
                                                        
                                                                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#asistenciaCollapse" aria-expanded="false" aria-controls="asistenciaCollapse">
                                                                    <div className="sb-nav-link-icon"><i className="far fa-calendar-alt"></i></div>
                                                                        ASISTENCIA
                                                                    <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                                                </a>

                                                                <div className="collapse" id="asistenciaCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                                                    <ul className="navbar-nav flex-grow">

                                                    
                                                                        {
                                                                            validacionRutas('ROLE_VER_HORARIO') &&

                                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                    <NavLink tag={Link} className="nav-link" to="/asistencia">HORARIOS</NavLink>
                                                                                </NavItem>

                                                                        }

                                                                        {
                                                                            validacionRutas('ROLE_VER_FERIADO') &&

                                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                    <NavLink tag={Link} className="nav-link" to="/asistencia/diasferiados">DÍAS FERIADOS</NavLink>
                                                                                </NavItem>

                                                                        }

                                                                        {
                                                                            validacionRutas('ROLE_VER_PERMISO_GENERAL') &&

                                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                    <NavLink tag={Link} className="nav-link" to="/asistencia/permisosgenerales">PERMISOS GENERALES</NavLink>
                                                                                </NavItem>
                                                                        }
                                                                                                                        
                                                                    </ul>
                                                                </div>

                                                        </>

                                                    : <></>
                                            }

                                            {
                                                validacionRutas('ROLE_VER_HORARIO') | validacionRutas('ROLE_VER_FERIADO') | validacionRutas('ROLE_VER_PERMISO_GENERAL') | validacionRutas('ROLE_GENERAR_REPORTES_CONTROL') | validacionRutas('ROLE_ASISTENCIA') ?
                                            
                                                        <>
                                                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#reportesCollapse" aria-expanded="false" aria-controls="reportesCollapse">
                                                                <div className="sb-nav-link-icon"><i className="far fa-calendar"></i></div>
                                                                    REPORTES
                                                                <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                                            </a>

                                                            <div className="collapse" id="reportesCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                                                                                        
                                                                <ul className="navbar-nav flex-grow">

                                                                    {
                                                                        validacionRutas('ROLE_ASISTENCIA') &&

                                                                        <>
                                                                        
                                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                    <NavLink tag={Link} className="nav-link" to="/reportes/asistencias">ASISTENCIA</NavLink>
                                                                                </NavItem>

                                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                    <NavLink tag={Link} className="nav-link" to="/reportes/absentismo">ABSENTISMO</NavLink>
                                                                                </NavItem>

                                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                    <NavLink tag={Link} className="nav-link" to="/reportes/resumenAsistencia">RESUMEN DE ASISTENCIA</NavLink>
                                                                                </NavItem>
                                                                        
                                                                        </>

                                                                    }

                                                                    {
                                                                        validacionRutas('ROLE_GENERAR_REPORTES_CONTROL') && 

                                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                    <NavLink tag={Link} className="nav-link" to="/reportes/permisos">PERMISOS</NavLink>
                                                                                </NavItem>

                                                                    }

                
                                                                </ul>

                                                            </div>
                                                        </>

                                                : <></>
                                            
                                            }
                                        
                                    </nav>
                                </div>

                            {
                                validacionRutas('ROLE_VER_PERIODO_BONIFICACION') ?
                                
                                        <>
                                    
                                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#beneficiosCollapse" aria-expanded="false" aria-controls="beneficiosCollapse">
                                                <div className="sb-nav-link-icon"><i className="fas fa-dollar-sign"></i></div>
                                                    BENEFICIOS
                                                <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                            </a>

                                                <div className="collapse" id="beneficiosCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                                {/*<nav className="bg-white-menu-nested sidenav-menu-nested nav">*/}
                                                {/*    <a className="nav-link" href="401.html">BONO ESCOLAR</a>*/}
                                                {/*</nav>*/}
                                                    <ul className="navbar-nav flex-grow">
                                                        <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                            <NavLink tag={Link} className="nav-link" to="beneficios/bonoescolar">BONO ESCOLAR</NavLink>
                                                        </NavItem>
                                                    </ul>
                                                </div>

                                        </>

                                : <></>
                            }

                                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#evaluacionesCollapse" aria-expanded="false" aria-controls="evaluacionesCollapse">
                                                <div className="sb-nav-link-icon"><i className="far fa-clipboard"></i></div>
                                                    EVALUACIONES
                                                <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                            </a>

                                                <div className="collapse" id="evaluacionesCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                                    <ul className="navbar-nav flex-grow">

                                                        {
                                                            validacionRutas('ROLE_VER_PERIODO_EVALUACION') &&
                                                            
                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                    <NavLink tag={Link} className="nav-link" to="evaluaciones/desempeno">DESEMPEÑO</NavLink>
                                                                </NavItem>
                                                        }
                                                    
                                                        <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                            <NavLink tag={Link} className="nav-link" to="evaluaciones/misevaluaciones">PENDIENTES</NavLink>
                                                        </NavItem>
                                                    </ul>
                                                </div>
                                                
                            {
                                validacionRutas('ROLE_VER_AREAS_ORGANIZACIONALES') | validacionRutas('ROLE_VER_CARGOS') ?

                                    <>

                                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#disenoOrganizacionalCollapse" aria-expanded="false" aria-controls="disenoOrganizacionalCollapse">
                                                <div className="sb-nav-link-icon"><i className="fas fa-sitemap"></i></div>
                                                    DISEÑO ORGANIZACIONAL
                                                <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                            </a>

                                            <div className="collapse" id="disenoOrganizacionalCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                                <ul className="navbar-nav flex-grow">
                                                    <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                        <NavLink tag={Link} className="nav-link" to="organizacion/cargos">CARGOS</NavLink>
                                                    </NavItem>
                                                    <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                        <NavLink tag={Link} className="nav-link" to="organizacion/areasorganizacionales">ÁREA ORGANIZACIONAL</NavLink>
                                                    </NavItem>
                                                </ul>
                                            </div>
                                    
                                    </>
                                
                                : <></>
                            }
                                
                                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#controlAsistenciaCollapse" aria-expanded="false" aria-controls="controlAsistenciaCollapse">
                                                <div className="sb-nav-link-icon"><i className="far fa-address-book"></i></div>
                                                    CONTROL DE ASISTENCIA
                                                <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                            </a>
                                            
                                                <div className="collapse" id="controlAsistenciaCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                                        <ul className="navbar-nav flex-grow">

                                                        {
                                                            
                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                    <NavLink tag={Link} className="nav-link" to="/permisos/solicitudes_permisos">APROBAR PERMISOS</NavLink>
                                                                </NavItem>

                                                        }
                                                        
                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                    <NavLink tag={Link} className="nav-link" to="/permisos/solicitudes_personalizados">SOLICITAR PERMISOS</NavLink>
                                                                </NavItem>
                                                                
                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                    <NavLink tag={Link} className="nav-link" to="reportes/reporteAsistencia">REPORTE DE ASISTENCIA</NavLink>
                                                                </NavItem>
                                                        </ul>
                                                </div>
                                    
                                                <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#controlNominasCollapse" aria-expanded="false" aria-controls="controlAsistenciaCollapse">
                                                    <div className="sb-nav-link-icon"><i className="bi bi-cash-coin" style={{fontSize: 17}}></i></div>
                                                        CONTROL DE NÓMINAS
                                                    <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                                </a>

                                                    <div className="collapse" id="controlNominasCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                                            <ul className="navbar-nav flex-grow">

                                                                    <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                        <NavLink tag={Link} className="nav-link" to="/nominas/control/misvolantes">MIS VOLANTES DE PAGOS</NavLink>
                                                                    </NavItem>

                                                                    {
                                                                        validacionRutas('ROLE_VER_NOMINAS_COLABORADORES') &&

                                                                            <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                <NavLink tag={Link} className="nav-link" to="/nominas/control/colaboradores">VOLANTES DE PAGOS COLABORADORES</NavLink>
                                                                            </NavItem>
                                                                    }
                                                                    
                                                                    
                                                            </ul>
                                                    </div>

                                                    {/* {
                                                        validacionRutas('ROLE_DIGITAR_SUBSIDIO_COMIDA_CAFETERIA') | validacionRutas('ROLE_ADMIN') ?

                                                        <>
                                                        
                                                        
                                                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#cafeteriaCollapse" aria-expanded="false" aria-controls="controlAsistenciaCollapse">
                                                                <div className="sb-nav-link-icon"><i className="bi bi-shop" style={{fontSize: 18}}></i></div>
                                                                    CAFETERÍA
                                                                <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                                            </a>
                                                            
                                                                <div className="collapse" id="cafeteriaCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                                                    <ul className="navbar-nav flex-grow">

                                                                        {
                                                                            validacionRutas('ROLE_DIGITAR_SUBSIDIO_COMIDA_CAFETERIA') | validacionRutas('ROLE_ADMIN') ?

                                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                    <NavLink tag={Link} className="nav-link" to="/cafeteria">ASIGNACIÓN DE COMIDA</NavLink>
                                                                                </NavItem>

                                                                            : <></>
                                                                        }

                                                                        {
                                                                            validacionRutas('ROLE_GENERAR_REPORTE_CAFETERIA') | validacionRutas('ROLE_ADMIN') ?

                                                                                // <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                //     <NavLink tag={Link} className="nav-link" to="/cafeteria/reporte">REPORTE</NavLink>
                                                                                // </NavItem>

                                                                                <div className="collapse" id="cafeteriaCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                                                                    <ul className="navbar-nav flex-grow">
                                                                                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#cafeteriaCollapse" aria-expanded="false" aria-controls="controlAsistenciaCollapse">
                                                                                            <div className="sb-nav-link-icon"><i className="bi bi-shop" style={{fontSize: 18}}></i></div>
                                                                                                CAFETERÍA
                                                                                            <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                                                                        </a>
                                                                                    </ul>
                                                                                </div>  

                                                                            : <></>
                                                                        }
                                                                            
                                                                    </ul>
                                                                </div>

                                                        
                                                        </>
                                                        
                                                        : <></>

                                                    } */}

                                                {
                                                    validacionRutas('ROLE_DIGITAR_SUBSIDIO_COMIDA_CAFETERIA') | validacionRutas('ROLE_ADMIN') ? 

                                                        <>
                                                    
                                                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#collapsePages2" aria-expanded="false" aria-controls="collapsePages">
                                                                <div className="sb-nav-link-icon"><i className="bi bi-shop" style={{fontSize: 18}}></i></div>
                                                                    CAFETERÍA
                                                                <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                                            </a>

                                                        </>

                                                    : <></>
                                                }

                                                    <div className="collapse" id="collapsePages2" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                                                        <nav className="bg-white-menu-nested nav accordion sidenav-menu-nested" id="sidenavAccordionPages">

                                                            {
                                                    
                                                                validacionRutas('ROLE_GENERAR_REPORTE_CAFETERIA') | validacionRutas('ROLE_ADMIN') ?
                                                    
                                                                    <>
                                                    
                                                                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#permisosCollapse" aria-expanded="false" aria-controls="permisosCollapse">
                                                                            <div className="sb-nav-link-icon"><i className="bi bi-clipboard-data-fill" style={{fontSize: 18}}></i></div>
                                                                                REPORTES
                                                                            <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                                                        </a>

                                                    
                                                                        <div className="collapse" id="permisosCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">

                                                                            <ul className="navbar-nav flex-grow">

                                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                    <NavLink tag={Link} className="nav-link" to="/cafeteria/reporte/historico">HISTÓRICO</NavLink>
                                                                                </NavItem>    

                                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                    <NavLink tag={Link} className="nav-link" to="/cafeteria/reporte/estadistica">ESTADÍSTICA</NavLink>
                                                                                </NavItem>       

                                                                            </ul>
                                                
                                                                        </div>

                                                                    </>

                                                                : <></>

                                                            }

                                                            {
                                                                validacionRutas('ROLE_DIGITAR_SUBSIDIO_COMIDA_CAFETERIA') | validacionRutas('ROLE_ADMIN') ?
                                                                    <>
                                                                    
                                                                        <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#cafeteriaCollapse" aria-expanded="false" aria-controls="cafeteriaCollapse">
                                                                            <div className="sb-nav-link-icon"><MdFastfood fontSize={18} /></div>
                                                                                ACCIONES
                                                                            <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                                                        </a>

                                                    
                                                                        <div className="collapse" id="cafeteriaCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">

                                                                            <ul className="navbar-nav flex-grow">

                                                                                <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                    <NavLink tag={Link} className="nav-link" to="/cafeteria">ASIGNACIÓN DE COMIDA</NavLink>
                                                                                </NavItem>    

                                                                            </ul>
                                                
                                                                        </div>

                                                                    </>
                                                                    
                                                                : <></>
                                                            }

                                                        </nav>
                                                    </div>


                                                    {
                                                        validacionRutas('ROLE_VER_HISTORICO_CLINICO')  | validacionRutas('ROLE_ADMIN') ?

                                                        <>
                                                        
                                                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#consultorioCollapse" aria-expanded="false" aria-controls="controlAsistenciaCollapse">
                                                                <div className="sb-nav-link-icon"><i className="bi bi-activity" style={{fontSize: 18}}></i></div>
                                                                    CONSULTORIO MÉDICO
                                                                <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                                            </a>
                                                            
                                                                <div className="collapse" id="consultorioCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                                                    <ul className="navbar-nav flex-grow">

                                                                        {
                                                                            validacionRutas('ROLE_VER_HISTORICO_CLINICO') | validacionRutas('ROLE_ADMIN') ?

                                                                            <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                                <NavLink tag={Link} className="nav-link" to="/historialClinico">HISTORIAL CLÍNICO</NavLink>
                                                                            </NavItem>

                                                                            : <></>
                                                                        }

                                                                    </ul>
                                                                </div>

                                                        
                                                        </>
                                                        
                                                        : <></>

                                                    }

                                                    {
                                                        validacionRutas('ROLE_DIGITAR_EMPLEADO_SORTEO') ?

                                                        <>
                                                        
                                                            <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#sorteoCollapse" aria-expanded="false" aria-controls="controlAsistenciaCollapse">
                                                            <div className="sb-nav-link-icon"><i className="fa-solid fa-gift" style={{fontSize: 18}}></i></div>
                                                                SORTEO
                                                            <div className="bg-white-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                                            </a>
                                                        
                                                            <div className="collapse" id="sorteoCollapse" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                                                <ul className="navbar-nav flex-grow">

                                                                    {
                                                                        validacionRutas('ROLE_DIGITAR_EMPLEADO_SORTEO') ?

                                                                        <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                            <NavLink tag={Link} className="nav-link" to="/sorteo/agregarEmpleado">AGREGAR EMPLEADO</NavLink>
                                                                        </NavItem>

                                                                        : <></>
                                                                    }

                                                                    {
                                                                        validacionRutas('ROLE_GENERAR_SORTEO') ?

                                                                        <NavItem className="bg-white-menu-nested nav sidenav-menu-nested">
                                                                            <NavLink tag={Link} className="nav-link" to="/sorteo">SORTEO</NavLink>
                                                                        </NavItem>

                                                                        : <></>
                                                                    }
                                                                        
                                                                </ul>
                                                            </div>
                                                        
                                                        
                                                        
                                                        </>

                                                        :
                                                        <></>
                                                    }
                                                                                    
                        </div>
                    </div>
                </nav>
            </div>
        );
    }

   