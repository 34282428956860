import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "../config";
import { messageFrontEnd } from "../../utils/mensajeErrorCliente";
import { da } from "date-fns/locale";

const initialState = {
  isLoading: false,
  isSuccess: "idle",
  message: "",
  accionesReclutamiento: [],
  accionesPersonal: [],
  accionesReclutamientoCount: 0,
  accion: {}
};

export const fetchCambiarSuccess = createAsyncThunk(
  "acciones/fetchCambiarSuccess",
  async () => {
    return null;
  }
);

// * OBTENER VISITA A TRAVES DEL ID.
export const fetchPostAccionReclutamiento = createAsyncThunk(
  "acciones/fetchPostAccionReclutamiento",

  async (data) => {
    try {
      var resultado = await axiosApiInstance.post(
        endpoints.acciones.createReclutamiento,
        data
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response.data.data;
    }
  }
);

export const fetchUpdateAccion = createAsyncThunk(
  "acciones/fetchUpdateAccion",

  async (data) => {
    try {
      var resultado = await axiosApiInstance.post(
        endpoints.acciones.updateAccion,
        data
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response.data.data;
    }
  }
);

export const fetchPostAccionPersonal = createAsyncThunk(
  "acciones/fetchPostAccionPersonal",

  async (data) => {
    try {
      var resultado = await axiosApiInstance.post(
        endpoints.acciones.createPersonal,
        data
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response.data.data;
    }
  }
);

// * OBTENER LISTA DE ACCIONES DE RECLUTAMIENTO
export const fetchGetAccionesReclutamiento = createAsyncThunk(
  "acciones/fetchGetAccionesReclutamiento",

  async ({skipRow, rowsPerPage, empleado}) => {
    try {
      var resultado = await axiosApiInstance.post(
        `${endpoints.acciones.getListAccionesReclutamiento}/${skipRow}/${rowsPerPage}`, empleado
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }
);

// * OBTENER LISTA DE ACCIONES DE PERSONAL
export const fetchGetAccionesPersonal = createAsyncThunk(
  "acciones/fetchGetAccionesPersonal",

  async (empleado) => {
    try {
      var resultado = await axiosApiInstance.post(
        endpoints.acciones.getListAccionesPersonal, empleado
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }
);

// * OBTENER CANTIDAD DE ACCIONES DE RECLUTAMIENTO.
export const fetchGetAccionesReclutamientoCount = createAsyncThunk(
  "acciones/fetchGetAccionesReclutamientoCount",

  async (empleado) => {
    try {
      var resultado = await axiosApiInstance.post(
        endpoints.acciones.getCountAccionesReclutamiento, empleado
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }
);

// * OBTENER ACCION POR ID.
export const fetchGetAccionById = createAsyncThunk(
  "acciones/fetchGetAccionById",

  async (accionId) => {
    try {
      var resultado = await axiosApiInstance.get(
        `${endpoints.acciones.getAccionById}/${accionId}`
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response.data;
    }
  }
);

export const fetchProcesarAccionReclutamiento = createAsyncThunk(
  "acciones/fetchProcesarAccionReclutamiento",

  async (data) => {
    try {
      var resultado = await axiosApiInstance.post(
        endpoints.acciones.procesarAccionReclutamiento, data
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response.data;
    }
  }
);

export const fetchProcesarAccionPersonal = createAsyncThunk(
  "acciones/fetchProcesarAccionPersonal",

  async (data) => {
    try {
      var resultado = await axiosApiInstance.post(
        endpoints.acciones.procesarAccionPersonal, data
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response.data;
    }
  }
);

export const fetchCancelarAccionPersonal = createAsyncThunk(
  "acciones/fetchCancelarAccionPersonal",

  async (data) => {
    try {
      var resultado = await axiosApiInstance.post(
        endpoints.acciones.cancelarAccionPersonal, data
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }
);

export const fetchCancelarAccionReclutamiento = createAsyncThunk(
  "acciones/fetchCancelarAccionReclutamiento",

  async (data) => {
    try {
      var resultado = await axiosApiInstance.post(
        endpoints.acciones.cancelarAccionReclutamiento, data
      );
      return resultado.data;
    } catch (error) {
      console.error(error);
      return error.response;
    }
  }
);

export const fetchGenerarReporteAccionPDF = createAsyncThunk(
  "evaluaciones/fetchGenerarReporteAccionPDF",
  async (accion) => {

    // * DESESTRUCTURACION DE DATOS.
    const { accionId, empleado: { nombreLabel }, categoriaId  } = accion;
    
    const result = await axiosApiInstance({
      url: `${
        endpoints.acciones.reporteAccion
      }?accionId=${accionId}`, //your url

      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      const nombreArchivo = categoriaId === 1 ? 
                            `Acción de Reclutamiento de ${nombreLabel}`
                            : `Acción Personal de ${nombreLabel}`

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `${nombreArchivo}.pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    return result;
  }
);


export const AccionesSlice = createSlice({
  name: "acciones",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCambiarSuccess.fulfilled, (state) => {
        state.isSuccess = "idle";
      })
      .addCase(fetchPostAccionReclutamiento.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchPostAccionReclutamiento.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;
        } else {
          state.isSuccess = "error";
          state.message =
            action.payload?.code === 400
              ? action.payload.message[0]
              : action.payload.message;
        }
        state.isLoading = false;
      })
      .addCase(fetchPostAccionReclutamiento.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error.message);
      })
      .addCase(fetchPostAccionPersonal.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchPostAccionPersonal.fulfilled, (state, action) => {
        if (action.payload?.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;
        } else {
          state.isSuccess = "error";
          state.message =
            action.payload?.code === 400
              ? action.payload.message[0]
              : action.payload.message;
        }
        state.isLoading = false;
      })
      .addCase(fetchPostAccionPersonal.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error.message);
      })
      .addCase(fetchUpdateAccion.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchUpdateAccion.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;
        } else {
          state.isSuccess = "error";
          state.message =
            action.payload?.code === 400
              ? action.payload.message[0]
              : action.payload.message;
        }
        state.isLoading = false;
      })
      .addCase(fetchUpdateAccion.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error.message);
      })
      .addCase(fetchGetAccionesReclutamiento.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchGetAccionesReclutamiento.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;
          state.accionesReclutamiento = action.payload.data;

        } else {
          state.isSuccess = "error";
          state.accionesReclutamiento = [];
          state.message = messageFrontEnd();
          console.error(action.payload.message);
        }
        
        state.isLoading = false;
      })
      .addCase(fetchGetAccionesReclutamiento.rejected, (state, action) => {
        state.isSuccess = "error";
        state.accionesReclutamiento = [];
        state.message = messageFrontEnd();
        state.isLoading = false;
        console.error(action.payload.message);
      })
      .addCase(fetchGetAccionesPersonal.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchGetAccionesPersonal.fulfilled, (state, action) => {

        if (action.payload?.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;
          state.accionesPersonal = action.payload.data;

        } else {
          state.isSuccess = "error";
          state.accionesPersonal = [];
          state.message = messageFrontEnd();
          console.error(action.payload?.message);
        }
        
        state.isLoading = false;
      })
      .addCase(fetchGetAccionesPersonal.rejected, (state, action) => {
        state.isSuccess = "error";
        state.accionesPersonal = [];
        state.message = messageFrontEnd();
        state.isLoading = false;
        console.error(action.payload.message);
      })
      .addCase(fetchGetAccionesReclutamientoCount.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchGetAccionesReclutamientoCount.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;
          state.accionesReclutamientoCount = action.payload.data;

        } else {
          state.isSuccess = "error";
          state.accionesReclutamientoCount = 0;
          state.message = messageFrontEnd();
          console.error(action.payload.message);
        }
        
        state.isLoading = false;
      })
      .addCase(fetchGetAccionesReclutamientoCount.rejected, (state, action) => {
        state.isSuccess = "error";
        state.accionesReclutamientoCount = 0;
        state.message = messageFrontEnd();
        state.isLoading = false;
        console.error(action.payload.message);
      })
      .addCase(fetchGetAccionById.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchGetAccionById.fulfilled, (state, action) => {
        if (action.payload?.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;
          state.accion = action.payload.data;

        } else {
          state.isSuccess = "error";
          state.accion = {};
          state.message = messageFrontEnd();
          console.error(action.payload.message);
        }
        
        state.isLoading = false;
      })
      .addCase(fetchGetAccionById.rejected, (state, action) => {
        state.isSuccess = "error";
        state.accion = {};
        state.message = messageFrontEnd();
        state.isLoading = false;
        console.error(action.payload?.message);
      })
      .addCase(fetchProcesarAccionReclutamiento.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchProcesarAccionReclutamiento.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;

        } else {
          state.isSuccess = "error";
          state.message = messageFrontEnd();
          console.error(action.payload.message);
        }
        
        state.isLoading = false;
      })
      .addCase(fetchProcesarAccionReclutamiento.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        state.isLoading = false;
        console.error(action.payload.message);
      })
      .addCase(fetchProcesarAccionPersonal.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchProcesarAccionPersonal.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;

        } else {
          state.isSuccess = "error";
          state.message = action.payload.message;
        }
        
        state.isLoading = false;
      })
      .addCase(fetchProcesarAccionPersonal.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        state.isLoading = false;
        console.error(action.payload.message);
      })
      .addCase(fetchCancelarAccionPersonal.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchCancelarAccionPersonal.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;

        } else {
          state.isSuccess = "error";
          state.message = messageFrontEnd();
          console.error(action.payload.message);
        }
        
        state.isLoading = false;
      })
      .addCase(fetchCancelarAccionPersonal.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        state.isLoading = false;
        console.error(action.payload.message);
      })
      .addCase(fetchCancelarAccionReclutamiento.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchCancelarAccionReclutamiento.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;

        } else {
          state.isSuccess = "error";
          state.message = messageFrontEnd();
          console.error(action.payload.message);
        }
        
        state.isLoading = false;
      })
      .addCase(fetchCancelarAccionReclutamiento.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        state.isLoading = false;
        console.error(action.payload.message);
      })
      .addCase(fetchGenerarReporteAccionPDF.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchGenerarReporteAccionPDF.fulfilled, (state, action) => {
        state.isSuccess = "success";
        state.message = "El reporte fue generado correctamente.";
        state.isLoading = false;
      })
      .addCase(fetchGenerarReporteAccionPDF.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = action.error.message;
        state.isLoading = false;
      })
      
  },
});

export const selectIsLoading = (state) => state.acciones.isLoading;
export const selectIsSuccess = (state) => state.acciones.isSuccess;
export const selectMessage = (state) => state.acciones.message;
export const selectListaAccionesReclutamiento = (state) => state.acciones.accionesReclutamiento;
export const selectListaAccionesPersonal = (state) => state.acciones.accionesPersonal;
export const selectCountAccionesReclutamiento = (state) => state.acciones.accionesReclutamientoCount;
export const selectAccion = (state) => state.acciones.accion;

export const {} = AccionesSlice.actions;
export default AccionesSlice.reducer;
