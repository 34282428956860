import React, { useState } from "react";
import { useDispatch } from "react-redux";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ComposicionFamiliarEditar from "../Modals/ComposicionFamiliar/ComposicionFamiliarEditar";
import moment from "moment";
import swal from "sweetalert";

import { PermissionData } from "../../context/Component/PermissionData";

import { 
  deleteEmpleadoComposicionFamiliar,
} 
from "./../../redux/Empleados/EmpleadosSlice";

export const ComposicionFamiliar = ({ familia, index }) => {
	

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  // ? HOOK-STATE
  const [openComposicionFamiliar, setOpenComposicionFamiliar] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const { nombres, apellidos, sexo, parentesco, fechaNacimiento, empleadoId } = familia;
  
  // * FUNCIONES
  const deleteDependiente = () => {
    swal({
      title: "Esta seguro de eliminar el dependiente?",
      text: "Una vez eliminado, no estara nuevamente disponible!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setAddRequestStatus("success");
        dispatch(deleteEmpleadoComposicionFamiliar(familia));
      } else {
      }
    });
  };

  return (
    <div className="mt-2 pt-3 pb-2 mb-2 border ">
      <div className="row ">
        <div className="col-md-1 text-center align-self-center">
          <h1 className="text-primary-mem">{index + 1}</h1>
        </div>
        <div className="col-md-3 align-self-center">
          <h6 className="text-primary-mem">{`${nombres} ${apellidos}`}</h6>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Parentesco</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">{parentesco.nombre}</label>
          </div>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Sexo</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">{sexo}</label>
          </div>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Fecha Nacimiento</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">
              {moment(fechaNacimiento).format("DD/MM/yyyy")}
            </label>
          </div>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <button
              id='btn-editar-composicion-familiar'
              hidden={!permisosState.ROLE_EDITAR_REGISTRO_EMPLEADOS}
              type="button"
              className="btn btn-sm"
              onClick={() => setOpenComposicionFamiliar(true)}
            >
              {" "}
              <BorderColorIcon className="text-primary-mem" />
            </button>
          </div>
          <div className="col-md-12 text-center">
            {
              <button
                id='btn-borrar-composicion-familiar'
                type="button"
                hidden={!permisosState.ROLE_BORRAR_REGISTRO_EMPLEADOS}
                className="btn btn-sm mt-2 "
                onClick={() => deleteDependiente()}
              >
                {" "}
                <DeleteOutlineOutlinedIcon className="text-danger" />
              </button>
            }
          </div>
        </div>
      </div>
      <ComposicionFamiliarEditar
        familia={familia}
        openComposicionFamiliar={openComposicionFamiliar}
        setOpenComposicionFamiliar={setOpenComposicionFamiliar}
        empleadoId={familia.empleadoId}
      />
    
    </div>
  );
};

export default ComposicionFamiliar;
