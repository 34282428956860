import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { BorderColor, Delete } from '@mui/icons-material';
import {PermissionData} from '../../context/Component/PermissionData';
import { IconButton } from '@mui/material';

function Tables({setOpenEdit, setArea, deleteAreaOrganizacional, rowsColumn}) {

	// * PERMISOS DEL USUARIO.
	const permisosState = PermissionData();

	const handleArea = (data) => {
		setArea(data);
		setOpenEdit(true);
	}
	const deleteArea = (data) => {
		setArea(data);
		deleteAreaOrganizacional(data);
	}

	return (
		<>
		<TableContainer component={Paper}>
			
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						{/* {pruebaColumn} */}
						<TableCell className="fw-bold" align="left">
							#
						</TableCell>
						<TableCell className="fw-bold" align="left">
							Área Organizacional
						</TableCell>
						<TableCell className="fw-bold" align="center">
							Cantidad Empleados
						</TableCell>
						<TableCell className="fw-bold" align="center" width="10%">
							Acciones
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>

					{rowsColumn.map((row, index) => (
						<TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row">
								{index + 1}
							</TableCell>
							<TableCell component="th" scope="row">
								{row.nombre.replaceAll("-",'. ')}
							</TableCell>
							<TableCell align="center" component="th" scope="row" >
								{row.cantidadEmpleados}
							</TableCell>

							<TableCell component="th" scope="row">
								
								<div className="d-flex justify-content-between">
									<IconButton className="text-primary-mem" hidden={!permisosState.ROLE_EDITAR_AREAS_ORGANIZACIONALES} onClick={() => handleArea(row)} >
										<BorderColor />
									</IconButton>
									
									{row.cantidadEmpleados === 0 && 
									
										<IconButton className="text-danger" hidden={!permisosState.ROLE_BORRAR_AREAS_ORGANIZACIONALES} onClick={() => deleteArea(row)} >
											<Delete />
										</IconButton>
									}
								</div>

							</TableCell>
						</TableRow>
					))}
		
				</TableBody>

			</Table>
		</TableContainer>
		</>
	);
}

export default Tables;
