import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { IoAddCircle } from "react-icons/io5";
import { CleaningServices } from "@mui/icons-material";
import { Typography } from '@mui/material';
import { Controller } from "react-hook-form";
import Select from "react-select";

import { PermissionData } from "../../context/Component/PermissionData";

import {
    selectAllEmpleadosList,
    fetchAllEmpleados,
} from "../../redux/Empleados/EmpleadosSlice";

import {
    fetchAreasOrganizacionales,
    selectAllAreasOrganizacionales,
} from "../../redux/AreaOrganizacional/AreaOrganizacionalSlice";

import { 
    fetchCargosAll, 
    selectAllCargos 
  } from '../../redux/Cargos/CargosSlice';


export const FiltroHistoricoClinico = ({ register, control, reset}) => {

    // * PERMISOS DEL USUARIO.
    const permisosState = PermissionData();

    // ? HOOK-NAVEGACION
    const navigate = useNavigate();

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();
    
    // ? HOOK-SELECTOR
    const empleadosList = useSelector(selectAllEmpleadosList);
    const listAreaOrganizacionales = useSelector(selectAllAreasOrganizacionales);
    const listCargos = useSelector(selectAllCargos);
    
    // ? HOOK-STATE DE LISTAS
    const [listaAreasState, setListaAreasState] = useState([]);
    const [listaEmpleadosState, setListaEmpleadosState] = useState([]);
    const [listCargosState, setCargosState] = useState([]);


    useEffect(() => {
        setListaAreasState([
            { id: 0, nombre: "Seleccionar Área" },
            ...listAreaOrganizacionales,
        ]);

        setListaEmpleadosState([
            { id: 0, nombreLabel: "Seleccionar Empleado" },
            ...empleadosList,
        ]);

        setCargosState([
            { id: 0, titulo: "Seleccionar Cargo" },
            ...listCargos,
        ]);

    }, [listAreaOrganizacionales, empleadosList, listCargos])


    useEffect(() => {
        dispatch(fetchAllEmpleados());
        dispatch(fetchAreasOrganizacionales());
        dispatch(fetchCargosAll());
    }, [])

    // * VALIDACION PARA HABILITAR EL BOTON SEGUN EL TIPO DE PERMISO
    const permisosValidate = permisosState.ROLE_AGREGAR_HISTORICO_CLINICO || permisosState.ROLE_ADMIN ? true : false;

  return (
    <div className='border m-1 p-2 d-flex justify-content-between'>
        
        <div className='col-3'>
            <div className="form-group">
                <Typography fontSize='12px' fontWeight='bold'>Empleado</Typography>

                <Controller
                control={control}
                name="empleadoId"
                {...register("empleado.empleadoId")}
                render={({ field }) => (
                    <Select
                    id='empleadoId'
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Seleccionar Empleado"
                    options={listaEmpleadosState}
                    getOptionLabel={(option) => option.nombreLabel}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => field.onChange(e.id)}
                    value={listaEmpleadosState.find(x => x.id === field.value)}
                    />
                )}
                />

            </div>
        </div>

        <div className='col-3'>
            <Typography fontSize='12px' fontWeight='bold'>Área Organizacional</Typography>
            <Controller
                control={control}
                name="areaId"
                {...register("empleado.areaId")}
                render={({ field }) => (
                <Select
                    id='areaId'
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Seleccionar Área"
                    options={listaAreasState}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => field.onChange(e.id)}
                    value={listaAreasState.find(x => x.id === field.value)}
                />
                )}
            />
        </div>
        
        <div className='col-3'>
            <Typography fontSize='12px' fontWeight='bold'>Cargo</Typography>
            <Controller
                control={control}
                name="areaId"
                {...register("empleado.cargoId")}
                render={({ field }) => (
                <Select
                    id='cargoId'
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Seleccionar Cargo"
                    options={listCargosState}
                    getOptionLabel={(option) => option.titulo}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => field.onChange(e.id)}
                    value={listCargosState.find(x => x.id === field.value)}
                />
                )}
            />
        </div>

        <div className='col-1'>
            <button id='btn-clear' className='btn btn-danger' style={{marginTop: 17}} onClick={() => reset()}><CleaningServices /></button>
        </div>
        
        <div className="col-1 d-flex justify-content-end">
            <button id='btn-agregar' hidden={!permisosValidate} className='btn btn-primary-mem' onClick={() => navigate('/historialClinico/agregar')}>Agregar <IoAddCircle style={{marginBottom: 4}} fontSize='22' /></button>
        </div>
    </div>
  )
}
