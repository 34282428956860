import React from 'react';
import { Avatar } from "@mui/material";

export const SorteoEmpleadoCard = ({data}) => {

  return (
    <div className="row mt-2 border rounded-3 ms-1 me-1 pt-2 pb-3">

        <div className="col-1 align-self-center">
            <Avatar
                sx={{
                width: 80,
                height: 80,
                marginLeft: 0,
                border: "solid",
                borderWidth: 0.5,
                borderColor: "#003876",
                }}
                // alt={`${empleado.nombres} ${empleado.apellidos} `}
                src={`https://sireh.mem.gob.do/img/profile/${data?.empleadoId}.jpg`}
                // src={`${endpoints.fetchEmpleadoImagen}${empleado?.codigoEmpleado}.jpg?${new Date().getTime()}`}
            />
            <label className="codigoEmpleado ms-3">
                {data?.codigoEmpleado}
            </label>
        </div>

        <div className="col">
        
            <div>
                <h3 className='mt-3'>{data?.nombreLabel}</h3>
                <p style={{marginBottom: 0}}>{data?.cargo}</p>
            </div>
        
        </div>        

    </div>
  )
}
