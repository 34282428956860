import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../config";
import axiosApiInstance from "../../utils/apiClient";

const initialState = {
  isSuccess: "idle",
  isLoading: false,
  message: "",
  listaPonches: [],
};

export const fetchListaAsistencias = createAsyncThunk(
  "reporteAsistencia/fetchListaAsistencias",
  async (filtroPonches) => {
    let resultado = await axiosApiInstance.post(
      `${endpoints.reporteAsistencias.asistencias}`,
      filtroPonches
    );
    return resultado.data;
  }
);

export const fetchLimpiarAsistencia = createAsyncThunk(
  "reporteAsistencia/fetchLimpiarAsistencia",
  async () => {
    return {};
  }
);

export const fetchListaAsistenciasExportar = createAsyncThunk(
  "reporteAsistencia/fetchListaAsistenciasExportar",
  async (filtroPonches) => {
    const { areaId, desde, empleadoId, estado, hasta, todos } = filtroPonches;

    const result = await axiosApiInstance({
      url: `${
        endpoints.reporteAsistencias.reporteAsistenciaExportar
      }?EmpleadoId=${Number(empleadoId)}&AreaId=${Number(areaId)}
      &Estado=${estado}&Desde=${desde}&Hasta=${hasta}&Todos=${todos}`, //your url

      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `Reporte de Asistencia (${desde} - ${hasta}).xls`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    return result;
  }
);

export const fetchReporteAbsentismo = createAsyncThunk(
  "reporteAsistencia/fetchReporteAbsentismo",
  async (ano) => {

    const result = await axiosApiInstance({
      url: `${
        endpoints.reporteAsistencias.reporteAbsentismo
      }?ano=${ano}`, //your url

      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `Reporte Absentismo (${ano}).xls`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    return result;
  }
);

export const fetchCambiarSuccess = createAsyncThunk(
  "reporteAsistencias/fetchCambiarSuccess",
  async () => {
    return null;
  }
);

const ReporteAsistenciasSlice = createSlice({
  name: "reporteAsistencias",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchListaAsistencias.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchListaAsistencias.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.message = "";
          state.isLoading = false;
          state.listaPonches = action.payload.data;
        } else {
          state.isSuccess = "error";
          state.message = action.payload.message;
          state.isLoading = false;
          state.listaPonches = [];
        }
      })
      .addCase(fetchListaAsistencias.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = action.error.message;
        state.listaPonches = [];
        state.isLoading = false;
      })

      .addCase(fetchListaAsistenciasExportar.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchListaAsistenciasExportar.fulfilled, (state, action) => {
        state.isSuccess = "success";
        state.message = "";
        state.linkDescarga = action.payload?.data;
        state.isLoading = false;
      })
      .addCase(fetchListaAsistenciasExportar.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = action.error.message;
        state.linkDescarga = null;
        state.isLoading = false;
      })
      .addCase(fetchReporteAbsentismo.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchReporteAbsentismo.fulfilled, (state, action) => {
        state.isSuccess = "success";
        state.message = "";
        state.linkDescarga = action.payload?.data;
        state.isLoading = false;
      })
      .addCase(fetchReporteAbsentismo.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = action.error.message;
        state.linkDescarga = null;
        state.isLoading = false;
      })
      .addCase(fetchCambiarSuccess.fulfilled, (state, action) => {
        state.isSuccess = "idle";
      })
      .addCase(fetchLimpiarAsistencia.fulfilled, (state, action) => {
        state.listaPonches = [];
      });
  },
});

export const selectListadoPonches = (state) =>
  state.reporteAsistencias.listaPonches;
export const selectIsLoading = (state) => state.reporteAsistencias.isLoading;
export const selectMessage = (state) => state.reporteAsistencias.message;
export const selectIsSuccess = (state) => state.reporteAsistencias.isSuccess;
export const selectLinkDescarga = (state) =>
  state.reporteAsistencias.linkDescarga;

export default ReporteAsistenciasSlice.reducer;
