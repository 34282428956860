import axios from "axios";
import { 
  fetchRefreshToken, 
  fetchLogoutSesionVencida 
} from "../redux/Login/LoginSlice";

// * VARIABLE DE LA STORE PARA INYECTAR LAS DEPENDENCIAS DEL STORAGE.
let storeInject;

let isRefreshing = false;
const refreshAndRetryQueue = [];

// * INYECTANDO DEPENDENCIAS PARA UTILIZAR EL DISPATCH DEL STORAGE.
export const injectStore = _store => {
  storeInject = _store
}

// * INSTANCIA PARA JSON.
const axiosApiInstance = axios.create();

// * INSTANCIA PARA FILES
export const axiosApiInstanceFiles = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const key = localStorage.getItem("token");
    config.headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${key}`,
      Accept: "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Request interceptor for API calls
axiosApiInstanceFiles.interceptors.request.use(
  async (config) => {
    const key = localStorage.getItem("token");
    config.headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/octet-stream",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${key}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const mensajeCaducado = "La sesión caducó";
    const tipoMensaje = 'error';

      if (error.response && error.response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;

          try {

              // Refresh the access token
              const newAccessToken = await storeInject.dispatch(fetchRefreshToken());;

              if(!newAccessToken.payload.isSuccess){
                await storeInject.dispatch(fetchLogoutSesionVencida({mensajeCaducado, tipoMensaje}));
                return Promise.reject(error);
              }
              
              // Update the request headers with the new access token
              error.config.headers['Authorization'] = `Bearer ${newAccessToken.payload.data.accessToken}`;
              
              // Retry all requests in the queue with the new token
              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                axiosApiInstance
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });

              // Clear the queue
              refreshAndRetryQueue.length = 0;

              // Retry the original request
              return axiosApiInstance(originalRequest);

            } catch (refreshError) {
                await storeInject.dispatch(fetchLogoutSesionVencida({mensajeCaducado, tipoMensaje}));
                return Promise.reject(error);
            } finally {
              isRefreshing = false;
            }
      }
  
        // Add the original request to the queue
        return new Promise((resolve, reject) => {
          refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
        });
      }
  
      // Return a Promise rejection if the status code is not 401
      return Promise.reject(error);
  }

);

axiosApiInstanceFiles.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const mensajeCaducado = "La sesión caducó";
    const tipoMensaje = 'error';

      if (error.response && error.response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;

          try {

              // Refresh the access token
              const newAccessToken = await storeInject.dispatch(fetchRefreshToken());;

              if(!newAccessToken.payload.isSuccess){
                await storeInject.dispatch(fetchLogoutSesionVencida({mensajeCaducado, tipoMensaje}));
                return Promise.reject(error);
              }
              
              // Update the request headers with the new access token
              error.config.headers['Authorization'] = `Bearer ${newAccessToken.payload.data.accessToken}`;
              
              // Retry all requests in the queue with the new token
              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                axiosApiInstance
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });

              // Clear the queue
              refreshAndRetryQueue.length = 0;

              // Retry the original request
              return axiosApiInstance(originalRequest);

            } catch (refreshError) {
                await storeInject.dispatch(fetchLogoutSesionVencida({mensajeCaducado, tipoMensaje}));
                return Promise.reject(error);
            } finally {
              isRefreshing = false;
            }
      }
  
        // Add the original request to the queue
        return new Promise((resolve, reject) => {
          refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
        });
      }
  
      // Return a Promise rejection if the status code is not 401
      return Promise.reject(error);
  }

);

export const diasSemana = [
  { id: 1, value: "Lunes" },
  { id: 2, value: "Martes" },
  { id: 3, value: "Miercoles" },
  { id: 4, value: "Jueves" },
  { id: 5, value: "Viernes" },
  { id: 6, value: "Sabado" },
  { id: 7, value: "Domingo" },
];

export default axiosApiInstance;
