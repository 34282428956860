import React from "react";
import { Route, Routes, Navigate } from "react-router";
import { RequiredPermission } from '../authentication/RequiredPermission';

import { 
  AccionesPersonal, 
  AccionesPersonalAgregar,
  AccionesPersonalDetalle,
  ReclutamientoSeleccion, 
  ReclutamientoSeleccionAgregar, 
  ReclutamientoSeleccionDetalle
} from '../pages/Acciones'

export const RutasAcciones = () => {
  return (
    <Routes>
      
      <Route element={<RequiredPermission permission='ROLE_VER_ACCIONES'/>}> 
        <Route path="/personal" element={<AccionesPersonal />}/>
        <Route path="/personal/detalle/:accionId" element={<AccionesPersonalDetalle />}/>
      </Route>

      <Route element={<RequiredPermission permission='ROLE_ADMIN'/>}> 
        <Route path="/personal" element={<AccionesPersonal />}/>
        <Route path="/personal/detalle/:accionId" element={<AccionesPersonalDetalle />}/>
      </Route>

      <Route element={<RequiredPermission permission='ROLE_PERSONAL_ACCIONES'/>}>
        <Route path="/personal/agregar" element={<AccionesPersonalAgregar />}/>
      </Route>
     
      <Route element={<RequiredPermission permission='ROLE_ADMIN'/>}>
        <Route path="/personal/agregar" element={<AccionesPersonalAgregar />}/>
      </Route>
  
      <Route element={<RequiredPermission permission='ROLE_RECLUTAMIENTO_ACCIONES'/>}>
        <Route path="/reclutamiento/agregar" element={<ReclutamientoSeleccionAgregar />}/>
      </Route>
      
      <Route element={<RequiredPermission permission='ROLE_ADMIN'/>}>
        <Route path="/reclutamiento/agregar" element={<ReclutamientoSeleccionAgregar />}/>
      </Route>
    
      <Route element={<RequiredPermission permission='ROLE_VER_ACCIONES'/>}> 
        <Route path="/reclutamiento" element={<ReclutamientoSeleccion />}/>
        <Route path="/reclutamiento/detalle/:accionId" element={<ReclutamientoSeleccionDetalle />}/>
      </Route>
      
      <Route element={<RequiredPermission permission='ROLE_ADMIN'/>}> 
        <Route path="/reclutamiento" element={<ReclutamientoSeleccion />}/>
        <Route path="/reclutamiento/detalle/:accionId" element={<ReclutamientoSeleccionDetalle />}/>
      </Route>

      <Route path='/*' element={<Navigate to="/" replace />} />

    </Routes>
  );
};
