import { useContext } from "react";
import {PermissionContext} from '../PermissionContext'

export const PermissionData = () => {
  // * CONTEXT DEL PERMISO.
  const context = useContext(PermissionContext);

  // * DESESTRUCTURACION DE LOS DATOS.
  const { permisosState } = context.value;

  return permisosState;
};
