import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, Autocomplete, Box } from "@mui/material";
import { TbMailForward } from "react-icons/tb";

import { meses } from "../../../utils/meses";
import { validateDateRange } from "../../../utils/validateDateRangeNomina";

import {
  fetchFiltrarNominasEmpleados,
  fetchPeriodosSubirNomina,
  selectPeriodoSubirNomina,
  selectListaProgramas,
  selectEstadoEnvioCorreos,
  fetchNominaListaPeriodoAgregar,
  fetchNominaListaProgramas,
  fetchVerificarEstadoEnvioCorreos,
  fetchEnvioCorreos,
} from "../../../redux/Nominas/NominaSlice";

export const NominaFiltroBusquedaCard = ({empleado, setEmpleado, setStatusRequest, listaNominaEmpleadosFiltradoLength}) => {
  
  // ? HOOK DISPATCH
  const dispatch = useDispatch();

  // ? FECHA ACTUAL
  const fechaActual = new Date();

  // ? HOOK-SELECTOR
  const listadoPeriodos = useSelector(selectPeriodoSubirNomina);
  const listaProgramas = useSelector(selectListaProgramas);
  const estadoEnvioCorreos = useSelector(selectEstadoEnvioCorreos);

  // ? HOOK-STATE
  const [filtrarNominasEmpleados, setFiltrarNominasEmpleados] = useState({
    ano: fechaActual.getFullYear().toString(),
    mes: fechaActual.getMonth() + 1,
    programaId: listaProgramas[0],
    areaOrganizacionalId: 0,
    encargadoId: 0,
  });

  // ? USE-EFFECT
  useEffect(() => {
    dispatch(fetchNominaListaPeriodoAgregar());
    dispatch(fetchPeriodosSubirNomina());
    dispatch(fetchNominaListaProgramas());
    dispatch(fetchVerificarEstadoEnvioCorreos());
  }, []);

  // ? USE-EFFECT PARA AGREGAR VALORES POR DEFECTO.
  useEffect(() => {
    const mes = meses.find(x => x.noEntero === fechaActual.getMonth() + 1).no;
    const programaId = listaProgramas[0]?.id ?? 0;
    setFiltrarNominasEmpleados({
      ...filtrarNominasEmpleados,
      ["mes"]: mes,
      ["programaId"]: programaId,
    });
  }, [listaProgramas, listadoPeriodos]);

  // * FUNCION PARA GUARDAR LA DATA DEL FORMULARIO EN EL USE-STATE
  const saveData = (name, value) => {
    setFiltrarNominasEmpleados({ ...filtrarNominasEmpleados, [name]: value });
  };

  // * FUNCION PARA ENVIAR DATA A LA API.
  const sendDataApi = () => {
    setStatusRequest('success');
    dispatch(fetchFiltrarNominasEmpleados(filtrarNominasEmpleados))
  };

  // * ENVIAR PETICION PARA ENVIO DE CORREOS
  const sendEmails = () => {

    swal({
      title: "Estás seguro de notificar el envío de correos de volantes de pago a los colaboradores?",
      text: "Una vez enviado no se podrá anular el envío masivo.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(fetchEnvioCorreos());
      }
    });

  }

  return (
    <>
      <div className="card mb-4">
        <div className="card-body">
          <div className="row justify-content-center">
            <div className="col-1">
              <div className="form-group">
                <Autocomplete
                  id="periodo"
                  name="periodo"
                  className="mb-3"
                  disablePortal
                  disableClearable
                  getOptionLabel={(listadoPeriodos) =>
                    `${listadoPeriodos.fecha}`
                  }
                  options={listadoPeriodos}
                  value={{ fecha: filtrarNominasEmpleados.ano }}
                  isOptionEqualToValue={(option, value) =>
                    option.fecha === value.fecha
                  }
                  noOptionsText={"No encontrado"}
                  renderOption={(props, listadoPeriodos) => (
                    <Box
                      component="option"
                      {...props}
                      key={listadoPeriodos.fecha}
                      value={listadoPeriodos.fecha}
                    >
                      {listadoPeriodos.fecha}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={" Año"} />
                  )}
                  onChange={(e) => saveData("ano", e.target.value)}
                  onKeyUp={(e) => saveData("ano", e.target.value)}
                />
              </div>
            </div>

            <div className="col-2">
              <div className="form-group">
                <Autocomplete
                  id="meses"
                  className="mb-2"
                  disablePortal
                  disableClearable
                  getOptionLabel={(meses) => `${meses.nombre}`}
                  options={meses}
                  isOptionEqualToValue={(option, value) =>
                    option.no === value.no
                  }
                  noOptionsText={"No encontrado"}
                  defaultValue={meses.find(x => x.noEntero === fechaActual.getMonth() + 1)}
                  renderOption={(props, meses) => (
                    <Box
                      component="option"
                      {...props}
                      key={meses.no}
                      value={meses.no}
                    >
                      {meses.nombre}
                    </Box>
                  )}
                  onChange={(e) => saveData("mes", e.target.value)}
                  onKeyUp={(e) =>
                    saveData(
                      "mes",
                      meses.find((x) => x.nombre === e.target.value)?.no ?? 0
                    )
                  }
                  renderInput={(params) => (
                    <TextField {...params} label={"Mes"} />
                  )}
                />
              </div>
            </div>

            <div className="col-3">
              <div className="form-group">
                <Autocomplete
                  id="programas"
                  className="mb-2"
                  disablePortal
                  disableClearable
                  defaultValue={{ id: 1, programa: "CONTRATADOS 01" }}
                  getOptionLabel={(listaProgramas) =>
                    `${listaProgramas.programa}`
                  }
                  options={listaProgramas}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  noOptionsText={"No encontrado"}
                  renderOption={(props, listaProgramas) => (
                    <Box
                      component="option"
                      {...props}
                      key={listaProgramas.id}
                      value={listaProgramas.id}
                    >
                      {listaProgramas.programa}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={"Programa"} />
                  )}
                  onChange={(e) => saveData("programaId", e.target.value)}
                  onKeyUp={(e) =>
                    saveData(
                      "programaId",
                      listaProgramas.find((x) => x.programa === e.target.value)
                        ?.id ?? 0
                    )
                  }
                />
              </div>
            </div>

            <div className="col-1">
              <button
                id="btn-buscar"
                className="btn btn-primary-mem"
                onClick={() => sendDataApi()}
              >
                {" "}
                Buscar <SearchIcon sx={{ marginTop: -0.5 }} />{" "}
              </button>
            </div>
            
            <div className="col-1">
              <button
              onClick={() => sendEmails()}
              disabled={!validateDateRange(fechaActual) || estadoEnvioCorreos}
              className="btn btn-success"
              >
                <TbMailForward fontSize={24}/>
              </button>
            </div>

            <div className="col-3">
              <TextField 
              disabled={listaNominaEmpleadosFiltradoLength < 1}
              label='Buscar Empleado' 
              value={empleado} 
              onChange={e => setEmpleado(e.target.value)} 
              fullWidth
              />
            </div>

          </div>
        </div>
      </div>
    </>
  );
};
