import React from "react";

export const ReporteAsistenciaEmpleadoCard = ({ empleado }) => {
  return (
    <div className="card mb-4">
      <div
        className="card-header fw-bold"
        style={{ backgroundColor: "#e2edfc" }}
      >
        Resumen
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-1">
            <div className="form-group">
              <div>
                <label className="fw-bold">Código</label>
              </div>
              <div>
                <label>{empleado.codigoEmpleado}</label>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <div>
                <label className="fw-bold">Empleado</label>
              </div>
              <div>
                <label>{`${empleado.nombres} ${empleado.apellidos}`}</label>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <div>
                <label className="fw-bold">Cargo</label>
              </div>
              <div>
                <label>{empleado.cargoLabel}</label>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <div>
                <label className="fw-bold">Àrea</label>
              </div>
              <div>
                <label>{empleado.areaOrganizacionalLabel}</label>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <div>
                <label className="fw-bold">Supervisor</label>
              </div>
              <div>
                <label>{empleado.encargado}</label>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <div>
                <label className="fw-bold">Horario</label>
              </div>
              <div>
                <label>{`${empleado.dias} ${empleado.horario}`}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReporteAsistenciaEmpleadoCard;
