import React from "react";
import { Route, Routes, Navigate } from "react-router";
import { AreaOrganizacionales, Cargos } from "../pages/AreaOrganizacional";
import {RequiredPermission} from '../authentication/RequiredPermission';

export const RutasAreasOrganizacionales = () => {
  return (
    <Routes>
      
      <Route element={<RequiredPermission permission='ROLE_VER_AREAS_ORGANIZACIONALES'/>}> 
        <Route path="/areasorganizacionales" element={<AreaOrganizacionales />}/>
      </Route>
    
      <Route element={<RequiredPermission permission='ROLE_VER_CARGOS'/>}> 
        <Route path="/cargos" element={<Cargos />}/>
      </Route>

      <Route path='/*' element={<Navigate to="/" replace />} />

    </Routes>
  );
};
