import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Encabezado } from '../../Components/Encabezado/Encabezado'
import { MisVolantesCard } from '../../Components/Nomina/Card/MisVolantesCard';
import TablePagination from '@mui/material/TablePagination';

import { selectUserData } from '../../redux/Login/LoginSlice';

import { 
    fetchNominaEmpleadoId,
    fetchCambiarSuccess,
    selectListaEmpleadoNomina,
    selectIsLoading,
    selectIsSuccess
} from '../../redux/Nominas/NominaSlice';
import { Typography } from '@mui/material';
import Loading from '../../Components/Modals/Loading/Loader';
import swal from 'sweetalert';

export const MisVolantes = () => {

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK SELECTOR
    const usuario = useSelector(selectUserData);
    const listaNominaEmpleado = useSelector(selectListaEmpleadoNomina);
    const isLoading = useSelector(selectIsLoading);
    const isSuccess = useSelector(selectIsSuccess);

    // ? HOOK-STATE
    const [listadoNominaEmpleadoFilter, setListadoNominaEmpleadoFilter] = useState([]);
    const [listadoNominaEmpleadoLength, setListadoNominaEmpleadoLength] = useState(0);
    
    // ? HOOK-STATE DE PAGINACION.
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(25);
    const [skipRow, setSkipRow] = useState(0);

    // * FUNCION PARA LIMPIAR EL SUCCESS
    const resetSuccess = () => dispatch(fetchCambiarSuccess());

    useEffect(() => {
      dispatch(fetchNominaEmpleadoId(usuario?.Id))
    }, [usuario])

    
    // ? FILTRADO DE LISTA A TRAVES DE USE-EFFECT.
    useEffect(() => {

        let inicio = rowsPerPage * page;
        let final = (rowsPerPage * page) + rowsPerPage;

        if(listaNominaEmpleado.length > 0){
        
        setListadoNominaEmpleadoFilter(listaNominaEmpleado.slice(inicio, final));
        setListadoNominaEmpleadoLength(listaNominaEmpleado.length);
        }

    }, [listaNominaEmpleado, page, skipRow, rowsPerPage])  

    useEffect(() => {
      
        if(isSuccess === 'success'){
            swal("Exitoso!", "El reporte fue generado correctamente.", "success");
            resetSuccess();
        }
        else if(isSuccess === 'error'){
            swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implementación de Sistemas.", "error");
            resetSuccess();
        }

    }, [isSuccess])
    
    
    // * FUNCION PARA LA PAGINACION
    const handleChangePage = (event, newPage) => {
        setSkipRow(newPage * rowsPerPage)
        setPage(newPage);
    };

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setSkipRow(0);
	};

  return (
    <Encabezado title={"Mis Volantes de Pagos"}>

        {
            listaNominaEmpleado.length > 0 ?

            <>

                <MisVolantesCard
                lista={listadoNominaEmpleadoFilter}
                />

                <TablePagination
                    component="div"
                    count={listadoNominaEmpleadoLength}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            
            </>

            : isLoading === false && listaNominaEmpleado.length === 0 &&

            <Typography>No se encontraron nóminas del empleado.</Typography>
        }

        <Loading open={isLoading} />

    </Encabezado>
  )
}
