import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';

import {
    TextField,
    Autocomplete,
    Box
} from "@mui/material";

import { meses } from '../../../utils/meses';

import { 
    fetchFiltrarNominasEmpleados,
    fetchNominaListaPeriodoVer, 
    selectListaPeriodoNominaVer
} from '../../../redux/Nominas/NominaSlice';

import { selectUserData } from '../../../redux/Login/LoginSlice';

export const NominaFiltroBusquedaColaboradoresCard = () => {

    // ? HOOK DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const listaPeriodos = useSelector(selectListaPeriodoNominaVer);
    const usuario = useSelector(selectUserData);
    
    // ? HOOK-STATE
    const [filtrarNominasEmpleados, setFiltrarNominasEmpleados] = useState({
        ano: new Date().getFullYear().toString(),
        mes: listaPeriodos[0],
        programaId: 0,
        areaOrganizacionalId: 0,
        encargadoId: 0
    });

    // ? USE-EFFECT
    useEffect(() => {
        dispatch(fetchNominaListaPeriodoVer());
    }, []);

    // ? USE-EFFECT PARA AGREGAR VALORES POR DEFECTO.
    useEffect(() => {
        const mes = meses[0].no;

        const filter ={
            mes: mes,
            areaOrganizacionalId: usuario?.AreaOrganizacionalId,
            encargadoId: usuario?.Id
        }

        setFiltrarNominasEmpleados({...filtrarNominasEmpleados, ...filter })
    }, [listaPeriodos]);

    
    // * FUNCION PARA GUARDAR LA DATA DEL FORMULARIO EN EL USE-STATE   
    const saveData = (name, value) => {
        setFiltrarNominasEmpleados({...filtrarNominasEmpleados, [name]: value});
    }

    // * FUNCION PARA ENVIAR DATA A LA API.
    const sendDataApi = () => dispatch(fetchFiltrarNominasEmpleados(filtrarNominasEmpleados));

  return (
    <>
    
        <div className="card mb-4">
            <div className="card-body">
                <div className="row justify-content-center">

                    <div className='col-2'>
                        <div className="form-group">
                            <Autocomplete
                                id="periodo"
                                name="periodo"
                                className="mb-2"
                                disablePortal
                                disableClearable
                                getOptionLabel={(listaPeriodos) =>
                                `${listaPeriodos.fecha}`
                                }
                                options={listaPeriodos}
                                defaultValue={{fecha: new Date().getFullYear().toString()}}
                                isOptionEqualToValue={(option, value) => option.fecha === value.fecha}
                                noOptionsText={"No encontrado"}
                                renderOption={(props, listaPeriodos) => (
                                <Box
                                    component="option"
                                    {...props}
                                    key={listaPeriodos.fecha}
                                    value={listaPeriodos.fecha}
                                >
                                    {listaPeriodos.fecha}
                                </Box>
                                )}
                                renderInput={(params) => <TextField {...params} label={" Año"} />}
                                onChange={e => saveData('ano', e.target.value)}
                                onKeyUp={e => saveData('ano', e.target.value)}
                            />

                            </div>
                    </div>

                    <div className='col-2'>
                        <div className="form-group">
                            <Autocomplete
                                id="meses"
                                className="mb-2"
                                disablePortal
                                disableClearable
                                getOptionLabel={(meses) =>
                                `${meses.nombre}`
                                }
                                options={meses}
                                isOptionEqualToValue={(option, value) => option.no === value.no}
                                noOptionsText={"No encontrado"}
                                defaultValue={meses[0]}
                                renderOption={(props, meses) => (
                                <Box
                                    component="option"
                                    {...props}
                                    key={meses.no}
                                    value={meses.no}
                                >
                                    {meses.nombre}
                                </Box>
                                )}
                                onChange={e => saveData('mes', e.target.value)}
                                onKeyUp={e => saveData('mes', meses.find(x => x.nombre === e.target.value)?.no ?? 0)}
                                renderInput={(params) => <TextField {...params} label={"Mes"} />}
                            />

                        </div>
                    </div>

                    <div className='col-2'>
                        <button id='btn-buscar' className='btn btn-primary-mem' onClick={() => sendDataApi()}> Buscar <SearchIcon sx={{marginTop: -0.5}} />  </button>
                    </div>

                </div>
            </div>
        </div>

    </>
  )
}
