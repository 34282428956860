import swal from "sweetalert";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Loading from "../../../Components/Modals/Loading/Loader";
import { Divider, Grid, Paper, Stack, Typography, IconButton, Box } from "@mui/material";
import Select from "react-select";
import MaskedInput from "react-input-mask";
import CurrencyInput from "../../../Components/InputSalario/CurrencyInput";
import Avatar from '@mui/material/Avatar';
import { BiSave } from "react-icons/bi";
import { MdModeEdit } from "react-icons/md";
import { MdCancel } from "react-icons/md";

import { colors } from '../../../utils/colors'
import { convertNumberPhoneToString } from "../../../utils/convertNumberPhoneToString";
import ActualizarAjustarImagen from '../../../Components/Modals/Empleados/ActualizarAjustarImagen';

import "react-datepicker/dist/react-datepicker.css";

import {
  fetchEstadosCiviles,
  fetchProgramas,
  fetchTipoEmpleados,
  fetchNacionalidades,
  selectAllNacionalidades,
  selectAllEstadosCiviles,
  selectAllPogramas,
  selectAllTiposEmpleados,
} from "../../../redux/Utility/UtilitySlice";

import {
  fetchHorarios,
  selectAllHorarios,
} from "../../../redux/Horarios/HorarioSlice";

import {
  fetchAreasOrganizacionales,
  selectAllAreasOrganizacionales,
} from "../../../redux/AreaOrganizacional/AreaOrganizacionalSlice";

import {
  fetchCargosLista,
  selectAllCargosLista,
} from "../../../redux/Cargos/CargosSlice";

import {
  fetchGetTipoAccionesAll,
  selectListTipoAcciones,
} from "../../../redux/TipoAcciones/TipoAccionesSlice";

import {
  selectIsSuccess,
  selectIsLoading,
  selectAccion,
  selectMessage,
  fetchGetAccionById,
  fetchUpdateAccion,
  fetchCambiarSuccess
} from "../../../redux/Acciones/AccionesSlice";

import { 
  selectEmpleadosIsSuccess,
  selectEmpleadosMessage,
  selectEmpleadosIsLoading,
  fetchCambiarSuccess as fetchCambiarSuccessEmpleado
} from "../../../redux/Empleados/EmpleadosSlice";

import { selectUserData } from "../../../redux/Login/LoginSlice";

import { PermissionData } from "../../../context/Component/PermissionData";

import { formatCedula, formatPhoneNumber } from "../../../utils/empleadoMaskInput";

export function ReclutamientoSeleccionDetalle() {

  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  // ? ACCION-ID DEL QUERY-PARAMETER
  const { accionId = 0 } = useParams();

  // ? HOOK-STATE PARA VALIDACION DE BOTON HABILITADO.
  const [editEnabled, setEditEnabled] = useState(false);

  // ? HOOK-STATE PARA LA IMAGEN
  const [openActualizarImagen, setOpenActualizarImagen]= useState(false);

  // ? HOOK-STATE PARA LOS ESTADOS
	const [addRequestStatus, setAddRequestStatus] = useState('idle')

  // ? HOOK-NAVEGACION
  const navigate = useNavigate();

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const estadosCivil = useSelector(selectAllEstadosCiviles);
  const horarios = useSelector(selectAllHorarios);
  const programas = useSelector(selectAllPogramas);
  const tipoEmpleados = useSelector(selectAllTiposEmpleados);
  const areaOrganizacionales = useSelector(selectAllAreasOrganizacionales);
  const cargos = useSelector(selectAllCargosLista);
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  const tipoAcciones = useSelector(selectListTipoAcciones);
  const accion = useSelector(selectAccion);
  const nacionalidades = useSelector(selectAllNacionalidades);
  const usuario = useSelector(selectUserData);

  // ? HOOK-SELECTOR EMPLEADO
  const messageEmpleado = useSelector(selectEmpleadosMessage);
  const successEmpleado = useSelector(selectEmpleadosIsSuccess);
  const isLoadingEmpleado = useSelector(selectEmpleadosIsLoading);

  // ? HOOK-EFFECT
  useEffect(() => {
    dispatch(fetchEstadosCiviles());
    dispatch(fetchHorarios());
    dispatch(fetchProgramas());
    dispatch(fetchTipoEmpleados());
    dispatch(fetchAreasOrganizacionales());
    dispatch(fetchCargosLista());
    dispatch(fetchGetTipoAccionesAll());
    dispatch(fetchNacionalidades());
  }, []);

  // ? HOOK-FORM
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      accion: {
        accionId: 0,
        estadoId: 0,
        detallesAccion: '',
        descripcionRRHH: '',
        listaTipoAccion: [],
        fechaEfectividad: new Date(),
        usuarioModificadoId: 0
      },
      empleado: {
        nombres: '',
        apellidos: '',
        cedula: '',
        fechaNacimiento: new Date(),
        sexo: '',
        estadoCivilId: 0,
        direccion: '',
        emailPersonal: '',
        celular: '',
        telefono: '',
        fechaIngreso: new Date(),
        tipoEmpleadoId: 0,
        programaId: 0,
        horarioId: 0,
        sueldo: '',
        emailInstitucional: '',
        areaOrganizacionalId: 0,
        cargoId: 0,
        areaReporteId: 0,
        foto: ''
      },
      listaTipoAccion: []
    }
  });

  const { fields } = useFieldArray({
    control,
    name: "listaTipoAccion",
    rules: {},
  });

  // ? HOOK-WATCH
  const empleadoAccion = useWatch({control, name: 'empleado'});

  // * FUNCION PARA REFRESCAR LA ACCION.
  const refreshAccion = () => dispatch(fetchGetAccionById(accionId));

  // ? HOOK-EFFECT PARA VERIFICAR SI SE BUSCARA UNA ACCION
  useEffect(() => {
    if(accionId > 0){
      refreshAccion();
    }
  }, [accionId])

  // ? HOOK-EFFECT PARA VALIDAR QUE LA ACCION FUE ENCONTRADA PARA
  // ? POSTERIORMENTE CARGAR LA INFORMACION EN EL FORMULARIO.
  useEffect(() => {
    
    if(Object.keys(accion).length > 0 && success === 'success'){

      // * APLICANDO LA DATA DEL EMPLEADO AL VALOR DEL HOOK-FORM
      const empleadoUpdated = {...empleadoAccion, ...accion.empleado};

      // * ASIGNACION DE VALORES.
      setValue('empleado', empleadoUpdated);
      setValue('empleado.foto', empleadoUpdated?.foto)
      setValue('accion', accion);

      setValue('empleado.celular', formatPhoneNumber(accion.empleado.celular), {
				shouldValidate: true,
			});
			
			setValue('empleado.telefono', formatPhoneNumber(accion.empleado.telefono), {
				shouldValidate: true,
			});
			
			setValue('empleado.flota', formatPhoneNumber(accion.empleado.flota), {
				shouldValidate: true,
			});

			setValue('empleado.cedula', formatCedula(accion.empleado.cedula), {
				shouldValidate: true,
			});

      const naturalezaAccion = accion.accionTipoAccion.map(x => x.tipoAccionId.toString())
      setValue('listaTipoAccion', naturalezaAccion);

    }

    if(Object.keys(accion).length === 0 && success === 'error'){
      navigate('/acciones/reclutamiento')
    }

  }, [accion])  


  // ? HOOK-EFFECT PARA MANEJAR LOS ESTADOS DE PETICION PARA MOSTRAR LAS ALERTAS CORRESPONDIENTES.
  useEffect(() => {
		
		if(successEmpleado === 'success' && addRequestStatus === 'success'){
			dispatch(fetchCambiarSuccessEmpleado());
			swal("Exitoso!", messageEmpleado, "success");
			setAddRequestStatus('idle');

      // * REFRESCAR LA ACCION
      refreshAccion();
		}
		else if(successEmpleado==='error' && addRequestStatus==='error'){
			swal("Error!", messageEmpleado, "error");
			setAddRequestStatus('idle');
		}

	},[successEmpleado])

  useEffect(() => {
		
		if(success === 'success' && message.length > 0){
			dispatch(fetchCambiarSuccess());
			swal("Exitoso!", message, "success");

      // * REFRESCAR LA ACCION
      refreshAccion();
      setEditEnabled(false);
		}
		else if(success ==='error'){
			swal("Error!", message, "error");
		}

	},[success])


  // * FUNCION PARA ACTUALIZAR LA ACCION
  const updateAccion = (data, e) => {

    e.preventDefault();

    if (data.listaTipoAccion.flat().length === 0) {
      swal("Información", "Debe seleccionar al menos una naturaleza.", "error");
      return;
    }

    const dataApi = { ...{ usuarioIdModificado: usuario.Id }, ...data };

    // * ELIMINANDO LOS CARÁCTERES DE LAS MÁSCARAS.
    dataApi.empleado.cedula = data.empleado?.cedula?.replace(/_/g, "").split("-").join("");
    dataApi.empleado.sueldo = typeof(data.empleado.sueldo) === 'number' ? data.empleado.sueldo : data.empleado.sueldo.replace('$', "").split(",").join("");
    dataApi.empleado.telefono = convertNumberPhoneToString(data.empleado?.telefono);
    dataApi.empleado.celular = convertNumberPhoneToString(data.empleado?.celular);
    dataApi.empleado.flota = convertNumberPhoneToString(data.empleado?.flota);
    dataApi.listaTipoAccion = dataApi.listaTipoAccion.flat();

    dispatch(fetchUpdateAccion(dataApi));
  }

  // * VARIABLE PARA EVALUAR PERMISOS
  const buttonEnabled = permisosState.ROLE_PERSONAL_ACCIONES || permisosState.ROLE_ADMIN;

  return (
    <div className="container">
      <form onSubmit={handleSubmit(updateAccion)}>
        <div className="row mt-2">
          <div>
            <h3 className="text-primary-mem">DETALLE DE LA ACCION</h3>
            <hr />
          </div>
        </div>

        <Paper elevation={4}>
          
          <Grid xs={2} item display='flex' justifyContent='end'>
            {/* EL BOTON SE VA A OCULTAR SI NO TIENE LOS PERMISOS Y SI LA ACCION FUE PROCESADA O ESTA SIN EFECTO. */}
            <IconButton id="btn-editar" hidden={!buttonEnabled || accion?.estadoId !== 2} disabled={editEnabled} onClick={() => setEditEnabled(!editEnabled)}>
                <MdModeEdit color={editEnabled ? 'gray' : colors.azulMem}  />
            </IconButton>
          </Grid>

          <div className="d-flex flex-row p-3">
            <div className="col-md-4 pe-4 border-end">

                <div className='d-flex justify-content-center'>
                  <Avatar
                    src={`${empleadoAccion?.foto}?t=${new Date().getTime()}`}
                    sx={{
                        width: 150,
                        height: 150,
                        marginLeft: 1,
                        border: 'solid',
                        borderWidth: 1,
                        borderColor: '#003876',
                        borderRadius:3
                    }}
                    variant="square"
                    onClick={()=>setOpenActualizarImagen(true)}
                  />
                </div>
              
              <div className="form-group">
                <label className="fw-bold">Nombres</label>
                <input
                  disabled={!editEnabled}
                  className="form-control"
                  {...register("empleado.nombres", {
                    required: "El nombre es requerido",
                  })}
                />
                <p className="text-danger">
                  {errors.empleado?.nombres?.message}
                </p>
              </div>
              <div className="form-group">
                <label className="fw-bold">Apelidos</label>
                <input
                  disabled={!editEnabled}    className="form-control"
                  {...register("empleado.apellidos", {
                    required: "El apellido es requerido",
                  })}
                />
                <p className="text-danger">
                  {errors.empleado?.apellidos?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Cédula</label>
                  <Controller
                  name="empleado.cedula"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "La cédula es requerida",
                    minLength: {
                      value: 13,
                      message: 'La cédula debe tener 11 dígitos'
                    }
                  }}
                  render={({ field }) => (
                    <MaskedInput
                      disabled={!editEnabled}        className="form-control"
                      mask="999-9999999-9"
                      maskChar=""
                      value={field.value}
                      onChange={field.onChange}
                      />
                  )}
                  />
                <p className="text-danger">
                  {errors.empleado?.cedula?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Fecha Nacimiento</label>
                <Controller
                  control={control}
                  name="ReactDatepicker"
                  {...register("empleado.fechaNacimiento", {
                    required: "La fecha de nacimiento es requerida",
                  })}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat='dd/MM/yyyy'
                        disabled={!editEnabled}          label={" "}
                        mask="__/__/____"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        renderInput={(params) => (
                          <TextField className="form-control" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />

                <p className="text-danger mt-3">
                  {errors.empleado?.fechaNacimiento?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Edad</label>
                <input disabled className="form-control" value={moment().diff(empleadoAccion?.fechaNacimiento, 'years',false)} />
              </div>

              <div className="form-group">
                <label className="fw-bold">Sexo</label>
                <select
                  disabled={!editEnabled}    className="form-control"
                  {...register("empleado.sexo", {
                    required: "El sexo es requerido",
                  })}
                >
                  <option value={""}>--Seleccionar Sexo--</option>
                  <option value={"Masculino"}>Masculino</option>
                  <option value={"Femenino"}>Femenino</option>
                </select>
                <p className="text-danger">{errors.empleado?.sexo?.message}</p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Estado Civil</label>
                <select
                  disabled={!editEnabled}    
                  className="form-control"
                  {...register("empleado.estadoCivilId", {
                    required: "El estado civil es requerido",
                  })}
                >
                  <option value={""}>--Seleccionar Estado Civil--</option>
                  {estadosCivil.map((estadoCivil) => (
                    <option key={estadoCivil.id} value={estadoCivil.id}>
                      {estadoCivil.nombre}
                    </option>
                  ))}
                </select>
                <p className="text-danger">
                  {errors.empleado?.estadoCivilId?.message}
                </p>
              </div>
            </div>

            <div className="col-md-4 ps-4 pe-4 border-end">

              <div className="form-group">
                <label className="fw-bold">Nacionalidad</label>
                <Controller
                control={control}
                name="empleado.nacionalidadId"
                render={({ field }) => (
                  <Select
                    isDisabled={!editEnabled}      
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Seleccionar Empleado"
                    options={nacionalidades}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => field.onChange(e.id)}
                    value={nacionalidades.find(x => x.id === field.value)}
                  />
                )}
                />

              </div>

              <div className="form-group">
                <label className="fw-bold">Dirección</label>
                <textarea
                  disabled={!editEnabled}    
                  className="form-control"
                  {...register("empleado.direccion")}
                />
                <p className="text-danger"></p>
              </div>  

              <div className="form-group">
                <label className="fw-bold">Correo Personal</label>
                <input
                  disabled={!editEnabled}    
                  className="form-control"
                  {...register("empleado.emailPersonal", {
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "El formato de correo no es valido",
                    },
                  })}
                />
                <p className="text-danger">
                  {errors.empleado?.emailPersonal?.message}
                </p>
              </div>

              <div className="form-group mb-3">
                <label className="fw-bold">Celular</label>
                <Controller
                control={control}
                name="empleado.celular"
                rules={
                  {
                    minLength: {
                    value: 14,
                    message: 'El celular debe tener 10 dígitos'
                  }}
                }
                render={({ field }) => (
                  <MaskedInput
                    disabled={!editEnabled}      
                    className="form-control"
                    mask="(999) 999-9999"
                    maskChar=""
                    value={field.value}
                    onChange={field.onChange} 
                    />
                )}
                />

                <p className="text-danger mt-2">
                  {errors.empleado?.celular?.message}
                </p>

              </div>

              <div className="form-group mb-3">
                <label className="fw-bold">Teléfono</label>
                <Controller
                control={control}
                name="empleado.telefono"
                rules={
                  {
                    minLength: {
                    value: 14,
                    message: 'El teléfono debe tener 10 dígitos'
                  }}
                }
                render={({ field }) => (
                  <MaskedInput
                    disabled={!editEnabled}      className="form-control"
                    mask="(999) 999-9999"
                    maskChar=""
                    value={field.value}
                    onChange={field.onChange} 
                    />
                )}
                />

                <p className="text-danger mt-2">
                  {errors.empleado?.telefono?.message}
                </p>


              </div>


              <div className="form-group">
                <label className="fw-bold">Fecha Ingreso</label>
                <Controller
                  control={control}
                  name="ReactDatepicker"
                  {...register("empleado.fechaIngreso", {
                    required: "La fecha de ingreso es requerida",
                  })}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat='dd/MM/yyyy'
                        disabled={!editEnabled}          
                        label={" "}
                        mask="__/__/____"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        renderInput={(params) => (
                          <TextField className="form-control" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />

                <p className="text-danger mt-3">
                  {errors.empleado?.fechaIngreso?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Tipo Empleado</label>
                <select
                  disabled={!editEnabled}    
                  className="form-control"
                  {...register("empleado.tipoEmpleadoId", {
                    required: "El tipo de empleado es requerido",
                  })}
                >
                  <option value={""}>Seleccionar Tipo Empleado</option>
                  {tipoEmpleados.map((tipoEmpleado) => (
                    <option key={tipoEmpleado.id} value={tipoEmpleado.id}>
                      {tipoEmpleado.tipo}
                    </option>
                  ))}
                </select>
                <p className="text-danger">
                  {errors.empleado?.tipoEmpleadoId?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Programa</label>
                <select
                  disabled={!editEnabled}    
                  className="form-control"
                  {...register("empleado.programaId", {
                    required: "El programa es requerido",
                  })}
                >
                  <option value={""}>Seleccionar Programa</option>
                  {programas.map((programa) => (
                    <option key={programa.id} value={programa.id}>
                      {programa.programa}
                    </option>
                  ))}
                </select>
                <p className="text-danger">
                  {errors.empleado?.programaId?.message}
                </p>
              </div>
            </div>

            <div className="col-md-4 ps-4 pe-4">

              <div className="form-group">
                <label className="fw-bold">Horario</label>
                <select
                  disabled={!editEnabled}    
                  className="form-control"
                  {...register("empleado.horarioId", {
                    required: "El horario es requerido",
                  })}
                >
                  <option value={""}>Seleccionar Horario</option>
                  {horarios.map((horario) => (
                    <option key={horario.id} value={horario.id}>
                      {horario.turnos[0]?.horarioLabel}
                    </option>
                  ))}
                </select>
                <p className="text-danger">
                  {errors.empleado?.horarioId?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Sueldo</label>

                <Controller
                name="empleado.sueldo"
                control={control}
                defaultValue=""
                rules={{
                  required: "El sueldo es requerido"
                }}
                render={({ field }) => (
                  <CurrencyInput
                    disabled={!editEnabled}    
                    className="form-control"
                    placeholder="$0.00" type="text"
                    value={field.value}
                    onChange={field.onChange}
                  >
                   
                  </CurrencyInput>
                )}
                />
              
                <p className="text-danger">
                  {errors.empleado?.sueldo?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Correo Institucional</label>
                <input
                  disabled={!editEnabled}    
                  className="form-control"
                  {...register("empleado.emailInstitucional", {
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "El formato de correo no es valido",
                    },
                  })}
                />
                <p className="text-danger">
                  {errors.empleado?.emailInstitucional?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Flota</label>
                <input
                  disabled={!editEnabled}    
                  className="form-control"
                  {...register("empleado.flota")}
                />
                <p className="text-danger"></p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Área Organizacional</label>
                <Controller
                    control={control}
                    name="empleado.areaOrganizacionalId"
                    {...register("empleado.areaOrganizacionalId", {
                      required: "El área organizacional es requerida",
                    })}
                    render={({ field }) => (
                      <Select
                        isDisabled={!editEnabled}        
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Área Organizacional"
                        options={areaOrganizacionales}
                        getOptionLabel={(option) => option.nombre}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        value={areaOrganizacionales.find(x => x.id === field.value)}
                      />
                    )}
                />
              </div>

                <div className="form-group">
                  <label className="fw-bold">Cargo</label>
                    
                    <Controller
                    control={control}
                    name="empleado.cargoId"
                    {...register("empleado.cargoId", {
                      required: "El Cargo es requerido",
                    })}
                    render={({ field }) => (
                      <Select
                        isDisabled={!editEnabled}    
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Cargo"
                        options={cargos}
                        getOptionLabel={(option) => option.titulo}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        value={cargos.find(x => x.id === field.value)}
                      />
                    )}
                    />


                  <p className="text-danger">
                      {errors.empleado?.cargoId?.message}
                  </p>
                </div>

              <div className="form-group">
              <label className="fw-bold">Área de Reporte</label>

                <Controller
                    control={control}
                    name="empleado.areaReporteId"
                    {...register("empleado.areaReporteId", {
                      required: "El área de reporte es requerida",
                    })}
                    render={({ field }) => (
                      <Select
                        isDisabled={!editEnabled}    
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Área Reporte"
                        options={areaOrganizacionales}
                        getOptionLabel={(option) => option.nombre}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        value={areaOrganizacionales.find(x => x.id === field.value)}
                      />
                    )}
                />

                <p className="text-danger">
                    {errors.empleado?.areaReporteId?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Fecha de Efectividad</label>
                <Controller
                  control={control}
                  name="ReactDatepicker"
                  {...register("accion.fechaEfectividad", {
                    required: "La fecha de efectividad es requerida",
                  })}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat='dd/MM/yyyy'
                        disabled={!editEnabled}          
                        label={" "}
                        mask="__/__/____"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        renderInput={(params) => (
                          <TextField className="form-control" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />

                <p className="text-danger mt-3">
                  {errors.fechaEfectividad?.message}
                </p>
              </div>
            </div>
          </div>
        </Paper>

        <Paper elevation={5}>
          <Stack mt={2} p={3} component={'div'} id="cuadro-naturaleza">
            <Typography textTransform="uppercase">
              Naturaleza de la acción
            </Typography>
            <Divider />

            <Grid container spacing={{ lg: 2, md: 1 }}>
              {tipoAcciones.length > 0 &&
                fields !== undefined &&
                tipoAcciones.map((item, index) => (
                  <Grid item p={2} xs={4} md={4} lg={2.4} key={item.id} mt={2}>
                    <Stack>
                      <Typography
                        key={index}
                        sx={{ fontSize: { lg: 14, md: 12, xs: 11 } }}
                        fontWeight="bold"
                        textTransform="uppercase"
                      >
                        {item.nombre}
                      </Typography>

                      {item.tiposAcciones.map((item2, index2) => (
                        <FormControlLabel
                          disabled={!editEnabled}            key={item2.id}
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                              checked={getValues("listaTipoAccion")
                                ?.flat()
                                .some((x) => x === item2.id.toString())}
                              {...register(`listaTipoAccion.${index2}`, {
                                value: [],
                              })}
                              value={item2?.id}
                            />
                          }
                          label={item2?.nombre}
                        />
                      ))}
                    </Stack>
                  </Grid>
                ))}

              <p
                style={{ marginLeft: 10, marginBottom: -2 }}
                className="text-danger"
              >
                {watch("listaTipoAccion")?.flat()?.length === 0 &&
                  "Debe seleccionar al menos una naturaleza"}
              </p>
            </Grid>
          </Stack>
        </Paper>

        <Paper elevation={5}>
          <Stack mt={2} p={3}>
            <Typography fontSize={14} textTransform="uppercase">
              Detalles explicativos de la acción
            </Typography>
            <TextField
              disabled={!editEnabled}
              {...register("accion.detallesAccion")}
              label=" "
              variant="outlined"
              multiline
              rows={2}
              sx={{ mb: 2 }}
              error={errors.detallesAccion}
              helperText={errors.detallesAccion?.message}
            />

            <Typography fontSize={14} textTransform="uppercase">
              Para uso de área de recursos humanos
            </Typography>
            <TextField
              disabled={!editEnabled}
              {...register("accion.descripcionRRHH")}
              label=" "
              variant="outlined"
              multiline
              rows={2}
              error={errors.descripcionRRHH}
              helperText={errors.descripcionRRHH?.message}
            />

            <Box className='d-flex justify-content-center' mt={3}>
              <button id="btn-guardar" type='submit' className='btn btn-primary-mem me-2' hidden={!editEnabled}>Guardar <BiSave fontSize={22} /></button>
              <button id="btn-cancelar" type='button' onClick={() => navigate('/acciones/reclutamiento')} className='btn btn-danger'>Cancelar <MdCancel fontSize={22} /></button>
            </Box>
       
          </Stack>
        </Paper>
      </form>
      
      <ActualizarAjustarImagen 
      setValue={setValue}
      openActualizarImagen={openActualizarImagen} 
      setOpenActualizarImagen={setOpenActualizarImagen} 
      empleadoId ={empleadoAccion.id} 
      setAddRequestStatus={setAddRequestStatus} 
      />

      <Loading open={isLoading || isLoadingEmpleado} />
    </div>
  );
}

export default ReclutamientoSeleccionDetalle;
