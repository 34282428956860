import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../config";
import axiosApiInstance from "../../utils/apiClient";

const initialState = {
  isSuccess: "idle",
  isLoading: false,
  message: "",
  permisosGenerales: [],
};

export const fetchPermisosGenerales = createAsyncThunk(
  "permisosGenerales/fetchPermisosGenerales",
  async (ano) => {
    let response = axiosApiInstance.get(
      `${endpoints.permisosGenerales.fetchPermisosGenerales}/${ano}`
    );
    return (await response).data;
  }
);

export const addPermisosGenerales = createAsyncThunk(
  "permisosGenerales/addPermisosGenerales",
  async (permisoGeneral) => {
    let response = axiosApiInstance.post(
      `${endpoints.permisosGenerales.savePermisosGenerales}`,
      permisoGeneral
    );
    return (await response).data;
  }
);
export const updatePermisosGenerales = createAsyncThunk(
  "permisosGenerales/updatePermisosGenerales",
  async (permisoGeneral) => {
    let response = axiosApiInstance.post(
      `${endpoints.permisosGenerales.updatePermisosGenerales}`,
      permisoGeneral
    );
    return (await response).data;
  }
);
export const deletePermisosGenerales = createAsyncThunk(
  "permisosGenerales/deletePermisosGenerales",
  async (permisoGeneral) => {
    let response = axiosApiInstance.post(
      `${endpoints.permisosGenerales.deletePermisosGenerales}`,
      permisoGeneral
    );
    return (await response).data;
  }
);

const PermisosGeneralesSlice = createSlice({
  initialState,
  name: "permisosGenerales",
  extraReducers(builder) {
    builder
      .addCase(fetchPermisosGenerales.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchPermisosGenerales.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.permisosGenerales = action.payload.data;
          state.isSuccess = "success";
        } else {
          state.permisosGenerales = action.payload.data;
          state.isSuccess = "error";
        }
      })
      .addCase(fetchPermisosGenerales.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = "error";
      })
      .addCase(addPermisosGenerales.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
        state.isSuccess = "idle";
      })
      .addCase(addPermisosGenerales.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.permisosGenerales = [
            ...state.permisosGenerales,
            action.payload.data,
          ];
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(addPermisosGenerales.rejected, (state, action) => {
        state.isSuccess = "error";
        state.isLoading = false;
        state.message = action.error.message;
      })
      .addCase(updatePermisosGenerales.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
        state.isSuccess = "idle";
      })
      .addCase(updatePermisosGenerales.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          let filterPermisosGenerales = state.permisosGenerales.filter(
            (x) => x.fecha !== action.payload.data.fecha
          );
          state.permisosGenerales = [
            ...filterPermisosGenerales,
            action.payload.data,
          ];
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(updatePermisosGenerales.rejected, (state, action) => {
        state.isSuccess = "error";
        state.isLoading = false;
        state.message = action.error.message;
      })
      .addCase(deletePermisosGenerales.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
        state.isSuccess = "idle";
      })
      .addCase(deletePermisosGenerales.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          let filterPermisosGenerales = state.permisosGenerales.filter(
            (x) => x.fecha !== action.payload.data.fecha
          );
          state.permisosGenerales = [...filterPermisosGenerales];
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(deletePermisosGenerales.rejected, (state, action) => {
        state.isSuccess = "error";
        state.isLoading = false;
        state.message = action.error.message;
      });
  },
});

export const selectPermisosGenerales = (state) =>
  state.permisosGenerales.permisosGenerales;
export const selectIsLoading = (state) => state.permisosGenerales.isLoading;
export const selectIsSuccess = (state) => state.permisosGenerales.isSuccess;
export const selectMessage = (state) => state.permisosGenerales.message;

export default PermisosGeneralesSlice.reducer;
