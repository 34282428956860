import React,{useState,useEffect} from 'react'
import { useDispatch } from 'react-redux';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { selectIsSuccess } from '../../redux/Cargos/CargosSlice';
import CargoEditar from '../Modals/Cargo/CargoEditar';
import { useSelector } from 'react-redux';
import {PermissionData} from '../../context/Component/PermissionData';
import { IconButton } from '@mui/material';

 const CardCargo = ({cargo, index, gruposOcupacionales,updateCargo,handleDeleteCargo}) => {

    // * PERMISOS DEL USUARIO.
    const permisosState = PermissionData();
    
    const dispatch = useDispatch();
    const isSuccess = useSelector(selectIsSuccess)
    const [ openEdit, setOpenEdit ] = useState(false);
    const handleOpenEdit = () => {
		setOpenEdit(true);
	};

    const handleUpdateCargo = (cargo)=>{
        dispatch(updateCargo(cargo))
    }


	useEffect(()=>{
		if(isSuccess){
			setOpenEdit(false);
		}

	},[isSuccess])
  return (
     
    <div className='container mt-2 pt-3 pb-2 mb-2 border align-self-center' key={index}>
        <div className='row '>
             <div className='col-md-1 text-center'><h1 className='text-primary-mem'>{cargo.id}</h1></div>
             <div className='col-md-5'><h6 className='text-primary-mem'>{cargo.titulo}</h6></div>
             <div className='col-md-2 '>
                <div className='col-md-12 text-center'>
                <label className=''><GroupsOutlinedIcon></GroupsOutlinedIcon> Grupo Ocupacional</label>
                </div>
                <div className='col-md-12 text-center'>
                <label className=''>{cargo.grupoOcupacionalId}</label>
                </div>
                
             </div>
             <div className='col-md-2 '>
             <div className='col-md-12 text-center'>
             <label className=''><GroupsOutlinedIcon></GroupsOutlinedIcon> Cantidad Empleados</label>
                </div>
                <div className='col-md-12 text-center'>
                <label className=''>{cargo.cantidadEmpleados}</label>
                </div>
             </div>
             <div className='col-md-2 '>
                <div className='col-md-12 text-center'>

                  <IconButton hidden={!permisosState.ROLE_EDITAR_CARGOS}  onClick={handleOpenEdit}>
                    <BorderColorIcon className='text-primary-mem' />
                  </IconButton>
                
                </div>

                <div className='col-md-12 text-center'>
                   {
                      cargo.cantidadEmpleados === 0 &&(
                      <IconButton hidden={!permisosState.ROLE_BORRAR_CARGOS} onClick={()=>handleDeleteCargo(cargo)}>
                          <DeleteOutlineOutlinedIcon className='text-danger' />
                      </IconButton>
                      )
                   }
                   
                </div>
             </div>

        </div> 
        <CargoEditar handleUpdateCargo={handleUpdateCargo} gruposOcupacionales={gruposOcupacionales} openEdit={openEdit} setOpenEdit={setOpenEdit} cargo={cargo}></CargoEditar>
    </div>
   
  )
}

export default CardCargo;