import { useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router";
import { useSelector } from "react-redux";
import { selectLogin } from "../redux/Login/LoginSlice";
import React, { useEffect, useContext } from "react";
import { PermissionContext } from "../context/PermissionContext";
import { parseJWT } from "../utils/JWTConvertToJson";

const RequiredAuth = () => {
  const location = useLocation();

  // ? HOOK-STATE
  const [usuarioLogin, setUsuarioLogin] = useState({});
  const [listadoPermisos, setListadoPermisos] = useState([]);

  // ? HOOK-CONTEXT.
  const value = useContext(PermissionContext);

  // * DESESTRUCTURACION DE LOS DATOS.
  const { permisosState, setPermisos } = value.value;

  // ? HOOK-SELECTOR PROVENIENTE DEL LOGIN.
  const user  = useSelector(selectLogin);

  // * DESESTRUCTURACION DE LOS DATOS.
  const {accessToken}  = user.data;

  // ? HOOK-EFFECT
  useEffect(() => {
    let usuario = {};
    let lista = [];

    if (accessToken !== null) {

      // * TOKEN DESERIALIZADO.
      usuario = parseJWT(accessToken);

      if (typeof usuario.Permiso === "object") {
        usuario.Permiso.map((x) => (lista = [...lista, JSON.parse(x)]));
      } else {
        lista = JSON.parse(usuario.Permiso);
      }
    }

    setListadoPermisos(lista);
    setUsuarioLogin(usuario);
  }, [user]);

  // ? HOOK-EFFECT QUE SE EJECUTA CADA VEZ QUE EL USUARIO CAMBIA.
  useEffect(() => {
    if (usuarioLogin !== null) {
      let permisosUsuarios = permisosState;

      for (let i = 0; i < listadoPermisos.length; i++) {
        // * LISTA DE PERMISOS POR POSICION.
        let permisos = listadoPermisos[i];

          if (typeof usuarioLogin.Permiso === "object") {
            for (let x = 0; x < permisos.length; x++) {
              permisosUsuarios[permisos[x].Role] = true;
            }
          } 
          else {
            permisosUsuarios[permisos.Role] = true;
          }

          // * GUARDNADO LOS PERMISOS EN EL LOCAL-STORAGE.
          setPermisos(JSON.stringify(permisosUsuarios));
      }
    }
  }, [usuarioLogin]);

    return accessToken !== null ?
      <Outlet />
      : 
        <Navigate to="/login" state={{ from: location }} replace />
    
};

export default RequiredAuth;
