import React from "react";
import { Routes, Route, Navigate } from "react-router";
import {
  Aprobaciones,
  TiposPermisos,
  DigitarPermiso,
  SolicitudesPermisos
} from "../pages/Control/Permisos";

import { RequiredPermission } from '../authentication/RequiredPermission';

export const RutasPermisos = () => {
  return (
    <Routes>

      <Route element={<RequiredPermission permission='ROLE_APROBACIONES_RRHH'/>}> 
        <Route index element={<Aprobaciones />} />
      </Route>

      <Route element={<RequiredPermission permission='ROLE_VER_TIPO_PERMISO'/>}> 
        <Route path="tipospermisos" element={<TiposPermisos />} />
      </Route>

      <Route path="solicitudes_personalizados" element={<DigitarPermiso />} />
      <Route path="solicitudes_permisos" element={<SolicitudesPermisos />} />

      <Route path='/*' element={<Navigate to="/" replace />} />

    </Routes>
  );
};
