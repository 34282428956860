import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Index.scss";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import { CardContent, Divider, Stack } from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { PiComputerTower, PiDesktopTower } from 'react-icons/pi';
import { RiCellphoneFill } from 'react-icons/ri';
import DesktopWindowsTwoToneIcon from '@mui/icons-material/DesktopWindowsTwoTone';
import { BsPhone } from 'react-icons/bs';
import { CgServer } from "react-icons/cg";
import { Image } from '../../Components/Profile/Image';
import moment from "moment/moment";
import { getPhoto } from '../../utils/getPhoto';

import {
    createTheme,
    ThemeProvider,
} from '@mui/material/styles';

import { selectUserData } from "../../redux/Login/LoginSlice";
import { 
    fetchGetEmpleadoDashboard, 
    fetchEmpleadoCumpleanos,
    selectEmpleadoCumpleanos,
    selectEmpleadoDashboard,
    selectIsLoading 
} from "../../redux/Empleados/EmpleadosSlice";

import { removeAccents } from '../../utils/removeAccents';

import { makeStyles } from '@mui/styles';
import Loading from "../../Components/Modals/Loading/Loader";

// * ESTILOS
const useStyles = makeStyles((theme) => ({
       
// * PADDING DE LOS ESTILOS DE LAS CARD ASIGNACIONES.
textPaddingAsignaciones: {
    [theme.breakpoints.up(1500)]: {
        paddingTop: "16px"
    }, 
    [theme.breakpoints.between(1400, 1499)]: {
        paddingTop: "17px",
    }, 
    [theme.breakpoints.between(1300, 1399)]: {
        paddingTop: "15px",
    }, 
    [theme.breakpoints.between(1200, 1299)]: {
        paddingTop: "12px",
    }, 
    [theme.breakpoints.between(1100, 1199)]: {
        paddingTop: "13px",
    },
    [theme.breakpoints.down(1100)]: {
        paddingTop: "11px",
    },
    
},

// * MARGIN DEL ICONO DE ASIGNACIONES
iconAsignaciones: {
    [theme.breakpoints.up(1300)]: {
        paddingTop: "0.5vw",
        fontSize: '45px'
    }, 
    [theme.breakpoints.down(1300)]: {
        marginTop: "0.3vw",
        fontSize: '40px'
    }, 
},

textNombreEmpleado: {
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.2vw'
    }, 
},

textPosicion: {
    [theme.breakpoints.between("sm", "lg")]: {
        fontSize: '0.8vw',
    }, 
},

textInfo: {
    [theme.breakpoints.between("sm", "lg")]: {
        fontSize: '0.8vw',
    }, 
},

// * ESTILOS DE LA IMAGEN DEL PERFIL
imgProfile: {
    [theme.breakpoints.between(1700, 2000)]: {
        height: '320px',
    }, 

    [theme.breakpoints.between(1600, 1700)]: {
        height: '300px',
    }, 

    [theme.breakpoints.between(1500, 1599)]: {
        height: '330px',
    }, 

    [theme.breakpoints.between(1400, 1499)]: {
        height: '330px',
    }, 

    [theme.breakpoints.between(1300, 1399)]: {
        height: '335px',
    }, 

    [theme.breakpoints.between(1200, 1299)]: {
        height: '360px',
    }, 
    [theme.breakpoints.down(1200)]: {
        height: '800',
    }, 
},

// * NUMERO EMPLEADO
textNumeroEmpleadoPadding: {
    [theme.breakpoints.up(1500)]: {
        paddingTop: '0.8vw'
    },
    [theme.breakpoints.between(1400, 1499)]: {
        paddingTop: '0.8vw'
    },
    [theme.breakpoints.down(1500)]: {
        paddingTop: '0.8vw'
    },
},

textCumpleanosNombre: {
    fontWeight: 'bold',
    [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '1.5vw',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '0.9vw',
    },
},

textCumpleanosDepartamento: {
    [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '1.2vw',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '0.8vw',
    },
}
}));

const DashBoard = () => {

    // ? HOOK-STATE
    const [value, setValue] = useState('1');

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const empleado = useSelector(selectEmpleadoDashboard);
    const isLoading = useSelector(selectIsLoading);
    const cumpleanos = useSelector(selectEmpleadoCumpleanos);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    // ? HOOK SELECTOR
	const usuario = useSelector(selectUserData);

    // ? HOOK-EFFECT PARA OBTENER LA DATA DEL EMPLEADO LOGEADO
    useEffect(() => {
        dispatch(fetchGetEmpleadoDashboard(usuario?.Id))
    }, [usuario])

    useEffect(() => {
      dispatch(fetchEmpleadoCumpleanos())
    }, [])

    // * COMPONENTE PARA EL ICONO PERSONALIZADO DE ASIGNACIONES.
    const IconAsignaciones = ({tipo, activo}) => {

       if(tipo === null){
        return;
       }

        const normalizeName = removeAccents(tipo).replace(' ', '_');

        const options = {
            monitor: DesktopWindowsTwoToneIcon,
            cpu: PiComputerTower,
            ups: CgServer,
            telefono_voip: RiCellphoneFill
        };

        const IconName = options[normalizeName.toLowerCase()] ?? PiDesktopTower

        return (
            <IconName className={classes.iconAsignaciones} />
        )
    }

    // * ESTILOS
    let theme = createTheme();
    theme.typography = {
        p: {
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: '1.5vw',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '0.9vw',
            },
        },
    };

    // * ESTILOS RESPONSIVE
    const classes = useStyles();

    return(

        <ThemeProvider theme={theme}>
            <div id="layoutSidenav_content" className="container-fluid">
                                    
                {
                    Object.keys(empleado).length > 0 && 

                        <Box sx={{ flexGrow: 1 }} marginTop={3}>
                            <Paper elevation={5} >
                                <Grid container spacing={2} paddingLeft={2} paddingBottom={2}>

                                    <Grid item lg={2.5} md={3} sm={3.4} className={`div-profile ${classes.imgProfile}`}>            
                                        <Image src={getPhoto(empleado)} sx={{ borderRadius: 2}} />
                                    </Grid>

                                    <Grid item sm={8.5} md={8.9} lg={9.4}>
                                        <Paper>
                                        <Grid padding={2}>
                                                <CardContent>
                                                    <Grid container>

                                                        <Grid item xs={10.5}>

                                                            <Typography fontWeight='bold' fontSize={{sm: 14, md: 18, lg: 20}}>{empleado.nombreCompleto}</Typography>
                                                            <Typography fontSize={{sm: 12, md: 16, lg: 16}}>{empleado.cargo}</Typography>
                                                            <Typography fontSize={{sm: 12, md: 16, lg: 16}}>{empleado.departamento}</Typography>

                                                        </Grid>

                                                        <Grid item xs={1.5}>

                                                            <Stack
                                                            sx={{borderRadius: 1}}
                                                            p={1}
                                                            className="bg-primary-mem"
                                                            component='div'
                                                            display='flex'
                                                            justifyContent='center'
                                                            alignItems='center'
                                                            >
                                                                <Typography 
                                                                color='white'
                                                                fontSize={{xs: 12, sm: 13, md: 15, lg: 20}}
                                                                >
                                                                    {empleado.codigoEmpleado}
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>

                                                    </Grid>
                                                </CardContent>

                                                <CardContent>
                                                    <Grid container>

                                                        <Grid item xs={5}>

                                                            <Typography fontSize={{sm: 12, md: 14, lg: 16}}><b>Sexo:</b> {empleado.sexo}</Typography>
                                                            <Typography fontSize={{sm: 12, md: 14, lg: 16}}><b>Fecha Ingreso:</b> {moment(empleado.fechaIngreso).format('DD-MM-YYYY')}</Typography>
                                                            <Typography fontSize={{sm: 12, md: 14, lg: 16}}><b>Celular:</b> {empleado.celular}</Typography>
                                                            <Typography fontSize={{sm: 12, md: 14, lg: 16}}><b>Correo Institucional:</b> {empleado.correolInstitucional}</Typography>
                        
                                                        </Grid>

                                                        <Grid item xs={4}>

                                                            <Typography fontSize={{sm: 12, md: 14, lg: 16}}><b>Tipo Empleado:</b> {empleado.tipoEmpleado}</Typography>
                                                            <Typography fontSize={{sm: 12, md: 14, lg: 16}}><b>Teléfono Residencial:</b> {empleado.telefono}</Typography>
                                                            <Typography fontSize={{sm: 12, md: 14, lg: 16}}><b>Correo Personal:</b> {empleado.correoPersonal}</Typography>
                                                            <Typography fontSize={{sm: 12, md: 14, lg: 16}}><b>Flota:</b> {empleado.flota}</Typography>

                                                        </Grid>

                                                    </Grid>
                                                </CardContent>
                                        </Grid>
                                        </Paper>
                                    </Grid>

                                </Grid>
                            </Paper>

                            <Box sx={{ flexGrow: 1 }} marginTop={3}>

                                <Grid container spacing={2} mb={2}>

                                    <Grid item xs={6}>

                                        <Paper elevation={5}>
                                            <Stack component='div' padding={2}>
                                            
                                                <Typography variant="h5" textTransform='uppercase' mb={1}>Asignaciones</Typography>
                                                <Divider />

                                                <Box sx={{ width: '100%', typography: 'body1' }} marginTop={2}>
                                                    <TabContext value={value}>
                                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                        <TabList onChange={handleChange}>
                                                            <Tab label="Equipos" value="1"/>
                                                            <Tab label="Flotas" value="2" />
                                                            <Tab label="Vehículo" value="3" />
                                                        </TabList>
                                                        </Box>
                                                        <TabPanel value="1">

                                                        {
                                                            empleado.asignaciones.map(({activo}, index) => (
                                                        
                                                                activo.equipo.id > 0 && (
                                                                    <Paper elevation={3} key={index}>
                                                                            <Stack mb={1} component='div' padding={2} >
                                                                                <Grid container>
                                                                                
                                                                                    <Grid item lg={2} md={2} sm={3} display='flex' justifyContent='center'>
                                                                                        <IconAsignaciones tipo={activo.equipo.tipoEquipo} activo={activo}/>
                                                                                    </Grid>
                                                                                    <Grid item lg={10} md={10} sm={9} className={classes.textPaddingAsignaciones}>
                                                                                        <Typography variant="p" fontSize={{lg: 14, md: 12, sm: 12, xs: 12}}>{activo.equipo.marca} {activo.equipo.modelo} ({activo.equipo.tipoEquipo})</Typography>
                                                                                    </Grid>
                                                                                    
                                                                                </Grid>
                                                                            </Stack>
                                                                    </Paper>
                                                                )
                                                        ))}
                                                                        

                                                        </TabPanel>
                                                        
                                                        <TabPanel value="2">

                                                        {
                                                            empleado.asignaciones.map(({activo}, index) => (
                                                        
                                                                activo.flota.id > 0 && (
                                                                    <Paper elevation={3} key={index}>
                                                                            <Stack mb={1} component='div' padding={2} >
                                                                                <Grid container>
                                                                                
                                                                                    <Grid item lg={2} md={2} sm={3} display='flex' justifyContent='center'>
                                                                                        <BsPhone className={classes.iconAsignaciones} />
                                                                                    </Grid>
                                                                                    <Grid item lg={10} md={10} sm={9} className={classes.textPaddingAsignaciones}>
                                                                                        <Typography variant="p" fontSize={{lg: 14, md: 12, sm: 12, xs: 12}}>{activo.flota.marca} {activo.flota.modelo} (Celular)</Typography>
                                                                                    </Grid>
                                                                                    
                                                                                </Grid>
                                                                            </Stack>
                                                                    </Paper>
                                                                )
                                                        ))}

                                                        </TabPanel>

                                                        <TabPanel value="3">

                                                        </TabPanel>

                                                    </TabContext>
                                                </Box>

                                            </Stack>
                                        </Paper>

                                    </Grid>
                                    

                                    <Grid item xs={6}>

                                        <Paper elevation={5}>
                                            <Stack component='div' padding={2}>
                                            
                                                <Typography variant="h5" textTransform='uppercase' mb={1}>Cumpleaños de hoy</Typography>
                                                <Divider />

                                                <Box mt={1.5}>
                                                    {
                                                        cumpleanos.map((item, index) => (

                                                            <Paper elevation={3} key={index}>
                                                                <Grid container mb={1}>
                                                                    <Grid item xs={2} className="div-profile-birthday">
                                                                        <Image src={getPhoto(item)} sx={{height: '7vw', borderRadius: 2}} />
                                                                    </Grid>

                                                                    <Grid item xs={10} padding={2}>
                                                                        <Typography fontWeight='bold' fontSize={{md: 14, lg: 16, sm: 12}}>{item.nombreCompleto}</Typography>
                                                                        <Typography fontSize={{md: 12, lg: 14, sm: 11}}>{item.departamento}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>

                                                        ))
                                                    }
                                                </Box>

                                            </Stack>
                                        </Paper>

                                    </Grid>

                                </Grid>
                            
                            </Box>

                    </Box>

                }

                <Loading open={isLoading} />
            </div>
                
        </ThemeProvider>
    
    )
}

export default DashBoard