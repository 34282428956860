
export const formatPhoneNumber = (phone) => {
    const phoneNumber = phone?.replace(/\D/g, '');
    if (phoneNumber && phoneNumber.length === 10) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }
    return phone;
};

export const formatCedula = (cedula) => {
    const cedulaNumber = cedula?.replace(/\D/g, '');
    if (cedulaNumber && cedulaNumber.length === 11) {
      return `${cedulaNumber.slice(0, 3)}-${cedulaNumber.slice(3, 7)}-${cedulaNumber.slice(7, 11)}`;
    }
    return cedula;
};
