import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';

import {useForm} from "react-hook-form"

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};

const CargoEditar = ({ openEdit, setOpenEdit,cargo,gruposOcupacionales,handleUpdateCargo }) => {

	const {register,handleSubmit, formState:{errors} } =useForm({defaultValues: {...cargo}});
	
	const onSubmit = (cargo)=>{
		handleUpdateCargo(cargo)
	}

	const handleClose = () => {
		setOpenEdit(false);
	};
	return (
		<Modal
			open={openEdit}
			onClose={handleClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
		>
			<Box sx={{ ...style, width: 700 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
				<div className="container">
					<div className="row">
						<h3 className="text-primary-mem text-center">Cargo</h3>
					</div>
					<div className="row">
						<div className="col-5 me-0 pe-0">
							<hr />
						</div>
						<div className="col-2 text-center">
						<BorderColorIcon className='text-primary-mem'></BorderColorIcon>
						</div>
						<div className="col-5 ms-0 ps-0 ">
							<hr />
						</div>
					</div>
                    <div className='row'>
					<div hidden className='form-group'>
                            <input {...register("id")} type="number" className='form-control'></input>
                        </div>
						<div className='form-group'>
                            <label className='fw-bold'>Título</label>
                            <textarea {...register("titulo",{required:"El titulo es requerido"})} type="text" className='form-control'></textarea>
                            <p className='text-danger'>{errors.titulo?.message}</p>
                        </div>
                        <div className='form-group'>
                            <label className='fw-bold'>Grupo Ocupacional</label>
                            <select {...register("grupoOcupacionalId",{required:"El grupo ocupacional es requerido"})} className='form-control'>
                                <option value="">Seleccionar grupo ocupacional</option>
								{
                                  gruposOcupacionales && gruposOcupacionales.length>0&&  gruposOcupacionales.map((grupo)=><option key={grupo.id} value={grupo.id}>{grupo.nombre}</option>)
                                }
                            </select>
                            <p className='text-danger'>{errors.grupoOcupacionalId?.message}</p>
                        </div>
                        <div className='d-flex justify-content-center'>
							<button type='submit' className='btn btn-primary-mem me-3'> <SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}} /> Guardar</button>
							<button type='button' className='btn btn-danger ' onClick={handleClose}> <DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}} /> Cancelar</button>
						</div>
                    </div>
				</div>
				</form>
			</Box>
		</Modal>
	);
};

export default CargoEditar;
