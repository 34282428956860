import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Encabezado } from '../../Components/Encabezado/Encabezado';
import Loading from "../../Components/Modals/Loading/Loader";
import { Grid, Paper, Stack, Typography, TextField, TableContainer, TableHead, TableRow, Box, TableBody, TableCell, Divider } from '@mui/material';
import { Table } from 'reactstrap';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Select from "react-select";
import { Controller, useForm, useFieldArray, useWatch} from 'react-hook-form';
import MaskedInput from "react-input-mask";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { BiSave } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import { Search } from "@material-ui/icons";
import moment from 'moment';
import swal from 'sweetalert';

import { 
    fetchTipoSangre,
    fetchAfpPensiones,
    fetchSeguros,
    fetchEstadosCiviles,
    fetchEnfermedades,
    fetchTipoFamiliares,
    fetchHabitos,
    selectAllTiposSangre,
    selectAllAFPPensiones,
    selectAllSeguros,
    selectAllEstadosCiviles,
    selectAllTipoFamiliares,
    selectAllEnfermedades,
    selectAllHabitos
} from '../../redux/Utility/UtilitySlice';

import {
    fetchCargosLista,
    selectAllCargosLista,
} from "../../redux/Cargos/CargosSlice";

import {
    fetchAreasOrganizacionales,
    selectAllAreasOrganizacionales,
} from "../../redux/AreaOrganizacional/AreaOrganizacionalSlice";

import { 
    fetchCreateHistorialClinico,
    fetchLimpiarEmpleadoHistorialClinico,
    fetchGetEmpleado,
    fetchCambiarSuccess,
    selectIsLoading,
    selectIsSuccess,
    selectMessage,
    selectEmpleado 
} from '../../redux/HistoricoClinico/HistoricoClinicoSlice';
import { generarListaPatologicosEnfermedades } from './Funcs/generarListaPatologicosEnfermedades';
import { generarListaHabitos } from './Funcs/generarListaHabitos';

export const AgregarHistorialClinico = () => {

    // ? HOOK-NAVEGACION
    const navigate = useNavigate();

    // ? USE-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const tipoSangre = useSelector(selectAllTiposSangre);
    const cargos = useSelector(selectAllCargosLista);
    const areaOrganizacionales = useSelector(selectAllAreasOrganizacionales);
    const seguros = useSelector(selectAllSeguros);
    const afpPensiones = useSelector(selectAllAFPPensiones);
    const estadosCivil = useSelector(selectAllEstadosCiviles);
    const tipoFamiliares = useSelector(selectAllTipoFamiliares);
    const enfermedades = useSelector(selectAllEnfermedades);
    const habitos = useSelector(selectAllHabitos);
    const empleado = useSelector(selectEmpleado);

    // ? HOOK-SELECTOR DEL HISTORIAL CLINICO.
    const isLoading = useSelector(selectIsLoading);
    const success = useSelector(selectIsSuccess);
    const message = useSelector(selectMessage);

    // ? HOOK-STATE
    const [listHabitos, setListHabitos] = useState({});
    const [listaEnfermedadesFamiliares, setListaEnfermedadesFamiliares] = useState([]);

    // ? HOOK-FORM PARA LA BUSQUEDA DE EMPLEADO
    const {
        register: register2,
        formState: { errors : errors2 },
        handleSubmit: handleSubmit2,
        control: control2,
        watch: watch2,
        getValues: getValues2,
        setValue: setValue2,
        reset: reset2,
        
      } = useForm({
        defaultValues: {
            filtro: {
                cedula: '',
                codigoEmpleado: 0
            },
            empleado: {
                empleadoId: 0,
                codigoEmpleado: 0,
                nombres: "",
                apellidos: "",
                cedula: "",
                sexo: "",
                fechaNacimiento: new Date(),
                tipoSangreId: 0,
                cargoId: 0,
                direccion: "",
                areaOrganizacionalId: 0,
                celular: "",
                arsId: 0,
                afpId: 0
            }
        }
      })

    // ? HOOK-FORM PARA EL FORMULARIO HISTORIAL-CLINICO.
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        control,
        watch,
        reset,
        getValues,
      } = useForm({
        defaultValues: {
            empleado: {
                empleadoId: 0,
                tipoSangreId: 0,
                arsId: 0,
                afpId: 0
            },
            historicoClinico: {
                id: 0,
                empleadoId: 0,
                fechaCreacion: new Date(),
                sarampion: false,
                varicela: false,
                meningitis: false,
                paperas: false,
                transfusiones: false,
                quirurgicos: false,
                cualQuirurgicos: "",
                hospitalizacion: "",
                usoLentes: false,
                vacunas: false,
                vacunasCovid1: false,
                vacunasCovid2: false,
                vacunasCovid3: false,
                alergias: false,
                alergiasCuales: "",
                edadMenarquia: 0,
                periodoMenstrual: "",
                menopausia: "",
                hijos: false,
                cantidadHijos: 0,
                pendientes: ''
            },
            vacunaCovid: 'vacunasCovid1',
            patologicosPersonales: [],
            habitosVida: []
        }
      });


    // ? HOOK-FIELD-ARRAY PARA LA LISTA DE PATOLOGICOS-PERSONALES
    const { fields } = useFieldArray({
        control,
        name: "patologicosPersonales",
        rules: {},
    });

    // ? HOOK-FIELD PARA LA LISTA DE HABITOS DE VIDA.
    const { fields: fields2 } = useFieldArray({
        control,
        name: "habitosVida",
        rules: {},
    });

    // ? HOOK-WATCH
    const habitosForm = useWatch({
        control,
        name: "habitosVida"
    });

    const patologicosForm = useWatch({
        control,
        name: "patologicosPersonales"
    });

    // ? HOOK-EFFECT PARA SOLICITAR TODAS LAS LISTAS.
    useEffect(() => {
      dispatch(fetchTipoSangre());
      dispatch(fetchCargosLista());
      dispatch(fetchAreasOrganizacionales());
      dispatch(fetchSeguros());
      dispatch(fetchAfpPensiones());
      dispatch(fetchEstadosCiviles());
      dispatch(fetchTipoFamiliares());
      dispatch(fetchEnfermedades());
      dispatch(fetchHabitos());
    }, [])
    
    // ? HOOK-EFFECT PARA CREACION DE LISTA DE ENFERMEDADES-FAMILIARES
    useEffect(() => {
        
      if(enfermedades.length > 0 && patologicosForm.length === 0 || enfermedades.length > 0 && patologicosForm?.flat()?.length === 0){

        // * VARIABLE QUE ALMACENA LA LISTA GENERADA POR LA FUNCION SOBRE ENFERMEDADES PATOLOGICAS.
        const arr = generarListaPatologicosEnfermedades({enfermedades, tipoFamiliares});
        
        // * ASIGNACION DE VALORES PREDETERMINADOS AL ARRAY DE PATOLOGICOS.
        setValue('patologicosPersonales', arr);

        // * ASIGNACION DE ARRAY AL STATE DE ENFERMEDADES-FAMILIARES/
        setListaEnfermedadesFamiliares(arr);
      }
      
    }, [enfermedades, patologicosForm, listaEnfermedadesFamiliares, tipoFamiliares])


    // ? HOOK-EFFECT PARA DIVIDIR LA LISTA DE LOS HABITOS.
    useEffect(() => {
      
        if(habitos.length > 0 && habitosForm.length === 0){

           const { listaHabitos, columnas } = generarListaHabitos(habitos);

            // * ASIGNACION DE VALORES PREDETERMINADOS AL ARRAY DE PATOLOGICOS.
            setValue('habitosVida', listaHabitos);
      
            // * ASIGNACION DE LISTA DE HABITOS POR COLUMNAS.
            setListHabitos(columnas);
        }

    }, [habitos, habitosForm])


    // ? HOOK-EFFECT PARA LAS ALERTAS DEL FORMULARIO.
    useEffect(() => {
        if (success === "success" && message?.length > 0) {
            
            // * REESTABLECER VALORES DE LOS FORMULARIOS.
            resetAll();

            // * LIMPIAR LOS REDUCER.
            dispatch(fetchCambiarSuccess());
            dispatch(fetchLimpiarEmpleadoHistorialClinico());
            
            // * MENSAJE DEL CONFIRMACION.
            swal("Exitoso!", message, "success");
        } else if (success === "error" ) {
            dispatch(fetchCambiarSuccess());
            swal("Error!", message, "error");
        }
    }, [success]);

    
    // ? HOOK-EFFECT PARA ASIGNAR EL EMPLEADO
    useEffect(() => {

        // * GUARDANDO EMPLEADO
        setValue2('empleado', empleado);
        
        const data = {
          afpId: empleado?.afpId,
          arsId: empleado?.arsId,
          tipoSangreId: empleado?.tipoSangreId,
          empleadoId: 0
        };
  
        // * GUARDANDO LA DATA DEL EMPLEADO PARA CAMBIAR A LOS SELECT.
        setValue('empleado', data);
  
    }, [empleado])
  

    // * FUNCION PARA OBTENER LA FRECUENCIA SEGUN EL DIA.
    const getFrecuencia = (id) => {

        const frecuencias = {
            1: 'día',
            2: 'día',
            3: 'semana',
            4: 'semana',
            5: 'mes',
            6: 'semana'
        };

        return frecuencias[id] ?? '';
    } 
  
    // * FUNCION PARA BUSCAR EMPLEADO
    const searchEmpleado = () => {

        // * DESESTRUCTURACION DE LA DATA.
        const { filtro: { cedula, codigoEmpleado } } = watch2();

        // * SI EXISTEN ERRORES SE CANCELA LA PETICION A LA API.
        if(errors2.filtro){
            return;
        }

        const filtro = {
            cedula: cedula.replace(/_/g, "").split("-").join(""),
            codigoEmpleado: isNaN(codigoEmpleado) ? 0 : codigoEmpleado
        }
        
        // * BUSCANDO DATA DEL EMPLEADO.
        dispatch(fetchGetEmpleado(filtro));

    }
    
    // * FUNCION PARA ENVIAR LA DATA.
    const sendData = () => {
        
        // * ESTRUCTURACION DE OBJETO.
        const empleadoSendApi = {
            afpId: watch('empleado.afpId'),
            arsId: watch('empleado.arsId'),
            empleadoId: watch2('empleado.id'),
            tipoSangreId: watch('empleado.tipoSangreId')
        }

        // * ASIGNACION DE DATA A ENVIAR A API.
        setValue('empleado', empleadoSendApi);
        
        // * BUSCANDO PROPIEDAD LLAMADA VACUNAS PARA ASIGNARLE SUS VALORES CORRESPONDIENTES
        for (let propiedad in watch('historicoClinico')) {
            if (watch('historicoClinico').hasOwnProperty(propiedad) && propiedad.includes("vacunasCovid")) {
              if(propiedad === getValues('vacunaCovid')){
                setValue(`historicoClinico.${propiedad}`, true);
              }
              else{
                setValue(`historicoClinico.${propiedad}`, false);
              }
            }
        }

        // * DESESTRUCTURACION DE DATOS
        const { empleado, habitosVida, historicoClinico, patologicosPersonales } = watch();

        // * ESTRUCTURACION DEL OBJETO A ENVIAR A API.
        const dataApi = {
            empleado,
            historicoClinico,
            listaHabitos: habitosVida,
            listaEnfermedades: patologicosPersonales.flat()
        }

        // * ENVIANDO DATA A LA API.
        dispatch(fetchCreateHistorialClinico(dataApi));
    }

    const resetAll = () => {
        
        // * RESET DEL FORMULARIO
        reset();

        // * LISTA DE HABITOS
        const { listaHabitos } = generarListaHabitos(habitos);

        // * ASIGNACION DE VALORES PREDETERMINADOS DEL ARRAY DE HABITOS.
        setValue('habitosVida', listaHabitos);

        // * VARIABLE QUE ALMACENA LA LISTA GENERADA POR LA FUNCION SOBRE ENFERMEDADES PATOLOGICAS.
        const arr = generarListaPatologicosEnfermedades({enfermedades, tipoFamiliares});

        // * ASIGNACION DE VALORES PREDETERMINADOS DEL ARRAY DE PATOLOGICOS.
        setValue('patologicosPersonales', arr);

        // * RESET DE LA BARRA DE BUSQUEDA.
        reset2();
    }


    // * VALIDACION DE EMPLEADO EXISTENTE
    const empleadoIsValid = Object.keys(empleado).length > 0;

    // * VALIDACION PARA VERIFICAR QUE ES HOMBRE Y DESHABILITAR LOS CAMPOS PERIODO-MENTRUAL Y MENARQUIA.
    const validacionHombre = Boolean(empleado?.sexo?.toLowerCase() === "Masculino".toLowerCase());

  return (
    <Encabezado title={'Agregar Historia Clínica'} container>
        
        <Paper elevation={5}>
            <Box p={2} mb={2} component='form' onSubmit={handleSubmit2(searchEmpleado)}>
                <div className="row">
                    <div className="col-4">
                        <div className="form-group">
                            <label className="form-label fw-bold">Cédula</label>

                            <Controller
                            name="filtro.cedula"
                            control={control2}
                            rules={{
                                validate: (value) => {
                                    const codigoEmpleado = watch2('filtro.codigoEmpleado');
                                    
                                    // * VALIDACION
                                    if ((codigoEmpleado === 0 && value.length === 0) | (isNaN(codigoEmpleado) && value.length === 0)) {
                                        return 'La cédula es requerida';
                                    }
                                    // Si no hay error, devuelve true o undefined
                                    return true;
                                },
                                required: {
                                    value: (getValues2('filtro.codigoEmpleado') === 0 && getValues2('filtro.cedula').length === 0),
                                    message: 'Ingrese una cédula válida'
                                },
                                minLength: {
                                    value: 13,
                                    message: 'La cédula debe tener 11 dígitos'
                                }
                            }}
                            render={({ field }) => (
                            <MaskedInput
                                id='cedula'
                                disabled={watch2('filtro.codigoEmpleado') > 0}
                                className="form-control"
                                mask="999-9999999-9"
                                maskChar=""
                                value={field.value}
                                onChange={field.onChange}
                                minLength={15}
                                />
                            )}
                            />

                            {errors2?.filtro?.cedula &&
                                errors2?.filtro?.cedula && (
                                <div className="text-danger">
                                    { errors2?.filtro?.cedula.message}
                                </div>
                            )}

                        </div>
                    </div>

                    <div className="col-4">
                        <div className="form-group">
                            <label className="form-label fw-bold">
                                Código de Empleado
                            </label>
                            <input
                                id='codigoEmpleado'
                                disabled={watch2('filtro.cedula')?.length > 0}
                                className="form-control"
                                type="number"
                                {...register2("filtro.codigoEmpleado", {
                                valueAsNumber: true,
                                value: 0,
                                validate: (valor) =>
                                   (getValues2('filtro.cedula').length === 0 && valor > 0) || getValues2('filtro.cedula').length > 0,
                                })}
                            />

                            {errors2?.filtro?.codigoEmpleado &&
                                errors2?.filtro?.codigoEmpleado?.type === "validate" && (
                                <div className="text-danger">
                                    Ingrese un código válido
                                </div>
                            )}

                        </div>
                    </div>

                    <div className='col-4 d-flex justify-content-end align-items-end'>
                        <div>
                            <button id='btn-buscar' type='submit' className='btn btn-primary-mem'>Buscar <Search style={{ marginTop: -1.5 }} /></button>
                        </div>
                    </div>

                </div>
            </Box>
        </Paper>

        <Paper elevation={5}>

            <Box p={2} mb={-4}>
                <Typography textTransform='uppercase'>
                    Datos del Empleado
                </Typography>
                <Divider />
            </Box>

            <Grid container p={2} spacing={2}>
            
                <Grid item xs={6}>
                    <Paper elevation={3}>
                        <Grid p={4}>
                            <Stack>
                            
                                <Typography fontWeight='bold' fontSize={12}>No. Empleado</Typography>
                                <input {...register2('empleado.codigoEmpleado')} disabled className='form-control' />
                                
                                <Typography fontWeight='bold' fontSize={12} mt={2}>Nombre</Typography>
                                <input {...register2('empleado.nombres')} disabled className='form-control' />
                                
                                <Typography fontWeight='bold' fontSize={12} mt={2}>Apellido</Typography>
                                <input {...register2('empleado.apellidos')} disabled className='form-control' />
                              
                                <Typography fontWeight='bold' fontSize={12} mt={2}>Cédula</Typography>
                                <Controller
                                name="empleado.cedula"
                                control={control2}
                                render={({ field }) => (
                                <MaskedInput
                                    disabled
                                    className="form-control"
                                    mask="999-9999999-9"
                                    maskChar=""
                                    value={field.value}
                                    onChange={field.onChange}
                                    />
                                )}
                                />
                                
                                <Typography fontWeight='bold' fontSize={12} mt={2}>Sexo</Typography>
                                <input disabled className='form-control' {...register2('empleado.sexo')} />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Fecha Nacimiento</Typography>

                                <Controller
                                control={control2}
                                name="empleado.fechaNacimiento"
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        disabled
                                        label={" "}
                                        mask="__/__/____"
                                        value={field.value}
                                        onChange={(e) => field.onChange(e)}
                                        renderInput={(params) => (
                                        <TextField className="form-control" {...params} />
                                        )}
                                    />
                                    </LocalizationProvider>
                                )}
                                />
                                
                                <Typography fontWeight='bold' fontSize={12} mt={2}>Edad</Typography>
                                <input value={moment().diff(empleado?.fechaNacimiento, 'years', false)} disabled className='form-control' />
                                
                                <Typography fontWeight='bold' fontSize={12} mt={2}>Estado Civil</Typography>
                                    <Controller
                                    control={control2}
                                    name="empleado.estadoCivilId"
                                    render={({ field }) => (
                                        <Select
                                            isDisabled
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            placeholder="Seleccionar Estado Civil"
                                            options={estadosCivil}
                                            getOptionLabel={(option) => option.nombre}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => field.onChange(e.id)}
                                            value={estadosCivil.find(x => x.id === field.value)}
                                        />
                                    )}
                                    />

                            </Stack>
                        </Grid>
                    </Paper>
                </Grid>
           
                <Grid item xs={6}>
                    <Paper elevation={3}>
                        <Grid p={4}>
                            <Stack>
                                <Typography fontWeight='bold' fontSize={12}>Tipo Sangre</Typography>
                                    <Controller
                                    control={control}
                                    name="empleado.tipoSangreId"
                                    render={({ field }) => (
                                        <Select
                                            id='empleado.tipoSangreId'
                                            isDisabled={!empleadoIsValid}
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            placeholder="Seleccionar Tipo de Sangre"
                                            options={tipoSangre}
                                            getOptionLabel={(option) => option.nombre}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => field.onChange(e.id)}
                                            value={tipoSangre.find(x => x.id === field.value)}
                                        />
                                        )}
                                    />
                                
                                <Typography fontWeight='bold' fontSize={12} mt={2}>Posición</Typography>
                                    <Controller
                                        control={control2}
                                        name="empleado.cargoId"
                                        render={({ field }) => (
                                            <Select
                                                isDisabled
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                placeholder="Seleccionar Cargo"
                                                options={cargos}
                                                getOptionLabel={(option) => option.titulo}
                                                getOptionValue={(option) => option.id}
                                                onChange={(e) => field.onChange(e.id)}
                                                value={cargos.find(x => x.id === field.value)}
                                            />
                                        )}
                                    />
                                    

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Ubicación</Typography>
                                <input {...register2('empleado.direccion')} disabled className='form-control' />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Área</Typography>
                                    <Controller
                                        control={control2}
                                        name="empleado.areaOrganizacionalId"
                                        render={({ field }) => (
                                                <Select
                                                isDisabled
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                placeholder="Seleccionar Área Organizacional"
                                                options={areaOrganizacionales}
                                                getOptionLabel={(option) => option.nombre}
                                                getOptionValue={(option) => option.id}
                                                onChange={(e) => field.onChange(e.id)}
                                                value={areaOrganizacionales.find(x => x.id === field.value)}
                                                />
                                            )}
                                    />
                                    

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Grado Académico</Typography>
                                <input {...register2('empleado.gradoAcademico')} disabled className='form-control' />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>No. Celular</Typography>

                                <Controller
                                    control={control2}
                                    name="empleado.celular"
                                    render={({ field }) => (
                                            <MaskedInput
                                            disabled
                                            className="form-control"
                                            mask="(999) 999-9999"
                                            maskChar=""
                                            value={field.value}
                                            onChange={field.onChange} 
                                            />
                                        )}
                                />
                                    

                                <Typography fontWeight='bold' fontSize={12} mt={2}>ARS (Seguro)</Typography>
                                    <Controller
                                    control={control}
                                    name="empleado.arsId"
                                    render={({ field }) => (
                                        <Select
                                            id='empleado.arsId'
                                            isDisabled={!empleadoIsValid}
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            placeholder="Seleccionar ARS"
                                            options={seguros}
                                            getOptionLabel={(option) => option.nombre}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => field.onChange(e.id)}
                                            value={seguros.find(x => x.id === field.value)}
                                        />
                                        )}
                                    />
                                
                                <Typography fontWeight='bold' fontSize={12} mt={2}>AFP (Pensiones)</Typography>
                                    <Controller
                                    control={control}
                                    name="empleado.afpId"
                                    render={({ field }) => (
                                        <Select
                                            id='empleado.afpId'
                                            isDisabled={!empleadoIsValid}
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            placeholder="Seleccionar AFP"
                                            options={afpPensiones}
                                            getOptionLabel={(option) => option.nombre}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => field.onChange(e.id)}
                                            value={afpPensiones.find(x => x.id === field.value)}
                                        />
                                        )}
                                    />


                            </Stack>
                        </Grid>
                    </Paper>
                </Grid>
                   
            </Grid>
        </Paper>
        
        <form onSubmit={handleSubmit(sendData)}>

            <Paper elevation={5}>
                <Box sx={{ mt: 3 }} maxWidth={'100%'} p={2}>

                    <Typography textTransform='uppercase'>
                        Patológicos Personales y Familiares
                    </Typography>
                    <Divider />

                    <Box mt={2}>
                        <Paper elevation={3}>
                            <TableContainer>
                                <Table stickyHeader>

                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                
                                            </TableCell>
                                            {
                                                tipoFamiliares.map((item) => (
                                                    <TableCell align='center' key={item.id} sx={{fontWeight: 'bold'}}>
                                                        {item.nombre}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    
                                    <TableBody>

                                        {
                                            listaEnfermedadesFamiliares.length > 0 &&
                                            listaEnfermedadesFamiliares?.map((item, index) => (
                                                <TableRow key={index}>

                                                    <TableCell> 
                                                        {enfermedades.find(x => x.id === item[0]?.enfermedadId)?.nombre ?? ''}
                                                    </TableCell>

                                                {
                                                    item.map((item2, index2) => (
                                                        <TableCell align='center' key={index2}>
                                                            <FormControlLabel
                                                            key={index2}
                                                            sx={{
                                                                "& .MuiTypography-root": {
                                                                fontSize: { lg: 14, md: 12, xs: 11 },
                                                                },
                                                            }}
                                                            control={
                                                                    <>
                                                                        <Checkbox
                                                                        id={`enfermedad.${item2.enfermedadId}.${item2.familiarId}`}
                                                                        disabled={!empleadoIsValid}
                                                                        value={getValues(`patologicosPersonales.${index}.${index2}.seleccionado`)}
                                                                        checked={watch(`patologicosPersonales.${index}.${index2}.seleccionado`)}
                                                                        onChange={(e) => {
                                                                            const isChecked = e.target.checked;
                                                                            setValue(`patologicosPersonales.${index}.${index2}.seleccionado`, isChecked);

                                                                            if (!isChecked) { // Si el checkbox está desmarcado, establece el valor del campo de texto como vacío
                                                                                setValue(`patologicosPersonales.${index}.${index2}.enfermedadesOtras`, ''); // Actualiza el valor en useForm
                                                                            }
                                                                        }}
                                                                        />

                                                                        <TextField
                                                                        {...register(`patologicosPersonales.${index}.${index2}.enfermedadesOtras`, {
                                                                            value: '',
                                                                        })}
                                                                        label=" "
                                                                        hidden={item2.enfermedadId !== 11 | !Boolean(watch(`patologicosPersonales.${index}.${index2}.seleccionado`))}
                                                                        />

                                                                    </>
                                                            }
                                                            />

                                                            </TableCell>
                                                    ))
                                                }
                                                    
                                                </TableRow>
                                            ))
                                        }


                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>

                </Box>
            </Paper>

            <Paper elevation={5}>
                <Box sx={{ mt: 3 }} maxWidth={'100%'} p={2}>
                    <Typography textTransform='uppercase'>
                        Condiciones médicas personales
                    </Typography>
                    <Divider />

                    <Paper>             
                        <Box marginTop={2} p={2}>

                            <Box className='d-flex'>
                                <Typography mt={1.2}>Enfermedad de la infancia: </Typography>
                                    <Controller
                                    control={control}
                                    name={'historicoClinico.varicela'}
                                    defaultValue={false}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                        control={
                                            <Checkbox id='historicoClinico.varicela' disabled={!empleadoIsValid} checked={value} onChange={onChange} />
                                        }
                                        label="Varicela"
                                        />
                                    )}
                                    />
                            
                                    <Controller
                                    control={control}
                                    name={'historicoClinico.meningitis'}
                                    defaultValue={false}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                        control={
                                            <Checkbox id='historicoClinico.meningitis' disabled={!empleadoIsValid} checked={value} onChange={onChange} />
                                        }
                                        label="Meningitis"
                                        />
                                    )}
                                    />

                                    <Controller
                                    control={control}
                                    name={'historicoClinico.paperas'}
                                    defaultValue={false}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                        control={
                                            <Checkbox id='historicoClinico.paperas' disabled={!empleadoIsValid} checked={value} onChange={onChange} />
                                        }
                                        label="Paperas"
                                        />
                                    )}
                                    />
                                
                            </Box>
                        
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box className='d-flex'>
                                        <Typography mt={1.2}>Transfusiones: </Typography>
                                            <Controller
                                            control={control}
                                            name={'historicoClinico.transfusiones'}
                                            defaultValue={false}
                                            render={({ field: { onChange, value } }) => (
                                                <FormControl className='d-flex'>
                                                    <RadioGroup
                                                        id='historicoClinico.transfusiones'
                                                        value={value}
                                                        style={{marginLeft: 2}}
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={onChange}
                                                    >
                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={true} control={<Radio />} label="Si" />
                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={false} control={<Radio />} label="No" />
                                                        
                                                    </RadioGroup>
                                                </FormControl>
                                            )}
                                            />
                                        
                                    </Box>

                                    <Box className='d-flex'>
                                        <Typography mt={1.2}>Quirúrgicos: </Typography>
                                        <Controller
                                        control={control}
                                        name={'historicoClinico.quirurgicos'}
                                        defaultValue={false}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl className='d-flex'>
                                                <RadioGroup
                                                    id='historicoClinico.quirurgicos'
                                                    value={value ? 'true' : 'false'}
                                                    style={{ marginLeft: 2 }}
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={(e) => {
                                                        const isChecked = e.target.value === 'true'; 

                                                        onChange(isChecked);

                                                        if (!isChecked) {
                                                            setValue('historicoClinico.cualQuirurgicos', "");
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        disabled={!empleadoIsValid}
                                                        labelPlacement="start"
                                                        value={'true'}
                                                        control={<Radio />}
                                                        label="Si"
                                                    />
                                                    <FormControlLabel
                                                        disabled={!empleadoIsValid}
                                                        labelPlacement="start"
                                                        value={'false'}
                                                        control={<Radio />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />
                                    </Box>

                                    <Box className='d-flex mb-2'>
                                        <Typography mt={1.2}>Cual</Typography>
                                        <input id='historicoClinico.cualQuirurgicos' disabled={!empleadoIsValid | !watch('historicoClinico.quirurgicos')} {...register('historicoClinico.cualQuirurgicos')} className='form-control w-75 ms-2' />
                                    </Box>
                                
                                    <Box className='d-flex mb-2'>
                                        <Typography mt={1.2}>Hospitalización</Typography>
                                        <input id='historicoClinico.hospitalizacion' disabled={!empleadoIsValid} {...register('historicoClinico.hospitalizacion')}  className='form-control w-75 ms-2' />
                                    </Box>

                                    <Box className='d-flex mb-2'>
                                        <Typography mt={1.2}>Uso de lentes: </Typography>
                                            <Controller
                                                control={control}
                                                name={'historicoClinico.usoLentes'}
                                                defaultValue={false}
                                                render={({ field: { onChange, value } }) => (
                                                    <FormControl className='d-flex'>
                                                        <RadioGroup
                                                            id='historicoClinico.usoLentes'
                                                            value={value}
                                                            style={{marginLeft: 2}}
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            onChange={onChange}
                                                        >
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={true} control={<Radio />} label="Si" />
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={false} control={<Radio />} label="No" />
                                                            
                                                        </RadioGroup>
                                                    </FormControl>
                                                )}
                                                />
                                    </Box>

                                </Grid>
                                <Grid item xs={6}>

                                    <Box className='d-flex'>
                                        <Typography mt={1.2}>Vacunas: </Typography>
                                            <Controller
                                                control={control}
                                                name={'historicoClinico.vacunas'}
                                                defaultValue={false}
                                                render={({ field: { onChange, value } }) => (
                                                    <FormControl className='d-flex'>
                                                        <RadioGroup
                                                            id='historicoClinico.vacunas'
                                                            value={value}
                                                            style={{marginLeft: 2}}
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            onChange={onChange}
                                                        >
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={true} control={<Radio />} label="Si" />
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={false} control={<Radio />} label="No" />
                                                            
                                                        </RadioGroup>
                                                    </FormControl>
                                                )}
                                                />

                                    </Box>

                                    <Box className='d-flex'>
                                        <Typography mt={1.2}>Vacunas COVID: </Typography>
                                            <Controller
                                                control={control}
                                                name={'vacunaCovid'}
                                                defaultValue={false}
                                                render={({ field: { onChange, value } }) => (
                                                    <FormControl className='d-flex'>
                                                        <RadioGroup
                                                            id='vacunaCovid'
                                                            value={value}
                                                            style={{marginLeft: 2}}
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            onChange={onChange}
                                                        >
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={'vacunasCovid1'} control={<Radio  />} label="1ra" />
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={'vacunasCovid2'} control={<Radio />} label="2da" />
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={'vacunasCovid3'} control={<Radio />} label="3ra" />
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={'ninguna'} control={<Radio />} label="No aplica" />
                                                            
                                                        </RadioGroup>
                                                    </FormControl>
                                                )}
                                                />
                                    </Box>

                                    <Box className='d-flex mb-2'>
                                        <Typography mt={1.2}>Pendientes</Typography>
                                        <input id='historicoClinico.pendientes' disabled={!empleadoIsValid} {...register('historicoClinico.pendientes')} className='form-control w-75 ms-2' />
                                    </Box>
                                    
                                    <Box className='d-flex mb-2'>
                                        <Typography mt={1.2}>Alergias: </Typography>
                                        
                                        <Controller
                                        control={control}
                                        name={'historicoClinico.alergias'}
                                        defaultValue={false}
                                        render={({ field: { onChange, value } }) => (
                                            <FormControl className='d-flex'>
                                                <RadioGroup
                                                    id='historicoClinico.alergias'
                                                    value={value ? 'true' : 'false'}
                                                    style={{ marginLeft: 2 }}
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={(e) => {
                                                        const isChecked = e.target.value === 'true'; 

                                                        onChange(isChecked);

                                                        if (!isChecked) {
                                                            setValue('historicoClinico.alergiasCuales', "");
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        disabled={!empleadoIsValid}
                                                        labelPlacement="start"
                                                        value={'true'}
                                                        control={<Radio />}
                                                        label="Si"
                                                    />
                                                    <FormControlLabel
                                                        disabled={!empleadoIsValid}
                                                        labelPlacement="start"
                                                        value={'false'}
                                                        control={<Radio />}
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                        />
                                    </Box>

                                    <Box className='d-flex mb-2'>
                                        <Typography mt={1.2}>Cuales</Typography>
                                        <input id='historicoClinico.alergiasCuales' disabled={!empleadoIsValid | !watch('historicoClinico.alergias')} {...register('historicoClinico.alergiasCuales')} className='form-control w-75 ms-2' />
                                    </Box>
                                                        
                                </Grid>
                            </Grid>
                        
                        </Box>
                    </Paper>    
                
                </Box>
            </Paper>

            <Paper elevation={5}>
                <Box sx={{ mt: 3 }} maxWidth={'100%'} p={2}>

                    <Typography textTransform='uppercase'>
                        Hábitos de Vida
                    </Typography>
                    <Divider />

                    <Paper elevation={3}>
                        <Box mt={2}>
                            <TableContainer p={2} mt={2}>
                                <Table stickyHeader>

                                    <TableHead>
                                    
                                        <TableRow>
                                            <TableCell width="15%" sx={{fontWeight: 'bold'}}>
                                                Hábitos
                                            </TableCell>
                                            <TableCell align='center' width="15%" sx={{fontWeight: 'bold'}}>
                                                Si/No
                                            </TableCell>
                                            <TableCell width="15%" sx={{fontWeight: 'bold'}}>
                                                Frecuencia
                                            </TableCell>
                                            <TableCell width="15%" sx={{fontWeight: 'bold'}}>
                                                Hábitos
                                            </TableCell>
                                            <TableCell align='center' width="15%" sx={{fontWeight: 'bold'}}>
                                                Si/No
                                            </TableCell>
                                            <TableCell width="15%" sx={{fontWeight: 'bold'}}>
                                                Frecuencia
                                            </TableCell>
                                        </TableRow>

                                    </TableHead>

                                <TableBody>
                                        {
                                            listHabitos?.column1?.map((item, index) => (
                                                <TableRow key={index}>
                                                
                                                    <TableCell>
                                                        {habitos.find(x => x.id === item.habitoId)?.nombre ?? ''}
                                                    </TableCell>
                                                
                                                    <TableCell>

                                                        <div className='d-flex justify-content-center'>
                                                            <Controller
                                                            name={`habitosVida.${item.habitoId}`}
                                                            control={control}
                                                            value={getValues(`habitosVida.${index}.seleccionado`)}
                                                            defaultValue={false}
                                                            render={() => (
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        id={`habitosVida.${item.habitoId}`}
                                                                        value={getValues(`habitosVida.${index}.seleccionado`)}
                                                                        checked={watch(`habitosVida.${index}.seleccionado`)}
                                                                        row
                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        name="row-radio-buttons-group"
                                                                        onChange={(e) => {
                                                                            const isChecked = e.target.value === 'true' ? true : false;
                        
                                                                            setValue(`habitosVida.${index}.seleccionado`, isChecked);

                                                                            if (!isChecked) { // Si el checkbox está desmarcado, establece el valor del campo de texto como vacío
                                                                                setValue(`habitosVida.${index}.frecuencia`, 0); // Actualiza el valor en useForm
                                                                            }
                                                                        }}
                                                                    >
                                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={true} control={<Radio />} label="Si" />
                                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={false} control={<Radio />} label="No" />
                                                                        
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            )}
                                                            />
                                                        </div>

                                                    </TableCell>

                                                    <TableCell align='center'>
                                                        <Box className='d-flex'>
                                                            <TextField 
                                                            id={`habitosVida.${item.habitoId}`}
                                                            disabled={!empleadoIsValid | !getValues(`habitosVida.${index}.seleccionado`)}
                                                            {...register(`habitosVida.${index}.frecuencia`, {
                                                                value: 0,
                                                            })}
                                                            style={{width: '30%'}} 
                                                            type='number' 
                                                            variant='standard' />

                                                            <Typography ml={1} mt={1.5}>{getFrecuencia(item.habitoId)}</Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        {habitos.find(x => x.id === listHabitos?.column2[index]?.habitoId)?.nombre ?? ''}
                                                    </TableCell>
                                                    <TableCell>

                                                        <div className='d-flex justify-content-center'>
                                                        <Controller
                                                            name={`habitosVida.${item.habitoId}`}
                                                            control={control}
                                                            value={getValues(`habitosVida.${index + 3}.seleccionado`)}
                                                            defaultValue={false}
                                                            render={() => (
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        id={`habitosVida.${item.habitoId}`}
                                                                        value={getValues(`habitosVida.${index + 3}.seleccionado`)}
                                                                        checked={watch(`habitosVida.${index + 3}.seleccionado`)}
                                                                        row
                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        name="row-radio-buttons-group"
                                                                        onChange={(e) => {
                                                                            const isChecked = e.target.value === 'true' ? true : false;
                        
                                                                            setValue(`habitosVida.${index + 3}.seleccionado`, isChecked);

                                                                            if (!isChecked) { // Si el checkbox está desmarcado, establece el valor del campo de texto como vacío
                                                                                setValue(`habitosVida.${index}.frecuencia`, 0); // Actualiza el valor en useForm
                                                                            }
                                                                        }}
                                                                    >
                                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={true} control={<Radio />} label="Si" />
                                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={false} control={<Radio />} label="No" />
                                                                        
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            )}
                                                            />
                                                        </div>
                                                    
                                                        
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box className='d-flex'>
                                                            <TextField 
                                                            id={`frecuenciaHabito.${item.habitoId}`}
                                                            disabled={!empleadoIsValid | !getValues(`habitosVida.${index + 3}.seleccionado`)}
                                                            {...register(`habitosVida.${index + 3}.frecuencia`, {
                                                                value: 0,
                                                            })}
                                                            style={{width: '30%'}} 
                                                            type='number' 
                                                            variant='standard' />

                                                            <Typography ml={1} mt={1.5}>{getFrecuencia(item.habitoId)}</Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                </TableBody>

                                </Table>
                            </TableContainer>
                        </Box>
                    </Paper>
                </Box>

            </Paper>

            <Paper elevation={5}>
                <Box maxWidth={'100%'} p={2} mb={2} mt={2}>

                    <Typography textTransform='uppercase'>
                        Gineco-obstétricos
                    </Typography>
                    <Divider />

                    <Paper elevation={3}>
                        <Box mt={2} p={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography>Edad de menarquia</Typography>
                                    <input id='historicoClinico.edadMenarquia' disabled={!empleadoIsValid | validacionHombre} type='number' {...register('historicoClinico.edadMenarquia')} className='form-control mb-2' />
                                    
                                    <Typography>Período Menstrual</Typography>
                                    <input id='historicoClinico.periodoMenstrual' disabled={!empleadoIsValid | validacionHombre}  {...register('historicoClinico.periodoMenstrual')} className='form-control' />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Menopausia</Typography>
                                    <input id='historicoClinico.menopausia' disabled={!empleadoIsValid}  {...register('historicoClinico.menopausia')} className='form-control' />

                                    <Box className='d-flex mt-4'>
                                        <Box className='d-flex mt-2'>
                                            <Typography mt={1}>Hijos: </Typography>
                                            <Controller
                                            control={control}
                                            name={'historicoClinico.hijos'}
                                            defaultValue={false}
                                            render={({ field: { onChange, value } }) => (
                                                <FormControl className='d-flex'>
                                                    <RadioGroup
                                                        id='historicoClinico.hijos'
                                                        style={{marginLeft: 2}}
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        value={value}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.value === 'true';

                                                            // * ASIGNANDO EL VALOR.
                                                            onChange(isChecked);

                                                            if (!isChecked) { // Si el checkbox está desmarcado, establece el valor del campo de texto como vacío
                                                                setValue('historicoClinico.cantidadHijos', 0); // Actualiza el valor en useForm
                                                            }
                                                        }}
                                                    >
                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={'true'} control={<Radio />} label="Si" />
                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={'false'} control={<Radio />} label="No" />

                                                    </RadioGroup>
                                                </FormControl>
                                            )}
                                            />
                                        </Box>

                                        <Typography mt={2} marginLeft={2} marginRight={1}>Cantidad</Typography>
                                        <input id='historicoClinico.hijos' disabled={!empleadoIsValid | !watch('historicoClinico.hijos')} type='number' {...register('historicoClinico.cantidadHijos')} className='form-control w-25' />
                                </Box>

                                </Grid>
                            </Grid>

                            <Box className='d-flex justify-content-center' mt={3}>
                                <button id='btn-guardar' type='submit' className='btn btn-primary-mem me-2' disabled={!empleadoIsValid}>Agregar <BiSave fontSize={22} /></button>
                                <button id='btn-cancelar' type='button' onClick={() => navigate('/historialClinico')} className='btn btn-danger'>Cancelar <MdCancel fontSize={22} /></button>
                            </Box>
                            
                        </Box>

                    </Paper>
                    
                
                </Box>

                
            </Paper>  

         </form>  

        <Loading open={isLoading} />
    </Encabezado>
  )
}
