import React, { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { TextField } from "@mui/material";
import { Search, CloudDownload, Delete } from "@mui/icons-material";
import swal from "sweetalert";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {selectUserData} from '../../redux/Login/LoginSlice';

export const FiltroReporteEmpleadosAsistencia = ({
  searchReporteAsistencia,
  isSuccess,
  message,
  setArea,
  ReporteAsistenciaExcel, 
  updateSuccess
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();

  // ? HOOK-SELECTOR
  const usuario = useSelector(selectUserData);

  // ? HOOK-STATE
  const [fechaDesde, setFechaDesde] = useState(new Date());
  const [fechaHasta, setFechaHasta] = useState(new Date());
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  // ? HOOK-EFFECT

  useEffect(() => {
   
    if(isSuccess === 'success'){
      swal("Exitoso!", "El reporte fue generado.", "success");
      updateSuccess();
    }
    else if(isSuccess === 'error'){
      swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implementación de Sistemas.", "error");
      updateSuccess();
    }

  }, [isSuccess])
  

  useEffect(() => {
    if (addRequestStatus === "success") {
      let filtro = {
        empleadoId: usuario.Id,
        desde: fechaDesde,
        hasta: fechaHasta,
        areaId: 0,
        estado: 0,
        todos: false, 
      };

      searchReporteAsistencia(filtro);
      setAddRequestStatus("idle");
    }
  }, [addRequestStatus, isSuccess]);


  // * FUNCION QUE LIMPIA LOS FILTROS.
  const clearFilter = (e) => {
    e.reset();
  };

  // * FUNCION QUE ENVIA LOS DATOS.
  const onSubmit = () => {
    setAddRequestStatus("success");
  };

  const onExport = () => {
    ReporteAsistenciaExcel({

        empleadoId: usuario.Id,
        desde: moment(fechaDesde).format("YYYY-MM-DD"),
        hasta: moment(fechaHasta).format("YYYY-MM-DD"),
        areaId: 0,
        estado: 0,
        todos: false, 
    });
  };


  return (
    <form onSubmit={clearFilter}>
      <div className="card mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="fw-bold">Fecha Desde</label>
                <Controller
                  control={control}
                  className="mb-3"
                  name="ReactDatepicker"
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat='dd/MM/yyyy'
                        label={" "}
                        mask="__/__/____"
                        value={fechaDesde}
                        onChange={(e) => setFechaDesde(e)}
                        renderInput={(params) => (
                          <TextField
                            height={10}
                            className="form-control"
                            style={{ height: 10 }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                <p className="text-danger mt-3">
                  {errors.fechaInicio?.message}
                </p>
                <p className="text-danger">{errors.nombres?.message}</p>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="fw-bold">Fecha Hasta</label>
                <Controller
                  control={control}
                  className="mb-3"
                  name="ReactDatepicker"
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat='dd/MM/yyyy'
                        minDate={fechaDesde}
                        label={" "}
                        mask="__/__/____"
                        value={fechaHasta}
                        onChange={(e) => setFechaHasta(e)}
                        renderInput={(params) => (
                          <TextField
                            height={10}
                            className="form-control"
                            style={{ height: 10 }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                <p className="text-danger mt-3">
                  {errors.fechaTermino?.message}
                </p>
                <p className="text-danger">{errors.nombres?.message}</p>
              </div>
            </div>
            
            <div className="text-end mt-3">
              <button
                type="button"
                className="btn btn-primary-mem rounded me-2"
                onClick={() => onSubmit()}
                title="Buscar"
              >
                <Search></Search>
              </button>
              <button
                type="button"
                onClick={() => onExport()}
                className='btn btn-success rounded-6 me-2'
                title="Descargar"
              >
                <CloudDownload></CloudDownload>
              </button>

                <a type="dow">

                </a>

              <button type="submit" className="btn btn-danger">
                <Delete />
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
