import React, { useState, useEffect } from 'react';
import { Encabezado } from '../../Components/Encabezado/Encabezado';
import { FiltroReporteEmpleado } from '../../Components/Empleado/FiltroReporteEmpleado';
import { ReporteDinamicoTable } from '../../Components/Empleado/Table/ReporteDinamicoTable';
import Loading from "../../Components/Modals/Loading/Loader";
import { 
  useSelector,
  useDispatch
} from 'react-redux';
import swal from 'sweetalert';

import {
  fetchCambiarSuccess,
  selectReporteDinamicoCount,
  selectReporteDinamicoData,
  selectEmpleadosIsLoading,
  selectEmpleadosIsSuccess,
  selectEmpleadosMessage
} from "../../redux/Empleados/EmpleadosSlice";


export const EmpleadosReporte = () => {

  const dispatch = useDispatch();

  // ? USE-STATE
  const [campos, setCampos] = useState({});

  // ? USE-STATE PARA LA PAGINACION
  const [page, setPage] = React.useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(25);
	const [ skipRow, setSkipRow ] = useState(0);

  // ? USE-SELECTOR
  const reporteData = useSelector(selectReporteDinamicoData);
  const reporteCount = useSelector(selectReporteDinamicoCount);
  const isLoading = useSelector(selectEmpleadosIsLoading);
  const isSuccess = useSelector(selectEmpleadosIsSuccess);
  const message = useSelector(selectEmpleadosMessage);

  // ? FUNCION PARA LIMPIAR SUCCESS
  const clearSuccess = () => dispatch(fetchCambiarSuccess())

  // ? HOOK-EFFECT
  useEffect(() => {
    
    if(isSuccess === "success" && message.length > 0){
      swal("Exitoso!", message, "success");
      clearSuccess();
    }
    else if(isSuccess === "error"){
      swal("Error", message, "error");
      clearSuccess();
    }

  }, [isSuccess])
  

  return (
    <Encabezado title='Reporte de Empleados' container={true}>
      <FiltroReporteEmpleado 
      setCampos={setCampos}
      page={page}
      skipRow={skipRow}
      rowsPerPage={rowsPerPage}
      reporteCount={reporteCount}
      />

      {
        reporteCount > 0 && 

          <ReporteDinamicoTable 
          campos={campos}
          reporteData={reporteData}
          reporteCount={reporteCount}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setSkipRow={setSkipRow}
          />
      }

      <Loading open={isLoading} />

    </Encabezado>
  )
}
