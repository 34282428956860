import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FaFilePdf } from "react-icons/fa6";
import { DropzoneArea } from 'material-ui-dropzone';
import { Typography } from '@mui/material';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';
import swal from 'sweetalert';

import { fetchPostResultadosValoresFiles } from '../../../redux/Evaluaciones/EvaluacionesSlice';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};

export const CargarArchivosPDFModal = ({uploadFilesPDF, setUploadFilesPDF, cargaArchivosEnabled}) => {
    
    const { logroMetaId, files: listFilesExist } = cargaArchivosEnabled;

    // ? HOOK-FORM
    const {
        handleSubmit,
        formState:{errors},
    }= useForm();
    
    // ? HOOK-STATE
    const [files, setFiles] = useState([]);
    
    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    const onSubmit = async ()=>{

        try {

            // * VALIDACION EN CASO DE QUE EL USUARIO GUARDÓ SIN SUBIR NINGUN ARCHIVO.
            if(files.length === 0){
                swal("Error", "Las evidencias son requeridas.", "error");
                return;
            }
            
            // * VALIDACION PARA VERIFICAR QUE LOS ARCHIVOS YA REGISTRADOS NO SE DUPLIQUEN CON LOS NUEVOS.
            if(listFilesExist.length > 0){
                const filesDuplicates = files
                    .filter(element =>
                        listFilesExist.some(fileExist => fileExist.nombreArchivo.toLowerCase() === element.name.toLowerCase())
                    )
                    .map(element => element.name);

                    if(filesDuplicates.length > 0){
                        const duplicatesString = filesDuplicates.join(', ');

                        swal("Error", `Estos archivos ya fueron registrados: ${duplicatesString}`, "error");
                        return;
                    }
            }

            // * VALIDACION PARA NO SUBIR MAS DE 5 ARCHIVOS
            if(cargaArchivosEnabled.files.length >= 5 || files.length > 5 || (cargaArchivosEnabled.files.length + files.length) > 5){
                swal("Error", "Has alcanzado el límite máximo de evidencias permitidas (5).", "error");
                return;
            }

            // * VALIDACION PARA IDENTIFICAR SI ** FILES ** ES UN ARRAY.
            if (Array.isArray(files) && files.length > 0) {

                // * CONVERTIR LOS DOCUMENTOS EN FORM-DATA PARA ENVIARLOS AL SERVIDOR
                const formData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    formData.append('files', file);  
                }

                // * GUARDAR LOS PDFS.
                dispatch(fetchPostResultadosValoresFiles({formData, logroMetaId}));
                
                // * CERRAR MODAL.
                setUploadFilesPDF(false)
            }
           
            
        } catch (error) {
            console.error(error);
        }
    }

  return (
    <Modal
    open={uploadFilesPDF}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
    >
        <Box sx={{ ...style, width: 700 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container">

                    <div className="row">
                        <Typography variant='h5' sx={{textTransform: 'uppercase'}} className="text-primary-mem text-center">Subir evidencias</Typography>
                    </div>

                    <div className="row mt-4">
                        <div className="col-5 me-0 pe-0">
                            <hr />
                        </div>
                        <div className="col-2 text-center">
                            <FaFilePdf fontSize={25} className='text-danger' />
                        </div>
                        <div className="col-5 ms-0 ps-0 ">
                            <hr />
                        </div>
                    </div>

                    <div className='row'>
                        <DropzoneArea
                            filesLimit={5}
                            onChange={(files) => {
                                setFiles(files)    
                            }}
                            acceptedFiles={['.pdf']}
                            maxFileSize={5000000}
                        />

                        <div className='d-flex justify-content-center mt-2'>
                            <button type='button' className='btn btn-danger me-3' onClick={() => setUploadFilesPDF(false)}><DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>CANCELAR</button>
                            <button type='submit' className='btn btn-primary-mem'><SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>GUARDAR</button>
                        </div>
                    </div>
                    
                </div>
            </form>
        </Box>
    </Modal>
  )
}

export default CargarArchivosPDFModal