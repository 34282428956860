import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { EmpleadosAccordion } from "./../../Components/AccordionEmpleados/Empleados";
import {
  selectAllEmpleados,
  selectEmpleadosIsLoading,
  fetchEmpleadosCount,
  selectEmpleadosCount,
  fetchEmpleados
} from "./../../redux/Empleados/EmpleadosSlice";
import Loading from "../../Components/Modals/Loading/Loader";
import TablePagination from "@mui/material/TablePagination";
import { BarraDeBusqueda } from "../../Components/Empleado/BarraDeBusqueda";

export function EmpleadosDesvinculados() {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const empleadosDesvinculados = useSelector(selectAllEmpleados);
  const empleadosLoading = useSelector(selectEmpleadosIsLoading);
  const empleadosCountApi = useSelector(selectEmpleadosCount);

  // ? HOOK-STATE PARA PAGINACION
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [skipRow, setSkipRow] = useState(0);

  // ? HOOK-STATE PARA GUARDAR INFORMACIONES.
  const [areaOrganizacionalId, SetAreaOrganizacionalId] = useState(0);
  const [cargoId, setCargoId] = useState(0);
  const [empleado, setEmpleado] = useState("");
  const [estado, setEstado] = useState(0);
  const [changeEmpleado, setChangeEmpleado] = useState("");

  // * FUNCIONES
  const handleChangePage = (event, newPage) => {
    setSkipRow(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSkipRow(0);
  };

  const handleAreaOrganizacional = (area) => {
    SetAreaOrganizacionalId(area.id);
  };

  const handleEmpleado = () => {
    setEmpleado(changeEmpleado);
  };

  const cleanfilter = () => {
    setEmpleado("");
    setChangeEmpleado("");
    SetAreaOrganizacionalId(0);
  };

  const CargarEmpleados = () => {
    dispatch(fetchEmpleados({
      skipRow,
      rowsPerPage,
      empleado,
      areaOrganizacionalId,
      cargoId,
      estado }));

      dispatch(
        fetchEmpleadosCount({ areaOrganizacionalId, cargoId, empleado, estado })
      );
  }

  // ? HOOK-EFFECT
  useEffect(() => {
    CargarEmpleados();
  }, [rowsPerPage, skipRow]);

  useEffect(() => {
    CargarEmpleados();
    setPage(0);
    setSkipRow(0);
  }, [areaOrganizacionalId, cargoId, empleado]);

  // ? HOOK-MEMO PARA ALMACENAR LA DATA DEL LISTADO DE EMPLEADOS Y EVITAR QUE SE RENDERICE.
  const data = useMemo(() => (empleadosDesvinculados), [empleadosDesvinculados]);
  
  return (
    <div className="container-fluid main">
      <div className="row">
        <div className="col-md-12">
          <div>
            <h3 className="text-primary-mem">EMPLEADOS DESVINCULADOS</h3>
            <hr />
          </div>

          <BarraDeBusqueda
            areaOrganizacionalId={areaOrganizacionalId}
            handleAreaOrganizacional={handleAreaOrganizacional}
            changeEmpleado={changeEmpleado}
            handleEmpleado={handleEmpleado}
            setChangeEmpleado={setChangeEmpleado}
            cleanfilter={cleanfilter}
          />

            <EmpleadosAccordion 
            empleados={data}
            />

        </div>

        <>
          <TablePagination
            component="div"
            count={empleadosCountApi}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>

        <Loading open={empleadosLoading} />
      </div>
    </div>
  );
}

export default EmpleadosDesvinculados;
