import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SearchIcon from "@mui/icons-material/Search";
import { IconTextField } from "../../Components/IconText/IconTextField";
import moment from "moment";

// * ICONS
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";

// * SLICE
import { fetchResumenLimpiar } from "../../redux/Reportes/ResumenAsistenciasSlice";
import { useDispatch } from "react-redux";

export const FiltroResumenAsistencia = ({
  searchResumenAsistencia,
  setEmpleados,
  cantidadEmpleados,
  setCantidadEmpleados,
  setValue,
  value,
  setEmpleadosFiltros,
  exportExcel,
}) => {
  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-STATE
  const [fechaDesde, setFechaDesde] = useState(new Date());
  const [fechaHasta, setFechaHasta] = useState(new Date());
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  useEffect(() => {
    if (addRequestStatus === "success") {
      const filtro = {
        Desde: moment(fechaDesde).format("YYYY-MM-DD"),
        Hasta: moment(fechaHasta).format("YYYY-MM-DD"),
      };

      searchResumenAsistencia(filtro);
      setAddRequestStatus("idle");
    }
  }, [addRequestStatus]);

  // ? CUSTOM-HOOK
  const {
    formState: { errors },
    control,
  } = useForm();

  // * FUNCION QUE ENVIA LOS DATOS.
  const onSubmit = () => {
    setAddRequestStatus("success");
  };

  // * FUNCION QUE LIMPIA LOS FILTROS
  const cleanFilter = () => {
    setFechaDesde(new Date());
    setFechaHasta(new Date());
    setEmpleados([]);
    setEmpleadosFiltros([]);
    setValue("");
    setCantidadEmpleados(0);
    dispatch(fetchResumenLimpiar());
  };

  // * FUNCION QUE SOLICITA EL INFORME DE RESUMEN DE ASISTENCIA.
  const descargarInforme = () => {
    exportExcel({
      Desde: moment(fechaDesde).format("YYYY-MM-DD"),
      Hasta: moment(fechaHasta).format("YYYY-MM-DD"),
    });
  };

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="row">
          <div className="col-5">
            <div className="form-group">
              <Controller
                control={control}
                className="mb-3"
                name="fecha-desde"
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat='dd/MM/yyyy'
                      label={"Desde"}
                      mask="__/__/____"
                      value={fechaDesde}
                      onChange={(e) => setFechaDesde(e)}
                      renderInput={(params) => (
                        <TextField
                          height={10}
                          className="form-control"
                          style={{ height: 10 }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
          </div>

          <div className="col-5">
            <div className="form-group">
              <Controller
                control={control}
                className="mb-3"
                name="fecha-hasta"
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat='dd/MM/yyyy'
                      label={"Hasta"}
                      mask="__/__/____"
                      value={fechaHasta}
                      onChange={(e) => setFechaHasta(e)}
                      renderInput={(params) => (
                        <TextField
                          height={10}
                          className="form-control"
                          style={{ height: 10 }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
          </div>

          <div className="col-2">
            <div className="form-group">
              <IconTextField
                id='input-search'    
                label="Buscar"
                iconEnd={<SearchIcon />}
                setValue={setValue}
                value={value}
                valorCondition={cantidadEmpleados}
              />
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="form-group">
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                id="btn-vista-previa"
                className="btn btn-primary-mem"
                onClick={() => onSubmit()}
              >
                Vista Previa <VisibilityIcon sx={{ marginTop: -0.5 }} />
              </button>
              <button
                id="btn-descargar"
                className="btn btn-primary-mem"
                onClick={() => descargarInforme()}
              >
                Descargar
                <CloudDownloadIcon
                  sx={{ marginTop: -0.5, marginLeft: 1 }}
                />{" "}
              </button>
              <button id="btn-eliminar" className="btn btn-danger" onClick={() => cleanFilter()}>
                <DeleteIcon />{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
