import { useDispatch } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@mui/material';
import React from 'react'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import {
    fetchClearEvaluacionesGenerarPeriodo
    } from "../../../redux/Evaluaciones/EvaluacionesSlice";

export const InfoGeneracionPeriodoModal = ({open, setOpen, listaMensajesGeneracionPeriodo}) => {

    const dispatch = useDispatch();

    const clear = () => {
        dispatch(fetchClearEvaluacionesGenerarPeriodo());
        setOpen(false);
    }

  return (
    <Dialog 
    PaperProps={{ sx: { width: "50%", height: "70%" } }}
    open={open}
    maxWidth='lg'
    >
        <DialogTitle sx={{textTransform: 'uppercase'}} className='text-mem text-center'>Informaciones</DialogTitle>
        <Divider />

        <DialogContent>

        <Stack sx={{ width: '100%' }} spacing={2}>

        {
            listaMensajesGeneracionPeriodo.lstEvaluacionesNoSePuedieronCrear?.map((item, index) => 
                <Alert severity="error" key={index}>{item.msg}</Alert>
            )
        }
            
            <Alert severity="success">{listaMensajesGeneracionPeriodo.cantidadEvaluacionesCreadas}</Alert>
            <Alert severity="success">{listaMensajesGeneracionPeriodo.cantidadMinistrosYMilitares}</Alert>

        </Stack>

        </DialogContent>

        <DialogActions className='justify-content-center'>
            <button onClick={() => clear()} className='btn btn-primary-mem'>Aceptar</button>
        </DialogActions>

    </Dialog>
  )
}
