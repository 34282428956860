import React from 'react'
import { Route, Routes, Navigate } from 'react-router'
import { RequiredPermission} from '../authentication/RequiredPermission';
import { CargarNominas, VerNominas, NominaEmpleado, EliminarNomina } from '../pages/Nominas';
import { MisVolantes, VolantesPagosColaboradores } from '../pages/Control de Nóminas';

export const RutasNominas = () => {
  return (
    <Routes>

        <Route element={<RequiredPermission permission='ROLE_VER_NOMINAS'/>}> 
            <Route path="/vernominas" element={<VerNominas />} />
        </Route>

        <Route element={<RequiredPermission permission='ROLE_VER_NOMINAS'/>}> 
            <Route path="/cargarnomina" element={<CargarNominas />} />
        </Route>

        <Route element={<RequiredPermission permission='ROLE_ADMIN'/>}> 
            <Route path="/listanominas" element={<EliminarNomina />} />
        </Route>

        <Route path="/vernominas/empleado" element={<NominaEmpleado />} />

        <Route path="/control/misvolantes" element={<MisVolantes />} />

        <Route element={<RequiredPermission permission='ROLE_VER_NOMINAS_COLABORADORES'/>}> 
            <Route path="/control/colaboradores" element={<VolantesPagosColaboradores />} />
        </Route>

        <Route path='/*' element={<Navigate to="/" replace />} />

    </Routes>
  )
}
