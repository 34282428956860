import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import FormControlLabel from "@mui/material/FormControlLabel";
import InsertarImagen from '../../../Components/Modals/Empleados/InsertarImagen';
import Checkbox from "@mui/material/Checkbox";
import swal from "sweetalert";
import { useForm, useFieldArray, useWatch, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Loading from "../../../Components/Modals/Loading/Loader";
import { Box, Divider, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import  Avatar  from '@mui/material/Avatar';
import { BiSave } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import MaskedInput from "react-input-mask";
import Select from "react-select";
import CurrencyInput from "../../../Components/InputSalario/CurrencyInput";
import moment from "moment";

import { convertNumberPhoneToString } from "../../../utils/convertNumberPhoneToString";

import "react-datepicker/dist/react-datepicker.css";

import {
  fetchEstadosCiviles,
  fetchProgramas,
  fetchNacionalidades,
  selectAllEstadosCiviles,
  selectAllPogramas,
  fetchTipoEmpleados,
  selectAllTiposEmpleados,
  selectAllNacionalidades
} from "../../../redux/Utility/UtilitySlice";

import {
  fetchHorarios,
  selectAllHorarios,
} from "../../../redux/Horarios/HorarioSlice";

import {
  fetchAreasOrganizacionales,
  selectAllAreasOrganizacionales,
} from "../../../redux/AreaOrganizacional/AreaOrganizacionalSlice";

import {
  fetchCargosLista,
  selectAllCargosLista,
} from "../../../redux/Cargos/CargosSlice";

import {
  fetchGetTipoAccionesAll,
  selectListTipoAcciones,
} from "../../../redux/TipoAcciones/TipoAccionesSlice";

import {
  selectIsSuccess,
  selectMessage,
  selectIsLoading,
  fetchPostAccionReclutamiento,
  fetchCambiarSuccess,
} from "../../../redux/Acciones/AccionesSlice";

import { selectUserData } from "../../../redux/Login/LoginSlice";
import { Delete } from "@mui/icons-material";
import InsertarAjustarImagen from "../../../Components/Modals/Empleados/InsertarAjustarImagen";

export function ReclutamientoSeleccionAgregar() {

  // * FECHA ACTUAL 
  const fechaActual = new Date();

  // ? HOOK-NAVIGATE
  const navigate = useNavigate();

  // ? HOOK-SELECTOR
  const usuario = useSelector(selectUserData);

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-STATE DE LISTAS
  const [listaAreasState, setListaAreasState] = useState([]);
  const [listaCargosState, setListaCargosState] = useState([]);

  // ? HOOK-STATE PARA LA IMAGEN
  const [openInsertarImagen, setOpenInsertarImagen]= useState(false);

  // ? HOOK-SELECTOR
  const estadosCivil = useSelector(selectAllEstadosCiviles);
  const horarios = useSelector(selectAllHorarios);
  const programas = useSelector(selectAllPogramas);
  const tipoEmpleados = useSelector(selectAllTiposEmpleados);
  const areaOrganizacionales = useSelector(selectAllAreasOrganizacionales);
  const cargos = useSelector(selectAllCargosLista);
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  const tipoAcciones = useSelector(selectListTipoAcciones);
  const nacionalidades = useSelector(selectAllNacionalidades);

  // ? HOOK-EFFECT
  useEffect(() => {
      dispatch(fetchEstadosCiviles());
      dispatch(fetchHorarios());
      dispatch(fetchProgramas());
      dispatch(fetchTipoEmpleados());
      dispatch(fetchAreasOrganizacionales());
      dispatch(fetchCargosLista());
      dispatch(fetchGetTipoAccionesAll());
      dispatch(fetchNacionalidades());
  }, []);

  // ? HOOK-FORM
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      detallesAccion: '',
      descripcionRRHH: '',
      listaTipoAccion: [],
      fechaEfectividad: new Date(`${fechaActual.getFullYear()}-${fechaActual.getMonth() + 1}-1`),
      empleado: {
        nombres: '',
        apellidos: '',
        cedula: '',
        fechaNacimiento: new Date(),
        sexo: '',
        estadoCivilId: 0,
        direccion: '',
        emailPersonal: '',
        celular: '',
        telefono: '',
        fechaIngreso: new Date(),
        tipoEmpleadoId: 0,
        programaId: 0,
        horarioId: 1,
        sueldo: "0",
        emailInstitucional: '',
        areaOrganizacionalId: 0,
        cargoId: 0,
        areaReporteId: 0,
        nacionalidadId: 155
      },
      listaTipoAccion: [],
      imagenEmpleado: ''
    }
  });

  // ? HOOK-WATCH
  const empleadoAccion = useWatch({control, name: 'empleado'});

  const { fields } = useFieldArray({
    control,
    name: "listaTipoAccion",
    rules: {},
  });

  // ? HOOK-EFFECT PARA LAS ALERTAS
  useEffect(() => {
    if (success === "success" && message?.length > 0) {
      reset();
      dispatch(fetchCambiarSuccess());
      swal("Exitoso!", message, "success");
    } else if (success === "error") {
      dispatch(fetchCambiarSuccess());
      swal("Error!", message, "error");
    }
  }, [success]);
  
  // * FUNCION PARA GUARDAR EL FORMULARIO
  const onSubmit = (data, e) => {

    e.preventDefault();

    if (data.listaTipoAccion.flat().length === 0) {
      swal("Información", "Debe seleccionar al menos una naturaleza.", "error");
      return;
    }

    const dataApi = { ...{ usuarioLogeado: usuario.Id }, ...data };

    // * ELIMINANDO LOS CARÁCTERES DE LAS MÁSCARAS.
    dataApi.empleado.cedula = data.empleado.cedula.replace(/_/g, "").split("-").join("");
    dataApi.empleado.sueldo = data.empleado.sueldo.replace('$', "").split(",").join("");
    dataApi.empleado.telefono = convertNumberPhoneToString(data.empleado.telefono);
    dataApi.empleado.celular = convertNumberPhoneToString(data.empleado.celular);
    dataApi.empleado.flota = convertNumberPhoneToString(data.empleado.flota);
    dataApi.listaTipoAccion = dataApi.listaTipoAccion.flat();

    dispatch(fetchPostAccionReclutamiento(dataApi));
  };

  useEffect(() => {
   
    setListaAreasState([
      { id: 0, nombre: "Seleccionar Área" },
      ...areaOrganizacionales,
    ]);
    
    setListaCargosState([
      { id: 0, titulo: "Seleccionar Cargo" },
      ...cargos,
    ]);

  }, [areaOrganizacionales, cargos])

  // * ELIMINAR IMAGEN DEL EMPLEADO
  const deleteImageEmpleado = () => {
    swal({
      title: "Estás seguro de eliminar la imagen?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setValue('imagenEmpleado', '')
      } 
    });
  }

  return (
    <div className="container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mt-2">
          <div>
            <h3 className="text-primary-mem">NUEVO EMPLEADO</h3>
            <hr />
          </div>
        </div>

        <Paper elevation={4}>
          <div className="d-flex flex-row p-3">
            <div className="col-md-4 pe-4 border-end">

              <div className='d-flex justify-content-center'>
                <Avatar
                  src={watch('imagenEmpleado')}
                  sx={{
                      width: 150,
                      height: 150,
                      marginLeft: 1,
                      border: 'solid',
                      borderWidth: 1,
                      borderColor: '#003876',
                      borderRadius:3
                  }}
                  variant="square"
                  onClick={()=>setOpenInsertarImagen(true)}
                />
										
                <div>
                  <IconButton onClick={() => deleteImageEmpleado()} hidden={watch('imagenEmpleado').length === 0}><Delete color="error" /></IconButton>
                </div>

									
							</div>

              <div className="form-group">
                <label className="fw-bold">Nombres</label>
                <input
                  id="empleado.nombres"
                  className="form-control"
                  {...register("empleado.nombres", {
                    required: "El nombre es requerido",
                  })}
                />
                <p className="text-danger">
                  {errors.empleado?.nombres?.message}
                </p>
              </div>
              <div className="form-group">
                <label className="fw-bold">Apelidos</label>
                <input
                  id="empleado.apellidos"
                  className="form-control"
                  {...register("empleado.apellidos", {
                    required: "El apellido es requerido",
                  })}
                />
                <p className="text-danger">
                  {errors.empleado?.apellidos?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Cédula</label>

                <Controller
                name="empleado.cedula"
                control={control}
                defaultValue=""
                rules={{
                  required: "La cédula es requerida",
                  minLength: {
                    value: 13,
                    message: 'La cédula debe tener 11 dígitos'
                  }
                }}
                render={({ field }) => (
                  <MaskedInput
                    id="empleado.cedula"
                    mask="999-9999999-9"
                    maskChar=""
                    value={field.value}
                    onChange={field.onChange}
                  >
                    {(inputProps) => (
                      <input
                        className="form-control"
                        {...inputProps}
                        type="text"
                      />
                    )}
                  </MaskedInput>
                )}
                />

                <p className="text-danger">
                  {errors.empleado?.cedula?.message}
                </p>

              </div>

              <div className="form-group">
                <label className="fw-bold">Fecha Nacimiento</label>
                <Controller
                  control={control}
                  name="ReactDatepicker"
                  {...register("empleado.fechaNacimiento", {
                    required: "La fecha de nacimiento es requerida",
                  })}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        InputProps={{id: 'empleado.fechaNacimiento'}}
                        inputFormat='dd/MM/yyyy'
                        label={" "}
                        mask="__/__/____"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        renderInput={(params) => (
                          <TextField className="form-control" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />

                <p className="text-danger">
                  {errors.empleado?.fechaNacimiento?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Edad</label>
                <input disabled className="form-control" value={moment().diff(empleadoAccion?.fechaNacimiento, 'years',false)} />
              </div>

              <div className="form-group">
                <label className="fw-bold">Sexo</label>
                <select
                  id="empleado.sexo"
                  className="form-control"
                  {...register("empleado.sexo", {
                    required: "El sexo es requerido",
                  })}
                >
                  <option value={""}>Seleccionar Sexo</option>
                  <option value={"Masculino"}>Masculino</option>
                  <option value={"Femenino"}>Femenino</option>
                </select>
                <p className="text-danger">{errors.empleado?.sexo?.message}</p>
              </div>

              <div className="form-group">
                
                <label className="fw-bold">Estado Civil</label>
                <Controller
                control={control}
                name="empleado.estadoCivilId"
                rules={{
                  required: "El estado civil es requerido"
                }}
                render={({ field }) => (
                  <Select
                    id="empleado.estadoCivilId"
                    classNamePrefix="select"
                    placeholder="Seleccionar Estado Civil"
                    isSearchable={false}
                    options={estadosCivil}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => field.onChange(e.id)}
                    value={estadosCivil.find(x => x.id === field.value)}
                  />
                )}
                />

                <p className="text-danger">
                  {errors.empleado?.estadoCivilId?.message}
                </p>
              </div>

            </div>

            <div className="col-md-4 ps-4 pe-4 border-end">

              <div className="form-group">
                <label className="fw-bold">Nacionalidad</label>
                <Controller
                control={control}
                name="empleado.nacionalidadId"
                rules={{
                  required: "La nacionalidad es requerida"
                }}
                render={({ field }) => (
                  <Select
                    id="empleado.nacionalidadId"
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Seleccionar Empleado"
                    options={nacionalidades}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => field.onChange(e.id)}
                    value={nacionalidades.find(x => x.id === field.value)}
                  />
                )}
                />
              </div>

              <div className="form-group mt-3">
                <label className="fw-bold">Dirección</label>
                <textarea
                  id="empleado.direccion"
                  className="form-control"
                  {...register("empleado.direccion")}
                />
                <p className="text-danger"></p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Correo Personal</label>
                <input
                  id="empleado.emailPersonal"
                  className="form-control"
                  {...register("empleado.emailPersonal", {
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "El formato de correo no es valido",
                    },
                  })}
                />
                <p className="text-danger">
                  {errors.empleado?.emailPersonal?.message}
                </p>
              </div>

              <div className="form-group mb-3">
                <label className="fw-bold">Celular</label>
                <Controller
                control={control}
                name="empleado.celular"
                rules={
                  {
                    minLength: {
                    value: 14,
                    message: 'El celular debe tener 10 dígitos'
                  }}
                }
                render={({ field }) => (
                  <MaskedInput
                    id="empleado.celular"
                    mask="(999) 999-9999"
                    maskChar=""
                    value={field.value}
                    onChange={field.onChange}
                  >
                    {(inputProps) => (
                      <input
                        className="form-control"
                        {...inputProps}
                        type="text"
                      />
                    )}
                  </MaskedInput>
                )}
                />

                <p className="text-danger">
                  {errors.empleado?.celular?.message}
                </p>

              </div>

              <div className="form-group mb-3">
                <label className="fw-bold">Teléfono</label>
                <Controller
                control={control}
                name="empleado.telefono"
                rules={
                  {
                    minLength: {
                    value: 14,
                    message: 'El teléfono debe tener 10 dígitos'
                  }}
                }
                render={({ field }) => (
                  <MaskedInput
                    id="empleado.telefono"
                    mask="(999) 999-9999"
                    maskChar=""
                    value={field.value}
                    onChange={field.onChange}
                  >
                    {(inputProps) => (
                      <input
                        className="form-control"
                        {...inputProps}
                        type="text"
                      />
                    )}
                  </MaskedInput>
                )}
                />

                <p className="text-danger">
                  {errors.empleado?.telefono?.message}
                </p>


              </div>

              <div className="form-group">
                <label className="fw-bold">Fecha Ingreso</label>
                <Controller
                  control={control}
                  name="ReactDatepicker"
                  {...register("empleado.fechaIngreso", {
                    required: "La fecha de ingreso es requerida",
                  })}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        InputProps={{id: 'empleado.fechaIngreso'}}
                        inputFormat='dd/MM/yyyy'
                        label={" "}
                        mask="__/__/____"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        renderInput={(params) => (
                          <TextField className="form-control" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />

                <p className="text-danger mt-3">
                  {errors.empleado?.fechaIngreso?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Tipo Empleado</label>
                <Controller
                  control={control}
                  name="empleado.tipoEmpleadoId"
                  rules={
                    {
                      validate: value => value !== 0 || "El tipo de empleado es requerido"
                    }
                  }
                  render={({ field }) => (
                      <Select
                        id="empleado.tipoEmpleadoId"
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Tipo Empleado"
                        options={tipoEmpleados}
                        getOptionLabel={(option) => option.tipo}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        value={tipoEmpleados.find(x => x.id === field.value)}
                      />
                  )}
                />
               
                <p className="text-danger">
                  {errors.empleado?.tipoEmpleadoId?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Programa</label>
                <Controller
                control={control}
                name="empleado.programaId"
                {...register("empleado.programaId")}
                rules={
                  {
                    validate: value => value !== 0 || "El programa es requerido"
                  }
                }
                render={({ field }) => (
                  <Select
                    id="empleado.programaId"
                    classNamePrefix="select"
                    isSearchable={false}
                    placeholder="Seleccionar Programa"
                    options={programas}
                    getOptionLabel={(option) => option.programa}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => field.onChange(e.id)}
                    value={programas.find(x => x.id === field.value)}
                  />
                )}
                />
                <p className="text-danger">
                  {errors.empleado?.programaId?.message}
                </p>
              </div>
            </div>

            <div className="col-md-4 ps-4 pe-4">

              <div className="form-group">
                <label className="fw-bold">Horario</label>
                <Controller
                  control={control}
                  name="empleado.horarioId"
                  rules={
                    {
                      validate: value => value !== 0 || 'El horario es requerido',
                    }
                  }
                  render={({ field }) => (
                      <Select
                        id="empleado.horarioId"
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Horario"
                        options={horarios}
                        getOptionLabel={(option) => option.turnos[0]?.horarioLabel}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        value={horarios.find(x => x.id === field.value)}
                      />
                  )}
                />

                <p className="text-danger">
                  {errors.empleado?.horarioId?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Sueldo</label>

                <Controller
                name="empleado.sueldo"
                control={control}
                defaultValue=""
                rules={{
                  required: "El sueldo es requerido"
                }}
                render={({ field }) => (
                  <CurrencyInput
                    id='empleado.sueldo'
                    className="form-control"
                    placeholder="$0.00" type="text"
                    value={field.value}
                    onChange={field.onChange}
                  >
                   
                  </CurrencyInput>
                )}
                />
              
                <p className="text-danger">
                  {errors.empleado?.sueldo?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Correo Institucional</label>
                <input
                  id="empleado.emailInstitucional"
                  className="form-control"
                  {...register("empleado.emailInstitucional", {
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "El formato de correo no es valido",
                    },
                  })}
                />
                <p className="text-danger">
                  {errors.empleado?.emailInstitucional?.message}
                </p>
              </div>

              <div className="form-group mb-3">
                <label className="fw-bold">Flota</label>
                <Controller
                control={control}
                name="empleado.flota"
                rules={
                  {
                    minLength: {
                    value: 14,
                    message: 'La flota debe tener 10 dígitos'
                  }}
                }
                render={({ field }) => (
                  <MaskedInput
                    id="empleado.flota"
                    mask="(999) 999-9999"
                    maskChar=""
                    value={field.value}
                    onChange={field.onChange}
                  >
                    {(inputProps) => (
                      <input
                        className="form-control"
                        {...inputProps}
                        type="text"
                      />
                    )}
                  </MaskedInput>
                )}
                />

                <p className="text-danger mt-2">
                  {errors.empleado?.flota?.message}
                </p>

              </div>

              <div className="form-group">
                <label className="fw-bold">Área Organizacional</label>
                <Controller
                  control={control}
                  name="empleado.areaOrganizacionalId"
                  rules={
                    {
                      validate: value => value !== 0 || 'El área organizacional es requerida',
                    }
                  }
                  render={({ field }) => (
                      <Select
                        id="empleado.areaOrganizacionalId"
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Área Organizacional"
                        options={listaAreasState}
                        getOptionLabel={(option) => option.nombre}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        value={listaAreasState.find(x => x.id === field.value)}
                      />
                  )}
                />

              <p className="text-danger">
                {errors.empleado?.areaOrganizacionalId?.message}
              </p>
            </div>

              <div className="form-group">
                <label className="fw-bold">Cargo</label>
                
                  <Controller
                  control={control}
                  name="empleado.cargoId"
                  rules={
                    {
                      validate: value => value !== 0 || 'El cargo es requerido'
                    }
                  }
                  render={({ field }) => (
                    <Select
                      id="empleado.cargoId"
                      classNamePrefix="select"
                      isSearchable={true}
                      placeholder="Seleccionar Cargo"
                      options={listaCargosState}
                      getOptionLabel={(option) => option.titulo}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => field.onChange(e.id)}
                      value={listaCargosState.find(x => x.id === field.value)}
                    />
                  )}
                  />

                <p className="text-danger">
                    {errors.empleado?.cargoId?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Área de Reporte</label>

                <Controller
                    control={control}
                    name="empleado.areaReporteId"
                    rules={
                      {
                        validate: value => value !== 0 || 'El área reporte es requerido',
                      }
                    }
                    render={({ field }) => (
                      <Select
                          id="empleado.areaReporteId"
                          classNamePrefix="select"
                          isSearchable={true}
                          placeholder="Seleccionar Área Reporte"
                          options={listaAreasState}
                          getOptionLabel={(option) => option.nombre}
                          getOptionValue={(option) => option.id}
                          onChange={(e) => field.onChange(e.id)}
                          value={listaAreasState.find(x => x.id === field.value)}
                      />
                    )}
                />

                <p className="text-danger">
                    {errors.empleado?.areaReporteId?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Fecha de Efectividad</label>
                <Controller
                  control={control}
                  name="ReactDatepicker"
                  {...register("fechaEfectividad", {
                    required: "La fecha de efectividad es requerida",
                  })}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        InputProps={{id: 'fechaEfectividad'}}
                        inputFormat='dd/MM/yyyy'
                        label={" "}
                        mask="__/__/____"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        renderInput={(params) => (
                          <TextField className="form-control" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />

                <p className="text-danger mt-3">
                  {errors.fechaEfectividad?.message}
                </p>
              </div>
            </div>
          </div>
        </Paper>

        <Paper elevation={5}>
          <Stack mt={2} p={3} component={'div'} id="cuadro-naturaleza">
            <Typography textTransform="uppercase">
              Naturaleza de la acción
            </Typography>
            <Divider />

            <Grid container spacing={{ lg: 2, md: 1 }}>
              {tipoAcciones.length > 0 &&
                fields !== undefined &&
                tipoAcciones.map((item, index) => (
                  <Grid item p={2} xs={4} md={4} lg={2.4} key={item.id} mt={2}>
                    <Stack>
                      <Typography
                        key={index}
                        sx={{ fontSize: { lg: 14, md: 12, xs: 11 } }}
                        fontWeight="bold"
                        textTransform="uppercase"
                      >
                        {item.nombre}
                      </Typography>

                      {item.tiposAcciones.map((item2, index2) => (
                        <FormControlLabel
                          key={item2.id}
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox 
                              id={`tipoaccion.${item2.id}`}
                              checked={getValues("listaTipoAccion")
                                ?.flat()
                                .some((x) => x === item2.id.toString())}
                              {...register(`listaTipoAccion.${index2}`, {
                                value: [],
                              })}
                              value={item2?.id}
                            />
                          }
                          label={item2?.nombre}
                        />
                      ))}
                    </Stack>
                  </Grid>
                ))}

              <p
                style={{ marginLeft: 10, marginBottom: -2 }}
                className="text-danger"
              >
                {(watch("listaTipoAccion")?.flat()?.length === 0 && tipoAcciones.length > 0) &&
                  "Debe seleccionar al menos una naturaleza"}
              </p>
            </Grid>
          </Stack>
        </Paper>

        <Paper elevation={5}>
          <Stack mt={2} p={3}>
            <Typography fontSize={14} textTransform="uppercase">
              Detalles explicativos de la acción
            </Typography>
            <TextField
              id="detallesAccion"
              {...register("detallesAccion")}
              label=" "
              variant="outlined"
              multiline
              rows={2}
              sx={{ mb: 2 }}
              error={errors.detallesAccion}
              helperText={errors.detallesAccion?.message}
            />

            <Typography fontSize={14} textTransform="uppercase">
              Para uso de área de recursos humanos
            </Typography>
            <TextField
              id="descripcionRRHH"
              {...register("descripcionRRHH")}
              label=" "
              variant="outlined"
              multiline
              rows={2}
              error={errors.descripcionRRHH}
              helperText={errors.descripcionRRHH?.message}
            />

            <Box mt={2} display="flex" justifyContent="center">
              <button id='btn-guardar' className="btn btn-primary-mem me-2">
                Guardar <BiSave fontSize={22} />
              </button>

              <button id='btn-cancelar' className="btn btn-danger" onClick={() => navigate('/acciones/reclutamiento')}>
                Cancelar <MdCancel fontSize={22} />
              </button>
            </Box>
            
          </Stack>
        </Paper>
      </form>

      <InsertarAjustarImagen
      setValue={setValue}
      openInsertarImagen={openInsertarImagen} 
      setOpenInsertarImagen={setOpenInsertarImagen} 
      />

      <Loading open={isLoading} />
    </div>
  );
}

export default ReclutamientoSeleccionAgregar;
