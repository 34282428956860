export const meses = [
    {
        no: '01',
        noEntero: 1,
        nombre: 'Enero'
    },
    {
        no: '02',
        noEntero: 2,
        nombre: 'Febrero'
    },
    {
        no: '03',
        noEntero: 3,
        nombre: 'Marzo'
    },
    {
        no: '04',
        noEntero: 4,
        nombre: 'Abril'
    },
    {
        no: '05',
        noEntero: 5,
        nombre: 'Mayo'
    },
    {
        no: '06',
        noEntero: 6,
        nombre: 'Junio'
    },
    {
        no: '07',
        noEntero: 7,
        nombre: 'Julio'
    },
    {
        no: '08',
        noEntero: 8,
        nombre: 'Agosto'
    },
    {
        no: '09',
        noEntero: 9,
        nombre: 'Septiembre'
    },
    {
        no: '10',
        noEntero: 10,
        nombre: 'Octubre'
    },
    {
        no: '11',
        noEntero: 11,
        nombre: 'Noviembre'
    },
    {
        no: '12',
        noEntero: 12,
        nombre: 'Diciembre'
    }
]