import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Dialog, 
    DialogTitle,
    Divider, 
    DialogContent, 
    DialogActions,
    Typography
} from '@mui/material';

import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';

import { 
    fetchCancelarAccionPersonal,
    fetchCancelarAccionReclutamiento,
    selectIsSuccess,
    selectMessage
} from '../../../redux/Acciones/AccionesSlice';


export const CancelarAccionModal = ({open, setOpen, accion, usuarioId}) => {

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const message = useSelector(selectMessage);
    const isSuccess = useSelector(selectIsSuccess);

    // ? HOOK-FORM
    const {
        register,
        formState: { errors },
        control,
        handleSubmit,
        setValue,
        reset
    } = useForm({
        defaultValues: {
            accion: {
                accionId: 0,
                usuarioIdModificado: 0,
                motivo: ''
            }
        }
    });

    useEffect(() => {
      if(accion?.accionId > 0){
        setValue('accion.accionId', accion?.accionId)
        setValue('accion.usuarioIdModificado', usuarioId)
      }
      else {
        setOpen(false);
      }
    }, [accion])

    // ? HOOK-EFFECT PARA LAS ALERTAS
    useEffect(() => {
        
        if(isSuccess === 'success' && message.length > 0){
            reset();
            setOpen(false);
        }

    }, [isSuccess])

    // * FUNCION USADA EN EL BOTON CANCELAR.
    const cancelAction = () => {
        setOpen(false);
    }

      // * FUNCION PARA GUARDAR EL FORMULARIO
    const onSubmit = (data, e) => {
        e.preventDefault();

        if(accion?.categoriaId === 1){
            dispatch(fetchCancelarAccionReclutamiento(data.accion))
        }
        else if(accion?.categoriaId === 2){
            dispatch(fetchCancelarAccionPersonal(data.accion))
        }
    };
    
  return (
    <Dialog
    PaperProps={{ sx: { width: "30%", height: "30%" } }}
    open={open}
    maxWidth='lg'
    >
        <DialogTitle sx={{textTransform: 'uppercase'}} className='text-mem text-center'>Cancelar acción</DialogTitle>
        <Divider />
            
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>

                    <Typography fontWeight='bold'>Motivo</Typography>
                    <textarea 
                    id='text-area-motivo'
                    {...register("accion.motivo", {
                        required: "El campo es requerido",
                    })}  
                    style={{ width: '100%', resize: 'vertical', overflow: 'auto', maxHeight: '70%' }} 
                    className='form-control' 
                    />

                    <p className="text-danger">
                    {errors.accion?.motivo?.message}
                    </p>

                </DialogContent>

                <Divider />

                <DialogActions className='justify-content-center'>
                    <button id='btn-cancelar' type='button' onClick={() => cancelAction()} className='btn btn-danger'><DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>CANCELAR</button>
                    <button id='btn-guardar' className='btn btn-primary-mem'><SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>GUARDAR</button>
                </DialogActions>
            </form>

    </Dialog>
  )
}
