import React, { useState, useEffect } from "react";
import { FaFileExcel } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  Autocomplete,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import {
  selectAllAreasOrganizacionales,
  fetchAreasOrganizacionales,
} from "../../redux/AreaOrganizacional/AreaOrganizacionalSlice";

import {
  selectAllEmpleadosList,
  fetchAllEmpleados,
} from "../../redux/Empleados/EmpleadosSlice";

import {
  fetchTiposPermisosCategorias,
  fetchTiposPermisos,
  selectAllTiposPermisosCategorias,
  selectAllTiposPermisos
} from "../../redux/Permisos/PermisosSlice";

import {selectIsSuccess} from '../../redux/Reportes/ReportePermisosSlice';
import {onKeyPressEnterOrganizacion, onKeyPressEnterEmpleado} from '../../utils';
import moment from "moment";
import swal from "sweetalert";

export const FiltroReportesPermisos = ({onGenerateExcel, onGeneratePDF, updateSuccess}) => {

  // ? HOOK-SELECTOR
  const isSuccess = useSelector(selectIsSuccess);
  const tipospermisosCategoria = useSelector(selectAllTiposPermisosCategorias);
  const tiposPermisos = useSelector(selectAllTiposPermisos);

  // ? HOOK-STATE CAMPOS
  const [tiposPermisosCategoriaGrupo, setTiposPermisosCategoriaGrupo] = useState([]);
  const [fechaDesde, setFechaDesde] = useState(new Date());
  const [fechaHasta, setFechaHasta] = useState(new Date());
  const [areaId, setAreaId] = useState(0);
  const [empleadoId, setEmpleadoId] = useState(0);
  const [permisoId, setPermisoId] = useState(0);
  const [checked, setChecked] = useState(true);
  const [estado, setEstado] = useState(1);

  // ? STATE-STATUS
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  // ? STATE DE LISTAS.
  const [listaAreasState, setListaAreasState] = useState([]);
  const [listaEmpleadosState, setListaEmpleadosState] = useState([]);
  const [listPermisosCategoriasState, setPermisosCategoriasState] = useState([]);


  // * REDUX
  const dispatch = useDispatch();
  const listAreaOrganizacionales = useSelector(selectAllAreasOrganizacionales);
  const listEmpleados = useSelector(selectAllEmpleadosList);
  const listPermisosCategorias = useSelector(selectAllTiposPermisosCategorias);

  // ? USE-EFFECT
  useEffect(() => {
    dispatch(fetchAreasOrganizacionales());
    dispatch(fetchAllEmpleados());
    dispatch(fetchTiposPermisos());
    dispatch(fetchTiposPermisosCategorias());
  }, []);

  useEffect(() => {

    let tiposPermisosCategoriaOrder = [];
    if (tipospermisosCategoria && tipospermisosCategoria.length > 0) {
      tipospermisosCategoria.forEach((element) => {
        let arrayTipoPermiso = tiposPermisos.filter(
          (x) => x.categoriaId === element.id
        );
        let categoria = {
          label: element.nombre,
          options: arrayTipoPermiso.map((x) => ({
            label: x.nombre,
            value: x.id,
          })),
        };
        tiposPermisosCategoriaOrder.push(categoria);
      });
      setTiposPermisosCategoriaGrupo(tiposPermisosCategoriaOrder);
    }
  }, [tipospermisosCategoria, tiposPermisos]);

  useEffect(() => {
    
    if(addRequestStatus === 'success'){
      const filtro = {
        desde: moment(fechaDesde).format('YYYY-MM-DD'),
        hasta: moment(fechaHasta).format('YYYY-MM-DD'),
        areaId,
        codigoEmpleado: empleadoId,
        tipoPermiso: permisoId,
        todo: estado
      }

      onGeneratePDF(filtro);
      setAddRequestStatus('idle');
    }

  }, [addRequestStatus])

  useEffect(() => {
   
    const state = checked === true ? 1 : 0;
    setEstado(state);

  }, [checked])


  // * USE-EFFECT PARA EL SWEET-ALERT.
  useEffect(() => {
    
    if(isSuccess === 'success'){
      swal("Exitoso!", "El reporte fue generado.", "success");
      updateSuccess();
    }
    else if(isSuccess === 'error'){
      swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implementación de Sistemas.", "error");
      updateSuccess();
    }

  }, [isSuccess])
  

  // ? HOOK-EFFECT QUE AÑADE UN NUEVO VALOR A LAS LISTAS CORRESPONDIENTES.
  useEffect(() => {
    setListaAreasState([
      { id: 0, nombre: "Seleccionar Área" },
      ...listAreaOrganizacionales,
    ]);
    setListaEmpleadosState([
      { id: 0, nombreLabel: "Seleccionar Empleado" },
      ...listEmpleados,
    ]);
    setPermisosCategoriasState([
      { id: 0, nombre: "Seleccionar Permiso" },
      ...listPermisosCategorias,
    ]);
  }, [listAreaOrganizacionales, listEmpleados, listPermisosCategorias]);
  

  // ? CUSTOM-HOOK
  const {
    formState: { errors },
    control,
  } = useForm();

  // * FUNCION QUE SOLICITA EL REPORTE DE PERMISOS.
  const onClickReporte = () => {
    setAddRequestStatus("success");
  }

  // * FUNCION
  const generateExcel = () => {
    const filtro = {
      desde: moment(fechaDesde).format('YYYY-MM-DD'),
      hasta: moment(fechaHasta).format('YYYY-MM-DD'),
      areaId,
      codigoEmpleado: empleadoId,
      tipoPermiso: permisoId,
      todo: estado
    }

    onGenerateExcel(filtro);
  }

  return (
    <div className="card mb-2">
      <div className="card-body">
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <Controller
                control={control}
                className="mb-3"
                name="fecha-desde"
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={"Desde"}
                      mask="__/__/____"
                      value={fechaDesde}
                      onChange={(e) => setFechaDesde(e)}
                      renderInput={(params) => (
                        <TextField
                          height={10}
                          className="form-control"
                          style={{ height: 10 }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
          </div>

          <div className="col-3">
            <div className="form-group">
              <Controller
                control={control}
                className="mb-3"
                name="fecha-hasta"
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      minDate={fechaDesde}
                      label={"Hasta"}
                      mask="__/__/____"
                      value={fechaHasta}
                      onChange={(e) => setFechaHasta(e)}
                      renderInput={(params) => (
                        <TextField
                          height={10}
                          className="form-control"
                          style={{ height: 10 }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
            <Autocomplete
              id="select-areas"
              className="mb-2"
              disablePortal
              disableClearable
              getOptionLabel={(listaAreasState) =>
                `${listaAreasState.nombre}`
              }
              options={listaAreasState}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              defaultValue={{ id: 0, nombre: "Seleccionar Área" }}
              noOptionsText={"No encontrado"}
              renderOption={(props, listaAreasState) => (
                <Box
                  component="option"
                  {...props}
                  key={listaAreasState.id}
                  value={listaAreasState.id}
                >
                  {listaAreasState.nombre}
                </Box>
              )}
              renderInput={(params) => <TextField {...params} label={"Área"} />}
              onChange={(e) => setAreaId(parseInt(e.currentTarget.value))}
              onKeyUp={(e) =>
                onKeyPressEnterOrganizacion(e, listaAreasState, setAreaId)
              }
            />

            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-6">
            <div className="form-group">
              <Autocomplete
                id="select-empleado"
                className="mb-2"
                disablePortal
                disableClearable
                getOptionLabel={(listaEmpleadosState) =>
                  `${listaEmpleadosState.nombreLabel}`
                }
                defaultValue={
                  { nombreLabel: "Seleccionar Empleado", id: 0 } || null
                }
                options={listaEmpleadosState}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText={"No encontrado"}
                renderOption={(props, listaEmpleadosState) => (
                  <Box
                    component="option"
                    {...props}
                    key={listaEmpleadosState.id}
                    value={listaEmpleadosState.id}
                  >
                    {listaEmpleadosState.nombreLabel}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={"Empleado"} />
                )}
                onChange={(e) => setEmpleadoId(parseInt(e.currentTarget.value))}
                onKeyUp={(e) =>
                  onKeyPressEnterEmpleado(e, listaEmpleadosState, setEmpleadoId)
                }
              />
            </div>
          </div>

          <div className="col-3">
            <div className="form-group">

              <Select
                classNamePrefix="select"
                isSearchable={true}
                placeholder="Seleccionar Tipo Permiso"
                options={tiposPermisosCategoriaGrupo}
                onChange={(e) => setPermisoId(e.value)}
                defaultValue={permisoId}
              />
              
            </div>
          </div>

          <div className="col-3">
            <div className="form-group">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkbox-empleados-activos"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                  }
                  label="Sólo empleado activos"
                />
              </FormGroup>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="form-group">
            <div className="d-flex justify-content-end">
              <button style={{marginRight: 3}} id="btn-generar-reporte" className="btn btn-danger" onClick={() => onClickReporte()}>Generar PDF <FaFilePdf style={{marginTop: -3}} fontSize={20} /> </button>
              <button id="btn-generar-reporte" className="btn btn-success" onClick={() => generateExcel()}>Generar EXCEL <FaFileExcel style={{marginTop: -3}} fontSize={20} /></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
