import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { Encabezado } from '../../../Components/Encabezado/Encabezado';
import { FiltroAcciones } from '../../../Components/Acciones/FiltroAcciones';
import { IoCheckmarkDone } from "react-icons/io5";
import { FaEye } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa6";
import { CancelarAccionModal } from '../../../Components/Acciones/Modal/CancelarAccionModal';
import Loading from '../../../Components/Modals/Loading/Loader';
import swal from 'sweetalert';

import { 
  Box, 
  Paper, 
  TableContainer, 
  Table, 
  TableHead, 
  TableBody, 
  TablePagination,
  TableRow, 
  TableCell,
  IconButton,
  Typography
} from '@mui/material';


import { 
  fetchCambiarSuccess,
  fetchGenerarReporteAccionPDF,
  fetchGetAccionesPersonal,
  fetchProcesarAccionPersonal,
  selectListaAccionesPersonal,
  selectIsLoading,
  selectMessage,
  selectIsSuccess
} from '../../../redux/Acciones/AccionesSlice';

import { selectUserData } from "../../../redux/Login/LoginSlice";

export const AccionesPersonal = () => {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-NAVEGACION
  const navigate = useNavigate();

  // ? HOOK-SELECTOR
  const listAccionesPersonal = useSelector(selectListaAccionesPersonal);
  const message = useSelector(selectMessage);
  const isLoading = useSelector(selectIsLoading);
  const isSuccess = useSelector(selectIsSuccess);
  const usuario = useSelector(selectUserData);

  // ? USE-STATE PARA LA PAGINACION
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(25);
  const [ skipRow, setSkipRow ] = useState(0);
  const [listadoPaginadoAccionesPersonal, setListadoPaginadoAccionesPersonal] = useState([]);

  // ? HOOK-STATE
  const [open, setOpen] = useState(false);
  const [accion, setAccion] = useState({});
 
  // ? HOOK-FORM
  const {
    register,
    formState: { errors },
    control,
    reset
  } = useForm({
    defaultValues: {
     empleado: {
      areaId: 0,
      empleadoId: 0,
      estadoId: 2
     },
    }
  });
  
  // ? HOOK-WATCH
  const empleado = useWatch({ control, name: 'empleado'});

  // * FUNCION PARA REFRESCAR LISTA.
  const refreshList = () => dispatch(fetchGetAccionesPersonal(empleado));

  // * FUNCION PARA REFRESCAR SUCCESS
  const refreshSuccess = () => dispatch(fetchCambiarSuccess());

  // ? HOOK-EFFECT PARA RESTABLECER VALORES Y ACTUALIZAR LA LISTA.
  useEffect(() => {
    setPage(0);
    setSkipRow(0);
    setRowsPerPage(25);
    refreshList();
  }, [empleado])
  
  // ? FILTRADO DE LISTA A TRAVES DE USE-EFFECT.
  useEffect(() => {

    let inicio = rowsPerPage * page;
    let final = (rowsPerPage * page) + rowsPerPage;
    
    // * FILTRANDO LA LISTA SEGUN LOS VALORES DE LA PAGINACION Y EL RANGO.
    setListadoPaginadoAccionesPersonal(listAccionesPersonal.slice(inicio, final));

  }, [listAccionesPersonal, page, skipRow, rowsPerPage])  

  // ? HOOK-EFFECT PARA LAS ALERTAS
  useEffect(() => {
  
    if(isSuccess === 'success' && message.length > 0){
      swal({title: 'Exitoso', text: message, icon: 'success'});
      refreshList();
      refreshSuccess();

    }
    else if(isSuccess === 'error'){
      swal({title: 'Error', text: message, icon: 'error'});
      refreshSuccess();
    }

  }, [isSuccess])

  // * FUNCIONES PARA PAGINACION.
    const handleChangePage = (event, newPage) => {
    setSkipRow(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSkipRow(0);
  };

  // * FUNCION PARA GENERAR REPORTE
  const generarReportePDF = (accion) => dispatch(fetchGenerarReporteAccionPDF(accion));

    // * FUNCION PARA PROCESAR UNA ACCION
    const procesarAccion = (id) => {

      swal({
        title: 'Procesar acción',
        text: 'Estás seguro/a de procesar esta acción?',
        icon: 'warning',
        buttons: ['No', 'Si']
      }).then(respuesta => {
  
          const data = {
            accionId: id,
            usuarioIdModificado: usuario.Id
          };
  
          if(respuesta){
            dispatch(fetchProcesarAccionPersonal(data));
          }
      })
  
    }
  
    // * FUNCION PARA CANCELAR UNA ACCION
    const cancelarAccion = (accion) => {
      setOpen(true);
      setAccion(accion);
    }

  return (
    <Encabezado title='Acciones de Personal'>

      <FiltroAcciones 
      control={control}
      register={register}
      reset={reset}
      rutaAccionBotonAgregar={'/acciones/personal/agregar'}
      permisoBoton={'ROLE_PERSONAL_ACCIONES'}
      />

    <Box sx={{ mt: 3 }} maxWidth={'100%'}>
      <Paper sx={{ mb: 2 }} elevation={3}>
        <TableContainer>

          <Table >
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold'}}>
                  Fecha de Creación
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Fecha de Efectividad
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Empleado
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Código
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Área Organizacional
                </TableCell>
                <TableCell align='center' style={{fontWeight: 'bold'}}>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>

              {
                listAccionesPersonal.length === 0 ?
                  <TableCell colSpan={6} align='center'>
                    <Typography>No hay registros</Typography>
                  </TableCell>
                :
                  listadoPaginadoAccionesPersonal.map((item, index) => (
                    <TableRow key={index}>
                      
                      <TableCell>
                        {moment(item?.fechaCreacion).format('DD/MM/yyyy')}
                      </TableCell>

                      <TableCell>
                        {moment(item?.fechaEfectividad).format('DD/MM/yyyy')}
                      </TableCell>

                      <TableCell>
                        {item.empleado.nombreLabel}
                      </TableCell>
                    
                      <TableCell>
                        {item.empleado.codigoEmpleado}
                      </TableCell>
                      
                      <TableCell>
                        {item.empleado.areaOrganizacional.nombre}
                      </TableCell>

                      <TableCell align='center'>

                        {/* SI UNO DE LOS ESTADOS NO ES "PENDIENTE" SE OCULTARA LOS SIGUIENTES BOTONES. */}
                        <IconButton id='btn-procesar-accion' hidden={item.estadoId !== 2} onClick={() => procesarAccion(item.accionId)}>
                          <IoCheckmarkDone color='#005215'/>
                        </IconButton>
                        
                        <IconButton id='btn-cancelar-accion' hidden={item.estadoId !== 2}  onClick={() => cancelarAccion(item)}>
                          <MdCancel color='#BD0000' />
                        </IconButton>
                      
                        <IconButton id='btn-generar-reporte' hidden={item.estadoId === 1} onClick={() => generarReportePDF(item)}>
                          <FaFilePdf color='#BD0000' />
                        </IconButton>

                        <IconButton id='btn-ver-detalle-accion' onClick={() => navigate(`/acciones/personal/detalle/${item.accionId}`)}>
                          <FaEye color='#003876' />
                        </IconButton>

                      </TableCell>
                      
                    </TableRow>
                  ))
                }

            </TableBody>

          </Table>

        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={listAccionesPersonal.length}
          page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>

    <CancelarAccionModal 
    open={open}
    setOpen={setOpen}
    accion={accion}
    usuarioId={usuario?.Id}
    />

    <Loading open={isLoading} />

    </Encabezado>
  )
}
