export const opcionesBotonesCalificacion = [
    {
        name: '5. Siempre',
        ponderacion: 0,
    },
    {
        name: '4. Frecuentemente',
        ponderacion: 0 
    },
    {
        name: '3. Algunas veces',
        ponderacion: 0
    },
    {
        name: '2. Rara vez',
        ponderacion: 0
    },
    {
        name: '1. Nunca' ,
        ponderacion: 0
    }
  ];