import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Assignment,
  TimerOutlined,
  CalendarToday,
  Print,
} from "@material-ui/icons";

import { Avatar } from "@mui/material";
import moment from "moment";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import swal from "sweetalert";
import ClearIcon from '@mui/icons-material/Clear';


import { 
  fetchGenerarReportePermisoPDF,
  aprobarPermisoEmpleadoRRHH, 
  rechazarPermiso 
} from "../../redux/Permisos/PermisosSlice";

import { 
	selectDiasFeriadosAnoActual
} from '../../redux/DiasFeriados/DiasFeriadosSlice';

import { calcularDiasHabiles } from "../../utils/funcionesFechasPermisos";

const AprobacionesCard = ({ permiso }) => {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? USE-SELECTOR
  const diasFeriados = useSelector(selectDiasFeriadosAnoActual);

  // * DESESTRUCTURACION DE DATOS
  const {fechaInicio, fechaTermino, horaInicio, horaTermino, diaCompleto, empleado } = permiso;

  const ValidateEstatusPermiso = ({estado}) => {

    // * CLASE DEL TEXTO QUE DEFINE EL ESTADO.
    const claseTextoEstado = "col-md-2 text-end fw-bold";

    // * ESTADO-DETALLE
    const options = [
      {
        estado: 1,
        clase: `${claseTextoEstado} text-success`,
        texto: "Aprobado"
      },
      {
        estado: 2,
        clase: `${claseTextoEstado}`,
        texto: "En espera"
      },
      {
        estado: 3,
        clase: `${claseTextoEstado} text-secondary`,
        texto: "Pendiente"
      },
      {
        estado: 4,
        clase: `${claseTextoEstado} text-danger`,
        texto: "Rechazado"
      }
    
    ].find(x => x.estado === estado);
    
    return (
      <div className={options.clase ?? ''} style={options.estado === 2 ? {color: '#FB9C00'} : {}}>{options.texto ?? ''}</div>
    );
  
  };

  // * FUNCION PARA APROBAR SOLICITUD DE PERMISO POR RRHH.
  const aprobarPermisoRRHH = (permisoId) => {

    swal({
      title: "Esta seguro de aprobar este permiso?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((answer) => {
      if (answer) {
        dispatch(aprobarPermisoEmpleadoRRHH(permisoId));
      } 
    });

  };

  // * FUNCION PARA RECHAZAR UNA SOLICITUD DE PERMISO.
  const anularPermiso = (permisoId) => {

		swal({
		  title: "Esta seguro de anular este permiso?",
		  text: "Una vez anulado, no podrá modificar el permiso.",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		}).then((answer) => {
		  if (answer) {
        dispatch(rechazarPermiso(permisoId));
		  } 
    });

  };

  // * FUNCION PARA GENERAR REPORTE DE UN PERMISO APROBADO.
  const generatePDFReport = (permisoId) => dispatch(fetchGenerarReportePermisoPDF(permisoId))

  const imageUrl = useMemo(() => {
		return `${empleado?.foto}?cacheBust=${new Date().getTime()}`;
	}, [empleado?.foto]);

  return (
    <div className="row mt-2 border rounded-3 ms-1 me-1 pt-2 pb-1">
      <div className="col-1 align-self-center">
        <Avatar
          sx={{
            width: 80,
            height: 80,
            marginLeft: 0,
            border: "solid",
            borderWidth: 0.5,
            borderColor: "#003876",
          }}
          alt={`${empleado.nombres} ${empleado.apellidos} `}
          src={imageUrl}
        />
        <label className="codigoEmpleado ms-3">
          {permiso.empleado.codigoEmpleado}
        </label>
      </div>
      <div className="col-11">
        <div className="row">
          <div className="col-md-10">
            <h3>{`${empleado.nombres} ${empleado.apellidos}`}</h3>
          </div>

          <ValidateEstatusPermiso 
            estado={permiso.permisoEstadoId}
          />
        </div>
        <div className="row row-cols-2 row-cols-sx-1">
          <div className="col-4">
            <div>
              <label>{permiso.empleado.cargoLabel}</label>
            </div>
            <div>
              <label> {permiso.empleado.areaOrganizacionalLabel}</label>
            </div>
          </div>

          <div className="col-2">
            <div>
              <label>
                <Assignment /> Tipo de Permiso:
              </label>
            </div>
            <div>
              <label>
                <CalendarToday /> Fecha Inicio:
              </label>
            </div>
          </div>
          <div className="col-1">
            <div>
              <label>{permiso.tipoPermiso.nombre}</label>
            </div>
            <div>
              <label>{moment(permiso.fechaInicio).format("DD/MM/YYYY")}</label>
            </div>
          </div>

          <div className="col-2">
            <div>
              <label>
                <TimerOutlined /> Duración:
              </label>
            </div>
            <div>
              <label>
                <CalendarToday /> Fecha de Retorno:
              </label>
            </div>
          </div>
          <div className="col-1">
            <div>
              <label>
              {calcularDiasHabiles({fechaInicio, fechaTermino, diasFeriados, horaInicio, horaTermino, checkedDiaCompleto: diaCompleto})}{" "}días
              </label>
            </div>
            <div>
              <label>{moment(permiso.fechaTermino).format("DD/MM/YYYY")}</label>
            </div>
          </div>
          <div className="col-2 align-self-center">
            <div className="text-end">
             {
              permiso.permisoEstadoId === 1 && (
                <button id="btn-print" type="button" className="btn btn-primary-mem" onClick={() => generatePDFReport(permiso.id)}>
                  <Print />
                </button>
              )
             }

            {
              (permiso.permisoEstadoId === 2) | (permiso.empleado?.areaReporteId === 1 && permiso.permisoEstadoId === 3)  ? (
                <>
                  <button id="btn-aprobar-permiso" type="button" className="btn btn-success me-2" onClick={() => aprobarPermisoRRHH(permiso.id)}>
                    <DoneAllIcon />
                  </button>

                  <button id="btn-anular-permiso" type="button" className="btn btn-danger" onClick={() => anularPermiso(permiso.id)}>
                    <ClearIcon />
                  </button>

                </>  
              )
              : <></>
             }

            </div>
          </div>
        </div>
        <div className="row mt-2">
          <hr />
          <label className="text-center">{permiso.justificacion}</label>
        </div>
      </div>
    </div>
  );
};

export default AprobacionesCard;
