// * PERFILES DE USUARIO
let profileMan = require('../assets/male profile.png')
let profileMale = require('../assets/female profile.png')

// * FUNCION QUE DEVUELVE UNA FOTO DEL EMPLEADO.
export const getPhoto = async(empleado) => {

    // * DES-ESTRUCTURACION DEL OBJETO.
    const { sexo, foto } = empleado;

    if(foto.trim().length === 0){
        return sexo === 'Masculino' ? profileMan : profileMale;
    }
    else{
        return `${foto}?cacheBust=${new Date().getTime()}`;
    }
}