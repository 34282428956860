import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "../config";
import { messageFrontEnd } from "../../utils/mensajeErrorCliente";

const initialState = {
    isLoading: false,
    isSuccess: false,
    message: "",
    estadosCiviles: [],
    programas: [],
    horarios: [],
    tipoEmpleados: [],
    gruposOcupacionales: [],
    parentescos: [],
    estudiosNiveles: [],
    idiomas: [],
    nacionalidades: [],
    tipoSangre: [],
    afpPensiones: [],
    seguros: [],
    tipoFamiliares: [],
    enfermedades: [],
    habitos: [],
    listaAnosAbsentismo: [],
    estadosEmpleado: []
}

export const fetchEstadosCiviles = createAsyncThunk("utility/fetchEstadosCiviles", async ()=>{
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchEstadosCiviles);
		return resultado.data.data;
    } catch (error) {
        return error.response;
    }
})

export const fetchProgramas = createAsyncThunk("utility/fetchProgramas", async ()=>{
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchProgramas);
		return resultado.data.data;
    } catch (error) {
        return error.response;
    }
})

export const fetchTipoEmpleados = createAsyncThunk("utility/fetchTipoEmpleados", async ()=>{
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchTiposEmpleados);
		return resultado.data.data;
    } catch (error) {
        return error.response;
    }
})

export const fetchGruposOcupacionales = createAsyncThunk("utility/fetchGruposOcupacionales", async ()=>{
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchGrupoOcupacionales);
		return resultado.data.data;
    } catch (error) {
        return error;
    }
})

export const fetchParentescos = createAsyncThunk("utility/fetchParentescos", async ()=>{
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchParentescos);
		return resultado.data.data;
    } catch (error) {
        return error;
    }
})

export const fetchEstudiosNiveles = createAsyncThunk("utility/fetchEstudiosNiveles", async ()=>{
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchEstudiosNiveles);
		return resultado.data.data;
    } catch (error) {
        return error;
    }
})

export const fetchIdiomas = createAsyncThunk("utility/fetchIdiomas", async ()=>{
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchIdiomas);
		return resultado.data.data;
    } catch (error) {
        return error;
    }
})

export const fetchNacionalidades = createAsyncThunk("utility/fetchNacionalidades", async () => {
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchNacionalidades);
		return resultado.data.data;
    } catch (error) {
        return error.response;
    }
})

export const fetchTipoSangre = createAsyncThunk("utility/fetchTipoSangre", async () => {
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchTipoSangre);
		return resultado.data.data;
    } catch (error) {
        return error.response;
    }
})

export const fetchAfpPensiones = createAsyncThunk("utility/fetchAfpPensiones", async () => {
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchAfpPensiones);
		return resultado.data.data;
    } catch (error) {
        return error.response;
    }
})

export const fetchSeguros = createAsyncThunk("utility/fetchSeguros", async () => {
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchSeguros);
		return resultado.data.data;
    } catch (error) {
        return error.response;
    }
})

export const fetchTipoFamiliares = createAsyncThunk("utility/fetchTipoFamiliares", async () => {
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchTipoFamiliares);
		return resultado.data.data;
    } catch (error) {
        return error.response;
    }
})

export const fetchEnfermedades = createAsyncThunk("utility/fetchEnfermedades", async () => {
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchEnfermedades);
		return resultado.data.data;
    } catch (error) {
        return error.response;
    }
})

export const fetchHabitos = createAsyncThunk("utility/fetchHabitos", async () => {
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchHabitos);
		return resultado.data.data;
    } catch (error) {
        return error.response;
    }
})

export const fetchListaAnosAbsentismo = createAsyncThunk("utility/fetchListaAnosAbsentismo", async () => {
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchListaAnosAbsentismo);
		return resultado.data.data;
    } catch (error) {
        return error.response;
    }
})

export const fetchEstadosEmpleado = createAsyncThunk("utility/fetchEstadosEmpleado", async () => {
    try {
        var resultado = await axiosApiInstance.get(endpoints.utility.fetchEstadosEmpleado);
		return resultado.data.data;
    } catch (error) {
        return error.response;
    }
})

export const UtilitySlice = createSlice({
    name:"utility",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder.addCase(fetchEstadosCiviles.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchEstadosCiviles.fulfilled,(state,action)=>{
            state.isSuccess = true;
            state.message = action.payload.message;
            state.estadosCiviles = action.payload ?? [];
            state.isLoading = false;
        })
        .addCase(fetchEstadosCiviles.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.estadosCiviles = [];
            state.message = action.error.message;
            console.error(action.payload.message);
        })
        .addCase(fetchProgramas.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchProgramas.fulfilled,(state,action)=>{
            state.isSuccess = true;
            state.message = action.payload.message;
            state.programas = action.payload ?? [];
            state.isLoading = false;
        })
        .addCase(fetchProgramas.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.programas = [];
            state.message = action.error.message;
            console.error(action.payload.message);
        })
        .addCase(fetchTipoEmpleados.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchTipoEmpleados.fulfilled,(state,action)=>{
            state.isSuccess = true;
            state.message = action.payload.message;
            state.tipoEmpleados = action.payload ?? [];
            state.isLoading = false;
        })
        .addCase(fetchTipoEmpleados.rejected,(state,action)=>{
            state.isSuccess = false;
            state.tipoEmpleados = [];
            state.message = messageFrontEnd();
            console.error(action.payload.message);
        })
        .addCase(fetchNacionalidades.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchNacionalidades.fulfilled,(state,action)=>{
            state.isSuccess = true;
            state.message = action.payload.message;
            state.nacionalidades = action.payload ?? [];
            state.isLoading = false;
        })
        .addCase(fetchNacionalidades.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.nacionalidades = [];
            console.error(action.payload.message);
            state.message = action.error.message;
        })
        .addCase(fetchGruposOcupacionales.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchGruposOcupacionales.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.gruposOcupacionales = action.payload;
        })
        .addCase(fetchGruposOcupacionales.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message;
            state.gruposOcupacionales = [];
        })
        .addCase(fetchParentescos.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchParentescos.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.parentescos = action.payload;
        })
        .addCase(fetchParentescos.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message;
            state.parentescos = [];
        })
        .addCase(fetchEstudiosNiveles.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchEstudiosNiveles.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.estudiosNiveles = action.payload;
        })
        .addCase(fetchEstudiosNiveles.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message;
            state.estudiosNiveles = [];
        })
        .addCase(fetchIdiomas.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchIdiomas.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.idiomas = action.payload;
        })
        .addCase(fetchIdiomas.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message;
            state.idiomas = [];
        })
        .addCase(fetchTipoSangre.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchTipoSangre.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.tipoSangre = action.payload;
        })
        .addCase(fetchTipoSangre.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message;
            state.tipoSangre = [];
        })
        .addCase(fetchAfpPensiones.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchAfpPensiones.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.afpPensiones = action.payload;
        })
        .addCase(fetchAfpPensiones.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message;
            state.afpPensiones = [];
        })
        .addCase(fetchTipoFamiliares.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchTipoFamiliares.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.tipoFamiliares = action.payload;
        })
        .addCase(fetchTipoFamiliares.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message;
            state.tipoFamiliares = [];
        })
        .addCase(fetchEnfermedades.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchEnfermedades.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.enfermedades = action.payload;
        })
        .addCase(fetchEnfermedades.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message;
            state.enfermedades = [];
        })
        .addCase(fetchHabitos.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchHabitos.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.habitos = action.payload;
        })
        .addCase(fetchHabitos.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message;
            state.habitos = [];
        })
        .addCase(fetchSeguros.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchSeguros.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.seguros = action.payload;
        })
        .addCase(fetchSeguros.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message;
            state.listaAnosAbsentismo = [];
        })
        .addCase(fetchListaAnosAbsentismo.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchListaAnosAbsentismo.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.listaAnosAbsentismo = action.payload;
        })
        .addCase(fetchListaAnosAbsentismo.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message;
            state.listaAnosAbsentismo = [];
        })
        .addCase(fetchEstadosEmpleado.pending,(state,action)=>{
            state.isLoading = true;
        })
        .addCase(fetchEstadosEmpleado.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.estadosEmpleado = action.payload;
        })
        .addCase(fetchEstadosEmpleado.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message;
            state.estadosEmpleado = [];
        })

    }
})


export const selectAllEstadosCiviles = (state) => state.utility.estadosCiviles;
export const selectAllNacionalidades = (state) => state.utility.nacionalidades;
export const selectAllPogramas = (state) => state.utility.programas;
export const selectAllTiposEmpleados = (state) => state.utility.tipoEmpleados;
export const selectAllGruposOcupacionales = (state) => state.utility.gruposOcupacionales;
export const selectAllParentescos = (state) => state.utility.parentescos;
export const selectAllEstudiosNiveles = (state) => state.utility.estudiosNiveles;
export const selectAllIdiomas = (state) => state.utility.idiomas;
export const selectAllTiposPermisosCategoria = (state) => state.utility.tiposPermisosCategoria;
export const selectAllTiposSangre = (state) => state.utility.tipoSangre;
export const selectAllAFPPensiones = (state) => state.utility.afpPensiones;
export const selectAllSeguros = (state) => state.utility.seguros;
export const selectAllTipoFamiliares = (state) => state.utility.tipoFamiliares;
export const selectAllEnfermedades = (state) => state.utility.enfermedades;
export const selectAllHabitos = (state) => state.utility.habitos;
export const selectAllAnosAbsentismo = (state) => state.utility.listaAnosAbsentismo;
export const selectAllEstadosEmpleado = (state) => state.utility.estadosEmpleado;

export const getUtilityIsSuccess = (state) => state.utility.isSuccess;
export const getUtilityIsLoading = (state) => state.utility.isLoading;
export const getUtilityMessage = (state) => state.empleados.message;

export const {  } = UtilitySlice.actions;
export default UtilitySlice.reducer;