import React, { useEffect } from "react";
import { Typography, Grid, Stack } from "@mui/material";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { useSelector } from "react-redux";
import { selectLogin } from "../../redux/Login/LoginSlice";
import { useNavigate } from "react-router";

export const NoAutorizado = () => {
  const usuario = useSelector(selectLogin);
  const navigate = useNavigate();
  const from = "/login";

  // ? HOOK-EFFECT QUE EVALUA LA EXISTENCIA DEL EMPLEADO, SI NO EXISTE 
  // ? LO ENVIARA AL LOGIN.
  useEffect(() => {
    if (usuario.nombre === null) {
      navigate(from, { replace: true });
    }
  }, [usuario]);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <Stack alignItems="center">
            <NoAccountsIcon
              sx={{ fontSize: "10vw", marginBottom: 2, color: "#003876" }}
            />
            <Typography variant="h3" sx={{ color: "#003876", marginBottom: 2 }}>
              Personal no autorizado
            </Typography>

            <Typography variant="h5">
              Para más información, consulte con el departamento de tecnología.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
