import React from 'react';
import Modal from '@mui/material/Modal';
import './style.css';

const Loading = ({ open, setOpen }) => {
   
	return (
		<Modal
			open={open}
		>
			<div>
				<span className="loader"></span>	
			</div>
		</Modal>
	);
};

export default Loading;
