import React, { useEffect } from 'react'
import { Encabezado } from '../../Components/Encabezado/Encabezado'
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FaFileExcel } from "react-icons/fa6";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import Loading from "../../Components/Modals/Loading/Loader";
import swal from 'sweetalert';

import { 
  fetchGenerarReporteHistoricoCafeteria,
  fetchCambiarSuccess,
  selectIsLoading,
  selectIsSuccess,
  selectMessage
} from '../../redux/Cafeteria/CafeteriaSlice';

export const ReporteHistorico = () => {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch()

  // ? HOOK-SELECTOR
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);

  // ? HOOK FORM
  const {
  handleSubmit,
  formState: { errors },
  watch,
  register,
  getValues,
  control,
  reset
  } = 
  useForm(
    {
      defaultValues: {
        fechaDesde: new Date(),
        fechaHasta: new Date()
    }
  });

  // * FUNCION PARA GUARDAR DATA DESDE EL FORMULARIO.
  const onSubmit = (data) => {
    dispatch(fetchGenerarReporteHistoricoCafeteria(data))
  };

  // * LIMPIAR INPUTS
  const resetAll = () => {
    reset();
    dispatch(fetchCambiarSuccess());
  }

  // * SWEET-ALERT
  useEffect(() => {
  
    if(success === 'success' && message.length > 0){
      swal("Exitoso!", message, "success");
      resetAll();
    }
    else if(success === 'error'){
      swal("Error", message, "error");
      dispatch(fetchCambiarSuccess());
    }

  }, [success])

  return (
   <Encabezado title='Reporte Histórico Cafetería'>

      <div className="card mb-4" >
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-3">
                <div className="form-group">

                  <label className="fw-bold">Fecha Desde</label>
                  <Controller
                  rules={{
                    required: true,
                    validate: (fecha) => fecha <= watch('fechaHasta') ? true : false
                  }}
                    control={control}
                    className="mb-3"
                    name="fechaDesde"
                    render={({ field: { onChange, value } }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={value}
                          maxDate={watch('fechaHasta')}
                          onChange={onChange}
                          label={" "}
                          mask="__/__/____"
                          renderInput={(params) => (
                            <TextField
                              height={10}
                              className="form-control"
                              style={{ height: 10 }}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                
                </div>

                {errors.fechaDesde && errors.fechaDesde.type === "validate" && <div className='text-danger mt-3'>Este campo no puede ser mayor que la fecha final.</div> }

              </div>

              <div className='col-3'>

                <label className="fw-bold">Fecha Hasta</label>
                    <Controller
                      rules={{
                        required: true,
                        validate: (fecha) => fecha >= watch('fechaDesde') ? true : false
                      }}
                      control={control}
                      className="mb-3"
                      name="fechaHasta"
                      render={({ field: { onChange, value } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            minDate={watch('fechaDesde')}
                            value={value}
                            onChange={onChange}
                            label={" "}
                            mask="__/__/____"
                            renderInput={(params) => (
                              <TextField
                                height={10}
                                className="form-control"
                                style={{ height: 10 }}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                
                
                  {errors.fechaHasta && errors.fechaHasta.type === "validate" && <div className='text-danger mt-3'>Este campo no puede ser menor que la fecha inicial.</div> }

              </div>

              <div className="col">
                  <div className='d-flex justify-content-end mt-4'>
                    <div className="form-group">
                      <button className='btn btn-success' type='submit'>Generar <FaFileExcel fontSize={22} style={{marginTop: -1.5}}/></button>
                    </div>
                  </div>
              </div>

            </div>
          </form>
        </div>
      </div>

      <Loading open={isLoading} />

   </Encabezado>
  )
}
