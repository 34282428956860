import React from 'react';
import { useDispatch } from 'react-redux';

import { 
    Paper, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    Divider, 
    DialogTitle,
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Typography,
    IconButton
  } from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { FaFilePdf } from "react-icons/fa6";
import { IoMdCloudUpload } from "react-icons/io";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import swal from 'sweetalert';

import { fetchDeleteResultadosValoresFiles } from '../../../redux/Evaluaciones/EvaluacionesSlice'

export const VerListaArchivosModal = ({estado, cargaArchivosEnabled, setCargaArchivosEnabled, setUploadFilesPDF, setPdfViewEnabled}) => {

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // * DESESTRUCTURACION DE DATOS.
    const { files, logroMeta } = cargaArchivosEnabled;

    // * FUNCION PARA ABRIR EL MODAL DEL PDF
    const openPDFView = (item) => setPdfViewEnabled({enabled: true, file: {link: item.file, nombre: item.nombreArchivo }});

    // * FUNCION PARA ELIMINAR UN ARCHIVO DE EVIDENCIA
    const deleteEvidencia = (item) => {
        swal({
            title: `Estás seguro de eliminar la evidencia ${item.nombreArchivo}?`,
            text: "Una vez eliminado el archivo, no será posible recuperarlo.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((answer) => {
            if (answer) {
                dispatch(fetchDeleteResultadosValoresFiles(item.id));
            } 
      });
    } 

    // * FUNCION PARA CARGAR ARCHIVOS
    const openFilesUpload = () => {

        if(files.length === 5){
            swal("Error", "Has alcanzado el límite máximo de evidencias permitidas (5).", "error");
            return;
        }

        setUploadFilesPDF(true)
    }

  return (
    
      <Dialog 
      PaperProps={{ sx: { width: "50%", height: "60%" } }}
      open={cargaArchivosEnabled.enabled}
      maxWidth='lg'
      >
        <DialogTitle sx={{textTransform: 'uppercase'}} className='text-mem text-center'>Evidencias de logro de meta: <br/> <b>{logroMeta}</b></DialogTitle>
        <Divider />

          <DialogContent>
            <Paper sx={{ mb: 2 }} elevation={3}>
                <TableContainer>

                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell width="75%" sx={{fontWeight: 'bold'}}>
                                    Archivo
                                </TableCell>

                                <TableCell align='right'>
                                    <button hidden={estado === 2} className='btn btn-primary-mem' onClick={() => openFilesUpload()}>Cargar <IoMdCloudUpload style={{marginBottom: 1}} fontSize={22} className='ms-1' /></button>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                files.length > 0 ?
                                    files.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {item.nombreArchivo}
                                            </TableCell>
                                            <TableCell className='d-flex justify-content-end'>
                                                <IconButton onClick={() => openPDFView(item)} style={{color: '#C72924'}}>
                                                    <FaFilePdf />
                                                </IconButton>
                                                <IconButton hidden={estado === 2} onClick={() => deleteEvidencia(item)} style={{color: '#C72924'}}>
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                :
                                <TableCell colSpan={6} align='center'>
                                    <Typography>No hay registros</Typography>
                                </TableCell>
                            }
                        </TableBody>

                    </Table>

                </TableContainer>
            </Paper>
          </DialogContent>

          <DialogActions className='justify-content-center'>
              <button onClick={() => setCargaArchivosEnabled({...cargaArchivosEnabled, enabled: false})} className='btn btn-primary'><ArrowBackIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>Volver</button>
          </DialogActions>

      </Dialog>
  )
}
