import React from 'react'

export const EvaluadoDetalle = ({empleado}) => {

  return (
    <div className="card mb-4 text-center">
    <div
      className="card-header fw-bold"
      style={{ backgroundColor: "#e2edfc" }}
    >
      <h6 className='text-center fw-bold' style={{fontSize: 14}}>DETALLES DE LA EVALUACION</h6>
    </div>
    <div className="card-body">
      <div className="row">
        <div className="col-2">
         
            <div className='text-12'>
                <p className="fw-bold text-12">PERÍODO A EVALUAR</p>
                <p>{empleado.periodo}</p>

            </div>
             
        </div>
        
        <div className="col-2">
         
            <div className='text-12'>
              <p className="fw-bold">SERVIDOR/RA</p>
              <p>{empleado.nombreDelServidor}</p>
            </div>
          
        </div>
        <div className="col-2">
         
            <div className='text-12'>
              <p className="fw-bold">CARGO ACTUAL</p>
              <p>{empleado.cargoActualdelEvaluado}</p>
            </div>
          
        </div>
        <div className="col-2">
          
            <div className='text-12'>
              <p className="fw-bold">UNIDAD ORGANIZATIVA</p>
              <p>{empleado.unidadOrganizativa}</p>
            </div>
         
        </div>
        <div className="col-2">
        
            <div className='text-12'>
              <p className="fw-bold">SUPERVISOR INMEDIATO</p>
              <p>{empleado.nombredelSupervisor}</p>
            </div>
          
        </div>
        <div className="col-2">
          
            <div className='text-12'>
              <p className="fw-bold">CARGO ACTUAL</p>
              <p>{empleado.cargoActualdelSupervisor}</p>
            </div>

        </div>
      </div>
    </div>
  </div>
  )
}
