
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import SaveIcon from '@mui/icons-material/Save';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { FaAngleDoubleRight } from 'react-icons/fa';
import React, { useState, useEffect } from 'react'
import Cropper from './Cropper';

import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    Divider, 
    Typography, 
    DialogTitle,
    Paper,
    Box,
    Stack
} from '@mui/material';

import { saveImagenEmpleado } from '../../../redux/Empleados/EmpleadosSlice';

export const ActualizarAjustarImagen = ({openActualizarImagen : open, setOpenActualizarImagen, setAddRequestStatus, empleadoId}) => {

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? STATE PARA GUARDAR LA IMAGEN.
    const [file, setSelectedFile] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);

    useEffect(() => {
        setSelectedFile(null);
    }, [open])
    
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleClose = () => {
        setOpenActualizarImagen(false);
    }
  
    const handleImageUpload = (event) => {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => setSelectedFile(reader.result);
        reader.readAsDataURL(file);
      }
    };

    const onSaveImage = async() => {
        try {

            if(croppedImage === null){
                swal("Error", "La imagen es requerida.", "error");
                return;
            }

            let image = {
                empleadoId: empleadoId,
                file: croppedImage
            }


            // * CAMBIAR EL ESTATUS
            setAddRequestStatus('success');

            // * GUARDAR LA IMAGEN.
            dispatch(saveImagenEmpleado(image));
            handleClose();

        } catch (error) {
            setAddRequestStatus('error');
        }
        
    }

    return (
        <Dialog
        PaperProps={{ sx: { width: "50%", height: "70%" } }}
        open={open}
        maxWidth='lg'
        >
            <DialogTitle align='center'>INSERTAR IMAGEN</DialogTitle>
            <Divider />
                <DialogContent>
                    <div className='container'>
                        <div className='mt-2 mb-4'>
                            <Typography fontSize={14} fontWeight='bold'>Adjuntar foto</Typography>
                            <input className="form-control" type="file" accept="image/*" onChange={handleImageUpload} />
                        </div>
                        
                        {file && (
                            <Paper elevation={2}>
                                <Box padding={3} className='row d-flex justify-content-center'>
                                <div className='col-4'>
                                    <>
                                        <Typography align='center' fontSize={18} marginBottom={1} fontWeight='bold'>Imagen</Typography>              
                                        <Cropper
                                        imageToCrop={file}
                                        onCropComplete={(croppedImage) => setCroppedImage(croppedImage)}
                                        />
                                    
                                    </>
                                </div>

                                <div className='col-2 d-flex justify-content-center align-items-center'>
                                    <FaAngleDoubleRight fontSize={60} />
                                </div>
                                
                                <Stack className='col-4'>
                                    {croppedImage && (
                                        <>
                                            <Typography align='center' fontSize={18} marginBottom={1} fontWeight='bold' variant='h6'>
                                                Vista Previa
                                            </Typography>
                                            <img
                                                src={croppedImage}
                                                style={{
                                                    maxWidth: '17vw',     
                                                    maxHeight: '17vw',     
                                                    width: 'auto',         
                                                    height: 'auto',       
                                                    objectFit: 'contain',
                                                }}
                                                alt="Cropped preview"
                                            />
                                        </>
                                    )}
                                </Stack>
                                                                    
                                </Box>
                            </Paper>
                        )}

                </div>
                                                        
                </DialogContent>

                <DialogActions className='justify-content-center'>
                    <button onClick={() => setOpenActualizarImagen(false)} className='btn btn-danger'><DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>CANCELAR</button>
                    <button disabled={file === null} onClick={() => onSaveImage()} className='btn btn-primary-mem'><SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>GUARDAR</button>
                </DialogActions>

        </Dialog>
    )
}

export default ActualizarAjustarImagen