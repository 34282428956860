import React from 'react';
import { useDispatch } from 'react-redux';
import { Viewer, Worker  } from '@react-pdf-viewer/core';
import CloseIcon from '@mui/icons-material/Close';

import {
  Dialog,
  DialogContent,
  DialogActions
} from '@mui/material';

import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { printPlugin } from '@react-pdf-viewer/print';

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export const ViewPDFGlobal = ({open = false, dataReporte, setOpen}) => {

  // * INSTANCIA PARA DESCARGAR EL PDF Y ASIGNAR UN NOMBRE POR DEFECTO.
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file) => {
      return dataReporte?.nombre;
    },
  });

  // * INSTANCIA PARA IMPRIMIR LA PAGINA.
  const printPluginInstance = printPlugin();

  // * BOTONES.
  const { DownloadButton } = getFilePluginInstance;
  const { PrintButton } = printPluginInstance;

  // * FUNCION PARA CERRAR EL MODAL.
  const cancelAction = () => {
    setOpen({enabled: false, file: {}});
  }

  return (
    
    <Dialog
    PaperProps={{ sx: { width: "50%", height: "90%" } }}
    open={open}
    maxWidth='lg'
    >

      <DialogContent>

        <div
          className="rpv-core__viewer"
          style={{
              border: '1px solid rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
          }}
          >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    padding: '4px',
                }}
            >
                <DownloadButton />
                <PrintButton />
            </div>
            
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            >
              {
                Object.keys(dataReporte).length > 0 &&

                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer fileUrl={dataReporte?.link} plugins={[getFilePluginInstance, printPluginInstance]} />
                </Worker>
              }
            </div>
          </div>

      </DialogContent>

      <DialogActions className='justify-content-center'>
        <button onClick={() => cancelAction()} className='btn btn-danger'><CloseIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>SALIR</button>
      </DialogActions>

    </Dialog>

  )
}
