import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "../config";

const initialState = {
    isLoading: false,
    message: "",
    isSuccess: 'idle',
    empleadosRifa: [],
    empleado: {},
    updateList: false,
    isSearch: false
}

// * LIMPIAR SUCCESS
export const fetchCambiarSuccess = createAsyncThunk("cafeteria/fetchCambiarSuccess",
    async () => {
        return null;
    }
);

export const fetchGetEmpleadosSorteo = createAsyncThunk("sorteo/fetchGetEmpleadosSorteo",

    async () => {

        let resultado = await axiosApiInstance.get(endpoints.sorteo.getEmpleados);
        return resultado.data;

    }
);

export const fetchGetEmpleadoById = createAsyncThunk("sorteo/fetchGetEmpleadoById",

    async (data) => {

        const { numeroEmpleado, cedula } = data;
        let resultado = await axiosApiInstance.get(`${endpoints.sorteo.getEmpleadoById}/?codigoEmpleado=${numeroEmpleado}&cedula=${cedula}`);

        return resultado.data;
    }
);

export const fetchAgregarEmpleadoRifa = createAsyncThunk("sorteo/fetchAgregarEmpleadoRifa",
    async (empleadoId) => {
        var resultado = await axiosApiInstance.post(`${endpoints.sorteo.saveEmpleadoRifa}/${empleadoId}`);
        return resultado.data;
    }
);

export const fetchSaveGanador = createAsyncThunk("sorteo/fetchSaveGanador",
    async (empleadoId) => {
        var resultado = await axiosApiInstance.post(`${endpoints.sorteo.saveGanador}/${empleadoId}`);
        return resultado.data;
    }
);

// * LIMPIAR EL STATE.
export const fetchLimpiarStateEmpleado = createAsyncThunk("sorteo/fetchLimpiarStateEmpleado",
    async () => {
      return null;
    }
); 

// * ACTUALIZAR EL ESTADO DE LA PROPIEDAD UPDATE LIST.
export const fetchUpdateList = createAsyncThunk("sorteo/fetchUpdateList",
    async () => {
      return null;
    }
); 

// * ACTUALIZAR EL ESTADO DE LA PROPIEDAD UPDATE LIST.
export const fetchClearIsSearch = createAsyncThunk("sorteo/fetchClearIsSearch",
    async () => {
      return null;
    }
); 

const SorteoSlice = createSlice({
    name: "sorteo",
    initialState,
    extraReducers(builder) {
        builder
            .addCase(fetchCambiarSuccess.fulfilled, (state) => {
                state.isSuccess = "idle";
                state.message = '';
            })
            .addCase(fetchLimpiarStateEmpleado.fulfilled, (state) => {
                state.empleado = {};
            })
            .addCase(fetchUpdateList.fulfilled, (state) => {
                state.updateList = false;
            })
            .addCase(fetchClearIsSearch.fulfilled, (state) => {
                state.isSearch = false;
            })
            .addCase(fetchGetEmpleadosSorteo.pending, (state, action) => {
                state.isLoading = true;
                state.message = "";
                state.isSuccess = 'idle';
            })
            .addCase(fetchGetEmpleadosSorteo.fulfilled, (state, action) => {

                if (action.payload.isSuccess) {
                    state.empleadosRifa = action.payload.data ?? [];
                    state.isSuccess = 'success';
                }
                else {
                    state.isSuccess = 'error';
                    state.tipoAcciones = [];
                    state.message = action.payload.message;
                }

                state.isLoading = false;
            })
            .addCase(fetchGetEmpleadosSorteo.rejected, (state, action) => {
                state.isLoading = false;
                state.message = action.error.message;
                state.isSuccess = 'error'
                state.tipoAcciones = [];
            })
            .addCase(fetchGetEmpleadoById.pending, (state, action) => {
                state.isLoading = true;
                state.isSearch = false;
                state.message = "";
                state.isSuccess = 'idle';
            })
            .addCase(fetchGetEmpleadoById.fulfilled, (state, action) => {

                if (action.payload.isSuccess) {
                    state.empleado = action.payload.data;
                    state.isSuccess = 'success';
                }
                else {
                    state.isSuccess = 'error';
                    state.empleado = {};
                    state.message = action.payload.message;
                }

                state.isSearch = true;
                state.isLoading = false;
            })
            .addCase(fetchGetEmpleadoById.rejected, (state, action) => {
                state.isLoading = false;
                state.message = action.error.message;
                state.isSuccess = 'error'
                state.tipoAcciones = [];
            })
            .addCase(fetchAgregarEmpleadoRifa.pending, (state, action) => {
                state.isLoading = true;
                state.isSuccess = 'idle';
            })
            .addCase(fetchAgregarEmpleadoRifa.fulfilled, (state, action) => {

                if (action.payload.isSuccess) {
                    state.isSuccess = 'success';
                } else {
                    state.isSuccess = 'error';
                }
                state.message = action.payload.message;
                state.isLoading = false;
            })
            .addCase(fetchAgregarEmpleadoRifa.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = 'error';
                state.message = action.error.message;
            })
            .addCase(fetchSaveGanador.pending, (state, action) => {
                state.isLoading = true;
                state.isSuccess = 'idle';
            })
            .addCase(fetchSaveGanador.fulfilled, (state, action) => {

                if (action.payload.isSuccess) {
                    state.isSuccess = 'success';
                    state.updateList = true;
                } else {
                    state.isSuccess = 'error';
                }
                state.message = action.payload.message;
                state.isLoading = false;
            })
            .addCase(fetchSaveGanador.rejected, (state, action) => {
                state.isLoading = false;
                state.isSuccess = 'error';
                state.message = action.error.message;
            })

            ;
    }
})

export const selectListEmpleadosRifa = (state) => state.sorteo.empleadosRifa;
export const selectUpdateList = (state) => state.sorteo.updateList;
export const selectEmpleado = (state) => state.sorteo.empleado;
export const selectIsSuccess = state => state.sorteo.isSuccess;
export const selectIsloading = state => state.sorteo.isLoading;
export const selectIsSearch = state => state.sorteo.isSearch;
export const selectMessage = state => state.sorteo.message;


export const { } = SorteoSlice.actions;
export default SorteoSlice.reducer;