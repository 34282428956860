import React, { useMemo } from 'react';
import { Avatar } from "@mui/material";

export const CafeteríaCardEmpleado = ({data}) => {

    const sueldoRango = data?.sueldo <= 50000 ? true : false;

    const imageUrl = useMemo(() => {
		return data?.foto;
	}, [data?.foto]);

  return (
    <div className="row mt-2 border rounded-3 ms-1 me-1 pt-2">

        <div className="col-1 align-self-center">
            <Avatar
                sx={{
                width: 80,
                height: 80,
                marginLeft: 0,
                border: "solid",
                borderWidth: 0.5,
                borderColor: "#003876",
                }}
                alt={`${data?.nombreLabel} `}
                src={imageUrl}
            />
            <label className="codigoEmpleado ms-3">
                {data?.codigoEmpleado}
            </label>
        </div>

        <div className="col">
        
            <div>
                <h3 className='mt-3'>{data?.nombreLabel}</h3>
                <p style={{marginBottom: 0}}>{data?.cargoLabel}</p>
                <p>{data?.areaOrganizacional?.nombre}</p>
            </div>
        
            <div className="row mt-4">
                <hr />
               
               <div className='input-group'>
                    <label style={{minWidth: '10%'}} className='text-14 me-1 p-2 fw-bold'>Disponibilidad de Almuerzo:</label>
                    
                    {
                        sueldoRango && !data?.seleccionado ?
                            <p className="text-light bg-success text-center p-2 fw-bold" style={{minWidth: '50%', fontSize: 18}}>Aplica a un 100%</p>
                        :

                        data?.seleccionado ? <p className="text-light bg-danger text-center p-2" style={{minWidth: '50%'}}>La comida fue entregada</p>
                                            : <p className="text-light bg-primary-mem text-center p-2 fw-bold" style={{minWidth: '50%', fontSize: 18}}>Aplica a un 25% y pagará RD${data?.precioComida}.00</p>

                    }

               </div>
               
            </div>

        </div>        

    </div>
  )
}
