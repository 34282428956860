import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Add } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import TablePagination from '@mui/material/TablePagination';
import {
	fetchPermisosAll,
	fetchCountPermisos,
	fetchCambiarSuccess,
	selectPermisos,
	selectPermisosCount,
	selectIsLoading,
	selectIsSuccess,
	selectMessage
} from './../../../redux/Permisos/PermisosSlice';

import { 
	fetchListDiasFeriadosAnoActual
} from '../../../redux/DiasFeriados/DiasFeriadosSlice';

import Loading from '../../../Components/Modals/Loading/Loader';
import Permisos from '../../../Components/AccordionPermisos/Permisos';
import PermisoNuevo from "./../../../Components/Modals/Permiso/PermisoNuevo";
import swal from 'sweetalert';
import { selectUserData } from "../../../redux/Login/LoginSlice";

const colors = {
	amarillo: '#FCFF23',
	gris: '#CFCFCF',
	verde: '#00C232',
	rojo: '#FF0C00'
};

export const DigitarPermiso = () => {

	// ? USE-FORM
	const form = useForm({
	defaultValues: {
		empleadoId: 0,
		tipoPermisoId: 0,
		dias: 0,
		fechaInicio: new Date(),
		fechaTermino: new Date(),
		recurrencia: {
		recurrente: false,
		l: false,
		m: false,
		x: false,
		j: false,
		v: false,
		s: false,
		d: false
		},
		diaCompleto: false,
		justificacion: ''
	}
	});

	// * DESESTRUCTURACION DEL USE-FORM
	const { reset, setValue } = form;

	// ? HOOK SELECTOR
	const usuario = useSelector(selectUserData);

	const dispatch = useDispatch();
	const isLoading = useSelector(selectIsLoading);
	const permisos = useSelector(selectPermisos);
	const permisosCount = useSelector(selectPermisosCount);
	const message = useSelector(selectMessage);
	const isSuccess = useSelector(selectIsSuccess);

	// ? HOOK-STATE PARA PAGINACION.
    const [open,setOpen] = useState(false);
	const [ page, setPage ] = useState(0);
	const [ rowsPerPage, setRowsPerPage ] = useState(25);
	const [ skipRow, setSkipRow ] = useState(0);
	const [ aprobacionesFilterState, setAprobacionesFilterState ] = useState({});

	// * FUNCION PARA CARGAR PERMISOS
	const cargarPermisos = (aprobacionesFilter) => {
		dispatch(fetchPermisosAll({aprobacionesFilter, skipRow, rowsPerPage}));
		dispatch(fetchCountPermisos(aprobacionesFilter));
		dispatch(fetchListDiasFeriadosAnoActual());
	}

	// * FUNCIONES PARA PAGINACION
	// *
	const handleChangePage = (event, newPage) => {
		setSkipRow(newPage * rowsPerPage);
		setPage(newPage);
	};

	// *
	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setSkipRow(0);
	};


	// ? HOOK-EFFECT PARA MOSTRAR LOS MENSAJES Y RESETEAR LOS INPUTS.
    useEffect(() => {

        if(isSuccess === 'success' && message.length > 0){
		  cargarPermisos(aprobacionesFilterState);
          swal("Exitoso!", message, "success");
          dispatch(fetchCambiarSuccess());
		  setOpen(false);
		  reset();
		  resetTimer();
        }
        else if(isSuccess === 'error'){
          swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implementación de Sistemas.", "error");
          dispatch(fetchCambiarSuccess());
        }
    
    }, [isSuccess])


	// ? HOOK-EFFECT PARA FILTRACIONES.
	useEffect(() => {

		const aprobacionesFilter = {
			empleado: '',
			estadoPermiso: 0,
			estadoEmpleado: 1,
			areaOrganizacional: 0,
			empleadoId: 0
		}; 

		// * ASIGNACION DE EMPLEADO-ID.
		aprobacionesFilter.empleadoId = usuario?.Id ?? 0;

		setAprobacionesFilterState(aprobacionesFilter);
		cargarPermisos(aprobacionesFilter);

	}, [usuario, skipRow, rowsPerPage])
	
	// * FUNCION PARA RESETEAR EL TIEMPO
	const resetTimer = () => {
		let date = new Date();
		setValue(
		  "horaInicio",
		  moment(
			new Date(date.getFullYear(), date.getMonth(), date.getDay(), 8, 0, 0, 0)
		  ).format("yyyy-MM-DD HH:mm:ss")
		);
		setValue(
		  "horaTermino",
		  moment(
			new Date(
			  date.getFullYear(),
			  date.getMonth(),
			  date.getDay(),
			  16,
			  0,
			  0,
			  0
			)
		  ).format("yyyy-MM-DD HH:mm:ss")
		);
	};

	return (
		<div className="container-fluid main">
			<div className="row mt-2">
				<div className="col-md-12">
					<div>
						<h3 className="text-primary-mem">Permisos</h3>
						<hr />
					</div>
					<div className="col-md-12 bg-primary-mem text-center pb-1">
						<h3 className="text-white">Solicitudes Personalizadas</h3>
					</div>
					<div className="row mt-2  ">
						<div className="col-md-2 ">
							<div className="input-group">
								<button id='btn-nuevo-permiso' type="button" className="input-group-text btn-primary-mem" onClick={()=>setOpen(true)} >
									Nuevo Permiso <Add />
								</button>
							</div>
						</div>
						<div className="col-md-6  border pt-2 pb-1 d-flex  justify-content-center">
							<div className="row">
								<div className="col>">
									<div className="input-group ">
										<div
											style={{
												backgroundColor: colors.gris,
												border: '1px solid gray',
												borderRadius: 10,
												marginRight: 5,
												marginLeft: 5,
												height: 20,
												width: 20
											}}
										/>
										<label>Pendiente</label>
										<div
											style={{
												backgroundColor: colors.amarillo,
												border: '1px solid gray',
												borderRadius: 10,
												marginRight: 5,
												marginLeft: 5,
												height: 20,
												width: 20
											}}
										/>
										<label>En espera</label>
										<div
											style={{
												backgroundColor: colors.verde,
												border: '1px solid gray',
												borderRadius: 10,
												marginRight: 5,
												marginLeft: 5,
												height: 20,
												width: 20
											}}
										/>
										<label>Aprobado</label>
										<div
											style={{
												backgroundColor: colors.rojo,
												border: '1px solid gray',
												borderRadius: 10,
												marginRight: 5,
												marginLeft: 5,
												height: 20,
												width: 20
											}}
										/>
										<label>Rechazado</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					{permisos &&
						permisos.length > 0 &&
						permisos.map((permiso) => <Permisos permiso={permiso} buttonActive = {false} />)
					}
						<TablePagination
							component="div"
							count={permisosCount}
							page={page}
							onPageChange={handleChangePage}
							rowsPerPage={rowsPerPage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>

					<Loading open={isLoading} />

                    <PermisoNuevo 
					form={form}
					open={open} 
					setOpen={setOpen} 
					resetTimer={resetTimer}
					/>
				</div>
			</div>
		</div>
	);
};

export default DigitarPermiso;
