import "./Empleados.scss";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from '@mui/material/Pagination';
import { EmpleadosAccordion } from "../../Components/AccordionEmpleados/Empleados";
import Loading from "../../Components/Modals/Loading/Loader";
import { BarraDeBusquedaMemo } from "../../Components/Empleado/BarraDeBusqueda";
import { Encabezado } from "../../Components/Encabezado/Encabezado";

import {
  fetchEmpleados,
  selectEmpleadosIsLoading,
  selectAllEmpleados,
  fetchEmpleadosCount,
  selectEmpleadosCount,
  desvincularEmpleado,
} from "../../redux/Empleados/EmpleadosSlice";

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#003876"
    },

  }
}));

export function Empleados() {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const empleados = useSelector(selectAllEmpleados);
  const empleadosLoading = useSelector(selectEmpleadosIsLoading);
  const empleaodsCount = useSelector(selectEmpleadosCount);

  // ? HOOK-STATE
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [skipRow, setSkipRow] = useState(0);
  const [areaOrganizacionalId, SetAreaOrganizacionalId] = useState(0);
  const [cargoId, setCargoId] = useState(0);
  const [changeEmpleado, setChangeEmpleado] = useState("");
  const [empleado, setEmpleado] = useState("");
  const [estado, setEstado] = useState(1);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const handleChangePage = (event, newPage) => {
    setSkipRow((newPage - 1) * rowsPerPage);
    setPage(newPage - 1);
  };

  const handleAreaOrganizacional = (area) => {
    SetAreaOrganizacionalId(area?.id);
  };

  const handleEmpleado = () => {
    setEmpleado(changeEmpleado);
  };

  const handleDesvincularEmpleado = (empleadoDesvinculado) => {
    try {
      setAddRequestStatus("success");
      dispatch(desvincularEmpleado(empleadoDesvinculado));
    } catch (error) {
      setAddRequestStatus("error");
    }
  };

  const cleanfilter = () => {
    setEmpleado("");
    setChangeEmpleado("");
    SetAreaOrganizacionalId(0);
  };

  const cargarEmpleados = () => {
    dispatch(
      fetchEmpleados({
        skipRow,
        rowsPerPage,
        empleado,
        areaOrganizacionalId,
        estado
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchEmpleadosCount({ empleado, areaOrganizacionalId, cargoId, estado })
    );
    dispatch(
      fetchEmpleados({
        skipRow,
        rowsPerPage,
        empleado,
        areaOrganizacionalId,
        cargoId,
		    estado
      })
    );
  }, []);

  // ? HOOK-EFFECT PARA CARGAR LA LISTA DE EMPLEADOS.
  useEffect(() => {
    cargarEmpleados();
  }, [skipRow, rowsPerPage, areaOrganizacionalId, cargoId, empleado]);

  // ? HOOK-EFFECT PARA OBTENER LA CANTIDAD DE EMPLEADOS SEGUN EL FILTRO.
  useEffect(() => {
    dispatch(
      fetchEmpleadosCount({ areaOrganizacionalId, cargoId, empleado, estado })
    );
    setPage(0);
    setSkipRow(0);
  }, [areaOrganizacionalId, cargoId, empleado]);

  // ? HOOK-MEMO PARA ALMACENAR LA DATA DEL LISTADO DE EMPLEADOS Y EVITAR QUE SE RENDERICE.
  const data = useMemo(() => (empleados), [empleados]);

  const classes = useStyles();

  return (
    <Encabezado title='Empleados'>
      <BarraDeBusquedaMemo
        areaOrganizacionalId={areaOrganizacionalId}
        handleAreaOrganizacional={handleAreaOrganizacional}
        changeEmpleado={changeEmpleado}
        handleEmpleado={handleEmpleado}
        setChangeEmpleado={setChangeEmpleado}
        cleanfilter={cleanfilter}
      />

        {empleados && empleados.length > 0 && (
          <EmpleadosAccordion
            empleados={data}
            cargarEmpleados={cargarEmpleados}
            cleanfilter={cleanfilter}
            handleDesvincularEmpleado={handleDesvincularEmpleado}
            addRequestStatus={addRequestStatus}
            setAddRequestStatus={setAddRequestStatus}
          />
        )}

        {empleados && empleados.length > 0 ? (
          <div className="p-4 d-flex justify-content-center">
            <Pagination 
            classes={{ ul: classes.ul }}
            variant="outlined" 
            shape="rounded"
            count={Math.round(empleaodsCount / 15) < 1 ? 1 : Math.round(empleaodsCount / 15)} 
            page={page + 1}
            onChange={handleChangePage}
            boundaryCount={10} 
            color="primary"
            />
          </div>
          )
          : (
            <p>No se encontraron empleados</p>
          )}

      <Loading open={empleadosLoading} />
    </Encabezado>
    
  );
}

export default Empleados;
