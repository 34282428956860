import React from 'react'
import { Route, Routes, Navigate } from 'react-router'
import { RequiredPermission } from '../authentication/RequiredPermission';

import { 
  AgregarHistorialClinico, 
  HistorialClinico, 
  VerHistorialClinico 
} from '../pages/HistoriaClinica';

export const RutasHistorialClinico = () => {
  return (
    <Routes>

        <Route element={<RequiredPermission permission='ROLE_VER_HISTORICO_CLINICO'/>}> 
          <Route index element={<HistorialClinico />} />
        <Route path="/detalle/:historicoClinicoId" element={<VerHistorialClinico />}/>

        <Route element={<RequiredPermission permission='ROLE_ADMIN'/>}> 
          <Route index element={<HistorialClinico />} />
          <Route path="/detalle/:historicoClinicoId" element={<VerHistorialClinico />}/>
        </Route>

        </Route>
        
        <Route element={<RequiredPermission permission='ROLE_AGREGAR_HISTORICO_CLINICO'/>}> 
            <Route path="/agregar" element={<AgregarHistorialClinico />} />  
        </Route>
        
        <Route element={<RequiredPermission permission='ROLE_ADMIN'/>}> 
            <Route path="/agregar" element={<AgregarHistorialClinico />} />  
        </Route>


        <Route path='/*' element={<Navigate to="/" replace />} />

    </Routes>
  )
}
