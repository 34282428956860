import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  Search,
  FilterAltOutlined,
  CleaningServices,
} from "@mui/icons-material";

import {
  selectAllAreasOrganizacionales,
  fetchAreasOrganizacionales,
} from "../../redux/AreaOrganizacional/AreaOrganizacionalSlice";

export const BarraDeBusqueda = ({
  areaOrganizacionalId,
  handleAreaOrganizacional,
  changeEmpleado,
  handleEmpleado,
  setChangeEmpleado,
  cleanfilter,
}) => {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const areasOrganizacionales = useSelector(selectAllAreasOrganizacionales);

  // ? HOOK-STATE PARA LA LISTA DE AREAS ORGANIZACIONALES
  const [areasOrganizacionalesList, setAreasOrganizacionalesList] = useState([]);
  
  // ? HOOK-EFFECT PARA ACTUALIZAR LA LISTA DE AREAS ORGANIZACIONAES Y AGREGAR UNA NUEVA OPCION.
  useEffect(() => {
    setAreasOrganizacionalesList([{id: 0, nombre: "Seleccionar Área Organizacional" }, ...areasOrganizacionales])
  }, [areasOrganizacionales])  

  useEffect(() => {
    dispatch(fetchAreasOrganizacionales());
  }, [])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleEmpleado();
    }
  };

  return (
    <div className="row border m-1 p-2">

      <div className="col-6 d-flex align-items-end">
        <FilterAltOutlined className="me-2 mb-2 text-primary-mem" />
        
        <label className="me-2 mb-2">Área </label>       
        <div className="col-10">
          <Select
            classNamePrefix="select"
            isSearchable={true}
            placeholder="Seleccionar Área Organizacional"
            options={areasOrganizacionalesList}
            getOptionLabel={(option) => option.nombre}
            getOptionValue={(option) => option.id}
            onChange={handleAreaOrganizacional}
            value={areasOrganizacionales.find(x => x.id === areaOrganizacionalId)}
          />
        </div>
      </div>
      <div className={"col-4"}>
        <div className="input-group mt-2 ">
          <label className="me-2 align-self-center">Empleado</label>
          <input
            className="form-control"
            value={changeEmpleado}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              e.preventDefault();
              setChangeEmpleado(e.target.value);
            }}
            type="text"
          />
          <button
            id="btn-search-empleado"
            className="btn btn-primary-mem"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Buscar Empleado"
            type="button"
            onClick={() => handleEmpleado()}
          >
            <Search />
          </button>
        </div>
      </div>

      <div className="col-1 mt-2 ps-5">
        <button
          id="btn-clean-empleados"
          className="btn btn-danger"
          type="button"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Limpiar filtros"
          onClick={() => {
            cleanfilter();
          }}
        >
          <CleaningServices />
        </button>
      </div>
          
    </div>
  );
};

export const BarraDeBusquedaMemo = React.memo(BarraDeBusqueda);