import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Add, BorderColor, Delete } from "@mui/icons-material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";
import { diasSemana } from "./../../../utils/apiClient";
import { useForm } from "react-hook-form";
import moment from "moment";
import swal from "sweetalert";

import Loading from "../Loading/Loader";
import Turno from "../../Turnos/Turno";
import {
  addNewHorario,
  selectIsloading,
  selectIsSuccess,
  selectMessage,
} from "../../../redux/Horarios/HorarioSlice";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const HorarioNuevo = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsloading);
  const isSuccess = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  // const [openLoading,setOpenLoading] = useState(false);
  const [diasSemanaDisponible, setDiasSemanaDisponible] = useState(diasSemana);
  const [nombre, setNombre] = useState("");
  const [turnos, setTurnos] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();

  const onSubmit = (turno) => {
    let turnosNew = turnos.filter((x) => x.dia != turno.dia);
    setTurnos(
      [...turnosNew, turno].sort(function (a, b) {
        return a.dia - b.dia;
      })
    );
    setValue("dia", "");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const editarTurno = (turno) => {
    const {
      horaInicio,
      horaTermino,
      horaInicioDate,
      horaTerminoDate,
      dia,
    } = turno;
    setValue("dia", dia);
    setValue(
      "horaInicioDate",
      moment(horaInicioDate).format("yyyy-MM-DD HH:mm:ss")
    );
    setValue(
      "horaTerminoDate",
      moment(horaTerminoDate).format("yyyy-MM-DD HH:mm:ss")
    );
  };

  const eliminarTurno = (turno) => {
    setTurnos(turnos.filter((x) => x.dia !== turno.dia));
  };

  const handleTurnos = () => {
    let mensaje = "";
    if (!turnos.length > 0 && nombre == "") {
      mensaje = "El titulo del horario y los turnos son valores requeridos";
    } else if (!turnos.length > 0) {
      mensaje = "Los turnos son requeridos";
    } else if (nombre == "") {
      mensaje = "El titulo del horario es requerido";
    }

    if (mensaje != "") {
      swal("Error!", mensaje, "error");
    } else {
      let turnosNew = {
        turnos,
        nombre,
      };
      dispatch(addNewHorario(turnosNew));
      setAddRequestStatus("success");
    }
  };

  useEffect(() => {
    let date = new Date();
    setValue(
      "horaInicioDate",
      moment(
        new Date(date.getFullYear(), date.getMonth(), date.getDay(), 8, 0, 0, 0)
      ).format("yyyy-MM-DD HH:mm:ss")
    );
    setValue(
      "horaTerminoDate",
      moment(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDay(),
          16,
          0,
          0,
          0
        )
      ).format("yyyy-MM-DD HH:mm:ss")
    );
  }, []);

  useEffect(() => {
    if (isSuccess === "success" && addRequestStatus === "success") {
      swal("Exitoso!", "Fue agregado el horario", "success");
      handleClose();
      // reset({
      //     estudioNivelId:"",
      //     titulo:"",
      //     estado:"",
      //     fecha:""
      // });
      setAddRequestStatus("idle");
    } else if (isSuccess === "error" && addRequestStatus === "success") {
      swal("Error!", message, "error");
      setAddRequestStatus("idle");
    }
  }, [isSuccess, addRequestStatus]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 700, minHeight: 400, maxHeight: 700 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row">
              <h3 className="text-primary-mem text-center">Nuevo Horario</h3>
            </div>
            <div className="row">
              <div className="col-5 me-0 pe-0">
                <hr />
              </div>
              <div className="col-2 text-center">
                <Add className="text-primary-mem"></Add>
              </div>
              <div className="col-5 ms-0 ps-0 ">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label className="fw-bold">Título</label>
                <input
                  className="form-control"
                  onChange={(e) => setNombre(e.target.value)}
                  type="text"
                ></input>
              </div>

              <div className="row pe-0">
                <div className="col-5 me-0 pe-0">
                  <hr />
                </div>
                <div className="col-2 text-center align-self-center">
                  <label className="text-primary-mem fw-bold">Turnos</label>
                </div>
                <div className="col-5 ms-0 ps-0 me-0 pe-0 ">
                  <hr />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <label className="fw-bold">Seleccionar Día</label>
                <select
                  className="form-control"
                  {...register("dia", {
                    required: "El día es un valor requerido",
                  })}
                >
                  <option value="">Seleccionar Día</option>
                  {diasSemanaDisponible.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.value}
                    </option>
                  ))}
                </select>
                <p className="text-danger">{errors.dia?.message}</p>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className="fw-bold">Hora Inicio</label>
                  <Controller
                    control={control}
                    // className="mb-3"
                    name="horaInicioDate"
                    {...register("horaInicioDate", {
                      required: "La hora de inicio es requerida",
                    })}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          value={field.value}
                          label={" "}
                          onChange={(e) =>
                            field.onChange(
                              moment(e).format("yyyy-MM-DD HH:mm:ss")
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              height={10}
                              className="form-control"
                              style={{ height: 10 }}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <p className="text-danger mt-3 mb-0">
                    {errors.horaInicioDate?.message}
                  </p>
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label className="fw-bold">Hora Término</label>
                  <Controller
                    control={control}
                    // className="mb-3"
                    name="horaTerminoDate"
                    {...register("horaTerminoDate", {
                      required: "La hora de término es requerida",
                    })}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          value={field.value}
                          label={" "}
                          onChange={(e) =>
                            field.onChange(
                              moment(e).format("yyyy-MM-DD HH:mm:ss")
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              height={10}
                              className="form-control"
                              style={{ height: 10 }}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  <p className="text-danger mt-3 mb-0">
                    {errors.horaTerminoDate?.message}
                  </p>
                </div>
              </div>
              <div className="col-2 align-self-center mt-2">
                <button className="btn btn-primary-mem" id="btn-add">
                  {" "}
                  <Add></Add>
                </button>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              {turnos.length > 0 && (
                <React.Fragment>
                  <div className="d-flex justify-content-between">
                    <div className="col-4">
                      <label className="fw-bold">Días</label>
                    </div>
                    <div className="col-6">
                      <label className="fw-bold"> Horario</label>
                    </div>
                    <div className="col-2 text-center">
                      <label className="fw-bold"> Acciones</label>
                    </div>
                  </div>
                  {turnos.map((turno) => (
                    <Turno
                      key={turno.dia}
                      turno={turno}
                      eliminarTurno={eliminarTurno}
                      editarTurno={editarTurno}
                    ></Turno>
                  ))}
                </React.Fragment>
              )}
            </div>
            <div className="d-flex justify-content-center align-self-end">
              <button
                id="btn-guardar"
                type="button"
                className="btn btn-primary-mem me-3"
                onClick={handleTurnos}
              >
                Guardar
              </button>
              <button
                id="btn-cancelar"
                type="button"
                className="btn btn-danger "
                onClick={handleClose}
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
        <Loading open={isLoading}></Loading>
      </Box>
    </Modal>
  );
};

export default HorarioNuevo;
