import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogTitle } from '@mui/material'
import { Dialog, DialogActions, DialogContent, Button, Divider, TextField, Typography, Box } from '@mui/material';
import { Autocomplete } from "@mui/material";
import React from 'react'
import { TextareaAutosize } from '@mui/base';
import {
    fetchAllEmpleados,
    selectAllEmpleadosList
    } from "../../../redux/Empleados/EmpleadosSlice";

import {
    onKeyPressEnterEmpleado
    } from "../../../utils/funcionesKeyPress";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1)
      }
    },
    textarea: {
      resize: "both"
    }
  }));

export const EliminarEvaluacionModal = ({open, setOpen}) => {

    // ? HOOK-STATE
    const [empleadoId, setEmpleadoId] = useState(0);

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const listEmpleados = useSelector(selectAllEmpleadosList);

    useEffect(() => {
        dispatch(fetchAllEmpleados());
    }, []);

    const classes = useStyles();

  return (
    <Dialog 
    PaperProps={{ sx: { width: "40%", height: "40%" } }}
    open={open}
    maxWidth='lg'
    >
        <DialogTitle>
           Eliminar Evaluación
        </DialogTitle>
        <Divider />

        <DialogContent>

        <Typography>Empleado</Typography>

            <Autocomplete
                className="mb-2"
                disablePortal
                disableClearable
                getOptionLabel={(listEmpleados) =>
                `${listEmpleados.nombreLabel}`
                }
                // defaultValue={
                // { nombreLabel: "Seleccionar Empleado", id: 0 } || null
                // }
                options={listEmpleados}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText={"No encontrado"}
                renderOption={(props, listEmpleados) => (
                <Box
                    component="option"
                    {...props}
                    key={listEmpleados.id}
                    value={listEmpleados.id}
                >
                    {listEmpleados.nombreLabel}
                </Box>
                )}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label={" "}
                />
                )}
                onChange={(e) => setEmpleadoId(parseInt(e.currentTarget.value))}
                onKeyUp={(e) =>
                onKeyPressEnterEmpleado(e, listEmpleados, setEmpleadoId)
                }
            />

        <Typography>Confirmación</Typography>
        <TextareaAutosize
            minRows="2"
            style={{ width: '100%', resize: 'vertical', overflow: 'auto', maxHeight: '65%' }}
            />

        </DialogContent>

        <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancelar</Button>
            <Button>Eliminar</Button>
        </DialogActions>

    </Dialog>
  )
}
