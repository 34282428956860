import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import { Search } from '@mui/icons-material';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import GroupsIcon from '@mui/icons-material/Groups';
import { PermissionData } from '../../../context/Component/PermissionData';

export const BarraOpciones = (
    {openModalCrear, 
    openModalNotificacion, 
    setEmpleado, 
    empleado, 
    estadoPeriodo,
    route,
    hidden = false

}) => {

  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  // ? HOOK-NAVIGATE
  const navigate = useNavigate();

  // * ESTILOS DE LOS ICONOS.
  const iconStyle = {
    marginTop: -0.5, marginRight: 0.5
  }
  return (
    <div className='row border m-1 p-2'>
        
        <div className='col-md-7 ps-2 container d-flex justify-content-start'>
            <button className='btn btn-primary-mem me-2' onClick={() => navigate(route)}>
                <ArrowBackIcon sx = {iconStyle} />
                Volver
            </button>

            {
                (estadoPeriodo !== 1) && (permisosState.ROLE_ADMIN_EVALUACIONES | permisosState.ROLE_ADMIN) && 
                    <>
                    
                        <button hidden={hidden} className="btn btn-primary me-2" onClick={() => openModalCrear()}>
                            <AddIcon sx = {iconStyle} /> Crear Evaluacion
                        </button>                                    

                        <div className="btn-group" hidden={hidden}>
                            <button type="button" className="btn btn-success dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <NotificationImportantIcon sx = {iconStyle} /> Notificar Evaluadores 
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" onClick={() => openModalNotificacion(1)}><SensorOccupiedIcon sx = {iconStyle} /> Notificación única</a></li>
                                <li><a className="dropdown-item" onClick={() => openModalNotificacion(2)} ><GroupsIcon sx = {iconStyle}/> Notificar a todos los evaluadores</a></li>
                            </ul>
                        </div>      

                    </>
            }

        </div>
            
        <div className='col-md-5'>
            
            <div className="input-group justify-content-end" hidden={hidden}>
                <label className="me-2 align-self-center">Empleado</label>
                    <input
                        className="form-control w-75"
                        value={empleado}
                        onChange={(e) => {
                        e.preventDefault();
                        setEmpleado(e.target.value);
                        }}
                        type="text"
                    />

                    <button
                        className="btn btn-primary-mem"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Buscar Empleado"
                        type="button"
                    >
                        <Search />
                    </button>
            </div>


        </div>          

    </div>
  )
}
