import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "./Components/Layout";
import Dashboard from "./pages/Dashboard";
import { Login } from "./pages/Login/Login";
import { NoAutorizado } from "./pages/No Autorizado/NoAutorizado";
import { PanelControl } from "./pages/Estadisticas/PanelControl"
import RequiredAuth from "./authentication/RequiredAuth";

// * RUTAS
import {
  RutasAsistencia,
  RutasEmpleados,
  RutasPermisos,
  RutasReportes,
  RutasAreasOrganizacionales,
  RutasEvaluaciones,
  RutasBeneficios,
  RutasNominas,
  RutasCafeteria,
  RutasAcciones,
  RutasHistorialClinico
} from "./Routes";

import "./assets/css/custom.css";
import { PermissionProvider } from "./context/PermissionProvider";
import { RutasSorteo } from "./Routes/RutasSorteo";

export const App = () => {
  return (
    <PermissionProvider>
      <Routes>

        {/* RUTAS PUBLICAS */}
        <Route path="login" element={<Login />} />
        <Route path="/*" element={<Navigate to="/login" replace />} />

        <Route element={<RequiredAuth />}>

          {/* RUTAS PRIVADAS */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />

            {/* RUTA EMPLEADOS */}
            <Route path="empleados/*" element={<RutasEmpleados />} />

            {/* RUTA PERMISOS */}
            <Route path="permisos/*" element={<RutasPermisos />} />

            {/* RUTA REPORTES */}
            <Route path="reportes/*" element={<RutasReportes />} />

            {/* RUTA ASISTENCIA */}
            <Route path="asistencia/*" element={<RutasAsistencia />} />

            {/* RUTA AREAS-ORGANIZACIONALES */}
            <Route path="organizacion/*" element={<RutasAreasOrganizacionales />} />

            {/* RUTA EVALUACIONES */}
            <Route path="evaluaciones/*" element={<RutasEvaluaciones />} />

            {/* RUTA BENEFICIOS */}
            <Route path="beneficios/*" element={<RutasBeneficios />} />

            {/* RUTA NÓMINAS */}
            <Route path="nominas/*" element={<RutasNominas />} />

            {/* RUTA CAFETERÍA */}
            <Route path="cafeteria/*" element={<RutasCafeteria />} />

            {/* RUTA ACCIONES */}
            <Route path="acciones/*" element={<RutasAcciones />} />

            {/* RUTA HISTORIAL CLINICO */}
            <Route path="historialClinico/*" element={<RutasHistorialClinico />} />

            {/* RUTAS SORTEO */}
            <Route path="sorteo/*" element={<RutasSorteo />} />

            {/* RUTA DE NOT-AUTHORIZED */}
            <Route path="noAutorizado" element={<NoAutorizado />} />

            {/* RUTA DE DASHBOARD */}
            <Route path="dashboard" element={<PanelControl />} />

          </Route>
        </Route>
      </Routes>
    </PermissionProvider>
  );
};

export default App;
