import React, { useEffect, useState } from "react";
import { Encabezado } from "../../../Components/Encabezado/Encabezado";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../../../redux/Login/LoginSlice";
import { Search } from "@material-ui/icons";
import Permisos from "../../../Components/AccordionPermisos/Permisos";
import TablePagination from "@mui/material/TablePagination";
import Loading from "../../../Components/Modals/Loading/Loader";
import { Typography } from "@mui/material";

import swal from "sweetalert";

import {
  fetchCountPermisosAreaReporteId,
  fetchListaPermisosAreaReporteId,
  fetchCambiarSuccess,
  fetchVerificarEncargadoPermiso,
  selectPermisos,
  selectPermisosCount,
  selectEsEncargadoBool,
  selectIsLoading,
  selectIsSuccess,
  selectMessage,
} from "./../../../redux/Permisos/PermisosSlice";

import { PermissionData } from "../../../context/Component/PermissionData";

export const SolicitudesPermisos = () => {

  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK SELECTOR
  const usuario = useSelector(selectUserData);
  const permisos = useSelector(selectPermisos);
  const isLoading = useSelector(selectIsLoading);
  const permisosCount = useSelector(selectPermisosCount);
  const isSuccess = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  const esEncargado = useSelector(selectEsEncargadoBool);

  // ? HOOK-STATE PAGINACION
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [skipRow, setSkipRow] = useState(0);
  const [page, setPage] = useState(0);

  // ? HOOK-STATE
  const [aprobacionesFilterState, setAprobacionesFilterState] = useState({});
  const [changeEmpleado, setChangeEmpleado] = useState("");
  const [empleado, setEmpleado] = useState("");
  const [listadoPermisoFilter, setListadoPermisoFilter] = useState([]);

  // * AREA REPORTE DEL USUARIO LOGEADO
  const areaReporte = parseInt(usuario?.AreaReporteId);

  // * FUNCION PARA OBTENER EL LISTADO DE PERMISOS SEGUN LA CONDICION.
  const cargarPermisos = (aprobacionesFilter) => {
    dispatch(fetchListaPermisosAreaReporteId({aprobacionesFilter, skipRow, rowsPerPage}));
    dispatch(fetchCountPermisosAreaReporteId(aprobacionesFilter));
  };

  // * FUNCIONES DE PAGINACION.
  const handleChangePage = (event, newPage) => {
    setSkipRow(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSkipRow(0);
  };

  // * FUNCIONES GENERALES
  const handleEmpleado = () => {
    setEmpleado(changeEmpleado);
  };

  // * USE-EFFECT
  useEffect(() => {

    if(permisosState.ROLE_APROBAR_PERMISO_DIRECTOR_MINISTRO){
      console.log(1);
      const aprobacionesFilterAreaReporteId = {
        empleado: empleado ?? "",
        areaReporteId: 1       
      };

      setAprobacionesFilterState(aprobacionesFilterAreaReporteId);
      cargarPermisos(aprobacionesFilterAreaReporteId, areaReporte);
    }
    else if (esEncargado) {
      console.log(2);

        // * EVALUANDO EL AREA-REPORTE-ID DEL USUARIO LOGEADO.
        const aprobacionesFilterAreaReporteId = {
          empleado: empleado ?? "",
          areaReporteId: usuario?.AreaOrganizacionalId        
        };

        setAprobacionesFilterState(aprobacionesFilterAreaReporteId);
        cargarPermisos(aprobacionesFilterAreaReporteId, areaReporte);
      }
  }, [skipRow, rowsPerPage, empleado, esEncargado]);

  useEffect(() => {
    if (isSuccess === "success" && message.length > 0) {
      cargarPermisos(aprobacionesFilterState);
      swal("Exitoso!", message, "success");
      dispatch(fetchCambiarSuccess());
    } else if (isSuccess === "error") {
      swal(
        "Error",
        "Por favor, comunicarse con el departamento de Desarrollo e Implementación de Sistemas.",
        "error"
      );
      dispatch(fetchCambiarSuccess());
    }
  }, [isSuccess]);

  useEffect(() => {
    if ((permisos?.length > 0 && esEncargado)) {
      // * FILTRANDO CON LA CONDICION DE QUE EL EMPLEADO-ID NO SEA IGUAL AL QUE SE ENCUENTRA LOGEADO.
      const listaFilter = permisos.filter(
        (x) => x.empleadoId !== parseInt(usuario?.Id)
      );

      // * ASIGNANDO FILTRO AL STATE.
      setListadoPermisoFilter(listaFilter);
    }
    else if(permisos.length && permisosState.ROLE_APROBAR_PERMISO_DIRECTOR_MINISTRO){
      setListadoPermisoFilter(permisos);
    }
  }, [permisos]);

  useEffect(() => {
    dispatch(fetchVerificarEncargadoPermiso(usuario));
  }, [usuario]);

  const validateInputEnable = permisosState.ROLE_APROBAR_PERMISO_DIRECTOR_MINISTRO || esEncargado;

  return (
    <Encabezado title="APROBAR PERMISOS">
      <div className="row mt-2">
        <div className="d-flex justify-content-end">
          <div className="col-6">
            <div className="input-group">
              <label className="align-self-center me-3">EMPLEADO</label>
              <input
                disabled={!validateInputEnable}
                type="text"
                className="form-control"
                id="inlineFormInputGroupUsername"
                value={changeEmpleado}
                onChange={(e) => {
                  e.preventDefault();
                  setChangeEmpleado(e.target.value);
                }}
              />

              <button
                onClick={() => handleEmpleado()}
                type="button"
                className="input-group-text btn-primary-mem"
                disabled={!esEncargado}
              >
                <Search />
              </button>
            </div>
          </div>
        </div>
      </div>

      {permisos.length > 0 &&
        listadoPermisoFilter.length > 0 &&
        listadoPermisoFilter.map((permiso) => (
          <Permisos permiso={permiso} buttonActive={true} />
        ))}

      {(permisos.length === 0 && isLoading === false) | !validateInputEnable ? (
        <div className="mt-4">
          <Typography variant="h5">
            No se encontraron solicitudes de permisos.
          </Typography>
        </div>
      ) : (
        <></>
      )}

      {permisos.length > 0 && listadoPermisoFilter.length > 0 && (
        <TablePagination
          component="div"
          count={permisosCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <Loading open={isLoading} />
    </Encabezado>
  );
};
