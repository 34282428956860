import React from "react";
import "./estilos/style.css";

export const ResumenAsistenciaCard = ({empleados}) => {

  return (
    <>
      {empleados.map((x) => (

        <div className="card mb-2" key={x.codigoEmpleado}>
          <div className="row g-0">
            <div className="col--1 text-center">
              <h6 className="card-title mt-5">{x.codigoEmpleado}</h6>
            </div>
            <div className="col-departamentos">
              <div className="card-body">
                <p className="card-title text-mem">
                  {x.nombreLabel}
                </p>
                <p className="text-13 mb-0">
                  {x.cargoLabel}
                </p>
                <p className="card-text text-13">
                 {x.areaOrganizacionalLabel}
                </p>
              </div>
            </div>

            <div className="col-2">
              <div className="mt-4 text-center text-13">
                <p className="card-text fw-bold">Supervisor:</p>
                <p>{x.encargado}</p>
              </div>
            </div>

            <div className="col-2">
              <div className="mt-4 text-center text-13">
                <p className="card-text fw-bold">Período:</p>
                <p>{x.periodo}</p>
              </div>
            </div>

            <div className="col-w11">
              <div className="mt-4 text-center text-13">
                <p className="card-text fw-bold">Minutos de Tardanza:</p>
                <p>{x.minutosdeTardanza}</p>
              </div>
            </div>

            <div className="col-w11">
              <div className="mt-4 text-center text-13">
                <p className="card-text fw-bold">Ausencias:</p>
                <p>{x.ausencias}</p>
              </div>
            </div>

            <div className="col-w11">
              <div className="mt-4 text-center text-13">
                <p className="card-text fw-bold">Ponche incompleto:</p>
                <p>{x.poncheIncompletos}</p>
              </div>
            </div>
          </div>
        </div>
        
      ))
    }

    </>
  );
};
