import React, { useEffect, useState } from 'react';

import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TablePagination 
} from '@mui/material';

import { camposReporteEmpleados } from '../../../utils/camposReporteEmpleados';

export const ReporteDinamicoTable = ({campos, reporteData, reporteCount, page, setPage, setRowsPerPage, setSkipRow, rowsPerPage}) => {

  // ? USE-STATE PARA LOS CAMPOS Y COLUMNAS
  const [columns, setColumns] = useState([]);
  const [campoSeleccionados, setCampoSeleccionados] = useState([]);
  
   // * FUNCIONES PARA PAGINACION.
	const handleChangePage = (event, newPage) => {
		setSkipRow(newPage * rowsPerPage);
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setSkipRow(0);
	};
  
  useEffect(() => {
    
    if(Object.keys(campos).length > 0){
      const listaCampos = Object.keys(campos).filter(x => campos[x] === true);
      const camposSeleccionados = camposReporteEmpleados.filter((x) => listaCampos.find(y => y === x.campo));

      let arr = [];

      for (let index = 0; index < camposSeleccionados.length; index++) {
        
        const data = camposSeleccionados[index];

        const campoAdd = { field: data.campo, headerName: data.label, width: 70 };
        arr.push(campoAdd);
      }

      setColumns(arr);
      setCampoSeleccionados(listaCampos);
    }

    
  }, [campos])

  return (
    <Box sx={{ mt: 3 }} maxWidth={'100%'}>
      <Paper sx={{ mb: 2 }} elevation={3}>
        <TableContainer>

          <Table stickyHeader>
            <TableHead>
              <TableRow>
                
                {
                  columns.map((item) => (
                    <TableCell key={item.field} sx={{fontWeight: 'bold'}}>
                      {item.headerName}
                    </TableCell>
                  ))
                }
                
              </TableRow>
            </TableHead>

            <TableBody>

              {
                reporteData.map((item, index) => (
                  <TableRow key={index}>
                    {
                    campoSeleccionados.map((campo, index) => (
                      <TableCell key={index}>
                        {
                          item[campo]
                        }
                      </TableCell>
                      ))
                    }
                    
                  </TableRow>
                ))
              }

            </TableBody>

          </Table>

        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={reporteCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  )
}
