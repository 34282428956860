import React, { 
    useState,
    useEffect
} from 'react';

import { useDispatch } from 'react-redux';

import { 
    Step, 
    StepButton, 
    Stepper 
} from '@mui/material';

import { fetchUpdateCompetenciasComportamientos } from '../../../redux/Evaluaciones/EvaluacionesSlice';
import { CompetenciaComportamientosPage } from '../CompetenciasTabs';

export const CompetenciasTab = ({ estado, listaCompetenciasComportamientosMod, setListaCompetenciasComportamientosMod }) => {

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-STATE PARA LA PAGINACION DE PAGINAS.
    const [stepPosition, setStepPosition] = useState(0); 

    // ? HOOK-STATE PARA VALIDACION DE CONFIMRACION DE DATA.
    const [confirmacionEnviarData, setConfirmacionEnviarData] = useState(false);   

    // ? HOOK-STATE PARA ACTVAR CHECK-BOX DE LOS TABS.
    const [enabledCheckTabs, setEnabledCheckTabs] = useState(false);

    const HandleSteps = ({ position }) => {
        let children = <></>;

        children = 
        <CompetenciaComportamientosPage 
        estado={estado}
        listaCompetenciasComportamientosMod={listaCompetenciasComportamientosMod}
        listaCompetenciasComportamientosModSeleccionado={listaCompetenciasComportamientosMod[position]} 
        setListaCompetenciasComportamientosMod={setListaCompetenciasComportamientosMod}
        setStepPosition={setStepPosition}
        stepPosition={stepPosition}
        setConfirmacionEnviarData={setConfirmacionEnviarData}
        />;

        return children;
    };

    // ? HOOK-EFFECT PARA ENVIAR CAMBIOS DE LAS COMPETENCIAS-COMPORTAMIENTOS A LA API.
    useEffect(() => {
      if(confirmacionEnviarData){

        // * FUNCION QUE FILTRA POR LA LISTA DE COMPORTAMIENTOS Y ELIMINA
        // * EL CAMPO COMPORTAMIENTO PARA CONVERTIRLO AL FORMATO PARA
        // * INSERTAR LOS COMPORTAMIENTOS EN EL SERVIDOR.
        const listaComportamientos = listaCompetenciasComportamientosMod
        .map(x => x.listaComportamientos)
        .flat()
        .map(comportamiento => { 
            const { ['comportamiento']: _, ...resto } = comportamiento;
            return resto;
        });
    
        dispatch(fetchUpdateCompetenciasComportamientos(listaComportamientos));
        setConfirmacionEnviarData(false);
        setStepPosition(0);
      }
    }, [confirmacionEnviarData])

    // ? HOOK-EFFECT QUE DETERMINAR SI EXISTEN VALORES NULOS EN LA LISTA DE COMPORTAMIENTOS DE CADA COMPETENCIA
    // ? SI SE CUMPLE PUES EL CHECH-BOX NO SE MARCA, PERO SI NINGUNA CALIFICACION ES NULA SIGNIFICA QUE EL ENCARGADO
    // ? CALIFICO
    useEffect(() => {
        setEnabledCheckTabs(listaCompetenciasComportamientosMod.some(x => x.listaComportamientos.some(x => x.calificacion === null)));
    }, [listaCompetenciasComportamientosMod])
    
    return (
        <>
            <Stepper alternativeLabel activeStep={enabledCheckTabs ? stepPosition : listaCompetenciasComportamientosMod.length} sx={{ marginTop: 2 }}>
                {
                    listaCompetenciasComportamientosMod.length > 0 &&
                        listaCompetenciasComportamientosMod.map((item, index) =>

                            <Step key={index}>
                                <StepButton disabled onClick={() => setStepPosition(index)}>
                                    {item.competencia}
                                </StepButton>
                            </Step>
                        )
                }
            </Stepper>

            <div style={{ marginTop: '3%' }}>
                <HandleSteps position={stepPosition} />
            </div>
        </>
    );
};