import React, { useEffect, useState, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Add, BorderColor, Remove, Delete } from '@mui/icons-material';
import TipoPermisoEditar from '../Modals/TiposPermisos/TipoPermisoEditar';
import {useDispatch,useSelector} from "react-redux";
import {updateTipoPermiso,selectIsLoading,selectMessage,selectIsSuccess} from "./../../redux/Permisos/PermisosSlice"
import Loading from "./../Modals/Loading/Loader";
import swal from 'sweetalert';
import {PermissionData} from '../../context/Component/PermissionData';
import { IconButton } from '@mui/material';

function TablesTipoPermisos(props) {

	 // * PERMISOS DEL USUARIO.
	 const permisosState = PermissionData();
	 
	const {openEdit,setOpenEdit ,setTipoPermiso, deleteTipoPermiso,tiposPermisos, tiposPermisosCategorias,tipoPermiso} = props;
    const dispatch = useDispatch();
    const [addRequestStatus,setAddRequestStatus] = useState('idle');
    const message = useSelector(selectMessage);
    const isSuccess = useSelector(selectIsSuccess);
    const isLoading = useSelector(selectIsLoading);
    const [addRequestStatusDelete,setAddRequestStatusDelete]=useState('idle');

	const handleTipoPermiso = (data)=>{
		 setTipoPermiso(data);
         setOpenEdit(true);
           
	}

    const handleUpdateTipoPermiso = (data)=>{
        try {
			setAddRequestStatus('success');
			dispatch(updateTipoPermiso(data));
		} catch (error) {
			setAddRequestStatus('error');
		}
    }

	const handleDeleteTipoPErmiso = (data)=>{
		setTipoPermiso(data);
        setAddRequestStatusDelete("success");
		
	}

    useEffect(()=>{
        if(tipoPermiso && tipoPermiso.id>0 &&addRequestStatusDelete=='success'){
            deleteTipoPermiso();
            setAddRequestStatusDelete("idle");
        }
    },[tipoPermiso,addRequestStatusDelete])
 

    useEffect(()=>{
        if(isSuccess==='success' && addRequestStatus==='success'){
			swal("Exitoso!", "Fue actualizado el tipo de permiso", "success");
			setOpenEdit(false);
            setAddRequestStatus('idle');
		}else if(isSuccess==='error'&& addRequestStatus==='success'){
			swal("Error!", message, "error");
			setAddRequestStatus('idle');
		}
    },[isSuccess,addRequestStatus])


	return (
		<>
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead className='bg-primary-mem '>
					<TableRow>
						{/* {pruebaColumn} */}
						<TableCell className="fw-bold text-white" align="left">
							#
						</TableCell>
						<TableCell className="fw-bold text-white" align="left">
							Tipo de Permiso
						</TableCell>
						<TableCell className="fw-bold text-white" align="center">
				            Estado
						</TableCell>
						<TableCell className="fw-bold text-white" align="center">
							Acciones
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{/* {rows.map((row) => */}
					{tiposPermisos.map((row, index) => (
						<TableRow key={row.id}  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row">
								{index+1}
							</TableCell>
							<TableCell component="th" scope="row">
								{row.nombre}
							</TableCell>
							<TableCell align="center" component="th" scope="row">
								{row.estado?"Activo":"Inactivo"}
							</TableCell>

							<TableCell component="th" className="text-center" scope="row">
								{/* <button className='btn btn-primary'><i className='fa fa-eyes'></i></button> */}
								 <IconButton id='btn-editar-tipo-permiso' onClick={()=>handleTipoPermiso(row)} className="text-primary-mem" hidden={!permisosState.ROLE_EDITAR_TIPO_PERMISO}>
								 	<BorderColor />
								 </IconButton>
								<IconButton id='btn-eliminar-tipo-permiso' onClick={()=>handleDeleteTipoPErmiso(row)} className="text-danger" hidden={!permisosState.ROLE_BORRAR_TIPO_PERMISO}>
									<Delete />
								</IconButton>
							</TableCell>
						</TableRow>
					 ))}
					{/* <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
				</TableBody>
			</Table>
		</TableContainer>
        <TipoPermisoEditar openEdit={openEdit} setOpenEdit={setOpenEdit} tiposPermisosCategorias={tiposPermisosCategorias} tipoPermiso={tipoPermiso} handleUpdateTipoPermiso={handleUpdateTipoPermiso}></TipoPermisoEditar>
        <Loading open={isLoading}></Loading>
		</>
	);
}

export default TablesTipoPermisos;
