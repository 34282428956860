export const calcularAsistencia = (empleado, setContadores) => {

  let contadorAusencias = 0;
  let contadorLlegadaTarde = 0;
  let contadorSalidaTemprano = 0;
  let contadorTardanza = 0;

  for (let i = 0; i < empleado.asistencias.length; i++) {
    const emp = empleado.asistencias[i];

    if (emp.justificacion === "Ausente") {
      contadorAusencias++;
    }

    contadorLlegadaTarde += emp.tarde;
    contadorSalidaTemprano += emp.temprano;
    contadorTardanza += emp.tardanza;

  }

  setContadores({
    totalAusencias: contadorAusencias,
    totalLlegadaTarde: contadorLlegadaTarde,
    totalSalidaTemprano: contadorSalidaTemprano,
    totalTardanza: contadorTardanza
  });
};
