import React from "react";

export const TotalResumenAsistenciaCard = ({ contadores }) => {

  // * DESESTRUCTURACION DE LOS CONTADORES.
  const { totalAusencias, totalLlegadaTarde, totalSalidaTemprano, totalTardanza } = contadores;

  return (
    <div className="row total-height mt-4">
      <div className="col-2 mt-1">
        <p className="fw-bold">Ausencias</p>
      </div>
      <div className="col-1 mt-1">
        <p>{totalAusencias}</p>
      </div>

      <div className="col-2 mt-1">
        <p className="fw-bold">Total llegada tarde</p>
      </div>

      <div className="col-1 mt-1">
        <p>{totalLlegadaTarde}</p>
      </div>

      <div className="col-2 mt-1">
        <p className="fw-bold">Total salida temprano</p>
      </div>

      <div className="col-1 mt-1">
        <p>{totalSalidaTemprano}</p>
      </div>

      <div className="col-2 mt-1">
        <p className="fw-bold">Total tardanza</p>
      </div>

      <div className="col-1 mt-1">
        <p>{totalTardanza}</p>
      </div>
    </div>
  );
};
