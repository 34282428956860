import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../config";
import axiosApiInstance, { axiosApiInstanceFiles } from "../../utils/apiClient";
import { messageFrontEnd } from "../../utils/mensajeErrorCliente"

const initialState = {
  isSuccess: "idle",
  isLoading: false,
  message: "",
  listaEvaluaciones: [],
  evaluacionCompetenciasComportamientos: [],
  listaEvaluacionesPorAno: [],
  mensajeGeneracionPeriodo: {},
  evaluacionEmpleadoHash: {},
  evaluacionesEmpleadoLogroMetas: [],
  estadoAprobacionMetas: {},
  planDeMejora: {},
  estado: 0
};

export const fetchListaEvaluaciones = createAsyncThunk(
  "evaluaciones/fetchListaEvaluaciones",
  async (evaluadorId = 0) => {
    let resultado = await axiosApiInstance.get(`${endpoints.evaluaciones.listaEvaluaciones}?evaluadorId=${evaluadorId}`);
    return resultado.data;
  }
);

export const fetchListaEvaluacionesPorAno = createAsyncThunk(
  "evaluaciones/fetchListaEvaluacionesPorAno",
  async ({ano, evaluadorId = 0}) => {
    let resultado = await axiosApiInstance.get(`${endpoints.evaluaciones.listaEvaluacionesPeriodo}?anio=${ano}&evaluadorId=${evaluadorId}`);
    return resultado.data;
  }
);


// ************************************************************************************** //
// *************************************- REPORTES -************************************* //
// ************************************************************************************** //

export const fetchListaEvaluacionesExportarMAP = createAsyncThunk(
  "evaluaciones/fetchListaEvaluacionesExportarMAP",
  async (ano) => {
    const result = await axiosApiInstance({
      url: `${
        endpoints.evaluaciones.reporteMAP
      }?anio=${ano}`, //your url

      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `Reporte de Evaluación MAP (${ano}).xls`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    return result;
  }
);

export const fetchListaEvaluacionesExportarDAF = createAsyncThunk(
  "evaluaciones/fetchListaEvaluacionesExportarDAF",
  async (ano) => {
    const result = await axiosApiInstance({
      url: `${
        endpoints.evaluaciones.reporteDAF
      }?anio=${ano}`, //your url

      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `Reporte de Evaluación DAF (${ano}).xls`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    return result;
  }
);

export const fetchGenerarEvaluacionEmpleadoPDF = createAsyncThunk(
  "evaluaciones/fetchGenerarEvaluacionEmpleadoPDF",
  async (evaluacionId) => {
    const result = await axiosApiInstance({
      url: `${
        endpoints.evaluaciones.evaluacionGenerarReporteEvaluacionEmpleado
      }?evaluacionId=${evaluacionId}`, //your url

      method: "POST",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `Acuerdo de Desempeño (${evaluacionId}).pdf`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    return result;
  }
);

export const fetchGetReporteEvaluacionesPeriodoMAP2024 = createAsyncThunk(
  "evaluaciones/fetchGetReporteEvaluacionesPeriodoMAP2024",
  async (ano) => {
    const result = await axiosApiInstance({
      url: `${endpoints.evaluaciones.fetchGetReporteEvaluacionesPeriodoMAP2024}${ano}`,
      method: "POST",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `Reporte Evaluacion de Desempeño Período (${ano}).xls`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    return result;
  }
);


// * LIMPIAR SUCCESS
export const fetchCambiarSuccess = createAsyncThunk(
  "reportePermisos/fetchCambiarSuccess",
  async () => {
    return null;
  }
);


// * OBTENER ESTADO DE UN PERIODO.
export const fetchObtenerEstadoPeriodo = createAsyncThunk(
  "evaluaciones/fetchObtenerEstadoPeriodo",
    async(ano) => {
      let resultado = await axiosApiInstance.get(`${endpoints.evaluaciones.evaluacionPeriodoEstado}/${ano}`);
      return resultado.data;
    }
)

// TODO: CUD (CREATE, UPDATE AND UPDATE)

export const fetchEvaluacionNueva = createAsyncThunk(
  "evaluaciones/fetchEvaluacionNueva",
  async (data) => {
    let resultado = await axiosApiInstance.post(endpoints.evaluaciones.evaluacionNueva, data);
    return resultado.data;
  }
);

export const fetchEvaluacionEliminar = createAsyncThunk(
  "evaluaciones/fetchEvaluacionEliminar",
  async (id) => {
    let resultado = await axiosApiInstance.post(`${endpoints.evaluaciones.evaluacionEliminar}/${id}`);
    return resultado.data;
  }
);

export const fetchEvaluacionActualizar = createAsyncThunk(
  "evaluaciones/fetchEvaluacionActualizar",
  async (data) => {
    let resultado = await axiosApiInstance.post(endpoints.evaluaciones.evaluacionActualizar, data);
    return resultado.data;
  }
);

export const fetchEvaluacionGenerarPeriodo = createAsyncThunk(
  "evaluaciones/fetchEvaluacionGenerarPeriodo",
  async (ano) => {
    let resultado = await axiosApiInstance.post(`${endpoints.evaluaciones.evaluacionGenerarPeriodo}?periodo=${ano}`);
    return resultado.data;
  }
);

export const fetchEvaluacionNotificacionesAll = createAsyncThunk(
  "evaluaciones/fetchEvaluacionNotificacionesAll",
  async ({periodo, asunto, mensaje, listaCorreos}) => {

    let resultado = await axiosApiInstance.post(`${endpoints.evaluaciones.evaluacionNotificarPeriodo}?periodo=${periodo}
                                                &asunto=${asunto}&mensaje=${mensaje}`, listaCorreos);
    return resultado.data;
  }
);

export const fetchObtenerEvaluacionEmpleadoHash = createAsyncThunk(
  "evaluaciones/fetchObtenerEvaluacionEmpleadoHash",
  async (hash) => {

    let resultado = await axiosApiInstance.get(`${endpoints.evaluaciones.evaluacionResultadosHash}?hash=${hash}`);
    return resultado.data;
  }
)

export const fetchObtenerCompetenciasComportamientosEvaluacionById = createAsyncThunk(
  "evaluaciones/fetchObtenerCompetenciasComportamientosEvaluacionById",
  async (id) => {
    let resultado = await axiosApiInstance.get(`${endpoints.evaluaciones.fetchGetCompetenciasComportamientos}${id}`);
    return resultado.data;
  }
)

export const fetchUpdateCompetenciasComportamientos = createAsyncThunk(
  "evaluaciones/fetchUpdateCompetenciasComportamientos",
  async (data) => {
    let resultado = await axiosApiInstance.post(endpoints.evaluaciones.fetchUpdateCompetenciasComportamientos, data);
    return resultado.data;
  }
)

// * REABRIR EVALUACION
export const fetchReabrirEvaluacion = createAsyncThunk(
  "evaluaciones/fetchReabrirEvaluacion",
  async (evaluacionId) => {
    let resultado = await axiosApiInstance.post(`${endpoints.evaluaciones.evaluacionReabrir}?evaluacionId=${evaluacionId}`);
    return resultado.data;
  }
)

// * CERRAR EVALUACION
export const fetchCerrarEvaluacion = createAsyncThunk(
  "evaluaciones/fetchCerrarEvaluacion",
  async (evaluacionId) => {
    let resultado = await axiosApiInstance.post(`${endpoints.evaluaciones.evaluacionCerrar}?evaluacionId=${evaluacionId}`);
    return resultado.data;
  }
)

// TODO: CRUD (CREATE, UPDATE AND UPDATE) EVALUACIONES LOGRO DE METAS

export const fetchEvaluacionMetasObtener = createAsyncThunk(
  "evaluaciones/fetchEvaluacionMetasObtener",
  async (evaluacionId) => {
    let resultado = await axiosApiInstance.post(`${endpoints.evaluaciones.evaluacionLogroMetasAllPorUsuario}?evaluacionId=${evaluacionId}`);
    return resultado.data;
  }
)

export const fetchEvaluacionMetasCrear = createAsyncThunk(
  "evaluaciones/fetchEvaluacionMetasCrear",
  async (data) => {
    let resultado = await axiosApiInstance.post(endpoints.evaluaciones.evaluacionLogroMetasAgregar, data);
    return resultado.data;
  }
)

export const fetchEvaluacionMetasActualizar = createAsyncThunk(
  "evaluaciones/fetchEvaluacionMetasActualizar",
  async (data) => {
    let resultado = await axiosApiInstance.post(endpoints.evaluaciones.evaluacionLogroMetasEditar, data);
    return resultado.data;
  }
)

// * EDITAR PERIODO EVALUACION
export const fetchEvaluacionEditarPeriodo = createAsyncThunk(
  "evaluaciones/fetchEvaluacionEditarPeriodo",
  async (data) => {
    let resultado = await axiosApiInstance.post(endpoints.evaluaciones.evaluacionEditarPeriodo, data);
    return resultado.data;
  }
)

// * CREAR PERIODO EVALUACION
export const fetchEvaluacionCrearPeriodo = createAsyncThunk(
  "evaluaciones/fetchEvaluacionCrearPeriodo",
  async (data) => {
    let resultado = await axiosApiInstance.post(endpoints.evaluaciones.evaluacionCrearPeriodo, data);
    return resultado.data;
  }
)


// * CERRAR PERIODO EVALUACION
export const fetchEvaluacionCerrarPeriodo = createAsyncThunk(
  "evaluaciones/fetchEvaluacionCerrarPeriodo",
  async (ano) => {
    let resultado = await axiosApiInstance.post(`${endpoints.evaluaciones.evaluacionCerrarPeriodo}/?periodo=${ano}`);
    return resultado.data;
  }
)

export const fetchEvaluacionMetasEliminar = createAsyncThunk(
  "evaluaciones/fetchEvaluacionMetasEliminar",
  async (data) => {
    let resultado = await axiosApiInstance.post(endpoints.evaluaciones.evaluacionLogroMetasEliminar, data);
    return resultado.data;
  }
)

// * APROBAR LOGRO DE METAS
export const fetchEvaluacionMetasAprobar = createAsyncThunk(
  "evaluaciones/fetchEvaluacionMetasAprobar",
  async (data) => {
    let resultado = await axiosApiInstance.post(endpoints.evaluaciones.evaluacionAprobacionLogroMetas, data);
    return resultado.data;
  }
)

export const fetchEvaluacionMetasAprobadasObtenerEstado = createAsyncThunk(
  "evaluaciones/fetchEvaluacionMetasAprobadasObtenerEstado",
  async (evaluacionId) => {
    let resultado = await axiosApiInstance.get(`${endpoints.evaluaciones.evaluacionAprobacionLogroMetas}?evaluacionId=${evaluacionId}`);
    return resultado.data;
  }
)

// * PLAN DE MEJORA
export const fetchCrearPlanMejora = createAsyncThunk(
  "evaluaciones/fetchCrearPlanMejora",
  async (data) => {
    let resultado = await axiosApiInstance.post(endpoints.evaluaciones.evaluacionPlanMejora, data);
    return resultado.data;
  }
)

// * EDITAR PLAN DE MEJORA
export const fetchEditarPlanMejora = createAsyncThunk(
  "evaluaciones/fetchEditarPlanMejora",
  async (data) => {
    let resultado = await axiosApiInstance.post(endpoints.evaluaciones.evaluacionPlanMejoraEditar, data);
    return resultado.data;
  }
)

// * OBTENER PLAN DE MEJORA
export const fetchObtenerPlanMejora = createAsyncThunk(
  "evaluaciones/fetchObtenerPlanMejora",
  async (evaluacionId) => {
    let resultado = await axiosApiInstance.get(`${endpoints.evaluaciones.evaluacionPlanMejora}/evaluacionId/?evaluacionId=${evaluacionId}`);
    return resultado.data;
  }
)

// * ELIMINAR PLAN DE MEJORA
export const fetchEvaluacionEliminarAreaMejora = createAsyncThunk(
  "evaluaciones/fetchEvaluacionEliminarAreaMejora",
  async (areaMejoraId) => {
    let resultado = await axiosApiInstance.post(`${endpoints.evaluaciones.evaluacionAreasMejorasRemover}?areaMejoraId=${areaMejoraId}`);
    return resultado.data;
  }
)

// * ELIMINAR PLAN DE MEJORA
export const fetchEvaluacionEliminarPuntosFuertes = createAsyncThunk(
  "evaluaciones/fetchEvaluacionEliminarPuntosFuertes",
  async (puntoFuerteId) => {
    let resultado = await axiosApiInstance.post(`${endpoints.evaluaciones.evaluacionPuntosFuertesRemover}?puntoFuerteId=${puntoFuerteId}`);
    return resultado.data;
  }
)


export const fetchClearEvaluacionesGenerarPeriodo = createAsyncThunk(
  "evaluaciones/fetchClearEvaluacionesGenerarPeriodo",
  async () => {
    return {};
  }
)

export const fetchPostResultadosValoresFiles = createAsyncThunk(
  "evaluaciones/fetchPostResultadosValoresFiles",
  async ({formData, logroMetaId}) => {
    try {
      const response = await axiosApiInstanceFiles.post(
        `${endpoints.evaluaciones.fetchPostResultadosValoresFiles}?resultadoId=${logroMetaId}`,
        formData,
        
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const fetchDeleteResultadosValoresFiles = createAsyncThunk(
  "evaluaciones/fetchDeleteResultadosValoresFiles",
  async (fileId) => {
    try {
      const response = await axiosApiInstanceFiles.post(`${endpoints.evaluaciones.fetchDeleteResultadosValoresFiles}${fileId}`);
      
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const EvaluacionesSlice = createSlice({
  name: "evaluaciones",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchListaEvaluaciones.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchListaEvaluaciones.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.listaEvaluaciones = action.payload.data;
        } else {
          state.isSuccess = "error";
          state.listaEvaluaciones = [];
        }
      })
      .addCase(fetchListaEvaluaciones.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchListaEvaluacionesPorAno.pending, (state, action) => {
        state.listaEvaluacionesPorAno = [];
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchListaEvaluacionesPorAno.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "";
          state.listaEvaluacionesPorAno = action.payload.data;
        } else {
          state.isSuccess = "error";
          state.listaEvaluacionesPorAno = [];
        }
      })
      .addCase(fetchListaEvaluacionesPorAno.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchListaEvaluacionesExportarMAP.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchListaEvaluacionesExportarMAP.fulfilled, (state, action) => {
        state.isSuccess = "success";
        state.message = "";
        state.isLoading = false;
      })
      .addCase(fetchListaEvaluacionesExportarMAP.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchListaEvaluacionesExportarDAF.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchListaEvaluacionesExportarDAF.fulfilled, (state, action) => {
        state.isSuccess = "success";
        state.message = "";
        state.isLoading = false;
      })
      .addCase(fetchListaEvaluacionesExportarDAF.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchGenerarEvaluacionEmpleadoPDF.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchGenerarEvaluacionEmpleadoPDF.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "success";
        state.message = "El reporte fue generado.";
      })
      .addCase(fetchGenerarEvaluacionEmpleadoPDF.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error?.message;
        console.error(action.error?.message)
      })
      .addCase(fetchGetReporteEvaluacionesPeriodoMAP2024.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchGetReporteEvaluacionesPeriodoMAP2024.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "success";
        state.message = "El reporte fue generado.";
      })
      .addCase(fetchGetReporteEvaluacionesPeriodoMAP2024.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchCambiarSuccess.fulfilled, (state) => {
        state.isSuccess = "idle";
      })
       .addCase(fetchEvaluacionNueva.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionNueva.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionNueva.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionEliminar.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionEliminar.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionEliminar.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionActualizar.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionActualizar.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionActualizar.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchObtenerEstadoPeriodo.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchObtenerEstadoPeriodo.fulfilled, (state, action) => {
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.estado = action.payload.data.estado;
        } else {
          state.estado = 0;
          state.isSuccess = "error"; 
        }
        state.isLoading = false;
      })
      .addCase(fetchObtenerEstadoPeriodo.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionGenerarPeriodo.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionGenerarPeriodo.fulfilled, (state, action) => {
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.mensajeGeneracionPeriodo = action.payload.data;
        } else {
          state.estado = 0;
          state.isSuccess = "error"; 
        }
        state.isLoading = false;
      })
      .addCase(fetchEvaluacionGenerarPeriodo.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchClearEvaluacionesGenerarPeriodo.fulfilled, (state, action) => {
        state.mensajeGeneracionPeriodo = {};
      })
      .addCase(fetchEvaluacionNotificacionesAll.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionNotificacionesAll.fulfilled, (state, action) => {
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = 'success';
        } else {
          state.isSuccess = "error"; 
        }
        state.isLoading = false;
      })
      .addCase(fetchEvaluacionNotificacionesAll.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchObtenerEvaluacionEmpleadoHash.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchObtenerEvaluacionEmpleadoHash.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.evaluacionEmpleadoHash = action.payload.data;
        } else {
          state.isSuccess = "error"; 
          state.message = action.error?.message;
        }
        state.isLoading = false;
      })
      .addCase(fetchObtenerEvaluacionEmpleadoHash.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchObtenerCompetenciasComportamientosEvaluacionById.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchObtenerCompetenciasComportamientosEvaluacionById.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.evaluacionCompetenciasComportamientos = action.payload.data;
        } else {
          state.isSuccess = "error"; 
          state.message = action.error?.message;
        }
        state.isLoading = false;
      })
      .addCase(fetchObtenerCompetenciasComportamientosEvaluacionById.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchUpdateCompetenciasComportamientos.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchUpdateCompetenciasComportamientos.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;
        } else {
          state.isSuccess = "error"; 
          state.message = action.error?.message;
        }
        state.isLoading = false;
      })
      .addCase(fetchUpdateCompetenciasComportamientos.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionMetasCrear.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionMetasCrear.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionMetasCrear.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionMetasActualizar.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionMetasActualizar.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionMetasActualizar.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionMetasEliminar.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionMetasEliminar.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionMetasEliminar.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionMetasObtener.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionMetasObtener.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.evaluacionesEmpleadoLogroMetas = action.payload.data;
        } else {
          state.isSuccess = "error"; 
          state.message = action.payload?.message;
          console.error(action.payload?.message);
        }
        state.isLoading = false;
      })
      .addCase(fetchEvaluacionMetasObtener.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionMetasAprobar.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionMetasAprobar.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionMetasAprobar.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionMetasAprobadasObtenerEstado.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionMetasAprobadasObtenerEstado.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.estadoAprobacionMetas = action.payload.data;
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionMetasAprobadasObtenerEstado.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchCrearPlanMejora.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchCrearPlanMejora.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchCrearPlanMejora.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEditarPlanMejora.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEditarPlanMejora.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEditarPlanMejora.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchObtenerPlanMejora.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchObtenerPlanMejora.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.planDeMejora = action.payload.data;
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchObtenerPlanMejora.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionEditarPeriodo.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionEditarPeriodo.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionEditarPeriodo.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionCrearPeriodo.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionCrearPeriodo.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionCrearPeriodo.rejected, (state, action) => {

        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionCerrarPeriodo.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionCerrarPeriodo.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionCerrarPeriodo.rejected, (state, action) => {

        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchReabrirEvaluacion.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchReabrirEvaluacion.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchReabrirEvaluacion.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchCerrarEvaluacion.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchCerrarEvaluacion.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchCerrarEvaluacion.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionEliminarAreaMejora.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionEliminarAreaMejora.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionEliminarAreaMejora.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchEvaluacionEliminarPuntosFuertes.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEvaluacionEliminarPuntosFuertes.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload.message;
      })
      .addCase(fetchEvaluacionEliminarPuntosFuertes.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchPostResultadosValoresFiles.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchPostResultadosValoresFiles.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload?.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload?.message ?? "";
      })
      .addCase(fetchPostResultadosValoresFiles.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      .addCase(fetchDeleteResultadosValoresFiles.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchDeleteResultadosValoresFiles.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload?.isSuccess) {
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
        state.message = action.payload?.message ?? "";
      })
      .addCase(fetchDeleteResultadosValoresFiles.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error?.message)
      })
      ;
  },
});

export const selectListaEvaluaciones = (state) => state.evaluaciones.listaEvaluaciones;
export const selectListaEvaluacionesPorAno = (state) => state.evaluaciones.listaEvaluacionesPorAno;
export const selectEstadoPeriodo = (state) => state.evaluaciones.estado;
export const selectMensajeGeneracionPeriodo = (state) => state.evaluaciones.mensajeGeneracionPeriodo;
export const selectIsLoading = (state) => state.evaluaciones.isLoading;
export const selectMessage = (state) => state.evaluaciones.message;
export const selectIsSuccess = (state) => state.evaluaciones.isSuccess;
export const selectLinkDescarga = (state) => state.evaluaciones.linkDescarga;
export const selectEvaluacionEmpleadoHash = (state) => state.evaluaciones.evaluacionEmpleadoHash;
export const selectEvaluacionLogroMetasEmpleado = (state) => state.evaluaciones.evaluacionesEmpleadoLogroMetas;
export const selectEvaluacionLogroMetasEstado = (state) => state.evaluaciones.estadoAprobacionMetas;
export const selectPlanDeMejora = (state) => state.evaluaciones.planDeMejora;
export const selectCompetenciasComportamientos = (state) => state.evaluaciones.evaluacionCompetenciasComportamientos;

export default EvaluacionesSlice.reducer;
