import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Add from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewEmpleadoHistorialLaboral,
  selectEmpleadosIsLoading,
  selectEmpleadosIsSuccess,
  selectEmpleadosMessage,
} from "../../../redux/Empleados/EmpleadosSlice";
import swal from "sweetalert";
import Loading from "../Loading/Loader";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const HistorialLaboralNuevo = ({
  openHistorialLaboral,
  setOpenHistorialLaboral,
  empleadoId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const isSuccess = useSelector(selectEmpleadosIsSuccess);
  const isLoadingEmpleado = useSelector(selectEmpleadosIsLoading);
  const empleaodMessage = useSelector(selectEmpleadosMessage);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [openLoading, setOpenLoading] = useState(false);

  const onSubmit = (data) => {
    setAddRequestStatus("success");
    dispatch(addNewEmpleadoHistorialLaboral(data));
  };
  const handleClose = () => {
    setOpenHistorialLaboral(false);
  };
  useEffect(() => {
    setValue("empleadoId", Number(empleadoId));
  }, [empleadoId]);

  useEffect(() => {
    if (isSuccess === "success" && addRequestStatus === "success") {
      swal("Exitoso!", "Fue agregado el historial laboral", "success");
      reset({
        fechainicio: "",
        fechaTermino: "",
        institucion: "",
        sueldo: "",
        publica: false,
        observaciones: "",
      });
      handleClose();
      setAddRequestStatus("idle");
    } else if (isSuccess === "error" && addRequestStatus === "success") {
      swal("Error!", empleaodMessage, "error");
      setAddRequestStatus("idle");
    }
  }, [isSuccess, addRequestStatus]);

  useEffect(() => {
    if (!isLoadingEmpleado) {
      setOpenLoading(false);
    } else {
      setOpenLoading(true);
    }
  }, [isLoadingEmpleado]);

  return (
    <Modal
      open={openHistorialLaboral}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 700 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row">
              <h3 className="text-primary-mem text-center">
                Historial Laboral
              </h3>
            </div>
            <div className="row">
              <div className="col-5 me-0 pe-0">
                <hr />
              </div>
              <div className="col-2 text-center">
                <Add className="text-primary-mem"></Add>
              </div>
              <div className="col-5 ms-0 ps-0 ">
                <hr />
              </div>
            </div>
            <div className="row">
              <div hidden>
                <input {...register("empleadoId")}></input>
              </div>
              <div className="form-group">
                <label className="fw-bold">Fecha Inicio</label>
                <Controller
                  control={control}
                  className="mb-3"
                  name="ReactDatepicker"
                  {...register("fechaInicio", {
                    required: "La fecha de inicio es requerida",
                  })}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={" "}
                        mask="__/__/____"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        renderInput={(params) => (
                          <TextField
                            height={10}
                            className="form-control"
                            style={{ height: 10 }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                <p className="text-danger mt-3">
                  {errors.fechaInicio?.message}
                </p>
                <p className="text-danger">{errors.nombres?.message}</p>
              </div>
              <div className="form-group">
                <label className="fw-bold">Fecha Término</label>
                <Controller
                  control={control}
                  className="mb-3"
                  name="ReactDatepicker"
                  {...register("fechaTermino", {
                    required: "La fecha de término es requerida",
                  })}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label={" "}
                        mask="__/__/____"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        renderInput={(params) => (
                          <TextField
                            height={10}
                            className="form-control"
                            style={{ height: 10 }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                <p className="text-danger mt-3">
                  {errors.fechaTermino?.message}
                </p>
                <p className="text-danger">{errors.nombres?.message}</p>
              </div>
              <div className="form-group">
                <label className="fw-bold">Institución</label>
                <input
                  type="text"
                  {...register("institucion", {
                    required: "La institución es requerida",
                  })}
                  className="form-control"
                ></input>
                <p className="text-danger">{errors.institucion?.message}</p>
              </div>
              <div className="form-group">
                <label className="fw-bold">Sueldo</label>
                <input
                  type="text"
                  {...register("sueldo")}
                  className="form-control"
                ></input>
                <p className="text-danger">{errors.sueldo?.message}</p>
              </div>
              <div>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox {...register("publica")} />}
                    label="Pública"
                  />
                </FormGroup>
              </div>
              <div className="form-group">
                <label className="fw-bold">Observaciones</label>

                <textarea
                  {...register("observaciones")}
                  className="form-control"
                ></textarea>
                <p className="text-danger">{errors.parentescoId?.message}</p>
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-primary-mem me-3" id='btn-guardar'>
                  Guardar
                </button>
                <button
                  id='btn-cancelar'
                  type="button"
                  className="btn btn-danger"
                  onClick={handleClose}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </form>
        <Loading open={openLoading}></Loading>
      </Box>
    </Modal>
  );
};

export default HistorialLaboralNuevo;
