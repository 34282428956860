import React, { useState, useEffect } from 'react';
import { Add } from '@mui/icons-material';
import Loading from '../../Components/Modals/Loading/Loader';
import { useSelector, useDispatch } from 'react-redux';
import  Table  from './../../Components/Table/Table';
import AreaOrganizacionalNuevo from '../../Components/Modals/AreaOrganizacional/AreaOrganizacionalNuevo';
import AreaOrganizacionalEditar from '../../Components/Modals/AreaOrganizacional/AreaOrganizacionalEditar';
import swal from 'sweetalert';
import { PermissionData } from '../../context/Component/PermissionData';
import TablePagination from '@mui/material/TablePagination';

import {
	fetchAreasOrganizacionalesLista,
  fetchAreaOrganizacionalEliminar,
  fetchCambiarSuccess,
	selectAllAreasOrganizacionalesLista,
	selectIsLoading,
	selectIsSuccess,
	selectMessage
} from '../../redux/AreaOrganizacional/AreaOrganizacionalSlice';

export const AreaOrganizacionales = () => {

  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  // ? HOOK-SELECTOR
  const isLoading = useSelector(selectIsLoading);
  const areasOrganizacionales = useSelector(selectAllAreasOrganizacionalesLista);
  const dispatch = useDispatch();
  const isSuccess = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);

  // ? HOOK-STATE
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [area, setArea] = useState({});
  const [areasOrganizacionalesListaFilter, setAreasOrganizacionalesListaFilter] = useState([]);
  const [areasOrganizacionalesListadoLenght, setAreasOrganizacionalesListadoLenght] = useState(0);

  // ? HOOK-STATE DE PAGINACION.
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(25);
  const [skipRow, setSkipRow] = useState(0);

  // * FUNCION PARA REFRESCAR LISTA
const refreshList = () =>  dispatch(fetchAreasOrganizacionalesLista());

  const deleteAreaOrganizacional = (area) => {

    swal({
      title: `Esta seguro de eliminar el área organizacional "${area?.nombre.replaceAll("-",'')}"?`,
      text: "Una vez eliminado, no estara nuevamente disponible!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(fetchAreaOrganizacionalEliminar(area?.id))
      }
    });
  };

  useEffect(() => {
    dispatch(fetchAreasOrganizacionalesLista());
  }, []);

  // ? FILTRADO DE LISTA A TRAVES DE USE-EFFECT.
  useEffect(() => {

    let inicio = rowsPerPage * page;
    let final = (rowsPerPage * page) + rowsPerPage;

    // if(empleado.length > 0){
      
    //   // * FILTRADO DE NOMBRE EN EL ARRAY.
    //   const listFilter = listBonoEscolarPorAno.filter((x) =>
    //   removeAccents(x.nombredelEmpleado).toLowerCase().includes(removeAccents(empleado.toLowerCase())));

    //   setListadoBonoEscolarDinamico(listFilter.slice(inicio, final));
    //   setListaBonoEscolarLength(listFilter.length);
    // }
    if(areasOrganizacionales.length > 0){
      
      setAreasOrganizacionalesListaFilter(areasOrganizacionales.slice(inicio, final));
      setAreasOrganizacionalesListadoLenght(areasOrganizacionales.length);
    }

  }, [areasOrganizacionales, page, skipRow, rowsPerPage])  

  // * FUNCION PARA LA PAGINACION
  const handleChangePage = (event, newPage) => {
    setSkipRow(newPage * rowsPerPage)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSkipRow(0);
  };

  useEffect(() => {
    
    if(isSuccess === 'success' && message.length > 0){
      swal("Exitoso!", message, "success");
      setOpenEdit(false);
      setOpen(false)
      refreshList();
      dispatch(fetchCambiarSuccess());
    }
    else if(isSuccess === 'error'){
      swal("Error", message, "error");
      dispatch(fetchCambiarSuccess());
    }

}, [isSuccess])
  

  return (
    <div className="container">
      <div className="row">
        <div className="mt-3">
          <h3 className="text-primary-mem">Áreas Organizacionales</h3>
          <hr />
        </div>

        {!isLoading && areasOrganizacionales && (
          <div className="col-md-12  ">
            <div className="row ">
              <div className="col-md-12 ps-2  text-end">
                {/* <Link to={'/cargos/nuevo'} className="btn btn-primary-mem">
                        Nuevo Cargo <Add />
                    </Link> */}
                <button
                  hidden={!permisosState.ROLE_CREAR_AREAS_ORGANIZACIONALES}
                  type="buttom"
                  className="btn btn-primary-mem"
                  onClick={() => setOpen(true)}
                >
                  Nueva Área Organizacional <Add />
                </button>
              </div>
            </div>
            <div className="row mt-3">

              <Table
                setArea={setArea}
                setOpenEdit={setOpenEdit}
                deleteAreaOrganizacional={deleteAreaOrganizacional}
                rowsColumn={areasOrganizacionalesListaFilter}
              />

              <TablePagination
                component="div"
                count={areasOrganizacionalesListadoLenght}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

            </div>
          </div>
        )}
      </div>

      <AreaOrganizacionalNuevo
        open={open}
        setOpen={setOpen}
      />

      <AreaOrganizacionalEditar
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        area={area}
      />
      
      <Loading open={isLoading} />
    </div>
  );
};

export default AreaOrganizacionales;
