import InputAdornment from "@mui/material/InputAdornment";
import TextField from '@mui/material/TextField';

export const IconTextField = ({ iconStart, InputProps, iconEnd, setValue, valorCondition, value, id, ...props }) => {
    return (
      <TextField
      id={id}
      value={value}
      disabled={!Boolean(valorCondition)}
      onChange={e => setValue(e.target.value)}
        {...props}
        InputProps={{
          ...InputProps,
          startAdornment: iconStart ? (
            <InputAdornment position="start">{iconStart}</InputAdornment>
          ) : null,
          endAdornment: iconEnd ? (
            <InputAdornment position="end">{iconEnd}</InputAdornment>
          ) : null,
        }}
      />
    );
  };