import React, {useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import { Controller } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import swal from 'sweetalert';
import {selectEmpleadosIsSuccess,selectEmpleadosMessage} from "./../../../redux/Empleados/EmpleadosSlice";
import {useSelector} from "react-redux";
import { endpoints } from '../../../redux/config';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};

const DesvincularEmpleado = ({ open, setOpen, empleado, handleDesvincularEmpleado,addRequestStatus,setAddRequestStatus,cleanfilter,cargarEmpleados }) => {
	const { register, handleSubmit, formState: { errors }, control } = useForm({defaultValues:{empleadoId:empleado.id}});

    const empleadosIsSuccess = useSelector(selectEmpleadosIsSuccess);
    const empleadoMessage = useSelector(selectEmpleadosMessage);

	const onSubmit = (empleado) => {
		handleDesvincularEmpleado(empleado);
	};

    useEffect(()=>{
		if(empleadosIsSuccess==='success' && addRequestStatus==='success'){
			swal("Exitoso!", "Fue desvinculado el empleado", "success");
			handleClose();
			setAddRequestStatus('idle');
            cleanfilter();
            cargarEmpleados();
		}else if(empleadosIsSuccess==='error'&& addRequestStatus==='success'){
			swal("Error!", empleadoMessage, "error");
			setAddRequestStatus('idle');
		}


	},[empleadosIsSuccess])

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
		>
			<Box sx={{ ...style, width: 700 }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="container">
						<div className="row">
							<h3 className="text-primary-mem">DESVINCULAR EMPLEADO</h3>
						</div>
						<div className="row">
							<hr />
						</div>
                        <div hidden className='row'>
                            <input {...register("empleadoId")}/>
                        </div>
						<div className="row ">
							<div className="col-md-3 text-center align-self-center">
								<Avatar
									sx={{
										width: 130,
										height: 130,
										marginLeft: 0,
										border: 'solid',
										borderWidth: 1,
										borderColor: '#003876',
                                        borderRadius:3
									}}
									alt={`${empleado.nombres} ${empleado.apellidos} `}
									src={`${endpoints.fetchEmpleadoImagen}${empleado?.codigoEmpleado}.jpg?${new Date().getTime()}`}
									variant="square"
								/>
								<label className="codigoEmpleado">{empleado.codigoEmpleado}</label>
							</div>
							<div className="col-md-9">
								<div className="row text-center text-primary-mem">
									<h3>{`${empleado.nombres} ${empleado.apellidos} `}</h3>
								</div>
								<div className="row">
									<div className="col">
										<div className="form-group">
											<label className="fw-bold">Motivo</label>
											<select {...register("motivo",{required:"El motivo es requerido"})} className="form-control">
												<option value={''}>Elegir Motivo</option>
												<option value={'Renuncia'}>Renuncia</option>
												<option value={'Destitución'}>Destitución</option>
												<option value={'Contrato vencido'}>Contrato vencido</option>
												<option value={'Fallecimiento'}>Fallecimiento</option>
												<option value={'Pensión'}>Pensión</option>
											</select>
                                            <p className='text-danger'>{errors.motivo?.message}</p>
										</div>
									</div>
									<div className="col">
										<div className="form-group">
											<label className="fw-bold">Fecha Desvinculación</label>
											<Controller
												control={control}
                                                className="mb-3"
												name="ReactDatepicker"
												{...register('fecha', {
													required: 'La fecha es requerida'
												})}
												render={({ field }) => (
													<LocalizationProvider dateAdapter={AdapterDateFns}>
														<DatePicker
															label={' '}
															mask="__/__/____"
															value={field.value}
															onChange={(e) => field.onChange(e)}
															renderInput={(params) => (
																<TextField
																	height={10}
																	className="form-control"
																	style={{ height: 10 }}
																	{...params}
																/>
															)}
														/>
													</LocalizationProvider>
												)}
											/>
                                             <p className='text-danger mt-3'>{errors.fecha?.message}</p>
										</div>
									</div>
								</div>
								<div className="row mb-3">
									<div className="form-group">
										<label className="fw-bold">Descripción</label>
										<textarea {...register("comentarios")} rows={4} className="form-control" />
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="d-flex justify-content-end">
								<button type="submit" className="btn btn-primary-mem me-3">
									Desvincular
								</button>
								<button type="button" className="btn btn-danger " onClick={handleClose}>
									Cancelar
								</button>
							</div>
						</div>
					</div>
				</form>
			</Box>
		</Modal>
	);
};

export default DesvincularEmpleado;
