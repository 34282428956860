import React from "react";
import { Route, Routes, Navigate } from "react-router";
import { RequiredPermission } from '../authentication/RequiredPermission';
import { Sorteo, AgregarEmpleadoSorteo } from "../pages/Sorteo";

export const RutasSorteo = () => {
    return (
        <Routes>

            <Route element={<RequiredPermission permission="ROLE_VER_REGISTRO_EMPLEADOS" />}>
                <Route index element={<Sorteo />} />
            </Route>

            <Route element={<RequiredPermission permission='ROLE_VER_AREAS_ORGANIZACIONALES' />}>
                <Route path="/agregarEmpleado" element={<AgregarEmpleadoSorteo />} />
            </Route>

            <Route path='/*' element={<Navigate to="/" replace />} />

        </Routes>
    );
};
