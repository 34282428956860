import React, { useEffect } from 'react'
import { Encabezado } from '../../../Components/Encabezado/Encabezado';
import { IconButtonText } from '../../../Components/IconPersonalize/IconButtonText';
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { selectUserData } from "../../../redux/Login/LoginSlice";
import { fetchListaEvaluaciones, selectListaEvaluaciones } from '../../../redux/Evaluaciones/EvaluacionesSlice';
import moment from 'moment';

export const EvaluacionesPendientes = () => {

  // ? HOOK DISPATCH
  const dispatch = useDispatch();

  // ? HOOK SELECTOR
  const usuario = useSelector(selectUserData);
  const listaEvaluaciones = useSelector(selectListaEvaluaciones);

  // ? HOOK NAVEGACION.
  const navigate = useNavigate();

  // * FUNCION PARA NAVEGACION.
  const NavigateTo = (ano) => {
    navigate(`/evaluaciones/misevaluaciones/${ano}`);
  }

  useEffect(() => {
    dispatch(fetchListaEvaluaciones(usuario?.Id));
  }, [usuario])

  return (
    <Encabezado title={'Mis Evaluaciones'} >
        {
            listaEvaluaciones.map((item, index) => (
                <div className="row mt-2 mx-auto" key={index}>
                    <div className="card mb-2">
                        <div className="row g-0">
                            <div className="col-1 text-center mt-3 mb-3">
                                <p className="card-text fw-bold text-14">Año</p>
                                <h6 className="text-14">{item.anio}</h6>
                            </div>
        
                            <div className="col-3">
                                <div className="text-center mt-3 mb-3">
                                    <p className="card-text fw-bold text-14">Inicio de período:</p>
                                    <h6 className="text-14">{moment(item.iniciodePeriodo).format(`D [de] MMMM`)}</h6>
                                </div>
                            </div>
        
                            <div className="col-2">
                                <div className="text-center mt-3 mb-3">
                                    <p className="card-text fw-bold text-14">Cantidad de Evaluaciones</p>
                                    <h6 className="text-14">{item.cantidadEvaluaciones}</h6>
                                </div>
                            </div>
        
                            <div className="col-2">
                                <div className="text-center text-14 mt-3 mb-3">
                                    <p className="card-text fw-bold">Completadas</p>
                                    <h6 className="text-14">{item.completadas}</h6>
                                </div>
                            </div>
        
                            <div className="col-2">
                                <div className="text-center mt-3 mb-3">
                                    <p className="card-text fw-bold text-14">Terminadas (cerradas)</p>
                                    <h6 className="text-14">{item.terminadasCerradas}</h6>
                                </div>
                            </div>
        
                            <div className="col-2">
                                <div className='mt-2 mb-2 d-flex justify-content-end'>                      
                                    <IconButtonText title={""} Icon={ExitToAppRoundedIcon} marginTopRequired = {true} func = {NavigateTo} params={item.anio}/>
                                </div>
                            </div>   
                        </div>
                    </div>
                </div>
            ))
        }
    </Encabezado>
  )
}
