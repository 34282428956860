import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../../utils/apiClient";
import { endpoints } from "../../config";

const initialState = {
    isLoading: false,
    isSuccess: 'idle',
    message: "",
    bonoEscolarPeriodo: [],
    bonoEscolarPorPeriodo: [],
    estadoPeriodo: 2
}

export const fetchCambiarSuccess = createAsyncThunk("bonoEscolar/fetchCambiarSuccess",
    async () => {
      return null;
    }
);  

export const fetchBonoEscolarPeriodos = createAsyncThunk("bonoEscolar/fetchBonoEscolarPeriodos", 

    async () => {
        var resultado = await axiosApiInstance.get(endpoints.beneficios.fetchBonoEscolarPeriodo);
        return resultado.data;
    }
)

export const fetchBonoEscolarPorPeriodo = createAsyncThunk("bonoEscolar/fetchBonoEscolarPorPeriodo", 
    async (periodo) => {
        var resultado = await axiosApiInstance.get(`${endpoints.beneficios.fetchBonoEscolarPorPeriodo}?periodo=${periodo}`);
        return resultado.data;
    }
)

export const fetchBonoEscolarPorPeriodoAgregar = createAsyncThunk("bonoEscolar/fetchBonoEscolarPorPeriodoAgregar", 
    async (data) => {
        var resultado = await axiosApiInstance.post(endpoints.beneficios.fetchBonoEscolarCrearPeriodo, data);
        return resultado.data;
    }
)

export const fetchBonoEscolarPorPeriodoEditar = createAsyncThunk("bonoEscolar/fetchBonoEscolarPorPeriodoEditar", 
    async (data) => {
        var resultado = await axiosApiInstance.post(endpoints.beneficios.fetchBonoEscolarEditarPeriodo, data);
        return resultado.data;
    }
)

// * ACTUALIZAR BENEFICIARIOS DE UN PERIODO DE BONO ESCOLAR.
export const fetchBonoEscolarPorPeriodoActualizarBeneficiarios = createAsyncThunk("bonoEscolar/fetchBonoEscolarPorPeriodoActualizarBeneficiarios", 
    async (ano) => {
        var resultado = await axiosApiInstance.post(`${endpoints.beneficios.fetchBonoEscolarBeneficiariosActualizar}?periodo=${ano}`);
        return resultado.data;
    }
)

// * CERRAR PERIODO DE UN BONO ESCOLAR.
export const fetchBonoEscolarCerrarPeriodo = createAsyncThunk("bonoEscolar/fetchBonoEscolarCerrarPeriodo", 
    async (ano) => {
        var resultado = await axiosApiInstance.post(`${endpoints.beneficios.fetchBonoEscolarCerrarPeriodo}?periodo=${ano}`);
        return resultado.data;
    }
)

// * EDITAR EL BONO DE UN EMPLEADO.
export const fetchBonoEscolarEmpleadoEditar = createAsyncThunk("bonoEscolar/fetchBonoEscolarEmpleadoEditar", 
    async (data) => {
        var resultado = await axiosApiInstance.post(endpoints.beneficios.fetchBonoEscolarEditarBonoEmpleado, data);
        return resultado.data;
    }
)

// * EDITAR EL BONO DE UN EMPLEADO.
export const fetchBonoEscolarEmpleadoEliminar = createAsyncThunk("bonoEscolar/fetchBonoEscolarEmpleadoEliminar", 
    async (beneficiarioBonoEscolarId) => {
        var resultado = await axiosApiInstance.post(`${endpoints.beneficios.fetchBonoEscolarEliminarBonoEmpleado}?beneficiarioBonoEscolarId=${beneficiarioBonoEscolarId}`);
        return resultado.data;
    }
)

// * OBTENER ESTADO DE X PERIODO
export const fetchObtenerEstadoPeriodo = createAsyncThunk("bonoEscolar/fetchObtenerEstadoPeriodo", 
    async (ano) => {
        var resultado = await axiosApiInstance.get(`${endpoints.beneficios.fetchBonoEscolarEstadoPeriodo}?id=${ano}`);
        return resultado.data;
    }
)

// * GENERAR EXCEL DE UN PERIODO
export const fetchGenerarExcelPeriodoBeneficiarios = createAsyncThunk(
    "bonoEscolar/fetchGenerarExcelPeriodoBeneficiarios",
    async (ano) => {
      const result = await axiosApiInstance({
        url: `${
          endpoints.beneficios.fetchBonoEscolarExportarExcelPeriodo
        }?periodo=${ano}`, //your url
  
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
  
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `Beneficiarios Bono Escolar (${ano}).xls`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
  
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  
      return result;
    }
  );

export const BonoEscolarSlice = createSlice({
    name:"bonoEscolar",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(fetchCambiarSuccess.fulfilled, (state) => {
            state.isSuccess = "idle";
        })
        .addCase(fetchBonoEscolarPeriodos.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchBonoEscolarPeriodos.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.bonoEscolarPeriodo = action.payload.data;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
            }
            state.isLoading = false;
        })
        .addCase(fetchBonoEscolarPeriodos.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message
            state.bonoEscolarPeriodo = [];
        })
        .addCase(fetchBonoEscolarPorPeriodo.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle'; 
        })
        .addCase(fetchBonoEscolarPorPeriodo.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.bonoEscolarPorPeriodo = action.payload.data;
            } else {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
            }
            state.isLoading = false;
        })
        .addCase(fetchBonoEscolarPorPeriodo.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message
            state.bonoEscolarPorPeriodo = [];
        })
        .addCase(fetchBonoEscolarPorPeriodoAgregar.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle'; 
        })
        .addCase(fetchBonoEscolarPorPeriodoAgregar.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
            } else {
                state.isSuccess = 'error'; 
            }
            state.message = action.payload.message;
            state.isLoading = false;
        })
        .addCase(fetchBonoEscolarPorPeriodoAgregar.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message;
        })
        .addCase(fetchBonoEscolarPorPeriodoEditar.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle'; 
        })
        .addCase(fetchBonoEscolarPorPeriodoEditar.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
            } else {
                state.isSuccess = 'error'; 
            }
            state.message = action.payload.message;
            state.isLoading = false;
        })
        .addCase(fetchBonoEscolarPorPeriodoEditar.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message;
        })
        .addCase(fetchBonoEscolarPorPeriodoActualizarBeneficiarios.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle'; 
        })
        .addCase(fetchBonoEscolarPorPeriodoActualizarBeneficiarios.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
            } else {
                state.isSuccess = 'error'; 
            }
            state.message = action.payload.message;
            state.isLoading = false;
        })
        .addCase(fetchBonoEscolarPorPeriodoActualizarBeneficiarios.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message;
        })
        .addCase(fetchBonoEscolarCerrarPeriodo.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle'; 
        })
        .addCase(fetchBonoEscolarCerrarPeriodo.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
            } else {
                state.isSuccess = 'error'; 
            }
            state.message = action.payload.message;
            state.isLoading = false;
        })
        .addCase(fetchBonoEscolarCerrarPeriodo.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message;
        })
        .addCase(fetchBonoEscolarEmpleadoEditar.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle'; 
        })
        .addCase(fetchBonoEscolarEmpleadoEditar.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
            } else {
                state.isSuccess = 'error'; 
            }
            state.message = action.payload.message;
            state.isLoading = false;
        })
        .addCase(fetchBonoEscolarEmpleadoEditar.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message;
        })
        .addCase(fetchBonoEscolarEmpleadoEliminar.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle'; 
        })
        .addCase(fetchBonoEscolarEmpleadoEliminar.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
            } else {
                state.isSuccess = 'error'; 
            }
            state.message = action.payload.message;
            state.isLoading = false;
        })
        .addCase(fetchBonoEscolarEmpleadoEliminar.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message;
        })
        .addCase(fetchObtenerEstadoPeriodo.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchObtenerEstadoPeriodo.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.estadoPeriodo = action.payload.data?.estado;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
            }
            state.isLoading = false;
        })
        .addCase(fetchObtenerEstadoPeriodo.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message
            state.estadoPeriodo = 2;
        })
        .addCase(fetchGenerarExcelPeriodoBeneficiarios.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = "idle";
            state.message = "";
          })
          .addCase(fetchGenerarExcelPeriodoBeneficiarios.fulfilled, (state, action) => {
            state.isSuccess = "success";
            state.message = "El reporte fue generado";
            state.isLoading = false;
          })
          .addCase(fetchGenerarExcelPeriodoBeneficiarios.rejected, (state, action) => {
            state.isSuccess = "error";
            state.message = action.error.message;
            state.isLoading = false;
          })
        ;
    }
})

export const selectBonoEscolarPeriodos = (state) => state.bonoEscolar.bonoEscolarPeriodo;
export const selectBonoEscolarPorPeriodo = (state) => state.bonoEscolar.bonoEscolarPorPeriodo;
export const selectBonoEscolarEstadoPeriodo = (state) => state.bonoEscolar.estadoPeriodo;
export const selectIsLoading = (state) => state.bonoEscolar.isLoading
export const selectIsSuccess = (state) => state.bonoEscolar.isSuccess
export const selectMessage = (state) => state.bonoEscolar.message

export const {} = BonoEscolarSlice.actions;
export default BonoEscolarSlice.reducer;