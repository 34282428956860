import React, { useState, useEffect } from "react";

import { Add } from "@mui/icons-material";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";

import DiaFeriadoNuevo from "../../../Components/Modals/DiasFeriados/DiaFeriadoNuevo";
import DiaFeriadoEditar from "../../../Components/Modals/DiasFeriados/DiaFeriadoEditar";
import DiaFeriadoCard from "./../../../Components/Cards/DiaFeriadoCard";
import Loading from "../../../Components/Modals/Loading/Loader";
import { returArrayAnos } from "./helpers";
import { ListaDeAnos } from "./components/";
import { Encabezado } from '../../../Components/Encabezado/Encabezado';

import {
  fetchDiasFeriados,
  selectIsLoading,
  selectIsSuccess,
  selectMessage,
  selectDiasFeriados,
  DeleteDiaFeriado,
} from "./../../../redux/DiasFeriados";

import { PermissionData } from "../../../context/Component/PermissionData";

export const DiasFeriados = () => {
  
  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  const [open, setOpen] = useState(false);
  const [listadoAnos, setListadoAnos] = useState(returArrayAnos());
  const [openEditar, setOpenEditar] = useState(false);
  const [diaFeriadoSelected, setDiaFeriadoSelected] = useState({});
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [yearSelected, setYearSelected] = useState(new Date().getFullYear());

  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const message = useSelector(selectMessage);
  const isSuccess = useSelector(selectIsSuccess);
  const diasFeriados = useSelector(selectDiasFeriados);

  const editarDiaFeriado = (diaFeriado) => {
    setDiaFeriadoSelected(diaFeriado);
    setOpenEditar(true);
  };

  const eliminarDiaFeriado = (diaFeriado) => {
    swal({
      title: "Esta seguro de eliminar el día feriado?",
      text: "Una vez eliminado, no estara nuevamente disponible!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setAddRequestStatus("success");
        dispatch(DeleteDiaFeriado(diaFeriado));
      } else {
      }
    });
  };

  // ************************************************************* //
  // ******************* COMPONENTES ***************************** //
  // ************************************************************* //

  const DiasFeriados = () => {
    return diasFeriados && diasFeriados.length > 0 ? (
      diasFeriados.map((diaFeriado) => (
        <DiaFeriadoCard
          key={diaFeriado.id}
          diaFeriado={diaFeriado}
          setOpenEditar={setOpenEditar}
          eliminarDiaFeriado={eliminarDiaFeriado}
          editarDiaFeriado={editarDiaFeriado}
        ></DiaFeriadoCard>
      ))
    ) : (
      <h2>No tiene días feriados registrados</h2>
    );
  };

  // ************************************************************* //
  // ************************************************************* //

  useEffect(() => {
    dispatch(fetchDiasFeriados(yearSelected));
  }, [yearSelected]);

  useEffect(() => {
    if (isSuccess === "success" && addRequestStatus === "success") {
      setAddRequestStatus("idle");
      swal("Exitoso!", "Fue eliminado el día feriado", "success");
    } else if (isSuccess === "error" && addRequestStatus === "success") {
      setAddRequestStatus("idle");
      swal("Error!", message, "error");
    }
  }, [isSuccess, addRequestStatus]);

  return (
    <Encabezado title='Días Feriados'>
      <div className="row  mb-2 text-end">
        <div className="ps-2  align-self-center">
          <button
            id="btn-crear-feriado"
            hidden={!permisosState.ROLE_CREAR_FERIADO}
            type="button"
            className="btn btn-primary-mem"
            onClick={() => setOpen(true)}
          >
            <Add/> Nuevo Día Feriado
          </button>
        </div>
      </div>

      <div className=" d-flex justify-content-end">
        <div className="col-1 ">
          <select
            id="select-ano"
            className="form-control"
            defaultValue={yearSelected}
            onChange={(e) => {
              e.preventDefault();
              setYearSelected(e.currentTarget.value);
            }}
          >
            <ListaDeAnos listadoAnos={listadoAnos} />
          </select>
        </div>
      </div>

      <div className="row ms-2">

        <DiasFeriados />

        <DiaFeriadoNuevo 
        open={open} 
        setOpen={setOpen} />

        <DiaFeriadoEditar
          open={openEditar}
          setOpen={setOpenEditar}
          diaFeriadoSelected={diaFeriadoSelected}
        />

        <Loading open={isLoading}></Loading>
      </div>    

    </Encabezado>
  );
};

export default DiasFeriados;
