import React from 'react'
import moment from "moment";

export const Asignaciones = ({asignacion,index}) => {
    const {fechaAsignacion,fechaFinAsignacion,activo,tipoAsignacion}= asignacion;
    const {estado,tipo,equipo,flota,vehiculo} =activo;

    const Descripcion = () => {
        let marca = "";
        let modelo = "";
        let tipoEquipo = "";
    
        marca =
          tipo == "Equipo"
            ? equipo.marca
            : tipo === "Flota"
            ? flota.marca
            : vehiculo.marca;
        modelo =
          tipo == "Equipo"
            ? equipo.modelo
            : tipo === "Flota"
            ? flota.modelo
            : vehiculo.modelo;
        tipoEquipo =
          tipo == "Equipo"
            ? equipo.tipoEquipo
            : tipo === "Flota"
            ? "Celular"
            : "Vehículo";
        return (
          <h6 className="text-primary-mem">{`${marca} ${modelo} (${tipoEquipo})`}</h6>
        );
      };
          

  return (
    <div className="mt-2 pt-3 pb-2 mb-2 border ">
    <div className="row ">
        <div className="col-md-1 text-center align-self-center">
            <h1 className="text-primary-mem">{index+1}</h1>
        </div>
        <div className="col-md-3 align-self-center">
           <Descripcion />
        </div>
        <div className="col-md-2 ">
            <div className="col-md-12 text-center">
                <label className="fw-bold">Tipo</label>
            </div>
            <div className="col-md-12 text-center">
            {/* <label className="">{moment(empleo.fechaInicio).format("DD/MM/yyyy")}</label> */}
            <label className="">{tipoAsignacion}</label>
            </div>
        </div>
        <div className="col-md-2 ">
            <div className="col-md-12 text-center">
                <label className="fw-bold">Fecha Asignación</label>
            </div>
            <div className="col-md-12 text-center">
            <label className="">{moment(fechaAsignacion).format("DD/MM/yyyy")}</label>
            </div>
        </div>
        <div className="col-md-2 ">
            <div className="col-md-12 text-center">
                <label className="fw-bold">Fecha Fin Asignación</label>
            </div>
            <div className="col-md-12 text-center">
            <label className="">{fechaFinAsignacion!=null?moment(fechaFinAsignacion).format("DD/MM/yyyy"):'-'}</label>
            </div>
        </div>
        <div className="col-md-2 ">
            <div className="col-md-12 text-center">
                <label className="fw-bold">Estado</label>
            </div>
            <div className="col-md-12 text-center">
                <label className="">{estado==1?'Activa':'Inactiva'}</label>
            </div>
        </div>
    </div>
</div>
  )
}

export default Asignaciones