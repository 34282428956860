import { Box, Paper, Stack, Divider } from '@mui/material';
import React, { useEffect, useState} from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from "react-use";
import { Encabezado } from '../../Components/Encabezado/Encabezado';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { 
  fetchGetEmpleadosSorteo,
  fetchSaveGanador,
  fetchUpdateList,
  selectListEmpleadosRifa,
  selectUpdateList
} from '../../redux/Sorteo/SorteoSlice';

import fondoImagenInicial from '../../assets/Sorteo2024.jpeg';  
import fondoImagenSecundaria from '../../assets/Sorteo2024Ganador.jpg';  

const INTERVAL = 50;

const NamePicker = ({ empleados, interval, setGanadorSeleccionado, ganadorSeleccionado, imageValidate, setImageValidate }) => {
  const [empleado, setName] = useState(empleados.length > 0 ? empleados[0] : []);
  const [isWinner, setIsWinner] = useState(false);
  const [isRendering, setIsRendering] = useState(false);

  const { width, height } = useWindowSize();

  useEffect(() => {
    let intervalId;

    if (isRendering) {
      setIsWinner(false);
      intervalId = setInterval(() => {
        setName(empleados[Math.floor(Math.random() * empleados.length)]);
      }, interval);

      setTimeout(() => {
        setIsWinner(true);
        setIsRendering(false);
        clearInterval(intervalId);
      }, 5000);
    }

    return () => clearInterval(intervalId);
  }, [isRendering, empleados, interval]);

  useEffect(() => {
    if (isWinner) {
     setGanadorSeleccionado(empleado);
    }
  }, [isWinner]);

  useEffect(() => {
    window.addEventListener('keypress', e => {
      if(!isRendering){
        setIsRendering(true);
      }
    })
  }, [])

  return (
    <div>
      
      {
        Object.keys(ganadorSeleccionado).length === 0 && !isRendering ?
        <>
         
        </>
        :
        <div>
          <h1 style={{textShadow: '2px 2px 4px #f5b558', fontFamily: 'Libre Baskerville, serif, helvetica', marginTop: '25%', color: '#000000'}}>{empleado.nombreLabel} ({empleado.codigoEmpleado})</h1>
          
          <Divider sx={{color: 'rgba(154, 24, 8, 1)', borderBottomWidth: '2px'}} />
        </div>
      }

      <Confetti
        style={isWinner ? { display: 'block' } : { display: 'none' }}
        width={width}
        height={height}
      />
      
      <button id='btn-sorteo' disabled={isRendering | empleados.length < 2} type='submit' style={{textTransform: 'uppercase', marginTop: !imageValidate ? '12.5pc' : '4pc', height: '55px', width: '35%', fontSize: '25px', backgroundColor: '#9a1808', color: 'white'}} className="btn btn-lg rounded-pill" onClick={() => {setIsRendering(!isRendering); setImageValidate(true); }}>
        Iniciar Sorteo
      </button>

    </div>
  );
};

export const Sorteo = () => {

  // ? HOOK-STATE GANADOR
  const [ganadorSeleccionado, setGanadorSeleccionado] = useState({});

  const [imageValidate, setImageValidate] = useState(false);

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const empleadosRifa = useSelector(selectListEmpleadosRifa);
  const updateList = useSelector(selectUpdateList);

  // * FUNCION PARA REFRESCAR LISTA
  const refreshList = () => dispatch(fetchGetEmpleadosSorteo());

  useEffect(() => {
    refreshList();
  }, [])

  useEffect(() => {
    if(Object.keys(ganadorSeleccionado).length > 0){
      dispatch(fetchSaveGanador(ganadorSeleccionado.empleadoId))
    }
  }, [ganadorSeleccionado])
  
  useEffect(() => {

    if(updateList){
      refreshList();
      dispatch(fetchUpdateList());
    }
    
  }, [updateList])
  

  return (
    <Encabezado title='Sorteo' ano={new Date().getFullYear()}>
       <div className="d-flex justify-content-center">
        
        <div className="text-center">
          <Box component='div'>
            <Paper 
            sx={{
              backgroundImage: !imageValidate ? `url(${fondoImagenInicial})` : `url(${fondoImagenSecundaria})`,
              backgroundSize: 'cover', // Asegura que la imagen cubra todo el componente
              backgroundPosition: 'center', // Centra la imagen
              minHeight: '50pc',
              display: 'flex', // Flexbox para centrar el contenido
              justifyContent: 'center', // Centrado horizontal
              alignItems: 'center', // Centrado vertical
              color: 'white', // Color del texto
            }}
            
            elevation={5} variant='outlined'>

              <Box padding={10} height={470} width={1000}>
                
                <Stack mt={10}>
                  <NamePicker 
                  empleados={empleadosRifa} 
                  interval={INTERVAL} 
                  setGanadorSeleccionado={setGanadorSeleccionado} 
                  ganadorSeleccionado={ganadorSeleccionado}
                  setImageValidate={setImageValidate}
                  imageValidate={imageValidate}
                  />
                </Stack>
              </Box>

            </Paper>
          </Box>
        </div>
            
      </div>
    </Encabezado>
  );
};