import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Encabezado } from '../../Components/Encabezado/Encabezado';
import { NominaFiltroBusquedaCard } from '../../Components/Nomina/Card/NominaFiltroBusquedaCard'
import TablePagination from '@mui/material/TablePagination';
import Loading from "../../Components/Modals/Loading/Loader";
import { NominaEmpleadoCardLista } from '../../Components/Nomina/NominaEmpleadoCardLista';
import { Typography } from '@mui/material';
import swal from 'sweetalert';
import { removeAccents } from '../../utils';

import { 
    fetchCambiarSuccess,
    fetchVerificarEstadoEnvioCorreos,
    selectListaNominasEmpleadosFiltrado,
    selectIsLoading,
    selectIsSuccess,
    selectMessage
} from '../../redux/Nominas/NominaSlice';


export const VerNominas = () => {

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-STATE DE PAGINACION.
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(25);
    const [skipRow, setSkipRow] = useState(0);
    const [listaNominaEmpleadosFiltradoDinamico, setlistaNominaEmpleadosFiltradoDinamico] = useState([]);
    const [listaNominaEmpleadosFiltradoLength, setlistaNominaEmpleadosFiltradoLength] = useState(0);
    const [statusRequest, setStatusRequest] = useState('idle');

    // ? HOOK-SELECTOR
    const isLoading = useSelector(selectIsLoading);
    const listaNominaEmpleadosFiltrado = useSelector(selectListaNominasEmpleadosFiltrado);
    const isSuccess = useSelector(selectIsSuccess);
    const message = useSelector(selectMessage);

    // ? HOOK-STATE FILTRO EMPLEADO
    const [empleado, setEmpleado] = useState('');

    // * LIMPIAR SUCCESS
    const clearSuccess = () => dispatch(fetchCambiarSuccess());

    // * VERIFICAR ESTADO DE ENVIO DE CORREOS
    const verificarEstadoEnvioCorreos = () => dispatch(fetchVerificarEstadoEnvioCorreos());

    // ? HOOK-EFFECT PARA MOSTRAR LAS ALERTAS.
    useEffect(() => {

      if(isSuccess === 'success' && message.length > 0){
        swal("Exitoso!", message, "success");
        verificarEstadoEnvioCorreos();
        clearSuccess();
      } 
      else if(isSuccess === 'error'){
        setStatusRequest('idle');
        swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implementación de Sistemas.", "error");
        clearSuccess();
      }

    }, [isSuccess])


    // ? FILTRADO DE LISTA A TRAVES DE USE-EFFECT.
    useEffect(() => {
  
      let inicio = rowsPerPage * page;
      let final = (rowsPerPage * page) + rowsPerPage;
  
      // * FILTRADO POR EMPLEADO.
      if(listaNominaEmpleadosFiltrado.length > 0){

        // * FILTRADO DE NOMBRE EN EL ARRAY.
        const listFilter = listaNominaEmpleadosFiltrado.filter((x) => removeAccents(x.empleado).toLowerCase().includes(removeAccents(empleado.toLowerCase())));

        setlistaNominaEmpleadosFiltradoDinamico(listFilter.slice(inicio, final));
        setlistaNominaEmpleadosFiltradoLength(listFilter.length);
      }
      else if(listaNominaEmpleadosFiltrado.length === 0){
        setlistaNominaEmpleadosFiltradoDinamico([]);
        setlistaNominaEmpleadosFiltradoLength(0);
      }
  
      }, [listaNominaEmpleadosFiltrado, page, skipRow, rowsPerPage, empleado]);  
  

    // * FUNCION PARA LA PAGINACION
    const handleChangePage = (event, newPage) => {
      setSkipRow(newPage * rowsPerPage)
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      setSkipRow(0);
    };

  return (
    <Encabezado title='NÓMINAS'>

      <NominaFiltroBusquedaCard 
      empleado={empleado}
      setEmpleado={setEmpleado}
      setStatusRequest={setStatusRequest}
      listaNominaEmpleadosFiltradoLength={listaNominaEmpleadosFiltrado?.length ?? 0}
      />

      <NominaEmpleadoCardLista 
      listaNominaEmpleadosFiltradoLength={listaNominaEmpleadosFiltrado?.length ?? 0} 
      listaNominaEmpleadosFiltradoDinamico={listaNominaEmpleadosFiltradoDinamico}
      />

      {
        listaNominaEmpleadosFiltrado.length > 0 ?
          <TablePagination
          component="div"
          count={listaNominaEmpleadosFiltradoLength}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        : 
          (listaNominaEmpleadosFiltrado.length === 0 && statusRequest === 'success') &&
            <Typography variant='h6'>No se encontraron empleados.</Typography>
      }

      <Loading open={isLoading} />

    </Encabezado>
  )
}
