import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "./../config";

const initialState = {
  isSuccess: "idle",
  message: "",
  isLoading: false,
  diasFeriados: [],
  diasFeriadosAnoActual: [],
  diaFeriado: {},
};

export const fetchDiasFeriados = createAsyncThunk(
  "diasferiados/fetchDiasFeriados",
  async (ano) => {
    try {
      let resultado = await axiosApiInstance.get(
        `${endpoints.diasFeriados.fetchDiasFeriados}/${ano}`
      );
      return resultado.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addDiaFeriado = createAsyncThunk(
  "diasferiados/addDiaFeriado",
  async (diaFeriado) => {
    try {
      let resultado = await axiosApiInstance.post(
        endpoints.diasFeriados.saveDiaferiado,
        diaFeriado
      );
      return resultado.data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateDiaFeriado = createAsyncThunk(
  "diasferiados/updateDiaFeriado",
  async (diaFeriado) => {
    try {
      let resultado = await axiosApiInstance.post(
        endpoints.diasFeriados.updateDiaferiado,
        diaFeriado
      );
      return resultado.data;
    } catch (error) {
      throw error;
    }
  }
);
export const DeleteDiaFeriado = createAsyncThunk(
  "diasferiados/deleteDiaFeriado",
  async (diaFeriado) => {
    try {
      let resultado = await axiosApiInstance.post(
        endpoints.diasFeriados.deleteDiaferiado,
        diaFeriado
      );
      return resultado.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchListDiasFeriadosAnoActual = createAsyncThunk(
  "diasferiados/fetchListDiasFeriadosAnoActual",
  async () => {
    try {
      let resultado = await axiosApiInstance.get(
        endpoints.diasFeriados.fetchDiasFeriadosAnoActual
      );
      return resultado.data;
    } catch (error) {
      throw error;
    }
  }
);

const DiasFeriadosSlice = createSlice({
  name: "diasferiados",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchDiasFeriados.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
        state.isSuccess = "idle";
      })
      .addCase(fetchDiasFeriados.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.diasFeriados = action.payload.data;
        } else {
          state.diasFeriados = action.payload.data;
          state.isSuccess = "error";
          state.message = action.payload.message;
        }
      })
      .addCase(fetchDiasFeriados.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(fetchListDiasFeriadosAnoActual.pending, (state, action) => {
        state.isLoading = true;
        state.message = "";
        state.isSuccess = "idle";
      })
      .addCase(fetchListDiasFeriadosAnoActual.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.diasFeriadosAnoActual = action.payload.data;
        } else {
          state.diasFeriadosAnoActual = action.payload.data;
          state.isSuccess = "error";
          state.message = action.payload.message;
        }
      })
      .addCase(fetchListDiasFeriadosAnoActual.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(addDiaFeriado.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(addDiaFeriado.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.diasFeriados = [...state.diasFeriados, action.payload.data];
          state.isSuccess = "success";
          state.message = "";
        } else {
          state.isSuccess = "error";
          state.message = action.payload.message;
        }
      })
      .addCase(addDiaFeriado.rejected, (state, action) => {
        state.message = action.error.message;
        state.isLoading = false;
        state.isSuccess = "error";
      })
      .addCase(updateDiaFeriado.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(updateDiaFeriado.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          let filterDiasFeriaddos = state.diasFeriados.filter(
            (x) => x.id !== action.payload.data.id
          );
          state.diasFeriados = [...filterDiasFeriaddos, action.payload.data];
        } else {
          state.isSuccess = "error";
          state.message = action.payload.message;
        }
      })
      .addCase(updateDiaFeriado.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.error.message;
        state.isSuccess = "error";
      })
      .addCase(DeleteDiaFeriado.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(DeleteDiaFeriado.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.diasFeriados = state.diasFeriados.filter(
            (x) => x.id != action.payload.data.id
          );
          state.message = "";
        } else {
          state.message = action.payload.message;
          state.isSuccess = "error";
        }
      })
      .addCase(DeleteDiaFeriado.rejected, (state, action) => {
        state.message = action.error.message;
        state.isLoading = false;
        state.isSuccess = "error";
      });
  },
});

export const selectDiasFeriados = (state) => state.diasFeriados.diasFeriados;
export const selectDiasFeriadosAnoActual = (state) => state.diasFeriados.diasFeriadosAnoActual;
export const selectIsLoading = (state) => state.diasFeriados.isLoading;
export const selectIsSuccess = (state) => state.diasFeriados.isSuccess;
export const selectMessage = (state) => state.diasFeriados.message;

export default DiasFeriadosSlice.reducer;
