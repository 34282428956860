import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../config";
import axiosApiInstance from "../../utils/apiClient";

const initialState = {
  isSuccess: "idle",
  isLoading: false,
  message: "",
  listaPonches: []
};

export const fetchResumenAsistencias = createAsyncThunk(
  "resumenAsistencia/fetchResumenAsistencias",
  async (filtroPonches) => {

    let resultado = await axiosApiInstance.get(
      `${endpoints.reporteAsistencias.resumenAsistencia}?desde=${filtroPonches.Desde}&hasta=${filtroPonches.Hasta}`
    );

    return resultado.data;
  }
);

export const fetchResumenAsistenciasExcel = createAsyncThunk(
  "resumenAsistencia/fetchResumenAsistenciasExcel",
  async (filtroPonches) => {

    // * DESESTRUCTURACION DE LOS DATOS.
    const {Desde, Hasta} = filtroPonches;
    
    const result = await axiosApiInstance({
      url: `${endpoints.reporteAsistencias.resumenAsistenciaExportar}?Desde=${Desde}&Hasta=${Hasta}`, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
     
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `Resumen de Asistencia (${Desde} - ${Hasta}).xls`); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

    });

    return result;
  }
);

export const fetchResumenLimpiar = createAsyncThunk(
  "resumenLimpiar/fetchResumenLimpiar",
  async () => {
    return [];
  }
);

export const fetchCambiarSuccess = createAsyncThunk(
  "resumenAsistencia/fetchCambiarSuccess",
  async () => {
    return null;
  }
);

const ResumenAsistenciasSlice = createSlice({
  name: "resumenAsistencias",
  initialState,
  extraReducers(builder) {
    builder

      .addCase(fetchResumenAsistencias.pending, (state) => {
        state.isSuccess = "idle";
        state.message = "";
        state.isLoading = true;
      })
      .addCase(fetchResumenAsistencias.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.message = "";
          state.isLoading = false;
          state.listaPonches = action.payload.data;
        } else {
          state.isSuccess = "error";
          state.message = action.payload.message;
          state.isLoading = false;
          state.listaPonches = [];
        }
      })
      .addCase(fetchResumenAsistencias.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = action.payload.error.message;
        state.listaPonches = [];
        state.isLoading = false;
      })
      .addCase(fetchResumenLimpiar.fulfilled, (state) => {
        state.listaPonches = [];
      })

      .addCase(fetchResumenAsistenciasExcel.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchResumenAsistenciasExcel.fulfilled, (state, action) => {
          state.message = "";
          state.isLoading = false;
          state.isSuccess = "success";
  
      })
      .addCase(fetchResumenAsistenciasExcel.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = action.payload.error.message;
        state.isLoading = false;
      })
      .addCase(fetchCambiarSuccess.fulfilled, (state) => {
        state.isSuccess = "idle";
    })
  },
});

export const selectListadoPonches = (state) => state.resumenAsistencias.listaPonches;
export const selectIsLoading = (state) => state.resumenAsistencias.isLoading;
export const selectMessage = (state) => state.resumenAsistencias.message;
export const selectIsSuccess = (state) => state.resumenAsistencias.isSuccess;

export default ResumenAsistenciasSlice.reducer;
