import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Divider, TextField, Typography } from '@mui/material';
import { Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useForm } from "react-hook-form";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import Grid from '@mui/material/Grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import SaveIcon from '@mui/icons-material/Save';
import { CompetenciasTab } from './CompetenciasTab'
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { IoDocumentAttach } from "react-icons/io5";
import moment from 'moment';
import swal from 'sweetalert';
import { IconButton } from '@mui/material';

import { 
  fetchCrearPlanMejora,
  fetchEditarPlanMejora,
  fetchEvaluacionEliminarAreaMejora,
  fetchEvaluacionEliminarPuntosFuertes,
  selectPlanDeMejora,
} from '../../../redux/Evaluaciones/EvaluacionesSlice';

import { 
  VerListaArchivosModal,
  CargarArchivosPDFModal
} from '../../../Components/EvaluacionDesempeño/Modal';

import { PermissionData } from "../../../context/Component/PermissionData";

import { ViewPDFGlobal } from '../../../Components/PDFViewer/ViewPDFGlobal';

// * ESTILO MULTILINEA EN TEXTO.
let textMultiline = {
  width: '100px', 
  wordBreak: 'break-all'
};

export const EvaluacionTabs = 
({ 
  grupoOcupacionalId,
  estado,
  periodo, 
  setLogroMetasAdd, 
  logroMetasAdd, 
  listLogroMetasUsuario, 
  setAddRequestStatus, 
  editEnabled,                      
  setEditEnabled,
  deleteLogroMetas,
  aprobarLogroMetas,
  validacionEstadoLogroMetas,
  ponderacionGrupoOrganizacional,
  evaluacionId,
  validatioUserLoginEvaluador,
  hash,
  listaCompetenciasComportamientosMod,
  setListaCompetenciasComportamientosMod
}) => {
  
  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  // ? HOOK-SELECTOR
  const planDeMejoraData = useSelector(selectPlanDeMejora);

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  const { control } = useForm();

  // ? HOOK-STATE

  // ? PARA SELECCIONAR EL TIPO DE PESTAÑA.
  const [value, setValue] = useState('1');

  // ? HOOK-STATE PARA HABILITAR BOTON EN CASO DE QUE LA PONDERACION SEA IGUAL AL EQUIVALENTE DE LOGRO DE METAS
  // ? SEGUN EL AREA ORGANIZACIONAL.
  const [disabledButtonAprobacionLogroMetas, setDisabledButtonAprobacionLogroMetas] = useState(false);

  // ? PARA ALMACENAR LOS CALCULOS.
  const [calcLogroDeMetas, setCalcLogroDeMetas] = useState({
    totalPonderacionMetas: 0,
    totalCalificacion: 0
  })

  // ? HOOK-STATE PLAN DE MEJORA Y DESARROLLO PERSONAL
  const [addPlanDeMejora, setAddPlanDeMejora] = useState({
  recomendacionesFormacion: "",
  recomendacionesOtras: "",
  distorsionDesempeno: "",
  cuestionesMotivacion: "",
  comentariosEvaluado: "",
  conforme: 0,
  evaluacionId: 0,
  evaluacionPlanDesarrolloId: 0,
  puntosFuertes: [],
  areasMejoras: []
  });

  // ? ARRAYS DEL PLAN DE MEJORA Y DESARROLLO PERSONAL
  const [puntosFuertesArr, setPuntosFuertesArr] = useState([{puntoFuerteId: 0, texto: ''}]);
  const [areasMejorasArr, setAreasMejorasArr] = useState([{areaMejoraId: 0, texto: ''}]);

  // ? HOOK-STATE PARA VALIDAR PETICION DE ENVIO DE DATA DEL FORMULARIO DE PLAN DE MEJORA.
  const [addRequestStatusPlanMejora, setAddRequestStatusPlanMejora] = useState('idle');

  // ? HOOK-STATE CARGA DE ARCHIVOS DE LOGRO DE METAS
  const [cargaArchivosEnabled, setCargaArchivosEnabled] = useState({ 
    logroMeta: '',
    logroMetaId: '',
    files: [],
    enabled: false
  })

  // ? HOOK-STATE PARA HABILITAR CARGA DE ARCHIVOS
  const [uploadFilesPDF, setUploadFilesPDF] = useState(false);

  // ? HOOK-STATE PARA HABILITAR LA VISTA DEL PDF.
  const [pdfViewEnabled, setPdfViewEnabled] = useState({
    enabled: false,
    file: {}
  });

  // * FUNCION PARA CAMBIAR DE PESTAÑA.
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // * FUNCION PARA AGREGAR DATA AL USE-STATE.
  const addData = (name, value) => {
    setLogroMetasAdd({ ...logroMetasAdd, [name]: value });
  }

  // * FUNCION PARA AGREGAR DATA AL USE-STATE PARA LA ACCION EDITAR.
  const addDataEdit = (value) => {
    setLogroMetasAdd({ ...logroMetasAdd, ...value });
    setEditEnabled(true);
  }

  // * FUNCION PARA AÑADIR DATA AL HOOK-STATE DE PLAN DE MEJORA Y DESARROLLO PERSONAL.
  const addDataPlanMejoraDesarrollo = (e) => {

    const {name, value} = e.target;
    setAddPlanDeMejora({...addPlanDeMejora, [name]: value});
  }

  // * FUNCION PARA INSERTAR UNA NUEVA POSICION EN EL ARRAY.
  const insertObjectOnArrayPuntosFuertes = () => {
    setPuntosFuertesArr([...puntosFuertesArr, {puntoFuerteId: 0, texto: ''}]);
  }

  const insertObjectOnArrayAreasMejoras = () => {
    setAreasMejorasArr([...areasMejorasArr, {areaMejoraId: 0, texto: ''}]);
  }

  // * FUNCION PARA ELIMINAR POSICION DEL ARRAY.
  const deleteObjectOnArrayPuntosFuertes = (index, puntoFuerteId) => {

    if(puntoFuerteId === 0){
      setPuntosFuertesArr([...puntosFuertesArr.slice(0, index), ...puntosFuertesArr.slice(index + 1)]);
    }
    else if(puntoFuerteId > 0){
      swal({
        title: 'Eliminar',
        text: 'Estás seguro/a de eliminar este Punto Fuerte?',
        icon: 'warning',
        buttons: ['No', 'Si']
        }).then(respuesta => {
            if(respuesta){
              dispatch(fetchEvaluacionEliminarPuntosFuertes(puntoFuerteId));
            }
        })
    }
    
  }

  const deleteObjectOnArrayAreasMejoras = (index, areaMejoraId) => {

    if(areaMejoraId === 0){
      setAreasMejorasArr([...areasMejorasArr.slice(0, index), ...areasMejorasArr.slice(index + 1)]);
    }
    else if(areaMejoraId > 0){
        swal({
          title: 'Eliminar',
          text: 'Estás seguro/a de eliminar esta Área de Mejora?',
          icon: 'warning',
          buttons: ['No', 'Si']
          }).then(respuesta => {
              if(respuesta){
                  dispatch(fetchEvaluacionEliminarAreaMejora(areaMejoraId));
              }
          })
    }
    
  }

  // * FUNCIONES QUE CAMBIAN LA DATA DE UNA POSICION.
  const changePositionOnArrayPuntosFuertes = (indexMod, e) => {
    let arrChanged = puntosFuertesArr.map((item, index2) => index2 === indexMod ? { ...item, ['texto']: e.target.value} : item );
    setPuntosFuertesArr(arrChanged, ...puntosFuertesArr);
  }

  const changePositionOnArrayAreasMejoras = (indexMod, e) => {
    let arrChanged = areasMejorasArr.map((item, index2) => index2 === indexMod ? { ...item, ['texto']: e.target.value} : item );
    setAreasMejorasArr(arrChanged, ...areasMejorasArr);
  }

  // ? EFFECT PARA ACTUALIZAR LOS CALCULOS.
  useEffect(() => {

      if(cargaArchivosEnabled.enabled){
        setCargaArchivosEnabled({...cargaArchivosEnabled, files: listLogroMetasUsuario.find(x => x.resultadoId === cargaArchivosEnabled.logroMetaId).files ?? []})
      }
    
      const calcPonderacionMetas = listLogroMetasUsuario.reduce(
          (contador, item) => contador + item?.ponderacion,
          0
      );

      const calcCalificacion = listLogroMetasUsuario.reduce(
        (contador, item) => contador + item?.calificacion,
        0
      );

      setDisabledButtonAprobacionLogroMetas(calcPonderacionMetas === ponderacionGrupoOrganizacional ? true : false);
      setCalcLogroDeMetas({...calcLogroDeMetas, ['totalPonderacionMetas']: calcPonderacionMetas, ['totalCalificacion']: calcCalificacion});

  }, [listLogroMetasUsuario])

  useEffect(() => {
    
    if(addRequestStatusPlanMejora === 'success'){

      const planMejoraComplete = {...addPlanDeMejora, areasMejoras: areasMejorasArr, puntosFuertes: puntosFuertesArr, evaluacionId: evaluacionId};

      if(planMejoraComplete.evaluacionPlanDesarrolloId === 0){
        dispatch(fetchCrearPlanMejora(planMejoraComplete));
      }
      else{
        dispatch(fetchEditarPlanMejora(planMejoraComplete));
      }
      
      setAddRequestStatusPlanMejora('idle');
    }

  }, [addRequestStatusPlanMejora])

  useEffect(() => {

    if(planDeMejoraData?.evaluacionPlanDesarrolloId > 0){

      setAddPlanDeMejora({...addPlanDeMejora, ...planDeMejoraData});
      setPuntosFuertesArr(planDeMejoraData.puntosFuertes);
      setAreasMejorasArr(planDeMejoraData.areasMejoras)
    }
    else{
      setAddPlanDeMejora({
        recomendacionesFormacion: "",
        recomendacionesOtras: "",
        distorsionDesempeno: "",
        cuestionesMotivacion: "",
        comentariosEvaluado: "",
        conforme: 0,
        evaluacionId: 0,
        evaluacionPlanDesarrolloId: 0,
        puntosFuertes: [],
        areasMejoras: []
      });

      setPuntosFuertesArr([]);
      setAreasMejorasArr([]);
    }

  }, [planDeMejoraData, hash])

  useEffect(() => {
    
    if(puntosFuertesArr.length === 0){
      setPuntosFuertesArr([...puntosFuertesArr, {puntoFuerteId: 0, texto: ''}]);
    }

  }, [puntosFuertesArr])
  

  useEffect(() => {
    
    if(areasMejorasArr.length === 0){
      setAreasMejorasArr([...areasMejorasArr, {areaMejoraId: 0, texto: ''}]);
    }

  }, [areasMejorasArr])

  // * VALIDACION DE PERIODO.
  const validacionPeriodo = periodo < 2023 ? true : false;

  // * ESTILO DEL COMPONENTE PAPER.
  const paperStyle = {
    padding: 1.5, 
    textAlign: 'center'
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Logro de Metas" value="1" />
            <Tab hidden={periodo < 2025} label="Competencias" value="2" />
            <Tab label="Régimen ético y disciplinario" value="3" hidden={!validacionPeriodo} />
            <Tab label="Plan de mejora y desarrollo personal" value="4" />
          </TabList>
        </Box>

        <TabPanel value="1">

        {/**********************************************************************************************************************************************/}
        {/*******************************************************- Formato VIEJO 2022 INFERIOR -********************************************************/}
        {/**********************************************************************************************************************************************/}

          {
            validacionPeriodo ?

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width="5%"><Typography fontWeight='bold' fontSize={14}>No.</Typography></TableCell>
                            <TableCell align="center" width="20%"><Typography fontWeight='bold' fontSize={14}>Metas del/ de la Servidor/a <br></br> ¿Qué tengo que lograr?</Typography></TableCell>
                            <TableCell align="center" width="10%"><Typography fontWeight='bold' fontSize={14}>Indicador <br></br> ¿Cuánto tengo que lograr?</Typography></TableCell>
                            <TableCell align="center" width="12%"><Typography fontWeight='bold' fontSize={14}>Indicador <br></br>  ¿Cuándo tengo que lograrlo?</Typography></TableCell>
                            <TableCell align="center" width="10%"><Typography fontWeight='bold' fontSize={14}>Ponderación Metas</Typography></TableCell>
                            <TableCell align="center" width="10%"><Typography fontWeight='bold' fontSize={14}>Calificación otorgada</Typography></TableCell>
                            <TableCell align="center" width="20%"><Typography fontWeight='bold' fontSize={14}>Medios de Verificación y Evidencias <br></br> Observaciones</Typography></TableCell>
                            <TableCell align="center" width="3%"></TableCell>
                        </TableRow>
                    </TableHead>
      
                    <TableBody>
              
                        <TableRow
                        hidden={editEnabled}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                          </TableCell>

                          <TableCell component="th" scope="row">
                            <TextField label=' ' name='que' value={logroMetasAdd.que} onChange={(e) => addData(e.target.name, e.target.value) } sx={{width: '100%'}} />
                          </TableCell>
        
                          <TableCell component="th" scope="row">
                            <TextField label=' ' name='cuanto' type='number' value={logroMetasAdd.cuanto} onChange={(e) => addData(e.target.name, e.target.value)} />   
                          </TableCell>
        
                          <TableCell component="th" scope="row">
        
                            <Controller
                              control={control}
                              name="cuando"
                              render={({ field }) => (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DatePicker
                                    label={" "}
                                    mask="__/__/____"
                                    value={logroMetasAdd.cuando}
                                    onChange={(e) => addData('cuando', e)}
                                    renderInput={(params) => (
                                      <TextField
                                        className="form-control"
                                        {...params}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
          
                          </TableCell>
        
                          <TableCell component="th" scope="row">
                              <TextField label=' ' name='ponderacion' type='number'  value={logroMetasAdd.ponderacion} onChange={(e) => addData(e.target.name, e.target.value)} />
                          </TableCell>
                          
                          <TableCell component="th" scope="row">
                              <TextField disabled label=' ' name='calificacion' type='number' value={logroMetasAdd.calificacion} onChange={(e) => addData(e.target.name, e.target.value)} />
                          </TableCell>
        
                          <TableCell component="th" scope="row">
                              <TextField label=' ' sx={{width: '100%', marginRight: 2}} value={logroMetasAdd.medioVerificacion} name='medioVerificacion' onChange={(e) => addData(e.target.name, e.target.value)} />
                          </TableCell>
        
                          <TableCell>
                              <button className='btn btn-success' onClick={() => setAddRequestStatus('success')} disabled={!validatioUserLoginEvaluador} >
                                  <DoneOutlineIcon />
                              </button>
                          </TableCell>
      
                        </TableRow>

                          {
                            listLogroMetasUsuario.map((item, index) => (                            

                              <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  
                                  {
                                    item.resultadoId === logroMetasAdd.resultadoId ?

                                    <>
                                        <TableCell component="th" scope="row" >
                                            <Typography fontWeight='bold'>{index + 1}</Typography>
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                          <TextField label=' ' value={logroMetasAdd.que}  name='que' onChange={(e) => addData(e.target.name, e.target.value)} />
                                        </TableCell>
                    
                                        <TableCell component="th" scope="row">
                                          <TextField label=' ' value={logroMetasAdd.cuanto} type='number' name='cuanto' onChange={(e) => addData(e.target.name, e.target.value)}  />   
                                        </TableCell>
                    
                                        <TableCell component="th" scope="row">
              
                                          <Controller
                                            control={control}
                                            name="cuando"
                                            onChange={(e) => addData(e.target.name, e.target.value)}
                                            render={({ field }) => (
                                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                  label={" "}
                                                  mask="__/__/____"
                                                  value={Date.parse(logroMetasAdd.cuando)}
                                                  renderInput={(params) => (
                                                    <TextField
                                                      className="form-control"
                                                      {...params}
                                                    />
                                                  )}
                                                />
                                              </LocalizationProvider>
                                            )}
                                          />
                  
                                      
                                        </TableCell>
              
                                        <TableCell component="th" scope="row">
                                            <TextField label=' ' name='ponderacion' type='number' onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.ponderacion} />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <TextField label=' ' name='calificacion' type='number' onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.calificacion}  />
                                        </TableCell>
                      
                                        <TableCell component="th" scope="row">
                                            <TextField label=' ' sx={{width: '100%', marginRight: 2}} name='medioVerificacion' onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.medioVerificacion}  />
                                        </TableCell>
                      
                                        <TableCell>

                                        <button className='btn btn-success mb-2' onClick={() => setAddRequestStatus('success')} disabled={!validatioUserLoginEvaluador}>
                                          <DoneOutlineIcon />
                                        </button>
                                  
                                        <button className='btn btn-danger' onClick={() => deleteLogroMetas(item)} disabled={!validatioUserLoginEvaluador}>
                                            <DeleteForeverIcon />
                                        </button>

                                      </TableCell>
                                    </>

                                    :

                                      <>

                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>

                                        <TableCell component="th" scope="row" className='text-center'>
                                        <Typography>{item.que}</Typography>
                                        </TableCell>
                    
                                        <TableCell component="th" scope="row" className='text-center'>
                                          <Typography>{item.cuanto}</Typography>
                                        </TableCell>
                    
                                        <TableCell component="th" scope="row" className='text-center'>          
                                          <Typography>{moment(item.cuando).format(`DD/MM/YYYY`)}</Typography>
                                        </TableCell>
              
                                        <TableCell component="th" scope="row" className='text-center'>
                                          <Typography>{item.ponderacion}</Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row" className='text-center'>
                                          <Typography>{item.calificacion}</Typography>
                                        </TableCell>
                      
                                        <TableCell component="th" scope="row" className='text-center'>
                                          <Typography>{item.medioVerificacion}</Typography>
                                        </TableCell>
                      
                                        <TableCell>

                                        
                                        <button className='btn btn-warning mb-2' onClick={() => addDataEdit(item)} disabled={!validatioUserLoginEvaluador}>
                                          <EditIcon />
                                        </button> 
                                  
                                        <button className='btn btn-danger' onClick={() => deleteLogroMetas(item)} disabled={!validatioUserLoginEvaluador}>
                                            <DeleteForeverIcon />
                                        </button>

                                      </TableCell>
                                      
                                      </>

                                  }
            
                              </TableRow>
                            ))
                          }
      
                        <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell />
                          
                            <TableCell><Typography fontWeight='bold' fontSize={14}>Ponderación Total</Typography></TableCell>
                            <TableCell align="center"><Typography fontSize={14}>{calcLogroDeMetas.totalPonderacionMetas}</Typography></TableCell>
                            <TableCell align="center"><Typography fontSize={14}>{calcLogroDeMetas.totalCalificacion}</Typography></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            : periodo === 2023 ?
                
              <TableContainer component={Paper}>

                {/*************************************************************************************************************************************/}
                {/*******************************************************- Formato NUEVO 2023 -********************************************************/}
                {/*************************************************************************************************************************************/}

                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                          <TableRow>
                              <TableCell width="5%"><Typography fontWeight='bold' fontSize={14}>No.</Typography></TableCell>
                              <TableCell align="center" width="20%"><Typography fontWeight='bold' fontSize={14} sx={{marginBottom: -2}}>Metas del/ de la Servidor/a </Typography>
                              <Typography fontSize={14}>
                              <br></br> ¿Qué tengo que lograr?<br></br> 
                                ¿Cuánto tengo que lograr?<br></br> ¿Cuándo tengo qué lograrlo y con cuáles característica de calidad?
                              </Typography>
                              </TableCell>
                              <TableCell align="center" width="10%"><Typography fontWeight='bold' fontSize={14}>Ponderación Metas</Typography></TableCell>
                              <TableCell align="center" width="17%"><Typography fontWeight='bold' fontSize={14}>Medios de Verificación y Evidencias</Typography></TableCell>
                              <TableCell align="center" width="10%"><Typography fontWeight='bold' fontSize={14}>Calificación otorgada</Typography></TableCell>
                              <TableCell align="center" width="20%"><Typography fontWeight='bold' fontSize={14}>Observaciones</Typography></TableCell>
                              <TableCell align="center" width="10%"></TableCell>
                          </TableRow>
                      </TableHead>
        
                      <TableBody>
                          <TableRow
                          hidden={editEnabled || validacionEstadoLogroMetas}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                              <TableCell component="th" scope="row">
                                 
                              </TableCell>

                              <TableCell component="th" scope="row">
                                  <TextField multiline minRows={1} maxRows={5} label=' ' sx={{width: '100%'}} onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.que}  name='que' />
                              </TableCell>
            
                              <TableCell component="th" scope="row">
                                <TextField multiline minRows={1} maxRows={1} label=' ' type='number'  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.ponderacion}  name='ponderacion' />
                              </TableCell>

                              <TableCell component="th" scope="row">
                                  <TextField multiline minRows={1} maxRows={5} label=' ' sx={{width: '100%'}} onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.medioVerificacion}  name='medioVerificacion' />
                              </TableCell>

                              <TableCell component="th" scope="row">
                                  <TextField multiline minRows={1} maxRows={1}  label=' ' type='number'  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.calificacion} disabled name='calificacion' />
                              </TableCell>
            
                              <TableCell component="th" scope="row">
                                  <TextField multiline minRows={1} maxRows={5} label=' '  sx={{width: '100%', marginRight: 2}}  value={logroMetasAdd.observaciones}  onChange={(e) => addData(e.target.name, e.target.value)}  name='observaciones' />
                              </TableCell>
            
                              <TableCell>
                                 <div className='d-grid'>
                                    <button className='btn btn-success' onClick={() => setAddRequestStatus('success')} hidden={validacionEstadoLogroMetas} disabled={!validatioUserLoginEvaluador}>
                                        <DoneOutlineIcon />
                                    </button>
                                 </div>
                              </TableCell>
        
                          </TableRow>

                          {
                            listLogroMetasUsuario.map((item, index) => (

                            <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                              {
                                item.resultadoId === logroMetasAdd.resultadoId ?    
                                
                      
                                  <>
                                    <TableCell component="th" scope="row">
                                        <Typography fontWeight='bold'>{index + 1}</Typography>
                                    </TableCell>
        
                                    <TableCell component="th" scope="row">
                                        <TextField multiline minRows={1} maxRows={5} label=' ' sx={{width: '100%'}}  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.que}  name='que' disabled={validacionEstadoLogroMetas} />
                                    </TableCell>
                  
                                    <TableCell component="th" scope="row">
                  
                                    <TextField multiline minRows={1} maxRows={1} label=' ' type='number'  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.ponderacion}  name='ponderacion' disabled={validacionEstadoLogroMetas} />
                                    
                                    </TableCell>
        
                                    <TableCell component="th" scope="row">
                                        <TextField multiline minRows={1} maxRows={5} label=' ' onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.medioVerificacion}  name='medioVerificacion' disabled={validacionEstadoLogroMetas} />
                                    </TableCell>
        
                                    <TableCell component="th" scope="row">
                                        <TextField multiline minRows={1} maxRows={1} label=' ' type='number'  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.calificacion}  name='calificacion' />
                                    </TableCell>
                  
                                    <TableCell component="th" scope="row">
                                        <TextField   multiline minRows={1} maxRows={5}label=' '  sx={{width: '100%', marginRight: 2}}  value={logroMetasAdd.observaciones}  onChange={(e) => addData(e.target.name, e.target.value)}  name='observaciones' disabled={validacionEstadoLogroMetas} />
                                    </TableCell>
                  
                                    <TableCell>
                                        <button className='btn btn-success' onClick={() => setAddRequestStatus('success')} disabled={!validatioUserLoginEvaluador}>
                                            <DoneOutlineIcon />
                                        </button>
                                    </TableCell>
            
                                  </>

                                : 

                                  <>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
        
                                    <TableCell align='center' component="th" scope="row">
                                       <Typography component='span' style={textMultiline}>{item.que}</Typography>
                                    </TableCell>
                  
                                    <TableCell align='center' component="th" scope="row">
                                      <Typography>{item.ponderacion}</Typography>
                                    </TableCell>
        
                                    <TableCell width={12} align='center' component="th" scope="row">
                                        <Typography component='span' style={textMultiline}>{item.medioVerificacion}</Typography>
                                    </TableCell>
        
                                    <TableCell align='center' component="th" scope="row">
                                        <Typography>{item.calificacion}</Typography>      
                                    </TableCell>
                  
                                    <TableCell align='center' component="th" scope="row">
                                        <Typography component='span' style={textMultiline}>{item.observaciones}</Typography>
                                    </TableCell>
                  
                                    <TableCell>
                                        <div className='d-flex'>
                                          <button hidden={estado === 2} className='btn btn-warning me-2' onClick={() => addDataEdit(item)} disabled={!validatioUserLoginEvaluador}>
                                            <EditIcon />
                                          </button> 
                                    
                                          <button className='btn btn-primary-mem me-2' onClick={() => setCargaArchivosEnabled({enabled: true, logroMeta: item.que, logroMetaId: item.resultadoId, files: item.files})} disabled={estado === 0 ? !validatioUserLoginEvaluador : permisosState.ROLE_ADMIN === true ? !permisosState.ROLE_ADMIN : !permisosState.ROLE_ADMIN_RRHH}>
                                            <IoDocumentAttach fontSize={22} />
                                          </button> 

                                          <button className='btn btn-danger' onClick={() => deleteLogroMetas(item)} hidden={validacionEstadoLogroMetas} disabled={!validatioUserLoginEvaluador} >
                                              <DeleteForeverIcon />
                                          </button>
                                        </div>
                                    </TableCell>
              
                                  </>
                              
                              }

                          </TableRow>      

                        ))}
                          
                          <TableRow>

                              <TableCell />
                              <TableCell align='center'><Typography fontWeight='bold' fontSize={14}>Sumatoria</Typography></TableCell>
                              <TableCell align='center'><Typography fontWeight='bold' fontSize={14}>{calcLogroDeMetas.totalPonderacionMetas}</Typography></TableCell>
                              <TableCell align='center'><Typography fontWeight='bold' fontSize={14}>Sumatoria</Typography></TableCell>
                              <TableCell align="center"><Typography fontWeight='bold' fontSize={14}>{calcLogroDeMetas.totalCalificacion}</Typography></TableCell>
                              <TableCell />
                              <TableCell >

                                  <div className='d-grid'>
                                    <button className='btn btn-primary' hidden={validacionEstadoLogroMetas} disabled={!disabledButtonAprobacionLogroMetas} onClick={() => aprobarLogroMetas()}><DoneAllIcon sx={{marginRight: 0.5}} disabled={!validatioUserLoginEvaluador} />Aprobar</button>
                                  </div>

                              </TableCell>
                          </TableRow>

                      </TableBody>
                  </Table>
              </TableContainer>

              : (periodo >= 2024 && grupoOcupacionalId !== 5) ?

              <TableContainer component={Paper}>

                {/**********************************************************************************************************************************************************/}
                {/*******************************************************- Formato 2024 PARA GRUPO I, II, III y IV -********************************************************/}
                {/**********************************************************************************************************************************************************/}

                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                          <TableRow>
                              <TableCell width="5%"><Typography fontWeight='bold' fontSize={14}>No.</Typography></TableCell>
                              <TableCell align="center" width="20%">
                                <Typography fontWeight='bold' fontSize={14}>Metas del/ de la Servidor/a </Typography>
                                <Typography fontWeight='bold' fontSize={14}>Redactar siguiendo las características SMART: </Typography>
                                <Typography fontWeight='bold' fontSize={14}>Específica, Medible, Alcanzable, Realista y con Horizonte de Tiempo: </Typography>
                                <Typography fontSize={14}>
                                <br></br> ¿Qué tengo que lograr?<br></br> 
                                  ¿Cuánto tengo que lograr?<br></br> ¿Cuándo tengo qué lograrlo y con cuáles característica de calidad?
                                </Typography>
                              </TableCell>
                              <TableCell align="center" width="10%"><Typography fontWeight='bold' fontSize={14}>Ponderación o Valor en puntos de las Metas</Typography></TableCell>
                              <TableCell align="center" width="17%"><Typography fontWeight='bold' fontSize={14}>Medios de Verificación o Evidencias de logro de cada Meta</Typography></TableCell>
                              <TableCell align="center" width="10%"><Typography fontWeight='bold' fontSize={14}>Calificación otorgada</Typography></TableCell>
                              <TableCell align="center" width="20%"><Typography fontWeight='bold' fontSize={14}>Observaciones/ Comentarios del Evaluador/a</Typography></TableCell>
                              <TableCell align="center" width="10%"></TableCell>
                          </TableRow>
                      </TableHead>
        
                      <TableBody>
                          <TableRow
                          hidden={editEnabled || validacionEstadoLogroMetas}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                              <TableCell component="th" scope="row">
                                 
                              </TableCell>

                              <TableCell component="th" scope="row">
                                  <TextField multiline minRows={1} maxRows={5} label=' ' sx={{width: '100%'}} onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.que}  name='que' />
                              </TableCell>
            
                              <TableCell component="th" scope="row">
                                <TextField multiline minRows={1} maxRows={1} label=' ' type='number'  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.ponderacion}  name='ponderacion' />
                              </TableCell>

                              <TableCell component="th" scope="row">
                                  <TextField multiline minRows={1} maxRows={5} label=' ' sx={{width: '100%'}} onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.medioVerificacion}  name='medioVerificacion' />
                              </TableCell>

                              <TableCell component="th" scope="row">
                                  <TextField multiline minRows={1} maxRows={1}  label=' ' type='number'  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.calificacion} disabled name='calificacion' />
                              </TableCell>
            
                              <TableCell component="th" scope="row">
                                  <TextField multiline minRows={1} maxRows={5} label=' '  sx={{width: '100%', marginRight: 2}}  value={logroMetasAdd.observaciones}  onChange={(e) => addData(e.target.name, e.target.value)}  name='observaciones' />
                              </TableCell>
            
                              <TableCell>
                                 <div className='d-grid'>
                                    <button className='btn btn-success' onClick={() => setAddRequestStatus('success')} hidden={validacionEstadoLogroMetas} disabled={!validatioUserLoginEvaluador}>
                                        <DoneOutlineIcon />
                                    </button>
                                 </div>
                              </TableCell>
        
                          </TableRow>

                          {
                            listLogroMetasUsuario.map((item, index) => (

                            <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                              {
                                item.resultadoId === logroMetasAdd.resultadoId ?    
                                
                      
                                  <>
                                    <TableCell component="th" scope="row">
                                        <Typography fontWeight='bold'>{index + 1}</Typography>
                                    </TableCell>
        
                                    <TableCell component="th" scope="row">
                                        <TextField multiline minRows={1} maxRows={5} label=' ' sx={{width: '100%'}}  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.que}  name='que' disabled={validacionEstadoLogroMetas} />
                                    </TableCell>
                  
                                    <TableCell component="th" scope="row">
                  
                                    <TextField multiline minRows={1} maxRows={1} label=' ' type='number'  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.ponderacion}  name='ponderacion' disabled={validacionEstadoLogroMetas} />
                                    
                                    </TableCell>
        
                                    <TableCell component="th" scope="row">
                                        <TextField multiline minRows={1} maxRows={5} label=' ' onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.medioVerificacion}  name='medioVerificacion' disabled={validacionEstadoLogroMetas} />
                                    </TableCell>
        
                                    <TableCell component="th" scope="row">
                                        <TextField multiline minRows={1} maxRows={1} label=' ' type='number'  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.calificacion}  name='calificacion' />
                                    </TableCell>
                  
                                    <TableCell component="th" scope="row">
                                        <TextField   multiline minRows={1} maxRows={5}label=' '  sx={{width: '100%', marginRight: 2}}  value={logroMetasAdd.observaciones}  onChange={(e) => addData(e.target.name, e.target.value)}  name='observaciones' />
                                    </TableCell>
                  
                                    <TableCell>
                                        <button className='btn btn-success' onClick={() => setAddRequestStatus('success')} disabled={!validatioUserLoginEvaluador}>
                                            <DoneOutlineIcon />
                                        </button>
                                    </TableCell>
            
                                  </>

                                : 

                                  <>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
        
                                    <TableCell align='center' component="th" scope="row">
                                       <Typography component='span' style={textMultiline}>{item.que}</Typography>
                                    </TableCell>
                  
                                    <TableCell align='center' component="th" scope="row">
                                      <Typography>{item.ponderacion}</Typography>
                                    </TableCell>
        
                                    <TableCell width={12} align='center' component="th" scope="row">
                                        <Typography component='span' style={textMultiline}>{item.medioVerificacion}</Typography>
                                    </TableCell>
        
                                    <TableCell align='center' component="th" scope="row">
                                        <Typography>{item.calificacion}</Typography>      
                                    </TableCell>
                  
                                    <TableCell align='center' component="th" scope="row">
                                        <Typography component='span' style={textMultiline}>{item.observaciones}</Typography>
                                    </TableCell>
                  
                                    <TableCell>
                                        <div className='d-flex'>
                                          <button hidden={estado === 2} className='btn btn-warning me-2' onClick={() => addDataEdit(item)} disabled={!validatioUserLoginEvaluador}>
                                            <EditIcon />
                                          </button> 
                                    
                                          <button className='btn btn-primary-mem me-2' onClick={() => setCargaArchivosEnabled({enabled: true, logroMeta: item.que, logroMetaId: item.resultadoId, files: item.files})} disabled={estado === 0 ? !validatioUserLoginEvaluador : permisosState.ROLE_ADMIN === true ? !permisosState.ROLE_ADMIN : !permisosState.ROLE_ADMIN_RRHH}>
                                            <IoDocumentAttach fontSize={22} />
                                          </button> 

                                          <button className='btn btn-danger' onClick={() => deleteLogroMetas(item)} hidden={validacionEstadoLogroMetas} disabled={!validatioUserLoginEvaluador} >
                                              <DeleteForeverIcon />
                                          </button>
                                        </div>
                                    </TableCell>
              
                                  </>
                              
                              }

                          </TableRow>      

                        ))}
                          
                          <TableRow>

                              <TableCell />
                              <TableCell align='center'><Typography fontWeight='bold' fontSize={14}>Sumatoria</Typography></TableCell>
                              <TableCell align='center'><Typography fontWeight='bold' fontSize={14}>{calcLogroDeMetas.totalPonderacionMetas}</Typography></TableCell>
                              <TableCell align='center'><Typography fontWeight='bold' fontSize={14}>Sumatoria</Typography></TableCell>
                              <TableCell align="center"><Typography fontWeight='bold' fontSize={14}>{calcLogroDeMetas.totalCalificacion}</Typography></TableCell>
                              <TableCell />
                              <TableCell >

                                  <div className='d-grid'>
                                    <button className='btn btn-primary' hidden={validacionEstadoLogroMetas} disabled={!disabledButtonAprobacionLogroMetas} onClick={() => aprobarLogroMetas()}><DoneAllIcon sx={{marginRight: 0.5}} disabled={!validatioUserLoginEvaluador} />Aprobar</button>
                                  </div>

                              </TableCell>
                          </TableRow>

                      </TableBody>
                  </Table>
              </TableContainer>

              : (periodo >= 2024 && grupoOcupacionalId === 5) ?
              
              <TableContainer component={Paper}>

                {/********************************************************************************************************************************************/}
                {/*******************************************************- Formato 2024 PARA GRUPO V -********************************************************/}
                {/********************************************************************************************************************************************/}

                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width="5%"><Typography fontWeight='bold' fontSize={14}>No.</Typography></TableCell>
                            <TableCell align="center" width="20%">
                              <Typography fontWeight='bold' fontSize={14}>Metas del Plan Operativo</Typography>
                              <Typography fontWeight='bold' fontSize={14}>Anual -POA- </Typography>
                              <Typography fontWeight='bold' fontSize={14}>de la Unidad Organizativa</Typography>
                            </TableCell>
                            <TableCell align="center" width="20%">
                              <Typography fontWeight='bold' fontSize={14}>Metas del/ de la Servidor/a </Typography>
                              <Typography fontWeight='bold' fontSize={14}>Redactar siguiendo las características SMART: </Typography>
                              <Typography fontWeight='bold' fontSize={14}>Específica, Medible, Alcanzable, Realista y con Horizonte de Tiempo: </Typography>
                              <Typography fontSize={14}>
                              <br></br> ¿Qué tengo que lograr?<br></br> 
                                ¿Cuánto tengo que lograr?<br></br> ¿Cuándo tengo qué lograrlo y con cuáles característica de calidad?
                              </Typography>
                            </TableCell>
                            <TableCell align="center" width="10%"><Typography fontWeight='bold' fontSize={14}>Ponderación o Valor en puntos de las Metas</Typography></TableCell>
                            <TableCell align="center" width="17%"><Typography fontWeight='bold' fontSize={14}>Medios de Verificación o Evidencias de logro de cada Meta</Typography></TableCell>
                            <TableCell align="center" width="10%"><Typography fontWeight='bold' fontSize={14}>Calificación otorgada</Typography></TableCell>
                            <TableCell align="center" width="20%"><Typography fontWeight='bold' fontSize={14}>Observaciones/ Comentarios del Evaluador/a</Typography></TableCell>
                            <TableCell align="center" width="10%"></TableCell>
                        </TableRow>
                    </TableHead>
      
                    <TableBody>
                        <TableRow
                        hidden={editEnabled || validacionEstadoLogroMetas}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                               
                            </TableCell>

                            <TableCell component="th" scope="row">
                              <TextField multiline minRows={1} maxRows={5} label=' ' sx={{width: '100%'}} onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.metasPlanOperativo}  name='metasPlanOperativo' />
                            </TableCell>

                            <TableCell component="th" scope="row">
                                <TextField multiline minRows={1} maxRows={5} label=' ' sx={{width: '100%'}} onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.que}  name='que' />
                            </TableCell>
          
                            <TableCell component="th" scope="row">
                              <TextField multiline minRows={1} maxRows={1} label=' ' type='number'  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.ponderacion}  name='ponderacion' />
                            </TableCell>

                            <TableCell component="th" scope="row">
                                <TextField multiline minRows={1} maxRows={5} label=' ' sx={{width: '100%'}} onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.medioVerificacion}  name='medioVerificacion' />
                            </TableCell>

                            <TableCell component="th" scope="row">
                                <TextField multiline minRows={1} maxRows={1}  label=' ' type='number'  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.calificacion} disabled name='calificacion' />
                            </TableCell>
          
                            <TableCell component="th" scope="row">
                                <TextField multiline minRows={1} maxRows={5} label=' '  sx={{width: '100%', marginRight: 2}}  value={logroMetasAdd.observaciones}  onChange={(e) => addData(e.target.name, e.target.value)}  name='observaciones' />
                            </TableCell>
          
                            <TableCell>
                               <div className='d-grid'>
                                  <button className='btn btn-success' onClick={() => setAddRequestStatus('success')} hidden={validacionEstadoLogroMetas} disabled={!validatioUserLoginEvaluador}>
                                      <DoneOutlineIcon />
                                  </button>
                               </div>
                            </TableCell>
      
                        </TableRow>

                        {
                          listLogroMetasUsuario.map((item, index) => (

                          <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >

                            {
                              item.resultadoId === logroMetasAdd.resultadoId ?    
                              
                    
                                <>
                                  <TableCell component="th" scope="row">
                                      <Typography fontWeight='bold'>{index + 1}</Typography>
                                  </TableCell>
      
                                  <TableCell component="th" scope="row">
                                      <TextField multiline minRows={1} maxRows={5} label=' ' sx={{width: '100%'}}  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.metasPlanOperativo}  name='metasPlanOperativo' disabled={validacionEstadoLogroMetas} />
                                  </TableCell>

                                  <TableCell component="th" scope="row">
                                      <TextField multiline minRows={1} maxRows={5} label=' ' sx={{width: '100%'}}  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.que}  name='que' disabled={validacionEstadoLogroMetas} />
                                  </TableCell>
                
                                  <TableCell component="th" scope="row">
                
                                  <TextField multiline minRows={1} maxRows={1} label=' ' type='number'  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.ponderacion}  name='ponderacion' disabled={validacionEstadoLogroMetas} />
                                  
                                  </TableCell>
      
                                  <TableCell component="th" scope="row">
                                      <TextField multiline minRows={1} maxRows={5} label=' ' onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.medioVerificacion}  name='medioVerificacion' disabled={validacionEstadoLogroMetas} />
                                  </TableCell>
      
                                  <TableCell component="th" scope="row">
                                      <TextField multiline minRows={1} maxRows={1} label=' ' type='number'  onChange={(e) => addData(e.target.name, e.target.value)} value={logroMetasAdd.calificacion}  name='calificacion' />
                                  </TableCell>
                
                                  <TableCell component="th" scope="row">
                                      <TextField   multiline minRows={1} maxRows={5}label=' '  sx={{width: '100%', marginRight: 2}}  value={logroMetasAdd.observaciones}  onChange={(e) => addData(e.target.name, e.target.value)}  name='observaciones' />
                                  </TableCell>
                
                                  <TableCell>
                                      <button className='btn btn-success' onClick={() => setAddRequestStatus('success')} disabled={!validatioUserLoginEvaluador}>
                                          <DoneOutlineIcon />
                                      </button>
                                  </TableCell>
          
                                </>

                              : 

                                <>
                                  <TableCell component="th" scope="row">
                                      {index + 1}
                                  </TableCell>

                                  <TableCell align='center' component="th" scope="row">
                                     <Typography component='span' style={textMultiline}>{item.metasPlanOperativo}</Typography>
                                  </TableCell>
      
                                  <TableCell align='center' component="th" scope="row">
                                     <Typography component='span' style={textMultiline}>{item.que}</Typography>
                                  </TableCell>
                
                                  <TableCell align='center' component="th" scope="row">
                                    <Typography>{item.ponderacion}</Typography>
                                  </TableCell>
      
                                  <TableCell width={12} align='center' component="th" scope="row">
                                      <Typography component='span' style={textMultiline}>{item.medioVerificacion}</Typography>
                                  </TableCell>
      
                                  <TableCell align='center' component="th" scope="row">
                                      <Typography>{item.calificacion}</Typography>      
                                  </TableCell>
                
                                  <TableCell align='center' component="th" scope="row">
                                      <Typography component='span' style={textMultiline}>{item.observaciones}</Typography>
                                  </TableCell>
                
                                  <TableCell>
                                      <div className='d-flex'>
                                        <button hidden={estado === 2} className='btn btn-warning me-2' onClick={() => addDataEdit(item)} disabled={!validatioUserLoginEvaluador}>
                                          <EditIcon />
                                        </button> 
                                  
                                        <button className='btn btn-primary-mem me-2' onClick={() => setCargaArchivosEnabled({enabled: true, logroMeta: item.que, logroMetaId: item.resultadoId, files: item.files})} disabled={estado === 0 ? !validatioUserLoginEvaluador : permisosState.ROLE_ADMIN === true ? !permisosState.ROLE_ADMIN : !permisosState.ROLE_ADMIN_RRHH}>
                                          <IoDocumentAttach fontSize={22} />
                                        </button> 

                                        <button className='btn btn-danger' onClick={() => deleteLogroMetas(item)} hidden={validacionEstadoLogroMetas} disabled={!validatioUserLoginEvaluador} >
                                            <DeleteForeverIcon />
                                        </button>
                                      </div>
                                  </TableCell>
            
                                </>
                            
                            }

                        </TableRow>      

                      ))}
                        
                        <TableRow>

                            <TableCell />
                            <TableCell align='center'><Typography fontWeight='bold' fontSize={14}>Sumatoria</Typography></TableCell>
                            <TableCell align='center'><Typography fontWeight='bold' fontSize={14}>{calcLogroDeMetas.totalPonderacionMetas}</Typography></TableCell>
                            <TableCell align='center'><Typography fontWeight='bold' fontSize={14}>Sumatoria</Typography></TableCell>
                            <TableCell align="center"><Typography fontWeight='bold' fontSize={14}>{calcLogroDeMetas.totalCalificacion}</Typography></TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell >

                                <div className='d-grid'>
                                  <button className='btn btn-primary' hidden={validacionEstadoLogroMetas} disabled={!disabledButtonAprobacionLogroMetas} onClick={() => aprobarLogroMetas()}><DoneAllIcon sx={{marginRight: 0.5}} disabled={!validatioUserLoginEvaluador} />Aprobar</button>
                                </div>

                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
              </TableContainer>         

              : <></>
            
          }

      </TabPanel>


        <TabPanel value="2" hidden={periodo < 2025}>

          <CompetenciasTab 
          estado={estado}
          listaCompetenciasComportamientosMod={listaCompetenciasComportamientosMod}
          setListaCompetenciasComportamientosMod={setListaCompetenciasComportamientosMod}
          />
          
        </TabPanel>

        {/* <TabPanel value="3">

          <RegimenEticoTab />
          
        </TabPanel> */}

        <TabPanel value="4">

        <Grid container spacing={2}>

          <Grid item sx={{marginBottom: -1}}>
            <Typography fontWeight='bold' fontSize={14} sx={{marginTop: 1}}>ASPECTOS A CONSIDERAR DURANTE LA ENTREVISTA DE EVALUACION</Typography>
          </Grid>
        
          <Grid item xs={20}>
            <Paper elevation={4} sx={paperStyle} >
                <Box
                  sx={{ fontSize: '14px', textTransform: 'uppercase', textAlign: 'initial' }}
                >
                  PLAN DE MEJORA Y DESARROLLO PROFESIONAL
                </Box>

                <Divider />

                <Grid item sx={{marginTop: 2}}>
                  <Paper elevation={4} sx={paperStyle}>

                    <Box
                      sx={{ fontSize: '14px', textTransform: 'uppercase', textAlign: 'initial' }}
                    >
                      PUNTOS FUERTES
                    </Box>

                    <Divider />

                      {
                        puntosFuertesArr.map((item, index) => 
                          
                          <Grid container sx={{paddingTop: 2}} key={index + 1}>
                      
                            <Grid xs={0.5} alignItems='center'>
                              <Typography fontWeight='bold' sx={{marginTop: 1, color: 'black'}}>{index + 1}</Typography>
                            </Grid>

                            <Grid xs={11}>  
                              <TextField disabled={estado === 2} name='puntosFuertes' value={item.texto} onChange={(e) => changePositionOnArrayPuntosFuertes(index, e)} label=' ' sx={{width: '100%'}} />   
                            </Grid>

                           {
                            index === puntosFuertesArr.length - 1 ?

                              <Grid xs={0.5}>
                               {
                                puntosFuertesArr.length < 4 ?
                                  <IconButton hidden={puntosFuertesArr.length === 4 || estado === 2} style={{color: '#003876'}} onClick={() => insertObjectOnArrayPuntosFuertes() }><AddCircleIcon /></IconButton>
                                  :
                                  <IconButton hidden={estado === 2} style={{color: '#C72924'}} onClick={() => deleteObjectOnArrayPuntosFuertes(index, item?.puntoFuerteId)}><RemoveCircleIcon /></IconButton>

                               }
                              </Grid>
                            
                            :
                              <Grid xs={0.5}>
                                <IconButton hidden={estado === 2} style={{color: '#C72924'}} onClick={() => deleteObjectOnArrayPuntosFuertes(index, item?.puntoFuerteId)}><RemoveCircleIcon /></IconButton>
                              </Grid>
                           }

                          </Grid>

                        )
                      }
                     
                  </Paper>
                </Grid>

                <Grid item sx={{marginTop: 2}}>
                  <Paper elevation={3} sx={paperStyle}>

                    <Box
                      sx={{ fontSize: '14px', textTransform: 'uppercase', textAlign: 'initial' }}
                    >
                      ÁREAS DE MEJORAS
                    </Box>

                    <Divider />

                      {
                          areasMejorasArr.map((item, index) => 

                            <Grid container sx={{paddingTop: 2}} key={index}>
                        
                              <Grid xs={0.5}>
                              
                                <Typography fontWeight='bold' sx={{marginTop: 1, color: 'black'}}>{index + 1}</Typography>
                          
                              </Grid>

                              <Grid xs={11}>
                              
                                <TextField disabled={estado === 2} name='areasMejoras' value={item.texto} onChange={(e) => changePositionOnArrayAreasMejoras(index, e)} label=' ' sx={{width: '100%'}} />
                          
                              </Grid>

                              {
                                index === areasMejorasArr.length - 1 ? 
                                  <Grid xs={0.5}>
                                    {
                                      areasMejorasArr.length < 4 ?
                                        <IconButton hidden={estado === 2} style={{color: '#003876'}} onClick={() => insertObjectOnArrayAreasMejoras()}><AddCircleIcon /></IconButton>
                                      :
                                        <IconButton hidden={estado === 2} style={{color: '#C72924'}} onClick={() => deleteObjectOnArrayAreasMejoras(index, item?.areaMejoraId)}><RemoveCircleIcon /></IconButton>
                                    }
                                    
                                  </Grid>
                                :
                                  <Grid xs={0.5}>
                                    <IconButton style={{color: '#C72924'}} onClick={() => deleteObjectOnArrayAreasMejoras(index, item?.areaMejoraId)}><RemoveCircleIcon /></IconButton>
                                  </Grid>
                              }
                                             
                            </Grid>

                          )
                      }

                  </Paper>
                </Grid>

                <Grid item sx={{marginTop: 2}}>
                  <Paper elevation={3} sx={paperStyle}>

                    <Box
                      sx={{ fontSize: '14px', textTransform: 'uppercase', textAlign: 'initial' }}
                    >
                      RECOMENDACIONES
                    </Box>

                    <Divider />

                      <Grid sx={{paddingTop: 0.5}}>
                      
                          <Grid>
                          
                            <Typography fontSize={13} textAlign='initial' fontWeight='bold' sx={{marginTop: 1, color: 'black'}}>DE FORMACIÓN</Typography>
                      
                          </Grid>

                          <Grid>
                            <textarea disabled={estado === 2} value={addPlanDeMejora.recomendacionesFormacion} onChange={addDataPlanMejoraDesarrollo} name='recomendacionesFormacion' className="form-control" rows="3"></textarea>
                          </Grid>

                          <Grid>
                          
                            <Typography fontSize={13} textAlign='initial' fontWeight='bold' sx={{marginTop: 2, color: 'black'}}>DE OTRA CLASE</Typography>
                      
                          </Grid>

                          <Grid>
                            <textarea disabled={estado === 2} value={addPlanDeMejora.recomendacionesOtras} onChange={addDataPlanMejoraDesarrollo} name='recomendacionesOtras' className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                          </Grid>
              
                      </Grid>

                  </Paper>
                </Grid>

              </Paper>
          </Grid>

          <Grid item xs={20}>
            <Paper elevation={4} sx={paperStyle}>
                <Box
                  sx={{ fontSize: '14px', textTransform: 'uppercase', textAlign: 'initial' }}
                >
                  ENTORNO–CONDICIONES DE TRABAJO
                </Box>

                <Divider />

                <Grid item sx={{marginTop: 2}}>

                      <Grid>
                      
                          <Grid>
                          
                            <Typography 
                            fontSize={13} 
                            textAlign='initial' 
                            sx={{marginTop: 1, color: 'black'}}>
                              SEÑALE AQUELLOS ELEMENTOS QUE HAN DISTORSIONADO EL DESEMPEÑO DEL/ DE LA SERVIDOR/A
                            </Typography>
            
                          </Grid>

                          <Grid>
                            <textarea disabled={estado === 2} value={addPlanDeMejora.distorsionDesempeno} onChange={addDataPlanMejoraDesarrollo} name='distorsionDesempeno' className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                          </Grid>

                          <Grid>
                          
                            <Typography 
                            fontSize={13} 
                            textAlign='initial' 
                            sx={{marginTop: 2, color: 'black'}}>
                              SEÑALE OTRAS CUESTIONES DIRIGIDAS A INCREMENTAR LA MOTIVACIÓN Y SATISFACCIÓN PROFESIONAL DEL/DE LA SERVIDOR/A
                            </Typography>
                      
                          </Grid>

                          <Grid>
                            <textarea disabled={estado === 2} value={addPlanDeMejora.cuestionesMotivacion} onChange={addDataPlanMejoraDesarrollo} name='cuestionesMotivacion' className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                          </Grid>
              
                      </Grid>

                </Grid>

              </Paper>
          </Grid>

          <Grid item xs={20}>
            <Paper elevation={4} sx={paperStyle}>
                <Box
                  sx={{ fontSize: '14px', textTransform: 'uppercase', textAlign: 'initial' }}
                >
                  COMENTARIOS DEL/ DE LA SERVIDOR/A EVALUADO/A:
                </Box>

                <Divider />

                <Grid item sx={{marginTop: 1}}>

                      <Grid sx={{paddingTop: 0.5}}>

                          <Grid>     
                            <textarea disabled={estado === 2} value={addPlanDeMejora.comentariosEvaluado} onChange={addDataPlanMejoraDesarrollo} name='comentariosEvaluado' className="form-control" rows="3"></textarea>
                          </Grid>

                      </Grid>
                </Grid>

              </Paper>
          </Grid>

          <Grid item xs={20}>
            <Paper elevation={4} sx={paperStyle}>
                <Box
                  sx={{ fontSize: '14px', textTransform: 'uppercase', textAlign: 'initial' }}
                >
                  CONFORME CON LA CALIFICACIÓN Y EL PLAN DE MEJORA
                </Box>

                <Divider />

                <Grid item sx={{marginTop: 1}}>

                      <Grid sx={{paddingTop: 0.5}}>

                          <Grid>
                          
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="conforme"
                              value={addPlanDeMejora.conforme}
                              onChange={addDataPlanMejoraDesarrollo}
                            >
                              <FormControlLabel disabled={estado === 2} value="1" control={<Radio />} label="Sí" />
                              <FormControlLabel disabled={estado === 2} value="0" control={<Radio />} label="No" />

                            </RadioGroup>
                          </FormControl>
                      
                          </Grid>

                      </Grid>
                </Grid>

              </Paper>
          </Grid>

          <Grid item xs={20} className='d-flex justify-content-end'>
            <button hidden={estado === 2} className='btn btn-primary-mem' onClick={() => setAddRequestStatusPlanMejora('success')} disabled={!validatioUserLoginEvaluador}>
            <SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}} />
                Guardar
            </button>
          </Grid>

        </Grid>

        </TabPanel>
      </TabContext>

      <VerListaArchivosModal
        estado={estado}
        cargaArchivosEnabled={cargaArchivosEnabled}
        setCargaArchivosEnabled={setCargaArchivosEnabled}
        setUploadFilesPDF={setUploadFilesPDF}
        setPdfViewEnabled={setPdfViewEnabled}
      />

      <CargarArchivosPDFModal 
      uploadFilesPDF={uploadFilesPDF}
      setUploadFilesPDF={setUploadFilesPDF}
      cargaArchivosEnabled={cargaArchivosEnabled}
      />

      <ViewPDFGlobal
      open={pdfViewEnabled.enabled}
      dataReporte={pdfViewEnabled.file}
      setOpen={setPdfViewEnabled}
      />

    </Box>
  );
}