import React from "react";
import { Route, Routes, Navigate } from "react-router";
import {
  Empleados,
  EmpleadoEditar,
  EmpleadoNuevo,
  EmpleadoDetalle,
  EmpleadosDesvinculados,
  EmpleadosReporte
} from "../pages/Empleados";

import { RequiredPermission } from "../authentication/RequiredPermission";

export const RutasEmpleados = () => {

  return (
    <Routes>

      <Route element={<RequiredPermission permission="ROLE_VER_REGISTRO_EMPLEADOS" />}>
        <Route index element={<Empleados />} />

        <Route path="detalle/:empleadoId" element={<EmpleadoDetalle />} />

        {/* RUTA EMPLEADOS-DESVINCULADOS */}
        <Route path="empleadosdesvinculados" element={<EmpleadosDesvinculados />} />

        {/* RUTA DE REPORTE DE EMPLEADOS */}        
        <Route path="reporte" element={<EmpleadosReporte />} />
      </Route>

      <Route element={<RequiredPermission permission="ROLE_CREAR_EMPLEADO" />}>
        <Route path="nuevo" element={<EmpleadoNuevo />} />
      </Route>

      <Route element={<RequiredPermission permission="ROLE_EDITAR_REGISTRO_EMPLEADOS" />}>
        <Route path="editar/:empleadoId" element={<EmpleadoEditar />} />
      </Route>

      <Route path='/*' element={<Navigate to="/" replace />} />

    </Routes>
  );
};
