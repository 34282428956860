export const generarListaPatologicosEnfermedades = ({enfermedades, tipoFamiliares}) => {
    // * VARIABLE PARA LAS MATRICES
    let arr = [];
    let matriz = [];

    for (let index = 0; index < enfermedades.length; index++) {
        
        matriz = [];
        for (let x = 0; x < tipoFamiliares.length; x++) {
            const element = {
                id: 0,
                historicoClinicoId: 0,
                enfermedadId: enfermedades[index].id,
                familiarId: tipoFamiliares[x].id,
                enfermedadesOtras: '',
                seleccionado: false
            };
            
            matriz.push(element);
        }

        arr.push(matriz);
    }

    return arr;
}