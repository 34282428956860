import React from "react";
import moment from "moment";
import { TotalResumenAsistenciaCard } from "../Cards";

export const ReporteAsistenciaCard = ({ empleado, contadores }) => {
  const calcularTardanza = (horaEntrada) => {
    // if()
  };
  const calcularSalida = (horaSalida) => {};

  return (
    <div className="card mb-4">
      <div
        className="card-header fw-bold"
        style={{ backgroundColor: "#e2edfc" }}
      >
        Asistencia
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Hora entrada</th>
              <th>Hora salida</th>
              <th>Llegada tarde</th>
              <th>Salida temprano</th>
              <th>Tardanza</th>
              <th>Justificación</th>
            </tr>
            <tr></tr>
          </thead>
          <tbody>
            {/* <tr>
              <td>01/06/2022</td>
              <td>08:07:56</td>
              <td>16:06:12</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td></td>
            </tr> */}
            {empleado.asistencias &&
              empleado.asistencias.length > 0 &&
              empleado.asistencias.map((ponche) => (
                <tr key={ponche.fecha}>
                  <td>{moment(ponche.fecha).format("DD/MM/YYYY")}</td>
                  <td>
                    {ponche.horaEntrada
                      ? moment(ponche.horaEntrada).format("hh:mm:ss")
                      : "-"}
                  </td>
                  <td>
                    {ponche.horaSalida
                      ? moment(ponche.horaSalida).format("hh:mm:ss")
                      : "-"}
                  </td>
                  <td>{ponche.tarde}</td>
                  <td>{ponche.temprano}</td>
                  <td>{ponche.tardanza}</td>
                  <td>{ponche.justificacion}</td>
                </tr>
              ))}
          </tbody>
        </table>

        <TotalResumenAsistenciaCard contadores={contadores} />
      </div>
    </div>
  );
};

export default ReporteAsistenciaCard;
