import React, { useEffect, useState } from "react";
import { startOfDay, isAfter, isEqual } from 'date-fns';
import { useWatch } from "react-hook-form";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import Add from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import SaveIcon from "@mui/icons-material/Save";
import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { PermissionData } from "../../../context/Component/PermissionData";


import {
  fetchTiposPermisos,
  selectAllTiposPermisos,
  fetchTiposPermisosCategorias,
  selectAllTiposPermisosCategorias,
  savePermiso,
} from "./../../../redux/Permisos/PermisosSlice";

import { 
  selectDiasFeriadosAnoActual
} from '../../../redux/DiasFeriados/DiasFeriadosSlice';

import {
  selectAllEmpleados,
  fetchEmpleados,
} from "../../../redux/Empleados/EmpleadosSlice";

import { selectUserData } from "../../../redux/Login/LoginSlice";

import { calcularDiasHabiles } from "../../../utils/funcionesFechasPermisos";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const PermisoNuevo = ({ open, setOpen, form, resetTimer }) => {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // * DESESTRUCTURACION DE DATOS DEL USE-FORM
  const { 
    watch,
    setValue, 
    reset, 
    register, 
    control, 
    formState: {errors}, 
    handleSubmit 
  } = form;

  // ? HOOK-WATCH FECHA DE INICIO
  const fechaInicio = useWatch({
    control,
    name: "fechaInicio",
  })
  
  // ? HOOK-WATCH FECHA DE INICIO
  const fechaTermino = useWatch({
    control,
    name: "fechaTermino",
  })

  // ? HOOK-WATCH CHECKED-RECURRENTE
  const checkedRecurrente = useWatch({
    control,
    name: "recurrencia.recurrente",
  })
  
  // ? HOOK-WATCH CHECKED-DIA-COMPLETO
  const checkedDiaCompleto = useWatch({
    control,
    name: "diaCompleto",
  })

  // ? HOOK-WATCH CHECKED-DIA-COMPLETO
  const horaInicio = useWatch({
    control,
    name: "horaInicio",
  });

  // ? HOOK-WATCH CHECKED-DIA-COMPLETO
  const horaTermino = useWatch({
    control,
    name: "horaTermino",
  })


  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  // ? HOOK SELECTOR
  const usuario = useSelector(selectUserData);
  const empleadosList = useSelector(selectAllEmpleados);
  const tipospermisos = useSelector(selectAllTiposPermisos);
  const tipospermisosCategoria = useSelector(selectAllTiposPermisosCategorias);
  const diasFeriados = useSelector(selectDiasFeriadosAnoActual);

  const [inputEmployeeDisabled, setInputEmployeeDisabled] = useState(false);

  const [
    tiposPermisosCategoriaGrupo,
    setTiposPermisosCategoriaGrupo,
  ] = useState([]);

  const onSubmit = (data) => {

    if(!data){
      return;
    }

    dispatch(savePermiso(data));
  };

  // * FUNCION PARA CERRAR EL MODAL.
  const handleClose = () => {
    setOpen(false);
    reset();
    resetTimer();
  };


  // ? HOOK-EFECT PARA SOLICITAR LOS DATOS A LA API.
  useEffect(() => {
    let dataSolicitar = {
      skipRow: 0,
      rowsPerPage: 0,
      areaOrganizacionalId: 0,
      empleado: "",
      estado: 1,
    };

    // * VALIDACION DEL TIPO DE RETORNO DE LOS ROLES DEL USUARIO, SI ES STRING SE AGREGA A UN ARRAY,
    // * DE LO CONTRARIO SIGNIFICA QUE EL USUARIO POSEE UN ARRAY DE STRING'S Y SE RETORNARA.
    const arrRoles =
      typeof usuario?.RoleID === "string"
        ? [usuario?.RoleID] ?? []
        : usuario?.RoleID ?? [];

    // * VALIDACION PARA DETERMINAR SI EL USUARIO POSEE EL ROL-ID 4 (CONTROL).
    const validacionRol =
      arrRoles?.some((value) => parseInt(value) === 4) ?? false;

    if (validacionRol) {
      dispatch(fetchEmpleados(dataSolicitar));
    }
    // * VALIDACION DE PERMISO EVALUADOR.
    else if (permisosState.ROLE_VER_PERMISO_SUB) {
      dispatch(
        fetchEmpleados({
          ...dataSolicitar,
          ["areaOrganizacionalId"]: usuario?.AreaOrganizacionalId,
        })
      );
    } else {
      dispatch(fetchEmpleados(dataSolicitar));
      setInputEmployeeDisabled(true);
    }

    dispatch(fetchTiposPermisos());
    dispatch(fetchTiposPermisosCategorias());
    resetTimer();
  }, [usuario]);


  // ? HOOK-EFFECT PARA VERIFICAR SI EL CHECKED DEL DIA COMPLETO SE MARCA, HACE UN RESET A LAS HORAS.
  useEffect(() => {
    if (checkedDiaCompleto) {
      resetTimer();
    }
  }, [checkedDiaCompleto]);


  // ? HOOK-EFFECT PARA RESTABLECER LOS CHECKBOX DE RECURRENCIA.
  useEffect(() => {
    
    if(!checkedRecurrente){
      setValue('recurrencia.l', false);
      setValue('recurrencia.m', false);
      setValue('recurrencia.x', false);
      setValue('recurrencia.j', false);
      setValue('recurrencia.v', false);
      setValue('recurrencia.s', false);
      setValue('recurrencia.d', false);
    }

  }, [checkedRecurrente])
  

  // ? CREACION DE LISTA PARA ORDENAR LOS PERMISOS SEGUN LA CATEGORIA.
  useEffect(() => {
    let tiposPermisosCategoriaOrder = [];
    if (tipospermisosCategoria && tipospermisosCategoria.length > 0) {
      tipospermisosCategoria.forEach((element) => {
        let arrayTipoPermiso = tipospermisos.filter(
          (x) => x.categoriaId === element.id
        );
        let categoria = {
          label: element.nombre,
          options: arrayTipoPermiso.map((x) => ({
            label: x.nombre,
            value: x.id,
          })),
        };
        tiposPermisosCategoriaOrder.push(categoria);
      });
      setTiposPermisosCategoriaGrupo(tiposPermisosCategoriaOrder);
    }
  }, [tipospermisosCategoria]);


  // ? ASIGNACION DE USUARIO.
  useEffect(() => {
    setValue("empleadoId", parseInt(usuario?.Id));
  }, [empleadosList, open]);

    // ? HOOK-EFFECT PARA CALCULAR LOS DIAS DE DURACION DE PERMISO SEGUN LAS FECHAS.
  useEffect(() => {
    setValue("dias", calcularDiasHabiles({fechaInicio, fechaTermino, diasFeriados, horaInicio, horaTermino, checkedDiaCompleto}));
  }, [fechaInicio, fechaTermino, checkedDiaCompleto])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 700 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row">
              <h3 className="text-primary-mem text-center">
                Solicitar Permiso Personalizado
              </h3>
            </div>
            <div className="row">
              <div className="col-5 me-0 pe-0">
                <hr />
              </div>
              <div className="col-2 text-center">
                <Add className="text-primary-mem"></Add>
              </div>
              <div className="col-5 ms-0 ps-0 ">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label className="fw-bold">Empleado</label>

                <Controller
                control={control}
                {...register("empleadoId", {
                  required: "El empleado es requerido",
                })}
                render={({ field }) => (
                  <Select
                    id='select-empleado'
                    isDisabled={inputEmployeeDisabled}
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Seleccionar Empleado"
                    options={empleadosList} 
                    getOptionLabel={(option) => option.nombreLabel}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => field.onChange(e.id)}
                    value={empleadosList.find(x => x.id === field.value)}
                  />
                )}
                />

                <p className="text-danger mb-0">{errors?.empleadoId?.message}</p>
              </div>
              <div className="form-group">
                <label className="fw-bold">Tipo Permiso</label>

                <Controller
                  control={control}
                  name="tipoPermisoId"
                  {...register("tipoPermisoId", {
                    required: "El tipo de permiso es requerido",
                    validate: value => value !== 0 || "El permiso es requerido"
                  })}
                  render={({ field }) => (
                    <Select
                      classNamePrefix="select"
                      isSearchable={true}
                      placeholder="Seleccionar Tipo Permiso"
                      options={tiposPermisosCategoriaGrupo}
                      onChange={(e) => field.onChange(e.value)}
                      defaultValue={field.value}
                    />
                  )}
                />
                <p className="text-danger mb-0">
                  {errors?.tipoPermisoId?.message}
                </p>
              </div>
              <div className="form-group">
                <label className="fw-bold">Dias</label>
                <input
                  className="form-control"
                  type="number"
                  {...register("dias", {
                    required: "Los dias de permiso son requeridos",
                  })}
                 />

                  <p className="text-danger mb-0">
                    {errors?.dias?.message}
                  </p>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label className="fw-bold">Fecha inicio</label>
                    <Controller
                      control={control}
                      // className="mb-3"
                      name="fechaInicio"
                      {...register("fechaInicio", {
                        required: "La fecha de inicio es requerida",
                        
                      })}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat='dd/MM/yyyy'
                            label={" "}
                            mask="__/__/____"
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                            renderInput={(params) => (
                              <TextField
                                height={10}
                                className="form-control"
                                style={{ height: 10 }}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <p className="text-danger mt-3 mb-0">
                      {errors?.fechaInicio?.message}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label className="fw-bold">Fecha Término</label>
                    <Controller
                      control={control}
                      name="fechaTermino"
                      {...register("fechaTermino", {
                        required: "La fecha de término es requerida",
                        validate: (value) => {
                          const fechaTermino = startOfDay(new Date(value));
                          const fechaInicioAjustada = startOfDay(new Date(fechaInicio));
                          return (
                            isAfter(fechaTermino, fechaInicioAjustada) || 
                            isEqual(fechaTermino, fechaInicioAjustada) ||
                            "La fecha de término debe ser mayor o igual a la fecha de inicio"
                          );
                        },
                      })}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            minDate={startOfDay(new Date(fechaInicio)) - 1}
                            inputFormat="dd/MM/yyyy"
                            label={" "}
                            mask="__/__/____"
                            value={field.value ? startOfDay(new Date(field.value)) : null}
                            onChange={(date) => {
                              // Ajustar siempre al inicio del día
                              const fechaAjustada = startOfDay(new Date(date));
                              field.onChange(fechaAjustada);
                            }}
                            renderInput={(params) => (
                              <TextField
                                height={10}
                                className="form-control"
                                style={{ height: 10 }}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <p className="text-danger mt-3 mb-0">
                      {errors?.fechaTermino?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label className="fw-bold">Hora inicio</label>
                    <Controller
                      control={control}
                      // className="mb-3"
                      name="horaInicio"
                      {...register("horaInicio", {
                        required: "La hora de inicio es requerida",
                      })}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            value={field.value}
                            label={" "}
                            onChange={(e) =>
                              field.onChange(
                                moment(e).format("yyyy-MM-DD HH:mm:ss")
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                height={10}
                                className="form-control"
                                style={{ height: 10 }}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <p className="text-danger mt-3 mb-0">
                      {errors?.horaInicio?.message}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label className="fw-bold">Hora Término</label>
                    <Controller
                      control={control}
                      name="horaTermino"
                      {...register("horaTermino", {
                        required: "La hora de término es requerida",
                      })}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            value={field.value}
                            label={" "}
                            onChange={(e) =>
                              field.onChange(
                                moment(e).format("yyyy-MM-DD HH:mm:ss")
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                height={10}
                                className="form-control"
                                style={{ height: 10 }}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <p className="text-danger mt-3 mb-0">
                      {errors?.horaTermino?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...register("diaCompleto")}
                          />
                        }
                        label="Día Completo"
                      />
                    </FormGroup>

                    {/* <p className='text-danger mt-3'>{errors?.fecha?.message}</p> */}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...register("recurrencia.recurrente")}
                          />
                        }
                        label="Permiso Recurrente"
                      />
                    </FormGroup>
                    {/* <label className="fw-bold">Permiso Recurrente</label> */}

                    {/* <p className='text-danger mt-3'>{errors?.fecha?.message}</p> */}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row border me-1 ms-1">
                  <div className="col-3">
                    <div className="form-group">
                      {/* <label className="fw-bold">Día Completo</label> */}

                      <FormGroup>
                      
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.l")}
                            checked={watch("recurrencia.l")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.l`, isChecked);                         
                            }}
                            />
                          }
                          label="Lunes"
                        />
                      </FormGroup>

                      {/* <p className='text-danger mt-3'>{errors?.fecha?.message}</p> */}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      {/* <label className="fw-bold">Día Completo</label> */}

                      <FormGroup>
                      
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.m")}
                            checked={watch("recurrencia.m")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.m`, isChecked);                         
                            }}
                            />
                          }
                          label="Martes"
                        />
                      </FormGroup>

                      {/* <p className='text-danger mt-3'>{errors?.fecha?.message}</p> */}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      {/* <label className="fw-bold">Día Completo</label> */}

                      <FormGroup>
                      
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.x")}
                            checked={watch("recurrencia.x")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.x`, isChecked);                         
                            }}
                            />
                          }
                          label="Miércoles"
                        />
                     </FormGroup>

                      {/* <p className='text-danger mt-3'>{errors?.fecha?.message}</p> */}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      {/* <label className="fw-bold">Día Completo</label> */}

                      <FormGroup>
                      
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.j")}
                            checked={watch("recurrencia.j")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.j`, isChecked);                         
                            }}
                            />
                          }
                          label="Jueves"
                          />
                        </FormGroup>

                      {/* <p className='text-danger mt-3'>{errors?.fecha?.message}</p> */}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      {/* <label className="fw-bold">Día Completo</label> */}

                      <FormGroup>
                      
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.v")}
                            checked={watch("recurrencia.v")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.v`, isChecked);                         
                            }}
                            />
                          }
                          label="Viernes"
                        />
                      </FormGroup>

                      {/* <p className='text-danger mt-3'>{errors?.fecha?.message}</p> */}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      {/* <label className="fw-bold">Día Completo</label> */}

                      <FormGroup>
                      
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.s")}
                            checked={watch("recurrencia.s")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.s`, isChecked);                         
                            }}
                            />
                          }
                          label="Sábado"
                        />
                      </FormGroup>

                      {/* <p className='text-danger mt-3'>{errors?.fecha?.message}</p> */}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <FormGroup>
                        
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.d")}
                            checked={watch("recurrencia.d")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.d`, isChecked);                         
                            }}
                            />
                          }
                          label="Domingo"
                        />
                      </FormGroup>
                      {/* <label className="fw-bold">Permiso Recurrente</label> */}

                      {/* <p className='text-danger mt-3'>{errors?.fecha?.message}</p> */}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group" hidden>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Domingo"
                        />
                      </FormGroup>
                      {/* <label className="fw-bold">Permiso Recurrente</label> */}

                      {/* <p className='text-danger mt-3'>{errors?.fecha?.message}</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="fw-bold">Comentario</label>

                <textarea
                  className="form-control"
                  {...register("justificacion")}
                  rows={2}
                ></textarea>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <button
                  id="btn-guardar"
                  type="submit"
                  className="btn btn-primary-mem me-3"
                >
                  <SaveIcon sx={{ marginTop: -0.5, marginRight: 0.5 }} />{" "}
                  Guardar
                </button>

                <button
                  id="btn-cancelar"
                  type="button"
                  className="btn btn-danger "
                  onClick={handleClose}
                >
                  <DoNotDisturbAltIcon
                    sx={{ marginTop: -0.5, marginRight: 0.5 }}
                  />
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default PermisoNuevo;






 