import React from 'react';
import moment from 'moment';

export const Acciones = ({ acciones }) => {
  return (
    acciones.map((item, index) => (
        <div className="mt-2 pt-3 pb-2 mb-2 border ">
            <div className="row ">

                <div className="col-md-1 text-center align-self-center">
                    <h1 className="text-primary-mem">{index+1}</h1>
                </div>
                
                <div className="col-md-3 ">
                    <div className="col-md-12 text-center">
                        <label className="fw-bold">Categoría</label>
                    </div>
                    <div className="col-md-12 text-center">
                        <label className="">{item.categoriaAccion}</label>
                    </div>
                </div>

                <div className="col-md-3 ">
                    <div className="col-md-12 text-center">
                        <label className="fw-bold">Tipo</label>
                    </div>
                    <div className="col-md-12 text-center">
                        <label className="">{item.tipoAccion}</label>
                    </div>
                </div>

                <div className="col-md-2 ">
                    <div className="col-md-12 text-center">
                        <label className="fw-bold">Estado</label>
                    </div>
                    <div className="col-md-12 text-center">
                        <label className="">{item.estado}</label>
                    </div>
                </div>
                
                <div className="col-md-2 ">
                    <div className="col-md-12 text-center">
                        <label className="fw-bold">Fecha Creción</label>
                    </div>
                    <div className="col-md-12 text-center">
                       <label>{moment(item.fechaCreacion).format("DD/MM/yyyy")}</label>
                    </div>
                </div>

        </div>
    </div>
    ))
  )
}
