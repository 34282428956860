export const convertNumberPhoneToString = (phoneNumber = '') => {
    
    let phoneParse = "";

    if(phoneNumber){
        phoneParse = phoneNumber.replace(/\(|\)/g, '').replace(/ /g, "").split("-").join("").trim()
    }
    
    return phoneParse; 
};
