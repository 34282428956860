import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "../config";
import { messageFrontEnd } from "../../utils/mensajeErrorCliente";
import moment from "moment";

const initialState = {
    isLoading: false,
    isSuccess: 'idle',
    message: "",
    empleadoData: {}
}

// * LIMPIAR SUCCESS
export const fetchCambiarSuccess = createAsyncThunk("cafeteria/fetchCambiarSuccess",
    async () => {
      return null;
    }
);  

export const fetchBuscarEmpleado = createAsyncThunk("cafeteria/fetchBuscarEmpleado", 

    async (data) => {

        const {numeroEmpleado, cedula} = data;

        var resultado = await axiosApiInstance.get(`${endpoints.cafeteria.buscarEmpleado}/?codigoEmpleado=${numeroEmpleado}&cedula=${cedula}`);
        return resultado.data;
    }
)

export const fetchAgregarComidaEmpleado = createAsyncThunk("cafeteria/fetchAgregarComidaEmpleado", 

    async (empleado) => {

        var resultado = await axiosApiInstance.post(endpoints.cafeteria.agregarComida, empleado);
        return resultado.data;
    }
);

// * GENERAR EXCEL DE HISTORICO DE CAFETERIA
export const fetchGenerarReporteHistoricoCafeteria = createAsyncThunk(
    "cafeteria/fetchGenerarReporteHistoricoCafeteria",
    async (data) => {

      const { fechaDesde, fechaHasta } = data;

      const fechaDesdeParse = moment(fechaDesde).format('yyyy-MM-DD');
      const fechaHastaParse = moment(fechaHasta).format('yyyy-MM-DD');

      const result = await axiosApiInstance({
        url: `${
          endpoints.cafeteria.generarReporteHistoricoCafeteria
        }?desde=${fechaDesdeParse}&hasta=${fechaHastaParse}`, //your url
  
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
  
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `Reporte Histórico Cafetería (${fechaDesdeParse} - ${fechaHastaParse}).xls`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
  
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  
      return result;
    }
);

// * GENERAR EXCEL DE HISTORICO DE CAFETERIA
export const fetchGenerarReporteEstadisticaCafeteria = createAsyncThunk(
    "cafeteria/fetchGenerarReporteEstadisticaCafeteria",
    async (data) => {

      const { fechaDesde, fechaHasta } = data;

      const fechaDesdeParse = moment(fechaDesde).format('yyyy-MM-DD');
      const fechaHastaParse = moment(fechaHasta).format('yyyy-MM-DD');

      const result = await axiosApiInstance({
        url: `${
          endpoints.cafeteria.generarReporteEstadisticaCafeteria
        }?desde=${fechaDesdeParse}&hasta=${fechaHastaParse}`, //your url
  
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
  
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `Reporte Estadística Cafetería (${fechaDesdeParse} - ${fechaHastaParse}).xls`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
  
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  
      return result;
    }
);

// * LIMPIAR EL STATE.
export const fetchLimpiarStateEmpleado = createAsyncThunk("cafeteria/fetchLimpiarStateEmpleado",
    async () => {
      return null;
    }
); 

export const CafeteriaSlice = createSlice({
    name:"cafeteria",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(fetchCambiarSuccess.fulfilled, (state) => {
            state.isSuccess = "idle";
            state.message = '';
        })
        .addCase(fetchLimpiarStateEmpleado.fulfilled, (state) => {
            state.empleadoData = {};
        })
        .addCase(fetchBuscarEmpleado.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchBuscarEmpleado.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.empleadoData = action.payload.data ?? {};
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.payload.message;
            }

            state.isLoading = false;
        })
        .addCase(fetchBuscarEmpleado.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.empleadoData = {};
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchAgregarComidaEmpleado.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchAgregarComidaEmpleado.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
            } 
            else {
                state.isSuccess = 'error'; 
            }

            state.message = action.payload.message;
            state.isLoading = false;
        })
        .addCase(fetchAgregarComidaEmpleado.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchGenerarReporteHistoricoCafeteria.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = "idle";
            state.message = "";
          })
        .addCase(fetchGenerarReporteHistoricoCafeteria.fulfilled, (state, action) => {
            state.isSuccess = "success";
            state.message = "El reporte fue generado";
            state.isLoading = false;
        })
        .addCase(fetchGenerarReporteHistoricoCafeteria.rejected, (state, action) => {
            state.isSuccess = "error";
            state.message = action.error.message;
            state.isLoading = false;
        })
        .addCase(fetchGenerarReporteEstadisticaCafeteria.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = "idle";
            state.message = "";
          })
        .addCase(fetchGenerarReporteEstadisticaCafeteria.fulfilled, (state, action) => {
            state.isSuccess = "success";
            state.message = "El reporte fue generado";
            state.isLoading = false;
        })
        .addCase(fetchGenerarReporteEstadisticaCafeteria.rejected, (state, action) => {
            state.isSuccess = "error";
            state.message = action.error.message;
            state.isLoading = false;
        })
        ;
       
    }
})

export const selectIsLoading = (state) => state.cafeteria.isLoading;
export const selectIsSuccess = (state) => state.cafeteria.isSuccess;
export const selectMessage = (state) => state.cafeteria.message;
export const selectEmpleado = (state) => state.cafeteria.empleadoData;


export const {} = CafeteriaSlice.actions;
export default CafeteriaSlice.reducer;