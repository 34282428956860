import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';

import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography
} from '@mui/material';

import { TextField, Autocomplete } from "@mui/material";
import { Box } from "@mui/system";
import Divider from '@mui/material/Divider';
import { fetchAreasOrganizacionales, selectAllAreasOrganizacionales } from "../../../redux/AreaOrganizacional/AreaOrganizacionalSlice";
import { fetchPeriodosLista, selectAllPeriodos } from '../../../redux/Periodos/PeriodosSlice'
import { fetchGruposOcupacionalesLista, selectAllGruposOcupacionales } from '../../../redux/GruposOcupacionales/GruposOcupacionalesSlice'
import { fetchCargosAll, selectAllCargos } from '../../../redux/Cargos/CargosSlice'
import { fetchAllEmpleados, selectAllEmpleadosList } from "../../../redux/Empleados/EmpleadosSlice";
import { fetchEvaluacionNueva, fetchEvaluacionActualizar, selectMessage, fetchCambiarSuccess, selectIsSuccess } from "../../../redux/Evaluaciones/EvaluacionesSlice";
import { onKeyPressEnterOrganizacion, onKeyPressEnterEmpleado, onKeyPressEnterCargo, onKeyPressEnterGrupoOcupacional } from "../../../utils/funcionesKeyPress";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';

export const EvaluacionDetalleModal = ({open, setOpen, tipoAccion, evaluado, setEvaluado, periodo, refreshList, setEmpleado}) => {

        // * DESESTRUCTURACION DE DATOS.
        const { empleadoId, evaluadorId, cargoId, grupoOcupacional, ano, areaOrganizacionalId, evaluacionId } = evaluado;

        // ? CONDICION CREAR Y EDITAR.
        const validacionAccion = tipoAccion === 0 ? true : false;

        // ? HOOK-STATE CAMPOS
        const [periodoId, setPeriodoId] = useState(periodo);
        const [evaluadoIdState, setEvaluadoIdState] = useState(0);
        const [evaluador, setEvaluador] = useState(0);
        const [grupoOrganizacionalId, setGrupoOrganizacionalId] = useState(0);
        const [cargoDelEmpleado, setCargoDelEmpleado] = useState(0);
        const [areaId, setAreaId] = useState(0);
        const [addRequestStatus, setAddRequestStatus] = useState("idle");

        // ? HOOK-STATE LISTAS
        const [listAreaOrganizacionalesState, setListAreaOrganizacionalesState] = useState([]);
        const [listEmpleadosState, setListEmpleadosState] = useState([]);
        const [listCargosState, setCargosState] = useState([]);
        const [listGruposOcupacionalesState, setListGruposOcupacionalesState] = useState([]);

        // ? HOOK-DISPATCH
        const dispatch = useDispatch();

        // ? HOOK-SELECTOR
        const listAreaOrganizacionales = useSelector(selectAllAreasOrganizacionales);
        const listEmpleados = useSelector(selectAllEmpleadosList);
        const listCargos = useSelector(selectAllCargos);
        const listPeriodos = useSelector(selectAllPeriodos);
        const listGruposOcupacionales = useSelector(selectAllGruposOcupacionales);
        const success = useSelector(selectIsSuccess);
        const message = useSelector(selectMessage);

        // ? USE-EFFECT
        useEffect(() => {
            dispatch(fetchAllEmpleados());
            dispatch(fetchAreasOrganizacionales());
            dispatch(fetchCargosAll());
            dispatch(fetchPeriodosLista());
            dispatch(fetchGruposOcupacionalesLista());
        }, []);

        // ? USE-EFFECT PARA MOSTRAR MENSAJE DE ALERTA.
        useEffect(() => {
          
            if(success === 'success'){
                swal("Exitoso!", message, "success");
                setEmpleado('');
                refreshList();
                setOpen(false);
                dispatch(fetchCambiarSuccess());
            }
            else if (success === 'error'){
                swal("Error!", message, "error");
                dispatch(fetchCambiarSuccess());
            }

        }, [message, success])
        

        // ? USE-EFFECT PARA ENVIAR DATA A LA API.
        useEffect(() => {

            if(addRequestStatus === 'success'){
                
                const data = {
                    evaluacionId: evaluacionId !== undefined ? parseInt(evaluacionId) : 0,
                    ano: periodo,
                    empleadoId: evaluadoIdState,
                    evaluadorId: evaluador,
                    grupoOcupacionalId: grupoOrganizacionalId,
                    cargoId: cargoDelEmpleado,
                    areaOrganizacionalId: areaId,
                    estado: 0,
                }

                if(validacionAccion){
                    dispatch(fetchEvaluacionNueva(data));
                }
                else{
                    dispatch(fetchEvaluacionActualizar(data));
                }

                setAddRequestStatus('idle');
            }
          
        }, [addRequestStatus]);

        
        // ? USE-EFFECT PARA ESTABLECER LOS PARAMETROS POR DEFECTO.
        useEffect(() => {
          
            if(!validacionAccion){

                setEvaluadoIdState(empleadoId);
                setEvaluador(evaluadorId);
                setGrupoOrganizacionalId(grupoOcupacional);
                setCargoDelEmpleado(cargoId);
                setAreaId(areaOrganizacionalId);
            }
            else{
                setEvaluadoIdState(0);
                setEvaluador(0);
                setGrupoOrganizacionalId(0);
                setCargoDelEmpleado(0);
                setAreaId(0);
            }

        }, [validacionAccion])
        

        // ? USE-EFFECT QUE AÑADE LA OPCION DE VALOR POR DEFECTO A LAS SIGUIENTES LISTAS.
        useEffect(() => {
            setListAreaOrganizacionalesState([
                { id: 0, nombre: "Seleccionar Área" },
                ...listAreaOrganizacionales,
              ]);

            setListEmpleadosState([
            { id: 0, nombreLabel: 'Seleccionar Empleado' },
            ...listEmpleados,
            ]);

            setCargosState([
                { id: 0, titulo: "Seleccionar Cargo" },
                ...listCargos,
              ]);

            setListGruposOcupacionalesState([
                { id: 0, nombre: "Seleccionar Grupo" },
                ...listGruposOcupacionales,
              ]);
        }, [listAreaOrganizacionales, listEmpleados, listCargos, listPeriodos, listGruposOcupacionales])
        

        // * FUNCION USADA EN EL BOTON CANCELAR.
        const cancelAction = () => {
            setEvaluado({});
            setOpen(false);
        }

        return(
            <Dialog
            PaperProps={{ sx: { width: "35%", height: "70%" } }}
            open={open}
            maxWidth='lg'
            >
                <DialogTitle sx={{textTransform: 'uppercase'}} className='text-mem text-center'>{validacionAccion ? 'Crear' : 'Editar' } evaluación</DialogTitle>
                <Divider />
                    <DialogContent>
                        <Typography>Período</Typography>
                            <Autocomplete
                                disabled
                                className="mb-3"
                                disablePortal
                                disableClearable
                                getOptionLabel={(listPeriodos) =>
                                `${listPeriodos.ano}`
                                }
                                options={listPeriodos}
                                isOptionEqualToValue={(option, value) => option.ano === value.ano}
                                defaultValue={
                                validacionAccion ? listPeriodos.find(x => x.ano === parseInt(periodo)) 
                                                 : listPeriodos.find(x => x.ano === ano)
                                }
                                noOptionsText={"No encontrado"}
                                renderOption={(props, listPeriodos) => (
                                <Box
                                    component="option"
                                    {...props}
                                    key={listPeriodos.ano}
                                    value={listPeriodos.ano}
                                >
                                    {listPeriodos.ano}
                                </Box>
                                )}
                                onChange={(e) => setPeriodoId((e.target.value))}
                                renderInput={(params) => <TextField {...params} label={" "} />}
                                onKeyUp={(e) => setPeriodoId(parseInt(e.target.value))}
                                on
                                
                            />


                        <Typography>Evaluado</Typography>
                            <Autocomplete
                                disabled = {!validacionAccion}
                                className="mb-3"
                                disablePortal
                                disableClearable
                                getOptionLabel={(listEmpleadosState) =>
                                `${listEmpleadosState.nombreLabel}`
                                }
                                options={listEmpleadosState}  
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                defaultValue={validacionAccion ? {id: 0, nombreLabel: 'Seleccionar Empleado'} 
                                                               : listEmpleadosState.find(x => x.id === empleadoId)
                                }
                                noOptionsText={"No encontrado"}
                                renderOption={(props, listEmpleadosState) => (
                                <Box
                                    component="option"
                                    {...props}
                                    key={listEmpleadosState.id}
                                    value={listEmpleadosState.id}
                                >
                                    {listEmpleadosState.nombreLabel}
                                </Box>
                                )}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={" "}
                                />
                                )}
                                onChange={(e) => setEvaluadoIdState((parseInt(e.target.value)))}
                                onKeyUp={(e) => onKeyPressEnterEmpleado(e, listEmpleados, setEvaluadoIdState)}
                            />


                        <Typography>Evaluador</Typography>
                            <Autocomplete
                                className="mb-3"
                                disablePortal
                                disableClearable
                                getOptionLabel={(listEmpleadosState) =>
                                `${listEmpleadosState.nombreLabel}`
                                }
                                options={listEmpleadosState}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                defaultValue={validacionAccion ? {id: 0, nombreLabel: 'Seleccionar Empleado'} 
                                                               :  listEmpleadosState.find(x => x.id === evaluadorId)
                                }
                                noOptionsText={"No encontrado"}
                                renderOption={(props, listEmpleadosState) => (
                                <Box
                                    component="option"
                                    {...props}
                                    key={listEmpleadosState.id}
                                    value={listEmpleadosState.id}
                                  
                                >
                                    {listEmpleadosState.nombreLabel}
                                </Box>
                                )}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={" "}
                                />
                                )}
                                onChange={(e) => setEvaluador(parseInt(e.target.value))}
                                onKeyUp={(e) => onKeyPressEnterEmpleado(e, listEmpleados, setEvaluador)}
                            />


                        <Typography>Grupo Organizacional</Typography>
                            <Autocomplete
                                className="mb-3"
                                disablePortal
                                disableClearable
                                getOptionLabel={(listGruposOcupacionalesState) =>
                                `${listGruposOcupacionalesState.nombre}`
                                }
                                defaultValue={validacionAccion ? { nombre: "Seleccionar Grupo", id: 0 } 
                                                               :  listGruposOcupacionalesState.find(x => x.id === grupoOcupacional)}
                                options={listGruposOcupacionalesState}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                noOptionsText={"No encontrado"}
                                renderOption={(props, listGruposOcupacionalesState) => (
                                <Box
                                    component="option"
                                    {...props}
                                    key={listGruposOcupacionalesState.id}
                                    value={listGruposOcupacionalesState.id}
                                >
                                    {listGruposOcupacionalesState.nombre}
                                </Box>
                                )}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={" "}
                                />
                                )}
                                onChange={(e) => setGrupoOrganizacionalId(parseInt(e.target.value))}
                                onKeyUp={(e) => onKeyPressEnterGrupoOcupacional(e, listGruposOcupacionalesState, setGrupoOrganizacionalId)} 
                            />


                        <Typography>Cargo del Empleado</Typography>
                            <Autocomplete
                                className="mb-3"
                                disablePortal
                                disableClearable
                                getOptionLabel={(listCargosState) =>
                                `${listCargosState.titulo}`
                                }
                                defaultValue={validacionAccion ? { titulo: "Seleccionar Cargo", id: 0 } 
                                                               : listCargosState.find(x => x.id === cargoId)}
                                options={listCargosState}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                noOptionsText={"No encontrado"}
                                renderOption={(props, listCargosState) => (
                                <Box
                                    component="option"
                                    {...props}
                                    key={listCargosState.id}
                                    value={listCargosState.id}
                                >
                                    {listCargosState.titulo}
                                </Box>
                                )}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={" "}
                                />
                                )}
                                onChange={(e) => setCargoDelEmpleado(parseInt(e.target.value))}
                                onKeyUp={(e) => onKeyPressEnterCargo(e, listCargosState, setCargoDelEmpleado)}
                            />


                        <Typography>Área Organizacional</Typography>
                            <Autocomplete
                                id="box1"
                                className="mb-3"
                                disablePortal
                                disableClearable
                                getOptionLabel={(listAreaOrganizacionalesState) =>
                                `${listAreaOrganizacionalesState.nombre}`
                                }
                                options={listAreaOrganizacionalesState}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                defaultValue={ validacionAccion ? { id: 0, nombre: 'Seleccionar Área'} 
                                                                : listAreaOrganizacionalesState.find(x => x.id === areaOrganizacionalId)}
                                noOptionsText={"No encontrado"}
                                renderOption={(props, listAreaOrganizacionalesState) => (
                                <Box
                                    component="option"
                                    {...props}
                                    key={listAreaOrganizacionalesState.id}
                                    value={listAreaOrganizacionalesState.id}
                                >
                                    {listAreaOrganizacionalesState.nombre}
                                </Box>
                                )}
                                renderInput={(params) => <TextField {...params} label={" "} />}
                                onChange={(e) => setAreaId(parseInt(e.target.value))}
                                onKeyUp={(e) => onKeyPressEnterOrganizacion(e, listAreaOrganizacionalesState, setAreaId) }
                            />

                    </DialogContent>

                    <DialogActions className='justify-content-center'>
                        <button onClick={() => cancelAction()} className='btn btn-danger'><DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>CANCELAR</button>
                        <button className='btn btn-primary-mem' onClick={() => setAddRequestStatus('success')}><SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>GUARDAR</button>
                    </DialogActions>

            </Dialog>

    )
}