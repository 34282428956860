import React, { useEffect, useState } from 'react';
import TablePagination from '@mui/material/TablePagination';
import {
	fetchCargos,
	fetchCargosCount,
	fetchCambiarSuccess,
	addCargo,
	updateCargo,
	deleteCargo,
	selectCargoListFilter,
	selectIsLoading,
	selectIsSuccess,
	SelectCargosCount,
	selectMessage
} from '../../redux/Cargos/CargosSlice';

import {
	fetchAreasOrganizacionales
} from '../../redux/AreaOrganizacional/AreaOrganizacionalSlice';

import { 
	fetchGruposOcupacionales, 
	selectAllGruposOcupacionales 
} from './../../redux/Utility/UtilitySlice';

import { useSelector, useDispatch } from 'react-redux';
import { Add, Search, FilterAltOutlined,CleaningServices } from '@mui/icons-material';
import CardCargo from '../../Components/Cargos/CardCargo';
import CargoNuevo from '../../Components/Modals/Cargo/CargoNuevo';
import Loading from '../../Components/Modals/Loading/Loader';
import swal from 'sweetalert';

import { PermissionData } from '../../context/Component/PermissionData';

export const Cargos = () => {

	// * PERMISOS DEL USUARIO.
	const permisosState = PermissionData();

	// ? HOOK-DISPATCH
	const dispatch = useDispatch();

	// ? HOOK-SELECTOR
	const cargos = useSelector(selectCargoListFilter);
	const gruposOcupacionales = useSelector(selectAllGruposOcupacionales);
	const cargosCount = useSelector(SelectCargosCount);
	const isSuccess = useSelector(selectIsSuccess);
	const isLoading = useSelector(selectIsLoading);
	const message = useSelector(selectMessage);

	// ? HOOK-STATE
	const [ open, setOpen ] = useState(false);
	const [ page, setPage ] = useState(0);
	const [ rowsPerPage, setRowsPerPage ] = useState(25);
	const [skipRow, setSkipRow] = useState(0);
	const [grupoOcupacionalId,setGrupoOcupacionalId] = useState(0);
	const [cargo,setCargo] = useState("");
	const [changeCargo,setChangeCargo] = useState("");

	// * FUNCION PARA REFRESCAR LISTA
	const refreshList = () => {
		dispatch(fetchCargosCount({grupoOcupacionalId,cargo}));
		dispatch(fetchCargos({ skipRow, rowsPerPage,grupoOcupacionalId,cargo }));
	}

	// * FUNCIONES
	const handleOpen = () => {
		setOpen(true);
	};

	const handleAddCargo = (cargo) => {
		dispatch(addCargo(cargo));
	};

	const handleDeleteCargo = (cargo) => {

		swal({
			title: `Esta seguro de remover el cargo "${cargo?.titulo}"?`,
			icon: "warning",
			buttons: true,
			dangerMode: true,
			}).then((answer) => {
			if (answer) {
				dispatch(deleteCargo(cargo));
			} 
		});
		
	};

	const handleChangePage = (event, newPage) => {
		setSkipRow(newPage* rowsPerPage)
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setSkipRow(0);
	};
	const handleGrupoOcupacional = (grupo)=>{
		setGrupoOcupacionalId(grupo.target.value)
	}
	const handleCargo =()=>{
		setCargo(changeCargo);
	}

	const cleanfilter = ()=>{
		setCargo("");
		setChangeCargo("");
		setGrupoOcupacionalId(0);
	}

	useEffect(
		() => {
			if (isSuccess) {
				setOpen(false);
			}
		},
		[ isSuccess ]
	);

	useEffect(()=>{
		dispatch(fetchCargos({skipRow,rowsPerPage,grupoOcupacionalId,cargo }))
	},[skipRow,rowsPerPage,grupoOcupacionalId,cargo])

	useEffect(()=>{
		dispatch(fetchCargosCount({grupoOcupacionalId,cargo}));
		setPage(0);
		setSkipRow(0);
	},[grupoOcupacionalId,cargo])

	useEffect(() => {
		dispatch(fetchAreasOrganizacionales());
		dispatch(fetchGruposOcupacionales());
		refreshList();
	}, []);

	useEffect(() => {
    
        if(isSuccess === 'success' && message.length > 0){
          swal("Exitoso!", message, "success");
		  refreshList();
          dispatch(fetchCambiarSuccess());
        }
        else if(isSuccess === 'error'){
          swal("Error", message, "error");
          dispatch(fetchCambiarSuccess());
        }
    
    }, [isSuccess])


	return (
		<div className="container">
			<div className="row">
				<div className='mt-3'>
					<h3 className="text-primary-mem">Cargos</h3>
					<hr />
				</div>
				<div className="col-md-12  ">
					<div className="row border m-1 p-1">
						<div className="col-md-4 ps-2  align-self-center">
							{/* <Link to={'/cargos/nuevo'} className="btn btn-primary-mem">
								Nuevo Cargo <Add />
							</Link> */}
							<button hidden={!permisosState.ROLE_CREAR_CARGOS} type="buttom" className="btn btn-primary-mem" onClick={handleOpen}>
								Nuevo Cargo <Add />
							</button>
						</div>
						<div className="col-md-8  ">
							<div className="row ">
								<div className="col-md-5 ">
									<div className="input-group mt-2">
										<FilterAltOutlined className="me-3 align-self-center text-primary-mem" />
										<label className="me-2 align-self-center">Grupos </label>
										<select className="form-control" value={grupoOcupacionalId} onChange={handleGrupoOcupacional}>
											<option value={Number(0)}>---Filtrar por Grupo---</option>
											{gruposOcupacionales &&
												gruposOcupacionales.length > 0 &&
												gruposOcupacionales.map((grupo) => (
													<option key={grupo.id} value={grupo.id}>
														{grupo.nombre}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="col-md-5">
									<div className="input-group mt-2 ">
										<label className="me-2 align-self-center">Cargo</label>
										<input className="form-control" value={changeCargo} onChange={(e)=> setChangeCargo(e.target.value)} type="text" />
										<button className="btn btn-primary-mem" data-bs-toggle="tooltip" data-bs-placement="top" title="Buscar Cargo" type="button" onClick={handleCargo}>
											<Search />
										</button>
									</div>
								</div>
								<div className='col-md-2 mt-2 ps-5'>
								<button className="btn btn-danger"  type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Limpiar filtros" onClick={()=>{cleanfilter()}}>
											<CleaningServices />
										</button>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-12 mt-3">
						{cargos &&
							cargos.length > 0 &&
							cargos.map((cargo, index) => (
								<CardCargo
									handleDeleteCargo={handleDeleteCargo}
									updateCargo={updateCargo}
									gruposOcupacionales={gruposOcupacionales}
									key={cargo.id}
									cargo={cargo}
									index={index}
								/>
							))}
							<TablePagination
							component="div"
							count={cargosCount}
							page={page}
							onPageChange={handleChangePage}
							rowsPerPage={rowsPerPage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</div>
					
				</div>
				
			</div>
			
			<CargoNuevo
				handleAddCargo={handleAddCargo}
				gruposOcupacionales={gruposOcupacionales}
				open={open}
				setOpen={setOpen}
			/>
			<Loading open={isLoading} />
		</div>
	);
};

export default Cargos;
