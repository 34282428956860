import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import {
  EventNoteOutlined,
  AccessTimeFilledOutlined,
  Delete,
  BorderColor
} from "@mui/icons-material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import PermisoEditar from "../Modals/Permiso/PermisoEditar";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import swal from "sweetalert";
import { useDispatch } from "react-redux";

import { 
  aprobarPermisoEmpleadoEncargado, 
  fetchEliminarPermiso,
  rechazarPermiso 
} from "../../redux/Permisos/PermisosSlice";

import { 
	selectDiasFeriadosAnoActual
} from '../../redux/DiasFeriados/DiasFeriadosSlice';

import { calcularDiasHabiles } from "../../utils/funcionesFechasPermisos";

const colors = {
  amarillo: '#FCFF23',
  gris: '#CFCFCF',
  verde: '#00C232',
  rojo: '#FF0C00'
};

const Permisos = ({permiso, buttonActive = false}) => {

  // * DESESTRUCTURACION DE DATOS
  const {fechaInicio, fechaTermino, horaInicio, horaTermino, diaCompleto, empleado } = permiso;

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const diasFeriados = useSelector(selectDiasFeriadosAnoActual);

  // ? HOOK-STATE
  const [expanded, setExpanded] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [permisoSelected, setPermisoSelected] = useState({});

  // * FUNCION PARA ACCORDION.
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const statusPermiso = (permiso) => {

    // * DESESTRUCTURACION DE DATOS
    const { permisoEstadoId, permisoEstado } = permiso;

    return (
      <div className="d-flex justify-content-end">
        <div
          style={{
            backgroundColor:
              permisoEstadoId === 3
                ? colors.gris
                : permisoEstadoId === 2
                ? colors.amarillo
                : permisoEstadoId === 1
                ? colors.verde
                : colors.rojo,
            border: "1px solid gray",
            borderRadius: 10,
            marginRight: 5,
            marginLeft: 5,
            height: '1.1vw',
            width: '1.1vw'
          }}
        />
        <label>{ permisoEstado }</label>
      </div>
    );
  };

  useEffect(() => {
    if (Object.keys(permisoSelected).length > 0) {
      setOpenEdit(true);
    }
  }, [permisoSelected]);

  // * FUNCION PARA RECHAZAR UNA SOLICITUD DE PERMISO.
  const anularPermiso = (permisoId) => {
		swal({
		  title: "Esta seguro de anular este permiso?",
		  text: "Una vez anulado, no podrá modificar el permiso.",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		}).then((answer) => {
		  if (answer) {
        dispatch(rechazarPermiso(permisoId));
        setExpanded(false);
		  } 
    });
  };

  // * FUNCION PARA APROBAR PERMISO PARA LUEGO SER EVALUADO POR RRHH.
  const aprobarPermiso = (permisoId) => {

    swal({
      title: "Esta seguro de aprobar este permiso?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((answer) => {
      if (answer) {
        dispatch(aprobarPermisoEmpleadoEncargado(permisoId));
        setExpanded(false);
      } 
    });
  };

  // * FUNCION PARA ELIMINAR PERMISO.
  const eliminarPermiso = (permisoId) => {
    swal({
      title: "Esta seguro de eliminar este permiso?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((answer) => {
      if (answer) {
        dispatch(fetchEliminarPermiso(permisoId));
        setExpanded(false);
      } 
    });
  };

  const imageUrl = useMemo(() => {
		return `${empleado?.foto}?cacheBust=${new Date().getTime()}`;
	}, [empleado?.foto]);

  return (
    <div className="mt-3">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    marginLeft: 0,
                    border: "solid",
                    borderWidth: 0.5,
                    borderColor: "#003876",
                  }}
                  alt={`${empleado.nombres} ${empleado.apellidos} `}
                  src={imageUrl}
                />
                <label className="codigoEmpleado ms-3">
                  {permiso.empleado.codigoEmpleado}
                </label>
              </div>

              <div className="col-md-10">
                <h3 className="text-primary-mem">{`${empleado.nombres} ${empleado.apellidos}`}</h3>

                <div className="d-flex flex-row">
                  <div className="col-md-7">
                    <div className="d-flex flex-column">
                      <label> {empleado.cargoLabel}</label>
                      <label>{empleado.areaOrganizacionalLabel}</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="d-flex ">
                        <div className="col-md-6">
                          <EventNoteOutlined
                            sx={{ fontSize: 15 }}
                            className="text-primary-mem"
                          />{" "}
                          <label className="fw-bold">Tipo de permiso: </label>
                        </div>
                        <div className="col-md-6">
                          <label>{permiso.tipoPermiso.nombre}</label>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div className="col-md-6">
                          <AccessTimeFilledOutlined
                            sx={{ fontSize: 15 }}
                            className="text-primary-mem"
                          />{" "}
                          <label className="fw-bold">
                            Fecha inicio - fin:{" "}
                          </label>
                        </div>
                        <div className="col-md-6 ">
                          <label>{`${moment(permiso.fechaInicio).format(
                            "DD/MM/YYYY"
                          )} - ${moment(permiso.fechaTermino).format(
                            "DD/MM/YYYY"
                          )}`}</label>
                        </div>
                      </div>
                    </div>
                   
                  </div>

                    <div className="col">
                    {statusPermiso(permiso)}
                    </div>

                </div>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="container">
            <div className="row text-end">
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <IconButton
                  id="btn-editar-permiso"
                  className="text-primary-mem"
                  hidden={permiso.permisoEstadoId !== 3 || buttonActive}
                  onClick={() => setPermisoSelected(permiso)}
                >
                  <BorderColor fontSize="30" />
                </IconButton>

                <IconButton
                  id="btn-eliminar-permiso"
                  className="text-danger"
                  hidden={permiso.permisoEstadoId !== 3 || buttonActive}
                  onClick={() => eliminarPermiso(permiso.id)}
                >
                  <Delete fontSize="30" />
                </IconButton>

                <button 
                  id="btn-aprobar-permiso"
                  className="btn btn-success" 
                  aria-label="Aprobar Permiso"
                  hidden={permiso.permisoEstadoId !== 3 || !buttonActive}
                  onClick={() => aprobarPermiso(permiso.id)}
                >
                  <CheckIcon />
                </button>

                <button 
                  id="btn-anular-permiso"
                  className="btn btn-danger" 
                  aria-label="Anular Permiso"
                  hidden={permiso.permisoEstadoId !== 3 || !buttonActive}
                  onClick={() => anularPermiso(permiso.id)}
                >
                  <ClearIcon/>
                </button>

              </Stack>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex flex-row  ps-0">
                <div className="col-md-3 border-end border-primary ">
                  <div className="row">
                    <div className="d-flex ">
                      <div className="col-md-6">
                        <label className="fw-bold">Tipo de permiso: </label>
                      </div>
                      <div className="col-md-6">
                        <label>{permiso.tipoPermiso.nombre}</label>
                      </div>
                    </div>
                    <div className="d-flex ">
                      <div className="col-md-6">
                        <label className="fw-bold">Fecha inicio - fin: </label>
                      </div>
                      <div className="col-md-6 ">
                        <label>{`${moment(permiso.fechaInicio).format(
                          "DD/MM/YYYY"
                        )} - ${moment(permiso.fechaTermino).format(
                          "DD/MM/YYYY"
                        )}`}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 border-end border-primary ms-2 ">
                  <div className="row">
                    <div className="d-flex ">
                      <div className="col-md-4">
                        <label className="fw-bold">Día - Días: </label>
                      </div>
                      <div className="col-md-8">
                        <label>
                          <label>
                            {calcularDiasHabiles({fechaInicio, fechaTermino, diasFeriados, horaInicio, horaTermino, checkedDiaCompleto: diaCompleto})}
                          </label>
                        </label>
                      </div>
                    </div>
                    <div className="d-flex ">
                      <div className="col-md-4">
                        <label className="fw-bold">Hora inicio - fin: </label>
                      </div>
                      <div className="col-md-8">
                        <label>{`${moment(
                          permiso.horaInicio,
                          "yyyy-MM-DD HH:mm:ss"
                        ).format("hh:mm a")} - ${moment(
                          permiso.horaTermino,
                          "yyyy-MM-DD HH:mm:ss"
                        ).format("hh:mm a")}`}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 ">
                  <div className="ps-3">
                    <div className="d-flex flex-column  pe-3">
                      <label className="fw-bold">Justificación:</label>
                      {/* {empleado.emailInstitucional} */}
                      <p>{permiso.justificacion}</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <PermisoEditar
        setExpanded={setExpanded}
        setOpenEdit={setOpenEdit}
        openEdit={openEdit}
        permiso={permisoSelected}
        setPermisoSelected={setPermisoSelected}
      />
    </div>
  );
};

export default Permisos;
