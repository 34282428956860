import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, convertToPixelCrop } from 'react-image-crop';
import useScreenSize from '../../../utils/useScreenSize';
import 'react-image-crop/dist/ReactCrop.css';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 20, // Ajuste inicial al 20% del ancho para un área de recorte más pequeña
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const Cropper = ({ imageToCrop, onCropComplete }) => {
  const { width } = useScreenSize();
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [aspect, setAspect] = useState(); // Relación de aspecto vertical

  useEffect(() => {
    if (imageToCrop) {
      clearCanvas(); // Limpiar el canvas cuando se carga una nueva imagen
    }
  }, [imageToCrop]);

  const handleImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const newCrop = centerAspectCrop(width, height, aspect);
    setCrop(newCrop);
    setCompletedCrop(convertToPixelCrop(newCrop, width, height));
    updatePreview(newCrop, e.currentTarget); // Generar vista previa inmediatamente al cargar la imagen
  };

  const updatePreview = (crop, image) => {
    if (!previewCanvasRef.current || !crop || !image || crop.width <= 0 || crop.height <= 0) {
      console.warn('Recorte inválido, mostrando imagen original');
      drawOriginalImage(image); // Mostrar la imagen original si el recorte no es válido
      return;
    }

    const canvas = previewCanvasRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;

    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );
      const base64Image = canvas.toDataURL('image/jpeg');
      onCropComplete(base64Image); // Llamar a la función de callback con la imagen recortada
    }
  };

  const clearCanvas = () => {
    const canvas = previewCanvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
      canvas.width = 0; // Restablecer el ancho del canvas
      canvas.height = 0; // Restablecer el alto del canvas
    }
  };

  const drawOriginalImage = (image) => {
    const canvas = previewCanvasRef.current;
    if (canvas && image) {
      const ctx = canvas.getContext('2d');
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      const base64Image = canvas.toDataURL('image/jpeg');
      onCropComplete(base64Image); // Mostrar la imagen original en lugar del recorte
    }
  };

  return (
    <div style={{ maxWidth: '500px', margin: 'auto' }}>
      <ReactCrop
        crop={crop}
        onChange={(newCrop) => {
          setCrop(newCrop);
          updatePreview(newCrop, imgRef.current); // Generar la vista previa automáticamente mientras se ajusta el recorte
        }}
        onComplete={(c) => setCompletedCrop(c)}
        aspect={aspect}
        minWidth={width > 1300 ? 130 : 70} // Ancho mínimo del recorte
        minHeight={width > 1300 ? 130 : 120} // Alto mínimo del recorte
        style={{ maxWidth: '100%', height: 'auto' }}
      >
        <img
          ref={imgRef}
          src={imageToCrop}
          alt="Crop me"
          onLoad={handleImageLoad}
          style={{ maxWidth: '100%', height: 'auto', cursor: 'pointer' }}
        />
      </ReactCrop>

      <div>
        <canvas
          ref={previewCanvasRef}
          style={{ display: 'none' }} // Mantener el canvas oculto ya que solo lo usamos para el procesamiento de la imagen
        />
      </div>
    </div>
  );
};

export default Cropper;
