export const generarListaHabitos = (habitos) => {
    // * VARIABLE QUE ALMACENA EL ARRAY DE OBJETOS.
    let arr = [];

    for (let index = 0; index < habitos.length; index++) {
        const element = {
            id: 0,
            historicoClinicoId: 0,
            habitoId: habitos[index].id,
            frecuencia: 0,
            seleccionado: false
        }
        
        arr.push(element);
    }

    // * NUMERO DE DIVISION DE COLUMNAS
    const mitadArray = Math.floor(arr.length / 2);

    // * COLUMNAS
    const column1 = arr.slice(0, mitadArray);
    const column2 = arr.slice(mitadArray);

    return {
        listaHabitos: arr,
        columnas: {
            column1,
            column2
        }
    };
}