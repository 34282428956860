import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import HistorialLaboralEditar from "../Modals/HistorialLaboral/HistorialLaboralEditar";
import { deleteEmpleadoHistorialLaboral } from "../../redux/Empleados/EmpleadosSlice";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import {PermissionData} from '../../context/Component/PermissionData';

export const HistorialLaboral = ({ empleo, index }) => {

   // * PERMISOS DEL USUARIO.
   const permisosState = PermissionData();

  const dispatch = useDispatch();
  const [openHistorialLaboralEditar, setOpenHistorialLaboralEditar] = useState(
    false
  );
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const deleteGistorialLaboral = () => {
    swal({
      title: "Esta seguro de eliminar el historial laboral?",
      text: "Una vez eliminado, no estara nuevamente disponible!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setAddRequestStatus("success");
        dispatch(deleteEmpleadoHistorialLaboral(empleo));
      } else {
      }
    });
  };

  return (
    <div className="mt-2 pt-3 pb-2 mb-2 border ">
      <div className="row ">
        <div className="col-md-1 text-center align-self-center">
          <h1 className="text-primary-mem">{index + 1}</h1>
        </div>
        <div className="col-md-3 align-self-center">
          <h6 className="text-primary-mem">{empleo.institucion}</h6>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Fecha Inicio</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">
              {moment(empleo.fechaInicio).format("DD/MM/yyyy")}
            </label>
          </div>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Fecha término</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">
              {moment(empleo.fechaTermino).format("DD/MM/yyyy")}
            </label>
          </div>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Pública</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">{empleo.publica ? "Si" : "No"}</label>
          </div>
        </div>

        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <button
              id='btn-editar-historia-laboral'
              type="button"
              className="btn btn-sm"
              onClick={() => setOpenHistorialLaboralEditar(true)}
              hidden={!permisosState.ROLE_EDITAR_REGISTRO_EMPLEADOS}
            >
              {" "}
              <BorderColorIcon className="text-primary-mem" />
            </button>
          </div>
          <div className="col-md-12 text-center">
            {
              <button
                id='btn-eliminar-historia-laboral'
                type="button"
                className="btn btn-sm mt-2 "
                onClick={() => deleteGistorialLaboral()}
                hidden={!permisosState.ROLE_BORRAR_REGISTRO_EMPLEADOS}
              >
                {" "}
                <DeleteOutlineOutlinedIcon className="text-danger" />
              </button>
            }
          </div>
        </div>
      </div>
      <HistorialLaboralEditar
        openHistorialLaboralEditar={openHistorialLaboralEditar}
        setOpenHistorialLaboralEditar={setOpenHistorialLaboralEditar}
        historial={empleo}
        empleadoId={empleo.empleadoId}
      ></HistorialLaboralEditar>
    </div>
  );
};

export default HistorialLaboral;
