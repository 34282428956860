import React, {
  useEffect, 
  useRef, 
  useState 
} from "react";

import { 
  useDispatch,
  useSelector 
} from "react-redux";

import { useForm } from "react-hook-form";
import { Search } from "@material-ui/icons";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { BsPersonFillAdd } from "react-icons/bs";
import { Typography } from '@mui/material';
import ReactInputMask from "react-input-mask";
import { Encabezado } from "../../Components/Encabezado/Encabezado";
import { SorteoEmpleadoCard } from "../../Components/Sorteo/SorteoEmpleadoCard";

import swal from "sweetalert";
import Loading from "../../Components/Modals/Loading/Loader";

import {
  fetchAgregarEmpleadoRifa,
  fetchLimpiarStateEmpleado,
  fetchGetEmpleadoById,
  fetchCambiarSuccess,
  selectIsSuccess,
  selectIsloading,
  selectEmpleado,
  selectMessage,
  selectIsSearch,
  fetchClearIsSearch,
} from "../../redux/Sorteo/SorteoSlice";

export const AgregarEmpleadoSorteo = () => {

  // ? HOOK-STATE
  const [cedula, setCedula] = useState("");
  const [focusCedula, setFocusCedula] = useState(false);

  // ? HOOK-REF
  const refButton = useRef();
  const refButtonCancelar = useRef();
  let cedulaRef = useRef();

  // ? HOOK FORM
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      cedula: "",
      numeroEmpleado: 0,
    },
  });

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const isLoading = useSelector(selectIsloading);
  const empleadoData = useSelector(selectEmpleado);
  const success = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  const isSearch = useSelector(selectIsSearch);

  // * FUNCION PARA GUARDAR DATA DESDE EL FORMULARIO.
  const onSubmit = (data) => {
    const sendData = {
      cedula: data?.cedula.replace(/_/g, "").split("-").join(""),
      numeroEmpleado: isNaN(data?.numeroEmpleado) ? 0 : data?.numeroEmpleado,
    };

    if (sendData.numeroEmpleado === 0 && sendData.cedula.length === 0) {
      return;
    }

    dispatch(fetchGetEmpleadoById(sendData));
  };

  // * FUNCION PARA RECHAZAR UNA SOLICITUD DE PERMISO.
  const agregarEmpleadoSorteo = (empleadoId) => {
    dispatch(fetchAgregarEmpleadoRifa(empleadoId));
  };

  // * LIMPIAR INPUTS
  const resetAll = () => {
    setFocusCedula(false);
    setCedula("");

    cedulaRef.current.getInputDOMNode().focus();
    cedulaRef.current.onFocus();

    reset({
      numeroEmpleado: 0,
      cedula: "",
    });

    dispatch(fetchCambiarSuccess());
    dispatch(fetchLimpiarStateEmpleado());
    dispatch(fetchClearIsSearch());
  };

  // * HACER FOCUS EN EL CAMPOS CEDULA
  useEffect(() => {
    cedulaRef.current.getInputDOMNode().focus();
    cedulaRef.current.onFocus();
  }, []);

  useEffect(() => {
    if (cedula.length > 0) {
      setValue("cedula", cedula);
    } else if (!focusCedula) {
      cedulaRef.current.getInputDOMNode().focus();
      cedulaRef.current.onFocus();
      setFocusCedula(true);
    }
  }, [cedula]);

  // * SWEET-ALERT
  useEffect(() => {
    if (success === "success" && message.length > 0) {
      swal("Exitoso!", message, "success", {
        timer: 1200,
      });

      resetAll();
    } else if (success === "error") {
      swal("Error", message, "error");
      dispatch(fetchCambiarSuccess());
    }
  }, [success]);

  useEffect(() => {

    const { seleccionado = false } = empleadoData;

    if (Object.keys(empleadoData).length > 0 && seleccionado) {
      swal(
        "Información",
        `El empleado ${empleadoData.nombreLabel} fue registrado en el sorteo.`,
        "warning",
        {
          timer: 5000,
        }
      );

      refButtonCancelar.current.focus();
    }

    // * VALIDACION PARA EJECUTAR EL EVENTO FOCUS EN LOS BOTONES.
    if (Object.keys(empleadoData).length > 0) {
      refButton.current.focus();
    }
  }, [empleadoData]);

  return (
    <Encabezado title="AGREGAR EMPLEADO SORTEO" ano={new Date().getFullYear()}>
      <div className="card mb-4">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  <label className="form-label fw-bold">Cédula</label>

                  <ReactInputMask
                    id="empleado.cedula"
                    value={cedula}
                    ref={cedulaRef}
                    onChange={(e) => setCedula(e?.target.value ?? "")}
                    className="form-control"
                    mask="999-9999999-9"
                    overwrite
                  />

                  {errors.cedula ? (
                    <div className="text-danger">{errors.cedula.message}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label className="form-label fw-bold">
                    Código de Empleado
                  </label>
                  <input
                    id="empleado.codigoEmpleado"
                    className="form-control"
                    type="number"
                    name="numeroEmpleado"
                    {...register("numeroEmpleado", {
                      valueAsNumber: true,
                      validate: (value) =>
                        getValues().cedula.length !== 0 || value !== 0,
                    })}
                    onFocus={(event) => event.target.select()}
                  />

                  {errors.numeroEmpleado &&
                    errors.numeroEmpleado.type === "validate" && (
                      <div className="text-danger">
                        Ingrese un código válido
                      </div>
                    )}
                </div>
              </div>

              <div className="col">
                <div className="d-flex justify-content-end mt-4">
                  <div className="form-group">
                    <button id="btn-buscar" className="btn btn-primary-mem" type="submit">
                      Buscar <Search style={{ marginTop: -1.5 }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {Object.keys(empleadoData).length > 0 ? (
        <>
          <SorteoEmpleadoCard data={empleadoData} />

          <div className="d-flex justify-content-end mt-3">
            <button
              id="btn-agregar-participante"
              ref={refButton}
              className="btn btn-primary-mem me-3"
              disabled={empleadoData?.seleccionado}
              onClick={() => agregarEmpleadoSorteo(empleadoData.empleadoId)}
            >
              <BsPersonFillAdd fontSize={25} className="me-2" />
              Agregar Participante
            </button>

            <button
              id="btn-cancelar"
              ref={refButtonCancelar}
              className="btn btn-danger"
              onClick={() => resetAll()}
            >
              <NotInterestedIcon
                className="me-1"
                style={{ marginBottom: 1.5 }}
              />
              Cancelar
            </button>
          </div>
        </>
      ) : (
        Object.keys(empleadoData).length === 0 &&
        success === "success" &&
        isSearch && (
          <Typography variant="h6">El empleado no fue encontrado.</Typography>
        )
      )}

      <Loading open={isLoading} />
    </Encabezado>
  );
};
