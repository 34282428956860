import React, { useState, useEffect, useContext } from 'react';
import Loading from '../../../Components/Modals/Loading/Loader';
import { Add } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectAllTiposPermisos,
	fetchTiposPermisos,
	selectAllTiposPermisosCategorias,
	fetchTiposPermisosCategorias,
	selectIsLoading,
	selectIsSuccess,
	selectMessage,
    saveTipoPermiso,
    deleteTipoPermiso
} from '../../../redux/Permisos/PermisosSlice';
import TablesTipoPermisos from '../../../Components/Table/TableTiposPermisos';
import TipoPermisoNuevo from '../../../Components/Modals/TiposPermisos/TipoPermisoNuevo';
import swal from "sweetalert";
import {PermissionData} from '../../../context/Component/PermissionData';

export const TiposPermisos = () => {

	// * PERMISOS DEL USUARIO.
	const permisosState = PermissionData();

    const dispatch = useDispatch();
	const isLoading = useSelector(selectIsLoading);
	const message = useSelector(selectMessage);
	const isSuccess = useSelector(selectIsSuccess);
    const tiposPermisos = useSelector(selectAllTiposPermisos)
    const tiposPermisosCategorias = useSelector(selectAllTiposPermisosCategorias)
	const [ openEdit, setOpenEdit ] = useState(false);
	const [ open, setOpen ] = useState(false);
	const [ tipoPermiso, setTipoPermiso ] = useState({});
    const [addRequestStatus,setAddRequestStatus] = useState('idle');
    const [addRequestStatusDelete,setAddRequestStatusDelete] = useState('idle');

    const handleDeleteTipoPermiso = ()=>{
		
        swal({
            title: "Esta seguro de eliminar el tipo de permiso?",
            text: "Una vez eliminado, no estara nuevamente disponible!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
				setAddRequestStatusDelete("success");
				dispatch(deleteTipoPermiso(tipoPermiso))
            } else {
              
            }
          });
    }

    const handleAddTipoPermiso=(tipoPermiso)=>{
        try {
			setAddRequestStatus('success');
			dispatch(saveTipoPermiso(tipoPermiso));
		} catch (error) {
			setAddRequestStatus('error');
		}
    }
    useEffect(()=>{
        if(isSuccess==='success' && addRequestStatus==='success'){
			swal("Exitoso!", "Fue creado el tipo de permiso", "success");
			setOpen(false);
            setAddRequestStatus('idle');
		}else if(isSuccess==='error'&& addRequestStatus==='success'){
			swal("Error!", message, "error");
			setAddRequestStatus('idle');
		}
    },[isSuccess,addRequestStatus])
    useEffect(()=>{
        if(isSuccess==='success' && addRequestStatusDelete==='success'){
			swal("Exitoso!", "Fue eliminado el tipo de permiso", "success");
			setOpen(false);
            setAddRequestStatusDelete('idle');
		}else if(isSuccess==='error'&& addRequestStatusDelete==='success'){
			swal("Error!", message, "error");
			setAddRequestStatusDelete('idle');
		}
    },[isSuccess,addRequestStatusDelete])


	useEffect(() => {
        dispatch(fetchTiposPermisos())
        dispatch(fetchTiposPermisosCategorias())
    }, []);

	return (
		<div className="container-fluid main">
			<div className="row mt-2">
				<div className="col-md-12">
					<div>
						<h3 className="text-primary-mem">Tipos de Permisos</h3>
						<hr />
					</div>
					<div className=" text-end mb-3">
						<button type="button" className="btn btn-primary-mem" onClick={()=>setOpen(true)} hidden={!permisosState.ROLE_CREAR_TIPO_PERMISO}>
							Nuevo Tipo Permiso <Add />
						</button>
					</div>
					<TablesTipoPermisos
						setOpenEdit={setOpenEdit}
                        openEdit={openEdit}
						setTipoPermiso={setTipoPermiso}
						deleteTipoPermiso={handleDeleteTipoPermiso}
                        tiposPermisos ={tiposPermisos}
                        tiposPermisosCategorias={tiposPermisosCategorias}
                        tipoPermiso={tipoPermiso}
					/>
					<Loading open={isLoading} />
				</div>
			</div>
            <TipoPermisoNuevo open={open} setOpen={setOpen} tiposPermisosCategorias={tiposPermisosCategorias} handleAddTipoPermiso={handleAddTipoPermiso} ></TipoPermisoNuevo>
		</div>
	);
};

export default TiposPermisos;
