import React, { useState } from "react";
import moment from "moment";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import IdiomasEditar from "../Modals/Idiomas/IdiomaEditar";
import { deleteEmpleadoIdioma } from "../../redux/Empleados/EmpleadosSlice";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { PermissionData } from "../../context/Component/PermissionData";

export const IdiomasEmpleados = ({ idiomaEmpleado, index }) => {
    
  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  const { habla, escribe, lee, idioma } = idiomaEmpleado;
  const [openIdiomasEditar, setOpenIdiomasEditar] = useState(false);
  const dispatch = useDispatch();
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const deleteDependiente = () => {
    swal({
      title: "Esta seguro de eliminar el idioma?",
      text: "Una vez eliminado, no estara nuevamente disponible!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setAddRequestStatus("success");
        dispatch(deleteEmpleadoIdioma(idiomaEmpleado));
      } else {
      }
    });
  };

  return (
    <div className="mt-2 pt-3 pb-2 mb-2 border ">
      <div className="row ">
        <div className="col-md-1 text-center align-self-center">
          <h1 className="text-primary-mem">{index + 1}</h1>
        </div>
        <div className="col-md-3 align-self-center">
          <h6 className="text-primary-mem">{`${idioma.nombre}`}</h6>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Habla</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">{habla}</label>
          </div>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Escribe</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">{escribe}</label>
          </div>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <label className="fw-bold">Lee</label>
          </div>
          <div className="col-md-12 text-center">
            <label className="">{lee}</label>
          </div>
        </div>
        <div className="col-md-2 ">
          <div className="col-md-12 text-center">
            <button
              id='btn-editar-idioma'
              hidden={!permisosState.ROLE_EDITAR_REGISTRO_EMPLEADOS}
              type="button"
              className="btn btn-sm"
              onClick={() => setOpenIdiomasEditar(true)}
            >
              {" "}
              <BorderColorIcon className="text-primary-mem" />
            </button>
          </div>
          <div className="col-md-12 text-center">
            {
              <button
                id='btn-eliminar-idioma'
                hidden={!permisosState.ROLE_BORRAR_REGISTRO_EMPLEADOS}
                type="button"
                className="btn btn-sm mt-2 "
                onClick={() => deleteDependiente()}
              >
                {" "}
                <DeleteOutlineOutlinedIcon className="text-danger" />
              </button>
            }
          </div>
        </div>
      </div>
      <IdiomasEditar
        openIdiomasEditar={openIdiomasEditar}
        setOpenIdiomasEditar={setOpenIdiomasEditar}
        empleadoId={idiomaEmpleado.empleadoId}
        idioma={idiomaEmpleado}
      />
    </div>
  );
};

export default IdiomasEmpleados;
