import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Select from "react-select";
import { Controller, useForm, useWatch } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FaRegEye } from "react-icons/fa";
import { FaFileExcel } from "react-icons/fa";

import {
  fetchAreasOrganizacionales,
  selectAllAreasOrganizacionales,
} from "../../redux/AreaOrganizacional/AreaOrganizacionalSlice";

import { 
  fetchGruposOcupacionalesLista, 
  selectAllGruposOcupacionales 
} from '../../redux/GruposOcupacionales/GruposOcupacionalesSlice';

import { 
  fetchCargosAll, 
  selectAllCargos 
} from '../../redux/Cargos/CargosSlice';

import { estadosEmpleado } from '../../utils/estadosEmpleado';
import { camposReporteEmpleados } from '../../utils/camposReporteEmpleados';

import {
  fetchGetReporteDinamico, 
  fetchGetReporteDinamicoCount,
  fetchReporteDinamicoExcel
} from "../../redux/Empleados/EmpleadosSlice";

export const FiltroReporteEmpleado = ({setCampos, page, rowsPerPage, reporteCount, skipRow}) => {

  // ? HOOK-FORM
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch
  } = useForm({
    defaultValues: {
      filtro: {
        areaId: 0,
        grupoOcupacionalId: 0,
        cargoId: 0,
        estado: 1,
      },
      campos: 
        {
          nombres: true,
          codigoEmpleado: true,
          cedula: true,
          sexo: true,
          areaOrganizacional: false,
          estudios: false,
          cargo: false,
          supervisor: false,
          cuentaBancaria: false,
          sueldo: false,
          fechaIngreso: false,
          edad: false,
          grupoOcupacional: false,
          correoInstitucional: false,
          fechaNacimiento: false,
          celular: false,
          flota: false,
          fechaTerminoDestitucion: false,
          motivoDestitucion: false
      }
    }
  });

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const listAreaOrganizacionales = useSelector(selectAllAreasOrganizacionales);
  const listGruposOcupacionales = useSelector(selectAllGruposOcupacionales);
  const listCargos = useSelector(selectAllCargos);
  

  // ? HOOK-STATE DE LISTAS
  const [listaAreasState, setListaAreasState] = useState([]);
  const [listGruposOcupacionalesState, setListGruposOcupacionalesState] = useState([]);
  const [listCargosState, setCargosState] = useState([]);
  const [listaCamposState, setListaCamposState] = useState([]);

  // ? HOOK-STATE PARA VALIDACIONES
  const [validacionSeleccionCampos, setValidacionSeleccionCampos] = useState(false);
  const [camposInactivoHabilitado, setCamposInactivoHabilitado] = useState(false);

  // ? HOOK-WATCH
  const camposObjecto = useWatch({
    control,
    name: "campos",
  })

  // ? VARIABLE QUE ALMACENA EL ESTADO
  const estado = useWatch({
    control,
    name: "filtro.estado"});

  useEffect(() => {
    dispatch(fetchAreasOrganizacionales());
    dispatch(fetchGruposOcupacionalesLista());
    dispatch(fetchCargosAll());
  }, []);

  useEffect(() => {
    setListaAreasState([
      { id: 0, nombre: "Seleccionar Área" },
      ...listAreaOrganizacionales,
    ]);

    setListGruposOcupacionalesState([
      { id: 0, nombre: "Seleccionar Grupo" },
      ...listGruposOcupacionales,
    ]);

    setCargosState([
      { id: 0, titulo: "Seleccionar Cargo" },
      ...listCargos,
    ]);

  }, [listAreaOrganizacionales, listGruposOcupacionales, listCargos, estadosEmpleado])
  
    useEffect(() => {
      
      // * DIVISION DE COLUMNAS DEL ARRAY.
      let arr = [];
      let cont = 5;
      for (let index = 0; index < 5; index++) {

        arr = [...arr, camposReporteEmpleados.slice(index === 0 ? index : index * 5, cont)]
        cont+=5;
      }
      
      setListaCamposState(arr);
     
    }, [])

    useEffect(() => {

      // * VALIDACION DE QUE EXISTA UN CAMPO SELECCIONADO O CON VALOR TRUE.
      let isEmptyObject = Object.keys(camposObjecto).some(x => camposObjecto[x] === true);
      setValidacionSeleccionCampos(isEmptyObject);

      setCampos(camposObjecto)
      
    }, [camposObjecto])

    useEffect(() => {
      setCamposInactivoHabilitado(estado === 1 ? true : false);
    }, [estado, camposInactivoHabilitado])  

    
    useEffect(() => {
      
      if(reporteCount > 0){
        vistaPrevia();
      }

    }, [page, rowsPerPage, skipRow])

    
    useEffect(() => {
      
      if(camposInactivoHabilitado){
        const campos = watch('campos');
        campos.motivoDestitucion = false;
        campos.fechaTerminoDestitucion = false;

        setValue('campos', campos);
      }

    }, [camposInactivoHabilitado])
    
    // * FUNCION PARA SOLICITAR LA VISTA PREVIA
    const vistaPrevia = () => {

      const filtro = watch('filtro');

      dispatch(fetchGetReporteDinamico({filtro, skipRow, rowsPerPage}));
      dispatch(fetchGetReporteDinamicoCount({filtro}));

    }

    const generarExcel = () => {
      const data = {
        campos: watch('campos'),
        filtros: watch('filtro'), 
      };

      dispatch(fetchReporteDinamicoExcel(data));
    }

  return (
    <>
      
      <Paper elevation={3}>
        <Grid container p={2}>
          <Grid item xs={6}>
            <Stack p={1}>
              <Typography variant='h6'>FILTROS</Typography>
              <Divider />

              <Stack p={1} mt={2}>
                
                <Stack>
                  <Typography fontSize='14px' fontWeight='bold'>Área Organizacional</Typography>
                  <Controller
                    control={control}
                    name="filtro.areaId"
                    {...register("filtro.areaId")}
                    render={({ field }) => (
                      <Select
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Área"
                        options={listaAreasState}
                        getOptionLabel={(option) => option.nombre}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        defaultValue={field.value}
                      />
                    )}
                  />
                </Stack>

                <Stack mt={1}>
                  <Typography fontSize='14px' fontWeight='bold'>Grupo Ocupacional</Typography>
                  <Controller
                    control={control}
                    name="filtro.grupoOcupacionalId"
                    {...register("filtro.grupoOcupacionalId")}
                    render={({ field }) => (
                      <Select
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Grupo"
                        options={listGruposOcupacionalesState}
                        getOptionLabel={(option) => option.nombre}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        defaultValue={field.value}
                      />
                    )}
                  />
                </Stack>
               
                <Stack mt={1}>
                  <Typography fontSize='14px' fontWeight='bold'>Cargos</Typography>
                  <Controller
                    control={control}
                    name="filtro.cargoId"
                    {...register("filtro.cargoId")}
                    render={({ field }) => (
                      <Select
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Cargo"
                        options={listCargosState}
                        getOptionLabel={(option) => option.titulo}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        defaultValue={field.value}
                      />
                    )}
                  />
                </Stack>
              
                <Stack mt={1}>
                  <Typography fontSize='14px' fontWeight='bold'>Estado</Typography>
                  <Controller
                    control={control}
                    name="filtro.estado"
                    {...register("filtro.estado")}
                    render={({ field }) => (
                      <Select
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Estado"
                        options={estadosEmpleado}
                        getOptionLabel={(option) => option.nombre}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        defaultValue={estadosEmpleado.filter(x => x.id === field.value)}
                      />
                    )}
                  />
                </Stack>
                
              </Stack>

            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack p={1}>
              <Typography variant='h6'>CAMPOS</Typography>
              <Divider />

               <Grid mt={2}>
                  <Paper>
                    <Stack p={2} height={'270px'}>

                      <Grid container spacing={{ lg: 2, md: 1 }}>

                        {listaCamposState.length > 0 &&
                          listaCamposState.map((item, index) => (
                                
                            <Grid item key={index} xs={3}>

                              {
                                item.map((item2) => (

                                  <>
                                    {item2.campo === 'fechaTerminoDestitucion' && <Typography hidden={camposInactivoHabilitado} fontWeight='bold' fontSize='14px'>Destitución</Typography>}

                                      <Stack>
                                        <FormControlLabel
                                        hidden={(item2.campo === 'fechaTerminoDestitucion' || item2.campo === 'motivoDestitucion') && camposInactivoHabilitado ? true : false}
                                        key={item2.campo}
                                        sx={{
                                          "& .MuiTypography-root": {
                                            fontSize: { lg: 12, md: 10, xs: 11 },
                                          },
                                        }}
                                        control={
                                          <Checkbox
                                            checked={getValues('campos')[item2.campo] === true}
                                            {...register(`campos.${item2.campo}`, {
                                            })}
                                          />
                                        }
                                        label={item2?.label}
                                        />
                                      </Stack>

                                  </>

                                ))
                              
                              }

                            </Grid>
                        
                        ))}

                        <p style={{ marginLeft: 10, marginBottom: -2 }} className="text-danger">
                          {!validacionSeleccionCampos && "Debe seleccionar al menos un campo"}
                        </p>
                      </Grid>

                    </Stack>

                  </Paper>
               </Grid>

            </Stack>
          </Grid>

          <Grid item xs={12} className='d-flex justify-content-center mt-1'>
            <button onClick={() => vistaPrevia()} className='btn btn-primary-mem me-2' disabled={!validacionSeleccionCampos}>Vista previa <FaRegEye fontSize={22} style={{marginTop: -2}}/></button>                             
            <button onClick={() => generarExcel()} className='btn btn-success' disabled={!validacionSeleccionCampos}>Generar Excel <FaFileExcel fontSize={22} style={{marginTop: -3}}/></button>                             
          </Grid>

        </Grid>
      </Paper>


    </>
  )
}
