import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography,
    TextField
} from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';
import { selectUserData } from "../../../redux/Login/LoginSlice";
import { fetchBonoEscolarPorPeriodoAgregar, fetchBonoEscolarPorPeriodoEditar } from '../../../redux/Beneficios/BonoEscolar/BonoEscolarSlice';

export const AgregarBonoEscolarModal = ({open = false, setOpen, estadoAction, listaBonoEscolarPeriodo, bonoEscolarPeriodoEdit}) => {

    const {
        formState: { errors },
        control,
    } = useForm();

    //  * VALIDACION SEGUN LA ACCION (AGREGAR/EDITAR)
    const validateAction = estadoAction === 0 ? true : false;
    
    // * ULTIMA POSICION DE LA LISTA.
    const bonoEscolarPeriodo = listaBonoEscolarPeriodo[listaBonoEscolarPeriodo?.length - 1];

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK SELECTOR
    const usuario = useSelector(selectUserData);
    
    // ? HOOK-STATE
    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [data, setData] = useState({
        bonoEscolarId: 0,
        ano: 0,
        creadoPor: 0,
        fechaInicio: new Date(),
        fechaTermino: new Date(),
        estado: 0
    });
    
    // * FUNCION PARA OBTENER LA FECHA DEL PROXIMO PERIODO DE EVALUACION A CREAR.
    const obtenerFecha = () => new Date().setFullYear(bonoEscolarPeriodo?.ano + 1);
    
    // * FUNCION USADA EN EL BOTON CANCELAR.
    const cancelAction = () => {
        setOpen(false);
    }

    // * FUNCION PARA ENVIAR LA DATA A API.
    const sendData = () => setAddRequestStatus('success');
    
    // ? HOOK-EFFECT
    useEffect(() => {

        let changedData = {
            bonoEscolarId: 0,
            ano: 0,
            creadoPor: usuario?.Id,
            fechaInicio: new Date(),
            fechaTermino: new Date(),
            estado: 0
        }
        
        if(estadoAction === 0){

            changedData.ano = bonoEscolarPeriodo?.ano + 1;
            changedData.fechaInicio = obtenerFecha();
            changedData.fechaTermino = obtenerFecha();
            
            // * GUARDANDO DATA EN EL USE-STATE.
            setData(changedData)
        }
        else if(estadoAction === 1){

            changedData.bonoEscolarId = bonoEscolarPeriodoEdit?.bonoEscolarId
            changedData.ano = bonoEscolarPeriodoEdit?.ano;
            changedData.fechaInicio = bonoEscolarPeriodoEdit?.fechaInicio;
            changedData.fechaTermino = bonoEscolarPeriodoEdit?.fechaTermino;

            // * GUARDANDO DATA EN EL USE-STATE.
            setData(changedData)
        }

    }, [bonoEscolarPeriodo, validateAction, bonoEscolarPeriodoEdit])


    // * EFFECT PARA ENVIAR DATA A LA API.
    useEffect(() => {
      
        if(addRequestStatus === 'success'){
            if(data.bonoEscolarId === 0){
                dispatch(fetchBonoEscolarPorPeriodoAgregar(data))
            }
            else{
                dispatch(fetchBonoEscolarPorPeriodoEditar(data))
            }
        }

        // * CAMBIO DE ESTADO.
        setAddRequestStatus('idle');

    }, [addRequestStatus])
    

  return (
    <Dialog
        PaperProps={{ sx: { width: "30%", height: "40%" } }}
        open={open}
        maxWidth='lg'
        >

        <DialogTitle sx={{textTransform: 'uppercase'}} className='text-mem text-center'>{validateAction ? 'Nuevo' : 'Editar' } bono escolar</DialogTitle>
        <Divider />

        <DialogContent>

            <Typography>Año</Typography>
            <input value={data.ano} variant="outlined" sx={{width: '100%'}} className='mb-2 form-control' disabled />

            <Typography>Fecha Inicio</Typography>
                <Controller
                    control={control}
                    name="ReactDatepicker"
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            name='fechaInicio'
                            label={" "}
                            mask="__/__/____"
                            value={data.fechaInicio}
                            onChange={(e) => setData({...data, fechaInicio: e})}
                            renderInput={(params) => (
                            <TextField
                                height={10}
                                className="form-control"
                                style={{ height: 10 }}
                                {...params}
                            />
                            )}
                        />
                        </LocalizationProvider>
                    )}
                />

            <Typography className='mt-4'>Fecha Término</Typography>
                <Controller
                    control={control}
                    name="ReactDatepicker"
                    render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            name='fechaTermino'
                            label={" "}
                            mask="__/__/____"
                            value={data.fechaTermino}
                            onChange={(e) => setData({...data, fechaTermino: e})}
                            renderInput={(params) => (
                            <TextField
                                height={10}
                                className="form-control"
                                style={{ height: 10 }}
                                {...params}
                            />
                            )}
                        />
                        </LocalizationProvider>
                    )}
                />
            
        </DialogContent>

        <DialogActions className='justify-content-center'>
                <button onClick={() => cancelAction()} className='btn btn-danger'><DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>CANCELAR</button>
                <button onClick={() => sendData()} className='btn btn-primary-mem'><SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>GUARDAR</button>
        </DialogActions>

    </Dialog>
  )
}
