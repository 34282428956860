import React from 'react';
import  Avatar  from '@mui/material/Avatar';
import {useSelector} from "react-redux"
import moment from 'moment';
import { selectEmpleadosIsLoading } from '../../redux/Empleados/EmpleadosSlice';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {useNavigate} from "react-router-dom"
import { endpoints } from '../../redux/config';
import {PermissionData} from '../../context/Component/PermissionData';

export const EmpleadoPerfil = (props) => {

    // * PERMISOS DEL USUARIO.
    const permisosState = PermissionData();

    const isLoading = useSelector(selectEmpleadosIsLoading);
    const navigate = useNavigate();
    const {empleado} = props;

	return (
		<div className="container">
			
            {!isLoading && empleado&& Object.keys(empleado).length>0?(
                  <div className="row ">
  <div className="col-md-2 text-center ">
      <Avatar
          sx={{
              width: 150,
              height: 150,
              marginLeft: 1,
              border: 'solid',
              borderWidth: 1,
              borderColor: '#003876',
              borderRadius:3
          }}
          alt={`${empleado?.nombres} ${empleado?.apellidos} `}
            src={empleado.foto}
          variant="square"
      />
      <label className="codigoEmpleado">{empleado?.codigoEmpleado}</label>
  </div>

  <div className="col-md-10">
      <div className='row'>
          <div className='col-11'>
             <h3 className='text-primary-mem'>{`${empleado?.nombres} ${empleado?.apellidos}`}</h3>
          </div>
          <div className='col-1'>
          <button type="button" className="btn btn-sm" onClick={()=>navigate(`/empleados/editar/${empleado.id}`)} hidden={!permisosState.ROLE_EDITAR_REGISTRO_EMPLEADOS}>
                    <BorderColorIcon className="text-primary-mem" />
                </button>
          </div>
             
      </div>
      <div className='row'>
          <div className='col'>
              <div className='form-group'>
                  <label className='fw-bold'>Tipo de Empleado</label>
                  <input className='form-control' readOnly defaultValue={empleado?.tipoEmpleado?.tipo}></input>
              </div>
          </div>
          <div className='col-4'>
          <div className='form-group'>
                  <label className='fw-bold'>Cargo</label>
                  <input className='form-control' readOnly defaultValue={empleado?.cargoLabel}></input>
              </div>
          </div>
          <div className='col-4'>
            <div className='form-group'>
                    <label className='fw-bold'>Grupo Ocupacional</label>
                    <input className='form-control' readOnly defaultValue={empleado?.grupoOcupacionalLabel}></input>
                </div>
            </div>
          <div className='col'>
          <div className='form-group'>
                  <label className='fw-bold'>Fecha Ingreso</label>
                  <input className='form-control' readOnly defaultValue={moment(empleado?.fechaIngreso).format('DD/MM/yyyy') }></input>
              </div>
          </div>
      </div>
      <div className='row'>
          <div className='col'>
              <div className='form-group'>
                  <label className='fw-bold'>Cédula</label>
                  <input className='form-control' readOnly defaultValue={empleado?.cedula}></input>
              </div>
          </div>
          <div className='col'>
          <div className='form-group'>
                  <label className='fw-bold'>Correo Personal</label>
                  <input className='form-control' readOnly defaultValue={empleado?.emailPersonal}></input>
              </div>
          </div>
          <div className='col'>
          <div className='form-group'>
                  <label className='fw-bold'>Fecha Nacimiento</label>
                  <input className='form-control' readOnly defaultValue={moment(empleado?.fechaNacimiento).format('DD/MM/yyyy') }></input>
              </div>
          </div>
      </div>
      <div className='row'>
          <div className='col'>
              <div className='form-group'>
                  <label className='fw-bold'>Sexo</label>
                  <input className='form-control' readOnly defaultValue={empleado?.sexo}></input>
              </div>
          </div>
          <div className='col'>
          <div className='form-group'>
                  <label className='fw-bold'>Estado Civil</label>
                  <input className='form-control' readOnly defaultValue={empleado?.estadoCivil?.nombre}></input>
              </div>
          </div>
          <div className='col'>
          <div className='form-group'>
                  <label className='fw-bold'>Celular</label>
                  <input className='form-control' readOnly defaultValue={empleado?.celular }></input>
              </div>
          </div>
      </div>
      <div className='row'>
          <div className='col-6'>
            <div className='form-group'>
                    <label className='fw-bold'>Área Organizacional</label>
                    <input className='form-control' readOnly defaultValue={empleado?.areaOrganizacionalLabel}></input>
                </div>
            </div>
            <div className='col-6'>
                <div className='form-group'>
                    <label className='fw-bold'>Área de Reporte</label>
                    <input className='form-control' readOnly defaultValue={empleado?.nombredelPadre}></input>
                </div>
            </div>
      </div>
      <div className='row'>

        <div className='col-4'>
            <div className='form-group'>
                <label className='fw-bold'>Correo Institucional</label>
                <input className='form-control' readOnly defaultValue={empleado?.emailInstitucional}></input>
            </div>
        </div>

        <div className='col-8'>
            <div className='form-group'>
                <label className='fw-bold'>Dirección</label>
                <input className='form-control' readOnly defaultValue={empleado?.direccion}></input>
            </div>
        </div>

      </div>

      <div className='row'>
        <div className='col-4'>
            <div className='form-group'>
                <label className='fw-bold'>Flota</label>
                <input className='form-control' readOnly defaultValue={empleado?.flota}></input>
            </div>
        </div>
        <div className='col-8'>
            <div className='form-group'>
                <label className='fw-bold'>Nombre del Supervisor</label>
                <input className='form-control' readOnly defaultValue={empleado?.nombredelSupervisor}></input>
            </div>
        </div>
    </div>

    <div className='row mt-4' hidden={empleado?.estado === 1}>
        <div>
            <label className='fw-bold'>Desvinculación</label>
        </div>
        <div className='col-4'>
          <input readOnly className='form-control' value={moment(empleado?.fechaTermino).format('DD/MM/yyyy')} />
        </div>
    </div>

  </div>
                  </div>
            ):(
                <div className="row text-center">
                        <h1 className="text-primary-mem">Empleado no encontrado favor verificar</h1>
                </div>
                
            )}
            
          
		</div>
	);
};

export default EmpleadoPerfil;
