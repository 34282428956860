import React, { useState, useEffect } from "react";
import HorariosCard from "../../../Components/Cards/HorariosCard";
import HorarioNuevo from "../../../Components/Modals/Horario/HorarioNuevo";
import { Add } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";

import {
  fetchHorarios,
  selectAllHorarios,
  selectIsloading,
  selectIsSuccess,
  selectMessage,
  deleteHorario,
} from "../../../redux/Horarios/HorarioSlice";
import Loading from "../../../Components/Modals/Loading/Loader";
import { Encabezado } from '../../../Components/Encabezado/Encabezado';

import { PermissionData } from "../../../context/Component/PermissionData";

export const Horarios = () => {

  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  const [open, setOpen] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsloading);
  const isSuccess = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  const horarios = useSelector(selectAllHorarios);

  const eliminarHorario = (horario) => {
    swal({
      title: "Esta seguro de eliminar el horario?",
      text: "Una vez eliminado, no estara nuevamente disponible!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setAddRequestStatus("success");
        dispatch(deleteHorario(horario));
      } else {
      }
    });
  };
  useEffect(() => {
    dispatch(fetchHorarios());
  }, []);

  useEffect(() => {
    if (isSuccess === "success" && addRequestStatus === "success") {
      swal("Exitoso!", "Fue eliminado el horario especificado", "success");
      setAddRequestStatus("idle");
    } else if (isSuccess === "error" && addRequestStatus === "success") {
      swal("Error!", message, "error");
      setAddRequestStatus("idle");
    }
  }, [isSuccess, addRequestStatus]);

  const Horarios = () => {
    return horarios.length > 0 ? (
      horarios.map((horario) => (
        <div key={horario.id} className="col-12 col-md-6  col-lg-4">
          <HorariosCard
            eliminarHorario={eliminarHorario}
            horario={horario}
          ></HorariosCard>
        </div>
      ))
    ) : (
      <h3>No existen horarios creados</h3>
    );
  };

  return (
  
    <Encabezado title='Horario'>

      <div className="row  m-1 p-1 text-end">
        <div className="ps-2  align-self-center">
          <button
            id="btn-nuevo-horario"
            hidden={!permisosState.ROLE_CREAR_HORARIO}
            type="button"
            className="btn btn-primary-mem"
            onClick={() => setOpen(true)}
          >
            <Add></Add> Nuevo Horario
          </button>
        </div>
      </div>

      <div className="row">
        <Horarios />
      </div>
      
      <HorarioNuevo open={open} setOpen={setOpen}></HorarioNuevo>
      <Loading open={isLoading}></Loading>

    </Encabezado>
    
  );
};

export default Horarios;
