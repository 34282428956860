import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    iconButton: {
      display: "flex",
      flexDirection: "column",
      height: "2vw",
      width: "2vw",
  
      [theme.breakpoints.down("md")]: {
        height: "1.5vw",
        width: "1.5vw",
        marginTop: 5,
        marginRight: "-0.4vw",
      },
    },
    icon: {
      color: 'primary',
      [theme.breakpoints.down("md")]: {
        transform: "scale(1)",
      },
    },
  }));

  // * COMPONENTE PERSONALIZADO DE ICONO.
  export const IconButtonText = ({ title = "", Icon, func, marginTopRequired, color, params, style, id = "" })  => {

    const classes = useStyles();
    const validationSpace = title === '' ? true : false;

    // * FUNCION PARA HACER EL ON-CLICK CON PARAMETROS.
    const openFunc = () => func(params);

    return  (

        <div className="mt-4 mb-3">
          <IconButton id={id} classes={{ label: classes.iconButton }} onClick={openFunc} style={marginTopRequired ? {marginTop: -2} : style != null ? style : {}}>
            <Icon color="primary" className={classes.icon} sx={color != '' ? {color: color } : {}} />
            <p hidden={validationSpace} style={{ fontSize: "1vw", fontWeight: "bold" }}>{title}</p>
          </IconButton>
        </div>
      )

  };