  // * FUNCION PARA CALCULAR VALORES SEGUN EL GRUPO OCUPACIONAL.
  export const calcGrupoOcupacionalLogroMetas = (value, grupoOcupacional) => {
    return grupoOcupacional === 5 ? (value * 100) / 65 : (value * 100) / 55;
  };

  // * FUNCION PARA CALCULAR LAS COMPETENCIAS SEGUN EL GRUPO OCUPACIONAL.
  export const calcGrupoOcupacionalCompetencias = (value, grupoOcupacional) => {
    return grupoOcupacional === 5 ? value * (100 / 20) : value * (100 / 30);
  };

  // * FUNCION PARA CALCULAR EL TOTAL DE EVALUACIONES SEGUN EL GRUPO OCUPACIONAL A PARTIR DEL 2023.
  export const calcTotalEvaluaciones2023 = (value, grupoOcupacional) => {
    return grupoOcupacional === 5
      ? Math.round((value * 100) / 65)
      : Math.round((value * 100) / 55);
  };

  // * FUNCION PARA CALCULAR VALORES SEGUN EL GRUPO OCUPACIONAL.
  export const calcGrupoOcupacionalCompetenciasComportamientos2024 = (value, grupoOcupacional) => {
   return grupoOcupacional === 5 ? (value * 100) / 35 : (value * 100) / 45;
  }
