
import {useState} from 'react'

export const usePaginacion = ({setEmpleadosPagina, listaEmpleadosPonches}) => {

    const [contador, setContador] = useState(0);

    const siguientePagina = () => {

        let Arr = [];
   
        let limite = contador;
        limite = limite + 20;
    
        for (let i = contador; i < limite; i++) {
          
        if(listaEmpleadosPonches[i] != undefined){
        Arr.push(listaEmpleadosPonches[i]);
        }
      }
    
        setEmpleadosPagina(Arr);
        setContador(limite);
      }
    
      const anteriorPagina = () => {
    
        let Arr = [];
  
        let limite = contador;
        limite = limite - 20;
    
        for (let i = limite - 20; i < limite; i++) {
          
        if(listaEmpleadosPonches[i] != undefined){
        Arr.push(listaEmpleadosPonches[i]);
        }
      }
    
        setEmpleadosPagina(Arr);
        setContador(limite);
      }
    
    

  return{
    contador,
    setContador,
    siguientePagina,
    anteriorPagina
  }
}
