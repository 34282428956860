import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import BorderColor from '@mui/icons-material/BorderColor';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { 
	fetchAreasOrganizacionales,
	fetchAreaOrganizacionalActualizar,
	selectAllAreasOrganizacionales
} from '../../../redux/AreaOrganizacional/AreaOrganizacionalSlice';

import { 
	fetchCargosLista,
	selectAllCargosLista 
} from '../../../redux/Cargos/CargosSlice';

import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};

const AreaOrganizacionalEditar = ({ openEdit, setOpenEdit,area }) => {

    const { register, handleSubmit, formState: { errors }, reset,setValue} = useForm();
	const dispatch = useDispatch();
	const areasOrganizacionales = useSelector(selectAllAreasOrganizacionales);
	const cargos = useSelector(selectAllCargosLista);

    const onSubmit = (data) =>{
		
		const dataAPI = {
			id: data?.id,
			nombre: data?.nombre,
			encargadoId: data?.encargadoId,
			padre: data?.padre,
			lft: 0,
			rgt: 0,
			lvl: 0,
			rootId: 0
		}
		
		dispatch(fetchAreaOrganizacionalActualizar(dataAPI));

	};

	const handleClose = () => {
		cleanInput()
		setOpenEdit(false);
	};

	useEffect(()=>{
		dispatch(fetchAreasOrganizacionales());
		dispatch(fetchCargosLista());
	},[])

	useEffect(()=>{

		if(area && Object.keys(area).length>0) {

			setValue('id', area.id)
			setValue('nombre', area.nombre.replaceAll('-',""))
			setValue('encargadoId', area.encargadoId == 0 ? "": area.encargadoId)
			setValue('padre', area.padre == 0 ? "": area.padre)
		}

	}, [area])

	const cleanInput = () =>{
		reset({
			nombre:"",
			padre:"",
			encargadoId:""
		})
	}

	return (
		<Modal
			open={openEdit}
			onClose={handleClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
		>
			<Box sx={{ ...style, width: 700 }}>
			   <form onSubmit={handleSubmit(onSubmit)}>
            	<div className="container">
					<div className="row">
						<h3 className="text-primary-mem text-center">Editar  Área Organizacional</h3>
					</div>
					<div className="row">
						<div className="col-5 me-0 pe-0">
							<hr />
						</div>
						<div className="col-2 text-center">
							<BorderColor className="text-primary-mem" />
						</div>
						<div className="col-5 ms-0 ps-0 ">
							<hr />
						</div>
					</div>
                    <div className='row'>
                        <div className='form-group'>
                            <label className='fw-bold'>Nombre</label>
                            <input type="text" {...register("nombre",{required:"El nombre es requerido"})}  className='form-control'></input>
                            <p className='text-danger'>{errors.nombre?.message}</p>
                        </div>
                        <div className='form-group'>
                            <label className='fw-bold'>Área Padre</label>
                            <select {...register("padre")} className='form-control'>
                                <option value="">Seleccionar padre</option>
                                {
                                   areasOrganizacionales && areasOrganizacionales.length>0&&  areasOrganizacionales.map((area)=><option key={area.id} value={area.id}>{area.nombre}</option>)
                                }
                            </select>
                        </div>
						<div className='form-group'>
                            <label className='fw-bold'>Cargo Supervisa</label>
                            <select {...register("encargadoId")} className='form-control'>
                                <option value="">Seleccionar Cargo</option>
                                {
                                   cargos && cargos.length>0&&  cargos.map((cargo)=><option key={cargo.id} value={cargo.id}>{cargo.titulo}</option>)
                                }
                            </select>
                        </div>
                        <div className='d-flex justify-content-center mt-3'>
                        <button type='submit' className='btn btn-primary-mem me-3'> <SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}}/> Guardar</button>
                        <button type='button' className='btn btn-danger ' onClick={handleClose}> <DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}}/> Cancelar</button>
                        </div>
                    </div>
				</div>
                </form>
	
			</Box>
		</Modal>
	);
};

export default AreaOrganizacionalEditar;
			// ):(
				// 	<label>2</label>
				// // <Loading open={false}/>
				// )}