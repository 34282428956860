import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Add from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../Loading/Loader';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';
import Select from "react-select";
import { Controller } from "react-hook-form";

import { 
	fetchAreasOrganizacionales,
	selectAllAreasOrganizacionales,
	fetchAreaOrganizacionalAgregar,
	selectIsLoading,
} from '../../../redux/AreaOrganizacional/AreaOrganizacionalSlice';

import { 
	fetchCargosAll,
	selectAllCargos 
} from '../../../redux/Cargos/CargosSlice';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};

const AreaOrganizacionalNuevo = ({ open, setOpen }) => {

    const { register, handleSubmit, formState: { errors }, reset, control} = useForm();

	// ? HOOK-DISPATCH
	const dispatch = useDispatch();

	// ? HOOK-SELECTOR
	const areasOrganizacionales = useSelector(selectAllAreasOrganizacionales);
	const isLoading = useSelector(selectIsLoading);
	const cargos = useSelector(selectAllCargos);
    
	const onSubmit = (data) =>{

		const dataAPI = {
			id: 0,
			nombre: data?.nombre,
			encargadoId: data?.encargadoId,
			padre: data?.padre,
			lft: 0,
			rgt: 0,
			lvl: 0,
			rootId: 0
		}

		dispatch(fetchAreaOrganizacionalAgregar(dataAPI));
	};

	const handleClose = () => {
		cleanInput()
		setOpen(false);
	};
	useEffect(() =>{
		dispatch(fetchAreasOrganizacionales());
		dispatch(fetchCargosAll())
	},[])

	const cleanInput = () =>{
		reset({
			nombre:"",
			padre:"",
			encargadoId:""
		})
	}

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
		>
			<Box sx={{ ...style, width: 700 }}>
			   {!isLoading?(
			   <form onSubmit={handleSubmit(onSubmit)}>
            	<div className="container">
					<div className="row">
						<h3 className="text-primary-mem text-center">Nueva Área Organizacional</h3>
					</div>
					<div className="row">
						<div className="col-5 me-0 pe-0">
							<hr />
						</div>
						<div className="col-2 text-center">
							<Add className="text-primary-mem" />
						</div>
						<div className="col-5 ms-0 ps-0 ">
							<hr />
						</div>
					</div>
                    <div className='row'>

                        <div className='form-group'>
                            <label className='fw-bold'>Nombre</label>
                            <input type="text" {...register("nombre",{required:"El nombre es requerido"})}  className='form-control'></input>
                            <p className='text-danger'>{errors.nombre?.message}</p>
                        </div>

                        <div className='form-group'>
							
                            <label className='fw-bold'>Área Padre</label>

							<Controller
								control={control}
								className="mb-3"
								name="padre"
								{...register("padre", {
									required: "El área es requerida",
								})}
								render={({ field }) => (
									<Select
									classNamePrefix="select"
									isSearchable={true}
									placeholder="Seleccionar Área Padre"
									options={areasOrganizacionales}
									getOptionLabel={(option) => option.nombre}
									getOptionValue={(option) => option.id}
									onChange={(e) => field.onChange(e.id)}
									defaultValue={field.value}
									/>
								)}
							/>

                			<p className="text-danger mb-0">{errors.padre?.message}</p>

                        </div>

						<div className='form-group mt-3'>
                            <label className='fw-bold'>Cargo Supervisa</label>
							
							<Controller
								control={control}
								name="encargadoId"
								{...register("encargadoId", {
									required: "El cargo es requerido.",
								})}
								render={({ field }) => (
									<Select
									classNamePrefix="select"
									isSearchable={true}
									placeholder="Seleccionar Cargo"
									options={cargos}
									getOptionLabel={(option) => option.titulo}
									getOptionValue={(option) => option.id}
									onChange={(e) => field.onChange(e.id)}
									defaultValue={field.value}
									/>
								)}
							/>

                			<p className="text-danger mb-0">{errors.encargadoId?.message}</p>

                        </div>

                        <div className='d-flex justify-content-center mt-3'>
							<button type='submit' className='btn btn-primary-mem me-3'> <SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}}/> Guardar</button>
							<button type='button' className='btn btn-danger ' onClick={handleClose}> <DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}}/> Cancelar</button>
                        </div>
                    </div>
				</div>
                </form>
				):(
				<Loading open={isLoading}/>
				)}
			</Box>
		</Modal>
	);
};

export default AreaOrganizacionalNuevo;
