import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "../config";

const initialState = {
    isLoading: false,
    message: "",
    isSuccess: 'idle',
    tipoAcciones: [],
}

export const fetchGetTipoAccionesAll = createAsyncThunk("periodos/fetchGetTipoAcciones", async () => {
    let resultado = await axiosApiInstance.get(endpoints.tipoAccion.getListaTipoAcciones);
    return resultado.data;
});

const TiposAccionesSlice = createSlice({
    name:"tipoAcciones",
    initialState,
    extraReducers(builder){
        builder.addCase(fetchGetTipoAccionesAll.pending,(state, action)=>{
            state.isLoading = true;
            state.message = "";
            state.isSuccess= 'idle';
        })
        .addCase(fetchGetTipoAccionesAll.fulfilled,(state, action)=>{
            
            if(action.payload.isSuccess){
                state.tipoAcciones = action.payload.data;
                state.isSuccess = 'success';
            }
            else
            {
                state.isSuccess = 'error';
                state.tipoAcciones = [];
                state.message = action.payload.message;
            }

            state.isLoading = false;
        })
        .addCase(fetchGetTipoAccionesAll.rejected,(state, action)=>{
            state.isLoading = false;
            state.message = action.error.message;
            state.isSuccess = 'error' 
            state.tipoAcciones = [];
        });     
    }
})

export const selectListTipoAcciones = (state) => state.tipoAcciones.tipoAcciones;
export const selectIsSuccess = state => state.tipoAcciones.isSuccess;
export const selectIsloading = state => state.tipoAcciones.isLoading;
export const selectMessage = state => state.tipoAcciones.message;

export const {} = TiposAccionesSlice.actions;
export default TiposAccionesSlice.reducer;