import React, { useEffect, useState } from "react";
import { usePaginacion } from "../../pages/Control/Reportes/hook/usePaginacion";
import "./estilos/style.css";

export const FiltroDepartamentoReporteAsistenciaCard = ({
  listaEmpleadosPonches,
  setEmpleado,
  empleado,
  area
}) => {
  
  // ? HOOK-STATE
  // * HOOK-STATE QUE ALMACENA UNA LISTA DE EMPLEADOS.
  const [empleadosPagina, setEmpleadosPagina] = useState([]);

  // ? CUSTOM-HOOK DE PAGINACION.
  const { contador, setContador, siguientePagina, anteriorPagina } = usePaginacion({ setEmpleadosPagina, listaEmpleadosPonches });

  // ? HOOK-EFFECT
  // * HOOK-EFFECT QUE CAMBIA EL VALOR DEL STATE-EMPLEADOS Y LE ASIGNA LA PRIMERA POSICION DE LA
  // * LISTA PARA MOSTRAR LAS INFORMACIONES EN LA PANTALLA.
  useEffect(() => {
    let Arr = [];
    setEmpleado(listaEmpleadosPonches[0]);

    if (listaEmpleadosPonches.length >= 20) {
      for (let i = 0; i < 20; i++) {
        if (listaEmpleadosPonches[0] != undefined) {
          Arr.push(listaEmpleadosPonches[i]);
        }
      }
      
      setContador(20);
      setEmpleadosPagina(Arr);
    } else {
      setContador(listaEmpleadosPonches.length);
      setEmpleadosPagina(listaEmpleadosPonches);
    }
  }, [listaEmpleadosPonches]);


  // * CONSTANTE QUE ALMACENA EL AREA ORGANIZACIONES SEGUN LA VALIDACION.
  const areaOrganizacional = area != null ? area : listaEmpleadosPonches[0]?.areaOrganizacionalLabel;

  return (
    <div className="card mb-4 card-size">
      <div
        className="card-header fw-bold"
        style={{ backgroundColor: "#e2edfc" }}
      >
        <i className="fas fa-chart-bar me-1"></i>
        {areaOrganizacional}
      </div>
      <div className="card-body">
        <div className="row">
          {listaEmpleadosPonches &&
            listaEmpleadosPonches.length > 0 &&
            empleadosPagina.map((emp) => (
              <div key={emp.id} className="col-3 mb-2">
                <button
                  id="btn-empleado"
                  className={emp.id === empleado?.id 
                    ? "btn btn-outline-blue btn-sm active" 
                    : "btn btn-outline-blue btn-sm"
                  }
                  onClick={() => setEmpleado(emp)}
                >
                  {emp.nombres}
                </button>
              </div>
            ))}
        </div>

        {listaEmpleadosPonches.length > 20 && (
          <>

            <hr className="hr-card" />

            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
         
                <button
                  id="btn-anterior"
                  className={`${
                    contador > 20
                      ? "btn btn-primary-mem"
                      : "btn btn-primary-mem disabled"
                  }`}
                  onClick={() => anteriorPagina()}
                >
                  Anterior
                </button>
               
                <button
                  id="btn-siguiente"
                  className={`${
                    contador < listaEmpleadosPonches.length
                      ? "btn btn-primary-mem"
                      : "btn btn-primary-mem disabled"
                  }`}
                  onClick={() => siguientePagina()}
                >
                  Siguiente
                </button>
              
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FiltroDepartamentoReporteAsistenciaCard;
