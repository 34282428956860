import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Encabezado } from '../../Components/Encabezado/Encabezado';
import TablePagination from '@mui/material/TablePagination';
import { IconButtonText } from '../../Components/IconPersonalize/IconButtonText';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { meses } from '../../utils/meses';
import swal from 'sweetalert';
import Loading from "../../Components/Modals/Loading/Loader";

import { 
    fetchGenerarListaNominaDetalle, 
    fetchEliminarNomina,
    fetchCambiarSuccess,
    selectListaNominasDetalle,
    selectIsSuccess,
    selectMessage,
    selectIsLoading 
} from '../../redux/Nominas/NominaSlice';


export const EliminarNomina = () => {

    // ? HOOK-STATE DE PAGINACION.
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(25);
    const [ skipRow, setSkipRow ] = useState(0);
    const [ listaNominasDetallesLength, setListaNominasDetallesLength ] = useState(0);
    const [listaNominasDetalleDinamico, setListaNominasDetalleDinamico] = useState([]);
    
    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const listaNominasDetalle = useSelector(selectListaNominasDetalle);
    const isSuccess = useSelector(selectIsSuccess);
    const message = useSelector(selectMessage);
    const isLoading = useSelector(selectIsLoading);

    // * FUNCION PARA REFRESCAR LA LISTA.
    const refreshList = () => dispatch(fetchGenerarListaNominaDetalle());

    // * FUNCION PARA REFRESCAR SUCCESS
    const refreshSuccess = () => dispatch(fetchCambiarSuccess());

    // ? USE-EFFECT QUE GENERA LA LISTA.
    useEffect(() => {
        refreshList();
    }, [])

    // ? FILTRADO DE LISTA A TRAVES DE USE-EFFECT.
    useEffect(() => {

        let inicio = rowsPerPage * page;
        let final = (rowsPerPage * page) + rowsPerPage;

        if(listaNominasDetalle.length > 0){
            setListaNominasDetalleDinamico(listaNominasDetalle.slice(inicio, final));
            setListaNominasDetallesLength(listaNominasDetalle.length);
        }

    }, [listaNominasDetalle, page, skipRow, rowsPerPage])
    

    // ? HOOK-EFFECT PARA LAS ALERTAS.
    useEffect(() => {
        
        if(isSuccess === 'success' && message?.length > 0){
            swal("Exitoso!", message, "success");
            refreshList();
            refreshSuccess();
        }
        else if(isSuccess === 'error'){
            swal("Error", message, "error");
            refreshSuccess();
        }

    }, [isSuccess])  
    

    // * FUNCION PARA LA PAGINACION
    const handleChangePage = (event, newPage) => {
        setSkipRow(newPage * rowsPerPage)
        setPage(newPage);
    };

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setSkipRow(0);
	};

    // * FUNCION PARA ELIMINAR NOMINA
    const deleteNomina = (item) => {
    
        // * DESESTRUCTURACION DE LA DATA.
        const { mes, ano, programaId } = item;

        swal({
            title: 'Confirmación',
            text: `Estás seguro/a que desea eliminar la Nómina ${mes}/${ano} del programa ${programaId}?`,
            icon: 'warning',
            buttons: ['No', 'Si']
        }).then(respuesta => {
            if(respuesta){
                dispatch(fetchEliminarNomina(item));
            }
        });  
    }

    return (
        <Encabezado title='LISTA DE NÓMINAS'>

            <div className="row mt-4 mx-auto">
              {
                listaNominasDetalle.length > 0 &&
                listaNominasDetalleDinamico.length > 0 &&
                (
                    listaNominasDetalleDinamico.map((item, index) => (

                        <div className="card mb-3" key={index}>
                            <div className="row g-0">

                                <div className="col-3 text-center mt-3 mb-3 text-mem">
                                    <p className="card-text ">AÑO</p>
                                    <h6 className="fw-bold">{item.ano}</h6>
                                </div>

                                <div className="col-2 text-center mt-3 mb-3 text-mem">
                                    <p className="card-text ">MES</p>
                                    <h6 className="fw-bold">{meses.find(x => x.no === item.mes).nombre}</h6>
                                </div>
        
                                <div className="col-2">
                                    <div className="text-center mt-3 mb-3">
                                    <p className="card-text fw-bold text-14">Programa</p>
                                    <p className="text-14">{item.programaId}</p>
                                    </div>
                                </div>
        
                                
                                <div className="col-2">
                                    <div className="text-center mt-3 mb-3">
                                    <p className="card-text fw-bold text-14">Cantidad de Registros</p>
                                    <p className="text-14">{item.cantidadEmpleados}</p>
                                    </div>
                                </div>
             
                                <div className="col">
                                    <div className='mt-2 mb-2 d-flex justify-content-center' >

                                        {
                                            item.carga !== null &&
                                                <IconButtonText Icon={DeleteForeverIcon} color={'#C72924'} func={deleteNomina} params={item} id={'btn-eliminar-nomina'} />
                                        }
  
                                    </div>
                                </div>
        
                            </div>
                        </div>
                        
                    ))
                )
              }
            </div>

            {
                listaNominasDetalle.length > 0 &&
                <TablePagination
                component="div"
                count={listaNominasDetallesLength}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
            }

            <Loading open={isLoading} />

        </Encabezado>
  )
}
