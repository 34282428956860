import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LinearProgress } from "@mui/material";

// * COMPONENTE PERSONALIZADO DEL PROGRESS.
export const ProgressValueComponent = ({value, ponderacion = 0}) => {

    // * COLOR PERSONALIZADO DEL MEM.
    const theme = createTheme({
        palette: {
        primaryMem: {
            main: "#003876",
        }
        },
    });

    return (
        <div className="d-flex justify-content-center row">
            <p style={{marginBottom: 1, fontSize: 13, color: '#003876'}}>{ponderacion === 0 ? value : ponderacion}</p>
            <ThemeProvider theme={theme}>
                <LinearProgress color="primaryMem" style={{width: '3vw', height: 8, borderRadius: 10}} variant="determinate" value={value} />
            </ThemeProvider>
        </div>
    )

};