import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate,  useParams } from 'react-router';
import { Encabezado } from '../../Components/Encabezado/Encabezado';
import Loading from "../../Components/Modals/Loading/Loader";
import { Grid, Paper, Stack, Typography, TextField, TableContainer, TableHead, TableRow, Box, TableBody, TableCell, Divider, IconButton } from '@mui/material';
import { Table } from 'reactstrap';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Select from "react-select";
import { Controller, useForm, useFieldArray, useWatch} from 'react-hook-form';
import MaskedInput from "react-input-mask";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { BiSave } from "react-icons/bi";
import { MdModeEdit } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import moment from 'moment';
import swal from 'sweetalert';
import { colors } from '../../utils/colors'
import { PermissionData } from "../../context/Component/PermissionData";

import {
    fetchTipoSangre,
    fetchAfpPensiones,
    fetchSeguros,
    fetchEstadosCiviles,
    fetchEnfermedades,
    fetchTipoFamiliares,
    fetchHabitos,
    selectAllTiposSangre,
    selectAllAFPPensiones,
    selectAllSeguros,
    selectAllEstadosCiviles,
    selectAllTipoFamiliares,
    selectAllEnfermedades,
    selectAllHabitos
} from '../../redux/Utility/UtilitySlice';

import {
    fetchCargosLista,
    selectAllCargosLista,
} from "../../redux/Cargos/CargosSlice";

import {
    fetchAreasOrganizacionales,
    selectAllAreasOrganizacionales,
} from "../../redux/AreaOrganizacional/AreaOrganizacionalSlice";

import {
    fetchUpdateHistorialClinico,
    fetchCambiarSuccess,
    fetchGetHistorialClinicoById,
    selectIsLoading,
    selectIsSuccess,
    selectMessage ,
    selectHistoricoClinico
} from '../../redux/HistoricoClinico/HistoricoClinicoSlice';

export const VerHistorialClinico = () => {

    // * PERMISOS DEL USUARIO.
    const permisosState = PermissionData();

    // ? ACCION-ID DEL QUERY-PARAMETER
    const { historicoClinicoId = 0 } = useParams();

    // ? HOOK-NAVEGACION
    const navigate = useNavigate();

    // ? USE-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const tipoSangre = useSelector(selectAllTiposSangre);
    const cargos = useSelector(selectAllCargosLista);
    const areaOrganizacionales = useSelector(selectAllAreasOrganizacionales);
    const seguros = useSelector(selectAllSeguros);
    const afpPensiones = useSelector(selectAllAFPPensiones);
    const estadosCivil = useSelector(selectAllEstadosCiviles);
    const tipoFamiliares = useSelector(selectAllTipoFamiliares);
    const enfermedades = useSelector(selectAllEnfermedades);
    const habitos = useSelector(selectAllHabitos);

    // ? HOOK-SELECTOR DEL HISTORIAL CLINICO.
    const historicoClinico = useSelector(selectHistoricoClinico);
    const isLoading = useSelector(selectIsLoading);
    const success = useSelector(selectIsSuccess);
    const message = useSelector(selectMessage);

    // ? HOOK-STATE
    const [listHabitos, setListHabitos] = useState({});
    const [listaEnfermedadesFamiliares, setListaEnfermedadesFamiliares] = useState([]);

    // ? HOOK-STATE PARA VALIDACION
    const [editEnabled, setEditEnabled] = useState(false);

    // ? HOOK-FORM PARA EL FORMULARIO HISTORIAL-CLINICO.
    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
        control,
        watch,
        reset,
        getValues,
      } = useForm({
        defaultValues: {
            empleado: {
                empleadoId: 0,
                tipoSangreId: 0,
                arsId: 0,
                afpId: 0
            },
            historicoClinico: {
                historicoClinicoId: 0,
                empleadoId: 0,
                fechaCreacion: new Date(),
                sarampion: false,
                varicela: false,
                meningitis: false,
                paperas: false,
                transfusiones: false,
                quirurgicos: false,
                cualQuirurgicos: "",
                hospitalizacion: "",
                usoLentes: false,
                vacunas: false,
                vacunasCovid1: false,
                vacunasCovid2: false,
                vacunasCovid3: false,
                alergias: false,
                alergiasCuales: "",
                edadMenarquia: 0,
                periodoMenstrual: "",
                menopausia: "",
                hijos: false,
                cantidadHijos: 0,
                pendientes: ''
            },
            vacunaCovid: 'ninguna',
            patologicosPersonales: [],
            habitosVida: []
        }
      });

    // ? HOOK-WATCH
    const empleado = useWatch({ control, name: 'empleado'});


    // ? HOOK-FIELD-ARRAY PARA LA LISTA DE PATOLOGICOS-PERSONALES
    const { fields } = useFieldArray({
        control,
        name: "patologicosPersonales",
        rules: {},
    });

    // ? HOOK-FIELD PARA LA LISTA DE HABITOS DE VIDA.
    const { fields: fields2 } = useFieldArray({
        control,
        name: "habitosVida",
        rules: {},
    });


    // ? HOOK-EFFECT PARA VERIFICAR SI SE BUSCARA UNA ACCION
    useEffect(() => {
        if (historicoClinicoId > 0) {
            dispatch(fetchGetHistorialClinicoById(historicoClinicoId));
        }
    }, [historicoClinicoId])

    // ? HOOK-EFFECT PARA VALIDAR QUE EL HISTORICO FUE ENCONTRADO PARA
   // ? POSTERIORMENTE CARGAR LA INFORMACION EN EL FORMULARIO.
   useEffect(() => {

    if (Object.keys(historicoClinico).length > 0 && success === 'success' && !isLoading) {

        // * DESESTRUCTURACION DE DATOS
        const { empleado } = historicoClinico;

        // * OBJETO QUE ALMACENA LA DATA DEL HISTORICO PARA SER SOBRE-ESCRITO.
        let historialClinicoEmpleado = watch('historicoClinico');

        // * SIRVE PARA REALIZAR UNA COPIA DEL OBJETO HISTORICO-CLINICO DEL HOOK FORM
        // * Y SOLAMENTE SOBRE-ESCRIBIR LAS PROPIEDADES QUE TIENEN EN COMUN, 
        // * EVITANDO AGREGAR LAS PROPIEDADES QUE NO SE VAN A USAR.
        Object.keys(historicoClinico).forEach(key => {
            if (historialClinicoEmpleado.hasOwnProperty(key)) {
                historialClinicoEmpleado[key] = historicoClinico[key];
            }
        });

        // * OBTENER EL NOMBRE DE LA VACUNA PARA LLENAR LOS RADIO-BUTTON.
        const nombreVacuna = Object.keys(historicoClinico).find(x => x.includes('vacunasCovid') && historicoClinico[x] === true) ?? 'ninguna';

        // * ASIGNACION DE VALORES
        setValue('empleado', empleado);
        setValue('historicoClinico', historialClinicoEmpleado);
        setValue('vacunaCovid', nombreVacuna);
    }

    if (Object.keys(historicoClinico).length === 0 && success === 'error') {
       navigate('/historialClinico')
    }

 }, [historicoClinico, isLoading])

    // ? HOOK-EFFECT
    useEffect(() => {
      dispatch(fetchTipoSangre());
      dispatch(fetchCargosLista());
      dispatch(fetchAreasOrganizacionales());
      dispatch(fetchSeguros());
      dispatch(fetchAfpPensiones());
      dispatch(fetchEstadosCiviles());
      dispatch(fetchTipoFamiliares());
      dispatch(fetchEnfermedades());
      dispatch(fetchHabitos());
    }, [])

    // ? HOOK-EFFECT PARA CREACION DE LISTA DE ENFERMEDADES-FAMILIARES
    useEffect(() => {

      if(enfermedades.length > 0 && Object.keys(historicoClinico).length > 0){

        // * VARIABLE PARA LAS MATRICES
        let arr = [];
        let matriz = [];

        for (let index = 0; index < enfermedades.length; index++) {

            matriz = [];
            for (let x = 0; x < tipoFamiliares.length; x++) {

                // * VARIABLES CON LOS ID.
                const enfermedad = enfermedades[index].id;
                const familiar =  tipoFamiliares[x].id;

                // * BUSCANDO DATA EN EL HISTORICO CLINICO DEL EMPLEADO.
                const historico = historicoClinico.enfermedades.find(x => x.enfermedadId === enfermedad && x.familiarId === familiar);

                // * DESESTRUCTURACION DE DATOS
                const { id, historicoClinicoId, enfermedadId, familiarId, enfermedadesOtras, seleccionado } = historico;
                
                const element = {
                    id,
                    historicoClinicoId,
                    enfermedadId,
                    familiarId,
                    enfermedadesOtras,
                    seleccionado
                };

                matriz.push(element);
            }

            arr.push(matriz);
        }

        // * ASIGNACION DE VALORES PREDETERMINADOS AL ARRAY DE PATOLOGICOS.
        setValue('patologicosPersonales', arr);

        // * ASIGNACION DE ARRAY AL STATE DE ENFERMEDADES-FAMILIARES/
        setListaEnfermedadesFamiliares(arr);
      }

    }, [enfermedades, historicoClinico])


    // ? HOOK-EFFECT PARA LAS ALERTAS DEL FORMULARIO.
    useEffect(() => {

        if (success === "success" && message?.length > 0) {
            // * LIMPIAR LOS REDUCER.
            dispatch(fetchCambiarSuccess());

            // * REFRESCAR HISTORIAL-CLINICO
            dispatch(fetchGetHistorialClinicoById(historicoClinicoId));

            // * DESHABILITAR EDICION.
            setEditEnabled(false);

            // * MENSAJE DEL CONFIRMACION.
            swal("Exitoso!", message, "success");
        } else if (success === "error") {
            dispatch(fetchCambiarSuccess());
            swal("Error!", message, "error");
        }
    }, [success]);


    // ? HOOK-EFFECT PARA DIVIDIR LA LISTA DE LOS HABITOS.
    useEffect(() => {

        if(habitos.length > 0  && Object.keys(historicoClinico).length > 0){

            // * VARIABLE QUE ALMACENA EL ARRAY DE OBJETOS.
            let arr = [];

            for (let index = 0; index < habitos.length; index++) {

                // * VARIABLES CON LOS ID.
                const habitoIdEmpleado = habitos[index].id;

                // * BUSCANDO DATA EN EL HISTORICO CLINICO DEL EMPLEADO.
                const habito = historicoClinico.habitos.find(x => x.habitoId === habitoIdEmpleado);

                // * DESESTRUCTURACION DE DATOS
                const { id, historicoClinicoId, habitoId, frecuencia, seleccionado } = habito;

                const element = {
                    id,
                    historicoClinicoId,
                    habitoId,
                    frecuencia,
                    seleccionado
                }

                arr.push(element);
            }

            // * NUMERO DE DIVISION DE COLUMNAS
            const mitadArray = Math.floor(arr.length / 2);

            // * COLUMNAS
            const column1 = arr.slice(0, mitadArray);
            const column2 = arr.slice(mitadArray);

            // * ASIGNACION DE VALORES PREDETERMINADOS AL ARRAY DE PATOLOGICOS.
            setValue('habitosVida', arr);

            // * ASIGNACION DE LISTA DE HABITOS POR COLUMNAS.
            setListHabitos({column1: column1, column2: column2});
        }

    }, [habitos, historicoClinico])

    // ? HOOK-PARA OBTENER LA FRECUENCIA SEGUN EL DIA.
    const getFrecuencia = (id) => {

        const frecuencias = {
            1: 'día',
            2: 'día',
            3: 'semana',
            4: 'semana',
            5: 'mes',
            6: 'semana'
        };

        return frecuencias[id] ?? '';
    }


    // * FUNCION PARA ENVIAR LA DATA.
    const sendData = () => {

        // * ESTRUCTURACION DE OBJETO.
        const empleadoSendApi = {
            afpId: watch('empleado.afpId'),
            arsId: watch('empleado.arsId'),
            empleadoId: watch('empleado.id'),
            tipoSangreId: watch('empleado.tipoSangreId')
        }

        // * ASIGNACION DE DATA A ENVIAR A API.
        setValue('empleado', empleadoSendApi);

        // * BUSCANDO PROPIEDAD LLAMADA VACUNAS PARA ASIGNARLE SUS VALORES CORRESPONDIENTES
        for (let propiedad in watch('historicoClinico')) {
            if (watch('historicoClinico').hasOwnProperty(propiedad) && propiedad.includes("vacunasCovid")) {
              if(propiedad === getValues('vacunaCovid')){
                setValue(`historicoClinico.${propiedad}`, true);
              }
              else{
                setValue(`historicoClinico.${propiedad}`, false);
              }
            }
        }

        // * DESESTRUCTURACION DE DATOS
        const { empleado, habitosVida, historicoClinico, patologicosPersonales } = watch();

        // * ESTRUCTURACION DEL OBJETO A ENVIAR A API.
        const dataApi = {
            empleado,
            historicoClinico,
            listaHabitos: habitosVida,
            listaEnfermedades: patologicosPersonales.flat()
        }

        // * ENVIANDO DATA A LA API.
        dispatch(fetchUpdateHistorialClinico(dataApi));
    }

    // * VALIDACION DE EMPLEADO EXISTENTE
    const empleadoIsValid = Object.keys(empleado).length > 0 & editEnabled;

    // * VALIDACION PARA VERIFICAR QUE ES HOMBRE Y DESHABILITAR LOS CAMPOS PERIODO-MENTRUAL Y MENARQUIA.
    const validacionHombre = Boolean(empleado?.sexo?.toLowerCase() === "Masculino".toLowerCase());

    // * VALIDACION PARA VERIFICAR LOS PERMISOS.
    const validacionPermiso = permisosState.ROLE_EDITAR_HISTORICO_CLINICO || permisosState.ROLE_ADMIN

  return (
    <Encabezado title={editEnabled ? 'Editar Historial Clínico' : 'Historial Clínico'} container>

        <Paper elevation={5}>

            <Box p={2} mb={-4} >
                <Grid container spacing={2}>

                    <Grid item xs={10}>
                        <Typography fontSize={20} textTransform='uppercase'>Datos del Empleado</Typography>
                    </Grid>

                    <Grid xs={2} item display='flex' justifyContent='end'>
                        <IconButton id='btn-editar' hidden={!validacionPermiso} disabled={editEnabled} onClick={() => setEditEnabled(!editEnabled)}>
                            <MdModeEdit color={editEnabled ? 'gray' : colors.azulMem} />
                        </IconButton>
                    </Grid>

                </Grid>
                <Divider />
            </Box>

            <Grid container p={2} spacing={2}>

                <Grid item xs={6}>
                    <Paper elevation={3}>
                        <Grid p={4}>
                            <Stack>

                                <Typography fontWeight='bold' fontSize={12}>No. Empleado</Typography>
                                <input {...register('empleado.codigoEmpleado')} disabled className='form-control' />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Nombre</Typography>
                                <input {...register('empleado.nombres')} disabled className='form-control' />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Apellido</Typography>
                                <input {...register('empleado.apellidos')} disabled className='form-control' />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Cédula</Typography>
                                <Controller
                                name="empleado.cedula"
                                control={control}
                                render={({ field }) => (
                                <MaskedInput
                                    disabled
                                    className="form-control"
                                    mask="999-9999999-9"
                                    maskChar=""
                                    value={field.value}
                                    onChange={field.onChange}
                                    />
                                )}
                                />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Sexo</Typography>
                                <input disabled className='form-control' {...register('empleado.sexo')} />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Fecha Nacimiento</Typography>

                                <Controller
                                control={control}
                                name="empleado.fechaNacimiento"
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        disabled
                                        label={" "}
                                        mask="__/__/____"
                                        value={field.value}
                                        onChange={(e) => field.onChange(e)}
                                        renderInput={(params) => (
                                        <TextField className="form-control" {...params} />
                                        )}
                                    />
                                    </LocalizationProvider>
                                )}
                                />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Edad</Typography>
                                <input value={moment().diff(empleado?.fechaNacimiento, 'years', false)} disabled className='form-control' />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Estado Civil</Typography>
                                    <Controller
                                    control={control}
                                    name="empleado.estadoCivilId"
                                    render={({ field }) => (
                                        <Select
                                            isDisabled
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            placeholder="Seleccionar Estado Civil"
                                            options={estadosCivil}
                                            getOptionLabel={(option) => option.nombre}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => field.onChange(e.id)}
                                            value={estadosCivil.find(x => x.id === field.value)}
                                        />
                                    )}
                                    />

                            </Stack>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={6}>
                    <Paper elevation={3}>
                        <Grid p={4}>
                            <Stack>
                                <Typography fontWeight='bold' fontSize={12}>Tipo Sangre</Typography>
                                    <Controller
                                    control={control}
                                    name="empleado.tipoSangreId"
                                    render={({ field }) => (
                                        <Select
                                            id='empleado.tipoSangreId'
                                            isDisabled={!empleadoIsValid}
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            placeholder="Seleccionar Tipo de Sangre"
                                            options={tipoSangre}
                                            getOptionLabel={(option) => option.nombre}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => field.onChange(e.id)}
                                            value={tipoSangre.find(x => x.id === field.value)}
                                        />
                                        )}
                                    />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Posición</Typography>
                                    <Controller
                                        control={control}
                                        name="empleado.cargoId"
                                        render={({ field }) => (
                                            <Select
                                                isDisabled
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                placeholder="Seleccionar Cargo"
                                                options={cargos}
                                                getOptionLabel={(option) => option.titulo}
                                                getOptionValue={(option) => option.id}
                                                onChange={(e) => field.onChange(e.id)}
                                                value={cargos.find(x => x.id === field.value)}
                                            />
                                        )}
                                    />


                                <Typography fontWeight='bold' fontSize={12} mt={2}>Ubicación</Typography>
                                <input {...register('empleado.direccion')} disabled className='form-control' />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>Área</Typography>
                                    <Controller
                                        control={control}
                                        name="empleado.areaOrganizacionalId"
                                        render={({ field }) => (
                                                <Select
                                                isDisabled
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                placeholder="Seleccionar Área Organizacional"
                                                options={areaOrganizacionales}
                                                getOptionLabel={(option) => option.nombre}
                                                getOptionValue={(option) => option.id}
                                                onChange={(e) => field.onChange(e.id)}
                                                value={areaOrganizacionales.find(x => x.id === field.value)}
                                                />
                                            )}
                                    />


                                <Typography fontWeight='bold' fontSize={12} mt={2}>Grado Académico</Typography>
                                <input {...register('empleado.gradoAcademico')} disabled className='form-control' />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>No. Celular</Typography>

                                <Controller
                                    control={control}
                                    name="empleado.celular"
                                    render={({ field }) => (
                                            <MaskedInput
                                            disabled
                                            className="form-control"
                                            mask="(999) 999-9999"
                                            maskChar=""
                                            value={field.value}
                                            onChange={field.onChange}
                                            />
                                        )}
                                />


                                <Typography fontWeight='bold' fontSize={12} mt={2}>ARS (Seguro)</Typography>
                                    <Controller
                                    control={control}
                                    name="empleado.arsId"
                                    render={({ field }) => (
                                        <Select
                                            id='empleado.arsId"'
                                            isDisabled={!empleadoIsValid}
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            placeholder="Seleccionar ARS"
                                            options={seguros}
                                            getOptionLabel={(option) => option.nombre}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => field.onChange(e.id)}
                                            value={seguros.find(x => x.id === field.value)}
                                        />
                                        )}
                                    />

                                <Typography fontWeight='bold' fontSize={12} mt={2}>AFP (Pensiones)</Typography>
                                    <Controller
                                    control={control}
                                    name="empleado.afpId"
                                    render={({ field }) => (
                                        <Select
                                            id='empleado.afpId'
                                            isDisabled={!empleadoIsValid}
                                            classNamePrefix="select"
                                            isSearchable={true}
                                            placeholder="Seleccionar AFP"
                                            options={afpPensiones}
                                            getOptionLabel={(option) => option.nombre}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => field.onChange(e.id)}
                                            value={afpPensiones.find(x => x.id === field.value)}
                                        />
                                        )}
                                    />


                            </Stack>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>
        </Paper>

        <form onSubmit={handleSubmit(sendData)}>

            <Paper elevation={5}>
                <Box sx={{ mt: 3 }} maxWidth={'100%'} p={2}>

                    <Typography textTransform='uppercase'>
                        Patológicos Personales y Familiares
                    </Typography>
                    <Divider />

                    <Box mt={2}>
                        <Paper elevation={3}>
                            <TableContainer>
                                <Table stickyHeader>

                                    <TableHead>
                                        <TableRow>
                                            <TableCell>

                                            </TableCell>
                                            {
                                                tipoFamiliares.map((item) => (
                                                    <TableCell align='center' key={item.id} sx={{fontWeight: 'bold'}}>
                                                        {item.nombre}
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>

                                        {
                                            listaEnfermedadesFamiliares.length > 0 &&
                                            listaEnfermedadesFamiliares?.map((item, index) => (
                                                <TableRow key={index}>

                                                    <TableCell>
                                                        {enfermedades.find(x => x.id === item[0]?.enfermedadId)?.nombre ?? ''}
                                                    </TableCell>

                                                {
                                                    item.map((item2, index2) => (
                                                        <TableCell align='center' key={index2}>
                                                            <FormControlLabel
                                                            key={index2}
                                                            sx={{
                                                                "& .MuiTypography-root": {
                                                                fontSize: { lg: 14, md: 12, xs: 11 },
                                                                },
                                                            }}
                                                            control={
                                                                    <>

                                                                        <Checkbox
                                                                        id={`enfermedad.${item2.enfermedadId}.${item2.familiarId}`}
                                                                        disabled={!empleadoIsValid}
                                                                        value={getValues(`patologicosPersonales.${index}.${index2}.seleccionado`)}
                                                                        checked={getValues(`patologicosPersonales.${index}.${index2}.seleccionado`)}
                                                                        onChange={(e) => {
                                                                            const isChecked = e.target.checked;
                                                                            setValue(`patologicosPersonales.${index}.${index2}.seleccionado`, isChecked);

                                                                            if (!isChecked) { // Si el checkbox está desmarcado, establece el valor del campo de texto como vacío
                                                                                setValue(`patologicosPersonales.${index}.${index2}.enfermedadesOtras`, ""); // Actualiza el valor en useForm
                                                                            }
                                                                        }}
                                                                        />

                                                                        <TextField
                                                                        {...register(`patologicosPersonales.${index}.${index2}.enfermedadesOtras`)}
                                                                        label=" "
                                                                        disabled={!empleadoIsValid}
                                                                        hidden={item2.enfermedadId !== 11 | !Boolean(watch(`patologicosPersonales.${index}.${index2}.seleccionado`))}
                                                                        />

                                                                    </>
                                                            }
                                                            />

                                                            </TableCell>
                                                    ))
                                                }

                                                </TableRow>
                                            ))
                                        }


                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>

                </Box>
            </Paper>

            <Paper elevation={5}>
                <Box sx={{ mt: 3 }} maxWidth={'100%'} p={2}>
                    <Typography textTransform='uppercase'>
                        Condiciones médicas personales
                    </Typography>
                    <Divider />

                    <Paper>
                        <Box marginTop={2} p={2}>

                            <Box className='d-flex'>
                                <Typography mt={1.2}>Enfermedad de la infancia: </Typography>
                                    <Controller
                                    control={control}
                                    name={'historicoClinico.varicela'}
                                    defaultValue={false}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                        control={
                                            <Checkbox id='historicoClinico.varicela' disabled={!empleadoIsValid} checked={value} onChange={onChange} />
                                        }
                                        label="Varicela"
                                        />
                                    )}
                                    />

                                    <Controller
                                    control={control}
                                    name={'historicoClinico.meningitis'}
                                    defaultValue={false}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                        control={
                                            <Checkbox id='historicoClinico.meningitis' disabled={!empleadoIsValid} checked={value} onChange={onChange} />
                                        }
                                        label="Meningitis"
                                        />
                                    )}
                                    />

                                    <Controller
                                    control={control}
                                    name={'historicoClinico.paperas'}
                                    defaultValue={false}
                                    render={({ field: { onChange, value } }) => (
                                        <FormControlLabel
                                        control={
                                            <Checkbox id='historicoClinico.paperas' disabled={!empleadoIsValid} checked={value} onChange={onChange} />
                                        }
                                        label="Paperas"
                                        />
                                    )}
                                    />

                            </Box>

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box className='d-flex'>
                                        <Typography mt={1.2}>Transfusiones: </Typography>
                                            <Controller
                                            control={control}
                                            name={'historicoClinico.transfusiones'}
                                            defaultValue={false}
                                            render={({ field: { onChange, value } }) => (
                                                <FormControl className='d-flex'>
                                                    <RadioGroup
                                                        id='historicoClinico.transfusiones'
                                                        value={value}
                                                        style={{marginLeft: 2}}
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={onChange}
                                                    >
                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={true} control={<Radio />} label="Si" />
                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={false} control={<Radio />} label="No" />

                                                    </RadioGroup>
                                                </FormControl>
                                            )}
                                            />

                                    </Box>

                                    <Box className='d-flex'>
                                        <Typography mt={1.2}>Quirúrgicos: </Typography>
                                           
                                        <FormControl className='d-flex'>
                                            <RadioGroup
                                                id='historicoClinico.quirurgicos'
                                                value={getValues('historicoClinico.quirurgicos')}
                                                style={{marginLeft: 2}}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={(e) => {
                                                    const isChecked = e.target.value;
                                                    const value = isChecked === 'true' ? true : false;

                                                    // * GUARDANDO DATA.
                                                    setValue('historicoClinico.quirurgicos', value);

                                                    if(!value){
                                                        setValue('historicoClinico.cualQuirurgicos', "");
                                                    }
                                                }}
                                            >
                                                <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={true} control={<Radio />} label="Si" />
                                                <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={false} control={<Radio />} label="No" />

                                            </RadioGroup>
                                        </FormControl>
                                             
                                    </Box>

                                    <Box className='d-flex mb-2'>
                                        <Typography mt={1.2}>Cual</Typography>
                                        <input id='historicoClinico.cualQuirurgicos'  disabled={!empleadoIsValid | !getValues('historicoClinico.quirurgicos')} {...register('historicoClinico.cualQuirurgicos')} className='form-control w-75 ms-2' />
                                    </Box>

                                    <Box className='d-flex mb-2'>
                                        <Typography mt={1.2}>Hospitalización</Typography>
                                        <input id='historicoClinico.hospitalizacion'  disabled={!empleadoIsValid} {...register('historicoClinico.hospitalizacion')}  className='form-control w-75 ms-2' />
                                    </Box>

                                    <Box className='d-flex mb-2'>
                                        <Typography mt={1.2}>Uso de lentes: </Typography>
                                            <Controller
                                                control={control}
                                                name={'historicoClinico.usoLentes'}
                                                defaultValue={false}
                                                render={({ field: { onChange, value } }) => (
                                                    <FormControl className='d-flex'>
                                                        <RadioGroup
                                                            id='historicoClinico.usoLentes'
                                                            value={value}
                                                            style={{marginLeft: 2}}
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            onChange={onChange}
                                                        >
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={true} control={<Radio />} label="Si" />
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={false} control={<Radio />} label="No" />

                                                        </RadioGroup>
                                                    </FormControl>
                                                )}
                                                />
                                    </Box>

                                </Grid>
                                <Grid item xs={6}>

                                    <Box className='d-flex'>
                                        <Typography mt={1.2}>Vacunas: </Typography>
                                            <Controller
                                                control={control}
                                                name={'historicoClinico.vacunas'}
                                                defaultValue={false}
                                                render={({ field: { onChange, value } }) => (
                                                    <FormControl className='d-flex'>
                                                        <RadioGroup
                                                            id='historicoClinico.vacunas'
                                                            value={value}
                                                            style={{marginLeft: 2}}
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            onChange={onChange}
                                                        >
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={true} control={<Radio />} label="Si" />
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={false} control={<Radio />} label="No" />

                                                        </RadioGroup>
                                                    </FormControl>
                                                )}
                                                />

                                    </Box>

                                    <Box className='d-flex'>
                                        <Typography mt={1.2}>Vacunas COVID: </Typography>
                                            <Controller
                                                control={control}
                                                name={'vacunaCovid'}
                                                defaultValue={false}
                                                render={({ field: { onChange, value } }) => (
                                                    <FormControl className='d-flex'>
                                                        <RadioGroup
                                                            id='vacunaCovid'
                                                            value={value}
                                                            style={{marginLeft: 2}}
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            onChange={onChange}
                                                        >
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={'vacunasCovid1'} control={<Radio  />} label="1ra" />
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={'vacunasCovid2'} control={<Radio />} label="2da" />
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={'vacunasCovid3'} control={<Radio />} label="3ra" />
                                                            <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={'ninguna'} control={<Radio />} label="No aplica" />

                                                        </RadioGroup>
                                                    </FormControl>
                                                )}
                                                />
                                    </Box>

                                    <Box className='d-flex mb-2'>
                                        <Typography mt={1.2}>Pendientes</Typography>
                                        <input id='historicoClinico.pendientes'  disabled={!empleadoIsValid} {...register('historicoClinico.pendientes')} className='form-control w-75 ms-2' />
                                    </Box>

                                    <Box className='d-flex mb-2'>
                                        <Typography mt={1.2}>Alergias: </Typography>
                                       
                                            <FormControl className='d-flex'>
                                                <RadioGroup
                                                    id='historicoClinico.alergias'
                                                    value={getValues('historicoClinico.alergias')}
                                                    style={{marginLeft: 2}}
                                                    row
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    onChange={(e) => {
                                                        const isChecked = e.target.value;
                                                        const value = isChecked === 'true' ? true : false;

                                                        // * GUARDANDO DATA.
                                                        setValue('historicoClinico.alergias', value);

                                                        if(!value){
                                                            setValue('historicoClinico.alergiasCuales', "");
                                                        }
                                                    }}
                                                >
                                                    <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={true} control={<Radio />} label="Si" />
                                                    <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={false} control={<Radio />} label="No" />

                                                </RadioGroup>
                                            </FormControl>
                                    </Box>

                                    <Box className='d-flex mb-2'>
                                        <Typography mt={1.2}>Cuales</Typography>
                                        <input id='historicoClinico.alergiasCuales' disabled={!empleadoIsValid | !getValues('historicoClinico.alergias')} {...register('historicoClinico.alergiasCuales')} className='form-control w-75 ms-2' />
                                    </Box>

                                </Grid>
                            </Grid>

                        </Box>
                    </Paper>

                </Box>
            </Paper>

            <Paper elevation={5}>
                <Box sx={{ mt: 3 }} maxWidth={'100%'} p={2}>

                    <Typography textTransform='uppercase'>
                        Hábitos de Vida
                    </Typography>
                    <Divider />

                    <Paper elevation={3}>
                        <Box mt={2}>
                            <TableContainer p={2} mt={2}>
                                <Table stickyHeader>

                                    <TableHead>

                                        <TableRow>
                                            <TableCell width="15%" sx={{fontWeight: 'bold'}}>
                                                Hábitos
                                            </TableCell>
                                            <TableCell align='center' width="15%" sx={{fontWeight: 'bold'}}>
                                                Si/No
                                            </TableCell>
                                            <TableCell width="15%" sx={{fontWeight: 'bold'}}>
                                                Frecuencia
                                            </TableCell>
                                            <TableCell width="15%" sx={{fontWeight: 'bold'}}>
                                                Hábitos
                                            </TableCell>
                                            <TableCell align='center' width="15%" sx={{fontWeight: 'bold'}}>
                                                Si/No
                                            </TableCell>
                                            <TableCell width="15%" sx={{fontWeight: 'bold'}}>
                                                Frecuencia
                                            </TableCell>
                                        </TableRow>

                                    </TableHead>

                                <TableBody>
                                        {
                                            listHabitos?.column1?.map((item, index) => (
                                                <TableRow key={index}>

                                                    <TableCell>
                                                        {habitos.find(x => x.id === item.habitoId)?.nombre ?? ''}
                                                    </TableCell>

                                                    <TableCell>

                                                        <div className='d-flex justify-content-center'>
                                                            <Controller
                                                            name={`habito.${item.habitoId}`}
                                                            control={control}
                                                            value={getValues(`habitosVida.${index}.seleccionado`)}
                                                            defaultValue={false}
                                                            render={() => (
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        id={`habito.${item.habitoId}`}
                                                                        value={getValues(`habitosVida.${index}.seleccionado`)}
                                                                        checked={watch(`habitosVida.${index}.seleccionado`)}
                                                                        row
                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        name="row-radio-buttons-group"
                                                                        onChange={(e) => {
                                                                            const isChecked = e.target.value === 'true' ? true : false;
                           
                                                                            setValue(`habitosVida.${index}.seleccionado`, e.target.value);

                                                                            if (!isChecked) { // Si el checkbox está desmarcado, establece el valor del campo de texto como vacío
                                                                                setValue(`habitosVida.${index}.frecuencia`, 0); // Actualiza el valor en useForm
                                                                            }
                                                                        }}
                                                                    >
                                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={true} control={<Radio />} label="Si" />
                                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={false} control={<Radio />} label="No" />
                                                                        
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            )}
                                                            />
                                                        </div>

                                                    </TableCell>

                                                    <TableCell>
                                                        <Box className='d-flex'>
                                                            <TextField
                                                            id={`frecuenciaHabito.${item.habitoId}`}
                                                            disabled={!empleadoIsValid | !getValues(`habitosVida.${index}.seleccionado`)}
                                                            {...register(`habitosVida.${index}.frecuencia`, {
                                                                value: 0,
                                                            })}
                                                            style={{width: '30%'}}
                                                            type='number'
                                                            variant='standard' />

                                                            <Typography ml={1} mt={1.5}>{getFrecuencia(item.habitoId)}</Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        {habitos.find(x => x.id === listHabitos?.column2[index]?.habitoId)?.nombre ?? ''}
                                                    </TableCell>
                                                    <TableCell>

                                                        <div className='d-flex justify-content-center'>
                                                            <Controller
                                                            name={`habito.${item.habitoId + 3}`}
                                                            control={control}
                                                            defaultValue={false}
                                                            render={() => (
                                                                <FormControl className='d-flex'>
                                                                    <RadioGroup
                                                                        id={`habito.${item.habitoId + 3}`}
                                                                        value={getValues(`habitosVida.${index + 3}.seleccionado`)}
                                                                        checked={watch(`habitosVida.${index + 3}.seleccionado`)}
                                                                        style={{marginLeft: 2}}
                                                                        row
                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        name="row-radio-buttons-group"
                                                                        onChange={(e) => {
                                                                            const isChecked = e.target.value === 'true' ? true : false;
                                                                            
                                                                            setValue(`habitosVida.${index + 3 }.seleccionado`, e.target.value);

                                                                            if (!isChecked) { // Si el checkbox está desmarcado, establece el valor del campo de texto como vacío
                                                                                setValue(`habitosVida.${index + 3}.frecuencia`, 0); // Actualiza el valor en useForm
                                                                            }
                                                                        }}
                                                                    >
                                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={true} control={<Radio />} label="Si" />
                                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={false} control={<Radio />} label="No" />
                                                                        
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            )}
                                                            />
                                                        </div>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Box className='d-flex'>
                                                            <TextField
                                                            id={`frecuenciaHabito.${item.habitoId + 3}`}
                                                            disabled={!empleadoIsValid | !getValues(`habitosVida.${index + 3}.seleccionado`)}
                                                            {...register(`habitosVida.${index + 3}.frecuencia`, {
                                                                value: 0,
                                                            })}
                                                            style={{width: '30%'}} type='number' variant='standard'
                                                            />
                                                            <Typography ml={1} mt={1.5}>{getFrecuencia(listHabitos?.column2[index]?.habitoId)}</Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                </TableBody>

                                </Table>
                            </TableContainer>
                        </Box>
                    </Paper>
                </Box>

            </Paper>

            <Paper elevation={5}>
                <Box maxWidth={'100%'} p={2} mb={2} mt={2}>

                    <Typography textTransform='uppercase'>
                        Gineco-obstétricos
                    </Typography>
                    <Divider />

                    <Paper elevation={3}>
                        <Box mt={2} p={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Typography>Edad de menarquia</Typography>
                                    <input id='historicoClinico.edadMenarquia' disabled={!empleadoIsValid | validacionHombre} type='number' {...register('historicoClinico.edadMenarquia')} className='form-control mb-2' />

                                    <Typography>Período Menstrual</Typography>
                                    <input id='historicoClinico.periodoMenstrual' disabled={!empleadoIsValid | validacionHombre}  {...register('historicoClinico.periodoMenstrual')} className='form-control' />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Menopausia</Typography>
                                    <input id='historicoClinico.menopausia' disabled={!empleadoIsValid}  {...register('historicoClinico.menopausia')} className='form-control' />

                                    <Box className='d-flex mt-4'>
                                        <Box className='d-flex mt-2'>
                                            <Typography mt={1}>Hijos: </Typography>
                                          
                                                <FormControl className='d-flex'>
                                                    <RadioGroup
                                                        id='historicoClinico.hijos'
                                                        style={{marginLeft: 2}}
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        value={getValues('historicoClinico.hijos')}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.value === 'true';
                                                            setValue('historicoClinico.hijos', isChecked);

                                                            if (!isChecked) { // Si el checkbox está desmarcado, establece el valor del campo de texto como vacío
                                                                setValue('historicoClinico.cantidadHijos', 0); // Actualiza el valor en useForm
                                                            }
                                                        }}
                                                    >
                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={'true'} control={<Radio />} label="Si" />
                                                        <FormControlLabel disabled={!empleadoIsValid} labelPlacement="start" value={'false'} control={<Radio />} label="No" />

                                                    </RadioGroup>
                                                </FormControl>
                                        </Box>

                                        <Typography mt={2} marginLeft={2} marginRight={1}>Cantidad</Typography>
                                        <input id='historicoClinico.hijos' disabled={!empleadoIsValid | !watch('historicoClinico.hijos')} type='number' {...register('historicoClinico.cantidadHijos')} className='form-control w-25' />
                                </Box>

                                </Grid>
                            </Grid>

                            <Box className='d-flex justify-content-center' mt={3}>
                                <button id='btn-guardar' type='submit' className='btn btn-primary-mem me-2' hidden={!empleadoIsValid || !validacionPermiso}>Guardar <BiSave fontSize={22} /></button>
                                <button id='btn-cancelar' type='button' onClick={() => navigate('/historialClinico')} className='btn btn-danger'>Cancelar <MdCancel fontSize={22} /></button>
                            </Box>

                        </Box>

                    </Paper>


                </Box>


            </Paper>

         </form>

        <Loading open={isLoading} />
    </Encabezado>
  )
}
