
  export const onKeyPressEnterOrganizacion = (e, listAreaOrganizacionales, setAreaId) => {

      if(e.code === 'Enter'){
          const areaOrganizacional = listAreaOrganizacionales.find(x => x.nombre === e.target.value);
          setAreaId(areaOrganizacional.id);
      }
   
  }

  export const onKeyPressEnterEmpleado = (e, listEmpleados, setEmpleadoId) => {

      if(e.code === 'Enter'){
          const empleado = listEmpleados.find(x => x.nombreLabel === e.target.value);
          setEmpleadoId(empleado.id);
      }
   
  }

  export const onKeyPressEnterPermiso = (e, listPermisos, setPermisoId) => {

    if(e.code === 'Enter'){
        const permiso = listPermisos.find(x => x.nombre === e.target.value);
        setPermisoId(permiso.id);
    }
 
}

export const onKeyPressEnterCargo = (e, listCargos, setCargoDelEmpleado) => {

    if(e.code === 'Enter'){
        const cargo = listCargos.find(x => x.titulo === e.target.value);
        setCargoDelEmpleado(cargo.id);
    }
 
}

export const onKeyPressEnterGrupoOcupacional = (e, listGruposOcupacionalesState, setGrupoOrganizacional) => {

    if(e.code === 'Enter'){
        const grupoOrganizacional = listGruposOcupacionalesState.find(x => x.nombre === e.target.value);
        setGrupoOrganizacional(grupoOrganizacional.id);
    }
 
}

