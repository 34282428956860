import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IconButtonText } from '../../../Components/IconPersonalize/IconButtonText';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { ViewPDF } from '../../PDFViewer/ViewPDF';

import { meses } from '../../../utils/meses';

import { 
    fetchReporteNominaEmpleado,
    selectNominaEmpleadoReporteAtributos
} from '../../../redux/Nominas/NominaSlice';

export const MisVolantesCard = ({ lista }) => {

    // ? HOOK-STATE
    const [openModal, setOpenModal] = useState(false)

    // ? HOOK-NAVIGATE
    const navigate = useNavigate();

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const dataReporteNominaPDF = useSelector(selectNominaEmpleadoReporteAtributos);

    // * FUNCION PARA VER NOMINA DE UN EMPLEADO.
    const navigateNominaEmpleado = (item) => {
        navigate('/nominas/vernominas/empleado', { state: item });
    }

    // * FUNCION PARA GENERAR REPORTE.
    const generateReportNominaEmpleado = (item) => {

        const data = {
            empleadoId: item?.empleadoId ?? 0,
            ano: item.ano,
            mes: item.mes
        }
        
        dispatch(fetchReporteNominaEmpleado(data));

    }

    useEffect(() => {

        // * SI EL EXISTE DATA EN EMPLEADO REPORTE EN REDUX PUES
        // * SE HABILITA EL MODAL DE VER VISTA PREVIA DEL PDF.
        if(Object.keys(dataReporteNominaPDF).length > 0){
            setOpenModal(true);
        }

    }, [dataReporteNominaPDF])

  return (
    <>
    
        {
            lista.map((item, index) => (

                <div className="row mx-auto" key={index}>      
                    <div className="card mb-3">
                        <div className="row g-0">
                            <div className="col-md-2 text-center mt-3 mb-3">
                                <p className="card-text fw-bold text-mem">No. Empleado</p>
                                <p className="text-14 text-mem">{item.codigoEmpleado}</p>
                            </div>

                            <div className="col-md-3">
                                <div className="text-center mt-3 mb-3">
                                <p className="card-text fw-bold text-14">Año</p>
                                <p className="text-14">{item.ano}</p>
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="text-center mt-3 mb-3">
                                <p className="card-text fw-bold text-14">Mes</p>
                                <p className="text-14">{meses.find(x => x.no === item.mes).nombre}</p>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="text-center mt-3 mb-3">
                                <p className="card-text fw-bold text-14">Empleado</p>
                                <p className="text-14">{item.empleado}</p>
                                </div>
                            </div>


                        
                            <div className="col-md-2">
                                <div className='mt-2 mb-2 d-flex justify-content-end' >

                                    <>
                                        <IconButtonText Icon={PermContactCalendarIcon} params={item} func={navigateNominaEmpleado} id={'btn-ver-mi-nomina'}  />
                                        <IconButtonText Icon={TaskOutlinedIcon} params={item} func={generateReportNominaEmpleado} color={'#BD0000'} id={'btn-generar-pdf-nomina'} />
                                    </>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            ))
        }

        <ViewPDF 
        open={openModal}
        dataReporte={dataReporteNominaPDF}
        setOpen={setOpenModal}
        />
    
    </>
  )
}
