import React, { useState } from "react";
import { useMemo } from "react";
import MuiAccordion from "@mui/material/Accordion";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import {
  LocalPhone,
  Email,
  LocationOn,
  Delete,
  BorderColor,
  Visibility,
} from "@mui/icons-material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import "./EmpleadosAccordion.scss";
import moment from "moment";
import { useNavigate } from "react-router";
import DesvincularEmpleado from "../Modals/Empleados/DesvincularEmpleado";
import { PermissionData } from "../../context/Component/PermissionData";

const Empleados = (props) => {

  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  const [expanded, setExpanded] = React.useState(false);
  const {
    empleados,
    handleDesvincularEmpleado,
    addRequestStatus,
    setAddRequestStatus,
    cleanfilter,
    cargarEmpleados
  } = props;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const Accordion = withStyles({
    root: {
      marginTop: "10px",
    },
  })(MuiAccordion);


  // * COMPONENTE PERSONALIZADO DE LA IMAGEN. 
  const ImageComponent = ({ empleado }) => {
    
    // Memoriza la URL de la imagen solo cuando `empleado.foto` cambia
    const imageUrl = useMemo(() => {
      return `${empleado.foto}?cacheBust=${new Date().getTime()}`;
    }, [empleado.foto]);

    const component = (
      <>
        <Avatar
        sx={{
          width: 80,
          height: 80,
          marginLeft: 0,
          border: "solid",
          borderWidth: 0.5,
          borderColor: "#003876",
        }}
        alt={`${empleado.nombres} ${empleado.apellidos} `}
        src={imageUrl}
        />
        <label className="codigoEmpleado ms-3">
          {empleado.codigoEmpleado}
        </label>
      </>
    )
  
    return component;
  }

  return (
    empleados.map((empleado) => (
      <div>
      <Accordion
        key={empleado.id}
        expanded={expanded === empleado.id}
        onChange={handleChange(empleado.id)}
      >
        <AccordionSummary
          
          expandIcon={<IconButton id="button-accordion"><ExpandMoreIcon/></IconButton>}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {expanded !== empleado.id ? (
            <div className="container">
              <div className="row">
                <div className="col-md-2">
                  <ImageComponent empleado={empleado} />
                </div>

                <div className="col-md-10">
                  <h3 className="text-primary-mem">{`${empleado.nombres} ${empleado.apellidos}`}</h3>
                  <div className="d-flex flex-row">
                    <div className="col-md-8">
                      <div className="d-flex flex-column">
                        <label> {empleado.cargoLabel}</label>
                        <label>{empleado.areaOrganizacionalLabel}</label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={`d-flex flex-column `}>
                        <label>
                          <LocalPhone className="text-primary-mem" />{" "}
                          {empleado.telefono
                            ? empleado.telefono
                            : empleado.celular}
                        </label>
                        <label>
                          <Email className="text-primary-mem" />{" "}
                          {empleado.emailInstitucional}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="row">
                <div style={{ marginLeft: "6rem" }}>
                  <div className="col-md-12">
                    <h3 className="text-primary-mem">{`${empleado.nombres} ${empleado.apellidos}`}</h3>
                  </div>
                  <div className="d-flex flex-column">
                    <label>{empleado.areaOrganizacionalLabel}</label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div className="container">
            <div className="row text-end">
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                spacing={1}
              >
                <IconButton
                  id="btn-ver-empleado"
                  aria-label="Ver Empleado"
                  className="text-primary-mem"
                  hidden={!permisosState.ROLE_VER_REGISTRO_EMPLEADOS}
                >
                  <Visibility
                    fontSize="30"
                    onClick={() =>
                      navigate(`/empleados/detalle/${empleado.id}`)
                    }
                  />
                </IconButton>
                <IconButton
                  id="btn-editar-empleado"
                  aria-label="Editar Empleado"
                  className="text-success"
                  onClick={() => navigate(`/empleados/editar/${empleado.id}`)}
                  hidden={!permisosState.ROLE_EDITAR_REGISTRO_EMPLEADOS}
                >
                  <BorderColor fontSize="30" />
                </IconButton>

                {empleado.estado === 1 && (
                  <IconButton
                    id="btn-eliminar-empleado"
                    aria-label="Eliminar Empleado"
                    className="text-danger"
                    onClick={() => setOpen(true)}
                    hidden={!permisosState.ROLE_BORRAR_REGISTRO_EMPLEADOS}
                  >
                    <Delete fontSize="30" />
                  </IconButton>
                )}
              </Stack>
            </div>
            <div className="row">
              <div className="col-md-2">
                <ImageComponent empleado={empleado} />
              </div>

              <div className="col-md-10 d-flex flex-row  ps-0">
                <div className="col-md-3 border-end border-primary ">
                  <div className="row">
                    <div className="d-flex ">
                      <div className="col-md-6">
                        <label>Fecha Nacimiento: </label>
                      </div>
                      <div className="col-md-6">
                        <label>
                          {moment(empleado.fechaNacimiento).format(
                            "DD/MM/yyyy"
                          )}

                          <b> ({moment().diff(empleado.fechaNacimiento, 'years',false)})</b>
                        </label>
                      </div>
                    </div>
                    <div className="d-flex ">
                      <div className="col-md-6">
                        <label>Cedula: </label>
                      </div>
                      <div className="col-md-6">
                        <label>{empleado.cedula}</label>
                      </div>
                    </div>
                    <div className="d-flex ">
                      <div className="col-md-6">
                        <label>Sexo: </label>
                      </div>
                      <div className="col-md-6">
                        <label>{empleado.sexo}</label>
                      </div>
                    </div>
                    <div className="d-flex ">
                      <div className="col-md-6">
                        <label>Estado Civil: </label>
                      </div>
                      <div className="col-md-6">
                        <label>{empleado.estadoCivilLabel}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 border-end border-primary ms-2 ">
                  <div className="row">
                    <div className="d-flex ">
                      <div className="col-md-4">
                        <label>Fecha Ingreso: </label>
                      </div>
                      <div className="col-md-8">
                        <label>
                          <label>
                            {moment(empleado.fechaIngreso).format("DD/MM/yyyy")}
                          </label>
                        </label>
                      </div>
                    </div>
                    <div className="d-flex ">
                      <div className="col-md-4">
                        <label>Cargo: </label>
                      </div>
                      <div className="col-md-8">
                        <label>{empleado.cargoLabel}</label>
                      </div>
                    </div>
                    <div className="d-flex ">
                      <div className="col-md-4">
                        <label>Grupo Ocupacional: </label>
                      </div>
                      <div className="col-md-8">
                        <label>{empleado.grupoOcupacionalLabel}</label>
                      </div>
                    </div>
                    <div className="d-flex ">
                      <div className="col-md-4">
                        <label>Tipo de Empleado: </label>
                      </div>
                      <div className="col-md-8">
                        <label>{empleado.tipoEmpleadoLabel}</label>
                      </div>
                    </div>
                    <div className="d-flex ">
                      <div className="col-md-4">
                        <label>Horario: </label>
                      </div>
                      <div className="col-md-8">
                        <label>{empleado.horario}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex ps-3">
                    <div className="d-flex flex-column  pe-3">
                      <label>
                        <Email className="text-primary-mem me-2" />{" "}
                        {empleado.emailInstitucional}
                      </label>
                      <label>
                        <LocalPhone className="text-primary-mem me-2" />{" "}
                        {empleado.telefono
                          ? empleado.telefono
                          : empleado.celular}
                      </label>
                      <label>
                        <LocationOn className="text-primary-mem me-2" />{" "}
                        {empleado.direccion}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </AccordionDetails>
        </Accordion>

        <DesvincularEmpleado
          addRequestStatus={addRequestStatus}
          cargarEmpleados={cargarEmpleados}
          cleanfilter={cleanfilter}
          setAddRequestStatus={setAddRequestStatus}
          open={open}
          setOpen={setOpen}
          empleado={empleado}
          handleDesvincularEmpleado={handleDesvincularEmpleado}
        />
        
      </div>
    ))
  );
};

export default Empleados;

export const EmpleadosAccordion = React.memo(Empleados, (prevProps, nextProps) => { 
 
  const { empleados } = prevProps;
  const { empleados : empleadosUpdate } = nextProps;

  // Compara cada elemento en el array (asumiendo que son elementos simples o que tienen un identificador único)
  for (let i = 0; i < empleados.length; i++) {
    if (empleados[i] !== empleadosUpdate[i]) {
      return false;
    }
  }

  return true; // No hubo cambios, evitar renderizado
});

