import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints, apiBase } from "./../config";
import { messageFrontEnd } from "../../utils/mensajeErrorCliente"

const initialState = {
  isLoading: false,
  empleado: {},
  empleados: [],
  empleadoDashboard: {},
  empleadosList: [],
  empleadoByFilter: {},
  empleadosDesvinculados: [],
  empleadoCumpleanos: [],
  panelControl: [],
  reporteDinamico: [],
  empleadosCount: 0,
  reporteDinamicoCount: 0,
  message: "",
  isSuccess: "idle", // 'success' | "error"
};

export const fetchEmpleados = createAsyncThunk(
  "empleados/fetchEmpleados",
  async (filtro) => {
    const { skipRow, rowsPerPage, areaOrganizacionalId, empleado, estado } = filtro;
    try {
      // var resultado = await axios.get('https://localhost:${apiBase}/empleados?index=1&itemsPerPage=25');
      var resultado = await axiosApiInstance.get(
        `${endpoints.empleados.fetchEmpleados}?index=${Number(
          skipRow
        )}&itemsPerPage=${Number(
          rowsPerPage
        )}&empleado=${empleado}&areaOrganizacionalId=${Number(
          areaOrganizacionalId
        )}&estado=${estado}`
      );
      return resultado.data;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchAllEmpleados = createAsyncThunk(
  "empleados/fetchAllEmpleados",
  async () => {
    try {
      // var resultado = await axios.get('https://localhost:${apiBase}/empleados?index=1&itemsPerPage=25');
      var resultado = await axiosApiInstance.get(
        `${endpoints.empleados.fetchAllEmpleados}`
      );
      return resultado.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchEmpleadosCount = createAsyncThunk(
  "fetchEmpleadosCount",
  async (filtro) => {
    const { areaOrganizacionalId, cargoId, empleado, estado } = filtro;
    try {
      var resultado = await axiosApiInstance.get(
        `${endpoints.empleados.fetchEmpleadosCount}?areaOrganizacionalId=${Number(
          areaOrganizacionalId
        )}&cargoId=${Number(cargoId)}&empleado=${empleado}&estado=${Number(
          estado
        )}`
      );
      return resultado.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchEmpleado = createAsyncThunk(
  "empleados/fetchEmpleado",
  async (empleadoId) => {
    try {
      // var resultado = await axios.get('https://localhost:${apiBase}/empleados?index=1&itemsPerPage=25');
      var resultado = await axiosApiInstance.get(
        `${endpoints.empleados.fetchEmpleado}/${empleadoId}`
      );
      return resultado.data;
    } catch (error) {
      console.error(error.message);
    }
  }
);

export const addNewEmpleado = createAsyncThunk(
  "empleados/addNewEmpleado",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/nuevo`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const updateEmpleado = createAsyncThunk(
  "empleados/updateEmpleado",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/editar`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const desvincularEmpleado = createAsyncThunk(
  "empleados/desvincularEmpleado",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/desvincular`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const addNewEmpleadoComposicionFamiliar = createAsyncThunk(
  "empleados/addNewEmpleadoComposicionFamiliar",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/composicionfamiliar/nuevo`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const updateEmpleadoComposicionFamiliar = createAsyncThunk(
  "empleados/updateEmpleadoComposicionFamiliar",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/composicionfamiliar/editar`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const deleteEmpleadoComposicionFamiliar = createAsyncThunk(
  "empleados/deleteEmpleadoComposicionFamiliar",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/composicionfamiliar/eliminar`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const addNewEmpleadoHistorialLaboral = createAsyncThunk(
  "empleados/addNewEmpleadoHistorialLaboral",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/historiallaboral/nuevo`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const updateEmpleadoHistorialLaboral = createAsyncThunk(
  "empleados/updateEmpleadoHistorialLaboral",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/historiallaboral/editar`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const deleteEmpleadoHistorialLaboral = createAsyncThunk(
  "empleados/deleteEmpleadoHistorialLaboral",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/historiallaboral/eliminar`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const addNewEmpleadoEstudios = createAsyncThunk(
  "empleados/addNewEmpleadoEstudios",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/estudio/nuevo`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const updateEmpleadoEstudios = createAsyncThunk(
  "empleados/updateEmpleadoEstudios",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/estudio/editar`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const deleteEmpleadoEstudios = createAsyncThunk(
  "empleados/deleteEmpleadoEstudios",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/estudio/eliminar`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const addNewEmpleadoIdioma = createAsyncThunk(
  "empleados/addNewEmpleadoIdioma",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/idioma/nuevo`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const updateEmpleadoIdioma = createAsyncThunk(
  "empleados/updateEmpleadoIdioma",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        `${apiBase}empleado/idioma/editar`,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const deleteEmpleadoIdioma = createAsyncThunk(
  "empleados/deleteEmpleadoIdioma",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        endpoints.empleados.deleteEmpleadoIdioma,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const saveImagenEmpleado = createAsyncThunk(
  "empleados/saveImagenEmpleado",
  async (initialEmpleado) => {
    try {
      const response = await axiosApiInstance.post(
        endpoints.empleados.saveImagen,
        initialEmpleado
      );
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const fetchEmpleadoCumpleanos = createAsyncThunk(
  "empleados/fetchEmpleadoCumpleanos",
  async () => {
    try {
      const response = await axiosApiInstance.get(endpoints.empleados.fetchCumpleanos);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const fetchPanelControl = createAsyncThunk(
  "empleados/fetchPanelControl",
  async () => {
    try {
      const response = await axiosApiInstance.get(endpoints.empleados.panelControl);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const fetchGetReporteDinamico = createAsyncThunk(
  "empleados/fetchGetReporteDinamico",
  async ({filtro, skipRow, rowsPerPage}) => {

    try {
      const response = await axiosApiInstance.post(`${endpoints.empleados.fetchGetReporteDinamico}/${skipRow}/${rowsPerPage}`, filtro);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const fetchGetReporteDinamicoCount = createAsyncThunk(
  "empleados/fetchGetReporteDinamicoCount",
  async ({filtro}) => {

    try {
      const response = await axiosApiInstance.post(endpoints.empleados.fetchGetReporteDinamicoCount, filtro);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const fetchCambiarSuccess = createAsyncThunk(
  "reporteAsistencias/fetchCambiarSuccess",
  async () => {
    return null;
  }
);

export const fetchReporteDinamicoExcel = createAsyncThunk(
  "empleados/fetchReporteDinamicoExcel",
  async (data) => {

    const result = await axiosApiInstance({
      url:
        `${endpoints.empleados.fetchGetReporteDinamicoExcel}?reportType=XLS`
      , //your url
      method: "POST",
      responseType: "blob", // important
      data: data,
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `Reporte de Empleados.xls`
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

    return result;
  }
);

export const fetchGetEmpleadoByCedulaOrCodigoEmpleado = createAsyncThunk(
  "empleados/fetchGetEmpleadoByCedulaOrCodigoEmpleado",
  async (filtro) => {

    try {
      const response = await axiosApiInstance.post(endpoints.empleados.fetchGetEmpleadoByCedulaOrCodigoEmpleado, filtro);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const fetchGetEmpleadoDashboard = createAsyncThunk(
  "empleados/fetchGetEmpleadoDashboard",
  async (empleadoId) => {

    try {
      const response = await axiosApiInstance.get(`${endpoints.empleados.fetchGetEmpleadoDashboard}?empleadoId=${empleadoId}`);
      return response.data;
    } catch (error) {
      throw error.message;
    }
  }
);

export const EmpleadosSlice = createSlice({
  name: "empleados",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCambiarSuccess.fulfilled, (state, action) => {
        state.isSuccess = "idle";
      })
      .addCase(fetchEmpleados.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEmpleados.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.empleados = action.payload.data;
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
          state.empleados = [];
        }
      })
      .addCase(fetchEmpleados.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";

        state.message = action.error?.message ?? "";
      })
      .addCase(fetchGetEmpleadoDashboard.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchGetEmpleadoDashboard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.empleadoDashboard = action.payload.data;
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
          state.empleadoDashboard = [];
        }
      })
      .addCase(fetchGetEmpleadoDashboard.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";

        state.message = action.error?.message ?? "";
      })
      .addCase(fetchAllEmpleados.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchAllEmpleados.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.empleadosList = action.payload.data;
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
          state.empleadosList = [];
        }
      })
      .addCase(fetchGetEmpleadoByCedulaOrCodigoEmpleado.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchGetEmpleadoByCedulaOrCodigoEmpleado.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.empleadoByFilter = action.payload.data;
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
          state.empleadosList = [];
        }
      })
      .addCase(fetchGetEmpleadoByCedulaOrCodigoEmpleado.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";

        state.message = action.error.message;
      })
      .addCase(fetchEmpleado.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEmpleado.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload?.message;
        if (action.payload?.isSuccess) {
          state.empleado = action.payload.data;
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
          state.empleado = {};
        }
      })
      .addCase(fetchEmpleado.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.empleado = {};
        state.message = action.error?.message ?? "";
      })
      .addCase(fetchEmpleadosCount.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;
          state.empleadosCount = action.payload.data;
        } else {
          state.empleadosCount = 0;
          state.isSuccess = "error";
        }
      })
      .addCase(updateEmpleado.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(updateEmpleado.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          action.payload.data.fechaNacimiento = new Date(
            action.payload?.data?.fechaNacimiento
          );
          action.payload.data.fechaIngreso = new Date(
            action.payload?.data?.fechaIngreso
          );
          state.isSuccess = "success";
          state.message = action.payload?.message
          state.empleados = state.empleados.filter(
            (x) => x.id !== action.payload.data.id
          );
          state.empleados.push(action.payload.data);
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(updateEmpleado.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(addNewEmpleado.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(addNewEmpleado.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          action.payload.data.fechaNacimiento = new Date(
            action.payload?.data?.fechaNacimiento
          );
          action.payload.data.fechaIngreso = new Date(
            action.payload?.data?.fechaIngreso
          );
          state.isSuccess = "success";
          state.empleados.push(action.payload.data);
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(addNewEmpleado.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(desvincularEmpleado.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(desvincularEmpleado.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.empleados.filter(
            (x) => x.id !== action.payload.data.empleadoId
          );
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(desvincularEmpleado.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(addNewEmpleadoComposicionFamiliar.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(addNewEmpleadoComposicionFamiliar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.empleado.composicionFamiliar.push(action.payload.data);
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(addNewEmpleadoComposicionFamiliar.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(updateEmpleadoComposicionFamiliar.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(updateEmpleadoComposicionFamiliar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.empleado.composicionFamiliar = state.empleado.composicionFamiliar.filter(
            (x) => x.id !== action.payload.data.id
          );
          state.empleado.composicionFamiliar.push(action.payload.data);
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(updateEmpleadoComposicionFamiliar.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(deleteEmpleadoComposicionFamiliar.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(deleteEmpleadoComposicionFamiliar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
        } 
        else {
          state.isSuccess = "error";
        }
      })
      .addCase(deleteEmpleadoComposicionFamiliar.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(addNewEmpleadoHistorialLaboral.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(addNewEmpleadoHistorialLaboral.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.empleado.historialLaboral.push(action.payload.data);
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(addNewEmpleadoHistorialLaboral.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(updateEmpleadoHistorialLaboral.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(updateEmpleadoHistorialLaboral.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.empleado.historialLaboral = state.empleado.historialLaboral.filter(
            (x) => x.id !== action.payload.data.id
          );
          state.empleado.historialLaboral.push(action.payload.data);
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(updateEmpleadoHistorialLaboral.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(deleteEmpleadoHistorialLaboral.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(deleteEmpleadoHistorialLaboral.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.empleado.historialLaboral = state.empleado.historialLaboral.filter(
            (x) => x.id !== action.payload.data.id
          );
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(deleteEmpleadoHistorialLaboral.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(addNewEmpleadoEstudios.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(addNewEmpleadoEstudios.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.empleado.estudios.push(action.payload.data);
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(addNewEmpleadoEstudios.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(updateEmpleadoEstudios.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(updateEmpleadoEstudios.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.empleado.estudios = state.empleado.estudios.filter(
            (x) => x.id !== action.payload.data.id
          );
          state.empleado.estudios.push(action.payload.data);
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(updateEmpleadoEstudios.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(deleteEmpleadoEstudios.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(deleteEmpleadoEstudios.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.empleado.estudios = state.empleado.estudios.filter(
            (x) => x.id !== action.payload.data.id
          );
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(deleteEmpleadoEstudios.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(addNewEmpleadoIdioma.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(addNewEmpleadoIdioma.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.empleado.idiomasEmpleado.push(action.payload.data);
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(addNewEmpleadoIdioma.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(updateEmpleadoIdioma.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(updateEmpleadoIdioma.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.empleado.idiomasEmpleado = state.empleado.idiomasEmpleado.filter(
            (x) => x.id !== action.payload.data.id
          );
          state.empleado.idiomasEmpleado.push(action.payload.data);
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(updateEmpleadoIdioma.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(deleteEmpleadoIdioma.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(deleteEmpleadoIdioma.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.empleado.idiomasEmpleado = state.empleado.idiomasEmpleado.filter(
            (x) => x.id !== action.payload.data.id
          );
        } else {
          state.isSuccess = "error";
        }
      })
      .addCase(deleteEmpleadoIdioma.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(saveImagenEmpleado.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(saveImagenEmpleado.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.message = action.payload.message;
        } else {
          state.isSuccess = "error";
          state.message = action.payload.message;
        }
      })
      .addCase(saveImagenEmpleado.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        state.message = action.error.message;
      })
      .addCase(fetchEmpleadoCumpleanos.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchEmpleadoCumpleanos.fulfilled, (state, action) => {

        if (action.payload.isSuccess) {
          state.empleadoCumpleanos = action.payload.data;
          state.isSuccess = "success";
          state.message = action.payload.message;
        } 
        else {
          state.isSuccess = "error";
          state.empleadoCumpleanos = [];
        }

        state.isLoading = false;
      })
      .addCase(fetchEmpleadoCumpleanos.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        console.error( action.error.message)
        state.empleadoCumpleanos = [];
      })
      .addCase(fetchPanelControl.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchPanelControl.fulfilled, (state, action) => {

        if (action.payload.isSuccess) {
          state.panelControl = action.payload.data;
          state.isSuccess = "success";
          state.message = action.payload.message;
        } 
        else {
          state.isSuccess = "error";
          state.panelControl = [];
        }

        state.isLoading = false;
      })
      .addCase(fetchPanelControl.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";
        console.error( action.error.message)
        state.panelControl = [];
      })
      .addCase(fetchGetReporteDinamico.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchGetReporteDinamico.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.reporteDinamico = action.payload.data;
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
          state.reporteDinamico = [];
        }
      })
      .addCase(fetchGetReporteDinamico.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";

        state.message = action.error.message;
      })
      .addCase(fetchGetReporteDinamicoCount.pending, (state, action) => {
        state.isLoading = true;
        state.isSuccess = "idle";
      })
      .addCase(fetchGetReporteDinamicoCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.reporteDinamicoCount = action.payload.data;
          state.isSuccess = "success";
        } else {
          state.isSuccess = "error";
          state.reporteDinamicoCount = [];
        }
      })
      .addCase(fetchGetReporteDinamicoCount.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = "error";

        state.message = action.error.message;
      })
      .addCase(fetchReporteDinamicoExcel.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchReporteDinamicoExcel.fulfilled, (state, action) => {
        state.isSuccess = "success";
        state.message = "El reporte fue generado.";
        state.isLoading = false;
      })
      .addCase(fetchReporteDinamicoExcel.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        state.isLoading = false;
      })
      ;
  },
});

export const selectAllEmpleados = (state) => state.empleados.empleados;
export const selectAllEmpleadosList = (state) => state.empleados.empleadosList;
export const selectEmpleado = (state) => state.empleados.empleado;
export const selectEmpleadoFilter = (state) => state.empleados.empleadoByFilter;
export const selectAllEmpleadosDesvinculados = (state) => state.empleados.empleadosDesvinculados;
export const selectEmpleadoCumpleanos = (state) => state.empleados.empleadoCumpleanos;
export const selectPanelControl = (state) => state.empleados.panelControl;
export const selectEmpleadosIsSuccess = (state) => state.empleados.isSuccess;
export const selectEmpleadosIsLoading = (state) => state.empleados.isLoading;
export const selectEmpleadosMessage = (state) => state.empleados.message;
export const selectEmpleadosCount = (state) => state.empleados.empleadosCount;
export const selectReporteDinamicoData = (state) => state.empleados.reporteDinamico;
export const selectReporteDinamicoCount = (state) => state.empleados.reporteDinamicoCount;
export const selectIsLoading = (state) => state.empleados.isLoading;
export const selectIsSuccess = (state) => state.empleados.isSuccess;
export const selectMessage = (state) => state.empleados.message;
export const selectEmpleadoDashboard = (state) => state.empleados.empleadoDashboard;

// export const {} = EmpleadosSlice.actions;
export default EmpleadosSlice.reducer;
