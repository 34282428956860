import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import { CardActionArea, IconButton } from "@mui/material";
import { AccessTime, BorderColor, Delete } from "@mui/icons-material";
import HorarioEditar from "../Modals/Horario/HorarioEditar";
import { diasSemana } from "../../utils/apiClient";
import {PermissionData} from '../../context/Component/PermissionData';

const HorariosCard = ({ horario, eliminarHorario }) => {

   // * PERMISOS DEL USUARIO.
   const permisosState = PermissionData();
   
  const [openEditar, setOpenEditar] = useState(false);
  return (
    <React.Fragment>
      <Card sx={{ maxWidth: 345, marginTop: 2, marginBottom: 1 }}>
        <div className="d-flex justify-content-between">
          <IconButton id="btn-eliminar-horario">
            <Delete
              className="text-danger"
              onClick={() => eliminarHorario(horario)}
              sx={{ cursor: "pointer" }}
              hidden={!permisosState.ROLE_BORRAR_HORARIO}
            />
          </IconButton>
          <IconButton id="btn-editar-horario">
            <BorderColor
              className="text-primary-mem"
              onClick={() => setOpenEditar(true)}
              sx={{ cursor: "pointer" }}
              hidden={!permisosState.ROLE_EDITAR_HORARIO}
            />
          </IconButton>
        </div>
        <div className="d-flex justify-content-center">
          <div className="col-2 ">
            {" "}
            <AccessTime className="text-primary-mem" fontSize="large" />
          </div>
          <div className="col-5">
            <h6 className="fw-bold">{horario.nombre}</h6>
          </div>
          <div className="col-3"></div>
        </div>
        <CardActionArea>
          <CardContent>
            {horario.turnos.map((turno) => (
              <div className="d-flex justify-content-between" key={turno.dia}>
                <div className="col">
                  {diasSemana.find((x) => x.id == turno.dia).value}
                </div>
                <div className="col">{`${moment(turno.horaInicioDate).format(
                  "hh:mm A"
                )} - ${moment(turno.horaTerminoDate).format("hh:mm A")}`}</div>
              </div>
            ))}

            <hr />
            <div className="d-flex justify-content-between">
              <div className="col-10">
                <h5 className="text-primary-mem">Empleados</h5>
              </div>
              <div className="col-2">
                <h5
                  className="text-primary-mem text-center"
                  style={{ backgroundColor: "#E2EDFC" }}
                >
                  {horario.empleados}
                </h5>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
      {Object.keys(horario).length > 0 && (
        <HorarioEditar
          setOpenEditar={setOpenEditar}
          openEditar={openEditar}
          horario={horario}
        />
      )}
    </React.Fragment>
  );
};

export default HorariosCard;
