import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../config";
import axiosApiInstance from "../../utils/apiClient";

const initialState = {
  isSuccess: "idle",
  isLoading: false,
  message: "",
};

export const fetchPermisosExportar = createAsyncThunk(
  "reportePermisos/fetchPermisosExportar",
  async (filtro) => {

    // * DESESTRUCTURACION DE LOS DATOS.
    const { desde, hasta, codigoEmpleado, areaId, todo, tipoPermiso } = filtro;

    const result = await axiosApiInstance({
        url: `${endpoints.permisos.reportePermisosExportar}?Desde=${desde}&Hasta=${hasta}&AreaId=${Number(areaId)}
        &CodigoEmpleado=${codigoEmpleado}&TipoPermiso=${tipoPermiso}&Estado=${todo}`, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
       
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
  
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Reporte de Permisos (${desde} - ${hasta}).pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
  
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
  
       
      });
    
    return result;

  }
);

export const fetchPermisosExportarExcel = createAsyncThunk(
  "reportePermisos/fetchPermisosExportarExcel",
  async (filtro) => {

    // * DESESTRUCTURACION DE LOS DATOS.
    const { desde, hasta, codigoEmpleado, areaId, todo, tipoPermiso } = filtro;

    const result = await axiosApiInstance({
        url: `${endpoints.permisos.reportePermisosExportarExcel}?Desde=${desde}&Hasta=${hasta}&AreaId=${Number(areaId)}
        &CodigoEmpleado=${codigoEmpleado}&TipoPermiso=${tipoPermiso}&Estado=${todo}`, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
       
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
  
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `Reporte de Permisos (${desde} - ${hasta}).xls`); //or any other extension
        document.body.appendChild(link);
        link.click();
  
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
  
       
      });
    
    return result;

  }
);

export const fetchCambiarSuccess = createAsyncThunk(
  "reportePermisos/fetchCambiarSuccess",
  async () => {
    return null;
  }
);



const ReportePermisosSlice = createSlice({
  name: "reportePermisos",
  initialState,
  extraReducers(builder) {
    builder

      .addCase(fetchPermisosExportar.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchPermisosExportar.fulfilled, (state, action) => {
        state.isSuccess = "success";
        state.message = "";
        state.isLoading = false;
      })
      .addCase(fetchPermisosExportar.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = action.payload?.error.message;
        state.isLoading = false;
      })
      .addCase(fetchPermisosExportarExcel.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchPermisosExportarExcel.fulfilled, (state, action) => {
        state.isSuccess = "success";
        state.message = "";
        state.isLoading = false;
      })
      .addCase(fetchPermisosExportarExcel.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = action.payload?.error.message;
        state.isLoading = false;
      })
      .addCase(fetchCambiarSuccess.fulfilled, (state) => {
        state.isSuccess = "idle";
      });
  },
});


export const selectIsLoading = (state) => state.reportePermisos.isLoading;
export const selectMessage = (state) => state.reportePermisos.message;
export const selectIsSuccess = (state) => state.reportePermisos.isSuccess;

export default ReportePermisosSlice.reducer;
