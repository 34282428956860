import React from "react";
import { Routes, Route, Navigate } from "react-router";
import RequiredPermission from "../authentication/RequiredPermission";
import {Asistencias, Permisos, ResumenAsistencia, Absentismo} from '../pages/Control/Reportes';
import {ReporteAsistenciaEmpleado} from '../pages/Control de Asistencia/Reportes de Asistencia/ReporteAsistenciaEmpleado'

export const RutasReportes = () => {

  return (
    <Routes>

      <Route element={<RequiredPermission permission='ROLE_GENERAR_REPORTES_CONTROL' />}>
        <Route path="permisos" element={<Permisos />} />
      </Route>

     <Route element={<RequiredPermission permission='ROLE_GENERAR_REPORTES_ASISTENCIA' />}>
        <Route path="asistencias" element={<Asistencias />} />
        <Route path="resumenAsistencia" element={<ResumenAsistencia />} />
        <Route path="absentismo" element={<Absentismo />} />
     </Route>
      
      <Route path="reporteAsistencia" element={<ReporteAsistenciaEmpleado />} />
      <Route path='/*' element={<Navigate to="/" replace />} />

    </Routes>
  );
};
