import React from "react";
import Loading from "../../../Components/Modals/Loading/Loader";
import { FiltroReportesPermisos } from "../../../Components/Cards";
import { useDispatch, useSelector } from "react-redux";
import {selectIsLoading, fetchPermisosExportar, fetchCambiarSuccess, fetchPermisosExportarExcel} from '../../../redux/Reportes/ReportePermisosSlice';
import { Encabezado } from '../../../Components/Encabezado/Encabezado';

export const Permisos = () => {

  // ? USE-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const isLoading = useSelector(selectIsLoading);

  // * FUNCION QUE GENERA EL EXCEL
  const onGenerateExcel = (filtro) => {
    dispatch(fetchPermisosExportarExcel(filtro));
  }

  const onGeneratePDF = (filtro) => {
    dispatch(fetchPermisosExportar(filtro));
  }

  // * FUNCION QUE CAMBIAR EL ESTADO DEL SUCCESS.
  const updateSuccess = () => {
    dispatch(fetchCambiarSuccess());
  }

  return (

    <Encabezado title='REPORTE DE PERMISOS'>

      <div className="row">
        <div className="col">

          <FiltroReportesPermisos 
          onGenerateExcel = {onGenerateExcel} 
          onGeneratePDF = {onGeneratePDF}
          updateSuccess = {updateSuccess} 
          />
      
        </div>
      </div>

      {/* PANTALLA DE CARGA */}
      <Loading open={isLoading} />
    </Encabezado>

  );
};
