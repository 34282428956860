import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosApiInstance from './../../utils/apiClient';
import { endpoints } from './../config';

const initialState = {
	permisos: [],
	permisosAll:[],
	tiposPermisos:[],
	permisosCount:0,
	tiposPermisosCategorias:[],
	permiso: {},
	esEncargado: false,
	isSuccess: 'idle', // 'success' | "error",
	message: '',
	isLoading: false
};

// * LIMPIAR SUCCESS
export const fetchCambiarSuccess = createAsyncThunk(
	"permisos/fetchCambiarSuccess",
	async () => {
	  return null;
	}
);

export const fetchPermisos = createAsyncThunk('permisos/fetchPermisos', async () => {
	try {
		let permisos = await axiosApiInstance.get(`${endpoints.permisos.fetchPermisos}`);
		return permisos.data;
	} catch (error) {
		throw error;
	}
});

// * PAGINACION DE PERMISOS

export const fetchPermisosAll = createAsyncThunk('permisos/fetchPermisosAll', async ({aprobacionesFilter, skipRow, rowsPerPage}) => {

	try {
		let permisosAll = await axiosApiInstance.post(`${endpoints.permisos.fetchPermisos}/${skipRow}/${rowsPerPage}`, 
			aprobacionesFilter
		);
		return permisosAll.data;
	} catch (error) {
		throw error;
	}
});

export const fetchCountPermisos = createAsyncThunk('permisos/fetchCountPermisos', async (filtro) => {

	try {
		let permisosCount = await axiosApiInstance.post(endpoints.permisos.permisosCount, 
			filtro
		);
		return permisosCount.data;
	} catch (error) {
		throw error;
	}
});

export const fetchPermiso = createAsyncThunk('permisos/fetchPermiso', async (permisoId) => {
	try {
		let permisos = await axiosApiInstance.get(`${endpoints.permisos.fetchPermiso}/${permisoId}`);
		return permisos.data;
	} catch (error) {
		throw error;
	}
});

export const fetchTiposPermisosCategorias = createAsyncThunk('permisos/fetchTiposPermisosCategorias', async () => {
	try {
		let permisos = await axiosApiInstance.get(`${endpoints.permisos.fetchTiposPermisosCategorias}`);
		return permisos.data;
	} catch (error) {
		throw error;
	}
});


export const fetchTiposPermisos = createAsyncThunk('permisos/fetchTiposPermisos', async () => {
	try {
		let tipospermisos = await axiosApiInstance.get(`${endpoints.permisos.fetchTiposPermisos}`);
		return tipospermisos.data;
	} catch (error) {
		throw error;
	}
});

export const savePermiso = createAsyncThunk('permisos/savePermiso', async (permiso) => {
	try {
		let newPermiso = await axiosApiInstance.post(`${endpoints.permisos.savePermiso}`, permiso);
		return newPermiso.data;
	} catch (error) {
		throw error;
	}
});

export const updatePermiso = createAsyncThunk('permisos/updatePermiso', async (permiso) => {
	try {
		let updatePermiso = await axiosApiInstance.post(`${endpoints.permisos.updatePermiso}`, permiso);
		return updatePermiso.data;
	} catch (error) {
		throw error;
	}
});
export const deletePermiso = createAsyncThunk('permisos/deletePermiso', async (permiso) => {
	try {
		let deletePermiso = await axiosApiInstance.post(`${endpoints.permisos.deletePermiso}`, permiso);
		return deletePermiso.data;
	} catch (error) {
		throw error;
	}
});
export const saveTipoPermiso = createAsyncThunk('permisos/saveTipoPermiso', async (tipoPermiso) => {
	try {
		let newTipoPermiso = await axiosApiInstance.post(`${endpoints.permisos.saveTipoPermisos}`, tipoPermiso);
		return newTipoPermiso.data;
	} catch (error) {
		throw error;
	}
});

export const updateTipoPermiso = createAsyncThunk('permisos/updateTipoPermiso', async (tipoPermiso) => {
	try {
		let updateTipoPermiso = await axiosApiInstance.post(`${endpoints.permisos.updateTipoPermisos}`, tipoPermiso);
		return updateTipoPermiso.data;
	} catch (error) {
		throw error;
	}
});
export const deleteTipoPermiso = createAsyncThunk('permisos/deleteTipoPermiso', async (tipoPermiso) => {
	try {
		let deleteTipoPermiso = await axiosApiInstance.post(`${endpoints.permisos.deleteTipoPermisos}`, tipoPermiso);
		return deleteTipoPermiso.data;
	} catch (error) {
		throw error;
	}
});

// * END-POINTS ACTUALIZAR ESTADOS PERMISOS-EMPLEADOS.

export const aprobarPermisoEmpleadoEncargado = createAsyncThunk('permisos/aprobarPermisoEmpleadoEncargado', 

	async (permisoId) => {
		try {
			let result = await axiosApiInstance.post(`${endpoints.permisos.aprobarPermisoEmpleadoEncargado}/${permisoId}`);
			return result.data;
		} catch (error) {
			throw error;
		}
	}
);

export const aprobarPermisoEmpleadoRRHH = createAsyncThunk('permisos/aprobarPermisoEmpleadoRRHH', 

	async (permisoId) => {
		try {
			let result = await axiosApiInstance.post(`${endpoints.permisos.aprobarPermisoEmpleadoRRHH}/${permisoId}`);
			return result.data;
		} catch (error) {
			throw error;
		}
	}
);

export const rechazarPermiso = createAsyncThunk('permisos/rechazarPermiso', 

	async (permisoId) => {
		try {
			let result = await axiosApiInstance.post(`${endpoints.permisos.rechazarPermisoEmpleado}/${permisoId}`);
			return result.data;
		} catch (error) {
			throw error;
		}
	}
);

// * VERIFICAR ENCARGADO-AREA PERMISO
export const fetchVerificarEncargadoPermiso = createAsyncThunk('permisos/fetchVerificarEncargadoPermiso', 

	async (data) => {
		
		const { Id, AreaOrganizacionalId } = data;

		try {
			let result = await axiosApiInstance.post(`${endpoints.permisos.verificarEncargado}?areaOrganizacionalId=${AreaOrganizacionalId}&empleadoId=${Id}`);
			return result.data;
		} catch (error) {
			throw error;
		}
	}
);


// * IMPRIMIR PERMISO APROBADO.
export const fetchGenerarReportePermisoPDF = createAsyncThunk(
	"evaluaciones/fetchGenerarReportePermisoPDF",
	async (permisoId) => {
	  const result = await axiosApiInstance({
		url: `${
		  endpoints.permisos.reportePermisoAprobado
		}?permisoId=${permisoId}`, //your url
		method: "GET",
		responseType: "blob", // important
	  }).then((response) => {
		// create file link in browser's memory
		const href = URL.createObjectURL(response.data);
  
		// create "a" HTML element with href to file & click
		const link = document.createElement("a");
		link.href = href;
		link.setAttribute(
		  "download",
		  `Reporte de Permiso (${permisoId}).pdf`
		); //or any other extension
		document.body.appendChild(link);
		link.click();
  
		// clean up "a" element & remove ObjectURL
		document.body.removeChild(link);
		URL.revokeObjectURL(href);
	  });
  
	  return result;
	}
);
  
// * ELIMINAR PERMISO
export const fetchEliminarPermiso = createAsyncThunk(
	"evaluaciones/fetchEliminarPermiso",
	async (permisoId) => {
	  let resultado = await axiosApiInstance.post(`${endpoints.permisos.eliminarPermiso}/${permisoId}`);
	  return resultado.data;
	}
)

// * PERMISOS POR AREA-REPORTE-ID
export const fetchListaPermisosAreaReporteId = createAsyncThunk('permisos/fetchListaPermisos', async ({aprobacionesFilter, skipRow, rowsPerPage}) => {

	try {
		let permisos = await axiosApiInstance.post(`${endpoints.permisos.fetchPermisosAreaReporteId}/${skipRow}/${rowsPerPage}`, aprobacionesFilter);
		return permisos.data;
	} catch (error) {
		throw error;
	}

});

export const fetchCountPermisosAreaReporteId = createAsyncThunk('permisos/fetchCountPermisosAreaReporteId', async (filtro) => {

	try {
		let permisosCount = await axiosApiInstance.post(`${endpoints.permisos.permisosCountAreaReporteId}`, filtro);
		return permisosCount.data;
	} catch (error) {
		throw error;
	}
});

export  const PermisosSlice = createSlice({
	name: 'permisos',
	initialState,
	extraReducers(builder) {
		builder
			.addCase(fetchPermisos.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(fetchPermisos.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.permisos = action.payload.data;
					state.isSuccess = 'success';
				} else {
					state.isSuccess = 'error';
					state.permisos = [];
				}
			})
			.addCase(fetchPermisos.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
			})
			.addCase(fetchCountPermisos.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(fetchCountPermisos.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.permisosCount = action.payload.data;
				} else {
					state.isSuccess = 'error';
					state.permisosCount = 0;
				}
			})
			.addCase(fetchCountPermisos.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
				state.permisosCount = 0;
			})
			.addCase(fetchPermisosAll.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(fetchPermisosAll.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.permisosAll = action.payload.data;
				} else {
					state.isSuccess = 'error';
					state.permiso = {};
				}
			})
			.addCase(fetchPermisosAll.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
			})
			.addCase(fetchPermiso.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(fetchPermiso.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.permiso = action.payload.data;
				} else {
					state.isSuccess = 'error';
					state.permiso = {};
				}
			})
			.addCase(fetchPermiso.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
			})
			.addCase(fetchTiposPermisosCategorias.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(fetchTiposPermisosCategorias.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.tiposPermisosCategorias = action.payload.data;
				} else {
					state.isSuccess = 'error';
					state.tiposPermisosCategorias = [];
				}
			})
			.addCase(fetchTiposPermisos.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(fetchTiposPermisos.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.tiposPermisos = action.payload.data;
				} else {
					state.isSuccess = 'error';
					state.tiposPermisos = [];
				}
			})
			.addCase(fetchTiposPermisos.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
			})
			.addCase(saveTipoPermiso.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(saveTipoPermiso.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.tiposPermisos.push(action.payload.data);
					state.isSuccess = 'success';
				} else {
					state.isSuccess = 'error';
				}
			})
			.addCase(saveTipoPermiso.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
			})
			.addCase(savePermiso.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(savePermiso.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.permisos.push(action.payload.data);
					state.isSuccess = 'success';
					state.message = 'El permiso fue guardado correctamente.';
				} else {
					state.isSuccess = 'error';
				}
			})
			.addCase(savePermiso.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
			})
			.addCase(updateTipoPermiso.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(updateTipoPermiso.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.tiposPermisos = state.tiposPermisos.filter((tipoPermiso) => tipoPermiso.id !== action.payload.data.id);
					state.tiposPermisos.push(action.payload.data);
					state.isSuccess = 'success';
				} else {
					state.isSuccess = 'error';
				}
			})
			.addCase(updateTipoPermiso.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
			})
			.addCase(updatePermiso.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(updatePermiso.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;

				if (action.payload.isSuccess) {
					state.message = "El permiso fue actualizado";
					state.permisosAll = state.permisosAll.filter((permiso) => permiso.id !== action.payload.data.id);
					state.permisosAll.push(action.payload.data);
					state.isSuccess = 'success';
				} else {
					state.isSuccess = 'error';
				}
			})
			.addCase(updatePermiso.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
			})
			.addCase(deletePermiso.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(deletePermiso.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.permisos = state.permisos.filter((permiso) => permiso.id !== action.payload.data.id);
					state.isSuccess = 'success';
				} else {
					state.isSuccess = 'error';
				}
			})
			.addCase(deletePermiso.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
			})
			.addCase(deleteTipoPermiso.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(deleteTipoPermiso.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.tiposPermisos = state.tiposPermisos.filter((tipoPermiso) => tipoPermiso.id !== action.payload.data.id);
					state.isSuccess = 'success';
				} else {
					state.isSuccess = 'error';
				}
			})
			.addCase(deleteTipoPermiso.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
			})
			.addCase(fetchCambiarSuccess.fulfilled, (state) => {
				state.isSuccess = "idle";
			})
			.addCase(aprobarPermisoEmpleadoEncargado.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(aprobarPermisoEmpleadoEncargado.fulfilled, (state, action) => {

				if (action.payload.isSuccess) {
					state.isSuccess = 'success';
					state.message = action.payload.message;
				} else {
					state.isSuccess = 'error';
					console.error(action.payload.message);
				}

				state.isLoading = false;
			})
			.addCase(aprobarPermisoEmpleadoEncargado.rejected, (state, action) => {
				state.isLoading = false;
				state.isSuccess = 'error';
				console.error(action.error.message);
			})
			.addCase(aprobarPermisoEmpleadoRRHH.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(aprobarPermisoEmpleadoRRHH.fulfilled, (state, action) => {

				if (action.payload.isSuccess) {
					state.isSuccess = 'success';
					state.message = action.payload.message;
				} else {
					state.isSuccess = 'error';
					console.error(action.payload.message);
				}

				state.isLoading = false;
			})
			.addCase(aprobarPermisoEmpleadoRRHH.rejected, (state, action) => {
				state.isLoading = false;
				state.isSuccess = 'error';
				console.error(action.error.message);
			})
			.addCase(rechazarPermiso.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(rechazarPermiso.fulfilled, (state, action) => {

				if (action.payload.isSuccess) {
					state.isSuccess = 'success';
					state.message = action.payload.message;
				} else {
					state.isSuccess = 'error';
					console.error(action.payload.message);
				}

				state.isLoading = false;
			})
			.addCase(rechazarPermiso.rejected, (state, action) => {
				state.isLoading = false;
				state.isSuccess = 'error';
				console.error(action.error.message);
			})
			.addCase(fetchVerificarEncargadoPermiso.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(fetchVerificarEncargadoPermiso.fulfilled, (state, action) => {

				if (action.payload.isSuccess) {
					state.isSuccess = 'success';
					state.message = action.payload.message;
					state.esEncargado = action.payload.data;
				} else {
					state.isSuccess = 'error';
					console.error(action.payload.message);
				}

				state.isLoading = false;
			})
			.addCase(fetchVerificarEncargadoPermiso.rejected, (state, action) => {
				state.isLoading = false;
				state.isSuccess = 'error';
				console.error(action.error.message);
			})
			.addCase(fetchGenerarReportePermisoPDF.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = "idle";
				state.message = "";
			  })
			.addCase(fetchGenerarReportePermisoPDF.fulfilled, (state, action) => {
			state.isSuccess = "success";
			state.message = "El reporte fue generado.";
			state.isLoading = false;
			})
			.addCase(fetchGenerarReportePermisoPDF.rejected, (state, action) => {
			state.isSuccess = "error";
			state.message = action.error.message;
			state.isLoading = false;
			})
			.addCase(fetchEliminarPermiso.pending, (state, action) => {
			state.isLoading = true;
			state.isSuccess = "idle";
			})
			.addCase(fetchEliminarPermiso.fulfilled, (state, action) => {
			state.isLoading = false;
			if (action.payload.isSuccess) {
				state.isSuccess = "success";
			} else {
				state.isSuccess = "error";
			}
			state.message = action.payload.message;
			})
			.addCase(fetchEliminarPermiso.rejected, (state, action) => {
			state.isLoading = false;
			state.isSuccess = "error";
			state.message = action.error.message;
			})
			.addCase(fetchListaPermisosAreaReporteId.pending, (state) => {
			state.isLoading = true;
			state.isSuccess = 'idle';
			})
			.addCase(fetchListaPermisosAreaReporteId.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.permisosAll = action.payload.data;
				} 
				else {
					state.isSuccess = 'error';
				}
			})
			.addCase(fetchListaPermisosAreaReporteId.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
			})
			.addCase(fetchCountPermisosAreaReporteId.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(fetchCountPermisosAreaReporteId.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;
				if (action.payload.isSuccess) {
					state.permisosCount = action.payload.data;
				} else {
					state.isSuccess = 'error';
					state.permisosCount = 0;
				}
			})
			.addCase(fetchCountPermisosAreaReporteId.rejected, (state, action) => {
				state.isLoading = false;
				state.message = action.error.message;
				state.isSuccess = 'error';
				state.permisosCount = 0;
			})

			;
	}
});

export const selectAllPermisos = (state)=>state.permisos.permisos;
export const selectPermisos = (state)=>state.permisos.permisosAll;
export const selectPermisosCount = (state)=>state.permisos.permisosCount;
export const selectIsLoading = (state)=>state.permisos.isLoading;
export const selectIsSuccess = (state)=>state.permisos.isSuccess;
export const selectMessage = (state)=>state.permisos.message;
export const selectPermiso = (state)=> state.permisos.permiso;
export const selectAllTiposPermisosCategorias = (state)=> state.permisos.tiposPermisosCategorias;
export const selectAllTiposPermisos = (state)=> state.permisos.tiposPermisos;
export const selectEsEncargadoBool = (state)=> state.permisos.esEncargado;


export const {  } = PermisosSlice.actions;
export default PermisosSlice.reducer;