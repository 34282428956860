import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../config";
import axiosApiInstance from "../../utils/apiClient";
import { parseJWT } from "../../utils/JWTConvertToJson";
import { messageFrontEnd } from "../../utils/mensajeErrorCliente";

const initialState = {
  isSuccess: "idle",
  isLoading: false,
  message: "",
  empleadoLogueadoToken: {
    data: {
      accessToken: null,
      refreshToken: null,
    },
  },
  message: "",
  empleadoData: {},
};

// * FUNCION QUE DEVUELVE UNA PROMESA Y DURA X CANTIDAD DE TIEMPO.
const timeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const fetchLogin = createAsyncThunk(
  "login/fetchLogin",

  async (credenciales) => {

    await timeout(1000);
    const login = await axiosApiInstance.post(
      endpoints.login.authenticate,
      credenciales,
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return login.data;
  }
);

export const fetchRefreshToken = createAsyncThunk(
  "login/fetchRefreshToken",

  async(_, { getState }) => {

    try {
      const data = getState();
      const resfreshToken = await axiosApiInstance.post(endpoints.login.refreshToken, data.login.empleadoLogueadoToken.data);
      return resfreshToken.data;
    } catch (error) {
      return error.response;
    }
  }
);


export const fetchLogout = createAsyncThunk(
  "login/fetchLogout", 

async () => {

  const empleadoLogueadoToken = {
    data: {
      accessToken: null,
      refreshToken: null,
    },
    isSuccess: 'idle',
    message: '',
  };

  return empleadoLogueadoToken;
});

export const fetchLogoutSesionVencida = createAsyncThunk(
  "login/fetchLogoutSesionVencida", 

async ({mensajeCaducado = "", tipoMensaje = "idle"}) => {

  const empleadoLogueadoToken = {
    data: {
      accessToken: null,
      refreshToken: null,
    },
    isSuccess: tipoMensaje,
    message: mensajeCaducado,
  };

  return empleadoLogueadoToken;
});

export const fetchCleanMessage = createAsyncThunk(
  "login/fetchCleanMessage", 

async () => {

  return {};
});

const LoginSlice = createSlice({
  name: "login",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchLogin.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchLogin.fulfilled, (state, action) => {
        if (action.payload.isSuccess) {
          state.isSuccess = "success";
          state.message = "";
          state.isLoading = false;
          state.empleadoLogueadoToken = action.payload;

          const jsonDeserialize =  parseJWT(action.payload.data.accessToken);
          localStorage.setItem(
            "token",
            action.payload.data.accessToken.replace(/[ '"]+/g, " ")
          );

          // * GUARDANDO LOS DATOS DEL TOKEN DESERIALIZADO.
          state.empleadoData = jsonDeserialize;
        } 
        else {
          state.isSuccess = "error";
          state.message = action.payload?.message.includes("Error de Sistema: ") ? messageFrontEnd() : action.payload?.message;
          console.error(action.payload?.message)
          state.isLoading = false;
        }
      })
      .addCase(fetchLogin.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error);
        state.isLoading = false;
      })
      .addCase(fetchRefreshToken.pending, (state) => {
        state.isSuccess = "idle";
        state.message = "";
      })
      .addCase(fetchRefreshToken.fulfilled, (state, action) => {
        
        if (action.payload.isSuccess) {

          state.isSuccess = "success";
          state.message = "";
          state.isLoading = false;
          state.empleadoLogueadoToken = action.payload;

          // * GUARDANDO LOS DATOS DEL TOKEN DESERIALIZADO.
          state.empleadoData = parseJWT(action.payload.data.accessToken);
          localStorage.setItem(
            "token",
            action.payload.data.accessToken.replace(/[ '"]+/g, " ")
          );
        } 
        else 
        {
          state.isSuccess = "error";
        }
      })
      .addCase(fetchRefreshToken.rejected, (state, action) => {
        state.isSuccess = "error";
        state.message = messageFrontEnd();
        console.error(action.error);
      })
      .addCase(fetchLogout.fulfilled, (state, action) => {
        localStorage.removeItem("token");
        state.isSuccess = action.payload.isSuccess;
        state.empleadoLogueadoToken = action.payload;
        state.empleadoData = [];
      })
      .addCase(fetchLogoutSesionVencida.fulfilled, (state, action) => {
        localStorage.removeItem("token");
        state.isSuccess = action.payload.isSuccess;
        state.empleadoLogueadoToken = action.payload;
        state.message = action.payload.message;
        state.empleadoData = [];
      })
      .addCase(fetchCleanMessage.fulfilled, (state, action) => {
        state.message = '';
        state.isSuccess = "idle";
      });
  },
});

export const selectLogin = (state) => state.login.empleadoLogueadoToken;
export const selectUserData = (state) => state.login.empleadoData;
export const selectIsLoading = (state) => state.login.isLoading;
export const selectMessage = (state) => state.login.message;
export const selectIsSuccess = (state) => state.login.isSuccess;

export default LoginSlice.reducer;
