import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Grid, Paper, TextField, Hidden } from "@mui/material";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { blue } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogin, selectIsSuccess, selectIsLoading, selectMessage, fetchCleanMessage } from "../../redux/Login/LoginSlice";

import Loading from "../../Components/Modals/Loading/Loader";

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.down("xs")]: {
      width: "63vw"
    },
  },

  image: {
    [theme.breakpoints.down("md")]: {
      paddingTop: "8vh",
    },
    [theme.breakpoints.down("lg")]: {
      paddingTop: 50,
    },
  },
}));

export const Login = () => {

  // ? HOOK-USE-FORM
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      usuario: "",
      contrasena: ""
    }
  });

  // * FUNCION PARA GUARDAR DATA DESDE EL FORMULARIO.
  const onSubmit = (data) => {
    dispatch(fetchLogin(data));
  };

  // ? HOOK-NAVEGACION
  const navigate = useNavigate();
  const from = "/";

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const isSuccess = useSelector(selectIsSuccess);
  const isLoading = useSelector(selectIsLoading);
  const message = useSelector(selectMessage);

  // ? HOOK-EFFECT QUE VERIFICA LA AUTENTICACION.
  useEffect(() => {
   
    if (isSuccess === 'success') {
      navigate(from, { replace: true });
    }
    else if(isSuccess === 'error'){
      swal("Error", message, 'error');
      dispatch(fetchCleanMessage());
    }

  }, [isSuccess]);

  // * ESTILOS.
  const cardStyle = {
    height: "45vh",
  };

  const paperStyle = {
    padding: 20,
    height: "55",
    width: "70vw",
    margin: "14pc auto",
    borderRadius: 0,
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(blue[800]),
    backgroundColor: blue[900],
    "&:hover": {
      backgroundColor: blue[800],
    },
    width: '65%', 
    maxWidth: "100%"
  }));

  // * VARIABLE PARA ACCEDER A LA CLASES DE ESTILOS PERSONALIZADAS.
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      className={"animate__animated animate__fadeInDown"}
    >
      <Paper elevation={5} style={paperStyle} className={classes.card}>
        {/* <Typography className={classes.root}>Hola</Typography> */}

        <Grid container spacing={2}>
          <Hidden xsDown>
            <Grid item xs={5}>
              <Stack
                sx={{
                  alignItems: "center",
                }}
              >
                <Box
                  className={classes.image}
                  component="img"
                  sx={{
                    maxHeight: "40%",
                    maxWidth: "75%",
                    margin: "0.5vh 2vh 0 55px",
                  }}
                  src={require("../../assets/logoSireh.png")}
                />
              </Stack>
            </Grid>
          </Hidden>

          <Grid item xs={7}>
            <Grid style={cardStyle} className={classes.card}>
              <Stack
                onSubmit={handleSubmit(onSubmit)}
                component="form"
                sx={{
                  alignItems: "center",
                }}
                spacing={2}
                noValidate
                autoComplete="off"
              >
                <Avatar
                  src="/broken-image.jpg"
                  sx={{
                    width: "7.5vw",
                    height: "7.5vw",
                    marginTop: 5,
                    marginBottom: 2,
                  }}
                />

                <TextField
                  label="Usuario"
                  variant="outlined"
                  sx={{maxWidth: "100%", width: '65%'}}
                  name="usuario"
                  id="usuario"
                  error={errors?.usuario}
                  helperText={errors?.usuario?.message}
                  {...register("usuario", 
                  {
                    required: "El usuario es requerido",
                    pattern: {
                      value: /^\S*$/, // Expresión regular para no permitir espacios en blanco
                      message: 'El usuario no puede contener espacios en blanco',
                    },
                  
                  })} 
                />
                <TextField

                  label="Contraseña"
                  variant="outlined"
                  sx={{maxWidth: "100%", width: '65%'}}
                  name="contrasena"
                  id="contrasena"
                  type="password"
                  error={errors?.contrasena}
                  helperText={errors?.contrasena?.message}
                  {...register("contrasena", 
                  {
                    required: "La contraseña es requerida"
                  })} 
                />

                <ColorButton
                  id="btnLogin"
                  variant="contained"
                  type="submit"
                >
                  INICIAR SESIÓN
                </ColorButton>

              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

       {/* PANTALLA DE CARGA */}
       <Loading open={isLoading} />

    </Grid>
  );
};
