import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Encabezado } from '../../../Components/Encabezado/Encabezado';
import { Grid, Paper, Stack, Typography, Divider, Box, TextField } from '@mui/material';
import FormControlLabel from "@mui/material/FormControlLabel";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import Loading from "../../../Components/Modals/Loading/Loader";
import { BiSave } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import { Controller, useWatch } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import swal from "sweetalert";
import MaskedInput from "react-input-mask";
import CurrencyInput from "../../../Components/InputSalario/CurrencyInput";

import { 
  fetchAllEmpleados,
  fetchEmpleado,
  selectAllEmpleadosList, 
  selectEmpleado
} from '../../../redux/Empleados/EmpleadosSlice';

import {
  fetchEstadosCiviles,
  fetchNacionalidades,
  fetchProgramas,
  selectAllEstadosCiviles,
  selectAllPogramas,
  fetchTipoEmpleados,
  selectAllTiposEmpleados,
  selectAllNacionalidades
} from "../../../redux/Utility/UtilitySlice";

import {
  fetchHorarios,
  selectAllHorarios,
} from "../../../redux/Horarios/HorarioSlice";

import {
  fetchAreasOrganizacionales,
  selectAllAreasOrganizacionales,
} from "../../../redux/AreaOrganizacional/AreaOrganizacionalSlice";

import {
  fetchCargosLista,
  selectAllCargosLista,
} from "../../../redux/Cargos/CargosSlice";

import {
  fetchGetTipoAccionesAll,
  selectListTipoAcciones,
} from "../../../redux/TipoAcciones/TipoAccionesSlice";

import {
  selectIsSuccess,
  selectMessage,
  selectIsLoading,
  fetchPostAccionPersonal,
  fetchCambiarSuccess
} from "../../../redux/Acciones/AccionesSlice";

import { selectUserData } from "../../../redux/Login/LoginSlice";

import { verificarNaturalezaSeleccionada } from "../../../utils/verificarNaturalezaSeleccionada";

export const AccionesPersonalAgregar = () => {

  // * FECHA ACTUAL 
  const fechaActual = new Date();

  // ? HOOK-NAVIGATE
  const navigate = useNavigate();

  // ? HOOK-STATE
  const [listaEmpleadosState, setListaEmpleadosState] = useState([]);

  // ? HOOK-FORM
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    setValue
  } = useForm({
    defaultValues: {
      empleadoId: 0,
      empleado: {        
        apellidos: '',
        areaOrganizacionalId: 0,
        areaReporteId: 0,
        cargoId: 0,
        cedula: '',
        celular: '',
        direccion: '',
        emailInstitucional: '',
        emailPersonal: '',
        estadoCivilId: 0,
        fechaIngreso: new Date(),
        fechaNacimiento: new Date(),
        flota: '',
        horarioId: 0,
        nombres: '',
        programaId: 0,
        sexo: 0,
        sueldo: 0,
        telefono: 0,
        tipoEmpleadoId: 0,
        nacionalidadId: 0
      },
      detallesAccion: '',
      descripcionRRHH: '',
      listaTipoAccion: [],
      fechaEfectividad: new Date(`${fechaActual.getFullYear()}-${fechaActual.getMonth() + 1}-1`),
      generarComentarios: true
    }
  });

  // ? HOOK-FIELD-ARRAY
  const { fields } = useFieldArray({
    control,
    name: "listaTipoAccion",
    rules: {},
  });

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-WATCH
  const empleadoId = useWatch({ control, name: 'empleadoId'});
  const listaTipoAccion = useWatch({ control, name: 'listaTipoAccion'});

  // ? HOOK-SELECTOR
  const listEmpleados = useSelector(selectAllEmpleadosList);
  const estadosCivil = useSelector(selectAllEstadosCiviles);
  const horarios = useSelector(selectAllHorarios);
  const programas = useSelector(selectAllPogramas);
  const tipoEmpleados = useSelector(selectAllTiposEmpleados);
  const areaOrganizacionales = useSelector(selectAllAreasOrganizacionales);
  const cargos = useSelector(selectAllCargosLista);
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  const tipoAcciones = useSelector(selectListTipoAcciones);
  const empleadoOld = useSelector(selectEmpleado);
  const usuario = useSelector(selectUserData);
  const nacionalidades = useSelector(selectAllNacionalidades);
  

  // ? HOOK-EFFECT
  useEffect(() => {
    dispatch(fetchEstadosCiviles());
    dispatch(fetchHorarios());
    dispatch(fetchProgramas());
    dispatch(fetchTipoEmpleados());
    dispatch(fetchAreasOrganizacionales());
    dispatch(fetchCargosLista());
    dispatch(fetchGetTipoAccionesAll());
    dispatch(fetchAllEmpleados());
    dispatch(fetchNacionalidades());
  }, [])

  useEffect(() => {
    setListaEmpleadosState([
      { id: 0, nombreLabel: "Seleccionar Empleado" },
      ...listEmpleados,
    ]);
  }, [listEmpleados])
  

  useEffect(() => {
    dispatch(fetchEmpleado(empleadoId));
  }, [empleadoId]);

  useEffect(() => {

    const empleadoSeleccionado = {
      apellidos: empleadoOld?.apellidos,
      areaOrganizacionalId: empleadoOld?.areaOrganizacionalId,
      areaReporteId: empleadoOld?.areaReporteId,
      cargoId: empleadoOld?.cargoId,
      cedula: empleadoOld?.cedula,
      celular: empleadoOld?.celular,
      direccion: empleadoOld?.direccion,
      emailInstitucional: empleadoOld?.emailInstitucional,
      emailPersonal: empleadoOld?.emailPersonal,
      estadoCivilId: empleadoOld?.estadoCivilId,
      fechaIngreso: empleadoOld?.fechaIngreso,
      fechaNacimiento: empleadoOld?.fechaNacimiento,
      flota: empleadoOld?.flota,
      horarioId: empleadoOld?.horarioId,
      nombres: empleadoOld?.nombres,
      programaId: empleadoOld?.programaId,
      sexo: empleadoOld?.sexo,
      sueldo: empleadoOld?.sueldo,
      telefono: empleadoOld?.telefono,
      tipoEmpleadoId: empleadoOld?.tipoEmpleadoId,
      nacionalidadId: empleadoOld?.nacionalidadId
    }

    setValue('empleado', empleadoSeleccionado);

  }, [empleadoOld])
  
  useEffect(() => {
    if (success === "success" && message?.length > 0) {
      reset();
      dispatch(fetchCambiarSuccess());
      swal("Exitoso!", message, "success");
    } else if (success === "error") {
      swal("Error!", message, "error");
    }
  }, [success]);
  
  const onSubmit = () => {

    const { empleado, detallesAccion, descripcionRRHH, fechaEfectividad, generarComentarios } = watch();

    // * ASIGNANDO VALORES.
    const data = {
      empleadoId,
      usuarioLogeado: usuario.Id,
      detallesAccion,
      descripcionRRHH,
      listaTipoAccion: listaTipoAccion.flat(),
      fechaEfectividad,
      generarComentarios,
      areaOrganizacionalIdNew: empleado.areaOrganizacionalId,
      areaReporteIdNew: empleado.areaReporteId,
      cargoIdNew: empleado.cargoId,
      sueldoNew: 0
    };

    data.sueldoNew = typeof empleado.sueldo === "number" ? empleado.sueldo : parseInt(empleado.sueldo.replace('$', "").split(",").join("")) ?? 0;

    // * GUARDANDO LA DATA
    dispatch(fetchPostAccionPersonal(data));
  }

  return (
    <Encabezado title='Acciones de Personal' container={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper elevation={5}>
          <Stack p={3}>

            <Typography fontWeight='bold'>Empleado</Typography>

            <Controller
            control={control}
            name="empleadoId"
            {...register("empleadoId", {
              required: "El empleado es requerido",
            })}
            render={({ field }) => (
              <Select
                id='select-empleado'
                classNamePrefix="select"
                isSearchable={true}
                placeholder="Seleccionar Empleado"
                options={listaEmpleadosState}
                getOptionLabel={(option) => option.nombreLabel}
                getOptionValue={(option) => option.id}
                onChange={(e) => field.onChange(e.id)}
                value={listaEmpleadosState.find(x => x.id === field.value)}
              />
            )}
            />

            <p
              style={{ marginLeft: 10, marginBottom: -2 }}
              className="text-danger"
            >
              {errors.empleadoId?.message}
            </p>


        <div className="row mt-4">
          <div>
            <h5 style={{marginBottom: -10}}>DATOS DEL EMPLEADO</h5>
            <hr />
          </div>
        </div>

        <Paper elevation={4}>
          <div className="d-flex flex-row p-3">
            <div className="col-md-4 pe-4 border-end">
              <div className="form-group">
                <label className="fw-bold">Nombres</label>
                <input
                  id='empleado.nombres'
                  disabled
                  className="form-control"
                  {...register("empleado.nombres", {
                    required: "El nombre es requerido",
                  })}
                />
                <p className="text-danger">
                  {errors.empleado?.nombres?.message}
                </p>
              </div>
              <div className="form-group">
                <label className="fw-bold">Apelidos</label>
                <input
                  id='empleado.apellidos'
                  disabled
                  className="form-control"
                  {...register("empleado.apellidos", {
                    required: "El apellido es requerido",
                  })}
                />
                <p className="text-danger">
                  {errors.empleado?.apellidos?.message}
                </p>
              </div>

              <div className="form-group mb-3">
                <label className="fw-bold">Cédula</label>

                <Controller
                name="empleado.cedula"
                control={control}
                render={({ field }) => (
                <MaskedInput
                    id='empleado.cedula'
                    disabled
                    className="form-control"
                    mask="999-9999999-9"
                    maskChar=""
                    value={field.value}
                    onChange={field.onChange}
                    />
                )}
                />

                {errors.empleado?.cedula ? (
                  <div className="text-danger">{errors.cedula?.message}</div>
                ) : null}
              
              </div>

              <div className="form-group">
                <label className="fw-bold">Fecha Nacimiento</label>
                <Controller
                  control={control}
                  name="ReactDatepicker"
                  {...register("empleado.fechaNacimiento", {
                    required: "La fecha de nacimiento es requerida",
                  })}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        InputProps={{id: 'empleado.fechaNacimiento'}}
                        inputFormat='dd/MM/yyyy'
                        disabled
                        label={" "}
                        mask="__/__/____"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        renderInput={(params) => (
                          <TextField className="form-control" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />

                <p className="text-danger mt-3">
                  {errors.empleado?.fechaNacimiento?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Sexo</label>
                <select
                  id='empleado.sexo'
                  disabled
                  className="form-control"
                  {...register("empleado.sexo", {
                    required: "El sexo es requerido",
                  })}
                >
                  <option value={""}>--Seleccionar Sexo--</option>
                  <option value={"Masculino"}>Masculino</option>
                  <option value={"Femenino"}>Femenino</option>
                </select>
                <p className="text-danger">{errors.empleado?.sexo?.message}</p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Estado Civil</label>
                <select
                  id='empleado.estadoCivilId'
                  disabled
                  className="form-control"
                  {...register("empleado.estadoCivilId", {
                    required: "El estado civil es requerido",
                  })}
                >
                  <option value={""}>--Seleccionar Estado Civil--</option>
                  {estadosCivil?.map((estadoCivil) => (
                    <option key={estadoCivil.id} value={estadoCivil.id}>
                      {estadoCivil.nombre}
                    </option>
                  ))}
                </select>
                <p className="text-danger">
                  {errors.empleado?.estadoCivilId?.message}
                </p>
              </div>

              <div className="form-group mb-3">
                <label className="fw-bold">Nacionalidad</label>
                <Controller
                control={control}
                name="empleado.nacionalidadId"
                render={({ field }) => (
                  <Select
                    id='empleado.nacionalidadId'
                    isDisabled
                    classNamePrefix="select"
                    isSearchable={true}
                    placeholder="Seleccionar Nacionalidad"
                    options={nacionalidades}
                    getOptionLabel={(option) => option.nombre}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => field.onChange(e.id)}
                    value={nacionalidades.find(x => x.id === field.value)}
                  />
                )}
                />
              </div>

              <div className="form-group">
                <label className="fw-bold">Dirección</label>
                <textarea
                  id='empleado.direccion'
                  disabled
                  className="form-control"
                  {...register("empleado.direccion")}
                />
                <p className="text-danger"></p>
              </div>
            </div>

            <div className="col-md-4 ps-4 pe-4 border-end">
              <div className="form-group">
                <label className="fw-bold">Correo Personal</label>
                <input
                  id='empleado.emailPersonal'
                  disabled
                  className="form-control"
                  {...register("empleado.emailPersonal", {
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "El formato de correo no es valido",
                    },
                  })}
                />
                <p className="text-danger">
                  {errors.empleado?.emailPersonal?.message}
                </p>
              </div>

              <div className="form-group mb-3">
                <label className="fw-bold">Celular</label>
                <Controller
                control={control}
                name="empleado.celular"
                render={({ field }) => (
                  <MaskedInput
                    id='empleado.celular'
                    className='form-control'
                    disabled
                    mask="(999) 999-9999"
                    maskChar=""
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
                />

              </div>

              <div className="form-group mb-3">
                <label className="fw-bold">Teléfono</label>
                <Controller
                control={control}
                name="empleado.telefono"
                render={({ field }) => (
                  <MaskedInput
                    id='empleado.telefono'
                    className='form-control'
                    disabled
                    mask="(999) 999-9999"
                    maskChar=""
                    value={field.value}
                    onChange={field.onChange}
                  />
                  
                )}
                />

              </div>

              <div className="form-group">
                <label className="fw-bold">Fecha Ingreso</label>
                <Controller
                  control={control}
                  name="ReactDatepicker"
                  {...register("empleado.fechaIngreso", {
                    required: "La fecha de ingreso es requerida",
                  })}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        InputProps={{id: 'empleado.fechaIngreso'}}
                        inputFormat='dd/MM/yyyy'
                        disabled
                        label={" "}
                        mask="__/__/____"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        renderInput={(params) => (
                          <TextField className="form-control" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />

                <p className="text-danger mt-3">
                  {errors.empleado?.fechaIngreso?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Tipo Empleado</label>
                <select
                  id='empleado.tipoEmpleadoId'
                  disabled
                  className="form-control"
                  {...register("empleado.tipoEmpleadoId", {
                    required: "El tipo de empleado es requerido",
                  })}
                >
                  <option value={""}>Seleccionar Tipo Empleado</option>
                  {tipoEmpleados.map((tipoEmpleado) => (
                    <option key={tipoEmpleado.id} value={tipoEmpleado.id}>
                      {tipoEmpleado.tipo}
                    </option>
                  ))}
                </select>
                <p className="text-danger">
                  {errors.empleado?.tipoEmpleadoId?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Programa</label>
                <Controller
                    control={control}
                    name="empleado.programaId"
                    {...register("empleado.programaId", {
                      required: "El programa es requerido",
                    })}
                    render={({ field }) => (
                      <Select
                        id='empleado.programaId'
                        isDisabled
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Programa"
                        options={programas}
                        getOptionLabel={(option) => option.programa}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        value={programas.find(x => x.id === field.value)}
                      />
                    )}
                />
                <p className="text-danger">
                  {errors.empleado?.programaId?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Horario</label>
                <select
                  id='empleado.horarioId'
                  disabled
                  className="form-control"
                  {...register("empleado.horarioId", {
                    required: "El horario es requerido",
                  })}
                >
                  <option value={""}>Seleccionar Horario</option>
                  {horarios.map((horario) => (
                    <option key={horario.id} value={horario.id}>
                      {horario.turnos[0]?.horarioLabel}
                    </option>
                  ))}
                </select>
                <p className="text-danger">
                  {errors.empleado?.horarioId?.message}
                </p>
              </div>
            </div>

            <div className="col-md-4 ps-4 pe-4">
              <div className="form-group">
                <label className="fw-bold">Sueldo</label>

                <Controller
                name="empleado.sueldo"
                control={control}
                defaultValue=""
                rules={{
                  required: "El sueldo es requerido"
                }}
                render={({ field }) => (
                  <CurrencyInput
                    id='empleado.sueldo'
                    disabled={!verificarNaturalezaSeleccionada([5, 7, 9, 11], listaTipoAccion)}
                    className="form-control"
                    placeholder="$0.00" type="text"
                    value={field.value}
                    onChange={field.onChange}
                  />
                  
                )}
                />
               
                <p className="text-danger">
                  {errors.empleado?.sueldo?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Correo Institucional</label>
                <input
                  id='empleado.emailInstitucional'
                  disabled
                  className="form-control"
                  {...register("empleado.emailInstitucional", {
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "El formato de correo no es valido",
                    },
                  })}
                />
                <p className="text-danger">
                  {errors.empleado?.emailInstitucional?.message}
                </p>
              </div>

              <div className="form-group mb-3">
                <label className="fw-bold">Flota</label>
                <Controller
                control={control}
                name="empleado.flota"
                render={({ field }) => (
                  <MaskedInput
                    id='empleado.flota'
                    className='form-control'
                    disabled
                    mask="(999) 999-9999"
                    maskChar=""
                    value={field.value}
                    onChange={field.onChange}
                  />
                  
                )}
                />

              </div>

              <div className="form-group">

                <label className="fw-bold">Área Organizacional</label>
                <Controller
                    control={control}
                    name="empleado.areaOrganizacionalId"
                    {...register("empleado.areaOrganizacionalId", {
                      required: "El área organizacional es requerida",
                    })}
                    render={({ field }) => (
                      <Select
                        id='empleado.areaOrganizacionalId'
                        isDisabled={!verificarNaturalezaSeleccionada([5, 7, 8, 10, 12], listaTipoAccion)}
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Área Organizacional"
                        options={areaOrganizacionales}
                        getOptionLabel={(option) => option.nombre}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        value={areaOrganizacionales.find(x => x.id === field.value)}
                      />
                    )}
                />

                <p className="text-danger">
                    {errors.empleado?.areaOrganizacionalId?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Cargo</label>
                
                    <Controller
                    control={control}
                    name="empleado.cargoId"
                    {...register("empleado.cargoId", {
                      required: "El Cargo es requerido",
                    })}
                    render={({ field }) => (
                      <Select
                        id='empleado.cargoId'
                        isDisabled={!verificarNaturalezaSeleccionada([5, 10, 12], listaTipoAccion)}
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Cargo"
                        options={cargos}
                        getOptionLabel={(option) => option.titulo}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        value={cargos.find(x => x.id === field.value)}
                      />
                    )}
                    />


                <p className="text-danger">
                    {errors.empleado?.cargoId?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Área de Reporte</label>

                <Controller
                    control={control}
                    name="empleado.areaReporteId"
                    {...register("empleado.areaReporteId", {
                      required: "El área de reporte es requerida",
                    })}
                    render={({ field }) => (
                      <Select
                        id='empleado.areaReporteId'
                        isDisabled={!verificarNaturalezaSeleccionada([5, 7, 8, 10, 12], listaTipoAccion)}
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder="Seleccionar Área Reporte"
                        options={areaOrganizacionales}
                        getOptionLabel={(option) => option.nombre}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => field.onChange(e.id)}
                        value={areaOrganizacionales.find(x => x.id === field.value)}
                      />
                    )}
                />

                <p className="text-danger">
                    {errors.empleado?.areaReporteId?.message}
                </p>
              </div>

              <div className="form-group">
                <label className="fw-bold">Fecha de Efectividad</label>
                <Controller
                  control={control}
                  name="ReactDatepicker"
                  {...register("fechaEfectividad", {
                    required: "La fecha de efectividad es requerida",
                  })}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        InputProps={{id: 'fechaEfectividad'}}
                        inputFormat='dd/MM/yyyy'
                        label={" "}
                        mask="__/__/____"
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        renderInput={(params) => (
                          <TextField className="form-control" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />

                <p className="text-danger mt-3">
                  {errors.fechaEfectividad?.message}
                </p>
              </div>
            </div>
          </div>
        </Paper>
            
          </Stack>
        </Paper>

        <Paper elevation={5}>
          <Stack mt={2} p={3}>
            <Typography textTransform="uppercase">
              Naturaleza de la acción
            </Typography>
            <Divider />

            <Grid container spacing={{ lg: 2, md: 1 }}>
              {tipoAcciones.length > 0 &&
                fields !== undefined &&
                tipoAcciones.map((item, index) => (
                  <Grid item p={2} xs={4} md={4} lg={2.4} key={item.id} mt={2}>
                    <Stack>
                      <Typography
                        key={index}
                        sx={{ fontSize: { lg: 14, md: 12, xs: 11 } }}
                        fontWeight="bold"
                        textTransform="uppercase"
                      >
                        {item.nombre}
                      </Typography>

                      {item.tiposAcciones.map((item2, index2) => (
                        <FormControlLabel
                          key={item2.id}
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                              id={`tipoaccion.${item2.id}`}
                              checked={getValues("listaTipoAccion")
                                ?.flat()
                                .some((x) => x === item2.id.toString())}
                              {...register(`listaTipoAccion.${index2}`, {
                                value: [],
                              })}
                              value={item2?.id}
                            />
                          }
                          label={item2?.nombre}
                        />
                      ))}
                    </Stack>
                  </Grid>
                ))}

              <p
                style={{ marginLeft: 10, marginBottom: -2 }}
                className="text-danger"
              >
                {(watch("listaTipoAccion")?.flat()?.length === 0 && tipoAcciones.length > 0) &&
                  "Debe seleccionar al menos una naturaleza"}
              </p>
            </Grid>
          </Stack>
        </Paper>

        <Paper elevation={5}>
          <Stack mt={2} p={3}>
            <Typography fontSize={14} textTransform="uppercase">
              Detalles explicativos de la acción
            </Typography>
            <TextField
              id='detallesAccion'
              {...register("detallesAccion")}
              label=" "
              variant="outlined"
              multiline
              rows={5}
              error={errors.detallesAccion}
              helperText={errors.detallesAccion?.message}
            />

            <div className='mb-3'>
              <FormControlLabel
              sx={{
                "& .MuiTypography-root": {
                    fontSize: { lg: 14, md: 12, xs: 11 },
                },
              }}
              control={
                <Checkbox
                value={getValues(`generarComentarios`)}
                checked={watch(`generarComentarios`)}
                onChange={(e) => {
                    const isChecked = e.target.checked;
                    setValue(`generarComentarios`, isChecked);
                }}
                />
              }
              label={"Generar comentarios sobre la acción mediante el sistema."}
              />
            </div>  

            <Typography fontSize={14} textTransform="uppercase">
              Para uso de área de recursos humanos
            </Typography>
            <TextField
              id='descripcionRRHH'
              {...register("descripcionRRHH")}
              label=" "
              variant="outlined"
              multiline
              rows={5}
              error={errors.descripcionRRHH}
              helperText={errors.descripcionRRHH?.message}
            />

            <Box mt={2} display="flex" justifyContent="center">
              <button id='btn-guardar' className="btn btn-primary-mem me-2">
                Guardar <BiSave fontSize={22} />
              </button>
              <button id='btn-cancelar' className="btn btn-danger" onClick={() => navigate('/acciones/personal')}>
                Cancelar <MdCancel fontSize={22} />
              </button>
            </Box>
          </Stack>
        </Paper>
      </form>

      <Loading open={isLoading} />

    </Encabezado>
  )
}
