import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Add from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};

const CargoNuevo = ({ open, setOpen, gruposOcupacionales, handleAddCargo }) => {

    const { 
		register, 
		handleSubmit, 
		formState: { errors },
		setValue
	} = useForm();

    const onSubmit = cargo => handleAddCargo(cargo);
	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
	  setValue('titulo', '');
	  setValue('grupoOcupacionalId', '')
	}, [open])

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
		>
			<Box sx={{ ...style, width: 700 }}>
			<form onSubmit={handleSubmit(onSubmit)}>
            	<div className="container">
					<div className="row">
						<h3 className="text-primary-mem text-center">Nuevo Cargo</h3>
					</div>
					<div className="row">
						<div className="col-5 me-0 pe-0">
							<hr />
						</div>
						<div className="col-2 text-center">
							<Add className="text-primary-mem" />
						</div>
						<div className="col-5 ms-0 ps-0 ">
							<hr />
						</div>
					</div>
                    <div className='row'>
                        <div className='form-group'>
                            <label className='fw-bold'>Título</label>
                            <textarea {...register("titulo",{required:"El titulo es requerido"})} type="text" className='form-control'></textarea>
                            <p className='text-danger'>{errors.titulo?.message}</p>
                        </div>
                        <div className='form-group'>
                            <label className='fw-bold'>Grupo Ocupacional</label>
                            <select {...register("grupoOcupacionalId",{required:"El grupo ocupacional es requerido"})} className='form-control'>
                                <option value="">Seleccionar grupo ocupacional</option>
                                {
                                   gruposOcupacionales && gruposOcupacionales.length>0&&  gruposOcupacionales.map((grupo)=><option key={grupo.id} value={grupo.id}>{grupo.nombre}</option>)
                                }
                            </select>
                            <p className='text-danger'>{errors.grupoOcupacionalId?.message}</p>
                        </div>

                        <div className='d-flex justify-content-center'>
							<button type='submit' className='btn btn-primary-mem me-3'> <SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}}/> Guardar</button>
							<button type='button' className='btn btn-danger ' onClick={handleClose}> <DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}} /> Cancelar</button>
                        </div>
						
                    </div>
				</div>
                </form>
			</Box>
		</Modal>
	);
};

export default CargoNuevo;
