import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "../config";

const initialState = {
    isLoading: false,
    message: "",
    isSuccess: 'idle',
    gruposOcupacionales: [],
}

export const fetchGruposOcupacionalesLista = createAsyncThunk("gruposOcupacionales/fetchGruposOcupacionalesLista",async ()=>{
        let resultado = await axiosApiInstance.get(endpoints.gruposOcupacionales.listaGrupoOcupacionales);
        return resultado.data;
});


const GruposOcupacionalesSlice = createSlice({
    name:"gruposOcupacionales",
    initialState,
    extraReducers(builder){
        builder.addCase(fetchGruposOcupacionalesLista.pending,(state,action)=>{
            state.isLoading = true;
            state.message = "";
            state.isSuccess= 'idle';
        })
        .addCase(fetchGruposOcupacionalesLista.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.message = action.payload.message;
            
            if(action.payload.isSuccess){
                state.gruposOcupacionales = action.payload.data;
                state.isSuccess = 'success';
            }else{
                state.isSuccess = 'error';
                state.gruposOcupacionales = [];
            }
        })
        .addCase(fetchGruposOcupacionalesLista.rejected,(state,action)=>{
            state.isLoading = false;
            state.message = action.error.message;
            state.isSuccess = 'error' 
            state.gruposOcupacionales = [];
        });     
    }
})

export const selectAllGruposOcupacionales = (state)=> state.gruposOcupacionales.gruposOcupacionales;
export const selectIsSuccess = state=> state.gruposOcupacionales.isSuccess;
export const selectIsloading = state=> state.gruposOcupacionales.isLoading;
export const selectMessage = state=> state.gruposOcupacionales.message;

export const {} = GruposOcupacionalesSlice.actions;
export default GruposOcupacionalesSlice.reducer;