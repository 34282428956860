import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router';
import { Encabezado } from '../../../Components/Encabezado/Encabezado';
import FormControlLabel from "@mui/material/FormControlLabel";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
import Loading from "../../../Components/Modals/Loading/Loader";
import { BiSave } from "react-icons/bi";
import { MdModeEdit } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import MaskedInput from "react-input-mask";
import CurrencyInput from "../../../Components/InputSalario/CurrencyInput";
import swal from 'sweetalert';

import {
   Grid, 
   Paper, 
   Stack, 
   Typography, 
   Divider, 
   TextField,
   IconButton,
   Box
} from '@mui/material';

import {
   fetchAllEmpleados,
   selectAllEmpleadosList,
   selectEmpleado
} from '../../../redux/Empleados/EmpleadosSlice';

import {
   fetchEstadosCiviles,
   fetchProgramas,
   selectAllEstadosCiviles,
   selectAllPogramas,
   fetchTipoEmpleados,
   fetchNacionalidades,
   selectAllTiposEmpleados,
   selectAllNacionalidades
} from "../../../redux/Utility/UtilitySlice";

import {
   fetchHorarios,
   selectAllHorarios,
} from "../../../redux/Horarios/HorarioSlice";

import {
   fetchAreasOrganizacionales,
   selectAllAreasOrganizacionales,
} from "../../../redux/AreaOrganizacional/AreaOrganizacionalSlice";

import {
   fetchCargosLista,
   selectAllCargosLista,
} from "../../../redux/Cargos/CargosSlice";

import {
   fetchGetTipoAccionesAll,
   selectListTipoAcciones,
} from "../../../redux/TipoAcciones/TipoAccionesSlice";

import {
   selectIsSuccess,
   selectAccion,
   selectIsLoading,
   selectMessage,
   fetchGetAccionById,
   fetchCambiarSuccess,
   fetchUpdateAccion,
} from "../../../redux/Acciones/AccionesSlice";

import { selectUserData } from "../../../redux/Login/LoginSlice";

import { verificarNaturalezaSeleccionada } from "../../../utils/verificarNaturalezaSeleccionada";

import { PermissionData } from "../../../context/Component/PermissionData";

import { colors } from '../../../utils/colors';

export const AccionesPersonalDetalle = () => {

   // * PERMISOS DEL USUARIO.
   const permisosState = PermissionData();

   // ? ACCION-ID DEL QUERY-PARAMETER
   const { accionId = 0 } = useParams();

   // ? HOOK-NAVEGACION
   const navigate = useNavigate();

   // ? HOOK-STATE
   const [listaEmpleadosState, setListaEmpleadosState] = useState([]);
   const [listTipoAccionInt, setListTipoAccionInt] = useState([]);

   // ? HOOK-STATE PARA VALIDACION DE BOTON HABILITADO.
   const [editEnabled, setEditEnabled] = useState(false);

   // ? HOOK-FORM
   const {
      register,
      formState: { errors },
      control,
      watch,
      getValues,
      handleSubmit,
      setValue
   } = useForm({
      defaultValues: {
         accion: {
            accionId: 0,
            detallesAccion: '',
            descripcionRRHH: '',
            listaTipoAccion: [],
            fechaEfectividad: new Date(),
            empleadoId: 0,
            generarComentarios: true
          },
          empleado: {
            nombres: '',
            apellidos: '',
            cedula: '',
            fechaNacimiento: new Date(),
            sexo: '',
            estadoCivilId: 0,
            direccion: '',
            emailPersonal: '',
            celular: '',
            telefono: '',
            fechaIngreso: new Date(),
            tipoEmpleadoId: 0,
            programaId: 0,
            horarioId: 0,
            sueldo: '',
            emailInstitucional: '',
            areaOrganizacionalId: 0,
            cargoId: 0,
            areaReporteId: 0,
            foto: ''
          },
         listaTipoAccion: []
      }
   });

   // ? HOOK-FIELD-ARRAY
   const { fields, replace } = useFieldArray({
      control,
      name: "listaTipoAccion",
      rules: {},
   });

   // ? HOOK-WATCH
   const empleadoAccion = useWatch({ control, name: 'empleado' });
   const listaTipoAccion = useWatch({ control, name: 'listaTipoAccion'});

   // * REFRESCAR ACCION
   const refreshAccion = () => dispatch(fetchGetAccionById(accionId));

   // ? HOOK-DISPATCH
   const dispatch = useDispatch();

   // ? HOOK-SELECTOR
   const listEmpleados = useSelector(selectAllEmpleadosList);
   const estadosCivil = useSelector(selectAllEstadosCiviles);
   const horarios = useSelector(selectAllHorarios);
   const programas = useSelector(selectAllPogramas);
   const tipoEmpleados = useSelector(selectAllTiposEmpleados);
   const areaOrganizacionales = useSelector(selectAllAreasOrganizacionales);
   const cargos = useSelector(selectAllCargosLista);
   const isLoading = useSelector(selectIsLoading);
   const success = useSelector(selectIsSuccess);
   const naturalezaTipoAcciones = useSelector(selectListTipoAcciones);
   const empleadoOld = useSelector(selectEmpleado);
   const accion = useSelector(selectAccion);
   const nacionalidades = useSelector(selectAllNacionalidades);
   const usuario = useSelector(selectUserData);
   const message = useSelector(selectMessage);

   // ? HOOK-EFFECT
   useEffect(() => {
         dispatch(fetchEstadosCiviles());
         dispatch(fetchHorarios());
         dispatch(fetchProgramas());
         dispatch(fetchTipoEmpleados());
         dispatch(fetchAreasOrganizacionales());
         dispatch(fetchCargosLista());
         dispatch(fetchGetTipoAccionesAll());
         dispatch(fetchAllEmpleados());
         dispatch(fetchNacionalidades());
   }, [])

   useEffect(() => {
      setListaEmpleadosState([
         { id: 0, nombreLabel: "Seleccionar Empleado" },
         ...listEmpleados,
      ]);
   }, [listEmpleados])

   // ? HOOK-EFFECT PARA VERIFICAR SI SE BUSCARA UNA ACCION
   useEffect(() => {
      if (accionId > 0) {
         refreshAccion();
      }
   }, [accionId])

   // ? HOOK-EFFECT PARA VALIDAR QUE LA ACCION FUE ENCONTRADA PARA
   // ? POSTERIORMENTE CARGAR LA INFORMACION EN EL FORMULARIO.
   useEffect(() => {

      if (Object.keys(accion).length > 0 && success === 'success' && !isLoading) {

         // * APLICANDO LA DATA DEL EMPLEADO AL VALOR DEL HOOK-FORM
         const empleadoUpdated = { ...empleadoAccion, ...accion.empleado };

         // * ASIGNACION DE VALORES.
         setValue('empleado', empleadoUpdated);
         setValue('accion', accion);

         // * SACAR LOS ID DE LOS TIPOS DE ACCIONES.
         const naturalezaAccion = accion.accionTipoAccion.map(x => x.tipoAccionId.toString());
         
         // * VARIABLE PARA LAS MATRICES
         let arr = [];
         let matriz = [];

         for (let index = 0; index < naturalezaTipoAcciones.length; index++) {
            
            matriz = [];
            const { tiposAcciones } = naturalezaTipoAcciones[index];

            for (let x = 0; x < tiposAcciones.length; x++) {

               const id = tiposAcciones[x].id;

               const element = {
                  id,
                  seleccionado: naturalezaAccion.some(x => x.toString() === id?.toString())
               };
               
               matriz.push(element);
            }

            arr.push(matriz);
         }
         
         replace(arr)
      }

      if (Object.keys(accion).length === 0 && success === 'error') {
         navigate('/acciones/personal')
      }

   }, [accion, isLoading])


   useEffect(() => {

      const empleadoSeleccionado = {
         apellidos: empleadoOld?.apellidos,
         areaOrganizacionalId: empleadoOld?.areaOrganizacionalId,
         areaReporteId: empleadoOld?.areaReporteId,
         cargoId: empleadoOld?.cargoId,
         cedula: empleadoOld?.cedula,
         celular: empleadoOld?.celular,
         direccion: empleadoOld?.direccion,
         emailInstitucional: empleadoOld?.emailInstitucional,
         emailPersonal: empleadoOld?.emailPersonal,
         estadoCivilId: empleadoOld?.estadoCivilId,
         fechaIngreso: empleadoOld?.fechaIngreso,
         fechaNacimiento: empleadoOld?.fechaNacimiento,
         flota: empleadoOld?.flota,
         horarioId: empleadoOld?.horarioId,
         nombres: empleadoOld?.nombres,
         programaId: empleadoOld?.programaId,
         sexo: empleadoOld?.sexo,
         sueldo: empleadoOld?.sueldo,
         telefono: empleadoOld?.telefono,
         tipoEmpleadoId: empleadoOld?.tipoEmpleadoId
      }

      setValue('empleado', empleadoSeleccionado);

   }, [empleadoOld])

   // * FUNCION PARA ACTUALIZAR LA ACCION DE PERSONAL.
   const onSubmit = (data, e) => {

      e.preventDefault();

      if (listTipoAccionInt.length === 0) {
         swal("Información", "Debe seleccionar al menos una naturaleza.", "error");
         return;
      }

      const { accion, empleado } = data;

      const dataToApi = {
         accion,
         empleado,
         listaTipoAccion: listTipoAccionInt,
         usuarioIdModificado: usuario?.Id
      };

      dataToApi.empleado.sueldo = typeof empleado.sueldo === "number" ? empleado.sueldo : parseInt(empleado.sueldo?.replace('$', "")?.split(",")?.join("")) ?? 0;

      // * GUARDANDO LA DATA
      dispatch(fetchUpdateAccion(dataToApi));
   }

   useEffect(() => {
      setListTipoAccionInt(listaTipoAccion?.flat()?.filter(x => x.seleccionado).map(item => item.id))
   }, [listaTipoAccion])

   // ? HOOK-EFFECT PARA ALERTAS.
   useEffect(() => {
		
		if(success === 'success' && message.length > 0){
			dispatch(fetchCambiarSuccess());
			swal("Exitoso!", message, "success");

      // * REFRESCAR LA ACCION
      refreshAccion();
      setEditEnabled(false);
		}
		else if(success ==='error'){
			swal("Error!", message, "error");
		}

	},[success])

   // * VARIABLE PARA EVALUAR PERMISOS
   const buttonEnabled = permisosState.ROLE_PERSONAL_ACCIONES || permisosState.ROLE_ADMIN;

   return (
      <Encabezado title='Acciones de Personal' container={true}>        
         <form onSubmit={handleSubmit(onSubmit)}>
            <Paper elevation={5}>

               <Grid xs={2} item display='flex' justifyContent='end'>
                  {/* EL BOTON SE VA A OCULTAR SI NO TIENE LOS PERMISOS Y SI LA ACCION FUE PROCESADA O ESTA SIN EFECTO. */}
                  <IconButton id='btn-editar' hidden={!buttonEnabled || accion?.estadoId !== 2} disabled={editEnabled} onClick={() => setEditEnabled(!editEnabled)}>
                     <MdModeEdit color={editEnabled ? 'gray' : colors.azulMem}  />
                  </IconButton>
               </Grid>

               <Stack p={3} mt={-2}>

                  <Typography fontWeight='bold'>Empleado</Typography>

                  <Controller
                     control={control}
                     name="accion.empleadoId"
                     render={({ field }) => (
                        <Select
                           isDisabled
                           classNamePrefix="select"
                           isSearchable={true}
                           placeholder="Seleccionar Empleado"
                           options={listaEmpleadosState}
                           getOptionLabel={(option) => option.nombreLabel}
                           getOptionValue={(option) => option.id}
                           onChange={(e) => field.onChange(e.id)}
                           value={listaEmpleadosState.find(x => x.id === field.value)}
                        />
                     )}
                  />

                  <p
                     style={{ marginLeft: 10, marginBottom: -2 }}
                     className="text-danger"
                  >
                     {errors.empleadoId?.message}
                  </p>


                  <div className="row mt-4">
                     <div>
                        <h5 style={{ marginBottom: -10 }}>DATOS DEL EMPLEADO</h5>
                        <hr />
                     </div>
                  </div>

                  <Paper elevation={4}>
                     <div className="d-flex flex-row p-3">
                        <div className="col-md-4 pe-4 border-end">
                           <div className="form-group">
                              <label className="fw-bold">Nombres</label>
                              <input
                                 disabled
                                 className="form-control"
                                 {...register("empleado.nombres", {
                                    required: "El nombre es requerido",
                                 })}
                              />
                              <p className="text-danger">
                                 {errors.empleado?.nombres?.message}
                              </p>
                           </div>
                           <div className="form-group">
                              <label className="fw-bold">Apelidos</label>
                              <input
                                 disabled
                                 className="form-control"
                                 {...register("empleado.apellidos", {
                                    required: "El apellido es requerido",
                                 })}
                              />
                              <p className="text-danger">
                                 {errors.empleado?.apellidos?.message}
                              </p>
                           </div>

                           <div className="form-group mb-3">
                              
                              <label className="fw-bold">Cédula</label>

                              <Controller
                              name="empleado.cedula"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                              <MaskedInput
                                 disabled
                                 className="form-control"
                                 mask="999-9999999-9"
                                 maskChar=""
                                 value={field.value}
                                 onChange={field.onChange}
                                 />
                              )}
                              />

                              {errors.empleado?.cedula ? (
                                 <div className="text-danger">{errors?.cedula?.message}</div>
                              ) : null}

                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Fecha Nacimiento</label>
                              <Controller
                                 control={control}
                                 name="ReactDatepicker"
                                 {...register("empleado.fechaNacimiento", {
                                    required: "La fecha de nacimiento es requerida",
                                 })}
                                 render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                       <DatePicker
                                          inputFormat='dd/MM/yyyy'
                                          disabled
                                          label={" "}
                                          mask="__/__/____"
                                          value={field.value}
                                          onChange={(e) => field.onChange(e)}
                                          renderInput={(params) => (
                                             <TextField className="form-control" {...params} />
                                          )}
                                       />
                                    </LocalizationProvider>
                                 )}
                              />

                              <p className="text-danger mt-3">
                                 {errors.empleado?.fechaNacimiento?.message}
                              </p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Sexo</label>

                              <input
                                disabled
                                className="form-control"
                                {...register("empleado.sexo")}
                              />

                              <p className="text-danger">{errors.empleado?.sexo?.message}</p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Estado Civil</label>
                              
                              <Controller
                                 control={control}
                                 name="empleado.estadoCivilId"
                                 {...register("empleado.estadoCivilId")}
                                 render={({ field }) => (
                                    <Select
                                       isDisabled
                                       classNamePrefix="select"
                                       isSearchable={true}
                                       placeholder="Seleccionar Estado Civil"
                                       options={estadosCivil}
                                       getOptionLabel={(option) => option.nombre}
                                       getOptionValue={(option) => option.id}
                                       onChange={(e) => field.onChange(e.id)}
                                       value={estadosCivil.find(x => x.id === field.value)}
                                    />
                                 )}
                              />

                              <p className="text-danger">
                                 {errors.empleado?.estadoCivilId?.message}
                              </p>
                           </div>

                           <div className="form-grou mb-3">
                              <label className="fw-bold">Nacionalidad</label>
                              <Controller
                              control={control}
                              name="empleado.nacionalidadId"
                              render={({ field }) => (
                                 <Select
                                 isDisabled
                                 classNamePrefix="select"
                                 isSearchable={true}
                                 placeholder="Seleccionar Nacionalidad"
                                 options={nacionalidades}
                                 getOptionLabel={(option) => option.nombre}
                                 getOptionValue={(option) => option.id}
                                 onChange={(e) => field.onChange(e.id)}
                                 value={nacionalidades.find(x => x.id === field.value)}
                                 />
                              )}
                              />

                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Dirección</label>
                              <textarea
                                 disabled
                                 className="form-control"
                                 {...register("empleado.direccion")}
                              />
                              <p className="text-danger"></p>
                           </div>
                        </div>

                        <div className="col-md-4 ps-4 pe-4 border-end">
                           <div className="form-group">
                              <label className="fw-bold">Correo Personal</label>
                              <input
                                 disabled
                                 className="form-control"
                                 {...register("empleado.emailPersonal", {
                                    pattern: {
                                       value: /\S+@\S+\.\S+/,
                                       message: "El formato de correo no es valido",
                                    },
                                 })}
                              />
                              <p className="text-danger">
                                 {errors.empleado?.emailPersonal?.message}
                              </p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Celular</label>
                                 <Controller
                                 control={control}
                                 name="empleado.celular"
                                 render={({ field }) => (
                                    <MaskedInput
                                    disabled
                                    className="form-control"
                                    mask="(999) 999-9999"
                                    maskChar=""
                                    value={field.value}
                                    onChange={field.onChange} 
                                    />
                                 )}
                                 />
                              <p className="text-danger"></p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Telefono</label>
                                 <Controller
                                 control={control}
                                 name="empleado.telefono"
                                 render={({ field }) => (
                                    <MaskedInput
                                    disabled
                                    className="form-control"
                                    mask="(999) 999-9999"
                                    maskChar=""
                                    value={field.value}
                                    onChange={field.onChange} 
                                    />
                                 )}
                                 />
                              <p className="text-danger"></p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Fecha Ingreso</label>
                              <Controller
                                 control={control}
                                 name="ReactDatepicker"
                                 {...register("empleado.fechaIngreso", {
                                    required: "La fecha de ingreso es requerida",
                                 })}
                                 render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                       <DatePicker
                                          inputFormat='dd/MM/yyyy'
                                          disabled
                                          label={" "}
                                          mask="__/__/____"
                                          value={field.value}
                                          onChange={(e) => field.onChange(e)}
                                          renderInput={(params) => (
                                             <TextField className="form-control" {...params} />
                                          )}
                                       />
                                    </LocalizationProvider>
                                 )}
                              />

                              <p className="text-danger mt-3">
                                 {errors.empleado?.fechaIngreso?.message}
                              </p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Tipo Empleado</label>

                              <Controller
                                 control={control}
                                 name="empleado.tipoEmpleadoId"
                                 {...register("empleado.tipoEmpleadoId")}
                                 render={({ field }) => (
                                    <Select
                                       isDisabled
                                       classNamePrefix="select"
                                       isSearchable={true}
                                       placeholder="Seleccionar Área Reporte"
                                       options={tipoEmpleados}
                                       getOptionLabel={(option) => option.tipo}
                                       getOptionValue={(option) => option.id}
                                       onChange={(e) => field.onChange(e.id)}
                                       value={tipoEmpleados.find(x => x.id === field.value)}
                                    />
                                 )}
                              />

                              <p className="text-danger">
                                 {errors.empleado?.tipoEmpleadoId?.message}
                              </p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Programa</label>

                              <Controller
                                 control={control}
                                 name="empleado.programaId"
                                 {...register("empleado.programaId")}
                                 render={({ field }) => (
                                    <Select
                                       isDisabled
                                       classNamePrefix="select"
                                       isSearchable={true}
                                       placeholder="Seleccionar Programa"
                                       options={programas}
                                       getOptionLabel={(option) => option.programa}
                                       getOptionValue={(option) => option.id}
                                       onChange={(e) => field.onChange(e.id)}
                                       value={programas.find(x => x.id === field.value)}
                                    />
                                 )}
                              />
                            
                              <p className="text-danger">
                                 {errors.empleado?.programaId?.message}
                              </p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Horario</label>
                              
                              <Controller
                                 control={control}
                                 name="empleado.horarioId"
                                 {...register("empleado.horarioId")}
                                 render={({ field }) => (
                                    <Select
                                       isDisabled
                                       classNamePrefix="select"
                                       isSearchable={true}
                                       placeholder="Seleccionar Horario"
                                       options={horarios}
                                       getOptionLabel={(option) => option.turnos[0].horarioLabel}
                                       getOptionValue={(option) => option.id}
                                       onChange={(e) => field.onChange(e.id)}
                                       value={horarios.find(x => x.id === field.value)}
                                    />
                                 )}
                              />
                            
                              <p className="text-danger">
                                 {errors.empleado?.horarioId?.message}
                              </p>
                           </div>
                        </div>

                        <div className="col-md-4 ps-4 pe-4">
                           <div className="form-group">
                              <label className="fw-bold">Sueldo</label>
                              <Controller
                              name="empleado.sueldo"
                              control={control}
                              defaultValue=""
                              rules={{
                                 required: "El sueldo es requerido"
                              }}
                              render={({ field }) => (
                                 <CurrencyInput
                                 disabled={!verificarNaturalezaSeleccionada([5, 7, 9, 11], listTipoAccionInt) && editEnabled || !editEnabled}
                                 className="form-control"
                                 placeholder="$0.00" type="text"
                                 value={field.value}
                                 onChange={field.onChange}
                                 >
                                 
                                 </CurrencyInput>
                              )}
                              />
                           
                              <p className="text-danger">
                                 {errors.empleado?.sueldo?.message}
                              </p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Correo Institucional</label>
                              <input
                                 disabled
                                 className="form-control"
                                 {...register("empleado.emailInstitucional", {
                                    pattern: {
                                       value: /\S+@\S+\.\S+/,
                                       message: "El formato de correo no es valido",
                                    },
                                 })}
                              />
                              <p className="text-danger">
                                 {errors.empleado?.emailInstitucional?.message}
                              </p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Flota</label>
                              <Controller
                                 control={control}
                                 name="empleado.flota"
                                 rules={
                                    {
                                    minLength: {
                                    value: 14,
                                    message: 'La flota debe tener 10 dígitos'
                                    }}
                                 }
                                 render={({ field }) => (
                                    <MaskedInput
                                    disabled
                                    className="form-control"
                                    mask="(999) 999-9999"
                                    maskChar=""
                                    value={field.value}
                                    onChange={field.onChange} 
                                    />
                                 )}
                                 />
                              <p className="text-danger"></p>
                           </div>

                           <div className="form-group">

                              <label className="fw-bold">Área Organizacional</label>
                              <Controller
                                 control={control}
                                 name="empleado.areaOrganizacionalId"
                                 {...register("empleado.areaOrganizacionalId")}
                                 render={({ field }) => (
                                    <Select
                                       isDisabled={!verificarNaturalezaSeleccionada([5, 7, 8, 10, 12], listTipoAccionInt) && editEnabled || !editEnabled}
                                       classNamePrefix="select"
                                       isSearchable={true}
                                       placeholder="Seleccionar Área Organizacional"
                                       options={areaOrganizacionales}
                                       getOptionLabel={(option) => option.nombre}
                                       getOptionValue={(option) => option.id}
                                       onChange={(e) => field.onChange(e.id)}
                                       value={areaOrganizacionales.find(x => x.id === field.value)}
                                    />
                                 )}
                              />

                              <p className="text-danger">
                                 {errors.empleado?.areaOrganizacionalId?.message}
                              </p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Cargo</label>
                              
                                 <Controller
                                 control={control}
                                 name="empleado.cargoId"
                                 {...register("empleado.cargoId")}
                                 render={({ field }) => (
                                    <Select
                                       isDisabled={!verificarNaturalezaSeleccionada([5, 10, 12], listTipoAccionInt) && editEnabled || !editEnabled}
                                       classNamePrefix="select"
                                       isSearchable={true}
                                       placeholder="Seleccionar Cargo"
                                       options={cargos}
                                       getOptionLabel={(option) => option.titulo}
                                       getOptionValue={(option) => option.id}
                                       onChange={(e) => field.onChange(e.id)}
                                       value={cargos.find(x => x.id === field.value)}
                                    />
                                 )}
                                 />


                              <p className="text-danger">
                                 {errors.empleado?.cargoId?.message}
                              </p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Área de Reporte</label>

                              <Controller
                                 control={control}
                                 name="empleado.areaReporteId"
                                 {...register("empleado.areaReporteId")}
                                 render={({ field }) => (
                                    <Select
                                       isDisabled={!verificarNaturalezaSeleccionada([5, 7, 8, 10, 12], listTipoAccionInt) && editEnabled || !editEnabled}
                                       classNamePrefix="select"
                                       isSearchable={true}
                                       placeholder="Seleccionar Área Reporte"
                                       options={areaOrganizacionales}
                                       getOptionLabel={(option) => option.nombre}
                                       getOptionValue={(option) => option.id}
                                       onChange={(e) => field.onChange(e.id)}
                                       value={areaOrganizacionales.find(x => x.id === field.value)}
                                    />
                                 )}
                              />

                              <p className="text-danger">
                                 {errors.empleado?.areaReporteId?.message}
                              </p>
                           </div>

                           <div className="form-group">
                              <label className="fw-bold">Fecha de Efectividad</label>
                              <Controller
                                 control={control}
                                 name="ReactDatepicker"
                                 {...register("accion.fechaEfectividad", {
                                    required: "La fecha de efectividad es requerida",
                                 })}
                                 render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                       <DatePicker
                                          inputFormat='dd/MM/yyyy'
                                          disabled
                                          label={" "}
                                          mask="__/__/____"
                                          value={field.value}
                                          onChange={(e) => field.onChange(e)}
                                          renderInput={(params) => (
                                             <TextField className="form-control" {...params} />
                                          )}
                                       />
                                    </LocalizationProvider>
                                 )}
                              />

                              <p className="text-danger mt-3">
                                 {errors.fechaEfectividad?.message}
                              </p>
                           </div>
                        </div>
                     </div>
                  </Paper>

               </Stack>
            </Paper>

 
            <Paper elevation={5}>
               <Stack mt={2} p={3}>
                  <Typography textTransform="uppercase">
                     Naturaleza de la acción
                  </Typography>
                  <Divider />

                  <Grid container spacing={{ lg: 2, md: 1 }} component={'div'} id='cuadro-naturaleza'>
                     {naturalezaTipoAcciones.length > 0 &&
                        fields !== undefined &&
                        naturalezaTipoAcciones.map((item, index) => (
                           <Grid item p={2} xs={4} md={4} lg={2.4} key={item.id} mt={2}>
                              <Stack>
                                 <Typography
                                    key={index}
                                    sx={{ fontSize: { lg: 14, md: 12, xs: 11 } }}
                                    fontWeight="bold"
                                    textTransform="uppercase"
                                 >
                                    {item.nombre}
                                 </Typography>

                                 {item.tiposAcciones.map((item2, index2) => (
                                    <FormControlLabel
                                       disabled={!editEnabled}
                                       key={item2.id}
                                       sx={{
                                          "& .MuiTypography-root": {
                                             fontSize: { lg: 14, md: 12, xs: 11 },
                                          },
                                       }}
                                       control={
                                          <Checkbox
                                          id={`tipoaccion.${item2.id}`}
                                          ref={register()}
                                          value={getValues(`listaTipoAccion.${index}.${index2}.seleccionado`)}
                                          checked={Boolean(watch(`listaTipoAccion[${index}][${index2}].seleccionado`))}
                                          onChange={(e) => {
                                              swal("Advertencia", "Las naturalezas están siendo modificas, favor verificar las informaciones de la acción.", "warning");
                                              const isChecked = e.target.checked;
                                              setValue(`listaTipoAccion.${index}.${index2}.seleccionado`, isChecked);
                                          }}
                                          />
                                       }
                                       label={item2?.nombre}
                                    />
                                 ))}
                              </Stack>
                           </Grid>
                        ))}

                     <p
                        style={{ marginLeft: 10, marginBottom: -2 }}
                        className="text-danger"
                     >
                        {listTipoAccionInt.length === 0 &&
                           "Debe seleccionar al menos una naturaleza"}
                     </p>
                  </Grid>
                  
               </Stack>
            </Paper>

            <Paper elevation={5}>
               <Stack mt={2} p={3}>
                  <Typography fontSize={14} textTransform="uppercase">
                     Detalles explicativos de la acción
                  </Typography>
                  <TextField
                     disabled={!editEnabled}
                     {...register("accion.detallesAccion")}
                     label=" "
                     variant="outlined"
                     multiline
                     rows={5}
                     error={errors.detallesAccion}
                     helperText={errors.detallesAccion?.message}
                  />

                  <div className='mb-3'>
                     <FormControlLabel
                     sx={{
                        "& .MuiTypography-root": {
                           fontSize: { lg: 14, md: 12, xs: 11 },
                        },
                     }}
                     control={
                        <Checkbox
                        disabled={!editEnabled}
                        value={getValues(`accion.generarComentarios`)}
                        checked={watch(`accion.generarComentarios`)}
                        onChange={(e) => {
                           const isChecked = e.target.checked;
                           setValue(`accion.generarComentarios`, isChecked);
                        }}
                        />
                     }
                     label={"Generar comentarios sobre la acción mediante el sistema."}
                     />
                  </div>

                  <Typography fontSize={14} textTransform="uppercase">
                     Para uso de área de recursos humanos
                  </Typography>
                  <TextField
                     disabled={!editEnabled}
                     {...register("accion.descripcionRRHH")}
                     label=" "
                     variant="outlined"
                     multiline
                     rows={5}
                     error={errors.descripcionRRHH}
                     helperText={errors.descripcionRRHH?.message}
                  />

                  <Box mt={2} display="flex" justifyContent="center">
                     <button id='btn-guardar' hidden={!editEnabled} className="btn btn-primary-mem me-2">
                        Guardar <BiSave fontSize={22} />
                     </button>
                     <button id='btn-cancelar' className="btn btn-danger" onClick={() => navigate('/acciones/personal')}>
                        Cancelar <MdCancel fontSize={22} />
                     </button>
                  </Box>

               </Stack>
            </Paper>
         </form>

         <Loading open={isLoading} />
      </Encabezado>
   )
}
