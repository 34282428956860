import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "../config";

const initialState = {
    isLoading: false,
    isSuccess: false,
    message:"",
    areaOrganizacionales: [],
    areaOrganizacionalesLista: [],
    areaOrganizacional: {}
}

// * MENSAJE A MOSTRAR DE UN ERROR 500 EN API.
let messageFrontEnd = "Por favor, comunicarse con el departamento de Desarrollo e Implementación de Sistemas.";

// * LIMPIAR SUCCESS
export const fetchCambiarSuccess = createAsyncThunk(
	"areaOrganizacional/fetchCambiarSuccess",
	async () => {
	  return null;
	}
);

export const fetchAreasOrganizacionales = createAsyncThunk("areaOrganizacional/fetchAreasOrganizacionales",async ()=>{

    var resultado = await axiosApiInstance.get(endpoints.areasOrganizacionales.fetchAreasOrganizacionales);
    return resultado.data.data;
  
})

export const fetchAreasOrganizacionalesLista = createAsyncThunk("areaOrganizacional/fetchAreasOrganizacionalesLista",async ()=>{
    try {
        var resultado = await axiosApiInstance.get(endpoints.areasOrganizacionales.fetchAreasOrganizacionalesLista);
		return resultado.data.data;
    } catch (error) {
        return error;
    }
})

export const fetchAreaOrganizacional = createAsyncThunk("areaOrganizacional/fetchAreasOrganizacional",async ()=>{
    try {
        var resultado = await axiosApiInstance.get(`${endpoints.areasOrganizacionales.fetchAreasOrganizacional}/1`);
		return resultado.data.data;
    } catch (error) {
        return error;
    }
})

export const fetchAreaOrganizacionalAgregar = createAsyncThunk("areaOrganizacional/fetchAreaOrganizacionalAgregar",

async (data) =>{
    try {
        var resultado = await axiosApiInstance.post(endpoints.areasOrganizacionales.fetchAreaOrganizacionalAgregar, data);
		return resultado.data;
    } catch (error) {
        return error;
    }
})

export const fetchAreaOrganizacionalActualizar = createAsyncThunk("areaOrganizacional/fetchAreaOrganizacionalActualizar",

async (data) =>{
    try {
        var resultado = await axiosApiInstance.post(endpoints.areasOrganizacionales.fetchAreaOrganizacionalActualizar, data);
		return resultado.data;
    } catch (error) {
        return error;
    }
})

export const fetchAreaOrganizacionalEliminar = createAsyncThunk("areaOrganizacional/fetchAreaOrganizacionalEliminar",

async (areaOrganizacionalId) => {
    try {
        var resultado = await axiosApiInstance.post(`${endpoints.areasOrganizacionales.fetchAreaOrganizacionalRemover}?areaOrganizacionalId=${areaOrganizacionalId}`);
		return resultado.data;
    } catch (error) {
        return error;
    }
})

export const AreaOrganizacionalSlice = createSlice({
    name:"areaOrganizacionales",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(fetchCambiarSuccess.fulfilled, (state) => {
            state.isSuccess = "idle";
        })
        .addCase(fetchAreasOrganizacionales.pending,(state,action)=>{
            state.isLoading =true;
        })
        .addCase(fetchAreasOrganizacionales.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.message = "";
            state.areaOrganizacionales = action.payload;
        })
        .addCase(fetchAreasOrganizacionales.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message
            state.areaOrganizacionales = [];
        })
        .addCase(fetchAreasOrganizacionalesLista.pending,(state,action)=>{
            state.isLoading =true;
        })
        .addCase(fetchAreasOrganizacionalesLista.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.message = "";
            state.areaOrganizacionalesLista = action.payload;
        })
        .addCase(fetchAreasOrganizacionalesLista.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message
            state.areaOrganizacionalesLista = [];
        })
        .addCase(fetchAreaOrganizacional.pending,(state,action)=>{
            state.isLoading =true;
        })
        .addCase(fetchAreaOrganizacional.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = true;
            state.message = ""
            state.areaOrganizacional = action.payload;
        })
        .addCase(fetchAreaOrganizacional.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = action.error.message
            state.areaOrganizacional = {};
        })
        .addCase(fetchAreaOrganizacionalAgregar.pending ,(state, action)=>{
            state.isLoading = true;
        })
        .addCase(fetchAreaOrganizacionalAgregar.fulfilled, (state, action)=>{

            if (action.payload.isSuccess) 
            {
                state.isSuccess = 'success';
            }
            else
            {
                state.isSuccess = 'error';
            }
      
            state.isLoading = false;
            state.message = action.payload.message;
        })
        .addCase(fetchAreaOrganizacionalAgregar.rejected, (state, action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = messageFrontEnd;
            console.error(action.error.message);
        })
        .addCase(fetchAreaOrganizacionalActualizar.pending ,(state, action)=>{
            state.isLoading = true;
        })
        .addCase(fetchAreaOrganizacionalActualizar.fulfilled, (state, action)=>{

            if (action.payload.isSuccess) 
            {
                state.isSuccess = 'success';
            }
            else
            {
                state.isSuccess = 'error';
            }
      
            state.isLoading = false;
            state.message = action.payload.message;
        })
        .addCase(fetchAreaOrganizacionalActualizar.rejected, (state, action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = messageFrontEnd;
            console.error(action.error.message);
        })
        .addCase(fetchAreaOrganizacionalEliminar.pending ,(state, action)=>{
            state.isLoading = true;
        })
        .addCase(fetchAreaOrganizacionalEliminar.fulfilled, (state, action)=>{

            if (action.payload.isSuccess) 
            {
                state.isSuccess = 'success';
            }
            else
            {
                state.isSuccess = 'error';
            }
      
            state.isLoading = false;
            state.message = action.payload.message;
        })
        .addCase(fetchAreaOrganizacionalEliminar.rejected, (state, action)=>{
            state.isLoading = false;
            state.isSuccess = false;
            state.message = messageFrontEnd;
            console.error(action.error.message);
        });

    }
})

export const selectAllAreasOrganizacionales = (state) => state.areaOrganizacionales.areaOrganizacionales;
export const selectAllAreasOrganizacionalesLista = (state) => state.areaOrganizacionales.areaOrganizacionalesLista;
export const selectAreaOrganizacional = (state) => state.areaOrganizacionales.areaOrganizacional;

export const selectIsLoading = (state) => state.areaOrganizacionales.isLoading
export const selectIsSuccess = (state) => state.areaOrganizacionales.isSuccess
export const selectMessage = (state) => state.areaOrganizacionales.message

export const {} = AreaOrganizacionalSlice.actions;
export default AreaOrganizacionalSlice.reducer;