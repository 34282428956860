import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  ReporteAsistenciaCard,
  ReporteAsistenciaEmpleadoCard,
  FiltroDepartamentoReporteAsistenciaCard as FiltroDepartamentoReportePermisoCard,
  FiltroReporteAsistenciaCard as FiltroReportePermisosCard,
} from "../../../Components/Cards";

import {
  fetchListaAsistencias,
  selectIsLoading,
  selectIsSuccess,
  selectListadoPonches,
  selectMessage,
  fetchListaAsistenciasExportar,
  fetchCambiarSuccess,
  fetchLimpiarAsistencia
} from "../../../redux/Reportes/ReporteAsistenciasSlice";

import Loading from "../../../Components/Modals/Loading/Loader";
import { calcularAsistencia } from "../../../utils/calcularAsistencia";
import { Encabezado } from "../../../Components/Encabezado/Encabezado";

export const Asistencias = () => {
  
  // ? USE-SELECTOR
  const dispatch = useDispatch();
  const isSuccess = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  const listaEmpleadosPonches = useSelector(selectListadoPonches);
  const isLoading = useSelector(selectIsLoading);

  // ? USE-STATE
  // * HOOK-STATE QUE GUARDA LOS DATOS DEL EMPLEADO.
  const [empleado, setEmpleado] = useState({});

  // * HOOK-STATE QUE ALMACENA EL NOMBRE DEL AREA QUE PERTENECEN LOS EMPLEADOS.
  const [area, setArea] = useState(null);

  // * HOOK-STATE DE LOS TOTALES.
  const [contadores, setContadores] = useState({
    totalAusencias: 0,
    totalLlegadaTarde: 0,
    totalSalidaTemprano: 0,
    totalTardanza: 0
  });

  // * FUNCION QUE CAMBIA EL SUCCESS.
  const updateSuccess = () => {
    dispatch(fetchCambiarSuccess());
  }

  // * FUNCION QUE CAMBIA EL EMPLEADO.
  const nextEmpleado = (emp) => {
    setEmpleado(emp);
  };

  const searchReporteAsistencia = (filtro) => {
    dispatch(fetchListaAsistencias(filtro));
  };

  // * FUNCION QUE SOLICITA EL REPORTE DE ASISTENCIA EN FORMATO EXCEL.
  const ReporteAsistenciaExcel = (filtro) => {
    dispatch(fetchListaAsistenciasExportar(filtro))
  }

  // ? HOOK-EFFECT QUE CALCULA LA ASISTENCIA DE UN EMPLEADO SEGUN EL SELECCIONADO.
  useEffect(() => {
    if (Object.keys(empleado).length > 0) {
      calcularAsistencia(empleado, setContadores);
    }
  }, [empleado]);

  useEffect(() => {
    dispatch(fetchLimpiarAsistencia());
  }, [])

  // * COMPROBACION DE QUE EXISTE UNO O MAS EMPLEADOS PARA MOSTRAR LAS OPCIONES CORRESPONDIENTES.
  const comprobacionEmpleados = listaEmpleadosPonches.length > 0 ? true : false;

  return (
  
    <Encabezado title='REPORTE DE ASISTENCIA'>
       <div className="row">
            <div className="col-xl-6">
              <FiltroReportePermisosCard
                searchReporteAsistencia={searchReporteAsistencia}
                isSuccess={isSuccess}
                message={message}
                setArea={setArea}
                ReporteAsistenciaExcel={ReporteAsistenciaExcel}
                updateSuccess={updateSuccess}
              />
            </div>

            <div className="col-xl-6">
              {comprobacionEmpleados ? (
                <FiltroDepartamentoReportePermisoCard
                  listaEmpleadosPonches={listaEmpleadosPonches}
                  setEmpleado={nextEmpleado}
                  empleado={empleado}
                  area={area}
                />
              ) : (
                <></>
              )}
            </div>

            <div className="col-xl-12">
              {comprobacionEmpleados ? (
                <ReporteAsistenciaEmpleadoCard
                  empleado={empleado}
               />
              ) : (
                <></>
              )}
            </div>

            <div className="col-xl-12">
              {comprobacionEmpleados ? (
                <ReporteAsistenciaCard
                  empleado={empleado}
                  contadores={contadores}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
      

      {/* PANTALLA DE CARGA */}
      <Loading open={isLoading} />
    </Encabezado>
   
  );
};

export default Asistencias;
