import React from "react";
import { Routes, Route, Navigate } from "react-router";
import {
  Horarios,
  DiasFeriados,
  PermisosGenerales,
} from "../pages/Control/Asistencia";

import {RequiredPermission} from '../authentication/RequiredPermission';

export const RutasAsistencia = () => {
  return (
    <Routes>

      <Route element={<RequiredPermission permission='ROLE_VER_TURNO'/>}> 
        <Route index element={<Horarios />} />
      </Route>

      <Route element={<RequiredPermission permission='ROLE_VER_FERIADO'/>}> 
        <Route path="diasferiados" element={<DiasFeriados />} />
      </Route>

      <Route element={<RequiredPermission permission='ROLE_VER_PERMISO_GENERAL'/>}> 
        <Route path="permisosgenerales" element={<PermisosGenerales />} />
      </Route>

      <Route path='/*' element={<Navigate to="/" replace />} />

    </Routes>
  );
};
