import React, { useState, useEffect } from 'react';
import numbro from 'numbro';
import { useDispatch, useSelector } from 'react-redux';
import { formatNumberRD } from '../../utils/formatNumberRD';
import { IconButtonText } from '../../Components/IconPersonalize/IconButtonText';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { useNavigate } from 'react-router';

import { 
    fetchReporteNominaEmpleado,
    selectNominaEmpleadoReporteAtributos
} from '../../redux/Nominas/NominaSlice';
import { ViewPDF } from '../PDFViewer/ViewPDF';

export const NominaEmpleadoCardLista = ({listaNominaEmpleadosFiltradoLength, listaNominaEmpleadosFiltradoDinamico}) => {

    // ? HOOK-STATE DE MODAL VIEW PDF.
    const [openModal, setOpenModal] = useState(false);

    // ? HOOK DE NAVEGACION
    const navigate = useNavigate();

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const dataReporteNominaPDF = useSelector(selectNominaEmpleadoReporteAtributos);

    // * FUNCION PARA VER NOMINA DE UN EMPLEADO.
    const navigateNominaEmpleado = (item) => {
        navigate('/nominas/vernominas/empleado', { state: item });
    }

    // * FUNCION PARA GENERAR REPORTE.
    const generateReportNominaEmpleado = (item) => {

        // * EXTRAYENDO AÑO Y MES DE LA FECHA.
        const fecha = item?.fechaNomina?.split('/');

        const data = {
            empleadoId: item?.empleadoId ?? 0,
            ano: fecha[1],
            mes: fecha[0]
        }
        
        dispatch(fetchReporteNominaEmpleado(data));

    }

    useEffect(() => {

        // * SI EL EXISTE DATA EN EMPLEADO REPORTE EN REDUX PUES
        // * SE HABILITA EL MODAL DE VER VISTA PREVIA DEL PDF.
        if(Object.keys(dataReporteNominaPDF).length > 0){
            setOpenModal(true);
        }

    }, [dataReporteNominaPDF])
    

    return (
        <>
        
            {
                listaNominaEmpleadosFiltradoLength > 0 &&
                listaNominaEmpleadosFiltradoDinamico.length > 0 &&
                
                    listaNominaEmpleadosFiltradoDinamico.map((item, index) => (

                        <div className="row mx-auto" key={index}>      
                            <div className="card mb-3">
                                <div className="row g-0">
                                <div className="col-1 text-center mt-3 mb-3">
                                    <p className="card-text fw-bold text-14">No. Empleado</p>
                                    <p className="text-14">{item.codigoEmpleado}</p>
                                </div>
                    
                                <div className="col-2">
                                    <div className="text-center mt-3 mb-3">
                                    <p className="card-text fw-bold text-14">Empleado</p>
                                    <p className="text-14">{item.empleado}</p>
                                    </div>
                                </div>
                    
                                <div className="col-2">
                                    <div className="text-center mt-3 mb-3">
                                    <p className="card-text fw-bold text-14">Cargo</p>
                                    <p className="text-14">{item.cargo}</p>
                                    </div>
                                </div>
                    
                                    
                                <div className="col-2">
                                    <div className="text-center mt-3 mb-3">
                                    <p className="card-text fw-bold text-14">Área</p>
                                    <p className="text-14">{item.area}</p>
                                    </div>
                                </div>
                    
                                <div className="col-2">
                                    <div className="text-center mt-3 mb-3">
                                    <p className="card-text fw-bold text-14">Programa</p>
                                    <p className="text-14">{item.programa}</p>
                                    </div>
                                </div>
                    
                                <div className="col-1">
                                    <div className="text-center mt-3 mb-3">
                                    <p className="card-text fw-bold text-14">Sueldo Base</p>
                                    <p className="text-14">{numbro(item.sueldoBase).formatCurrency(formatNumberRD)}</p>
                                    </div>
                                </div>
                    
                                <div className="col-1">
                                    <div className="text-center mt-3 mb-3">
                                    <p className="card-text fw-bold text-14">Fecha nómina</p>
                                    <p className="text-14">{item.fechaNomina}</p>
                                    </div>
                                </div>
                    
                                <div className="col-1">
                                    <div className='mt-2 mb-2 d-flex justify-content-center' >
                    
                                    <>
                                        <IconButtonText Icon={PermContactCalendarIcon} func={navigateNominaEmpleado} params={item} id={'btn-ver-nomina'} />
                                        <IconButtonText Icon={TaskOutlinedIcon} func={generateReportNominaEmpleado} params={item} color={'#BD0000'}  id={'btn-ver-nomina-pdf'} />
                                    </>
                    
                                    </div>
                                </div>
                    
                                </div>
                            </div>
                        </div>
                

                    ))
            }

            <ViewPDF 
            open={openModal}
            dataReporte={dataReporteNominaPDF}
            setOpen={setOpenModal}
            />

        </>
  )
}
