import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "../config";


const initialState = {
    isLoading:false,
    message:"",
    isSuccess:'idle',
    horarios:[],
    horario:{}
}


export const fetchHorarios = createAsyncThunk("horarios/fetchHorarios",async ()=>{
    try {
        let resultado = await axiosApiInstance.get(endpoints.horarios.fetchHorarios);
        return resultado.data;
    } catch (error) {
        throw error;
    }
});

export const fetchHorario = createAsyncThunk("horarios/fetchHorario", async (horarioid)=>{
    try {
        let resultado =await axiosApiInstance.get(`${endpoints.horarios.getHorario}/${horarioid}`)
        return resultado.data;
    } catch (error) {
        throw error;
    }

});

export const addNewHorario = createAsyncThunk("horarios/addNewHorario",async(horario)=>{
   try {
            let resultado = await axiosApiInstance.post(`${endpoints.horarios.saveHorario}`,horario);
             return resultado.data;
    } catch (error) {
        throw error;
    }
});
export const updateHorario = createAsyncThunk("horarios/updateHorario",async(horario)=>{
   try {
            let resultado = await axiosApiInstance.post(`${endpoints.horarios.updateHorario}`,horario);
             return resultado.data;
    } catch (error) {
        throw error;
    }
});

export const deleteHorario = createAsyncThunk("horarios/deleteHorario",async(horario)=>{
   try {
            let resultado = await axiosApiInstance.post(`${endpoints.horarios.deleteHorario}`,horario);
             return resultado.data;
    } catch (error) {
        throw error;
    }
});

const HorarioSlice = createSlice({
    name:"horarios",
    initialState,
    extraReducers(builder){
        builder.addCase(fetchHorarios.pending,(state,action)=>{
            state.isLoading = true;
            state.message = "";
            state.isSuccess= 'idle';
        })
        .addCase(fetchHorarios.fulfilled,(state,action)=>{
            state.isLoading = false;
            state.message = action.payload.message;
            if(action.payload.isSuccess){
                state.horarios = action.payload.data;
                state.isSuccess = 'success';
            }else{
                state.isSuccess = 'error';
                state.horarios = [];
            }
        })
        .addCase(fetchHorarios.rejected,(state,action)=>{
            state.isLoading = false;
            state.message = action.error.message;
            state.isSuccess = 'error' 
            state.horarios = [];
        })
        .addCase(fetchHorario.pending,(state,action)=>{
            state.isLoading = true;
            state.message = "";
            state.isSuccess= 'idle';
        })
        .addCase(fetchHorario.fulfilled,(state,action)=>{
            state.isLoading = false;
            if(action.payload.isSuccess){
                state.isSuccess = 'success';
                state.horario = action.payload.data;
                state.message = action.payload.message
            }else{
                state.horario={};
                state.isSuccess = 'error';
                state.message = action.payload.message;
            }
        })
        .addCase(fetchHorario.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = 'error'
            state.horario = {};
            state.message = action.error.message
        })
        .addCase(addNewHorario.pending,(state,action)=>{
            state.isLoading = true;
            state.isSuccess = 'idle'
            state.message = ""
        })
        .addCase(addNewHorario.fulfilled,(state,action)=>{
            state.isLoading = false;
            if(action.payload.isSuccess){
                state.isSuccess = "success";
                state.horarios = [...state.horarios,action.payload.data];
                state.message = action.payload.message;
            }else{
                state.isSuccess = "error";
                state.message = action.payload.message;
            }
        })
        .addCase(addNewHorario.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = 'error';
            state.message = action.error.message;
        })
        .addCase(updateHorario.pending,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = 'idle'
            state.message = ""
        })
        .addCase(updateHorario.fulfilled,(state,action)=>{
            state.isLoading = false;
            if(action.payload.isSuccess){
                state.isSuccess = "success";
                let horariosFilter = state.horarios.filter(x=> x.id !==action.payload.data.id)
                state.horarios = [...horariosFilter,action.payload.data]
                state.message = action.payload.message;
            }else{
                state.isSuccess = "error";
                state.message = action.payload.message;
            }
        })
        .addCase(updateHorario.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = 'error';
            state.message = action.error.message;
        })
        .addCase(deleteHorario.pending,(state,action)=>{
            state.isLoading = true;
            state.isSuccess = 'idle'
            state.message = ""
        })
        .addCase(deleteHorario.fulfilled,(state,action)=>{
            state.isLoading = false;
            if(action.payload.isSuccess){
                state.isSuccess = "success";
                state.horarios =  state.horarios.filter(x=> x.id !==action.payload.data.id)
                state.message = action.payload.message;
            }else{
                state.isSuccess = "error";
                state.message = action.payload.message;
            }
        })
        .addCase(deleteHorario.rejected,(state,action)=>{
            state.isLoading = false;
            state.isSuccess = 'error';
            state.message = action.error.message;
        })

    }
})


export const selectAllHorarios = (state)=> state.horarios.horarios;
export const selectHorario = (state)=> state.horarios.horario;
export const selectIsSuccess = state=> state.horarios.isSuccess;
export const selectIsloading = state=> state.horarios.isLoading;
export const selectMessage = state=> state.horarios.message;

export const {} = HorarioSlice.actions;
export default HorarioSlice.reducer;