import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography
} from '@mui/material';

import Divider from '@mui/material/Divider';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Autocomplete } from "@mui/material";
import { Box } from "@mui/system";
import { fetchEvaluacionEditarPeriodo, fetchEvaluacionCrearPeriodo } from '../../../redux/Evaluaciones/EvaluacionesSlice';

export const AgregarEditarPeriodoEvaluacionModal = ({open, setOpen, periodo, tipoDeAccion, ultimaEvaluacionAno}) => {

    const {
        formState: { errors },
        control
    } = useForm();

    // * DESESTRUCTURACION DEL OBJETO DEL PERIODO.
    const { iniciodePeriodo, anio } = periodo;

    // ? HOOK-STATE PARA ALMACENAR LA FECHA.
    const [fecha, setFecha] = useState(new Date());

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // * ENVIANDO DATA A LA API.
    const sendDataApiEvaluaciones = () => {

        if(tipoDeAccion === 1){
            const data = {
                anio: ultimaEvaluacionAno + 1,
                estado: 0,
                iniciodePeriodo: fecha
            }

            // * MENSAJE DE CONFIRMACION
            swal({
                title: 'Advertencia',
                text: `Estás seguro/a que desea crear el periodo ${ ultimaEvaluacionAno + 1}?`,
                icon: 'warning',
                buttons: ['No', 'Si']
              }).then(respuesta => {
                  if(respuesta){
                    dispatch(fetchEvaluacionCrearPeriodo(data));
                  }
              })
      
        }
        else if(tipoDeAccion === 2){
            const data = {
                anio: anio,
                estado: 0,
                iniciodePeriodo: fecha
            }
    
            dispatch(fetchEvaluacionEditarPeriodo(data));
        }
       
    }

        
    // * FUNCION USADA EN EL BOTON CANCELAR.
    const cancelAction = () => {
        setOpen(false);
    }

    useEffect(() => {

        if(tipoDeAccion === 1){
            setFecha(obtenerFecha());
        }else if(tipoDeAccion === 2){
            setFecha(iniciodePeriodo)
        }
      
    }, [periodo, tipoDeAccion])

    // * FUNCION PARA OBTENER LA FECHA DEL PROXIMO PERIODO DE EVALUACION A CREAR.
    const obtenerFecha = () => new Date().setFullYear(ultimaEvaluacionAno + 1);

    return(
        <Dialog
        PaperProps={{ sx: { width: "30%", height: "35%" } }}
        open={open}
        maxWidth='lg'
        >
            <DialogTitle sx={{textTransform: 'uppercase'}} className='text-mem text-center'>{tipoDeAccion === 1 ? 'Agregar' : 'Editar'} Período</DialogTitle>
            <Divider />
                <DialogContent>

                    <Typography>Período</Typography>
                        <Autocomplete
                            disabled
                            className="mb-3"
                            disablePortal
                            disableClearable
                            options={tipoDeAccion === 1 ? ultimaEvaluacionAno + 1 : periodo}
                            value={tipoDeAccion === 1 ? ultimaEvaluacionAno + 1 : anio}
                            noOptionsText={"No encontrado"}
                            renderOption={(props, periodo) => (
                            <Box
                                component="option"
                                {...props}
                                key={tipoDeAccion === 1 ? ultimaEvaluacionAno + 1 : periodo.anio}
                                value={tipoDeAccion === 1 ? ultimaEvaluacionAno + 1 : periodo.anio}
                            >
                                {tipoDeAccion === 1 ? ultimaEvaluacionAno + 1 : periodo.anio}
                            </Box>
                            )}
                            renderInput={(params) => <TextField {...params} label={" "} />}
                            on
                            
                        />

                
                    <div className="form-group">
                        <Typography>Fecha de Inicio</Typography>
                        <Controller
                        control={control}
                        className="mb-3"
                        name="ReactDatepicker"
                        render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label={" "}
                                mask="__/__/____"
                                value={fecha}
                                onChange={(e) => setFecha(e)}
                                renderInput={(params) => (
                                <TextField
                                    height={10}
                                    className="form-control"
                                    style={{ height: 10 }}
                                    {...params}
                                />
                                )}
                            />
                            </LocalizationProvider>
                        )}
                        />
                            <p className="text-danger mt-3">
                            {errors.fechaInicio?.message}
                            </p>
                            <p className="text-danger">{errors.nombres?.message}</p>
                    </div>
                                                        
                </DialogContent>

                <DialogActions className='justify-content-center'>
                    <button onClick={() => cancelAction()} className='btn btn-danger'><DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>CANCELAR</button>
                    <button className='btn btn-primary-mem' onClick={() => sendDataApiEvaluaciones()}><SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>GUARDAR</button>
                </DialogActions>

        </Dialog>

    )
}