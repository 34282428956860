import React, {useEffect, useState} from 'react';
import swal from 'sweetalert';
import { useNavigate } from 'react-router';
import { useForm,Controller } from 'react-hook-form';
import { useSelector,useDispatch } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
	fetchEstadosCiviles,
	fetchProgramas,
	getUtilityIsLoading,
	getUtilityMessage,
	getUtilityIsSuccess,
	selectAllEstadosCiviles,
	selectAllPogramas,
	fetchTipoEmpleados,
	selectAllTiposEmpleados
} from "../../redux/Utility/UtilitySlice";

import {
	fetchHorarios,
	selectAllHorarios,
} from "../../redux/Horarios/HorarioSlice"

import {
	    fetchAreasOrganizacionales,
	    fetchAreaOrganizacional,
		selectAllAreasOrganizacionales,
		selectAreaOrganizacional
	} from "../../redux/AreaOrganizacional/AreaOrganizacionalSlice"
import {
        	fetchCargo,
			fetchCargos,
			fetchCargosLista,
			selectAllCargosLista,
			selectAllCargos,
			selectCargo,
			selectIsSuccess,
			selectIsLoading,
		} from "../../redux/Cargos/CargosSlice"

import { addNewEmpleado,selectEmpleadosIsLoading,selectEmpleadosIsSuccess,selectEmpleadosMessage } from '../../redux/Empleados/EmpleadosSlice';

import Loading from '../../Components/Modals/Loading/Loader';


export function EmpleadoNuevo() {
	const estadosCivil = useSelector(selectAllEstadosCiviles);
	const horarios = useSelector(selectAllHorarios);
	const programas = useSelector(selectAllPogramas);
	const tipoEmpleados = useSelector(selectAllTiposEmpleados);
	const areaOrganizacionales = useSelector(selectAllAreasOrganizacionales)
	const areaOrganizacional = useSelector(selectAreaOrganizacional)
	const cargos = useSelector(selectAllCargosLista)
	const cargo = useSelector(selectCargo)
	const isLoading = useSelector(selectIsLoading);
	const cargosIsSuccess = useSelector(selectIsSuccess);
	const empleadosIsLoading = useSelector(selectEmpleadosIsLoading)
	const empleadosIsSuccess = useSelector(selectEmpleadosIsSuccess)
	const empleaodMessage = useSelector(selectEmpleadosMessage);
	const [addRequestStatus,setAddRequestStatus] = useState('idle')


const dispatch = useDispatch();
const navigate = useNavigate();
	useEffect(()=>{
		dispatch(fetchEstadosCiviles());
		dispatch(fetchHorarios());
		dispatch(fetchProgramas());
		dispatch(fetchTipoEmpleados());
		dispatch(fetchAreasOrganizacionales());
		dispatch(fetchCargosLista());
	},[])

	const [fechaNacimiento, setFechaNacimiento]= useState("");
	const [fecgaIngreso, setFechaingreso]= useState("");

	const { register, formState: { errors }, handleSubmit ,reset, setValue, control} = useForm();
    const onSaveEmpleado=(empleadoNew)=>{
		try {
			setAddRequestStatus('success')
			dispatch(addNewEmpleado(empleadoNew)).unwrap();
			/**reset register  */
		} catch (error) {
			setAddRequestStatus('error')
			console.error("Fallo para guardar el empleado",error)
		}
	}

	useEffect(()=>{
		if(empleadosIsSuccess==='success' && addRequestStatus==='success'){
			swal("Exitoso!", "Fue guardado el empleado", "success");
			navigate("/empleados");
			setAddRequestStatus('idle');
		}else if(empleadosIsSuccess==='error'&& addRequestStatus==='success'){
			swal("Error!", empleaodMessage, "error");
			setAddRequestStatus('idle');
		}


	},[empleadosIsSuccess])


	return (
		<div className="container">
			<div className="row mt-2">
				<form
					onSubmit={handleSubmit((data) => {
						onSaveEmpleado(data)
					})}
				>
					<div>
						<h3 className="text-primary-mem">NUEVO EMPLEADO</h3>
						<hr />
					</div>
					<div className="d-flex flex-row">
						<div className="col-md-6  pe-4  border-end">
							<div className="form-group">
								<label className="fw-bold">Nombres</label>
								<input
									className="form-control"
									{...register('nombres', { required: 'El nombre es requerido' })}
								/>
								<p className='text-danger'>{errors.nombres?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Apelidos</label>
								<input
									className="form-control"
									{...register('apellidos', { required: 'El apellido es requerido' })}
								/>
								<p className='text-danger'>{errors.apellidos?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Cédula</label>
								<input
									className="form-control"
									{...register('cedula', {
										required: 'La cedula es requerida',
										maxLength: {
											value: 11,
											message: 'La cedula no puede contener más 11 digitos'
										},
										minLength: {
											value: 11,
											message: 'La cedula debe contener 11 digitos'
										},
                                        
									})}
								/>
								<p className='text-danger'>{errors.cedula?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Fecha Nacimiento</label>
                                <Controller
                                           control={control}
                                           name="ReactDatepicker"
										   {...register('fechaNacimiento', {
											required: 'La fecha de nacimiento es requerida'
										})}
                                           render={({ field }) => (
											<LocalizationProvider dateAdapter={AdapterDateFns} >
											<DatePicker
											label={" "}
											 mask="__/__/____"
											 value={field.value}
											 onChange={(e) => field.onChange(e)}
											 renderInput={(params) => <TextField className='form-control' {...params} />}
											/>
										  </LocalizationProvider>
                                           )}
                                         />

								<p className='text-danger mt-3'>{errors.fechaNacimiento?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Sexo</label>
								<select
									className="form-control"
									{...register('sexo', { required: 'El sexo es requerido' })}
								>
									<option value={''}>--Seleccionar Sexo--</option>
								    <option  value={"Masculino"}>Masculino</option>
								    <option  value={"Femenino"}>Femenino</option>
																	
								</select>
								<p className='text-danger'>{errors.estadoCivilId?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Estado Civil</label>
								<select
									className="form-control"
									{...register('estadoCivilId', { required: 'El estado civil es requerido' })}
								>
									<option value={''}>--Seleccionar Estado Civil--</option>
									{
										estadosCivil.map((estadoCivil)=><option key={estadoCivil.id} value={estadoCivil.id}>{estadoCivil.nombre}</option>)
									}
									
								</select>
								<p className='text-danger'>{errors.estadoCivilId?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Correo Personal</label>
								<input
									className="form-control"
									{...register('emailPersonal', {
										pattern: {
											value: /\S+@\S+\.\S+/,
											message: 'El formato de correo no es valido'
										}
									})}
								/>
								<p className='text-danger'>{errors.emailPersonal?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Dirección</label>
								<textarea className="form-control" {...register('direccion')} />
								<p className='text-danger'></p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Celular</label>
								<input className="form-control" {...register('celular')} />
								<p className='text-danger'></p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Telefono</label>
								<input className="form-control" {...register('telefono')} />
								<p className='text-danger'></p>
							</div>
						</div>
						<div className="col-md-6 ps-4 pe-4">
							<div className="form-group">
								<label className="fw-bold">Código Empleado</label>
								<input
									className="form-control"
									{...register('codigoEmpleado', { required: 'El codigo de empleado es requerido' })}
								/>
								<p className='text-danger'>{errors.codigoEmpleado?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Fecha Ingreso</label>
								{/* <input
									className="form-control"
									{...register('fechaIngreso', { required: 'La fecha de ingreso es requerida' })}
								/> */}
								 <Controller
                                           control={control}
                                           name="ReactDatepicker"
										   {...register('fechaIngreso', { required: 'La fecha de ingreso es requerida' })}
                                           render={({ field }) => (
											<LocalizationProvider dateAdapter={AdapterDateFns} >
											<DatePicker
											label={" "}
											 mask="__/__/____"
											 value={field.value}
											 onChange={(e) => field.onChange(e)}
											 renderInput={(params) => <TextField className='form-control' {...params} />}
											/>
										  </LocalizationProvider>
                                           )}
                                         />
								<p className='text-danger mt-3'>{errors.fechaIngreso?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Tipo Empleado</label>
								<select
									className="form-control"
									{...register('tipoEmpleadoId', { required: 'El tipo de empleado es requerido' })}
								>
									<option value={''}>Seleccionar Tipo Empleado</option>
									{
										tipoEmpleados.map((tipoEmpleado)=><option key={tipoEmpleado.id} value={tipoEmpleado.id}>{tipoEmpleado.tipo}</option>)
									}
								</select>
								<p className='text-danger'>{errors.tipoEmpleadoId?.message}</p>
							</div>
                            <div className="form-group">
								<label className="fw-bold">Programa</label>
								<select
									className="form-control"
									{...register('programaId', { required: 'El programa es requerido' })}
								>
									<option value={''}>Seleccionar Programa</option>
									{
										programas.map((programa)=><option key={programa.id} value={programa.id}>{programa.programa}</option>)
									}
								</select>
								<p className='text-danger'>{errors.programaId?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Horario</label>
								<select
									className="form-control"
									{...register('horarioId', { required: 'El horario es requerido' })}
								>
									<option value={''}>Seleccionar Horario</option>
									{
										horarios.map((horario)=><option key={horario.id} value={horario.id}>{horario.turnos[0]?.horarioLabel}</option>)
									}
								</select>
								<p className='text-danger'>{errors.horarioId?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Sueldo</label>
								<input
									className="form-control"
									{...register('sueldo', { required: 'El sueldo es requerido' })}
								/>
								<p className='text-danger'>{errors.sueldo?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Correo Institucional</label>
								<input
									className="form-control"
									{...register('emailInstitucional', {
										pattern: {
											value: /\S+@\S+\.\S+/,
											message: 'El formato de correo no es valido'
										}
									})}
								/>
								<p className='text-danger'>{errors.emailInstitucional?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Flota</label>
								<input className="form-control" {...register('flota')} />
								<p className='text-danger'></p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Área Organizacional</label>
								<select
									className="form-control"
									{...register('areaOrganizacionalId', {
										required: 'El área organizacional es requerida'
									})}
								>
									<option value={''}>Seleccionar Área Organizacional</option>
									{
										areaOrganizacionales.map((area)=><option key={area.id} value={area.id}>{area.nombre}</option>)
									}
								</select>
								<p className='text-danger'>{errors.areaOrganizacionalId?.message}</p>
							</div>
                            <div className="form-group">
								<label className="fw-bold">Cargo</label>
								<select
									className="form-control"
									{...register('cargoId', { required: 'El Cargo es requerido' })}
								>
									<option value={''}>Seleccionar Cargo</option>
									{
										cargos.map((cargoEntity)=><option key={cargoEntity.id} value={cargoEntity.id}>{cargoEntity.titulo}</option>)
									}
								</select>
								<p className='text-danger'>{errors.cargoId?.message}</p>
							</div>
                            <div className="form-group">
								<label className="fw-bold">Área de Reporte</label>
								<select
									className="form-control"
									{...register('areaReporteId', { required: 'El área de reporte es requerida' })}
								>
									<option value={''}>Seleccionar Área de Reporte</option>
									{
										areaOrganizacionales.map((area)=><option key={area.id} value={area.id}>{area.nombre}</option>)
									}
								</select>
								<p className='text-danger'>{errors.areaReporteId?.message}</p>
							</div>
						</div>
					</div>
					<div className="text-center mt-3 mb-3">
						<button type="submit" className="btn btn-primary-mem me-3">
							Guardar
						</button>
						<button type="button" className="btn btn-danger" onClick={()=>navigate("/empleados")}>
							Cancelar
						</button>
					</div>
				</form>
				<Loading open={isLoading}></Loading>
			</div>
		</div>
	);
}

export default EmpleadoNuevo;
