import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import BorderColor from "@mui/icons-material/BorderColor";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller } from "react-hook-form";
import { useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading/Loader";
import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';

import {
  fetchTiposPermisos,
  selectAllTiposPermisos,
  selectIsLoading,
  fetchTiposPermisosCategorias,
  selectAllTiposPermisosCategorias,
  selectIsSuccess,
  selectMessage,
  updatePermiso
} from "./../../../redux/Permisos/PermisosSlice";

import {
  selectAllEmpleadosList,
  fetchAllEmpleados,
} from "../../../redux/Empleados/EmpleadosSlice";


import { 
	selectDiasFeriadosAnoActual
} from '../../../redux/DiasFeriados/DiasFeriadosSlice';

import { calcularDiasHabiles } from "../../../utils/funcionesFechasPermisos";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const PermisoEditar = ({
  openEdit,
  setOpenEdit,
  permiso,
  setPermisoSelected,
  setExpanded
}) => {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const isLoading = useSelector(selectIsLoading);
  const empleadosList = useSelector(selectAllEmpleadosList);
  const tipospermisos = useSelector(selectAllTiposPermisos);
  const tipospermisosCategoria = useSelector(selectAllTiposPermisosCategorias);
  const isSuccess = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);
  const diasFeriados = useSelector(selectDiasFeriadosAnoActual);

  // ? HOOK-STATE
  const [
    tiposPermisosCategoriaGrupo,
    setTiposPermisosCategoriaGrupo,
  ] = useState([]);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    watch,
  } = useForm({ defaultValues: {
    id: 0,
    empleadoId: 0,
		tipoPermisoId: 0,
		dias: 0,
		fechaInicio: new Date(),
		fechaTermino: new Date(),
    diaCompleto: false,
		recurrencia: {
		  recurrente: false,
      l: false,
      m: false,
      x: false,
      j: false,
      v: false,
      s: false,
      d: false
		},
		diaCompleto: false,
		justificacion: ''
  } });

  // ? HOOK-WATCH CHECKED-RECURRENTE
  const checkedRecurrente = useWatch({
    control,
    name: "recurrencia.recurrente",
  });

  // ? HOOK-WATCH CHECKED-DIA-COMPLETO
  const checkedDiaCompleto = useWatch({
    control,
    name: "diaCompleto",
  })
 
  // ? HOOK-WATCH FECHA DE INICIO
  const fechaInicio = useWatch({
    control,
    name: "fechaInicio",
  })
  
  // ? HOOK-WATCH FECHA DE INICIO
  const fechaTermino = useWatch({
    control,
    name: "fechaTermino",
  })

  // ? HOOK-WATCH HORA DE INICIO
  const horaInicio = useWatch({
    control,
    name: "horaInicio",
  });

  // ? HOOK-WATCH HORA DE TERMINO
  const horaTermino = useWatch({
    control,
    name: "horaTermino",
  })


  // * FUNCION PARA RESETEAR LAS HORAS.
  const resetTimer = () => {
    let date = new Date();
    setValue(
      "horaInicio",
      moment(
        new Date(date.getFullYear(), date.getMonth(), date.getDay(), 8, 0, 0, 0)
      ).format("yyyy-MM-DD HH:mm:ss")
    );
    setValue(
      "horaTermino",
      moment(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDay(),
          16,
          0,
          0,
          0
        )
      ).format("yyyy-MM-DD HH:mm:ss")
    );
  };

  // * ENVIO DE DATA
  const onSubmit = (data) => {
    dispatch(updatePermiso(data));
  };

  // * FUNCION PARA CERRAR EL MODAL.
  const handleClose = () => {
    setOpenEdit(false);
    reset();
    resetTimer();
    setPermisoSelected({});
    setExpanded(false);
  };
  
  
  // ? HOOK-EFFECT
  useEffect(() => {
    dispatch(fetchAllEmpleados());
    dispatch(fetchTiposPermisos());
    dispatch(fetchTiposPermisosCategorias());
    resetTimer();
  }, []);


  // ? HOOK-EFFECT PARA ASIGNACION DE VALORES EN EL USE-FORM.
  useEffect(() => {
    
    if (Object.keys(permiso).length > 0) {  

      setValue("empleadoId", permiso.empleadoId);
      setValue("id", permiso.id);
      setValue("tipoPermisoId", permiso.tipoPermisoId);
      setValue("justificacion", permiso.justificacion);
      setValue("horaInicio", permiso.horaInicio);
      setValue("horaTermino", permiso.horaTermino);
      setValue("fechaInicio", moment(permiso.fechaInicio, "YYYY/MM/DD"));
      setValue("fechaTermino", moment(permiso.fechaTermino, "YYYY/MM/DD"));
      setValue("diaCompleto", permiso.diaCompleto);
      setValue("recurrencia", permiso.recurrencia);
      setValue(
        "dias",
        Math.round(
          moment
            .duration(
              moment(permiso.fechaTermino, "YYYY/MM/DD").diff(
                moment(permiso.fechaInicio, "YYYY/MM/DD")
              )
            )
            .asDays()
        )
      );
    }
  }, [permiso]);


  // ? HOOK-EFFECT PARA CLASIFICAR LOS TIPOS DE PERMISOS.
  useEffect(() => {
    let tiposPermisosCategoriaOrder = [];
    if (tipospermisosCategoria && tipospermisosCategoria.length > 0) {
      tipospermisosCategoria.forEach((element) => {
        let arrayTipoPermiso = tipospermisos.filter(
          (x) => x.categoriaId === element.id
        );
        let categoria = {
          label: element.nombre,
          options: arrayTipoPermiso.map((x) => ({
            label: x.nombre,
            value: x.id,
          })),
        };
        tiposPermisosCategoriaOrder.push(categoria);
      });
      setTiposPermisosCategoriaGrupo(tiposPermisosCategoriaOrder);
 
    }
  }, [tipospermisosCategoria]);


  // ? HOOK-EFFECT PARA RESTABLECER LOS CHECKBOX DE RECURRENCIA.
  useEffect(() => {
    
    if(!checkedRecurrente){
      setValue('recurrencia.l', false);
      setValue('recurrencia.m', false);
      setValue('recurrencia.x', false);
      setValue('recurrencia.j', false);
      setValue('recurrencia.v', false);
      setValue('recurrencia.s', false);
      setValue('recurrencia.d', false);
    }

  }, [checkedRecurrente])


  // ? HOOK-EFFECT PARA VERIFICAR SI EL CHECKED DEL DIA COMPLETO SE MARCA, SI SE HABILITA SE ASIGNA UN VALOR
  // ? AL CAMPO DIAS.
  useEffect(() => {
    if (checkedDiaCompleto) {
      resetTimer();
    }
  }, [checkedDiaCompleto]);


  // ? HOOK-EFFECT PARA CERRAR EL MODAL EN CASO DE QUE EL PERMISO SE ACTUALIZO
  // ? CORRECTAMENTE
  useEffect(() => {

    if(isSuccess === 'success' && message.length > 0){
      handleClose();
      setExpanded(false);
    }
   
  }, [isSuccess])

  
  // ? HOOK-EFFECT PARA CALCULAR LOS DIAS DE DURACION DE PERMISO SEGUN LAS FECHAS.
	useEffect(() => {
	  setValue("dias", calcularDiasHabiles({fechaInicio, fechaTermino, diasFeriados, horaInicio, horaTermino, checkedDiaCompleto}));
	}, [fechaInicio, fechaTermino, checkedDiaCompleto])
  

  return (
    <Modal
      open={openEdit}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 700 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="container">
            <div className="row">
              <h3 className="text-primary-mem text-center">
                Editar Permiso Personalizado
              </h3>
            </div>
            <div className="row">
              <div className="col-5 me-0 pe-0">
                <hr />
              </div>
              <div className="col-2 text-center">
                <BorderColor className="text-primary-mem"></BorderColor>
              </div>
              <div className="col-5 ms-0 ps-0 ">
                <hr />
              </div>
            </div>
            <div className="row">
              <div hidden>
                <input type="text" {...register("id")}></input>
              </div>
              <div className="form-group">
                <label className="fw-bold">Empleado</label>

                <Controller
                  control={control}
                  name="empleadoId"
                  {...register("empleadoId", {
                    required: "El empleado es requerido",
                  })}
                  defaultValue={22}
                  render={({ field }) => (
                    <Select
                      isDisabled
                      classNamePrefix="select"
                      isSearchable={true}
                      placeholder="Seleccionar Empleado"
                      options={empleadosList}
                      getOptionLabel={(option) => option.nombreLabel}
                      getOptionValue={(option) => option.id}
                      onChange={(e) => field.onChange(e.id)}
                      defaultValue={empleadosList.find(
                        (x) => x.id === field.value
                      )}
                    />
                  )}
                />

                <p className="text-danger mb-0">{errors.empleadoId?.message}</p>
              </div>
              <div className="form-group">
                <label className="fw-bold">Tipo Permiso</label>

                <Controller
                  control={control}
                  name="tipoPermisoId"
                  {...register("tipoPermisoId", {
                    required: "El tipo de permiso es requerido",
                  })}
                  render={({ field }) => (
                    <Select
                      classNamePrefix="select"
                      isSearchable={true}
                      placeholder="Seleccionar Tipo Permiso"
                      options={tiposPermisosCategoriaGrupo}
                      onChange={(e) => field.onChange(e.value)}
                      defaultValue={tiposPermisosCategoriaGrupo
                        .find(
                          (x) =>
                            x.label === permiso?.tipoPermiso?.categoria?.nombre
                        )
                        .options.find((x) => x.value === field.value)}
                    />
                  )}
                />
                <p className="text-danger mb-0">
                  {errors.tipoPermisoId?.message}
                </p>
              </div>
              <div className="form-group">
                <label className="fw-bold">Dias</label>
                <input
                  className="form-control"
                  type="number"
                  {...register("dias", {
                    required: "Los dias de permiso son requeridos",
                  })}
                ></input>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label className="fw-bold">Fecha inicio</label>
                    <Controller
                      control={control}
                      name="fechaInicio"
                      {...register("fechaInicio", {
                        required: "La fecha de inicio es requerida",
                      })}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            inputFormat='dd/MM/yyyy'
                            label={" "}
                            mask="__/__/____"
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                            renderInput={(params) => (
                              <TextField
                                height={10}
                                className="form-control"
                                style={{ height: 10 }}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <p className="text-danger mt-3 mb-0">
                      {errors.fechaInicio?.message}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label className="fw-bold">Fecha Término</label>
                    <Controller
                      control={control}
                      name="fechaTermino"
                      {...register("fechaTermino", {
                        required: "La fecha de término es requerida",
                      })}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            minDate={fechaInicio}
                            inputFormat='dd/MM/yyyy'
                            label={" "}
                            mask="__/__/____"
                            value={field.value}
                            onChange={(e) => field.onChange(e)}
                            renderInput={(params) => (
                              <TextField
                                height={10}
                                className="form-control"
                                style={{ height: 10 }}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <p className="text-danger mt-3 mb-0">
                      {errors.fechaTermino?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label className="fw-bold">Hora inicio</label>
                    
                    <Controller
                      control={control}
                      name="horaInicio"
                      {...register("horaInicio", {
                        required: "La hora de inicio es requerida",
                      })}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            value={field.value}
                            label={" "}
                            onChange={(e) => field.onChange(moment(e).format("yyyy-MM-DD HH:mm:ss"))}
                            renderInput={(params) => (
                              <TextField
                                height={10}
                                className="form-control"
                                style={{ height: 10 }}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <p className="text-danger mt-3 mb-0">
                      {errors.horaInicio?.message}
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label className="fw-bold">Hora Término</label>
                    <Controller
                      control={control}
                      // className="mb-3"
                      name="horaTermino"
                      {...register("horaTermino", {
                        required: "La hora de término es requerida",
                      })}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            value={field.value}
                            label={" "}
                            onChange={(e) => field.onChange(moment(e).format("yyyy-MM-DD HH:mm:ss"))}
                            renderInput={(params) => (
                              <TextField
                                height={10}
                                className="form-control"
                                style={{ height: 10 }}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                    <p className="text-danger mt-3 mb-0">
                      {errors.horaTermino?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">

                <div className="col">
                  <div className="form-group">

                    <FormControlLabel
                    control={
                      <Checkbox
                      value={watch("diaCompleto")}
                      checked={watch("diaCompleto")}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setValue(`diaCompleto`, isChecked);                         
                      }}
                      />
                    }
                    label="Día Completo"
                    />

                    {/* <p className='text-danger mt-3'>{errors.fecha?.message}</p> */}
                  </div>
                </div>

                <div className="col">
                  <div className="form-group">
                    
                    <FormControlLabel
                    control={
                      <Checkbox
                      value={watch("recurrencia.recurrente")}
                      checked={watch("recurrencia.recurrente")}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setValue(`recurrencia.recurrente`, isChecked);                         
                      }}
                      />
                    }
                    label="Permiso Recurrente"
                    />
                  
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row border me-1 ms-1">
                  <div className="col-3">
                    <div className="form-group">

                      <FormGroup>
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.l")}
                            checked={watch("recurrencia.l")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.l`, isChecked);                         
                            }}
                            />
                          }
                          label="Lunes"
                        />
                      </FormGroup>

                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">

                      <FormGroup>
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.m")}
                            checked={watch("recurrencia.m")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.m`, isChecked);                         
                            }}
                            />
                          }
                          label="Martes"
                        />
                      </FormGroup>

                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">

                      <FormGroup>
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.x")}
                            checked={watch("recurrencia.x")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.x`, isChecked);                         
                            }}
                            />
                          }
                          label="Miércoles"
                        />
                        
                      </FormGroup>

                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">

                      <FormGroup>
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.j")}
                            checked={watch("recurrencia.j")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.j`, isChecked);                         
                            }}
                            />
                          }
                          label="Jueves"
                        />
                        
                      </FormGroup>

                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">

                      <FormGroup>             
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.v")}
                            checked={watch("recurrencia.v")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.v`, isChecked);                         
                            }}
                            />
                          }
                          label="Viernes"
                        />
                        
                      </FormGroup>

                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">

                      <FormGroup>
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.s")}
                            checked={watch("recurrencia.s")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.s`, isChecked);                         
                            }}
                            />
                          }
                          label="Sábado"
                        />
                        
                      </FormGroup>

                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">

                      <FormGroup>
                        <FormControlLabel
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: { lg: 14, md: 12, xs: 11 },
                            },
                          }}
                          control={
                            <Checkbox
                            disabled={!checkedRecurrente}
                            value={watch("recurrencia.d")}
                            checked={watch("recurrencia.d")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setValue(`recurrencia.d`, isChecked);                         
                            }}
                            />
                          }
                          label="Domingo"
                        />
                      </FormGroup>
                    
                    </div>
                  </div>
                  
                </div>
              </div>

              <div className="form-group">
                <label className="fw-bold">Comentario</label>

                <textarea
                  className="form-control"
                  {...register("justificacion")}
                  rows={2}
                ></textarea>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <button id="btn-guardar" type="submit" className="btn btn-primary-mem me-3">
                  <SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}}/> Guardar
                </button>
                <button
                  id="btn-cancelar"
                  type="button"
                  className="btn btn-danger "
                  onClick={handleClose}
                >
                  <DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </form>

        <Loading open={isLoading}></Loading>
      </Box>
    </Modal>
  );
};

export default PermisoEditar;
