import React, { useState, useEffect } from 'react';
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Encabezado } from '../../Components/Encabezado/Encabezado';
import { FiltroHistoricoClinico } from '../../Components/HistoricoClinico/FiltroHistoricoClinico';
import Loading from '../../Components/Modals/Loading/Loader';
import swal from 'sweetalert';

import { FaEye } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";

import { 
  Box, 
  Paper, 
  TableContainer, 
  Table, 
  TableHead, 
  TableBody, 
  TablePagination,
  TableRow, 
  TableCell,
  IconButton
} from '@mui/material';

import {
  fetchGenerarReporteHistoricoPDF,
  fetchGetHistorialClinico,
  fetchGetHistorialClinicoCount,
  fetchCambiarSuccess,
  selectHistoricoClinicoList,
  selectHistoricoClinicoListCount,
  selectIsLoading,
  selectMessage,
  selectIsSuccess
} from '../../redux/HistoricoClinico/HistoricoClinicoSlice'

export const HistorialClinico = () => {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-NAVEGACION
  const navigate = useNavigate();

  // ? HOOK-SELECTOR
  const listHistoricoClinico = useSelector(selectHistoricoClinicoList);
  const countListHistoricoClinico = useSelector(selectHistoricoClinicoListCount);
  const isLoading = useSelector(selectIsLoading);
  const success = useSelector(selectIsSuccess);
  const message = useSelector(selectMessage);


  // ? USE-STATE PARA LA PAGINACION
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(25);
  const [ skipRow, setSkipRow ] = useState(0);

  // ? HOOK-FORM
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      empleado: {
        areaId: 0,
        empleadoId: 0,
        cargoId: 0
      }
    }
  });

  // ? HOOK-WATCH
  const empleado = useWatch({ control, name: 'empleado'});

  // * FUNCION PARA REFRESCAR LISTA.
  const refreshList = () => dispatch(fetchGetHistorialClinico({skipRow, rowsPerPage, empleado}));

  // ? HOOK-EFFECT
  useEffect(() => {
    refreshList();
  }, [empleado, page, rowsPerPage])

  // ? HOOK-EFFECT PARA OBTENER LA CANTIDAD DE ACCIONES DE RECLUTAMIENTO
  // ? DESDE EL BACK-END
  useEffect(() => {
    dispatch(fetchGetHistorialClinicoCount(empleado));
  }, [empleado])

    // ? HOOK-EFFECT PARA LAS ALERTAS.
    useEffect(() => {

      if (success === "success" && message?.length > 0) {
        dispatch(fetchCambiarSuccess());
        swal("Exitoso!", message, "success");
      } else if (success === "error") {
        dispatch(fetchCambiarSuccess());
        swal("Error!", message, "error");
      }
    }, [success]);

  
  // * FUNCIONES PARA PAGINACION.
  const handleChangePage = (event, newPage) => {
    setSkipRow(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSkipRow(0);
  };

  // * FUNCION PARA GENERAR PDF
  const generateReportHistorico = (id) => {
    dispatch(fetchGenerarReporteHistoricoPDF(id));
  }

  return (
    <Encabezado title='Historial Clinico'>

      <FiltroHistoricoClinico 
      control={control}
      register={register}
      reset={reset}
      />

      <Box sx={{ mt: 3 }} maxWidth={'100%'}>
        <Paper sx={{ mb: 2 }} elevation={3}>
          <TableContainer>

            <Table >
              <TableHead>
                <TableRow>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Código
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Empleado
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Cargo
                  </TableCell>
                  <TableCell style={{fontWeight: 'bold'}}>
                    Departamento
                  </TableCell>
                  <TableCell align='center' style={{fontWeight: 'bold'}}>
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>

                {
                  listHistoricoClinico.length > 0 &&
                    listHistoricoClinico.map((item, index) => (
                      <TableRow key={index}>
                        
                        <TableCell>
                          {item.codigoEmpleado}
                        </TableCell>
                      
                        <TableCell>
                          {item.nombreEmpleado}
                        </TableCell>
                        
                        <TableCell>
                          {item.cargo}
                        </TableCell>
                        
                        <TableCell>
                          {item.departamento}
                        </TableCell>

                        <TableCell align='center'>
                        
                          <IconButton id='btn-ver-historial-clinico' onClick={() => navigate(`/historialClinico/detalle/${item.historicoClinicoId}`)}>
                            <FaEye color='#003876' />
                          </IconButton>

                          <IconButton id='btn-pdf' onClick={() => generateReportHistorico(item.historicoClinicoId)}>
                            <FaFilePdf color='#BD0000' />
                          </IconButton>

                        </TableCell>
                        
                      </TableRow>
                    ))
                  }

              </TableBody>

            </Table>

          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={countListHistoricoClinico}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <Loading open={isLoading} />

    </Encabezado>
  )
}
