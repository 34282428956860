import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Puedes ajustar esto según el almacenamiento que uses
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';

import AreaOrganizacionalSlice from "./AreaOrganizacional/AreaOrganizacionalSlice";
import EmpleadosSlice from "./Empleados/EmpleadosSlice";
import UtilitySlice from "./Utility/UtilitySlice";
import PermisosSlice from "./Permisos/PermisosSlice";
import HorarioSlice from "./Horarios/HorarioSlice";
import DiasFeriados from "./DiasFeriados/DiasFeriadosSlice";
import PermisosGeneralesSlice from "./PermisosGenerales/PermisosGeneralesSlice";
import ReporteAsistenciasSlice from "./Reportes/ReporteAsistenciasSlice";
import ResumenAsistenciasSlice from "./Reportes/ResumenAsistenciasSlice";
import ReportePermisosSlice from './Reportes/ReportePermisosSlice'
import LoginSlice from "./Login/LoginSlice";
import CargosSlice from "./Cargos/CargosSlice";
import EvaluacionesSlice from "./Evaluaciones/EvaluacionesSlice";
import PeriodosSlice from "./Periodos/PeriodosSlice";
import GruposOcupacionalesSlice from "./GruposOcupacionales/GruposOcupacionalesSlice";
import BonoEscolarSlice from "./Beneficios/BonoEscolar/BonoEscolarSlice";
import NominaSlice from "./Nominas/NominaSlice";
import CafeteriaSlice from "./Cafeteria/CafeteriaSlice";
import NotificacionesSlice from "./Notificaciones/Notificaciones"
import AccionesSlice from "./Acciones/AccionesSlice";
import TipoAccionesSlice from "./TipoAcciones/TipoAccionesSlice";
import SorteoSlice from "./Sorteo/SorteoSlice";
import HistoricoClinicoSlice from "./HistoricoClinico/HistoricoClinicoSlice";

const persistConfig = {
  key: 'root',
  version: 1,
  timeout: 1000,
  whitelist: ['login'],
  storage,
};

const reducers = combineReducers({
  login: LoginSlice,
  empleados: EmpleadosSlice,
  utility: UtilitySlice,
  cargos: CargosSlice,
  areaOrganizacionales: AreaOrganizacionalSlice,
  permisos: PermisosSlice,
  horarios: HorarioSlice,
  diasFeriados: DiasFeriados,
  permisosGenerales: PermisosGeneralesSlice,
  reporteAsistencias: ReporteAsistenciasSlice,
  resumenAsistencias: ResumenAsistenciasSlice,
  reportePermisos: ReportePermisosSlice,
  evaluaciones: EvaluacionesSlice,
  periodos: PeriodosSlice,
  gruposOcupacionales: GruposOcupacionalesSlice,
  bonoEscolar: BonoEscolarSlice,
  nomina: NominaSlice,
  cafeteria: CafeteriaSlice,
  notificaciones: NotificacionesSlice,
  acciones: AccionesSlice,
  tipoAcciones: TipoAccionesSlice,
  sorteo: SorteoSlice,
  historicoClinico: HistoricoClinicoSlice
});

const rootReducer = (state, action) => {

  // * VALIDACION PARA LIMPIAR TODOS LOS REDUCERS AL MOMENTO DE CIERRE DE SESION.
  if (action.type === "login/fetchLogout/fulfilled") {
    // * LIMPIA TODOS LOS REDUCERS.
    state = undefined;
  }

  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),

  // * OCULTAR EL DEV-TOOLS EN PRODUCCION PARA QUE NO SE MUESTRE LAS INFORMACIONES DE LOS REDUCERS.
  devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(store);