import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "../config";

const initialState = {
    isLoading: false,
    message: "",
    isSuccess: 'idle',
    periodos: [],
}

export const fetchPeriodosLista = createAsyncThunk("periodos/fetchPeriodosLista",async ()=>{
        let resultado = await axiosApiInstance.get(endpoints.periodos.listaPeriodos);
        return resultado.data;
});

const PeriodosSlice = createSlice({
    name:"periodos",
    initialState,
    extraReducers(builder){
        builder.addCase(fetchPeriodosLista.pending,(state, action)=>{
            state.isLoading = true;
            state.message = "";
            state.isSuccess= 'idle';
        })
        .addCase(fetchPeriodosLista.fulfilled,(state, action)=>{
            state.isLoading = false;
            state.message = action.payload.message;
            
            if(action.payload.isSuccess){
                state.periodos = action.payload.data;
                state.isSuccess = 'success';
            }else{
                state.isSuccess = 'error';
                state.periodos = [];
            }
        })
        .addCase(fetchPeriodosLista.rejected,(state, action)=>{
            state.isLoading = false;
            state.message = action.error.message;
            state.isSuccess = 'error' 
            state.periodos = [];
        });     
    }
})

export const selectAllPeriodos = (state)=> state.periodos.periodos;
export const selectIsSuccess = state=> state.periodos.isSuccess;
export const selectIsloading = state=> state.periodos.isLoading;
export const selectMessage = state=> state.periodos.message;

export const {} = PeriodosSlice.actions;
export default PeriodosSlice.reducer;