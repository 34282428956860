import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, Divider, Typography, DialogTitle } from '@mui/material';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Autocomplete } from "@mui/material";
import { fetchAllEmpleados, selectAllEmpleadosList } from '../../../redux/Empleados/EmpleadosSlice';
import { fetchEvaluacionNotificacionesAll } from '../../../redux/Evaluaciones/EvaluacionesSlice';

export const EvaluacionNotificacionModal = ({open, setOpen, periodo, isValidation}) => {

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const listEmpleados = useSelector(selectAllEmpleadosList);

    // ? HOOK-STATE
    const [value, setValue] = useState([]);
    const [asunto, setAsunto] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [enviarData, setEnviarData] = useState(false);

    // ? HOOK-EFFECT
    useEffect(() => {
      dispatch(fetchAllEmpleados());
    }, [])

    useEffect(() => {
      
      // * VALIDACION PARA ENVIAR LOS CORREO.
      if(enviarData === true){

        let listCorreosFiltrado = [];
        let data = {
          periodo: periodo,
          mensaje: mensaje,
          asunto: asunto,
          listaCorreos: listCorreosFiltrado
        }
        
          if(isValidation === 1) {
    
            value.map((item) => listCorreosFiltrado.push({
              correo: item.emailInstitucional,
              nombreEvaluador: item.nombres.concat(' ', item.apellidos)
            }))
    
            data = {
              ...data, listaCorreos: listCorreosFiltrado
            }
          }

        // * ENVIAR DATA A LA API.
        dispatch(fetchEvaluacionNotificacionesAll(data));
        setEnviarData(false);

      }

    }, [enviarData])

  return (
    
      <Dialog 
      PaperProps={{ sx: { width: "50%", height: "50%" } }}
      open={open}
      maxWidth='lg'
      >
          <DialogTitle sx={{textTransform: 'uppercase'}} className='text-mem text-center'>Notificar Evaluadores</DialogTitle>
          <Divider />

          <DialogContent>

            {
              isValidation === 1 && 
              <>
                <Typography>Destinatario</Typography>
                  <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={listEmpleados}
                  getOptionLabel={(option) => option.nombreLabel}
                  filterSelectedOptions
                  onChange={(event, newValue) => {
                    setValue([
                      ...newValue.filter((option) => listEmpleados.find(x => x.id === option.id))
                    ]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=" "
                      placeholder="Evaluador"
                    />
                  )}
                  className='mb-2'
                  />
                </>
            }

            <Typography>Asunto</Typography>
                <TextField value={asunto} onChange={(e) => setAsunto(e.target.value)} fullWidth label=" " id="fullWidth" className='mb-2' />

            <Typography>Mensaje</Typography>
              <textarea value={mensaje} onChange={(e) => setMensaje(e.target.value)} style={{ width: '100%', resize: 'vertical', overflow: 'auto', maxHeight: '50%' }} className='form-control'>
              </textarea>
          </DialogContent>

          <DialogActions className='justify-content-center'>
              <button onClick={() => setOpen(false)} className='btn btn-danger'><DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>CANCELAR</button>
              <button className='btn btn-primary-mem' onClick={() => setEnviarData(true)}><SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>GUARDAR</button>
          </DialogActions>

      </Dialog>
  )
}
